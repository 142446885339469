import {
  CaretDownFilled,
  CaretUpFilled,
  CloseOutlined,
} from '@ant-design/icons'
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Space,
  Image,
  Table,
} from 'antd'
import React, {
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../../app/store'
import { ToothTable } from '../../../compnents/tooth/ToothTable'
import { Page } from '../../../models/page'
import {
  getRecipeCategoryName,
  isTcmRecipeCategory,
  RecipeCategory,
  RecipeStatus,
} from '../../../models/recipe'
import { Treatment } from '../../../models/treatment'
import { CopyRecipeParams } from '../../../services/recipe'
import { CopyTreatmentParams } from '../../../services/treatment'
import { ThemeContext } from '../../../theme/ThemeContext'
import {
  MedicineMaterialColumns,
  MedicineSFMaterialColumns,
  TcmColumns,
} from '../editor/columns'
import {
  getMaterialList,
  getRecipeList,
  openMaterial,
  openTreatment,
  selectMaterials,
  selectRecipes,
} from './treatmentSlice'
import styles from '../oralCases/OralCases.module.css'
import { AppointmentIcon } from '../../../compnents/icons/Icons'
import FileUpload from '../../../compnents/file/FileUpload'
import { UploadFile } from 'antd/lib/upload/interface'
import { getUrl } from '../oralCases/oralCasesSlice'
import { unwrapResult } from '@reduxjs/toolkit'
import { traceRoute } from '../../../layouts/layoutSlice'
import { getGenderName } from '../../../models/user'

type action = 'edit' | 'print'
interface TreatmentBoxListProps {
  sfFlag?: any
  historyMessageTag?: any
  status?: any //1口腔
  tag?: any //执行点击进入按钮隐藏默认展开
  page: Page<Treatment>
  treatmentData?: any
  recipeCategory?: RecipeCategory
  showDiagnosis?: boolean
  exact?: boolean
  onCopy?: (params: CopyTreatmentParams | CopyRecipeParams, tip?: any, flag?: number, diease?: any) => void
  onOk?: (v: any) => void
  type?: 'check' | 'radio' | undefined
  showOpen?: any
  toothAction?: (action: action, t: any) => void // 口腔专业吧
}
// recipeCatagory

export const TreatmentBoxList = ({
  sfFlag,
  historyMessageTag,
  status,
  tag = false,
  page,
  treatmentData,
  recipeCategory,
  exact = true,
  showDiagnosis = true,
  onCopy,
  onOk,
  type = undefined,
  toothAction,
}: TreatmentBoxListProps): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const { Group } = type == 'check' ? Checkbox : Radio

  const theme = useContext(ThemeContext)

  const [diagnosisForm] = Form.useForm()

  const { Item } = Form

  const recipes = useSelector(selectRecipes)

  const materials = useSelector(selectMaterials)

  const [checkDataId, setCheckDataId] = useState<any>()

  const [isImageOrFile, setIsImageOrFile] = useState(false)

  const [isLookPicModalVisible, setIsLookPicModalVisible] = useState(false)

  const [lookPic, setLookPic] = useState('')

  // const [isModalVisible, setIsModalVisible] = useState(false)   //医美病历弹窗

  // const EditorRef = useRef<any>(null)   //医美病历

  useEffect(() => {
    const name = page.items?.filter((v: any) => {
      return v?.id == checkDataId
    })
    onOk && onOk(name)
  }, [page.items, checkDataId])

  const [showYa, setShowYa] = useState(false)

  const [tooth, setTooth] = useState([
    [{ teethStr: ['', '', '', ''], modalShow: false, Check: '' }],
    [{ teethStr: ['', '', '', ''], modalShow: false, Check: '' }],
    [{ teethStr: ['', '', '', ''], modalShow: false, Check: '' }],
    [{ teethStr: ['', '', '', ''], modalShow: false, Check: '' }],
    [{ teethStr: ['', '', '', ''], modalShow: false, Check: '' }],
  ])

  const [toothArray, setToothArray] = useState<any>([])

  const [current, setCurrent] = useState(-1)

  useEffect(() => {
    setCheckDataId(treatmentData?.id || undefined)
  }, [page, treatmentData])

  useEffect(() => {
    diagnosisForm.setFieldsValue({
      stomatologyTooth: [''],
      inspectionTooth: [''],
      diseaseTooth: [''],
      treatmentTooth: [''],
      disposeTooth: [''],
    })
  }, [])

  const [fileListArr, setFileListArr] = useState<any>([])

  const [fileLists, setFileLists] = useState<any>([])

  const [imgSrc, setImgSrc] = useState('') //预览图片路径

  const [visible, setVisible] = useState(false) //预览图片是否显示
  useEffect(() => {
    toothArray?.length &&
      current >= 0 &&
      current <= toothArray?.length &&
      diagnosisForm.setFieldsValue({
        stomatologyTooth: toothArray[current]?.[0]?.map((v: any) => {
          return {
            stomatologyCheck: v.Check,
          }
        }),
        inspectionTooth: toothArray[current]?.[1]?.map((v: any) => {
          return {
            inspectionCheck: v.Check,
          }
        }),
        diseaseTooth: toothArray[current]?.[2]?.map((v: any) => {
          return {
            diseases: v.Check,
          }
        }),
        treatmentTooth: toothArray[current]?.[3]?.map((v: any) => {
          return {
            treatmentPlan: v.Check,
          }
        }),
        disposeTooth: toothArray[current]?.[4]?.map((v: any) => {
          return { disposeContent: v.Check }
        }),
      })
  }, [toothArray, current])

  useEffect(() => {
    const data = fileListArr?.map((v: any) => {
      return v?.split(',')?.map((v: any) => {
        return ({
          name: v?.split('@')[0],
          uid: v?.split('@')[1],
          url: v?.split('@')[2],
        } as unknown) as UploadFile
      })
    })

    setFileLists(data)
  }, [fileListArr])

  useEffect(() => {
    tag &&
      page &&
      Object.keys(page).length &&
      dispatch(getRecipeList(page?.items?.[0]?.id))
  }, [tag, page])

  useEffect(() => {
    setCurrent(-1)
    setFileListArr(
      page?.items?.map((v: any) => {
        return v.coherentVideo
      })
    )
    // treatment.coherentVideo
    //   ? setFileList(
    //       treatment.coherentVideo.split(',').map((v) => {
    //         return ({
    //           name: v.split('@')[0],
    //           uid: v.split('@')[1],
    //           url: v.split('@')[2],
    //         } as unknown) as UploadFile
    //       })
    //     )
    //   : setFileList([])

    const data = page?.items?.map((el: any, index: number) => {
      return el.id
        ? tooth?.map((v, i) => {
            if (i === 0) {
              return page?.items[index]?.stomatologyTooth
                ?.split('#')
                .map((item, indexItem) => {
                  return {
                    teethStr: item?.split('@')?.map((current) => {
                      return current === '--' ? '' : current
                    }),
                    modalShow: false,
                    Check: page?.items[index]?.stomatologyCheck
                      ?.split('#%#')
                      ?.map((v) => {
                        return v
                      })[indexItem],
                  }
                }) as any
              // return v;
            } else if (i === 1) {
              return page?.items[index]?.inspectionTooth
                ?.split('#')
                ?.map((item, indexItem) => {
                  return {
                    teethStr: item.split('@')?.map((current) => {
                      return current === '--' ? '' : current
                    }),
                    modalShow: false,
                    Check: page?.items[index]?.inspectionCheck
                      ?.split('#%#')
                      ?.map((v) => {
                        return v
                      })[indexItem],
                  }
                }) as any
            } else if (i === 2) {
              return page?.items[index]?.diseaseTooth
                ?.split('#')
                .map((item, indexItem) => {
                  return {
                    teethStr: item?.split('@')?.map((current) => {
                      return current === '--' ? '' : current
                    }),
                    modalShow: false,
                    Check: page?.items[index]?.disease?.split('#')?.map((v) => {
                      return v
                    })[indexItem],
                  }
                }) as any
            } else if (i === 3) {
              return page?.items[index]?.treatmentTooth
                ?.split('#')
                .map((item, indexItem) => {
                  return {
                    teethStr: item?.split('@')?.map((current) => {
                      return current === '--' ? '' : current
                    }),
                    modalShow: false,
                    Check: page?.items[index]?.treatmentPlan
                      ?.split('#%#')
                      ?.map((v) => {
                        return v
                      })[indexItem],
                  }
                }) as any
            } else if (i === 4) {
              return page?.items[index]?.disposeTooth
                ?.split('#')
                ?.map((item, indexItem) => {
                  return {
                    teethStr: item.split('@')?.map((current) => {
                      return current === '--' ? '' : current
                    }),
                    modalShow: false,
                    Check: page.items[index]?.disposeContent
                      ?.split('#%#')
                      ?.map((v) => {
                        return v
                      })[indexItem],
                  }
                }) as any
            }
          })
        : ''
    })
    setToothArray(data)
  }, [page])

  const getTeethStr = (v: string) => {
    const teethStr = v.substring(1)
    return teethStr.replace('-', ',')
  }

  const renderTeeth = (
    arrayIndex: number,
    toothIndex: number,
    teethIndex: number,
    i: number
  ) => {
    if (i % 2) {
      return toothArray[arrayIndex][toothIndex][teethIndex]?.teethStr[i]
        ?.split(',')
        ?.map((v: any) => {
          if (v?.length !== 1) {
            return v ? (
              <>
                {v[0]}
                <sup>{getTeethStr(v)}</sup>
              </>
            ) : (
              ''
            )
          } else {
            return v
          }
        })
    } else {
      const data = toothArray[arrayIndex][toothIndex][teethIndex]?.teethStr[i]
        ?.split(',')
        .sort(function (a: any, b: any) {
          return b.substr(0, 1) - a.substr(0, 1)
        })
      const letters: any = data?.filter((element: any) => isNaN(element)) // 过滤出字母

      letters?.reverse() // 反转字母数组顺序
      const newData = data?.map((element: any) =>
        isNaN(element) ? letters?.shift() : element
      ) // 将反转后的字母按顺序放回原数组

      return newData?.map((v: any) => {
        if (v?.length > 1) {
          return v ? (
            <>
              {v[0]}
              <sup>{getTeethStr(v)}</sup>
            </>
          ) : (
            ''
          )
        } else {
          return v
        }
      })
    }
  }

  const getTreatmentName = (type: number): string => {
    switch (type) {
      case 1:
        return '通用病历'
      case 2:
        return '中医病历'
      case 3:
        return '口腔病历'
      default:
        return '通用病历'
    }
  }

  const isImage = (name: string) => {
    const patterns = /(\.jpg|\.jpeg|\.png|\.heic|\.bmp|\.tiff|\.webp)$/i;
    return patterns.test(name);
  };

  return (
    <Group
      className={styles.content}
      onChange={(v: any) => {
        if (type == 'check') {
          return
        } else if (type == 'radio') {
          setCheckDataId(v?.target.value)
        }
      }}
      value={checkDataId}
    >
      <>
        {/* <Editor ref={EditorRef} /> */}
        {page?.items?.map((t, i) => {
          const rs = recipes[t.id]

          return (
            <Col
              key={t.id}
              onClick={() => {
                setCurrent(i)
                if (type == 'check') {
                  return
                } else if (type == 'radio') {
                  setCheckDataId(t.id)
                }
              }}
            >
              <Row
                style={{
                  height: '3rem',
                  border: `solid 1px ${theme.wbd}`,
                  color: theme.tc1,
                  fontSize: '1rem',
                  padding: '0 10px',
                  whiteSpace: 'nowrap',
                  marginTop: i ? 10 : 0,
                }}
                onClick={(v) => {
                  if (rs) {
                    dispatch(openTreatment({ id: t.id, open: !rs.open }))
                  } else {
                    dispatch(getRecipeList(t.id))
                  }
                }}
                align='middle'
                wrap={false}
                justify='space-between'
              >
                <div
                  onClick={(e) => {
                    e.stopPropagation()
                  }}
                >
                  {type ? (
                    type == 'check' ? (
                      <Checkbox value={t.id} style={{ marginRight: '8px' }} />
                    ) : (
                      <Radio value={t.id} style={{ marginRight: '8px' }} />
                    )
                  ) : (
                    ''
                  )}
                </div>
                {t.appointmentId && t.appointmentId != -1 && (
                  <AppointmentIcon
                    style={{
                      marginRight: '5px',
                      width: '18px',
                      height: '18px',
                    }}
                  />
                )}
                <Col>{toothAction ? t?.registrationTime : t.createTime}</Col>
                <Col style={{ marginLeft: 5 }}>{t.patientName}</Col>
                {!toothAction && (
                  <>
                    <Col style={{ marginLeft: 5 }}>
                      {getGenderName(t.patientSex)}
                    </Col>
                    <Col style={{ marginLeft: 5 }}>{t.patientAge + '岁'}</Col>
                  </>
                )}
                <Col style={{ marginLeft: 10 }}>{t.treatmentDoctorName}</Col>
                {(toothAction || status == 1) && (
                  <Col style={{ marginLeft: 5 }}>
                    {t.insuranceCode == 'ZIFEI'
                      ? '自费'
                      : t.insuranceCode == '' || t.insuranceCode == null
                      ? '未知险种'
                      : '医保刷卡'}
                  </Col>
                )}
                <Col style={{ marginLeft: 5 }}>
                  （{t.treatmentDepartmentName}）
                </Col>
                {!toothAction && (
                  <Col style={{ marginLeft: 5, color: theme.c1 }}>
                    （
                    {t.medicalBeautyItem?.length
                      ? '医美病历'
                      : getTreatmentName(t.medicalRecord)}
                    ）
                  </Col>
                )}
                {toothAction && (
                  <Col style={{ marginLeft: 5 }}>
                    {t.receiveTag == 0 ? '初诊' : '复诊'}
                  </Col>
                )}
                <Col
                  style={{
                    marginLeft: 10,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    color: theme.c1,
                    fontWeight: 'bold',
                  }}
                  flex={1}
                >
                  {t.disease}
                </Col>

                {toothAction && (
                  <>
                    <Col style={{ marginLeft: 10 }}>
                      <Button
                        type='link'
                        size='small'
                        onClick={(e) => {
                          e.stopPropagation()
                          toothAction('edit', t)
                        }}
                      >
                        修改
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        type='link'
                        size='small'
                        onClick={(e) => {
                          e.stopPropagation()
                          toothAction('print', t)
                        }}
                      >
                        打印
                      </Button>
                    </Col>
                  </>
                )}

                {showDiagnosis && onCopy && !type && (
                  <>
                    <Col style={{ marginLeft: 10 }}>
                      <Button
                        style={{ fontSize: '1rem' }}
                        onClick={() =>
                          onCopy &&
                          onCopy(
                            {
                              copyRecipe: true,
                              srcTreatmentId: t.id,
                            },
                            t?.tip
                          )
                        }
                      >
                        复制诊断信息及处方
                      </Button>
                    </Col>
                    <Col style={{ marginLeft: 10 }}>
                      <Button
                        type='primary'
                        style={{ fontSize: '1rem' }}
                        onClick={() => {
                          onCopy &&
                            onCopy(
                              {
                                copyRecipe: false,
                                srcTreatmentId: t.id,
                              },
                              t?.tip
                            )
                        }}
                      >
                        复制诊断信息
                      </Button>
                    </Col>
                  </>
                )}

                {!tag && (
                  <Col style={{ marginLeft: 10, cursor: 'pointer' }}>
                    {
                      // 医美病历图标朝上
                      t?.medicalBeautyItem?.length ? (
                        <CaretUpFilled style={{ color: '#999999' }} />
                      ) : rs?.open ? (
                        <CaretUpFilled style={{ color: '#999999' }} />
                      ) : (
                        <CaretDownFilled style={{ color: '#999999' }} />
                      )
                    }
                  </Col>
                )}
              </Row>
              {/* t.medicalBeautyItem.length 医美病历不展示下面内容*/}
              {!t?.medicalBeautyItem?.length && (rs?.open || tag) && (
                <Row>
                  <Col
                    style={{
                      alignItems: 'stretch',
                      borderLeft: `solid 1px ${theme.wbd}`,
                      borderRight: `solid 1px ${theme.wbd}`,
                      borderBottom: `solid 1px ${theme.wbd}`,
                      paddingTop: 20,
                      paddingBottom: 10,
                    }}
                    flex={1}
                  >
                    {showDiagnosis && (
                      <div
                        style={{
                          marginLeft: 10,
                          color: theme.tc2,
                          fontSize: '1rem',
                        }}
                      >
                        <div>主诉：{t.chiefComplaint}</div>
                        <div>
                          现病史：
                          {t.medicalHistory || t.historyOfPresentIllness}
                        </div>
                        <div>既往史：{t.pastMedicalHistory}</div>
                        <div>过敏史：{t.allergen}</div>
                        {t.medicalRecord == 2 && (
                          <>
                            <div>脉象：{t.pulseCondition}</div>
                            <div>舌象：{t.tonguePicture}</div>
                          </>
                        )}
                        <div>疾病名称：{t.disease}</div>
                        <div>体格检查：
                          {t?.patientTemperature && t?.patientTemperature + '℃，'}
                          {t?.patientSystolicPressure && t?.patientSystolicPressure + '/'}
                          {t?.patientDiastolicPressure && t?.patientDiastolicPressure + 'mmHg，'}
                          {t?.patientPulse && t?.patientPulse + '次/分，'}
                          {t?.patientHeight && t?.patientHeight + 'cm，'}
                          {t?.patientWeight && t?.patientWeight + 'kg，'}
                          {t?.patientWeight && t?.patientHeight ? 
                            'BMI:' + ((t?.patientWeight /
                                  (t?.patientHeight * t?.patientHeight)) *
                                10000
                              ).toFixed(1) + ','
                              : ''}
                         {t.physicalExamination}</div>
                        <div>检验检查：{t.inspectionCheck}</div>
                        <div>治疗意见：{t.treatmentConclusion}</div>
                      </div>
                    )}
                    {t.medicalRecord == 3 &&
                      (t.diseaseTooth ||
                        t.disposeTooth ||
                        t.inspectionTooth ||
                        t.stomatologyTooth ||
                        t.treatmentTooth) && (
                        <>
                          {/* <Row
                          style={{
                            marginTop: 20,
                            marginLeft: 10,
                            marginRight: 10,
                            padding: '0 10px',
                            height: '2.5rem',
                            backgroundColor: theme.th,
                          }}
                          onClick={() => {
                            setShowYa(!showYa)
                          }}
                          align='middle'
                        >
                          <Col
                            flex={1}
                            style={{ color: theme.tc1, fontSize: '1rem' }}
                          >
                            查看牙位图
                          </Col>
                          <Col>
                            {showYa && current === i ? (
                              <CaretUpFilled />
                            ) : (
                              <CaretDownFilled />
                            )}
                          </Col>
                        </Row> */}
                          {current === i && (
                            <>
                              <Form
                                form={diagnosisForm}
                                colon={false}
                                className={historyMessageTag ==1 ?styles.infos : styles.info}
                                autoComplete='off'
                                labelCol={{
                                  flex: '6.5rem',
                                }}
                                style={{ marginRight: 30 }}
                              >
                                <div className={styles.itemList}>
                                  <Form.List name='stomatologyTooth'>
                                    {(fields, { add, remove }) => (
                                      <Item label='口腔检查'>
                                        {fields?.map((field, index) => (
                                          <Space
                                            key={field.key}
                                            align='baseline'
                                            style={{
                                              display: 'flex',
                                              alignItems: 'center',
                                              width: '100%',
                                            }}
                                          >
                                            <Item
                                              noStyle
                                              shouldUpdate={(
                                                prevValues,
                                                curValues
                                              ) =>
                                                prevValues.area !==
                                                  curValues.area ||
                                                prevValues.sights !==
                                                  curValues.sights
                                              }
                                            >
                                              {() => (
                                                <Item required>
                                                  <ToothTable
                                                    LT={renderTeeth(
                                                      i,
                                                      0,
                                                      index,
                                                      0
                                                    )}
                                                    RT={renderTeeth(
                                                      i,
                                                      0,
                                                      index,
                                                      1
                                                    )}
                                                    LB={renderTeeth(
                                                      i,
                                                      0,
                                                      index,
                                                      2
                                                    )}
                                                    RB={renderTeeth(
                                                      i,
                                                      0,
                                                      index,
                                                      3
                                                    )}
                                                  />
                                                </Item>
                                              )}
                                            </Item>
                                            <Item
                                              name={[
                                                field.name,
                                                'stomatologyCheck',
                                              ]}
                                              style={{ width: '200%' }}
                                            >
                                              <Input.TextArea
                                                readOnly
                                                autoSize
                                                style={{ width: '420px' }}
                                              />
                                            </Item>
                                          </Space>
                                        ))}
                                      </Item>
                                    )}
                                  </Form.List>
                                  <Form.List name='inspectionTooth'>
                                    {(fields, { add, remove }) => (
                                      <Item label='检验检查'>
                                        {fields?.map((field, index) => (
                                          <Space
                                            key={field.key}
                                            align='baseline'
                                            style={{
                                              display: 'flex',
                                              alignItems: 'center',
                                            }}
                                          >
                                            <Item
                                              noStyle
                                              shouldUpdate={(
                                                prevValues,
                                                curValues
                                              ) =>
                                                prevValues.area !==
                                                  curValues.area ||
                                                prevValues.sights !==
                                                  curValues.sights
                                              }
                                            >
                                              {() => (
                                                <Item required>
                                                  <ToothTable
                                                    LT={renderTeeth(
                                                      i,
                                                      1,
                                                      index,
                                                      0
                                                    )}
                                                    RT={renderTeeth(
                                                      i,
                                                      1,
                                                      index,
                                                      1
                                                    )}
                                                    LB={renderTeeth(
                                                      i,
                                                      1,
                                                      index,
                                                      2
                                                    )}
                                                    RB={renderTeeth(
                                                      i,
                                                      1,
                                                      index,
                                                      3
                                                    )}
                                                  />
                                                </Item>
                                              )}
                                            </Item>
                                            <Item
                                              name={[
                                                field.name,
                                                'inspectionCheck',
                                              ]}
                                              style={{ width: '200%' }}
                                            >
                                              <Input.TextArea
                                                readOnly
                                                autoSize
                                                style={{ width: '420px' }}
                                              />
                                            </Item>
                                          </Space>
                                        ))}
                                      </Item>
                                    )}
                                  </Form.List>
                                  <Item label='相关影像'>
                                    <FileUpload
                                      count={fileLists?.[i]?.length || 0}
                                      files={fileLists?.[i]}
                                      // onUpload={(v, i) => {
                                      //   setFileList([
                                      //     ...fileList,
                                      //     {
                                      //       uid: v.uid,
                                      //       size: v.size,
                                      //       name: v.name,
                                      //       type: v.type,
                                      //       originFileObj: {} as RcFile,
                                      //       url: i,
                                      //     },
                                      //   ])
                                      // }}
                                    >
                                      {/* <Button icon={<PlusOutlined />}>上传附件</Button> */}
                                    </FileUpload>
                                    {fileLists[i]?.some((v: any) => v.url) &&
                                      fileLists[i]?.map((item: any) => (
                                        <Row
                                          gutter={24}
                                          key={item.uid}
                                          style={{
                                            padding: '10px',
                                            width: '300px',
                                          }}
                                        >
                                          <Col flex={1}>
                                            <span
                                              style={{
                                                display: 'inline-block',
                                                maxWidth: '60px',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                verticalAlign: 'middle',
                                              }}
                                            >
                                              {item.name.split('.')[0]}
                                            </span>
                                            <span
                                              style={{
                                                verticalAlign: 'middle',
                                              }}
                                            >
                                              .{item.name.split('.')[1]}
                                            </span>
                                          </Col>
                                          <Col span={6}>
                                            <a
                                              onClick={() => {
                                                if (isImage(item.name)) {
                                                  setIsImageOrFile(true)
                                                } else {
                                                  setIsImageOrFile(false)
                                                }
                                                dispatch(
                                                  getUrl({ fileName: item.url })
                                                )
                                                  .then(unwrapResult)
                                                  .then((v: any) => {
                                                    const proxy =
                                                      window.location.origin.indexOf(
                                                        'his.develop.hydhis.com'
                                                      ) >= 0
                                                        ? '/proxyToOssDevPrivate'
                                                        : window.location.origin.indexOf(
                                                            'his.test.hydhis.com'
                                                          ) >= 0
                                                        ? '/proxyToOssTestPrivate'
                                                        : window.location.origin.indexOf(
                                                            'hydhis.cn'
                                                          ) >= 0
                                                        ? '/proxyToOssProdPrivate'
                                                        : '/proxyToOssProdPrivate'
                                                        const url = (window.location.origin?.includes('localhost')
                                                        ? 'http://his.develop.hydhis.com'
                                                        : window.location.origin) + proxy + v
                                                    setLookPic(url)
                                                    setIsLookPicModalVisible(
                                                      true
                                                    )
                                                    // window.open(v, '_blank')
                                                  })
                                              }}
                                            >
                                              查看
                                            </a>
                                          </Col>
                                          {/* <Col span={6}>
                                          <CloseOutlined
                                            onClick={() => {
                                              //  e.stopPropagation();
                                              setFileList(() =>
                                                fileList.filter((ite) => ite.uid !== item.uid)
                                              )
                                            }}
                                          />
                                        </Col> */}
                                        </Row>
                                      ))}
                                  </Item>
                                  <Form.List name='diseaseTooth'>
                                    {(fields, { add, remove }) => (
                                      <Item label='口腔诊断' required>
                                        {fields?.map((field: any, index) => (
                                          <>
                                            <Space
                                              key={field.key}
                                              align='baseline'
                                              style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                              }}
                                            >
                                              <Item
                                                noStyle
                                                shouldUpdate={(
                                                  prevValues,
                                                  curValues
                                                ) =>
                                                  prevValues.area !==
                                                    curValues.area ||
                                                  prevValues.sights !==
                                                    curValues.sights
                                                }
                                              >
                                                {() => (
                                                  <Item required>
                                                    <ToothTable
                                                      LT={renderTeeth(
                                                        i,
                                                        2,
                                                        index,
                                                        0
                                                      )}
                                                      RT={renderTeeth(
                                                        i,
                                                        2,
                                                        index,
                                                        1
                                                      )}
                                                      LB={renderTeeth(
                                                        i,
                                                        2,
                                                        index,
                                                        2
                                                      )}
                                                      RB={renderTeeth(
                                                        i,
                                                        2,
                                                        index,
                                                        3
                                                      )}
                                                    />
                                                  </Item>
                                                )}
                                              </Item>
                                              <Item
                                                name={[field.name, 'diseases']}
                                                // fieldKey={[field.fieldKey, 'last']}
                                                style={{ width: '200%' }}
                                              >
                                                <Input.TextArea
                                                  readOnly
                                                  autoSize
                                                  style={{ width: '420px' }}
                                                />
                                              </Item>
                                            </Space>
                                            <Col flex='7.125rem' />
                                          </>
                                        ))}
                                      </Item>
                                    )}
                                  </Form.List>
                                  <Form.List name='treatmentTooth'>
                                    {(fields, { add, remove }) => (
                                      <Item label='治疗计划'>
                                        {fields?.map((field, index) => (
                                          <Space
                                            key={field.key}
                                            align='baseline'
                                            style={{
                                              display: 'flex',
                                              alignItems: 'center',
                                            }}
                                          >
                                            <Item
                                              noStyle
                                              shouldUpdate={(
                                                prevValues,
                                                curValues
                                              ) =>
                                                prevValues.area !==
                                                  curValues.area ||
                                                prevValues.sights !==
                                                  curValues.sights
                                              }
                                            >
                                              {() => (
                                                <Item required>
                                                  <ToothTable
                                                    LT={renderTeeth(
                                                      i,
                                                      3,
                                                      index,
                                                      0
                                                    )}
                                                    RT={renderTeeth(
                                                      i,
                                                      3,
                                                      index,
                                                      1
                                                    )}
                                                    LB={renderTeeth(
                                                      i,
                                                      3,
                                                      index,
                                                      2
                                                    )}
                                                    RB={renderTeeth(
                                                      i,
                                                      3,
                                                      index,
                                                      3
                                                    )}
                                                  />
                                                </Item>
                                              )}
                                            </Item>
                                            <Item
                                              name={[
                                                field.name,
                                                'treatmentPlan',
                                              ]}
                                              style={{ width: '200%' }}
                                            >
                                              <Input.TextArea
                                                readOnly
                                                autoSize
                                                style={{ width: '420px' }}
                                              />
                                            </Item>
                                          </Space>
                                        ))}
                                      </Item>
                                    )}
                                  </Form.List>
                                  <Form.List name='disposeTooth'>
                                    {(fields, { add, remove }) => (
                                      <Item label='处置'>
                                        {fields?.map((field, index) => (
                                          <Space
                                            key={field.key}
                                            align='baseline'
                                            style={{
                                              display: 'flex',
                                              alignItems: 'center',
                                            }}
                                          >
                                            <Item
                                              noStyle
                                              shouldUpdate={(
                                                prevValues,
                                                curValues
                                              ) =>
                                                prevValues.area !==
                                                  curValues.area ||
                                                prevValues.sights !==
                                                  curValues.sights
                                              }
                                            >
                                              {() => (
                                                <Item required>
                                                  <ToothTable
                                                    LT={renderTeeth(
                                                      i,
                                                      4,
                                                      index,
                                                      0
                                                    )}
                                                    RT={renderTeeth(
                                                      i,
                                                      4,
                                                      index,
                                                      1
                                                    )}
                                                    LB={renderTeeth(
                                                      i,
                                                      4,
                                                      index,
                                                      2
                                                    )}
                                                    RB={renderTeeth(
                                                      i,
                                                      4,
                                                      index,
                                                      3
                                                    )}
                                                  />
                                                </Item>
                                              )}
                                            </Item>
                                            <Item
                                              style={{ width: '200%' }}
                                              name={[
                                                field.name,
                                                'disposeContent',
                                              ]}
                                            >
                                              <Input.TextArea
                                                readOnly
                                                autoSize
                                                style={{ width: '420px' }}
                                              />
                                            </Item>
                                          </Space>
                                        ))}
                                      </Item>
                                    )}
                                  </Form.List>
                                </div>
                              </Form>
                            </>
                          )}
                        </>
                      )}
                    {rs?.recipes
                      ?.filter((r) => {
                        if (recipeCategory === undefined) {
                          return true
                        }
                        if (exact) {
                          return recipeCategory === r.category
                        }
                        if (recipeCategory === RecipeCategory.Medicine) {
                          return !isTcmRecipeCategory(r.category)
                        }
                        return isTcmRecipeCategory(r.category)
                      })
                      ?.map((r) => {
                        const ms = materials[r.id]
                        const isReturnedFlag = r.state === RecipeStatus.Returned
                        const isToSettleFlag = r.state === RecipeStatus.ToSettle

                        return (
                          <>
                            <Row
                              key={r.id}
                              style={{
                                marginTop: 20,
                                marginLeft: 10,
                                marginRight: 10,
                                padding: "0 10px",
                                height: "2.5rem",
                                backgroundColor: theme.th,
                              }}
                              onClick={() => {
                                if (ms) {
                                  dispatch(
                                    openMaterial({ id: r.id, open: !ms.open })
                                  );
                                } else {
                                  dispatch(
                                    getMaterialList({
                                      recipeId: r.id,
                                      size: 100,
                                    })
                                    // getMaterialList(r.id)
                                  );
                                }
                              }}
                              align="middle"
                            >
                              <Col
                                flex={1}
                                style={{ color: theme.tc1, fontSize: "1rem" }}
                              >
                                {getRecipeCategoryName(r.category)}
                                处方单，处方编号：
                                {r.id}
                                {/* {isReturnedFlag
                                  ? isReturnedFlag && '（' + '已退费' + '）'
                                  : isToSettleFlag
                                  ? isToSettleFlag && '（' + '未结算' + '）'
                                  : ''} */}
                                {r?.state === RecipeStatus.Returned &&
                                  "（已退费）"}
                                {r?.state === RecipeStatus.ToSettle &&
                                  "（未结算）"}
                                {r?.state === RecipeStatus.Unknown &&
                                  "（未结算）"}
                                {r?.state === RecipeStatus.Settled &&
                                  "（已结算）"}
                              </Col>
                              {!showDiagnosis && (
                                <Col style={{ marginRight: 20 }}>
                                  {!exact && t.disease && (
                                    <Button
                                      style={{ marginRight: 20 }}
                                      onClick={() =>
                                        onCopy &&
                                        onCopy(
                                          {
                                            srcRecipeId: r.id,
                                          } as CopyRecipeParams,
                                          r?.tip,
                                          1,
                                          t.disease
                                        )
                                      }
                                    >
                                      复制诊断及处方
                                    </Button>
                                  )}
                                  <Button
                                    onClick={() =>
                                      onCopy &&
                                      onCopy(
                                        {
                                          srcRecipeId: r.id,
                                        } as CopyRecipeParams,
                                        r?.tip
                                      )
                                    }
                                  >
                                    复制处方
                                  </Button>
                                </Col>
                              )}
                              <Col>
                                <a
                                  style={{ fontSize: "1rem", marginRight: 10 }}
                                >
                                  单击查看处方信息
                                </a>
                              </Col>
                              <Col>
                                {/* {ms?.open ? (
                                  <CaretUpFilled />
                                ) : (
                                  <CaretDownFilled />
                                )} */}
                              </Col>
                            </Row>
                            {ms?.open && (
                              <Table
                                className={
                                  sfFlag ? styles.sfTable : styles.table
                                }
                                style={{ margin: '0 10px' }}
                                pagination={false}
                                size='small'
                                bordered
                                columns={
                                  r.category === RecipeCategory.Tcm ||
                                  r.category === RecipeCategory.TcmGranules
                                    ? TcmColumns(theme)
                                    : (styles.sfTable
                                        ? MedicineSFMaterialColumns
                                        : MedicineMaterialColumns)(
                                        theme,
                                        ms.materials
                                      )
                                }
                                dataSource={ms.materials}
                              />
                            )}
                          </>
                        );
                      })}
                  </Col>
                </Row>
              )}
              {t?.medicalBeautyItem?.length && (
                <Row>
                  <Col
                    style={{
                      alignItems: 'stretch',
                      borderLeft: `solid 1px ${theme.wbd}`,
                      borderRight: `solid 1px ${theme.wbd}`,
                      borderBottom: `solid 1px ${theme.wbd}`,
                      paddingTop: 20,
                      paddingBottom: 10,
                    }}
                    flex={1}
                  >
                    <Space
                      style={{
                        display: 'flex',
                        marginLeft: 10,
                        color: theme.tc2,
                        fontSize: '1rem',
                      }}
                    >
                      {t.medicalBeautyItem.map((item: any) => {
                        return (
                          <>
                            <Button
                              type='primary'
                              key={item.id}
                              onClick={() => {
                                if (item.category == 3) {
                                  setImgSrc('')
                                  item.coherentVideo
                                    .split(',')
                                    .map((v: any) => {
                                      dispatch(
                                        getUrl({ fileName: v.split('@')[2] })
                                      ) //获取图片路径
                                        .then(unwrapResult)
                                        .then((v: any) => {
                                          const proxy =
                                            window.location.origin.indexOf(
                                              'his.develop.hydhis.com'
                                            ) >= 0
                                              ? '/proxyToOssDevPrivate'
                                              : window.location.origin.indexOf(
                                                  'his.test.hydhis.com'
                                                ) >= 0
                                              ? '/proxyToOssTestPrivate'
                                              : window.location.origin.indexOf(
                                                  'hydhis.cn'
                                                ) >= 0
                                              ? '/proxyToOssProdPrivate'
                                              : '/proxyToOssProdPrivate'
                                          const url =
                                          (window.location.origin?.includes(
                                            'localhost'
                                          )
                                            ? 'http://his.develop.hydhis.com'
                                            : window.location.origin) + proxy + v
                                          setImgSrc(url)
                                          setVisible(true)
                                        })
                                    })
                                } else if (item.category == 1) {
                                  dispatch(
                                    traceRoute({
                                      name: '医生门诊',
                                      path: '/treatment',
                                      query: `registrationId=${t.registrationId}&id=${item.id}`,
                                    })
                                  )
                                }
                              }}
                            >
                              {item.name}
                            </Button>
                          </>
                        )
                      })}
                    </Space>
                  </Col>
                </Row>
              )}
            </Col>
          )
        })}
        {/* 图片预览 */}
        <Image
          style={{ display: 'none' }}
          src={imgSrc}
          preview={{
            visible,
            onVisibleChange: (value) => {
              setVisible(value)
            },
          }}
          fallback={
            'http://test-private-hydhis.oss-cn-hangzhou.aliyuncs.com/upload/2/20220818/L-8f-TKxpo0NoPYYHsk-0.png?Expires=1661226695&OSSAccessKeyId=LTAI5t6mhKfED99uJVPcuHwc&Signature=%2FZNcm4RxoQ%2FBNsh9ItkQiZ69w7Q%3D '
          }
          onError={(event) => {
            return
          }}
        />

        <Modal
          width={800}
          footer={null}
          title=''
          visible={isLookPicModalVisible}
          onOk={(v) => {
            setIsLookPicModalVisible(false)
          }}
          onCancel={(v) => {
            setIsLookPicModalVisible(false)
          }}
        >
          <div
            style={{
              maxHeight: '600px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              marginTop: '40px',
            }}
          >
            {isImageOrFile ? <img src={lookPic} style={{ width: '500px' }}></img>
            : <span style={{fontSize: '16px'}}>该文件类型暂不支持预览</span>}
            <a
              style={{ marginTop: '20px' }}
              // href={lookPic}
              onClick={(e) => {
                e.preventDefault()
                let link = ''
                const proxy =
                  window.location.origin.indexOf("his.develop.hydhis.com") >= 0
                    ? "/proxyToOssDevPrivate"
                    : window.location.origin.indexOf("his.test.hydhis.com") >= 0
                    ? "/proxyToOssTestPrivate"
                    : window.location.origin.indexOf("hydhis.cn") >= 0
                    ? "/proxyToOssProdPrivate"
                    : "/proxyToOssProdPrivate";
                const uploadIndex = lookPic.indexOf("/upload");
                if (uploadIndex !== -1) {
                  link = lookPic.substring(uploadIndex);
                }
                link = window.location.origin + proxy + link;
                console.log(link);
                window.open(link, "_blank");
                setIsLookPicModalVisible(false);
                // e.preventDefault()
                // window.open(lookPic, '_blank')
              }}
            >
              下载
            </a>
          </div>
        </Modal>
        {/* <Modal
          className={styles.history}
          width={1200}
          style={{ height: 720, maxHeight: 720, overflow: 'hidden' }}
          title='历史就诊信息'
          visible={isModalVisible}
          footer={null}
          onCancel={() => setIsModalVisible(false)}
          destroyOnClose
        >
          <Editor ref={EditorRef} />
        </Modal> */}
      </>
    </Group>
  )
}
