/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-11-14 15:40:21
 * @LastEditors: linxi
 * @LastEditTime: 2024-10-31 14:55:27
 */
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  RollbackOutlined,
} from '@ant-design/icons'
import { Button, Col, Rate, Row, Space, Tabs, Typography } from 'antd'
import React, { ReactElement, useEffect, useRef, useState } from 'react'
import {
  AppointIcon,
  BoyIcon,
  FemaleFilled,
  GirlIcon,
  GroupIcon,
  MaleFilled,
  MessageIcon,
  RegistrationIcon,
} from '../../../../compnents/icons/Icons'
import { RecipeCategory } from '../../../../models/recipe'
import { Gender } from '../../../../models/user'
import { FollowUpTab } from '../../../followUp/followUpTab'
import { OperationStation } from '../../../operation/operation'
import AddCurePlanModal from '../../../treatment/editor/modals/AddCurePlanModal'
import { ChargePage } from '../../toothHome/chargePage/chargePage'
import {
  CurePlanListModal,
  CurePlanListRefProps,
} from '../../../treatment/editor/modals/curePlanListModal'
import { ImageInformation } from '../../../imageInformation/imageInformation'
import { RecipeEditorMode } from '../../../treatment/editor/RecipeEditor'
import { Dispose } from './dispose/dispose'
import { MedicalInfo } from './medicalInfo/medicalInfo'
import { OutpatientInfo } from './outpatientInfo/outpatientInfo'
import { Outside } from './outside/outside'
import { PatientInfo } from './patientInfo/patientInfo'
import styles from './patientPanel.module.css'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../../../app/store'
import {
  getChangetreatmentAsync,
  getPatientGroupAsync,
  selectPatientInfo,
  selectPatientManagementStatus,
  setGroupList,
  setPatientInfo,
} from '../patientSlice'
import { unwrapResult } from '@reduxjs/toolkit'
import { setAddCurePlanModalVisible } from '../../../treatment/editor/recipeEditorSlice'
import { PatientListItem } from '../../../../models/toothPatient'
import { SendMessageModal } from '../../toothHome/modal/sendMessageModal'
import { SubscribeModal } from '../../toothHome/modal/subscribeModal'
import { RegisteredModal } from '../../toothHome/modal/registeredModal'
import { PatientGroupModal } from '../components/group'
import { getPrintTemplateSettingDetail } from '../../../print/printSlice'
import { AppointmentLists } from '../../../appointment/list/appointmentList'
const { Text } = Typography

export interface PatientPanelProps {
  registration?: any
  refreshDetailFlag?: any
  editGroupId?: any
  appointmentId?: any
  patientId: string
  backID?: string
  backCall?: () => void
  getDetailMessageCallBack?: () => void
  latelyCallBackList?: () => void
  refreshData?: () => void
  tabsActiveKey?: string
  rightTabsActiveKey?: string
  currentRegistrationId?: string
}

export const PatientPanel = ({
  registration,
  refreshDetailFlag,
  editGroupId,
  appointmentId,
  patientId,
  backID,
  backCall,
  refreshData,
  latelyCallBackList,
  tabsActiveKey,
  rightTabsActiveKey,
  currentRegistrationId,
}: PatientPanelProps): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const [activeKey, setActiveKey] = useState('1')

  const [sendMsgObj, setSendMsgObj] = useState<any>(null)

  const [sendMessageModal, setSendMessageModal] = useState(false) // 发送短信弹窗

  const [subscribeModal, setSubscribeModal] = useState(false) // 工作站-预约弹窗

  const [modalTile, setModalTile] = useState(0) //  弹窗标题

  const [cardType, setCardType] = useState<any>() // 读卡类型

  const [registeredModal, setRegisteredModal] = useState(false) //工作站-转诊弹窗

  const [groupId, setGroupId] = useState(0)

  const [isASync, setisASync] = useState(false)

  const [printFlagDetail, setPrintFlagDetail] = useState<any>()

  const [PatientGroupModalType, setPatientGroupModalType] = useState(0)

  const [groupVisible, setGroupVisible] = useState(false)

  const CurePlanListModalRef = useRef<CurePlanListRefProps>(null)

  const patientManagementStatus = useSelector(selectPatientManagementStatus)

  const detail = useSelector(selectPatientInfo)

  const getDetail = () => {
    dispatch(getChangetreatmentAsync(patientId))
      .then(unwrapResult)
      .then((res) => {
        dispatch(setPatientInfo(res))
      })
  }

  const getGroup = () => {
    dispatch(getPatientGroupAsync())
      .then(unwrapResult)
      .then((res: any) => {
        setGroupId(res.records[0]?.id)
        dispatch(setGroupList(res.records))
        setGroupVisible(false)
      })
  }
  useEffect(() => {
    patientId && getDetail()
  }, [patientId])

  useEffect(() => {
    sessionStorage.removeItem('toothHomeReturnTag')
  }, [])

  useEffect(() => {
    if (!patientManagementStatus) return
    patientId && getDetail()
  }, [patientManagementStatus, patientId])

  useEffect(() => {
    return () => {
      dispatch(setPatientInfo({}))
    }
  }, [])

  useEffect(() => {
    if(activeKey == '10')
   dispatch(getPrintTemplateSettingDetail())
       .then(unwrapResult)
       .then((v: any) => {
         setPrintFlagDetail(v)
       })
  }, [activeKey])
  

  useEffect(() => {
    setActiveKey(rightTabsActiveKey || '1')
  }, [tabsActiveKey, rightTabsActiveKey])

  return (
    <div className={styles.main}>
      <div className={styles.panelHeader}>
        <Col
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexShrink: 0,
            marginRight: 15,
          }}
        >
          {detail.patientSex == 1 && (
            <BoyIcon
              style={{
                width: 36,
                height: 36,
              }}
            />
          )}
          {detail.patientSex == 2 && (
            <GirlIcon
              style={{
                width: 36,
                height: 36,
              }}
            />
          )}
        </Col>
        <Row
          style={{
            flex: 1,
          }}
        >
          <Col span={4} className={styles.headerItem}>
            <div
              style={{
                fontSize: 16,
                fontWeight: 'bold',
                color: '#000',
              }}
            >
              {detail.patientName} <Rate disabled value={Number(detail.star)} />
            </div>
            <div>病历号：{detail.patientId}</div>
          </Col>
          <Col span={7} className={styles.headerItem}>
            <div>手机号： {detail.patientPhone}</div>
            <div>
              <span>会员卡余额：{detail?.balanceMoney?.toFixed(2)}</span>
              {detail?.arrearageAmountSum > 0 ? (
                <span style={{ marginLeft: '30px', color: 'red' }}>
                  欠费金额：{detail?.arrearageAmountSum?.toFixed(2)}
                </span>
              ) : (
                <></>
              )}
            </div>
          </Col>
          <Col span={6} className={styles.headerItem}>
            <div>最近就诊： {detail.treatmentTime}</div>
            <div>就诊医生：{detail.treatmentDoctorName}</div>
          </Col>
          <Col span={5} style={{ display: 'flex' }}>
            <Space>
              {backID && (
                <>
                  <div
                    className={styles.actionItems}
                    onClick={(e) => {
                      setModalTile(0)
                      setRegisteredModal(true)
                      setCardType('')
                      // onClick('registration', patientId)
                    }}
                  >
                    <RegistrationIcon
                      style={{
                        fontSize: 18,
                        width: 18,
                        height: 18,
                        fill: '#666',
                      }}
                    />
                    挂号
                  </div>
                  <div
                    className={styles.actionItems}
                    onClick={(e) => {
                      setSubscribeModal(true)
                      // onClick('appointment', patientId)
                    }}
                  >
                    <AppointIcon
                      style={{
                        fontSize: 18,
                        width: 18,
                        height: 18,
                        fill: '#666',
                      }}
                    />
                    预约
                  </div>
                  <div
                    className={styles.actionItems}
                    onClick={(el) => {
                      const res = {
                        name: detail.patientName,
                        sex: detail.patientSex,
                        ageYear: detail.ageYear,
                        phone: detail.patientPhone,
                        id: detail.patientId,
                      }
                      setSendMessageModal(true)
                      setSendMsgObj(res)
                    }}
                  >
                    <MessageIcon
                      style={{
                        fontSize: 18,
                        width: 18,
                        height: 18,
                        fill: '#666',
                      }}
                    />
                    短信
                  </div>
                  <div
                    className={styles.actionItems}
                    onClick={() => {
                      setisASync(true)
                      setPatientGroupModalType(5)
                      setGroupVisible(true)
                    }}
                  >
                    <GroupIcon
                      style={{
                        fontSize: 18,
                        width: 18,
                        height: 18,
                        fill: '#666',
                      }}
                    />
                    分组
                  </div>
                </>
              )}
            </Space>
          </Col>
          {backID && (
            <Col span={2}>
              <div
                className={styles.back}
                onClick={() => {
                  backCall && backCall()
                }}
              >
                返回
                <ArrowLeftOutlined />
              </div>
            </Col>
          )}
        </Row>
      </div>
      <Tabs
        activeKey={activeKey}
        className={styles.patientInfoTabs}
        onChange={(v) => setActiveKey(v)}
        destroyInactiveTabPane
      >
        <Tabs.TabPane
          tab='患者信息'
          key='1'
          style={{
            overflow: 'auto',
          }}
        >
          <PatientInfo
            getDetailMessageCallBack={() => {
              latelyCallBackList && latelyCallBackList()
            }}
            patientId={patientId}
            refreshDetailFlag={refreshDetailFlag}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab='就诊信息' key='2'>
          <OutpatientInfo patientId={patientId} />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab='处置记录'
          key='3'
          style={{
            overflow: 'auto',
          }}
        >
          <Dispose
            registration={registration}
            patientId={patientId}
            currentRegistrationId={currentRegistrationId}
            refreshData={() => {
              refreshData && refreshData()
            }}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab='收费信息' key='4'>
          <ChargePage patientId={patientId} flag={true} />
        </Tabs.TabPane>
        {/* <Tabs.TabPane tab="口腔检查" key="5">
          Content of Tab Pane 3
        </Tabs.TabPane>*/}
        <Tabs.TabPane tab='影像信息' key='6'>
          <ImageInformation patientId={patientId} tabKey={6} />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab='病历信息'
          key='7'
          style={{
            overflow: 'auto',
          }}
        >
          <MedicalInfo
            refreshData={() => {
              refreshData && refreshData()
            }}
            refreshPatient={() => {
              getDetail()
            }}
            patientId={patientId}
            currentRegistrationId={currentRegistrationId}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab='随访信息' key='8'>
          <FollowUpTab patientId={patientId} toothPatient={true} />
        </Tabs.TabPane>
        <Tabs.TabPane tab='外加工' key='9'>
          <Outside patientId={patientId} />
        </Tabs.TabPane>
        <Tabs.TabPane tab='治疗计划' key='10'>
          <div
            style={{
              backgroundColor: '#fff',
              height: '100%',
              overflow: 'auto',
              borderRadius: 6,
              position: 'relative',
            }}
          >
            <Text italic>
              <span style={{ color: '#999', fontSize: 14 }}>
                仅展示1年以内的历史治疗计划
              </span>
            </Text>
            <CurePlanListModal
              printFlagDetail={printFlagDetail}
              ref={CurePlanListModalRef}
              toothAction={(action) => {
                return
              }}
              patientId={patientId}
              type={'0'}
              sourceType={0}
            />
            <Button
              type='primary'
              className={styles.CurePlanListBtn}
              onClick={() => {
                dispatch(setAddCurePlanModalVisible(true))
              }}
            >
              新增治疗计划
            </Button>
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab='手术记录' key='11'>
          <OperationStation patientId={patientId} toothPatient={true} />
        </Tabs.TabPane>
        <Tabs.TabPane tab='预约列表' key='12'>
          <AppointmentLists
            activeKey={activeKey}
            toothPatient={true}
            name={detail.patientId}
          />
        </Tabs.TabPane>
      </Tabs>
      <AddCurePlanModal
        mode={RecipeEditorMode.Normal}
        recipeCategory={RecipeCategory.Dispose}
        toothAction={(action) => {
          if (action == 'edit') {
            CurePlanListModalRef.current?.refreshPage()
          }
        }}
      />
      {/* 短信 */}
      <SendMessageModal
        sendMsgObj={sendMsgObj}
        visible={sendMessageModal}
        onCancel={() => {
          setSendMsgObj(null)
          setSendMessageModal(false)
        }}
        onOk={() => {
          setSendMsgObj(null)
          // getTableList()
        }}
      />

      {/* 工作站-预约 */}
      <SubscribeModal
        visible={subscribeModal}
        patientId={patientId} //患者 ID
        treatmentDoctorId={''} //就诊医生 ID
        onOk={() => {
          setSubscribeModal(false)
        }}
        onCancel={() => {
          setSubscribeModal(false)
        }}
      />
      {/* 工作站-挂号 */}
      <RegisteredModal
        title={modalTile} // 0 挂号1 转诊
        patientId={patientId} //新增患者-保存并挂号
        visible={registeredModal}
        cardType={cardType}
        registrationId={modalTile == 1 ? currentRegistrationId : ''} //挂号 ID
        appointmentId={appointmentId} //挂号 ID
        onOk={() => {
          setRegisteredModal(false)
          setCardType('')
        }}
        onCancel={() => {
          setRegisteredModal(false)
          setCardType('')
        }}
      />
      <PatientGroupModal
        type={PatientGroupModalType}
        visible={groupVisible}
        patientIds={[patientId]}
        editGroupId={editGroupId}
        isASync={isASync}
        onCancel={() => {
          setGroupVisible(false)
          getGroup()
          setisASync(false)
        }}
        onOk={() => {
          setGroupVisible(false)
          getGroup()
        }}
      />
    </div>
  )
}
