import JSEncrypt from "jsencrypt";
import crypto from "crypto";
import CryptoJS from 'crypto-js';

export function m() {
  const key = "-----BEGIN PUBLIC KEY-----MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAgOzcPg/RWnYxyk8EGQ9vwgdXS/TLbGKu233du2064XpIUw34dGPhDdRrSfTg7bHSKuV2WlbeaWfRDW5mpurpbuZp2CCgbs1Uy+/MBD2zVIsyc1otY7er3LC/xMKnzfZmdLKqCyL5UkmTYqOF422EdnekiNyZLRSMasWWIOjfw/9yFEluU4iABaJiTvP253n+LsQYUO18QdKWIY87FBiny+kZYxrON22ypSlVJrref2Z0/wZnachZUz+1Ko87ymlrPSpuLgchjoCZyRKp2sv3Aywc6POWbcv6pQ6E3olD6oYN5C+XTs9cmg3H1B0GT0cZn+hK6I9Hx878ftzz2zLVowIDAQAB-----END PUBLIC KEY-----";
  const encrypt = new JSEncrypt()
  encrypt.setPublicKey(key)
  const diff = localStorage.getItem("timeDiff") || "0";
  return encrypt.encrypt((new Date().getTime() + parseInt(diff)).toString());
}

export function getSecretKey() {
  return "hTf0qtZwPeMlRXwpu2SZdAG3T8y4mwUE";
}

export function getHashStr() {
  return "lXfxs4xzAI";
}



export function encrypt(password : string,ss : string) {

  const iv = crypto.randomBytes(16);
  const cipher = crypto.createCipheriv('aes-256-cbc', Buffer.from(getSecretKey()), iv);
  let encrypted = cipher.update(password + getHashStr() + ss, 'utf8', 'base64');

  encrypted += cipher.final('base64');

  password = iv.toString('base64') + getHashStr() + encrypted;
  return password;
}

export const decrypt = (cipherText:string) => {
  if(cipherText?.indexOf("wpa")==0){
    const bytes = CryptoJS.AES.decrypt(cipherText.substr(3,cipherText.length-3), CryptoJS.enc.Utf8.parse("pdfg246js1lmkxcv"), {
      mode: CryptoJS.mode.ECB, // 使用ECB模式
      padding: CryptoJS.pad.Pkcs7 // 使用PKCS7填充
    });
    const originalText = bytes.toString(CryptoJS.enc.Utf8);
    return originalText;
  }else{
    return cipherText;
  }

};