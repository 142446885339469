/*
 * @Descripttion:
 * @version:
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-07-13 13:44:18
 * @LastEditors: linxi
 * @LastEditTime: 2024-11-14 10:44:33
 */
import { unwrapResult } from '@reduxjs/toolkit'
import { Button, Modal, notification, Pagination, Space } from 'antd'
import React, { ReactElement, useEffect, useState } from 'react'
import styles from './followUpPage.module.css'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../app/store'
import { EditableList } from '../../compnents/list/EditableList'
import { Retail } from '../../models/retail'
import { Query } from './query'
import { Columns } from './columns'
import {
  cancelFollowUp,
  detailFollowUpListPage,
  FollowUpType,
  getFollowUpListPage,
  selectAddVisibleData,
  setAddVisible,
} from './followUpPageSlice'
import { AddFollowUpModal } from './addFollowUp'
import { setId } from '../putstorage/putstorageSlice'
import { DetailModal } from './detailModal'
import { useLocation } from 'react-router-dom'
import { DateRangeType, getDateRange } from '../../compnents/form/DateSelect'

export const FollowUpPage = (props: {
  state: any
  patientId?: string
  toothPatient?: boolean
  status?: number //口腔专业版1 否则0
  onLeftCount?: (obj: any) => void
}): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const isAddVisibel: any = useSelector(selectAddVisibleData)

  const location = useLocation<any>()

  const [tableLoading, setTableLoading] = useState(false) //表格 loading

  const [followUpTypeList, setFollowUpTypeList] = useState<any>() //随访类型数据

  const [detailModalVisible, setDetailModalVisible] = useState(false) //详情 modal

  const [detailModalTitle, setDetailModalTitle] = useState('') //详情 modal

  const [detailDataInfo, setDetailDataInfo] = useState<any>() //详情 modal

  const [isModalVisible, setIsModalVisible] = useState(false)

  const [id, setId] = useState<any>()

  const [bnindType, setBindType] = useState<any>()

  const [bnindId, setBindId] = useState<any>()

  const [params, setParams] = useState<any>({
    current: 0,
    size: 10,
  })

  const [page, setPage] = useState<any>({
    total: 0,
    items: [],
  })

  const [head, tail] = getDateRange(DateRangeType.ThisWeek)

  useEffect(() => {
    if (props.state == '3') return
    //数据缓存
    setParams({ ...params, state: props.state })
  }, [props.state])

  useEffect(() => {
    dispatch(setAddVisible(isModalVisible))
  }, [isModalVisible])

  const getType = () => {
    dispatch(FollowUpType())
      .then(unwrapResult)
      .then((res) => {
        setFollowUpTypeList(res)
      })
  }

  const getPage = () => {
    if (props.state == '0') {
      //获取随访表格列表
      setTableLoading(true)
    }

    dispatch(
      getFollowUpListPage({
        ...params,
        startTime: params?.startTime || head,
        endTime: params?.endTime || tail,
        patientId: props.patientId,
      })
    )
      .then(unwrapResult)
      .then((res) => {
        const data = {
          total: res.total,
          items: res.records,
        }
        setPage(data)
        setTableLoading(false)
      })
      .catch(() => {
        setTableLoading(false)
      })
  }

  useEffect(() => {
    getType()
  }, [])

  useEffect(() => {
    if (!params.current) return
    sessionStorage.setItem(
        location.pathname+"_suifang",
        JSON.stringify({
            ...params,
            state: props.state == '3' ? 0 : props.state,
        })
    )
    getPage()
  }, [params])

    useEffect(() => {
        setParams({
            ...params,
            patientId: props.patientId
        })
    }, [props.patientId])


    const toothPatientFilter = props.toothPatient
    ? ['name', 'sex', 'age', 'phone']
    : []

  return (
    <div
      className={styles.wrap}
      style={{
        height: props.status == 1 ? '100%' : 'height: calc(100% - 10px)',
        padding: props.status == 1 ? '10px 10px 0' : '0 15px',
      }}
    >
      {!props.patientId && props.status != 1 && (
        <div className={styles.addFollowUp}>
          <Button
            type='primary'
            onClick={() => {
              setIsModalVisible(true)
            }}
          >
            新增随访
          </Button>
        </div>
      )}
      <Query
        status={props.state}
        type={props.status || 0}
        patientId={props.patientId}
        followUpTypeList={followUpTypeList}
        toothPatient={props.toothPatient}
        onValueChange={(v) => {
          setParams({
            ...params,
            ...v,
            state: v.stateV ? v.stateV : v.state,
            current: 1,
          })
          props.onLeftCount &&
            props.onLeftCount({
              nameV: v.name,
              startTimeV: v.startTime ? v.startTime : v.timeHead,
              endTimeV: v.endTime ? v.endTime : v.timeTail,
              stateV: v.stateV,
              visitFlagV: v.flag,
              visitUserIdV: v.userId,
            })
        }}
      />
      <EditableList
        bordered={props.status == 1 ? true : false}
        loading={tableLoading}
        style={{ flex: 1 }}
        className={styles.table}
        sticky={location.pathname == '/toothHome'}
        rowKey={(v, i) => `${i}`}
        page={{
          current: params.current,
          size: params.size,
          total: page.total,
          items: page.items,
        }}
        columns={Columns(
          (id, text, item) => {
            if (text == 'detail') {
              //详情
              dispatch(detailFollowUpListPage({ id }))
                .then(unwrapResult)
                .then((res) => {
                  setDetailDataInfo(res)
                  setDetailModalVisible(true)
                  setDetailModalTitle('随访详情')
                })
            } else if (text == 'edit') {
              //执行
              setId(id)
              setBindType(item?.bindType)
              setBindId(item?.bindId)
              setIsModalVisible(true)
            } else if (text == 'cancel') {
              //取消
              dispatch(cancelFollowUp({ id }))
                .then(unwrapResult)
                .then((res) => {
                  notification.success({ message: '操作成功' })
                  getPage()
                })
            }
          },
          10 * (params.current - 1),
          props.state
        ).filter((v: any) => !toothPatientFilter.includes(v.dataIndex))}
        onChangePage={(current, size) =>
          setParams({ ...params, current, size })
        }
      />
      {props.toothPatient && (
        <div>
          <Button
            type='primary'
            size='middle'
            onClick={() => setIsModalVisible(true)}
            style={{
              position: 'absolute',
              left: 15,
              bottom: 0,
            }}
          >
            新增随访
          </Button>
        </div>
      )}
      <DetailModal
        title={detailModalTitle}
        visible={detailModalVisible}
        detailDataInfo={detailDataInfo}
        onOk={() => {
          setDetailModalVisible(false)
        }}
        onCancel={() => {
          setDetailModalVisible(false)
        }}
      />
      <AddFollowUpModal
        status={props.status} //区分口腔专业 1还是正常 0
        treatmentTag={false} //非病例那边点开不需要查询条件以及治疗计划
        bindId={bnindId}
        tablePatientId={props.toothPatient ? props.patientId : undefined}
        bindType={bnindType}
        id={id}
        isModalVisible={isModalVisible}
        onCancel={() => {
          setIsModalVisible(false)
          getPage()
          setId('')
          setBindId('')
          setBindType('')
          getType()
        }}
        onOk={() => {
          setIsModalVisible(false)
          getPage()
          setId('')
          setBindId('')
          setBindType('')
          getType()
        }}
      ></AddFollowUpModal>
    </div>
  )
}
