/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-11-22 14:09:25
 * @LastEditors: linxi
 * @LastEditTime: 2024-11-14 13:54:10
 */

import _ from 'lodash'
import moment from 'moment'
import { getAge } from '../utils/StringUtils'
import { DateTimeFormat } from './datetime'
import { getOtcCategoryName } from './material'

// 西药
export function subPrintPrescriptionContent(data: any) {
  const allPage = data?.allPage
  const chunkedArray = data?.chunkedArray

  let subContent = ''

  for (let i = 0; i < allPage; i++) {
    const dataMian = { ...data, dataList: chunkedArray[i], indexs: i }
    subContent += SinglePrintPrescriptionContent(dataMian, i)
  }

  return subContent
}

export function SinglePrintPrescriptionContent(data: any, indexs: any) {
  // 拆分后的数组
  const chunkedArray = data?.chunkedArray
  const allPage = data?.allPage
  const tenantName = data?.tenantName
  const recipeId = data?.recipeId
  const dataListMain = data?.dataList
  const patientMessage = data?.patientMessage
  const detailMessage = data?.detailMessage
  const doctorMessage = data?.doctorMessage
  const zlCount = data?.zlCount
  const ypCount = data?.ypCount
  const SignatureSrc = data?.doctorMessage?.userSign
    ? data?.doctorMessage?.userSign
    : ''
  const YSSignatureSrc = data?.doctorMessage?.physicianSign
    ? data?.doctorMessage?.physicianSign
    : ''
  const PYYSSignatureSrc = data?.doctorMessage?.pharmacistSign
    ? data?.doctorMessage?.pharmacistSign
    : ''
  const FHYSSignatureSrc = data?.doctorMessage?.reviewerPharmacistSign
    ? data?.doctorMessage?.reviewerPharmacistSign
    : ''
  const SFYSSignatureSrc =data?.doctorMessage?.reviewerPharmacistSign
  ? data?.doctorMessage?.reviewerPharmacistSign
  : ''
  const showFlagList = data?.showFlag
  const typeName = data?.typeName
  const disease = _.chain(detailMessage?.disease)
    .split(',')
    .map((d) => d.split('/')[0])
    .value()
    ?.join(',')

  const getNum = (key: any) => {
    switch (key) {
      case 0:
        return '1'
      case 1:
        return '2'
      case 2:
        return '4'
      case 3:
        return '3'
      default:
        return '1'
    }
  }

  const diseaseList = detailMessage?.disease
    ?.split('#')
    .map((item: any, index: any) => {
      const data = detailMessage?.diseaseTooth
        ?.split('#')
        ?.map((v: any) =>
          v?.split('@').map((current: any) => (current === '--' ? '' : current))
        )

        ?.map((v1: any) => {
          const data = v1?.filter((val: any) => val)
          return data?.map((v2: any, indexTwo: any) => {
            return v2
              ?.split(',')
              ?.map((v3: any) => (v3 ? getNum(indexTwo) + v3 : ''))
              ?.join(',')
          })
        })
      const dataList = data
      return (dataList[index] ? dataList[index] : '') + ' ' + item
    })
  const diseaseListValue = diseaseList.join(',')

  const address = patientMessage?.patientAddress

  // 电子签名
  let dzqm = ''
  if (SignatureSrc) {
    dzqm = '<img src=' + SignatureSrc + ' style="width:70px;height:30px;" />'
  }

  // 医师电子签名
  let ysdzqm = ''
  if (YSSignatureSrc) {
    ysdzqm =
      '<img src=' + YSSignatureSrc + ' style="width:50px;height:18px;" />'
  }

  // 配药药师电子签名
  let pyysdzqm = ''
  if (PYYSSignatureSrc) {
    pyysdzqm =
      '<img src=' + PYYSSignatureSrc + ' style="width:50px;height:18px;" />'
  }

  // 复核药师电子签名
  let fhysdzqm = ''
  if (FHYSSignatureSrc) {
    fhysdzqm =
      '<img src=' + FHYSSignatureSrc + ' style="width:50px;height:18px;" />'
  }


   // 审方药师电子签名
   let sfysdzqm = ''
   if (SFYSSignatureSrc) {
    sfysdzqm =
       '<img src=' + SFYSSignatureSrc + ' style="width:50px;height:18px;" />'
   }
   
  // 病历号一维码
  let blhImg = ''
  if (showFlagList?.blhFlag == 'true') {
    if (detailMessage?.codeImg) {
      blhImg =
        '<img src=' +
        detailMessage?.codeImg +
        ' style="width:136px;height:56px;font-weight:bold;margin-left:20px" />'
    }
  } else {
    blhImg = ''
  }

  let xyMain = ''
  const getNewList = (dataList?: any) => {
    for (let i = 0; i < dataList?.length; i++) {
      for (let j = 0; j < dataList?.[i]?.length; j++) {
        dataList[i][j].sortStr =
          dataList[i][j]?.normalUsemethod +
          ',' +
          dataList[i][j]?.normalDose +
          ',' +
          dataList[i][j]?.doseUnit +
          ',' +
          dataList[i][j]?.normalFrequency
      }
      dataList[i]
      // dataList[i] = dataList[i].sort(
      //   (a: any, b: any) => a?.sortStr?.length - b?.sortStr.length
      // )
    }
    return dataList
  }

  const xyDataMainList: any = getNewList(dataListMain)

  for (let i = 0; i < xyDataMainList?.length; i++) {
    const dataMainList: any = xyDataMainList?.[i]
    for (let j = 0; j < dataMainList?.length; j++) {
      const otcRemark = getOtcCategoryName(Number(dataMainList[j]?.otc)) +
            (dataMainList[j]?.otc && dataMainList[j]?.otc != -1 ? ',' : '') +
            dataMainList[j]?.remark
 
      xyMain +=
        ' <div style="text-align: left;font-size: 12px;width:500px;float:left;">' +
        '   <div style="display:flex;width:460px;float:left;">' +
        '   <div style="display:flex;width:300px;">' +
        '<span style="font-weight:bold"> ' +
        (j == 0 ? Number(dataMainList[j]?.groupNumber) + '.  ' : '') +
        '</span>' +
        (j == 0
          ? '<span style="font-weight:bold"> ' +
            dataMainList[j]?.name +
            '</span>' +
            '<span style="width:6px"> ' +
            '</span>' +
            '<span > ' +
            dataMainList[j]?.packSpec +
            '</span>' +
            '<span style="width:6px"> ' +
            '</span>' +
            '<span style="marginLeft:10px"> ' +
            (dataMainList[j]?.skinResultinfo
              ? dataMainList[j]?.skinResultinfo
              : '') +
            '</span>' +
            '<span style="width:6px"> ' +
            '</span>' +
            (showFlagList?.zlFlag == 'true'
              ? '<span > ' +
                (dataMainList[j]?.cabineNo ? dataMainList[j]?.cabineNo : '') +
                '</span>'
              : '')
          : '<span style="font-weight:bold;margin-left:7px"> ' +
            dataMainList[j]?.name +
            '</span>' +
            '<span style="width:6px"> ' +
            '</span>' +
            '<span> ' +
            dataMainList[j]?.packSpec +
            '</span>' +
            '<span style="width:6px"> ' +
            '</span>' +
            '<span style="marginLeft:10px"> ' +
            (dataMainList[j]?.skinResultinfo
              ? dataMainList[j]?.skinResultinfo
              : '') +
            '</span>' +
            '<span style="width:6px"> ' +
            '</span>' +
            (showFlagList?.ghFlag == 'true'
              ? '<span > ' +
                (dataMainList[j]?.cabineNo ? dataMainList[j]?.cabineNo : '') +
                '</span>'
              : '')) +
        '</div>' + 
        '<span style="font-weight:bold;width: 80px"> ' +
        (showFlagList?.daysFlag == 'false'
          ? dataMainList[j]?.drugCount +
            (dataMainList[j]?.splitTag == 1
              ? dataMainList[j]?.splitUnit
              : dataMainList[j]?.accountUnit)
          : dataMainList[j]?.useDays +
            '天' +
            '/' +
            dataMainList[j]?.drugCount +
            (dataMainList[j]?.splitTag == 1
              ? dataMainList[j]?.splitUnit
              : dataMainList[j]?.accountUnit)) +
        '</span>' +
        (showFlagList?.bzFlag == 'true' && dataMainList[j]?.billingCategory != '01'
              ? '<span> ' + otcRemark + '</span>'
              : '') +
        '</div>' +
        (dataMainList[j]?.sortStr == dataMainList[j + 1]?.sortStr &&
        dataMainList[j]?.sortStr
          ? ''
          : dataMainList[j]?.drugType != 1
          ? '   <div style="width:460px;margin-left:8px">' +
            '   <div style="display:flex;width:460px;float:left;">用法：' +
            '<span style="width:100px"> ' +
            dataMainList[j]?.normalUsemethod +
            '</span>' +
            '<span style="width:100px"> ' +
            '每次' +
            dataMainList[j]?.normalDose +
            dataMainList[j]?.doseUnit +
            '</span>' +
            '<span style="width:100px"> ' +
            dataMainList[j]?.normalFrequency +
            '</span>' +
            (showFlagList?.bzFlag == 'true'
              ? '<span style="width:100px"> ' + otcRemark + '</span>'
              : '') +
            '</div>' +
            (showFlagList?.cjFlag == 'true'
              ? '   <div style="display:flex;width:460px;float:left;">' +
                '<span style="width:236px">厂家： ' +
                dataMainList[j]?.tenantSupplierName +
                '</span>' +
                '<span style="width:100px">批号： ' +
                dataMainList[j]?.batchNo +
                '</span>' +
                '</div>'
              : '') +
            '</div>'
          : '') +
        '</div>'
    }
  }
  // xyMain +=
  // ' <div style="font-size: 12px;width:460px;margin:10px 0;float:left;border-bottom:1px dashed #777777;">' +
  // ' </div>'

  let content =
    '<div style="width:420px;height:580px;page-break-after:always;page-break-inside:always;page-break-before:always;">' +
    '   <div style="margin: 5px 0">' +
    '   <div style="display:flex ;position:relative">' +
    '   <div style="display: flex;align-items: flex-end;width:110px;position:absolute;bottom:-6px">' +
    blhImg +
    ' </div>' +
    ' <div style="display: flex;flex-direction: column">' +
    ' <div style="text-align: center;float:left;width:460px;margin:0 20px;">' +
    '   <div style="padding: 10px 0;font-size: 20px;"><span>' +
    tenantName +
    '</span></div>' +
    ' </div>' +
    ' <div style="text-align: center;float:left;width:460px;font-weight:bold;margin:0 20px;">' +
    '   <div style="float:left;font-size: 24px;width:208px;text-align: right;"><span>处</span></div>' +
    '   <div style="float:left;font-size: 24px;width:40px;"><span>方</span></div>' +
    '   <div style="float:left;font-size: 24px;width:100px;text-align: left;"><span>笺</span></div>' +
    (typeName
      ? '   <div style="float:right;font-size: 12px;width:0px;"><div style="width:40px;height:40px;border:1px solid #333;display:flex;justify-content:center;align-items:center">' +
        typeName +
        '</div></div>'
      : '') +
    ' </div>' +
    ' </div>' +
    ' </div>' +
    ' <div style="text-align: left;font-size: 12px;width:460px;margin:4px 20px  0 20px;float:left;"><span>NO : </span>' +
    recipeId +
    '</div>' +
    ' <div style="display:flex;font-size: 12px;width:460px;margin:0 20px;float:left;">' +
    '   <div style=";width:140px;display:flex">' +
    '   <div style="width:40px;">姓名：' +
    '</div>' +
    '   <div style="width:95px;border-bottom:1px solid #000;">' +
    '<span style="font-weight:bold"> ' +
    detailMessage?.patientName +
    '</span>' +
    '<span> ' +
    detailMessage?.sex +
    '</span>' +
    '<span> ' +
    getAge(detailMessage?.patientAge, detailMessage?.patientMonth, '岁') +
    '</span>' +
    '</div>' +
    '</div>' +
    '   <div style=";width:80px;display:flex">' +
    '   <div style="width:40px;">费别：' +
    '</div>' +
    '   <div style="width:35px;border-bottom:1px solid #000;">' +
    (detailMessage?.insuranceArrayLength !=0 ? detailMessage?.type :'-') +
    '</div>' +
    '</div>' +
    '   <div style=";width:100px;display:flex">' +
    '   <div style="width:40px;">科室：' +
    '</div>' +
    '   <div style="width:55px;border-bottom:1px solid #000;">' +
    detailMessage?.treatmentDepartmentName +
    '</div>' +
    '</div>' +
    (showFlagList?.blhFlag == 'true'
      ? '   <div style="width:140px;display:flex">' +
        '   <div style="width:50px;">病历号：' +
        '</div>' +
        '   <div style="width:90px;border-bottom:1px solid #000;">' +
        detailMessage?.patientId +
        '</div>' +
        '</div>'
      : '') +
    ' </div>' +
    ' <div style="display:flex;font-size: 12px;width:460px;margin:0 20px;float:left;">' +
    '   <div style="width:320px;float:left;display:flex">' +
    '   <div style="width:40px;">地址：' +
    '</div>' +
    '   <div style="width:270px;border-bottom:1px solid #000;">' +
    address +
    '</div>' +
    '</div>' +
    '   <div style="width:140px;float:left;display:flex">' +
    '   <div style="width:40px;">电话：' +
    '</div>' +
    '   <div style="width:100px;border-bottom:1px solid #000;">' +
    (patientMessage?.phone ? patientMessage?.phone : '') +
    '</div>' +
    '</div>' +
    ' </div>' +
    (typeName == '精一' || typeName == '精二' || typeName == '麻醉'
      ? ' <div style="display:flex;font-size: 12px;width:460px;margin:0 20px;;float:left;">' +
        '   <div style="width:460px;float:left;display:flex">' +
        '   <div style="width:75px;">患者证件号：' +
        '</div>' +
        '   <div style="width:385px;border-bottom:1px solid #000;">' +
        patientMessage?.idCardNumber +
        '</div>' +
        '</div>' +
        ' </div>'
      : '') +
    (typeName == '麻醉'
      ? ' <div style="display:flex;font-size: 12px;width:460px;margin:0 20px;">' +
        ' <div style="font-size: 12px;width:220px;display:flex">' +
        '   <div style="width:110px;">麻醉药品专用卡号：' +
        '</div>' +
        '   <div style="width:105px;border-bottom:1px solid #000;">' +
        '</div>' +
        ' </div>' +
        ' <div style="font-size: 12px;width:240px;;display:flex">' +
        '   <div style="width:86px;">取药人证件号：' +
        '</div>' +
        '   <div style="width:154px;border-bottom:1px solid #000;">' +
        '</div>' +
        '</div>' +
        ' </div>'
      : '') +
    (typeName == '毒'
      ? ' <div style="display:flex;font-size: 12px;width:460px;margin:0 20px;">' +
        ' <div style="font-size: 12px;width:220px;display:flex;">' +
        '   <div style="width:80px;">患者证件号：' +
        '</div>' +
        '   <div style="width:140px;border-bottom:1px solid #000;">' +
        patientMessage?.idCardNumber +
        '</div>' +
        ' </div>' +
        ' <div style="font-size: 12px;width:240px;display:flex;">' +
        '   <div style="width:90px;">代办人证件号：' +
        '</div>' +
        '   <div style="width:150px;border-bottom:1px solid #000;">' +
        '</div>' +
        '</div>' +
        ' </div>'
      : '') +
    (showFlagList?.zsFlag == 'true'
      ? ' <div style="display:flex;font-size: 12px;width:460px;margin:0 20px;;float:left;">' +
        '   <div style="width:460px;float:left;display:flex">' +
        '   <div style="width:40px;">主诉：' +
        '</div>' +
        '   <div style="width:420px;border-bottom:1px solid #000;">' +
        detailMessage?.chiefComplaint +
        '</div>' +
        '</div>' +
        ' </div>'
      : '') +
    (detailMessage?.medicalRecord != 3
      ? ' <div style="display:flex;font-size: 12px;width:460px;margin:0 20px;;float:left;">' +
        '   <div style=";width:320px;display:flex">' +
        '   <div style="width:60px;">临床诊断：' +
        '</div>' +
        '   <div style="width:250px;border-bottom:1px solid #000;">' +
        disease +
        '</div>' +
        '</div>' +
        '   <div style=";width:140px;display:flex">' +
        '   <div style="width:62px;">开方日期：' +
        '</div>' +
        '   <div style="width:78px;border-bottom:1px solid #000;">' +
        detailMessage?.createTime +
        '</div>' +
        '</div>' +
        ' </div>'
      : ' <div style="display:flex;font-size: 12px;width:460px;margin:0 20px;;float:left;">' +
        '   <div style="width:460px;float:left;display:flex">' +
        '   <div style="width:60px;">临床诊断：' +
        '</div>' +
        '   <div style="width:400px;border-bottom:1px solid #000;display:-webkit-box;-webkit-line-clamp:2;-webkit-box-orient: vertical;overflow:hidden;text-overflow:ellipsis;">' +
        diseaseListValue +
        '</div>' +
        '</div>' +
        ' </div>' +
        ' <div style="display:flex;font-size: 12px;width:460px;margin:0 20px;;float:left;">' +
        '   <div style="width:460px;float:left;display:flex">' +
        '   <div style="width:60px;">开方日期：' +
        '</div>' +
        '   <div style="width:400px;border-bottom:1px solid #000;">' +
        detailMessage?.createTime +
        '</div>' +
        '</div>' +
        ' </div>') +
    ' <div style="font-size: 12px;width:460px;height:6px;float:left;margin:0 20px;border-bottom:1px dashed #777777;">' +
    ' </div>' +
    ' <div style="text-align: left;font-size: 14px;width:460px;margin:0px 20px 0px 20px;font-weight:bold;float:left;">Rp.</div>' +
    ' <div style="width:460px;min-height:400px;margin:0 20px;float:left;">' +
    ' <div style="text-align: left;float:left;width:460px;display:flex;flex-wrap:wrap;flex-basic:230px;flex-shrink: 0">' +
    xyMain +
    '</div>'
  content +=
    Number(indexs) + 1 == allPage
      ? '   <div style="font-size: 10px;width:460px;display:flex;justify-content:center;float:left;margin-top:5px">----- 以下处方完毕-----</div>' +
        '</div>'
      : '   <div style="font-size: 10px;width:460px;display:flex;justify-content:center;float:left;margin-top:5px">-----接下页-----</div>' +
        '</div>'
  content +=
    showFlagList?.jeFlag == 'true'
      ? ' <div style="text-align: left;font-size: 12px;width:460px;margin:20px 20px 0 20px;float:left">' +
        '药费：' +
        ('¥ ' + ypCount) +
        ' , ' +
        '诊疗费：' +
        ('¥ ' + zlCount) +
        ' </div>'
      : ' <div style="text-align: left;font-size: 12px;width:460px;margin:20px 20px 0 20px;display:flex">' +
        ' <div style="font-size: 12px;height:12px;">' +
        '药费：' +
        ' </div>' +
        ' <div style="font-size: 12px;width:70px;height:12px;margin:0 10px;border-bottom:1px solid #333;">' +
        ' </div>' +
        ' , ' +
        ' <div style="font-size: 12px;height:12px;">' +
        '诊疗费：' +
        ' </div>' +
        ' <div style="font-size: 12px;width:70px;height:12px;margin:0 10px;border-bottom:1px solid #333;">' +
        ' </div>' +
        ' </div>'
  content +=
    ' <div style="font-size: 12px;margin: 0 20px;float:left;width:460px;height:6px;border-bottom:1px dashed #777777;">' +
    ' </div>' +
    ' <div style="text-align: left;float:left;font-size: 12px;width:460px;margin:10px 20px 0 20px;display:flex;">' +
    ' <div style="display: flex;font-size: 12px;width:110px;margin-right:5px">' +
    '   <div style="width:40px;">医师：</div>' +
    (doctorMessage?.physicianType != 2
      ? '   <div style="flex:1;border-bottom:1px solid #000;height:16px;">' +
        (doctorMessage?.physicianSignType == 1
          ? ysdzqm
          : doctorMessage?.physicianName) +
        '</div>'
      : '   <div style="flex:1;border-bottom:1px solid #000;height:16px;">' +
        '</div>') +
    '</div>' +
    ' <div style="display: flex;font-size: 12px;width:110px;margin-right:5px">' +
    '   <div style="width:60px;">配药药师：</div>' +
    (doctorMessage?.pharmacistType != 2
      ? '   <div style="flex:1;border-bottom:1px solid #000;height:16px;">' +
        (doctorMessage?.pharmacistSignType == 1
          ? pyysdzqm
          : doctorMessage?.pharmacistName) +
        '</div>'
      : '   <div style="flex:1;border-bottom:1px solid #000;height:16px;">' +
        '</div>') +
    '</div>' +
    ' <div style="display: flex;font-size: 12px;width:110px;margin-right:5px">' +
    '   <div style="width:60px;">复核药师：</div>' +
    (doctorMessage?.reviewerPharmacistType != 2
      ? '   <div style="flex:1;border-bottom:1px solid #000;height:16px;">' +
        (doctorMessage?.reviewerPharmacistSignType == 1
          ? fhysdzqm
          : doctorMessage?.reviewerPharmacistName) +
        '</div>'
      : '   <div style="flex:1;border-bottom:1px solid #000;height:16px;">' +
        '</div>') +
    '</div>' +
    ' <div style="display: flex;font-size: 12px;width:110px;margin-right:5px">' +
    '   <div style="width:40px;">签名：</div>' +
    (dzqm && doctorMessage?.signatureType != 2
      ? '   <div style="flex:1;height:16px;">' + dzqm + '</div>'
      : '   <div style="flex:1;border-bottom:1px solid #000;height:16px;">' +
        '</div>') +
    '</div>' +
    ' </div>' +
    (dzqm && doctorMessage?.signatureType != 2
      ? ' <div style="text-align: left;float:left;font-size: 12px;width:460px;margin:20px 20px 0 20px">'
      : ' <div style="text-align: left;float:left;font-size: 12px;width:460px;margin:6px 20px 0 20px">') +
    '   <div style="width:460px;color:#777777">除主诊医师特别注明外,处方仅当日有效。按卫生部规定,药房药品一经发出,不得退换。</div>' +
    ' </div>' +
    // ' <div style="text-align: center;font-size: 14px;width:380px;margin:0 20px;float:left;">' +
    // '   <div style="padding: 10px 0;font-size: 14px;"><span>第' +
    // (Number(pageSize) + 1) +
    // '页/共' +
    // allPage +
    // '页</span></div>' +
    // ' </div>' +
    '</div>' +
    '</div>'

  return content
}

// 中药
export function subZYPrintPrescriptionContent(data: any) {
  const allPage = data?.allPage
  const chunkedArray = data?.chunkedArray

  let subContent = ''

  for (let i = 0; i < allPage; i++) {
    const dataMian = { ...data, dataList: chunkedArray[i], indexs: i }
    subContent += SinglezYPrintPrescriptionContent(dataMian, i)
  }

  return subContent
}

// 中药
export function SinglezYPrintPrescriptionContent(data: any, indexs: any) {
  const allPage = data?.allPage
  const tenantName = data?.tenantName
  const shortName= data?.shortName
  const recipeId = data?.recipeId
  const dataListMain = data?.dataList?.filter(
    (v: any) => v?.billingCategory != '97'
  )
  const patientMessage = data?.patientMessage
  const detailMessage = data?.detailMessage
  const paymentMessage = data?.payment
  const tcmMessage = data?.tcmMessage
  const doctorMessage = data?.doctorMessage
  const SignatureSrc = data?.doctorMessage?.userSign
    ? data?.doctorMessage?.userSign
    : ''
  const YSSignatureSrc = data?.doctorMessage?.physicianSign
    ? data?.doctorMessage?.physicianSign
    : ''
  const PYYSSignatureSrc = data?.doctorMessage?.pharmacistSign
    ? data?.doctorMessage?.pharmacistSign
    : ''
  const FHYSSignatureSrc = data?.doctorMessage?.reviewerPharmacistSign
    ? data?.doctorMessage?.reviewerPharmacistSign
    : ''
  const SFYSSignatureSrc =data?.doctorMessage?.reviewerPharmacistSign
    ? data?.doctorMessage?.reviewerPharmacistSign
    : ''
  const showFlagList = data?.showFlag
  const disease = _.chain(detailMessage?.disease)
    .split(',')
    .map((d) => d.split('/')[0])
    .value()
    ?.join(',')

  // 委外代煎标志
  const wwdjFlag = tcmMessage?.machiningTypeMsg?.indexOf('委外') >= 0
  // 电子签名
  let dzqm = ''
  if (SignatureSrc) {
    dzqm = '<img src=' + SignatureSrc + ' style="width:70px;height:30px;" />'
  }

  let zyMain = ''

  // 医师电子签名
  let ysdzqm = ''
  if (YSSignatureSrc) {
    ysdzqm =
      '<img src=' + YSSignatureSrc + ' style="width:50px;height:18px;" />'
  }

  // 配药药师电子签名
  let pyysdzqm = ''
  if (PYYSSignatureSrc) {
    pyysdzqm =
      '<img src=' + PYYSSignatureSrc + ' style="width:50px;height:18px;" />'
  }

  // 复核药师电子签名
  let fhysdzqm = ''
  if (FHYSSignatureSrc) {
    fhysdzqm =
      '<img src=' + FHYSSignatureSrc + ' style="width:50px;height:18px;" />'
  }

    // 审方药师电子签名
    let sfysdzqm = ''
    if (SFYSSignatureSrc) {
      sfysdzqm =
        '<img src=' + SFYSSignatureSrc + ' style="width:50px;height:18px;" />'
    }

  // 病历号一维码
  let blhImg = ''
  if (showFlagList?.blhFlag == 'true') {
    if (detailMessage?.codeImg) {
      blhImg =
        '<img src=' +
        detailMessage?.codeImg +
        ' style="width:136px;height:56px;font-weight:bold;margin-left:20px" />'
    }
  } else {
    blhImg = ''
  }

  const address = patientMessage?.patientAddress

  // for (let i = 0; i < dataListMain?.length; i++) {
  //   zyMain +=
  //     '   <div style="font-size: 14px;display:flex;;float:left;height:25px;align-items:center;width:230px;">' +
  //     '<span style="font-weight:bold;margin-right:4px"> ' +
  //     Number(dataListMain[i]?.groupNumber) +
  //     '.' +
  //     dataListMain[i]?.name +
  //     '</span>' +
  //     '<span style="font-weight:bold;margin-right:4px"> ' +
  //     dataListMain[i]?.countPerTip +
  //     dataListMain[i]?.doseUnit +
  //     '/' +
  //     '</span>' +
  //     '<span style="font-weight:bold;margin-right:4px"> ' +
  //     dataListMain[i]?.drugCount +
  //     dataListMain[i]?.doseUnit +
  //     '</span>' +
  //     '<span style="font-size: 12px;;margin-right:4px"> ' +
  //     (dataListMain[i]?.normalUsemethod == '无' ||
  //     dataListMain[i]?.normalUsemethod == '煎服'
  //       ? ''
  //       : dataListMain[i]?.normalUsemethod
  //       ? '(' + dataListMain[i]?.normalUsemethod + ')'
  //       : '') +
  //     '</span>' +
  //     '</div>'
  // }

  for (let i = 0; i < dataListMain?.length; i++) {
    zyMain +=
      '   <div style="font-size: 14px;width:230px;">' +
      (showFlagList?.ghFlag == 'true'
        ? '<div style="font-size: 12px;margin-left:10px;margin-bottom:-4px"> ' +
          (dataListMain[i]?.cabineNo ? dataListMain[i]?.cabineNo : '') +
          '</div>'
        : '<div style="font-size: 12px;margin-left:10px;margin-bottom:-4px"> ' +
          ' ' +
          '</div>') +
      '   <div style="font-size: 14px;display:flex;float:left;height:25px;align-items:center;width:230px;font-weight:bold;>' +
      '<span style="font-weight:bold;margin-right:4px"> ' +
      Number(dataListMain[i]?.groupNumber) +
      '.' +
      dataListMain[i]?.name +
      '</span>' +
      '<span style="font-weight:bold;margin-right:4px"> ' +
      dataListMain[i]?.countPerTip +
      (dataListMain[i]?.splitTag ? dataListMain[i]?.splitUnit :dataListMain[i]?.accountUnit ) +
      '</span>' +
      '(' +
      '<span style="font-weight:bold;margin-right:4px"> ' +
      dataListMain[i]?.drugCount +
      (dataListMain[i]?.splitTag ? dataListMain[i]?.splitUnit :dataListMain[i]?.accountUnit ) +
      '</span>' +
      ')' +
      '<span style="font-size: 12px;margin-right:4px;font-weight:500;margin-left:6px"> ' +
      (dataListMain[i]?.normalUsemethod == '无' ||
      dataListMain[i]?.normalUsemethod == '煎服'
        ? ''
        : dataListMain[i]?.normalUsemethod
        ? '(' + dataListMain[i]?.normalUsemethod + ')'
        : '') +
      '</span>' +
      '</div>' +
      '</div>'
  }

  // zyMain +=
  // ' <div style="font-size: 12px;width:460px;margin:10px 0;float:left;border-bottom:1px dashed #777777;">' +
  // ' </div>'

  let content =
    '<div style="width:420px;height:580px;page-break-after:always;page-break-inside:always;page-break-before:always;">' +
    '   <div style="margin: 5px 0">' +
    '   <div style="display:flex ;position:relative">' +
    '   <div style="display: flex;align-items: flex-end;width:126px;position:absolute;bottom:4px">' +
    blhImg +
    ' </div>' +
    ' <div style="display: flex;flex-direction: column">' +
    ' <div style="text-align: center;float:left;width:460px;margin:0 20px;">' +
    '   <div style="padding: 10px 0;font-size: 20px;"><span>' +
    shortName +
    '</span></div>' +
    ' </div>' +
    ' <div style="text-align: center;float:left;width:460px;font-weight:bold;margin:0 20px;">' +
    '   <div style="float:left;font-size: 24px;width:208px;text-align: right;"><span>处</span></div>' +
    '   <div style="float:left;font-size: 24px;width:40px;"><span>方</span></div>' +
    '   <div style="float:left;font-size: 24px;width:100px;text-align: left;"><span>笺</span></div>' +
    (wwdjFlag
      ? '   <div style="float:right;font-size: 12px;width:80px;"><div style="width:40px;height:40px;border:1px solid #333"><div style="margin-top:4px">委外</div><div>代煎</div></div></div>' +
        ' </div>'
      : '') +
    ' </div>' +
    ' </div>' +
    ' </div>' +
    ' <div style="text-align: left;font-size: 12px;width:460px;margin:4px 20px  0 20px;float:left;"><span>NO : </span>' +
    recipeId +
    '</div>' +
    ' <div style="display:flex;font-size: 12px;width:460px;margin:0 20px;float:left;">' +
    '   <div style=";width:140px;display:flex">' +
    '   <div style="width:40px;">姓名：' +
    '</div>' +
    '   <div style="width:95px;border-bottom:1px solid #000;">' +
    '<span style="font-weight:bold"> ' +
    detailMessage?.patientName +
    '</span>' +
    '<span> ' +
    detailMessage?.sex +
    '</span>' +
    '<span> ' +
    getAge(detailMessage?.patientAge, detailMessage?.patientMonth, '岁') +
    '</span>' +
    '</div>' +
    '</div>' +
    '   <div style=";width:80px;display:flex">' +
    '   <div style="width:40px;">费别：' +
    '</div>' +
    '   <div style="width:35px;border-bottom:1px solid #000;">' +
    (detailMessage?.insuranceArrayLength !=0 ? detailMessage?.type :'-') +
    '</div>' +
    '</div>' +
    '   <div style=";width:100px;display:flex">' +
    '   <div style="width:40px;">科室：' +
    '</div>' +
    '   <div style="width:55px;border-bottom:1px solid #000;">' +
    detailMessage?.treatmentDepartmentName +
    '</div>' +
    '</div>' +
    (showFlagList?.blhFlag == 'true'
      ? '   <div style=";width:140px;display:flex">' +
        '   <div style="width:50px;">病历号：' +
        '</div>' +
        '   <div style="width:90px;border-bottom:1px solid #000;">' +
        detailMessage?.patientId +
        '</div>' +
        '</div>'
      : '') +
    ' </div>' +
    ' <div style="display:flex;font-size: 12px;width:460px;margin:0 20px;;float:left;">' +
    '   <div style="width:320px;float:left;display:flex">' +
    '   <div style="width:40px;">地址：' +
    '</div>' +
    '   <div style="width:270px;border-bottom:1px solid #000;">' +
    address +
    '</div>' +
    '</div>' +
    '   <div style="width:140px;float:left;display:flex">' +
    '   <div style="width:40px;">电话：' +
    '</div>' +
    '   <div style="width:100px;border-bottom:1px solid #000;">' +
    patientMessage?.phone +
    '</div>' +
    '</div>' +
    ' </div>' +
    (showFlagList?.zsFlag == 'true'
      ? ' <div style="display:flex;font-size: 12px;width:460px;margin:0 20px;;float:left;">' +
        '   <div style=";width:460px;float:left;display:flex">' +
        '   <div style="width:40px;">主诉：' +
        '</div>' +
        '   <div style="width:420px;border-bottom:1px solid #000;">' +
        detailMessage?.chiefComplaint +
        '</div>' +
        '</div>' +
        ' </div>'
      : '') +
    ' <div style="display:flex;font-size: 12px;width:460px;margin:0 20px;;float:left;">' +
    '   <div style=";width:320px;display:flex">' +
    '   <div style="width:60px;">临床诊断：' +
    '</div>' +
    '   <div style="width:250px;border-bottom:1px solid #000;">' +
    disease +
    '</div>' +
    '</div>' +
    '   <div style=";width:140px;display:flex">' +
    '   <div style="width:62px;">开方日期：' +
    '</div>' +
    '   <div style="width:78px;border-bottom:1px solid #000;">' +
    detailMessage?.createTime +
    '</div>' +
    '</div>' +
    ' </div>' +
    // ' <div style="font-size: 12px;width:460px;height:6px;float:left;margin:0 20px;border-bottom:1px dashed #777777;">' +
    // ' </div>' +
    ' <div style="display:flex;justify-content:space-between;font-size: 12px;width:460px;margin:0px 20px 0px 20px;"> ' + 
    ' <div style="width:50%;font-weight:bold;font-size: 18px;">Rp.</div>' + 
   ( paymentMessage?.createTime ?
   ' <div style="width:50%;display:flex;justify-content:flex-end;margin-top:4px">结算时间：' + 
    paymentMessage?.createTime + 
    '</div>'  : '<div style="width:50%"></div>')  +  
    '</div>' +
    ' <div style="width:460px;min-height:360px;margin:0 20px;float:left">' +
    ' <div style="text-align: left;float:left;width:460px;display:flex;flex-wrap:wrap;flex-basic:230px;flex-shrink: 0">' +
    zyMain +
    '</div>'
  content +=
    '   <div style="font-size: 12px;width:460px;height:30px;margin:10px 0;float:left;overflow:hidden;text-overflow: ellipsis;display:-webkit-box; -webkit-box-orient: vertical;webkit-line-clamp: 2;">用法：' +
    tcmMessage?.recipeUseMethod +
    '</div>'
  content +=
    Number(indexs) + 1 == allPage
      ? '   <div style="float:left;font-size: 10px;width:460px;display:flex;justify-content:center;float:left;margin-top:5px">----- 以下处方完毕-----</div>' +
        '</div>'
      : '   <div style="float:left;font-size: 10px;width:460px;display:flex;justify-content:center;float:left;margin-top:5px">-----接下页-----</div>' +
        '</div>'
  content +=
    '   <div style="width:460px;display:flex;justify-content:space-between;">' +
    '   <div style="width:400px;display:flex;flex-direction:column">' +
    ' <div style="text-align: left;font-size: 14px;width:400px;margin-top:20px;float:left">' +
    (tcmMessage?.machiningTypeMsg
      ? '<i style="border-bottom:1px solid #333;"> ' +
        tcmMessage?.machiningTypeMsg +
        ' ,' +
        '</i>'
      : '') +
    '<span > ' +
    '共' +
    tcmMessage?.tipCount +
    '帖' +
    ' ,' +
    '</span>' +
    '<span > ' +
    '每帖重量：' +
    tcmMessage?.mtCount +
    'g' +
    ' ,' +
    '</span>' +
    '<span > ' +
    '总重量：' +
    tcmMessage?.allCount +
    'g' +
    '</span>' +
    ' </div>' +
    (showFlagList?.jeFlag == 'true'
      ? ' <div style="text-align: left;font-size: 14px;width:400px;margin-top:10px">' +
        '<span> ' +
        '每帖药费：' +
        '¥ ' +
        tcmMessage?.costPerPatch +
        ' ,' +
        '</span>' +
        '<span > ' +
        '煎药费' +
        ' ¥ ' +
        (tcmMessage?.fryingFee != 'NaN' ? tcmMessage?.fryingFee : '0.00') +
        ' ,' +
        '</span>' +
        '<span > ' +
        '合计' +
        ' ¥ ' +
        tcmMessage?.amount +
        '</span>' +
        ' </div>'
      : '') +
    ' </div>' +
    '   <div style="font-size: 14px;width:56px;margin-top:20px"><div style="width:56px;height:56px;border:1px solid #333;display:flex;;float:left;flex-direction:column;align-items:center;justify-content:center"><div>收费</div><div>盖章</div></div></div>' +
    ' </div>'
  content +=
    ' <div style="font-size: 12px;float:left;width:460px;height:6px;border-bottom:1px dashed #777777;">' +
    ' </div>' +
    ' <div style="text-align: left;float:left;font-size: 12px;width:460px;margin:10px 0px 0 0px;display:flex;">' +
    ' <div style="display: flex;font-size: 12px;width:110px;margin-right:5px">' +
    '   <div style="width:40px;">医师：</div>' +
    (doctorMessage?.physicianType != 2
      ? '   <div style="flex:1;border-bottom:1px solid #000;height:16px;">' +
        (doctorMessage?.physicianSignType == 1
          ? ysdzqm
          : doctorMessage?.physicianName) +
        '</div>'
      : '   <div style="flex:1;border-bottom:1px solid #000;height:16px;">' +
        '</div>') +
    '</div>' +
    ' <div style="display: flex;font-size: 12px;width:110px;margin-right:5px">' +
    '   <div style="width:60px;">配药药师：</div>' +
    (doctorMessage?.pharmacistType != 2
      ? '   <div style="flex:1;border-bottom:1px solid #000;height:16px;">' +
        (doctorMessage?.pharmacistSignType == 1
          ? pyysdzqm
          : doctorMessage?.pharmacistName) +
        '</div>'
      : '   <div style="flex:1;border-bottom:1px solid #000;height:16px;">' +
        '</div>') +
    '</div>' +
    ' <div style="display: flex;font-size: 12px;width:110px;margin-right:5px">' +
    '   <div style="width:60px;">复核药师：</div>' +
    (doctorMessage?.reviewerPharmacistType != 2
      ? '   <div style="flex:1;border-bottom:1px solid #000;height:16px;">' +
        (doctorMessage?.reviewerPharmacistSignType == 1
          ? fhysdzqm
          : doctorMessage?.reviewerPharmacistName) +
        '</div>'
      : '   <div style="flex:1;border-bottom:1px solid #000;height:16px;">' +
        '</div>') +
    '</div>' +
    ' <div style="display: flex;font-size: 12px;width:110px;margin-right:5px">' +
    '   <div style="width:40px;">签名：</div>' +
    (dzqm && doctorMessage?.signatureType != 2
      ? '   <div style="flex:1;height:16px;">' + dzqm + '</div>'
      : '   <div style="flex:1;border-bottom:1px solid #000;height:16px;">' +
        '</div>') +
    '</div>' +
    ' </div>' +
    (dzqm && doctorMessage?.signatureType != 2
      ? ' <div style="text-align: left;float:left;font-size: 12px;width:460px;margin:20px 20px 0 20px">'
      : ' <div style="text-align: left;float:left;font-size: 12px;width:460px;margin:6px 20px 0 20px">') +
    '   <div style="width:460px;color:#777777">除主诊医师特别注明外,处方仅当日有效。按卫生部规定,药房药品一经发出,不得退换。</div>' +
    ' </div>' +
    // ' <div style="text-align: center;font-size: 14px;width:380px;margin:0 20px;float:left;">' +
    // '   <div style="padding: 10px 0;font-size: 14px;"><span>第' +
    // (Number(pageSize) + 1) +
    // '页/共' +
    // allPage +
    // '页</span></div>' +
    // ' </div>' +
    '</div>' +
    '</div>'

  return content
}

// 诊断证明书打印
export function PrintDiagnosisCertificateContent(data: any) {
  // 拆分后的数组
  const tenantName = data?.tenantName
  const dataListMain = data?.dataList
  const patientMessage = data?.patientMessage
  const detailMessage = data?.detailMessage
  const disease = _.chain(detailMessage?.disease)
    .split(',')
    .map((d) => d.split('/')[0])
    .value()
    ?.join(',')
  // 电子签名
  const address = patientMessage?.patientAddress

  let zyMain = ''

  // 病历号一维码
  let blhImg = ''
  if (detailMessage?.codeImg) {
    blhImg =
      '<img src=' +
      detailMessage?.codeImg +
      ' style="width:136px;height:56px;font-weight:bold;margin-left:20px" />'
  }

  for (let i = 0; i < dataListMain?.length; i++) {
    zyMain +=
      '   <div style="font-size: 14px;display:flex;float:left;align-items:center;width:230px;">' +
      '<span style="font-weight:bold;margin-right:4px"> ' +
      (Number(i) + 1) +
      '.' +
      dataListMain[i]?.name +
      '</span>' +
      '<span style="font-weight:bold;margin-right:4px"> ' +
      dataListMain[i]?.countPerTip +
      dataListMain[i]?.doseUnit +
      '/' +
      '</span>' +
      '<span style="font-weight:bold;margin-right:4px"> ' +
      dataListMain[i]?.drugCount +
      dataListMain[i]?.doseUnit +
      '</span>' +
      '<span style="font-size: 12px;;margin-right:4px"> ' +
      (dataListMain[i]?.normalUsemethod == '无' ||
      dataListMain[i]?.normalUsemethod == '煎服'
        ? ''
        : dataListMain[i]?.normalUsemethod
        ? '(' + dataListMain[i]?.normalUsemethod + ')'
        : '') +
      '</span>' +
      '</div>'
  }

  let content =
    '<div style="width:422px;page-break-after:always;page-break-inside:always;page-break-before:always;">' +
    '   <div style="margin: 5px 0">' +
    '   <div style="display:flex ;position:relative">' +
    '   <div style="display: flex;align-items: flex-end;width:126px;position:absolute;bottom:4px">' +
    blhImg +
    ' </div>' +
    ' <div style="display: flex;flex-direction: column">' +
    ' <div style="text-align: center;float:left;width:422px">' +
    '   <div style="padding: 10px 0;font-size: 20px;"><span>' +
    tenantName +
    '</span></div>' +
    ' </div>' +
    ' <div style="width:422px;text-align: center;float:left;font-weight:bold;">' +
    '   <div style="float:left;font-size: 20px;width:172px;text-align: right;"><span>诊</span></div>' +
    '   <div style="float:left;font-size: 20px;width:25px;"><span>断</span></div>' +
    '   <div style="float:left;font-size: 20px;width:25px;"><span>证</span></div>' +
    '   <div style="float:left;font-size: 20px;width:25px;"><span>明</span></div>' +
    '   <div style="float:left;font-size: 20px;width:20px;text-align: left;"><span>书</span></div>' +
    ' </div>' +
    ' </div>' +
    ' </div>' +
    ' <div style="display:flex;justify-content:flex-end;font-size: 12px;width:410px;margin:0px 0px 8px 0px;padding:0 10px;"><span>诊断日期 : </span>' +
    detailMessage?.createTime +
    '</div>' +
    ' <div style="display:flex;justify-content:space-between;align-items:center;font-size: 12px;width:400px;height:30px;padding:0 10px;border-left:1px solid #333;border-right:1px solid #333;border-top:1px solid #333;">' +
    '   <div >姓名：' +
    patientMessage?.name +
    '</div>' +
    '   <div >性别：' +
    patientMessage?.sex +
    '</div>' +
    '   <div >年龄：' +
    getAge(patientMessage?.ageYear, patientMessage?.ageMonth, '岁') +
    '</div>' +
    '   <div >病历号：' +
    patientMessage?.id +
    '</div>' +
    ' </div>' +
    ' <div style="display:flex;justify-content:space-between;font-size: 12px;width:400px;height:30px;padding:0 10px;border-left:1px solid #333;border-right:1px solid #333;">' +
    '   <div style="margin-top:4px">地址： ' +
    address +
    '</div>' +
    ' </div>' +
    ' <div style="display:flex;justify-content:space-between;font-size: 12px;width:400px;height:50px;padding:0 10px;border-left:1px solid #333;border-right:1px solid #333;border-top:1px solid #333;">' +
    '   <div style="margin-top:10px">诊断： ' +
    disease +
    '</div>' +
    ' </div>' +
    ' <div style="font-size: 12px;width:400px;height:100px;padding:0 10px;border-left:1px solid #333;border-right:1px solid #333;border-top:1px solid #333;border-bottom:1px solid #333;">' +
    '   <div style="margin-top:10px;height:50px">意见： ' +
    detailMessage?.treatmentConclusion +
    '</div>' +
    '   <div style="display:flex;height:50px">' +
    '   <div style="display:flex;width:200px;justify-content:flex-start;">医生签名: ' +
    '</div>' +
    '   <div style="display:flex;width:200px;justify-content:flex-start;">盖章: ' +
    '</div>' +
    '</div>' +
    ' </div>'
  content +=
    ' <div style="display:flex;justify-content:space-between;font-size: 12px;width:410px;margin:10px 0 0 0;padding:0 0 0 10px;">' +
    ' <div style="font-size: 12px;width:200px;text-align:left">' +
    '注：此证明未经加盖公章无效' +
    '</div>' +
    ' <div style="font-size: 12px;width:200px;display:flex;justify-content:flex-end;">' +
    '打印时间：' +
    moment().format(DateTimeFormat)
  '</div>' + ' </div>' + '</div>' + '</div>'

  return content
}
