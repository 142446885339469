/*
 * @Descripttion:
 * @version:
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-05-25 09:17:44
 * @LastEditors: linxi
 * @LastEditTime: 2024-11-07 17:19:28
 */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { api,sendAsync } from '../../app/applicationSlice'
import { RootState, RootThunk, RootThunkApi } from '../../app/store'
import { stockfloorPage, StockfloorParams } from '../../services/stockfloor'

const initialState = {
  StockfloorData: [],
  Total: 0,
  Current: 1,
  pageLoading: false,
}

export const stockfloorSlice = createSlice({
  name: 'stockfloor',
  initialState,
  reducers: {
    setData(state, action) {
      state.StockfloorData = action.payload
    },
    setTotal(state, action) {
      state.Total = action.payload
    },
    setCurrent(state, action) {
      state.Current = action.payload
    },
    setPageLoading: (state, action) => {
      state.pageLoading = action.payload
    },
  },
})

export function pageStockfloor(params: StockfloorParams): RootThunk {
  return api(stockfloorPage(params), (data: any, dispatch) => {
    dispatch(setData(data.records))
    dispatch(setTotal(data.total))
    dispatch(setPageLoading(false))
  })
}

export const pageStockfloorList = createAsyncThunk<
  void,
  StockfloorParams,
  RootThunkApi<void>
>('toothPatient/pageStockfloorList', async (params, api) => {
  return sendAsync(stockfloorPage(params), api)
})

export const {
  setData,
  setTotal,
  setCurrent,
  setPageLoading,
} = stockfloorSlice.actions

export const selectStockfloorData = (state: RootState) =>
  state.stockfloor.StockfloorData

export const selectTotal = (state: RootState) => state.stockfloor.Total

export const selectCurrent = (state: RootState) => state.stockfloor.Current

export const selectPageLoading = (state: RootState) =>
  state.stockfloor.pageLoading

export default stockfloorSlice.reducer
