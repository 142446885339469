/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-09-13 14:53:45
 * @LastEditors: linxi
 * @LastEditTime: 2024-09-27 11:02:03
 */
import { Col, Divider, Popover, Row, Table, Tooltip } from 'antd'
import moment from 'moment'
import React, { useContext } from 'react'
import {
  getRecipeCategoryName,
  RecipeCategory,
  RecipeStatus,
} from '../../../models/recipe'
import { ThemeContext } from '../../../theme/ThemeContext'
import { TcmMedicineMaterialColumns } from '../editor/columns'
import styles from './TcmCases.module.css'

interface HistoryContentProps {
  v: any
}

export const HistoryContent = ({ v }: HistoryContentProps) => {
  const theme = useContext(ThemeContext)
  return (
    <div>
      {v.itemList.length ? (
        <>
          <Divider />
          <Row>
            {getRecipeCategoryName(v.category)}
            {v?.state === RecipeStatus.Returned && '（已退费）'}
            {v?.state === RecipeStatus.ToSettle && '（未结算）'}
            {v?.state === RecipeStatus.Unknown && '（未结算）'}
            {v?.state === RecipeStatus.Settled && '（已结算）'}
          </Row>
          {v.category == RecipeCategory.Tcm ||
          v.category === RecipeCategory.TcmGranules ? (
            <Row gutter={[4, 4]}>
              {v.itemList.map((el: any, i: number) => (
                <Popover
                  key={i}
                  overlayClassName={styles.popverContent}
                  zIndex={999999}
                  placement='bottom'
                  content={
                    <>
                      <div>
                        {el.name}（{el?.tenantSupplierName}）
                      </div>
                    </>
                  }
                >
                  <Col span={8} key={i} style={{ cursor: 'pointer' }}>
                    {el.name} {el.countPerTip}
                    {el.splitTag ? el.splitUnit : el.accountUnit}
                  </Col>
                </Popover>
              ))}
            </Row>
          ) : (
            <Table
              pagination={false}
              size='small'
              bordered
              columns={TcmMedicineMaterialColumns(theme)}
              dataSource={v.itemList}
            />
          )}
        </>
      ) : (
        ''
      )}
    </div>
  )
}
