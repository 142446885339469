import { CloseCircleOutlined, ExclamationCircleFilled } from '@ant-design/icons'
import {
  Button,
  Popconfirm,
  TableColumnType,
  Popover,
  Col,
  Space,
  Modal,
} from 'antd'
import React from 'react'
import { useSelector } from 'react-redux'
import {
  selectInsuranceArray,
  selectTenantName,
} from '../../app/applicationSlice'
import { StateSwitch } from '../../compnents/widgets/StateSwitch'
import { BillingCategoryName } from '../../models/billingCategory'
import {
  getApproveFlgName,
  getChrgitmLvName,
  getMaterialsName,
  Materials,
} from '../../models/commodity'
import { Dimen } from '../../models/dimen'
import { getOtcCategoryName } from '../../models/Otccategory'
import styles from './Commodity.module.css'
import { Payment } from '../../models/payment'

export const Columns = (
  onClick: (text: string, id: any, item?: any) => void,
  startIndex: number,
  queries: any
): TableColumnType<any>[] => {
  const insuranceArray = useSelector(selectInsuranceArray)
  const tenantName = useSelector(selectTenantName)

  const colums: TableColumnType<any>[] = [
    {
      title: '序号',
      align: 'center',
      // fixed: 'left',
      width: Dimen.Num,
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
    // {
    //   title: "商品名",
    //   align: "center",
    //   fixed: "left",
    //   dataIndex: "name",
    //   width: Dimen.Name,
    //   key: "name",
    //   sorter: (a, b) => a.name.localeCompare(b.name),
    // },
    {
      title: '商品信息',
      // fixed: "left",
      width: '300px',
      ellipsis: true,
      render: function Element(_, record) {
        return (
          <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Col>
              <Popover
                content={
                  <div
                    style={{
                      height: '55px',
                      color:
                        record.count <=
                        (record.inventoryAlert > 0 ? record.inventoryAlert : 0)
                          ? 'red'
                          : '',
                    }}
                  >
                    <p
                      style={{
                        height: '28px',
                        lineHeight: '36px',
                        padding: '0',
                        margin: '0',
                        fontSize: '16px',
                        fontWeight: 'bolder',
                      }}
                    >
                      {record.name}
                      {record.count <=
                      (record.inventoryAlert > 0 ? record.inventoryAlert : 0)
                        ? '(库存预警)'
                        : ''}
                    </p>
                    <p
                      style={{
                        height: '28px',
                        lineHeight: '24px',
                        padding: '0',
                        margin: '0',
                        color: '#999999',
                        fontSize: '14px',
                      }}
                    >
                      {record.materialsCategory == '0'
                        ? record.packSpec +
                          '/' +
                          record.doseCategory +
                          '/' +
                          record.tenantSupplierName
                        : record.packSpec +
                          '/' +
                          record.spec +
                          '/' +
                          record.tenantSupplierName}
                    </p>
                  </div>
                }
                placement='bottom'
              >
                <div
                  style={{
                    height: '55px',
                    width: '300px',
                    overflow: 'hidden',
                    color:
                      record.count <=
                      (record.inventoryAlert > 0 ? record.inventoryAlert : 0)
                        ? 'red'
                        : '',
                  }}
                >
                  <p
                    style={{
                      height: '28px',
                      lineHeight: '36px',
                      padding: '0',
                      margin: '0',
                      fontSize: '16px',
                      fontWeight: 'bolder',
                    }}
                  >
                    {record.name}
                    {record.count <=
                    (record.inventoryAlert > 0 ? record.inventoryAlert : 0)
                      ? '(库存预警)'
                      : ''}
                  </p>
                  <p
                    style={{
                      height: '28px',
                      lineHeight: '24px',
                      padding: '0',
                      margin: '0',
                      color: '#999999',
                      fontSize: '14px',
                    }}
                  >
                    {record.materialsCategory == '0'
                      ? record.packSpec +
                        '/' +
                        record.doseCategory +
                        '/' +
                        record.tenantSupplierName
                      : record.packSpec +
                        '/' +
                        record.spec +
                        '/' +
                        record.tenantSupplierName}
                  </p>
                </div>
              </Popover>
            </Col>
          </Space>
        )
      },
      // 商品名/厂家/规格/剂型）name/tenantSupplierName/packSpec/doseCategory
    },
    // {
    //   title: '商品编码',
    //   align: 'center',
    //   dataIndex: 'no',
    //   key: 'no',
    //   width: Dimen.No,
    // },
    {
      title: '通用名',
      align: 'center',
      dataIndex: 'genericName',
      width: '11rem',
      ellipsis: true,
      key: 'nogenericName',
      render: (_, t) => {
        return Number(t.genericName) !== 0 ? t.genericName : ''
      },
    },
    {
      title: '匹配状态',
      align: 'center',
      dataIndex: 'approveFlg',
      width: '6rem',
      ellipsis: true,
      key: 'approveFlg',
      className: insuranceArray.length == 0 ? 'notShow' : '',
      render: function Element(_, t) {
        return (
          !t.hilistCode ? '未匹配':
          <Button type={Number(t.approveFlg) ? 'link' : 'text'}>
            {getApproveFlgName(Number(t.approveFlg))}
          </Button>
        )
      },
    },
    {
      title: '医保国码',
      align: 'center',
      dataIndex: 'hilistCode',
      width: '16rem',
      ellipsis: true,
      className: insuranceArray.length == 0 ? 'notShow' : '',
      key: 'hilistCode',
      render: function Element(_, t) {
        if (t.materialsCategory !== 4) {
          return (
            <div className={t.hilistCode ? styles.delMedicalCode : ''}>
              <div
                className={styles.hilistName}
                onClick={() => {
                  onClick('insurance', t.id, t)
                }}
              >
                {t.hilistCode && <div>{t.hilistName}</div>}
                <div>
                  <Button type='link'>{t.hilistCode || '匹配'}</Button>
                  {getChrgitmLvName(t.chrgitmLv)}
                </div>
              </div>
              {t.hilistCode && (
                <span className={styles.Close}>
                  <CloseCircleOutlined
                    onClick={() => {
                      onClick('delCode', t.id, t)
                    }}
                  />
                </span>
              )}
            </div>
          )
        } else {
          return <></>
        }
      },
      // render: function Element(_, t) {
      //   return (
      //     <Button
      //       type='link'
      //       onClick={() => {
      //         onClick('insurance', t.id, t)
      //       }}
      //     >
      //       查看
      //     </Button>
      //   )
      // },
    },
    {
      title: '医保限价',
      align: 'center',
      width: '7rem',
      ellipsis: true,
      render: function Element(_, t) {
        const xj = Number(t.priceLimit || 0).toFixed(4)
        return (
          <span style={{ color: t.isCouldBx !== '0' ? '#15ad31' : '#000000' }}>
            {Number(xj) > 0 && Number(xj) <= 99998
              ? xj
              : Number(xj) == -1
              ? '-'
              : '不限价'}
          </span>
        )
      },
    },
    // {
    //   title: '规格',
    //   align: 'center',
    //   dataIndex: 'packSpec',
    //   width: Dimen.PackSpec,
    //   key: 'packSpec',
    //   render: (_, t) => {
    //     return Number(t.packSpec) !== 0 ? t.packSpec : ''
    //   },
    // },
    // {
    //   title: '单位',
    //   align: 'center',
    //   dataIndex: 'accountUnit',
    //   width: Dimen.Unit,
    //   key: 'accountUnit',
    // },
    {
      title: '批准文号',
      align: 'center',
      dataIndex: 'approvalNumber',
      width: Dimen.ApprovalNumber,
      ellipsis: true,
      key: 'approvalNumber',
    },
    {
      title: '拆零标志',
      align: 'center',
      dataIndex: 'splitTag',
      width: Dimen.State,
      ellipsis: true,
      key: 'splitTag',
      render: (splitTag: number) => {
        return splitTag > 0 ? '是' : '否'
      },
    },
    {
      title: '商品类别',
      align: 'center',
      dataIndex: 'materialsCategory',
      width: Dimen.State,
      sorter: (a: any, b: any) => a.materialsCategory - b.materialsCategory,
      render: (_, t) => {
        return getMaterialsName(t.materialsCategory)
      },
    },
    {
      title: '开票项目',
      align: 'center',
      width: Dimen.State,
      dataIndex: 'billingCategory',
      sorter: (a: any, b: any) => a.billingCategory - b.billingCategory,
      render: (billingCategory: any) => {
        return BillingCategoryName(billingCategory)
      },
    },
    // {
    //   title: '生产厂家',
    //   align: 'center',
    //   dataIndex: 'tenantSupplierName',
    //   key: 'tenantSupplierName',
    // },
    {
      title: '零售价',
      align: 'right',
      dataIndex: 'retailPrice',
      width: Dimen.Category,
      ellipsis: true,
      key: 'tenantSupplierName',
      render: (_, t) => {
        return t.retailPrice
          ? parseFloat(t.retailPrice).toFixed(5)
          : parseFloat('0').toFixed(5)
      },
    },
    {
      title: 'OTC标志',
      align: 'center',
      dataIndex: 'otc',
      width: Dimen.State,
      ellipsis: true,
      render: (_, t) => {
        return BillingCategoryName(t.billingCategory) !== '中草药' &&
          t.materialsCategory == 0
          ? getOtcCategoryName(t.otc)
          : '-'
        // return getOtcCategoryName(t.otc);
      },
    },
    {
      title: '最近一次修改',
      // fixed: "left",
      align: 'center',
      width: '10rem',
      ellipsis: true,
      render: function Element(_, t) {
        return (
          <a
            onClick={(e) => {
              e.preventDefault()
              if (t?.lastUpdateTime) {
                onClick('lastEditOperation', t?.id, t)
              }
            }}
            style={{ cursor: 'pointer' }}
          >
            {t?.lastUpdateTime}
          </a>
        )
      },
    },
    {
      title: '状态',
      align: 'center',
      dataIndex: 'state',
      width: Dimen.Status,
      sorter: (a: any, b: any) => a.state - b.state,
      render: function Element(_, t) {
        return (
          <div style={{ height: '39px' }}>
            <Popconfirm
              title={'确认进行' + (t.state === 1 ? '注销' : '启用') + '操作？'}
              onConfirm={() => {
                onClick('setState', t.id)
              }}
              onCancel={() => {
                return
              }}
              okText='确认'
              cancelText='取消'
            >
              <a href='#'>
                <StateSwitch value={t.state === 1} />
              </a>
            </Popconfirm>
            {/* <StateSwitch
              value={t.state === 1}
              onChange={() => onClick('setState', t.id)}
            /> */}
          </div>
        )
      },
    },
    {
      title: '操作',
      align: 'center',
      fixed: 'right',
      width: '10rem',
      render: function Element(_, t) {
        return (
          <>
            <Button
              type='primary'
              ghost
              size='small'
              style={{ marginRight: '6px' }}
              onClick={() => {
                onClick('edit', t.id, t)
              }}
            >
              编辑
            </Button>
            <Button
              type='primary'
              ghost
              size='small'
              onClick={() => {
                Modal.confirm({
                  centered: true,
                  title: '确认操作',
                  content: (
                    <div>
                      <ExclamationCircleFilled
                        style={{ color: '#FFAE55', fontSize: 18 }}
                      />
                      &nbsp;您确定复制目录吗？
                    </div>
                  ),
                  okText: '确认',
                  cancelText: '取消',
                  icon: null,
                  onOk: () => {
                    onClick('copyCatalog', t.id, t)
                  },
                })
              }}
            >
              复制目录
            </Button>
          </>
        )
      },
    },
  ]
  const result =
    queries?.materialsCategory == '0'
      ? tenantName?.indexOf('余姚健齿口腔诊所有限公司') >= 0 ||
        tenantName?.indexOf('余姚康民内科诊所') >= 0
        ? colums.filter((v) => v.key != 'approveFlg')
        : colums
      : tenantName?.indexOf('余姚健齿口腔诊所有限公司') >= 0 ||
        tenantName?.indexOf('余姚康民内科诊所') >= 0
      ? colums.filter((v) => v.key != 'nogenericName' && v.key != 'approveFlg')
      : colums.filter((v) => v.key != 'nogenericName')
  return result
}
export const Column = (
  onClick: (text: string, id: any, item?: any) => void,
  startIndex: number,
  queries: any
): TableColumnType<any>[] => {
  const insuranceArray = useSelector(selectInsuranceArray)
  const tenantName = useSelector(selectTenantName)

  const colums: TableColumnType<any>[] = [
    {
      title: '序号',
      align: 'center',
      // fixed: 'left',
      width: Dimen.Num,
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
    // {
    //   title: "商品名",
    //   align: "center",
    //   fixed: "left",
    //   dataIndex: "name",
    //   width: Dimen.Name,
    //   key: "name",
    //   sorter: (a, b) => a.name.localeCompare(b.name),
    // },
    {
      title: '商品信息',
      // fixed: "left",
      render: function Element(_, record) {
        return (
          <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Col>
              <Popover
                content={
                  <div
                    style={{
                      height: '55px',
                      color:
                        record.count <=
                        (record.inventoryAlert > 0 ? record.inventoryAlert : 0)
                          ? 'red'
                          : '',
                    }}
                  >
                    <p
                      style={{
                        height: '28px',
                        lineHeight: '36px',
                        padding: '0',
                        margin: '0',
                        fontSize: '16px',
                        fontWeight: 'bolder',
                      }}
                    >
                      {record.name}
                      {record.count <=
                      (record.inventoryAlert > 0 ? record.inventoryAlert : 0)
                        ? '(库存预警)'
                        : ''}
                    </p>
                    <p
                      style={{
                        height: '28px',
                        lineHeight: '24px',
                        padding: '0',
                        margin: '0',
                        color: '#999999',
                        fontSize: '14px',
                      }}
                    >
                      {record.materialsCategory == '0'
                        ? record.packSpec +
                          '/' +
                          record.doseCategory +
                          '/' +
                          record.tenantSupplierName
                        : record.packSpec +
                          '/' +
                          record.spec +
                          '/' +
                          record.tenantSupplierName}
                    </p>
                  </div>
                }
                placement='bottom'
              >
                <div
                  style={{
                    height: '55px',
                    color:
                      record.count <=
                      (record.inventoryAlert > 0 ? record.inventoryAlert : 0)
                        ? 'red'
                        : '',
                  }}
                >
                  <p
                    style={{
                      height: '28px',
                      lineHeight: '36px',
                      padding: '0',
                      margin: '0',
                      fontSize: '16px',
                      fontWeight: 'bolder',
                    }}
                  >
                    {record.name}
                    {record.count <=
                    (record.inventoryAlert > 0 ? record.inventoryAlert : 0)
                      ? '(库存预警)'
                      : ''}
                  </p>
                  <p
                    style={{
                      height: '28px',
                      lineHeight: '24px',
                      padding: '0',
                      margin: '0',
                      color: '#999999',
                      fontSize: '14px',
                    }}
                  >
                    {record.materialsCategory == '0'
                      ? record.packSpec +
                        '/' +
                        record.doseCategory +
                        '/' +
                        record.tenantSupplierName
                      : record.packSpec +
                        '/' +
                        record.spec +
                        '/' +
                        record.tenantSupplierName}
                  </p>
                </div>
              </Popover>
            </Col>
          </Space>
        )
      },
      // 商品名/厂家/规格/剂型）name/tenantSupplierName/packSpec/doseCategory
    },
    // {
    //   title: '商品编码',
    //   align: 'center',
    //   dataIndex: 'no',
    //   key: 'no',
    //   width: Dimen.No,
    // },
    {
      title: '通用名',
      align: 'center',
      dataIndex: 'genericName',
      width: Dimen.GenericName,
      key: 'nogenericName',
      render: (_, t) => {
        return Number(t.genericName) !== 0 ? t.genericName : ''
      },
    },
    // {
    //   title: '匹配状态',
    //   align: 'center',
    //   dataIndex: 'approveFlg',
    //   width: Dimen.GenericName,
    //   key: 'approveFlg',
    //   className: insuranceArray.length == 0 ? 'notShow' : '',
    //   render: function Element(_, t) {
    //     return (
    //       <Button type={Number(t.approveFlg) ? 'link' : 'text'}>
    //         {getApproveFlgName(Number(t.approveFlg))}
    //       </Button>
    //     )
    //   },
    // },
    // {
    //   title: '医保国码',
    //   align: 'center',
    //   dataIndex: 'hilistCode',
    //   width: Dimen.ApprovalNumber,
    //   className: insuranceArray.length == 0 ? 'notShow' : '',
    //   key: 'hilistCode',
    //   render: function Element(_, t) {
    //     if (t.materialsCategory !== 4) {
    //       return (
    //         <div className={t.hilistCode ? styles.delMedicalCode : ''}>
    //           <div
    //             className={styles.hilistName}
    //             onClick={() => {
    //               onClick('insurance', t.id, t)
    //             }}
    //           >
    //             {t.hilistCode && <div>{t.hilistName}</div>}
    //             <div>
    //               <Button type='link'>{t.hilistCode || '匹配'}</Button>
    //               {getChrgitmLvName(t.chrgitmLv)}
    //             </div>
    //           </div>
    //           {t.hilistCode && (
    //             <span className={styles.Close}>
    //               <CloseCircleOutlined
    //                 onClick={() => {
    //                   onClick('delCode', t.id, t)
    //                 }}
    //               />
    //             </span>
    //           )}
    //         </div>
    //       )
    //     } else {
    //       return <></>
    //     }
    //   },
    //   // render: function Element(_, t) {
    //   //   return (
    //   //     <Button
    //   //       type='link'
    //   //       onClick={() => {
    //   //         onClick('insurance', t.id, t)
    //   //       }}
    //   //     >
    //   //       查看
    //   //     </Button>
    //   //   )
    //   // },
    // },
    // {
    //   title: '规格',
    //   align: 'center',
    //   dataIndex: 'packSpec',
    //   width: Dimen.PackSpec,
    //   key: 'packSpec',
    //   render: (_, t) => {
    //     return Number(t.packSpec) !== 0 ? t.packSpec : ''
    //   },
    // },
    // {
    //   title: '单位',
    //   align: 'center',
    //   dataIndex: 'accountUnit',
    //   width: Dimen.Unit,
    //   key: 'accountUnit',
    // },
    {
      title: '批准文号',
      align: 'center',
      dataIndex: 'approvalNumber',
      width: Dimen.ApprovalNumber,
      key: 'approvalNumber',
    },
    {
      title: '拆零标志',
      align: 'center',
      dataIndex: 'splitTag',
      width: Dimen.State,
      key: 'splitTag',
      render: (splitTag: number) => {
        return splitTag > 0 ? '是' : '否'
      },
    },
    {
      title: '商品类别',
      align: 'center',
      dataIndex: 'materialsCategory',
      width: Dimen.Category,
      sorter: (a: any, b: any) => a.materialsCategory - b.materialsCategory,
      render: (_, t) => {
        return getMaterialsName(t.materialsCategory)
      },
    },
    {
      title: '开票项目',
      align: 'center',
      width: Dimen.BillingCategory,
      dataIndex: 'billingCategory',
      sorter: (a: any, b: any) => a.billingCategory - b.billingCategory,
      render: (billingCategory: any) => {
        return BillingCategoryName(billingCategory)
      },
    },
    // {
    //   title: '生产厂家',
    //   align: 'center',
    //   dataIndex: 'tenantSupplierName',
    //   key: 'tenantSupplierName',
    // },
    {
      title: '零售价',
      align: 'right',
      dataIndex: 'retailPrice',
      width: Dimen.Price,
      key: 'tenantSupplierName',
      render: (_, t) => {
        return t.retailPrice
          ? parseFloat(t.retailPrice).toFixed(5)
          : parseFloat('0').toFixed(5)
      },
    },
    {
      title: 'OTC标志',
      align: 'center',
      dataIndex: 'otc',
      width: Dimen.State,
      render: (_, t) => {
        return BillingCategoryName(t.billingCategory) !== '中草药' &&
          t.materialsCategory == 0
          ? getOtcCategoryName(t.otc)
          : '-'
        // return getOtcCategoryName(t.otc);
      },
    },
    {
      title: '状态',
      align: 'center',
      dataIndex: 'state',
      width: Dimen.Status,
      sorter: (a: any, b: any) => a.state - b.state,
      render: function Element(_, t) {
        return (
          <div style={{ height: '39px' }}>
            <Popconfirm
              title={'确认进行' + (t.state === 1 ? '注销' : '启用') + '操作？'}
              onConfirm={() => {
                onClick('setState', t.id)
              }}
              onCancel={() => {
                return
              }}
              okText='确认'
              cancelText='取消'
            >
              <a href='#'>
                <StateSwitch value={t.state === 1} />
              </a>
            </Popconfirm>
            {/* <StateSwitch
              value={t.state === 1}
              onChange={() => onClick('setState', t.id)}
            /> */}
          </div>
        )
      },
    },
    {
      title: '操作',
      align: 'center',
      fixed: 'right',
      width: '6rem',
      render: function Element(_, t) {
        return (
          <>
            <Button
              type='primary'
              ghost
              size='small'
              style={{ marginRight: '6px' }}
              onClick={() => {
                onClick('edit', t.id, t)
              }}
            >
              编辑
            </Button>
            <Button
              type='primary'
              ghost
              size='small'
              onClick={() => {
                Modal.confirm({
                  centered: true,
                  title: '确认操作',
                  content: (
                    <div>
                      <ExclamationCircleFilled
                        style={{ color: '#FFAE55', fontSize: 18 }}
                      />
                      &nbsp;您确定复制目录吗？
                    </div>
                  ),
                  okText: '确认',
                  cancelText: '取消',
                  icon: null,
                  onOk: () => {
                    onClick('copyCatalog', t.id, t)
                  },
                })
              }}
            >
              复制目录
            </Button>
          </>
        )
      },
    },
  ]
  const result =
    queries?.materialsCategory == '0'
      ? tenantName?.indexOf('余姚健齿口腔诊所有限公司') >= 0 ||
        tenantName?.indexOf('余姚康民内科诊所') >= 0
        ? colums.filter((v) => v.key != 'approveFlg')
        : colums
      : tenantName?.indexOf('余姚健齿口腔诊所有限公司') >= 0 ||
        tenantName?.indexOf('余姚康民内科诊所') >= 0
      ? colums.filter((v) => v.key != 'nogenericName' && v.key != 'approveFlg')
      : colums.filter((v) => v.key != 'nogenericName')
  return result
}

export const saveDurgColumns = (startIndex: number): TableColumnType<any>[] => {
  return [
    {
      title: '序号',
      align: 'center',
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
    {
      title: '通用名(商品名)',
      align: 'center',
      dataIndex: 'genericName',
      key: 'genericName',
    },
    {
      title: '规格',
      align: 'center',
      dataIndex: 'packSpec',
      key: 'packSpec',
    },
    {
      title: '商品类型',
      align: 'center',
      dataIndex: 'materialsCategory',
      key: 'materialsCategory',
      render: (_, t) => {
        return getMaterialsName(t.materialsCategory)
      },
    },
    {
      title: '生产厂家',
      align: 'center',
      dataIndex: 'tenantSupplierName',
      key: 'tenantSupplierName',
    },
    {
      title: '零售价',
      align: 'right',
      dataIndex: 'retailPrice',
      key: 'retailPrice',
    },
  ]
}
