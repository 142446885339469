import {
  CaretDownFilled,
  CaretDownOutlined,
  CaretUpOutlined,
  MedicineBoxOutlined,
} from '@ant-design/icons'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Col,
  Dropdown,
  Form,
  Input,
  Menu,
  notification,
  Popconfirm,
  Row,
  Select,
  Space,
  Table, TableColumnType,
} from 'antd'
import Modal from 'antd/lib/modal/Modal'
import _ from 'lodash'
import moment from 'moment'
import React, {
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectInsuranceArray,
  selectUserName,
  selectTenantAddress,
  selectTenantAreaCd,
  selectTenantName,
  selectUserId,
  selectTenantCategory,
} from '../../app/applicationSlice'
import { RootDispatch } from '../../app/store'
import {
  DateRangeFieldName,
  DateRangeType,
  DateSelect,
  getDateRange,
} from '../../compnents/form/DateSelect'
import { EditableList } from '../../compnents/list/EditableList'
import { selectInsuranceItem, setInsurance } from '../../layouts/insuranceSlice'
import { traceRoute } from '../../layouts/layoutSlice'
import {
  DateTimeFormat,
  DateTimeFormatSimpleOnlyOne,
} from '../../models/datetime'
import { DeliveryState } from '../../models/delivery'
import { Dimen } from '../../models/dimen'
import { DefaultPageSize } from '../../models/pagination'
import { getGenderName } from '../../models/user'
import { DeliveryListParams } from '../../services/delivery'
import { ThemeContext } from '../../theme/ThemeContext'
import { strParse, medicalSendHttp, readCardEHttp } from '../../utils/MedicalUtils'
import { uploadOptions } from '../InventoryChecking/query'
import {
  drugUploadAAsync,
  drugUploadAsync, returnDrugUploadAAsync,
  returnDrugUploadAsync,
  selectPutstorageData,
} from '../putstorage/putstorageSlice'
import { RecipeListModal } from '../recipe/modal/RecipeListModal'
import {
  DeliveryItem,
  directDeliver,
  getDeliveryList,
  selectDeliveryListPage,
  getDeliveryListExoprt,
} from './deliverySlice'
import { serverType } from '../../services/commodity'
import { getAge } from '../../utils/StringUtils'
import { DataType } from '../putstorage/putstorage'
import { TableRowSelection } from 'antd/lib/table/interface'
import { PrintTemplateCategory } from '../../models/print'
import { PrintTemplateModal } from '../print/PrintTemplateModal'

interface DeliveryListProps {
  deliveryState: DeliveryState
  tockSalesUploadCategoryType?: number
  station?:any
  shipmentsState?: number
}

export const DeliveryList = ({
  deliveryState,
  tockSalesUploadCategoryType,
  station,
  shipmentsState,
}: DeliveryListProps): ReactElement => {
  const inputRef = useRef<any>(null)

  const dispatch = useDispatch<RootDispatch>()

  const theme = useContext(ThemeContext)

  const [form] = Form.useForm()

  const page = useSelector(selectDeliveryListPage(deliveryState))

  const [head, tail] = getDateRange(DateRangeType.Today)

  const [queries, setQueries] = useState<DeliveryListParams>({
    current: 0,
    size: DefaultPageSize,
    shipmentsState: deliveryState,
    timeHead: head,
    timeTail: tail,
  })

  const [errors, setErrors] = useState<{ name: string; message: string }[]>([])

  const [isModalVisible, setIsModalVisible] = useState(false)

  const [treatmentId, setTreatmentId] = useState('')

  const [confirmLoading, setConfirmLoading] = useState(false)

  const { Option } = Select

  const data = useSelector(selectPutstorageData)

  const baseData = useSelector(selectInsuranceItem)

  const [insuranceItem, setInsuranceItem] = useState<any>()

  const insuranceArray = useSelector(selectInsuranceArray)

  const userName = useSelector(selectUserName)

  const tenantAddress = useSelector(selectTenantAddress)

  const tenantAreaCd = useSelector(selectTenantAreaCd)

  const tenantName = useSelector(selectTenantName)

  const userId = useSelector(selectUserId)

  const tenantCategory = useSelector(selectTenantCategory)

  const [selectIds, setSelectIds] = useState<any>('')

  const cycleCode = sessionStorage.getItem('cycleCode')

  const [printMenueVisible, setPrintMenueVisible] = useState(false)

  const [treatmentPrintTreatmentId, setTreatmentPrintTreatmentId] = useState('')

  const [printTemplateCategory, setPrintTemplateCategory] = useState<
    PrintTemplateCategory | undefined
  >()

  const [recipeId, setRecipeId] = useState('')

  const dataParams = JSON.parse(
    sessionStorage.getItem(location.pathname) || '{}'
  )

  const [pageLoading, setPageLoading] = useState(false) //表格 loading

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      setSelectIds(selectedRowKeys)
    },
    selectedRowKeys: selectIds,
  }

  // 保存医保结构体
  useEffect(() => {
    if (insuranceItem && JSON.stringify(insuranceItem) !== '{}') {
      dispatch(
        setInsurance({
          ...insuranceItem,
          userName,
          userId,
          cycleCode,
          tenantCategory,
          tenantAreaCd,
          name: tenantName,
          address: tenantAddress,
          areaCode: tenantAreaCd,
          endTime: moment(insuranceItem.endTime).format(
            DateTimeFormatSimpleOnlyOne
          ),
        })
      )
    }
    return () => {
      dispatch(setInsurance({}))
    }
  }, [insuranceItem])

  useEffect(() => {
    if (insuranceArray.length !== 0) {
      setInsuranceItem(insuranceArray[0])
    }
  }, [insuranceArray])

  // 发药单上传
  const drugUpload = (
    serverType: serverType,
    treatmentId: number | string,
    responseString?: string,
    recipeItemId?: string,
    paymentId?:number
  ) => {
    dispatch(
      drugUploadAsync({
        serverType,
        body: {
          baseData: {
            ...baseData,
            responseString,
          },
          treatmentId,
          shipmentState: 1,
          recipeItemId: recipeItemId || undefined,
          paymentId:paymentId,
        },
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        if (serverType === 'requestString') {
          const data = strParse(res)
          if(data?.isdone == "1"){
            notification.success({
              message: '上传完毕！',
            })
            dispatch(getDeliveryList(queries))
            return;
          }
          const itemId = data.recipeItemId
          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                baseData?.countryHospitalNb,
                baseData?.secretToken,
              ],
              message: '发药单上传',
            },
            (call: any, err) => {
              !err &&
              drugUpload(
                'response',
                treatmentId,
                JSON.stringify(call.netmessage),
                itemId,
                paymentId
              )
            }
          )
        } else {
          if (res.successCode === '1') {
            drugUpload('requestString', treatmentId,undefined,undefined,paymentId)
          } else {
            dispatch(getDeliveryList(queries))
          }
        }
      })
  }


  // 发药单批量上传
  const drugUploadA = (
    serverType: serverType,
    paymentIds: number[] | string[],
    responseString?: string,
    recipeId?: number
  ) => {
    dispatch(
      drugUploadAAsync({
        serverType,
        body: {
          baseData: {
            ...baseData,
            responseString,
          },
          paymentIds,
          shipmentState: 1,
          recipeId: recipeId,
        },
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        if (serverType === 'requestString') {
          let data = null
          try {
            data = JSON.parse(res)
          } catch (e) {
            data = strParse(res)
          }
          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                baseData?.countryHospitalNb,
                baseData?.secretToken,
              ],
              message: '发药单上传',
            },
            (call: any, err) => {
              !err &&
              drugUploadA(
                'response',
                paymentIds,
                JSON.stringify(call.netmessage),
                recipeId
              )
            }
          )
        } else {
          dispatch(getDeliveryList(queries))
        }
      })
  }

  // 退药单上传
  const returnDrugUpload = (
    serverType: serverType,
    treatmentId: number | string,
    responseString?: string,
    recipeItemId?: string,
    paymentId?:number
  ) => {
    dispatch(
      returnDrugUploadAsync({
        serverType,
        body: {
          baseData: {
            ...baseData,
            responseString,
          },
          treatmentId,
          shipmentState: 9,
          recipeItemId: recipeItemId || undefined,
          paymentId:paymentId
        },
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        if (serverType === 'requestString') {
          let data = null
          try {
            data = JSON.parse(res)
          } catch (e) {
            data = strParse(res)
          }
          if(data?.isdone == "1"){
            notification.success({
              message: '上传完毕！',
            })
            dispatch(getDeliveryList(queries))
            return;
          }
          const itemId = data.recipeItemId
          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                baseData?.countryHospitalNb,
                baseData?.secretToken,
              ],
              message: '发药单上传',
            },
            (call: any, err) => {
              !err &&
                returnDrugUpload(
                  'response',
                  treatmentId,
                  JSON.stringify(call.netmessage),
                  itemId,
                  paymentId
                )
            }
          )
        } else {
          if (res.successCode === '1') {
            returnDrugUpload('requestString', treatmentId,undefined,undefined,paymentId)
          } else {
            dispatch(getDeliveryList(queries))
          }
        }
      })
  }


  const returnDrugUploadA = (
    serverType: serverType,
    paymentIds: number[] | string[],
    responseString?: string,
    recipeId?: number
  ) => {
    dispatch(
      returnDrugUploadAAsync({
        serverType,
        body: {
          baseData: {
            ...baseData,
            responseString,
          },
          paymentIds,
          shipmentState: 9,
          recipeId: recipeId || undefined,
        },
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        if (serverType === 'requestString') {
          const data = strParse(res)
          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                baseData?.countryHospitalNb,
                baseData?.secretToken,
              ],
              message: '发药单上传',
            },
            (call: any, err) => {
              !err &&
              returnDrugUploadA(
                'response',
                paymentIds,
                JSON.stringify(call.netmessage),
                recipeId
              )
            }
          )
        } else {
          dispatch(getDeliveryList(queries))
        }
      })
  }

  useEffect(() => {
    if (!queries.current) return
    setPageLoading(true)
    dispatch(getDeliveryList({ ...queries, [DateRangeFieldName]: undefined }))
      .then(unwrapResult)
      .then((res: any) => {
        if (queries.param?.length == 9 && res.items.length == 1) {
          dispatch(
            traceRoute({
              name: '库房发药',
              path: '/delivery/manual',
              query: `treatmentId=${res.items[0][1].treatmentId}&paymentId=${res.items[0][1]?.paymentId}&state=${deliveryState}&station=${station}&tockSalesUploadCategory=${tockSalesUploadCategoryType?tockSalesUploadCategoryType:''}`,
            })
          )
        }
      })
      .finally(() => {
        setPageLoading(false)
      })
  }, [queries])

  useEffect(() => {
    if (!queries.current) return
    sessionStorage.setItem(location.pathname, JSON.stringify(queries))
  }, [queries, deliveryState])

  useEffect(() => {
    if (
      Object.keys(dataParams).length &&
      dataParams?.shipmentsState === deliveryState
    ) {
      form.setFieldsValue({
        ...dataParams,
        param: '',
      })
    } else {
      form.setFieldsValue({
        [DateRangeFieldName]: DateRangeType.Today,
        timeHead: head,
        timeTail: tail,
      })
    }
    inputRef.current!.focus({
      //获取焦点
      cursor: 'start',
    })
    form.submit()
  }, [])

  const refresh = () => {
    const values = form.getFieldsValue()
    setQueries({ ...dataParams, ...queries, ...values, current: 1 })
  }

  const actionName = deliveryState === DeliveryState.Fulfilled ? '退药' : '发药'

  const massUpload = () => {
    if (selectIds?.length === 0) {
      notification.info({
        message: '请选择上传的数据',
      })
    }
    if (selectIds?.length > 10) {
      notification.info({
        message: '一次最多上传10条',
      })
    } else {
      selectIds?.map((id: any) => {
        if (tockSalesUploadCategoryType === 1) {
          const record = page.items as any
          const item = record.find((v: any) => {
            return v[1].paymentId === id
          }) as any

          if (item[1]?.ybSaleUploadStatus === 0) {
            if(insuranceItem?.insuranceCode === 'ZHEJIANG'){
              drugUploadA('requestString', [id])
            }else {
              drugUpload('requestString', item[0].treatmentId ,undefined,undefined,id)
            }


          }
        } else if (tockSalesUploadCategoryType === 9) {
          const record = page.items as any
          const item = record.find((v: any) => {
            return v[1].paymentId === id
          }) as any
          if (item[1]?.ybSaleUploadStatus === 1) {
            if(insuranceItem?.insuranceCode === 'ZHEJIANG'){
              returnDrugUploadA('requestString', [id])
            }else {
              returnDrugUpload('requestString', item[0].treatmentId,undefined,undefined,id )
            }


          }
        }
      })
    }
  }

    // 实体卡读卡
    const readEType = () => {
      readCardEHttp(
        {
          trade_args: [
            insuranceItem.countryHospitalNb,
            '01306',
            '',
            userId,
            userName,
            '',
            '',
          ],
        },
        (data, err) => {
          console.log("ddd");
          if (!err) {
            const idCardNumber = data?.netmessage?.split('|')?.[1];
            if(idCardNumber){
              form.setFieldsValue({
                param:idCardNumber
              })
            }
          }
        }
      )
    }
  

  const outCluedExport = () => {
    dispatch(getDeliveryListExoprt({ ...dataParams, ...queries }))
      .then(unwrapResult)
      .then((res: any) => {
        const content = res
        const blob = new Blob([content])
        const fileName = '库房发药.xlsx'
        const selfURL = window[window.webkitURL ? 'webkitURL' : 'URL']
        const elink = document.createElement('a')
        if ('download' in elink) {
          elink.download = fileName
          elink.style.display = 'none'
          elink.href = selfURL['createObjectURL'](blob)
          document.body.appendChild(elink)
          // 触发链接
          elink.click()
          selfURL.revokeObjectURL(elink.href)
          document.body.removeChild(elink)
        } else {
          navigator.msSaveBlob(blob, fileName)
        }
      })
      .then(() => {
        notification.success({
          message: '导出成功',
          duration: 3,
        })
      })
      .catch((res: any) => {
        return
      })
  }

  const columns: TableColumnType<any>[] = [
    {
      title: '序号',
      dataIndex: 'no',
      align: 'center',
      width: Dimen.Num,
      render: (_1, _2, i) => `${(page.current - 1) * page.size + i + 1}`,
    },
    {
      title: '门诊号',
      key: 'outpatientNo',
      align: 'center',
      width: Dimen.OutpatientNo,
      render: (_, [t]) => t.outpatientNo,
    },

    {
      title: '患者姓名',
      key: 'patientName',
      width: Dimen.Name,
      align: 'center',
      render: (_, [, t]) => t.patientName,
    },
    {
      title: '病历号',
      key: 'patientId',
      width: Dimen.Sex,
      align: 'center',
      dataIndex: 'patientId',
      render: (_, [t]) => t.patientId,
    },
    {
      title: '性别',
      key: 'gender',
      width: Dimen.Sex,
      align: 'center',
      render: (_, [, t]) => getGenderName(t.patientSex),
    },
    {
      title: '年龄',
      key: 'patientAge',
      width: Dimen.Age,
      align: 'center',
      render: (_, [r]) => getAge(r?.patientAge, r?.patientMonth),
    },
    {
      title: '疾病名称',
      key: 'disease',
      align: 'center',
      render: (_1, [, t]) =>
        _.chain(t.disease)
          .split(',')
          .map((d) => d.split('/')[0])
          .value(),
    },
    {
      title: '诊断科室',
      key: 'treatmentDepartmentName',
      width: Dimen.Department,
      align: 'center',
      render: (_, [, t]) => t.treatmentDepartmentName,
    },
    {
      title: '诊断医生',
      key: 'treatmentDoctorName',
      width: Dimen.Doctor,
      align: 'center',
      render: (_, [, t]) => t.treatmentDoctorName,
    },
    {
      title:
        deliveryState == DeliveryState.Returned ? '退费时间' : '结算时间',
      key: 'treatmentDoctorName',
      width: Dimen.Doctor,
      align: 'center',
      render: (_, [, t]) =>
        t.paymentTime
          ? moment(t.paymentTime).format(DateTimeFormat)
          : '-',
    },
    {
      title: '追溯码上传',
      key: 'traceCode',
      width: Dimen.Doctor,
      align: 'center',
      render: function DeliveryActions(_, [, t]) {
        return (
          <Button
            type='primary'
            disabled={_[1].ybSaleUploadStatus === 1}
            size='small'
            onClick={()=>{
              if(insuranceItem?.insuranceCode === 'ZHEJIANG'){
                drugUploadA('requestString', [t.paymentId])
              }else {
                drugUpload('requestString', t.treatmentId,undefined,undefined,t.paymentId )
              }
            }}
          >
            {_[1].ybSaleUploadStatus === 1 ? '已上传':'未上传'}
          </Button>
        )
      }
    },
    {
      title: '操作',
      key: 'action',
      align: 'center',
      width: '8rem',
      render: function DeliveryActions(_, [, t]) {

        if (tockSalesUploadCategoryType) {

          let uploadFlag = false
          if (tockSalesUploadCategoryType === 1 && _[1].ybSaleUploadStatus === 1) {
            uploadFlag = true
          } else if (tockSalesUploadCategoryType === 9 && _[1].ybSaleUploadStatus === 2) {
            uploadFlag = true
          }

          return uploadFlag ? (
            '已上传'
          ) : (
            <Popconfirm
              title='确认执行此操作？'
              okText='确认'
              onConfirm={() => {
                if (tockSalesUploadCategoryType === 1) {
                  if(insuranceItem?.insuranceCode === 'ZHEJIANG'){
                    drugUploadA('requestString', [t.paymentId])
                  }else {
                    drugUpload('requestString', t.id ,undefined,undefined,t.paymentId )
                  }
                } else if (tockSalesUploadCategoryType === 9) {
                  if(insuranceItem?.insuranceCode === 'ZHEJIANG'){
                    returnDrugUploadA('requestString',[t.paymentId])
                  }else {
                    returnDrugUpload('requestString', t.id,undefined,undefined,t.paymentId )
                  }
                }
              }}
              cancelText='取消'
            >
              <Button type='primary' ghost size='small'>
                上传
              </Button>
            </Popconfirm>
          )
        } else {
          return (
            <Space>
              {deliveryState == DeliveryState.Returned &&
              t.payStatus == '5' ? (
                <></>
              ) : (
                <>
                  <Popconfirm
                    placement='top'
                    title={`是否${actionName}`}
                    okButtonProps={{ loading: confirmLoading }}
                    onConfirm={() => {
                      setConfirmLoading(true)
                      dispatch(
                        directDeliver({
                          paymentId: t.paymentId,
                          deliveryState,
                        })
                      )
                        .then(unwrapResult)
                        .then((s) => {
                          if (s.successful) {
                            notification.success({
                              message: '操作成功',
                            })
                            dispatch(getDeliveryList(queries))
                            setConfirmLoading(false)
                          } else {
                            setConfirmLoading(false)
                            setErrors(s.errors)
                            if (s.errors.length !== 0) {
                              setIsModalVisible(true)
                            } else {
                              notification.error({
                                message: s.message || '一键发药失败',
                              })
                            }
                          }
                        })
                        .catch((v) => {
                          setConfirmLoading(false)
                        })
                    }}
                    okText='确认'
                    cancelText='取消'
                  >
                    <Button type='primary' size='small'>
                      一键{actionName}
                    </Button>
                  </Popconfirm>
                  <Button
                    type='primary'
                    size='small'
                    onClick={() =>
                      dispatch(
                        traceRoute({
                          name: '库房发药',
                          path: '/delivery/manual',
                          query: `treatmentId=${t.id}&paymentId=${t?.paymentId}&state=${deliveryState}&station=${station}&tockSalesUploadCategory=${tockSalesUploadCategoryType? tockSalesUploadCategoryType :''}`,
                        })
                      )
                    }
                  >
                    手动{actionName}
                  </Button>
                </>
              )}
              <Dropdown
                overlay={
                  <Menu>
                    <Menu.Item
                      key='0'
                      onClick={() => {
                        setPrintMenueVisible(false)
                        setTreatmentId(t.id)
                      }}
                    >
                      打印处方
                    </Menu.Item>
                    <Menu.Item
                      key='1'
                      onClick={() => {
                        setPrintMenueVisible(false)
                        setPrintTemplateCategory(
                          PrintTemplateCategory.YHT
                        )
                        setTreatmentPrintTreatmentId(t.id)
                      }}
                    >
                      打印药盒贴
                    </Menu.Item>
                    <Menu.Item
                      key='2'
                      onClick={() => {
                        setPrintMenueVisible(false)
                        setPrintTemplateCategory(
                          PrintTemplateCategory.Zxd
                        )
                        setTreatmentPrintTreatmentId(t.id)
                      }}
                    >
                      打印输液单
                    </Menu.Item>
                     {deliveryState == DeliveryState.Unfilled ?  <Menu.Item
                      key='3'
                      onClick={() => {
                        setPrintMenueVisible(false)
                        setPrintTemplateCategory(
                          PrintTemplateCategory.Jd
                        )
                        setTreatmentPrintTreatmentId(t.id)
                      }}
                    >
                      打印瓶贴
                    </Menu.Item> :<></>}
                  </Menu>
                }
                trigger={['click']}
              >
                <Button type='primary' size='small'>
                  打印
                  {printMenueVisible ? (
                    <CaretUpOutlined />
                  ) : (
                    <CaretDownOutlined />
                  )}
                </Button>
              </Dropdown>
            </Space>
          )
        }
      },
    },
  ]

  return (
    <Col
      style={{
        marginTop: 10,
        height: 'calc(100% - 10px)',
        overflowY: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.pn,
        borderRadius: '10px',
        padding: '0px 15px',
      }}
    >
      <Form
        form={form}
        autoComplete='off'
        onFinish={(values) => {
          const data =
            dataParams?.shipmentsState === deliveryState ? dataParams : {}
          setQueries({
            ...data,
            ...queries,
            ...values,
            current: data?.current || 1,
          })
        }}
      >
        <Row
          align='middle'
          style={{ height: '3.75rem', alignItems: 'center' }}
          justify='space-between'
        >
          <Space>
            <Form.Item name='param' noStyle>
              <Input
                ref={inputRef}
                placeholder='患者姓名/手机号/病历号'
                style={{ width: '15rem' }}
                onChange={refresh}
              />
            </Form.Item>
            <DateSelect
              placeholder={
                deliveryState == DeliveryState.Returned
                  ? '退费时间'
                  : '结算时间'
              }
              labelPrefix={
                ''
                // deliveryState == DeliveryState.Returned
                //   ? '退费时间-'
                //   : '结算时间-'
              }
              namePrefix='time'
              style={{ width: '8.5rem' }}
              // initialValue={DateRangeType.Today}
              allowClear={false}
              onChange={refresh}
            />
            {tockSalesUploadCategoryType && (
              <Form.Item name='ybUploadFlag' noStyle>
                <Select
                  placeholder='是否已上传'
                  allowClear
                  style={{ width: '8.5rem' }}
                  // suffixIcon={<CaretDownFilled />}
                  onChange={refresh}
                >
                  {uploadOptions.map((v) => (
                    <Option value={v.value} key={v.value}>
                      {v.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            <Button type='primary' onClick={refresh}>
              查询
            </Button>
            {insuranceItem && insuranceItem?.insuranceCode === 'JIANGSU'  && (<Button
            type='primary'
            onClick={() => {
              readEType()
            }}
          >
            读电子凭证
          </Button>)}
          </Space>
          <Space>
            {!tockSalesUploadCategoryType && (
              <Button type='primary' onClick={() => outCluedExport()}>
                导出
              </Button>
            )}
          </Space>
          <Space>
            {tockSalesUploadCategoryType && (
              <Button type='primary' onClick={() => massUpload()}>
                批量上传
              </Button>
            )}
          </Space>
        </Row>
      </Form>
      <EditableList<DeliveryItem>
        page={page}
        loading={pageLoading}
        onRow={([, t]) => ({
          onDoubleClick: () => {
            dispatch(
              traceRoute({
                name: '库房发药',
                path: '/delivery/manual',
                query: `treatmentId=${t.id}&paymentId=${t?.paymentId}&state=${deliveryState}&station=${station}&tockSalesUploadCategory=${tockSalesUploadCategoryType ? tockSalesUploadCategoryType:''}`,
              })
            )
          },
        })}
        rowSelection={
          {
            ...rowSelection,
          } as TableRowSelection<any>
        }
        columns={
          tockSalesUploadCategoryType || shipmentsState != 1
          ? columns.filter((m) => m.key !== 'traceCode')
          : columns
        }
        onChangePage={
          (current, size) => {
            setQueries({ ...queries, current, size })
          }
          // dispatch(getDeliveryList({ ...queries, current, size }))
        }
        rowKey={(m: any) => m[1]?.paymentId}
      />
      <Modal
        title={`${actionName}失败`}
        visible={isModalVisible}
        onCancel={() => {
          setIsModalVisible(false)
        }}
        cancelButtonProps={{
          style: {
            display: 'none',
          },
        }}
        okText='确认'
        onOk={() => {
          setErrors([])
          setIsModalVisible(false)
        }}
      >
        <Table
          dataSource={errors}
          columns={[
            {
              title: '药品名称',
              dataIndex: 'name',
              align: 'center',
            },
            {
              title: '药品数量',
              dataIndex: 'count',
              align: 'center',
            },
            {
              title: '原因',
              dataIndex: 'message',
              align: 'center',
            },
          ]}
          pagination={false}
          rowKey={(_, i) => `${i}`}
        />
      </Modal>
      <RecipeListModal
        fyState={true}
        shipmentsState={shipmentsState}
        treatmentId={treatmentId}
        visible={!!treatmentId}
        onCancel={() => setTreatmentId('')}
      />
      <PrintTemplateModal
        templateCategory={printTemplateCategory}
        treatmentId={treatmentPrintTreatmentId}
        visible={!!treatmentPrintTreatmentId}
        onCancel={() => setTreatmentPrintTreatmentId('')}
      />
    </Col>
  )
}
