/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-11-16 09:39:17
 * @LastEditors: linxi
 * @LastEditTime: 2024-08-23 11:45:47
 */
import { Button, Form, Modal, notification, Select, Typography } from 'antd'
import React, { ReactElement, useEffect, useState } from 'react'
import { DisposeItem } from './disposeItem'
import { RecipeListEditor } from '../../../../treatment/editor/RecipeListEditor'
import styles from './dispose.module.css'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../../../../app/store'
import {
  selectPatientInfo,
  getAllRecipeByRegistrationIdAsync,
  getAllTreatmentWithRecipeByPatientidAsync,
  getRecipeItemByRecipeIdAsync,
  getRegistByPatientIdGneralAsync,
} from '../../patientSlice'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  selectStationCategory,
  selectTenantId,
  selectUserId,
} from '../../../../../app/applicationSlice'
import {
  checkTreatmentAsync,
  finishTreatment,
  resetRecipeList,
} from '../../../../treatment/editor/recipeListEditorSlice'
import { AddDisposeModal } from '../../components/addDisposeModal'
import { StationCategory } from '../../../../../models/user'
import { getChangeRegistration } from '../../../../patient/patientSlice'
import { RegisterNoAppointModal } from '../../../../registration/list/RegisterNoAppointModal'
import { getChangeRegisterAppointData } from '../../../../registration/register/registerSlice'

const { Text } = Typography

const { Option } = Select

interface DisposeProps {
  registration?: any
  patientId: string
  currentRegistrationId?: string
  refreshData?: () => void
}

export const Dispose = ({
  registration,
  patientId,
  currentRegistrationId,
  refreshData,
}: DisposeProps): ReactElement => {
  const [form] = Form.useForm()

  const dispatch = useDispatch<RootDispatch>()

  const tenantId = useSelector(selectTenantId)

  const userId = useSelector(selectUserId)

  const stationCategory = useSelector(selectStationCategory)

  const [editVisible, setEditVisible] = useState(false)

  const [addVisible, setAddVisible] = useState(false)

  const [disposeSelectOptions, setDisposeSelectOptions] = useState<any[]>([])

  const [registrationId, setRegistrationId] = useState('')

  const [
    registerNoAppointModalVisible,
    setRegisterNoAppointModalVisible,
  ] = useState(false)

  const [remark, setRemark] = useState('')

  const [registerNoAppointId, setRegisterNoAppointId] = useState('')

  const detail = useSelector(selectPatientInfo)

  useEffect(() => {
    patientId && getAllData()
  }, [patientId])

  const getAllData = () => {
    dispatch(getAllTreatmentWithRecipeByPatientidAsync({ patientId }))
      .then(unwrapResult)
      .then((res: any) => {
        setDisposeSelectOptions(
          res.map((v: any) => ({
            ...v,
            open: false,
            materials: null,
          }))
        )
      })
    // dispatch(getRegistByPatientIdGneralAsync({ patientId })).then(unwrapResult).then((res: any) => {
    //   setDisposeSelectOptions(res.map((v: any) => ({
    //     ...v,
    //     open: false,
    //     materials: null
    //   })))
    // })
  }

  useEffect(() => {
    !editVisible && dispatch(resetRecipeList())
  }, [editVisible])

  const changeDoctor = () => {
    dispatch(
      getChangeRegisterAppointData({
        doctorId: userId,
        registrationId: registrationId,
      })
    )
      .then(unwrapResult)
      .then((v) => {
        notification.success({
          message: '操作成功',
        })
      })
  }

  return (
    <div className={styles.disposeContainer}>
      <Text italic>
        <span style={{ color: '#999', fontSize: 14 }}>
          仅展示一年以内的历史处置记录
        </span>
      </Text>
      {disposeSelectOptions.map((v, i) => (
        <DisposeItem
          ListItem={v}
          key={i}
          onAction={(action, t) => {
            if (action == 'edit') {
              setRegistrationId(t.registrationId)
              if (
                registration?.registrationDepartmentId == '0' &&
                registration?.registrationDoctorId == '0'
              ) {
                // 如果是护士
                if (stationCategory == StationCategory.Nurse) {
                  dispatch(getChangeRegistration(t.registrationId))
                    .then(unwrapResult)
                    .then((v: any) => {
                      setEditVisible(true)
                    })
                    .catch((v) => {
                      setRemark(t?.remark?.replace('#', ','))
                      setRegisterNoAppointId(t.registrationId)
                      setRegisterNoAppointModalVisible(true)
                    })
                } else if (
                  stationCategory == StationCategory.DoctorOrPharmacist
                ) {
                  // 如果是医生
                  setEditVisible(true)
                }
              } else {
                // 正常情况
                setEditVisible(true)
              }
              // if (
              //   t.treatmentDepartmentId == '0' &&
              //   t.treatmentDoctorId == '0'
              // ) {
              //   setTimeout(() => {
              //     Modal.warn({
              //       title: '提示',
              //       content: '暂未指定医生，请先选择接诊医生再填写病历',
              //     })
              //   }, 500)

              //   // messageApi.open({
              //   //   type: 'warning',
              //   //   content: '请选择医生科室跟就诊医生',
              //   //   className: 'custom-class',
              //   //   style: {
              //   //     marginTop: '35vh',
              //   //   },
              //   // })
              // }
            } else if (action == 'done') {
              Modal.confirm({
                centered: true,
                title: '确认操作',
                width: 600,
                content: '确认完成该处置？',
                okText: '确认',
                cancelText: '取消',
                icon: null,
                onOk: () => {
                  dispatch(checkTreatmentAsync(t.treatmentId))
                    .then(unwrapResult)
                    .then(() => {
                      dispatch(finishTreatment(t.treatmentId))
                        .then(unwrapResult)
                        .then(() => {
                          getAllData()
                          refreshData && refreshData()
                        })
                    })
                },
              })
            } else if (action == 'open') {
              if (
                !disposeSelectOptions.find(
                  (v) => v.treatmentId == t.treatmentId
                ).materials
              ) {
                // dispatch(getAllRecipeByRegistrationIdAsync(t.registrationId)).then(unwrapResult).then((res: any) => {
                if (!t.recipeList?.length) {
                  notification.info({
                    message: '该处置下暂无处方',
                  })
                  return
                }
                t.recipeList.forEach((v: any) => {
                  dispatch(
                    getRecipeItemByRecipeIdAsync({
                      recipeId: v.id,
                      tenantId,
                    })
                  )
                    .then(unwrapResult)
                    .then((result: any) => {
                      setDisposeSelectOptions((el) => {
                        const cell = {
                          category: v.category,
                          list: result,
                          recipeId: v.id,
                        }
                        return el.map((item) => {
                          return item.treatmentId == t.treatmentId
                            ? {
                                ...item,
                                open: true,
                                materials: item.materials
                                  ? [...item.materials, cell]
                                  : [cell],
                              }
                            : item
                        })
                      })
                    })
                })
                // })
              } else {
                setDisposeSelectOptions(
                  disposeSelectOptions.map((v) => ({
                    ...v,
                    open: v.treatmentId == t.treatmentId ? !v.open : v.open,
                  }))
                )
              }
            }
          }}
        />
      ))}
      <Button
        type='primary'
        className={styles.addDispose}
        onClick={() => {
          setAddVisible(true)
          // setRegistrationId(t.registrationId)
          // setEditVisible(true)
        }}
      >
        新增处置
      </Button>
      <Modal
        title='编辑处置'
        width={1600}
        bodyStyle={{
          height: 700,
          padding: 10,
          position: 'relative',
        }}
        maskClosable={false}
        destroyOnClose
        visible={editVisible}
        onCancel={() => {
          // dispatch(resetRecipeList())
          setEditVisible(false)
          if (stationCategory == StationCategory.DoctorOrPharmacist) {
            changeDoctor()
          }
        }}
        footer={null}
        centered
      >
        <RecipeListEditor
          patientName={detail?.patientName}
          kqMoveTag={true}
          toothRegistrationId={registrationId}
          currentTab={'editor'}
          onAction={(action) => {
            if (action == 'wancheng') {
              if (stationCategory == StationCategory.DoctorOrPharmacist) {
                changeDoctor()
              }
              setEditVisible(false)
              getAllData()
              refreshData && refreshData()
            }
            if (action == 'jiesuan') {
              if (stationCategory == StationCategory.DoctorOrPharmacist) {
                changeDoctor()
              }
              getAllData()
            }
          }}
        />
      </Modal>

      <AddDisposeModal
        type={1}
        onCancel={() => {
          setAddVisible(false)
        }}
        addVisible={addVisible}
        patientId={patientId}
        registrationId={currentRegistrationId}
        onOk={(id) => {
          setRegistrationId(id)
          setAddVisible(false)
          // 未指定科室医生时
          if (
            registration?.registrationDepartmentId == '0' &&
            registration?.registrationDoctorId == '0'
          ) {
            // 如果是护士
            if (stationCategory == StationCategory.Nurse) {
              dispatch(getChangeRegistration(id))
                .then(unwrapResult)
                .then((v: any) => {
                  setEditVisible(true)
                })
                .catch((v) => {
                  setRemark(registration?.remark?.replace('#', ','))
                  setRegisterNoAppointId(id)
                  setRegisterNoAppointModalVisible(true)
                })
            } else if (stationCategory == StationCategory.DoctorOrPharmacist) {
              // 如果是医生
              setEditVisible(true)
            }
          } else {
            // 正常情况
            setEditVisible(true)
          }
          //  if (
          //    registration.treatmentDepartmentId == '0' &&
          //    registration.treatmentDoctorId == '0'
          //  ) {
          //    setTimeout(() => {
          //      Modal.warn({
          //        title: '提示',
          //        content: '暂未指定医生，请先选择接诊医生再填写病历',
          //      })
          //    }, 500)
          //  }
        }}
      />
      <RegisterNoAppointModal
        id={registerNoAppointId}
        remark={remark}
        visible={registerNoAppointModalVisible}
        onCancel={() => {
          setRegisterNoAppointModalVisible(false)
        }}
        onOk={() => {
          getAllData()
          refreshData && refreshData()
          setEditVisible(true)
          setRegisterNoAppointModalVisible(false)
        }}
      />
    </div>
  )
}
