/* eslint-disable react/display-name */
import { TableColumnType, Col, Button, Popconfirm } from 'antd'
import React, { ReactElement } from 'react'
import { Dimen } from '../../models/dimen'
import { ThemeData } from '../../theme/ThemeContext'

export const Columns = (
  startIndex?: any,
  onDelete?: (text: any, t: any) => void
): TableColumnType<any>[] => {
  return [
    {
      title: '序号',
      key: 'no',
      align: 'center',
      width: Dimen.Num,
      render: (_1, _2, i) => `${startIndex + i + 1}`,
    },
    {
      title: '药品名称',
      align: 'center',
      key: 'drugName',
      dataIndex: 'drugName',
      ellipsis: true,
      render: (_, r) => {
        return r.drugName ? r.drugName : '-'
      },
    },
    {
      title: '预览内容',
      align: 'center',
      key: 'reminderContent',
      dataIndex: 'reminderContent',
      ellipsis: true,
      render: (_, r) => {
        return r.reminderContent ? r.reminderContent : '-'
      },
    },
    {
      title: '操作',
      align: 'center',
      width: '90px',
      ellipsis: true,
      render: function Element(_, t) {
        return (
          <div>
            <Popconfirm
              title='确认此操作？'
              onConfirm={ () => {
                 onDelete && onDelete('delete', t)
              }}
              okText='确认'
              cancelText='取消'
            >
              <a style={{ color: 'red' }}>删除</a>
            </Popconfirm>
          </div>
        )
      },
    },
  ]
}

export const DrugSearchInput = (theme: ThemeData): TableColumnType<any>[] => {
  return [
    {
      title: '药品信息',
      key: 'name',
      render: function MaterialName(_, m): ReactElement {
        return (
          <Col style={{ cursor: 'pointer' }}>
            <div
              style={{
                width: 420,
                color: theme.tc1,
                fontSize: '1.125rem',
                fontWeight: 'bold',
              }}
            >
              {m.name}
            </div>
            <div
              title={
                m.spec +
                '/' +
                m.packSpec +
                '/' +
                m.accountUnit +
                '/' +
                m.doseCategory +
                '/' +
                m.tenantSupplierName
              }
              style={{
                width: 420,
                color: theme.tc2,
                fontSize: '1rem',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
            >
              {m.spec}/{m.packSpec}/{m.accountUnit}/{m.doseCategory}/
              {m.tenantSupplierName}
            </div>
          </Col>
          // </Popover>
        )
      },
    },
    {
      title: '单价/元',
      dataIndex: 'retailPrice',
      align: 'right',
    },
  ]
}
