import {
  CloseOutlined,
  DoubleRightOutlined,
  DoubleLeftOutlined,
  SyncOutlined,
  InfoCircleOutlined,
  ExclamationCircleFilled,
} from '@ant-design/icons'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Card,
  Col,
  Dropdown,
  message,
  Modal,
  notification,
  Row,
  Space,
  Table,
  Tabs,
  Tooltip,
  Carousel,
  Popover,
} from 'antd'
import moment from 'moment'
import React, {
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectEdition,
  selectInsuranceArray,
  selectTenantAddress,
  selectTenantAreaCd,
  selectTenantCategory,
  selectTenantName,
  selectUserId,
  selectUserName,
} from '../../../app/applicationSlice'
import { RootDispatch } from '../../../app/store'
import { traceRoute } from '../../../layouts/layoutSlice'
import { LoadingState } from '../../../models/common'
import { DateTimeFormatSimpleOnlyOne } from '../../../models/datetime'
import { patientInfo } from '../../../models/intelligence'
import { serverType } from '../../../services/commodity'

import {
  EditableRecipeCategories,
  getRecipeCategoryName,
  isRecipePayable,
  Recipe,
  RecipeCategory,
  RecipeStatus,
  ToothEditableRecipeCategories,
} from '../../../models/recipe'
import { DefaultUseMethod } from '../../../models/recipeusemethod'
import { Settings } from '../../../models/setting'
import { TreatmentNotice } from '../../../models/treatment'
import { getGenderName, getSexName } from '../../../models/user'
import { setUpdateFlags } from '../../../services/payment'
import { CopyTreatmentParams } from '../../../services/treatment'
import { ThemeContext } from '../../../theme/ThemeContext'
import { useQuery } from '../../../utils/HttpUtils'
import {
  strParse,
  medicalSendHttp,
  readCardEHttp, readCardSHttp, getTradeName,
} from '../../../utils/MedicalUtils'
import { getAge, getAgeName } from '../../../utils/StringUtils'
import { setActiveObj } from '../../A-toothModule/patient/patientSlice'
import {
  MdtrtinfoUpAsync,
  FeeListUpAsync,
  FeeListUpCancelAsync,
  preSettlementAsync,
  saveSettlementAsync,
  setUpdateFlagsAsync,
  outRecipeAsync,
  beforeAlertAsync,
  updateCardInfoAsync,
  recipeInventoryVerificationAsync,
  electronicPrescriptionInfoVerificationAsync,
  electronicPrescriptionSignAsync,
  electronicPrescriptionUploadAsync,
} from '../../payment/confirm/paymentConfirmSlice'
import { getUserProfileList } from '../../personalManagement/personalDetail/personalDetailSlice'
import { printTemplate } from '../../print/printSlice'
import { selectBooleanSetting } from '../../settings/settingsSlice'
import {
  copyTreatment,
  getIframePreScriptionAsync,
  getTreatment,
  getTreatments,
  selectRegistration,
  setRemindDetail,
} from '../diagnosis/diagnosisSlice'
import { RemindTips } from '../diagnosis/remindTips'
import { Treatments, TreatmentsRef } from '../list/Treatments'
import OutRecipeModal from './modals/outRecipe'
import { RecipeEditor } from './RecipeEditor'
import {
  getInsuranceToSelfpay, getRecipeDetail,
  selectRecipeMaterials,
} from './recipeEditorSlice'
import styles from './RecipeListEditor.module.css'
import {
  checkTreatmentAsync,
  createOrUpdateRecipe,
  finishTreatment,
  getRecipeList,
  getTreatmentNotice,
  removeRecipe,
  selectRecipes,
  selectTreatment,
  selectTreatmentUpdateFlgsAsync,
  setRecipeEditorTreatment,
  updateRecipe,
  setPatientInfo as toothSetPatientInfo,
} from './recipeListEditorSlice'
import { selectSettings } from '../../user/setting/settingSlice'
import { selectIsAllZifeiAsync } from '../beautyClinic/beautyClinicSlice'
import {
  getPatientInfoAsync, medicalRegisterAsync,
  medicalRegisterForMultiYidiAsync,
  searchPsnSumForLocalAsync
} from '../../registration/register/registerSlice'
import { getYBTypeName } from '../../../models/ybType'
import { PrintTemplateModal } from '../../print/PrintTemplateModal'
import { PrintTemplateCategory } from '../../../models/print'
import { setSelectId } from '../../commodity/commoditySlice'
import { sleep } from '../../../utils/SystemUtils'
import {Registration} from "../../../models/registration";
import {getTradeCode} from "../../../models/material";
const { TabPane } = Tabs

interface messageData {
  action: string
  params: {
    message: string
    hospitalId: string
    idcCode: string
    DoctorChoose: string
  }
}
type action = 'jiesuan' | 'wancheng'
const carouselRef: any = React.createRef()

export const RecipeListEditor = (props: {
  patientName?: any
  yzcfTag?: any //医嘱处方标志
  kqMoveTag?: any //口腔移动处方标志
  tag?: any
  currentTab?: string
  toothRegistrationId?: string
  onAction?: (action: action) => void
  refreshTreament?: (v?: any) => void
}): ReactElement => {
  const dispatch: any = useDispatch<RootDispatch>()

  const isShow: any = useSelector(selectSettings)[29] //判断是否开通智能审方

  const theme = useContext(ThemeContext)

  const treatment = useSelector(selectTreatment)

  const insuranceArray = useSelector(selectInsuranceArray)

  const recipes = useSelector(selectRecipes)

  const treatmentsRef = useRef<TreatmentsRef>(null)

  const outRecipeRef = useRef<any>(null)

  const registration = useSelector(selectRegistration)
  const [printTemplateCategory, setPrintTemplateCategory] = useState<
    PrintTemplateCategory | undefined
  >()

  const [selectedRecipeId, setSelectedRecipeId] = useState('')
  const [patientInsurance, setPatientInsurance] = useState<any>()

  const [isModalVisible, setIsModalVisible] = useState(false)

  const [mlBtnLoading, setmlBtnLoading] = useState(false)
  const [cfBtnLoading, setcfBtnLoading] = useState(false)
  const [kcBtnLoading, setkcBtnLoading] = useState(false)

  const [tipsCount, setTipsCount] = useState<any>()

  const settlementEnabled = useSelector(
    selectBooleanSetting(Settings.Treatment.Settlement)
  )

  const [openButton, setOpenButton] = useState<any>(false)

  const [notice, setNotice] = useState<TreatmentNotice | undefined>()

  const [checkData, setCheckData] = useState<any>([])

  const [readLoading, setReadLoading] = useState(false)

  const [finishingState, setFinishingState] = useState(LoadingState.Idle)

  const [isShowBeforeAlert, setIsShowBeforeAlert] = useState(false)

  const [beforeAlertMessage, setBeforeAlertMessage] = useState('')

  const [hasBeenAlerted, setHasBeenAlerted] = useState(false)

  const query = useQuery()

  const [recipesMemo, setRecipesMemo] = useState<any[]>([])

  const state = query.get('state') as string

  const registrationId =
    (query.get('registrationId') as string) || props.toothRegistrationId

  const treatmentId = query.get('treatmentId') as string
  const tenantAreaCd = useSelector(selectTenantAreaCd)

  const [data, setData] = useState<Recipe[]>([])

  const [isSettledFlag, setIsSettledFlag] = useState<any>()
  const hisVersion = useSelector(selectEdition) //区分口腔版还是普通

  const [createFlag, setCreateFlag] = useState(true)

  const [isAnimating, setIsAnimating] = useState(false)

  const [outRecipeDisable, setOutRecipeDisable] = useState(false)

  const [readCardLoading, setReadCardLoading] = useState(false)

  const [
    registrationForConvert,
    setRegistrationForConvert,
  ] = useState<any | null>()

  const [tempType, setTempType] = useState(false) //最近就诊详情

  const [showMemoStruct, setShowMemoStruct] = useState<any>({}) //显示出来的医保备注信息（取结构体的最后一条）

  const [isCopyReciptModalVisible, setIsCopyReciptModalVisible] = useState(
    false
  )
  const [searchPsnSumDisabled, setSearchPsnSumDisabled] = useState<boolean>(
    false
  )
  const [sumVisible, setSumVisible] = useState(false)

  const [isTipsModalVisible, setIsTipsModalVisible] = useState(false)

  const [sumList, setSumList] = useState<any>()
  const [isCopyReciptParam, setIsCopyReciptParam] = useState<any>()

  useEffect(() => {
    return () => {
      dispatch(setRemindDetail([]))
    }
  }, [])
  useEffect(() => {
    if (checkData?.length) {
      setData(
        checkData?.map((v: any) => {
          return { category: Number(v), amount: 0 } as Recipe
        })
      )
    }
  }, [checkData])

  useEffect(() => {
    if (!registration && isModalVisible) {
      dispatch(
        getTreatment({
          registrationId,
          treatmentId,
        })
      )
    }
  }, [registration, isModalVisible])

  // 接口重复调用，注释测试
  // useEffect(() => {
  //   if (!treatment)
  //     dispatch(
  //       getTreatments({
  //         registrationId,
  //         treatmentId,
  //       })
  //     )
  //       .then(unwrapResult)
  //       .then((v: any) => {
  //         dispatch(setRecipeEditorTreatment(v?.treatment))
  //       })
  // }, [treatment])

  useEffect(() => {
    if (createFlag) {
      if (data.length !== 0 && recipes.length === 0) {
        if (treatment) {
          dispatch(getRecipeList(treatment.id))
        }
        // data?.forEach((v: any) => {
        //   dispatch(
        //     createOrUpdateRecipe({
        //       treatmentId: treatment?.id,
        //       category: v?.category,
        //     } as Recipe)
        //   )
        //     .then(unwrapResult)
        //     .then(() => {
        //       // if (v?.category === 13)
        //       //   dispatch(
        //       //     createOrUpdateRecipe({
        //       //       treatmentId: treatment?.id,
        //       //       category: v?.category,
        //       //       tip: 7,
        //       //       useMethod: DefaultUseMethod.join(','),
        //       //     } as Recipe)
        //       //   )
        //       if (treatment) {
        //         dispatch(getRecipeList(treatment.id))
        //       }
        //       setCreateFlag(false)
        //     })
        //     .catch(() => {
        //       // do nothing.
        //     })
        // })
      }
    }
  }, [data])

  useEffect(() => {
    if (treatment?.id) {
      dispatch(getRecipeList(treatment.id))
        .then(unwrapResult)
        .then((recipes: any) => {
          if (!recipes.length) {
            dispatch(getUserProfileList({ userId: state }))
              .then(unwrapResult)
              .then((c: any) => {
                setCheckData(
                  c[1].codeName === '' ? undefined : c[1].codeName.split(',')
                )
              })
          }
        })
        .catch(() => {
          // do nothing.
        })
    }
  }, [dispatch, treatment?.id])

  useEffect(() => {
    if (registration?.patientId) {
      dispatch(getTreatmentNotice(registration.patientId))
        .then(unwrapResult)
        .then((notice: any) => setNotice(notice))
        .catch(() => {
          // do nothing
        })
    }
  }, [registration])

  useEffect(() => {
    if (recipes.length !== 0) {
      if (
        !selectedRecipeId ||
        !recipes.find((r) => r.id === selectedRecipeId)
      ) {
        setSelectedRecipeId(recipes[0].id)
      }
    } else {
      if (
        !selectedRecipeId ||
        !data.find((r: any) => r?.id === selectedRecipeId)
      ) {
        setSelectedRecipeId(data[0]?.id)
      }
    }
  }, [recipes, data])

  const initRecipes = async () => {
    await dispatch(
      createOrUpdateRecipe({
        category: 13,
        registrationId: registrationId,
      } as Recipe)
    ).then(unwrapResult)
    getTreatmentsDetail()
  }

  useEffect(() => {
    setOutRecipeDisable(treatment?.treatmentType == '1')
    dispatch(setRecipeEditorTreatment(treatment))
  }, [treatment])

  // 辅助诊断 start

  const [messageData, setMessageData] = useState<messageData>()

  const materials = useSelector(selectRecipeMaterials)

  const userId = useSelector(selectUserId)

  const userName = useSelector(selectUserName)

  const tenantAddress = useSelector(selectTenantAddress)

  const tenantName = useSelector(selectTenantName)

  const tenantCategory = useSelector(selectTenantCategory)

  const cycleCode = sessionStorage.getItem('cycleCode')

  const [patientData, setpatientData] = useState<patientInfo>()

  const [checkedData, setCheckedData] = useState<any>([])

  const [isDrawer, setIsDrawer] = useState<boolean>(
    Boolean(sessionStorage.getItem('isDrawer')) || false
  )

  const [isDrawerVisible, setIsDrawerVisible] = useState(false)

  const [treatmentData, setTreatmentData] = useState<any>({})

  const [OutRecipeVisible, setOutRecipeVisible] = useState(false)

  const [errorIframeUrl, setErrorIframeUrl] = useState<string>(
    'https://pre-aimedical.wecity.qq.com/toolbox/index.html'
  )

  const [iframeUrl, setIframeUrl] = useState<string>(
    'https://pre-aimedical.wecity.qq.com/toolbox/index.html'
  )

  const [outRepiceLoading, setOutRepiceLoading] = useState(false)

  useEffect(() => {
    setRegistrationForConvert(null)
  }, [])

  useEffect(() => {
    if (!registrationForConvert) {
      setReadCardLoading(false)
    }
  }, [registrationForConvert])

  const arouseDrawer = () => {
    if (isDrawer) {
      setIsDrawer(false)
      sessionStorage.removeItem('isDrawer')
    } else {
      setIsDrawer(true)
      sessionStorage.setItem('isDrawer', '1')
    }
  }


  const readCard = (cardType: 1 | 2 | 3) => {
    if (cardType == 1) {
      successBytype('读卡中', 1)
      readETypeToyb()
    } else if (cardType == 2) {
      // setOtherPatientInsuranceItem({}) // 防止误用他人信息
      // readCardRef.current.blur() //按钮失焦，防止重复点击
      // // const readType = form.getFieldsValue().readType
      // success('读卡中', 2)
      // const idCardNumber = form.getFieldsValue().idCardNumber
      // if (idCardNumber) {
      //     getPatientInfo(idCardNumber, 'requestString')
      // } else {
      //     notification.info({
      //         message: '请输入身份证号',
      //     })
      // }
    } else if (cardType === 3) {
      successBytype('读卡中', 3)
      readSType()
    }
  }

  // 实体卡读卡
  const readSType = () => {
    readCardSHttp(
      {
        trade_args: [getTradeCode(tenantAreaCd.slice(0, 4))],
      },
      (data, err) => {
        if (!err) {
          const tradeType = getTradeName()
          const Name =
            tradeType === 'JS_ZHYB'
              ? data?.netmessage
                ?.split('|')[4]
                ?.replace('\r', '')
                .replace('\t', '')
                .replace(' ', '')
                .trim()
              : data?.netmessage?.Name
          if (Name == registrationForConvert?.patientName) {
            getPatientInfoAndConvertToYb(
              tradeType === 'JS_ZHYB'
                ? data.netmessage
                : JSON.stringify(data.netmessage),
              'requestString',
              3
            )
          } else {
            Modal.error({
              title: '提示',
              content:
                `患者姓名与卡片姓名不一致，请确认是否插错卡，就诊人：` +
                registrationForConvert?.patientName +
                '，卡片姓名：' +
                Name,
              okText: '确认',
            })
            setReadCardLoading(false)
          }
        } else {
          setReadCardLoading(false)
        }
      }
    )
  }
  // 电子凭证读卡
  const readETypeToyb = () => {
    readCardEHttp(
      {
        trade_args: [
          insuranceArray[0].countryHospitalNb,
          '01301',
          '',
          userId,
          userName,
          '',
          '',
        ],
      },
      (data, err) => {
        if (!err) {
          const readName = data?.netmessage?.split('|')[0]
          if (readName != registrationForConvert?.patientName) {
            Modal.error({
              title: '提示',
              content:
                `退费信息与卡片信息不一致，请确认是否插错卡，就诊人：` +
                registrationForConvert?.patientName +
                '，电子凭证姓名：' +
                readName,
              okText: '确认',
            })
            setReadCardLoading(false)
          } else {
            getPatientInfoAndConvertToYb(data.netmessage, 'requestString', 1)
          }
        } else {
          setReadCardLoading(false)
        }
      }
    )
  }

  // 获取参保人信息
  const getPatientInfoAndConvertToYb = (
    fullCardInfo: string,
    serverType: any,
    cardType: number,
    responseString?: string
  ) => {
    dispatch(
      getPatientInfoAsync({
        serverType,
        body: {
          baseData: {
            ...insuranceArray[0],
            endTime: moment(insuranceArray[0].endTime).format(
              DateTimeFormatSimpleOnlyOne
            ),
            userName,
            userId,
            cycleCode,
            tenantCategory,
            tenantAreaCd,
            name: tenantName,
            address: tenantAddress,
            areaCode: tenantAreaCd,
            responseString: responseString ? responseString : null,
          },
          registrationId: registrationForConvert?.id,
          cardType: String(cardType),
          fullCardInfo,
          otherMap: {},
        },
      })
    )
      .then(unwrapResult)
      .then(async (res:any) => {
        if (serverType === 'requestString') {
          const data = strParse(res)
          await medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                insuranceArray[0].countryHospitalNb,
                insuranceArray[0].secretToken,
              ],
            },
            (call: any, err) => {
              if (!err) {
                successBytype('正在获取人员信息', cardType)
                getPatientInfoAndConvertToYb(
                  fullCardInfo,
                  'response',
                  cardType,
                  JSON.stringify(call.netmessage)
                )
                // }
              } else {
                setReadCardLoading(false)
              }
            }
          )
        } else {
          message.success('读卡成功', 0)
          //紧跟着做一次医保挂号
          medicalSubmitAndConvertToYb(
            res,
            fullCardInfo,
            cardType,
            'requestString'
          )
        }
      })
      .catch((ex:any) => {
        notification.error({
          message: ex.message,
          duration: 3,
        })
        setReadCardLoading(false)
      })
  }

  const medicalSubmitAndConvertToYb = (
    patientInsurance: any,
    fullCardInfo: any,
    cardType: number,
    serverType: any,
    responseString?: string
  ) => {
    dispatch(
      medicalRegisterAsync({
        serverType,
        body: {
          baseData: {
            ...insuranceArray[0],
            endTime: moment(insuranceArray[0].endTime).format(
              DateTimeFormatSimpleOnlyOne
            ),
            userName,
            userId,
            cycleCode,
            tenantCategory,
            tenantAreaCd,
            name: tenantName,
            address: tenantAddress,
            areaCode: tenantAreaCd,
            responseString: responseString ? responseString : null,
          },
          begntime: moment().format(DateTimeFormatSimpleOnlyOne),
          cardType: String(cardType),
          fullCardInfo: fullCardInfo,
          patientId: registrationForConvert?.patientId,
          registrationId: registrationForConvert?.id,
          treatmentId: registrationForConvert?.treatmentId,
          patientName: registrationForConvert?.patientName,
          pi: { ...patientInsurance?.patientInsurance },
          // cbxz: patientInsurance.cbxz,
          // doctorCode: doctorItem?.countryCode,
          // doctorName: doctorItem?.name,`
          // gwybz: patientInsurance.gwybz,
          // insuranceRegion: patientInsurance.insuranceRegion,
          // psnType: patientInsurance.memberType,
          // treatmentDepartmentCode: treatmentItem.code,
          // treatmentDepartmentName: treatmentItem.name,
          // treatmentNo: treatmentNum,
          // ybKebie: treatmentItem.ybKebie,
          // ybPersonalNo: vs.ybPersonalNo,
          // name: patientInsurance.name,
        },
      })
    )
      .then(unwrapResult)
      .then(async (res:any) => {
        setReadCardLoading(true)
        if (serverType === 'requestString') {
          const data = strParse(res)
          await medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                insuranceArray[0].countryHospitalNb,
                insuranceArray[0].secretToken,
              ],
              massage: '医保挂号',
            },
            (call: any, err) => {
              if (!err) {
                medicalSubmitAndConvertToYb(
                  patientInsurance,
                  fullCardInfo,
                  cardType,
                  'response',
                  JSON.stringify(call.netmessage)
                )
              } else {
                setReadCardLoading(false)
              }
            }
          )
        } else {
          notification.success({
            message: '自费转医保成功，请继续操作。',
            duration: 2,
          })
          setReadCardLoading(false)
          setRegistrationForConvert(null)
          getTreatmentsDetail()
          dispatch(getRecipeDetail(selectedRecipeId))
          props?.refreshTreament && props?.refreshTreament()
        }
      })
      .catch((ex:any) => {
        notification.error({
          message: ex.message,
          duration: 2,
        })
        setReadCardLoading(false)
      })
  }

  useEffect(() => {
    if (materials?.length && isShow) {
      const arr = materials
        ?.filter((v: any) => {
          return v?.memo && v?.memo != '' && v?.memo?.length > 5
        })
        ?.map((v: any) => {
          return {
            name: v?.name,
            memo: v?.memo,
          }
        })
      const recipeData: any = recipes?.find((v) => v?.id == selectedRecipeId)
      if (recipeData?.prescriptionReminders) {
        setRecipesMemo([
          ...arr,
          {
            name: getRecipeCategoryName(recipeData?.category),
            memo: recipeData?.prescriptionReminders,
          },
        ])
      } else {
        setRecipesMemo(arr)
      }
    } else {
      setRecipesMemo([])
    }
  }, [materials, isShow, recipes])

  useEffect(() => {
    // recipesMemo变化时触发动画效果
    if (recipesMemo.length > 0) {
      if (
        showMemoStruct?.name ==
        recipesMemo.length + '. ' + recipesMemo[recipesMemo?.length - 1]?.name
      ) {
        return
      }
      const recipeData: any = recipes?.find((v) => v?.id == selectedRecipeId)
      setShowMemoStruct({
        name: recipeData?.prescriptionReminders
          ? getRecipeCategoryName(recipeData?.category)
          : recipesMemo.length +
            '. ' +
            recipesMemo[recipesMemo?.length - 1]?.name,
        memo: recipeData?.prescriptionReminders
          ? recipeData?.prescriptionReminders
          : recipesMemo[recipesMemo?.length - 1]?.memo,
      })
      setIsAnimating(true)

      // 在一段延迟后结束动画
      const timeout = setTimeout(() => {
        setIsAnimating(false)
      }, 1000)

      return () => {
        clearTimeout(timeout)
      }
    }
  }, [recipesMemo])

  const setPatientInfo = () => {
    return setTreatmentData({
      prescriptionReviewRequestType: 0,
      requestCase: {
        caseType: 0,
        chiefComplaint: treatment?.chiefComplaint,
        presentIllness: treatment?.historyOfPresentIllness,
        patientEmrDiagnosis: treatment?.disease
          .replace(/#/g, ',')
          .split(',')
          .map((v) => v.split('/')[0])
          .join(','),
        caseId: '1',
        visitId: registrationId,
        patientBaseInfo: {
          sex: getSexName(patientData?.registration.patientSex || 0),
          age: getAgeName(
            patientData?.registration?.patientAge || 0,
            patientData?.registration?.patientMonth || 0
          ),
          height: patientData?.treatment.patientHeight,
          weight: patientData?.treatment.patientWeight,
          patientId: String(patientData?.treatment.patientId),
        },
        prescriptions: materials.map((v) => {
          return {
            dosagePerDay: '无',
            dosagePerTime: '无',
            dosagePerTimeUnit: '无',
            drugId: v.materialsCatalogId,
            drugName: v.name,
            packAge: v.drugCount,
            packageUnit: v.accountUnit,
            prescriptionId: selectedRecipeId,
            timePerDay: v.normalFrequency || '无',
            usage: v.normalUsemethod || '无',
          }
        }),
      },
    })
  }

  // 获取个人设置权限
  useEffect(() => {
    if (userId) {
      dispatch(getUserProfileList({ userId: userId }))
        .then(unwrapResult)
        .then((c: any) => {
          setCheckedData(c)
        })
    }
  }, [userId])

  const getTreatmentsDetail = async () => {
    const v: any = await dispatch(
      getTreatments({
        registrationId,
        treatmentId,
      })
    ).then(unwrapResult)
    const data = {
      patientId: v?.registration.patientId,
      patientName: v?.registration.patientName,
      treatmentDoctorId: v?.registration.treatmentDoctorId,
      treatmentDoctorName: v?.registration.treatmentDoctorName,
    }
    props.toothRegistrationId && dispatch(toothSetPatientInfo(data))
    setpatientData(v)
    if (!v.treatment.id) {
      initRecipes()
    }
    dispatch(setRecipeEditorTreatment(v.treatment))
    setPatientInsurance(v.patientInsurance)
  }

  // 获取患者信息
  useEffect(() => {
    if (registrationId) {
      getTreatmentsDetail()
    }
  }, [dispatch, registrationId])

  useEffect(() => {
    if (JSON.stringify(treatment) !== '{}') {
      setPatientInfo()
    }
  }, [treatment])

  useEffect(() => {
    if (JSON.stringify(treatment) !== '{}') {
      setPatientInfo()
    }
  }, [materials])

  const inventoryInfoList = materials?.filter((v) => {
    return v?.inventoryInfo == '库存不足'
  })

  useEffect(() => {
    if (isDrawer && JSON.stringify(treatmentData) !== '{}') {
      // setPatientInfo()
      dispatch(getIframePreScriptionAsync(treatmentData))
        .then(unwrapResult)
        .then((res: any) => {
          if (res.iframeUrl) {
            setIframeUrl(
              `${res.iframeUrl}?id=${res.prescriptionReviewId}&ADTAG=${res.hospitalId}&token=${res.token}&module=rational&iframe_width="280px"`
            )
          }
        })
    }
  }, [isDrawer, treatmentData])

  useEffect(() => {
    window.onmessage = (e: any) => {
      if (typeof e.data === 'string') setMessageData(JSON.parse(e.data))
    }
  })

  useEffect(() => {
    if (messageData?.params && messageData?.params.DoctorChoose === 'Comfirm') {
      setIsDrawer(false)
      setIsDrawerVisible(false)
      if (treatment) {
        setFinishingState(LoadingState.Loading)
        dispatch(finishTreatment(treatment.id)).then(() => {
          setFinishingState(LoadingState.Idle)
          dispatch(
            traceRoute({
              name: '医生门诊',
              path: '/patients',
            })
          )
        })
      }
    } else if (
      messageData?.params &&
      messageData?.params.DoctorChoose === 'Cancel'
    ) {
      // setIsDrawer(false)
      setIsDrawerVisible(false)
    }
  }, [messageData?.params])
  // 辅助诊断 end

  // 医保结算

  const loadState = () => {
    setReadLoading(true)
    setTimeout(() => {
      setReadLoading(false)
    }, 10000)
  }

  const success = (str: string) => {
    message.loading(str, 3)
  }

  const successBytype = (str: string, type: number) => {
    if (type === 1) {
      setReadCardLoading(true)
      const hide = message.loading(str, 0)
      setTimeout(() => {
        hide()
        setReadCardLoading(false)
      }, 3000)
    } else if (type === 2) {
      setReadCardLoading(true)
      const hide = message.loading(str, 0)
      setTimeout(() => {
        hide()
        setReadCardLoading(false)
      }, 3000)
    } else if (type === 3) {
      setReadCardLoading(true)
      const hide = message.loading(str, 0)
      setTimeout(() => {
        hide()
        setReadCardLoading(false)
      }, 3000)
    }
  }

  const messageNotification = async (str: string) => {
    await message.destroy()
    notification.success({
      message: str,
      duration: 1,
    })
  }

  const setUpdateFlags = (insuranceFlag: string, updateFlag: string) => {
    if (insuranceFlag === '1' && updateFlag === '1') {
      setReadLoading(false)
    }
    dispatch(
      setUpdateFlagsAsync({
        treatmentId: treatment?.id,
        insuranceFlag,
        updateFlag,
      })
    )
      .then(unwrapResult)
      .then(getTreatmentsDetail)
  }

  const linkToPayment = (str?: any) => {
    const rs = recipes.filter(isRecipePayable)
    if (rs.length > 0) {
      if (props.toothRegistrationId) {
        dispatch(
          traceRoute({
            name: '医生门诊',
            path: '/payment/confirm',
            query: `ids=${rs.map((r) => r.id).join(',')}&phone=${
              treatment?.patientPhone
            }`,
            state: {
              where: ['诊间结算', '结算明细'],
              prePay: str,
              routerLink: true,
              treatment,
              toothRegistrationId: !!props.toothRegistrationId,
              backtrace: {
                name: '患者管理',
                path: '/toothPatient',
              },
            },
          })
        )
      } else {
        dispatch(
          traceRoute({
            name: '医生门诊',
            path: '/payment/confirm',
            query: `ids=${rs.map((r) => r.id).join(',')}&phone=${
              treatment?.patientPhone
            }`,
            state: {
              where: ['诊间结算', '结算明细'],
              prePay: str,
              routerLink: true,
              treatment,
              backtrace: {
                name: '医生门诊',
                path: '/treatment',
                query: `registrationId=${registrationId}`,
              },
            },
          })
        )
      }
    }
  }

  const gotoPayment = () => {
    loadState()
    if (treatment?.insuranceCode !== 'ZIFEI') {
      //查询当前选择的处方，是否是全自费明细，如果是的话，走本地自费结算，否则继续走医保结算
      dispatch(selectIsAllZifeiAsync({ treatmentId: Number(treatment?.id) }))
        .then(unwrapResult)
        .then((res: any) => {
          if (res == 1) {
            linkToPayment()
          } else {
            dispatch(selectTreatmentUpdateFlgsAsync(treatment?.id || ''))
              .then(unwrapResult)
              .then((res: any) => {
                if (res?.insuranceFlag === '0' || res?.insuranceFlag === '2') {
                  // 未上传或已撤销
                  MdtrtinfoUp('requestString', undefined, undefined, undefined)
                } else if (res?.insuranceFlag === '1') {
                  if (res?.updateFlag === '1') {
                    //上传未撤销
                    FeeListUpCancel(
                      'requestString',
                      undefined,
                      undefined,
                      undefined
                    )
                  } else {
                    // 已上传
                    preSettlement(
                      'requestString',
                      undefined,
                      undefined,
                      undefined
                    )
                  }
                }
              })
              .catch(() => {
                if (
                  treatment?.insuranceFlag === '0' ||
                  treatment?.insuranceFlag === '2'
                ) {
                  // 未上传或已撤销
                  MdtrtinfoUp('requestString', undefined, undefined, undefined)
                } else if (treatment?.insuranceFlag === '1') {
                  if (treatment?.updateFlag === '1') {
                    //上传未撤销
                    FeeListUpCancel(
                      'requestString',
                      undefined,
                      undefined,
                      undefined
                    )
                  } else {
                    // 已上传
                    preSettlement(
                      'requestString',
                      undefined,
                      undefined,
                      undefined
                    )
                  }
                }
              })
          }
        })
    } else {
      linkToPayment()
    }
  }


  // 2201
  const medicalSubmitForMultiYidi = (
    serverType: any,
    responseString?: string,
    resolve?: any,
    reject?: any) => {
    const insuranceItem = insuranceArray.find(
      (v) => v.insuranceCode === treatment?.insuranceCode
    )
    dispatch(
      medicalRegisterForMultiYidiAsync({
        serverType,
        body: {
          baseData: {
            ...insuranceItem,
            endTime: moment(insuranceItem?.endTime).format(
              DateTimeFormatSimpleOnlyOne
            ),
            userName,
            userId,
            cycleCode,
            tenantCategory,
            tenantAreaCd,
            name: tenantName,
            address: tenantAddress,
            areaCode: tenantAreaCd,
            responseString: responseString ? responseString : null,
          },
          originRegistrationId:treatment?.registrationId
        },
      })
    )
      .then(unwrapResult)
      .then(async (res:any) => {
        if (serverType === 'requestString') {
          const data = strParse(res)
          await medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                insuranceItem.countryHospitalNb,
                insuranceItem.secretToken,
              ],
              massage: '医保挂号',
            },
            (call: any, err) => {
              if (!err) {
                medicalSubmitForMultiYidi('response', JSON.stringify(call.netmessage),resolve,reject)
              } else {
                setReadLoading(false)
                reject && reject()
              }
            }
          )
        } else {
          MdtrtinfoUp('requestString', undefined, undefined, undefined)
        }
      })
      .catch((v: any) => {
        setReadLoading(false)
        reject && reject()
      })
  }


  // 2203
  const MdtrtinfoUp = (
    serverType: any,
    responseString?: string,
    resolve?: any,
    reject?: any
  ) => {
    const insuranceItem = insuranceArray.find(
      (v) => v.insuranceCode === treatment?.insuranceCode
    )
    dispatch(
      MdtrtinfoUpAsync({
        serverType: serverType,
        body: {
          baseData: {
            ...insuranceItem,
            endTime: moment(insuranceItem?.endTime).format(
              DateTimeFormatSimpleOnlyOne
            ),
            userName,
            userId,
            cycleCode,
            tenantCategory,
            tenantAreaCd,
            name: tenantName,
            address: tenantAddress,
            areaCode: tenantAreaCd,
            responseString: responseString ? responseString : null,
          },
          exp_content: '',
          treatmentId: Number(treatment?.id),
        },
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        if (serverType === 'requestString') {
          if("yidi_to_registration" == res){
            medicalSubmitForMultiYidi('requestString',undefined,resolve,reject);
          }else{
            success('正在上传诊断')
            const data = strParse(res)
            medicalSendHttp(
              {
                trade_args: [
                  data.infno,
                  data.url,
                  data,
                  insuranceItem.countryHospitalNb,
                  insuranceItem.secretToken,
                ],
              },
              (call, err) => {
                if (err) {
                  setReadLoading(false)
                } else {
                  MdtrtinfoUp('response', JSON.stringify(call.netmessage))
                }
              },
              undefined,
              reject
            )
          }
        } else {
          messageNotification('诊断上传完毕')
          FeeListUp('requestString')
          resolve && resolve()
        }
      })
      .catch((err: any) => {
        setReadLoading(false)
        reject && reject()
      })
  }

  // 2204
  const FeeListUp = (
    serverType: any,
    responseString?: string,
    resolve?: any,
    reject?: any
  ) => {
    const insuranceItem = insuranceArray.find(
      (v) => v.insuranceCode === treatment?.insuranceCode
    )
    dispatch(
      FeeListUpAsync({
        serverType: serverType,
        body: {
          baseData: {
            ...insuranceItem,
            endTime: moment(insuranceItem?.endTime).format(
              DateTimeFormatSimpleOnlyOne
            ),
            userName,
            userId,
            cycleCode,
            tenantCategory,
            tenantAreaCd,
            name: tenantName,
            address: tenantAddress,
            areaCode: tenantAreaCd,
            responseString: responseString ? responseString : null,
          },
          exp_content: '',
          treatmentId: Number(treatment?.id),
        },
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        setUpdateFlags('1', '0')
        if (serverType === 'requestString') {
          success('正在上传费用')
          const data = strParse(res)
          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                insuranceItem.countryHospitalNb,
                insuranceItem.secretToken,
              ],
            },
            (call, err) => {
              if (err) {
                setUpdateFlags('1', '1')
                setReadLoading(false)
              } else {
                setUpdateFlags('1', '0'),
                  FeeListUp('response', JSON.stringify(call.netmessage))
              }
            },
            undefined,
            reject
          )
        } else {
          messageNotification('费用上传完毕')
          preSettlement('requestString')
          resolve && resolve()
        }
      })
      .catch(() => {
        resolve && resolve()
        if (serverType === 'response') {
          setUpdateFlags('1', '1')
        }
      })
  }

  // 2205
  const FeeListUpCancel = (
    serverType: any,
    responseString?: string,
    resolve?: any,
    reject?: any
  ) => {
    const insuranceItem = insuranceArray.find(
      (v) => v.insuranceCode === treatment?.insuranceCode
    )
    dispatch(
      FeeListUpCancelAsync({
        serverType: serverType,
        body: {
          baseData: {
            ...insuranceItem,
            endTime: moment(insuranceItem?.endTime).format(
              DateTimeFormatSimpleOnlyOne
            ),
            userName,
            userId,
            cycleCode,
            tenantCategory,
            tenantAreaCd,
            name: tenantName,
            address: tenantAddress,
            areaCode: tenantAreaCd,
            responseString: responseString ? responseString : null,
          },
          exp_content: '',
          treatmentId: Number(treatment?.id),
        },
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        if (serverType === 'requestString') {
          success('门诊费用明细信息撤销中')
          const data = strParse(res)
          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                insuranceItem.countryHospitalNb,
                insuranceItem.secretToken,
              ],
            },
            (call, err) => {
              if (err) {
                setReadLoading(false)
              } else {
                setUpdateFlags('2', '0')
                messageNotification('撤销成功')
                MdtrtinfoUp('requestString')
              }

              // FeeListUpCancel('response', JSON.stringify(call.netmessage))
            },
            undefined,
            reject
          )
        } else {
          MdtrtinfoUp('requestString')
          resolve && resolve()
        }
      })
      .catch(() => {
        setReadLoading(false)
        if (serverType === 'requestString') {
          setUpdateFlags('1', '1')
        }
        resolve && resolve()
      })
  }

  // 电子凭证读卡
  const readEType = (callback: (msg: string, success?: boolean) => void) => {
    const insuranceItem = insuranceArray.find(
      (v) => v.insuranceCode === treatment?.insuranceCode
    )
    readCardEHttp(
      {
        trade_args: [
          insuranceItem?.countryHospitalNb,
          '01301',
          '',
          userId,
          userName,
          '',
          '',
        ],
      },
      (data, err) => {
        if (!err) {
          setReadLoading(true)
          dispatch(
            updateCardInfoAsync({
              fullCardInfo: data.netmessage,
              patientId: Number(treatment?.patientId),
              cardType: 1,
              registrationId: treatment?.registrationId,
            })
          )
            .then(unwrapResult)
            .then((res: any) => {
              notification.success({
                message: res,
              })
              callback(res, true)
            })
            .catch((err2: any) => {
              callback('error', false)
            })
        } else {
          callback('扫码失败，请再试一次', false)
          setReadLoading(false)
        }
      }
    )
  }

  // 2206
  const preSettlement = (
    serverType: any,
    responseString?: string,
    resolve?: any,
    reject?: any
  ) => {
    const insuranceItem = insuranceArray.find(
      (v) => v.insuranceCode === treatment?.insuranceCode
    )
    dispatch(
      preSettlementAsync({
        serverType: serverType,
        body: {
          baseData: {
            ...insuranceItem,
            endTime: moment(insuranceItem?.endTime).format(
              DateTimeFormatSimpleOnlyOne
            ),
            userName,
            userId,
            cycleCode,
            tenantCategory,
            tenantAreaCd,
            name: tenantName,
            address: tenantAddress,
            areaCode: tenantAreaCd,
            responseString: responseString ? responseString : null,
          },
          chrg_bchno: '1',
          exp_content: '',
          treatmentId: treatment?.id,
          patientId: treatment?.patientId,
          registrationId: treatment?.registrationId,
        },
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        if (serverType === 'requestString') {
          success('正在预结算')
          const data = strParse(res)
          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                insuranceItem.countryHospitalNb,
                insuranceItem.secretToken,
              ],
            },
            (call, err) => {
              if (err) {
                setReadLoading(false)
              } else {
                preSettlement('response', JSON.stringify(call.netmessage))
              }
            },
            undefined,
            reject
          )
        } else {
          // messageNotification('结算成功')
          linkToPayment(res)
          resolve && resolve()
        }
      })
      .catch((ex: any) => {
        if (ex.message?.indexOf('电子凭证已过期') >= 0) {
          Modal.confirm({
            centered: true,
            title: '提示',
            content: '患者电子凭证已过期，请重新扫码。',
            okText: '重新扫码',
            cancelText: '取消',
            onOk: () => {
              setReadLoading(true)
              readEType((xmsg, success) => {
                if (success) {
                  preSettlement('requestString')
                } else {
                  setReadLoading(false)
                }
              })
            },
            onCancel: () => {
              setReadLoading(false)
            },
          })
        } else {
          setReadLoading(false)
          resolve && resolve()
        }
      })
  }

  //外配处方库存核验（苏州）
  const recipeInventoryVerification = (
    serverType: any,
    outRecipeParams?: any,
    responseString?: string
  ) => {
    const insuranceItem = insuranceArray.find(
      (v) => v.insuranceCode === treatment?.insuranceCode
    )
    dispatch(
      recipeInventoryVerificationAsync({
        serverType: serverType,
        body: {
          baseData: {
            ...insuranceItem,
            endTime: moment(insuranceItem?.endTime).format(
              DateTimeFormatSimpleOnlyOne
            ),
            userName,
            userId,
            cycleCode,
            tenantCategory,
            tenantAreaCd,
            name: tenantName,
            address: tenantAddress,
            areaCode: tenantAreaCd,
            responseString: responseString ? responseString : null,
          },
          ...outRecipeParams,
          rxFile: '',
        },
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        if (serverType === 'requestString') {
          success('正在进行外配处方库存核验')
          const data = strParse(res)
          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                insuranceItem.countryHospitalNb,
                insuranceItem.secretToken,
              ],
            },
            (call, err) => {
              if (err) {
                setOutRepiceLoading(false)
              } else {
                recipeInventoryVerification(
                  'response',
                  outRecipeParams,
                  JSON.stringify(call.netmessage)
                )
              }
            },
            undefined
          )
        } else {
          res = JSON.parse(res)
          if (res.code == 0) {
            notification.success({
              message: '外配处方库存核验成功。',
            })
            const accept = res.output.accept
            if (accept == 1) {
              recipeVerification('requestString', outRecipeParams)
            } else {
              Modal.confirm({
                centered: true,
                title: '确认操作',
                content: (
                  <div>
                    <ExclamationCircleFilled
                      style={{ color: '#FFAE55', fontSize: 18 }}
                    />
                    &nbsp;库存核验结果:未能完全满足处方药品要求，是否继续上传外配处方？
                  </div>
                ),
                okText: '确认',
                cancelText: '取消',
                icon: null,
                onOk: () => {
                  recipeVerification('requestString', outRecipeParams)
                },
                onCancel: () => {
                  setOutRepiceLoading(false)
                },
              })
            }
          }
        }
      })
      .catch(() => {
        setOutRepiceLoading(false)
      })
  }

  //外配处方核验（苏州）
  const recipeVerification = (
    serverType: any,
    outRecipeParams?: any,
    responseString?: string
  ) => {
    const insuranceItem = insuranceArray.find(
      (v) => v.insuranceCode === treatment?.insuranceCode
    )
    dispatch(
      electronicPrescriptionInfoVerificationAsync({
        serverType: serverType,
        body: {
          baseData: {
            ...insuranceItem,
            endTime: moment(insuranceItem?.endTime).format(
              DateTimeFormatSimpleOnlyOne
            ),
            userName,
            userId,
            cycleCode,
            tenantCategory,
            tenantAreaCd,
            name: tenantName,
            address: tenantAddress,
            areaCode: tenantAreaCd,
            responseString: responseString ? responseString : null,
          },
          ...outRecipeParams,
          rxFile: '',
        },
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        if (serverType === 'requestString') {
          success('正在进行外配处方核验')
          const data = strParse(res)
          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                insuranceItem.countryHospitalNb,
                insuranceItem.secretToken,
              ],
            },
            (call, err) => {
              if (err) {
                setOutRepiceLoading(false)
              } else {
                recipeVerification(
                  'response',
                  outRecipeParams,
                  JSON.stringify(call.netmessage)
                )
              }
            },
            undefined
          )
        } else {
          res = JSON.parse(res)
          if (res.code == 0) {
            notification.success({
              message: '外配处方核验成功。',
            })
            const hiRxno = res.output.hiRxno
            const rxTraceCode = res.output.rxTraceCode
            outRecipeParams.hiRxno = hiRxno
            outRecipeParams.rxTraceCode = rxTraceCode
            recipeSign('requestString', outRecipeParams)
          }
        }
      })
      .catch(() => {
        setOutRepiceLoading(false)
      })
  }

  //外配处方签名（苏州）
  const recipeSign = (
    serverType: any,
    outRecipeParams?: any,
    responseString?: string
  ) => {
    const insuranceItem = insuranceArray.find(
      (v) => v.insuranceCode === treatment?.insuranceCode
    )
    dispatch(
      electronicPrescriptionSignAsync({
        serverType: serverType,
        body: {
          baseData: {
            ...insuranceItem,
            endTime: moment(insuranceItem?.endTime).format(
              DateTimeFormatSimpleOnlyOne
            ),
            userName,
            userId,
            cycleCode,
            tenantCategory,
            tenantAreaCd,
            name: tenantName,
            address: tenantAddress,
            areaCode: tenantAreaCd,
            responseString: responseString ? responseString : null,
          },
          ...outRecipeParams,
          // hiRxno:"320500022040265506982100013991",
          // signDigest:"32793842230019031042"
        },
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        if (serverType === 'requestString') {
          success('正在进行外配处方签名')
          const data = strParse(res)
          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                insuranceItem.countryHospitalNb,
                insuranceItem.secretToken,
              ],
            },
            (call, err) => {
              if (err) {
                setOutRepiceLoading(false)
              } else {
                recipeSign(
                  'response',
                  outRecipeParams,
                  JSON.stringify(call.netmessage)
                )
              }
            },
            undefined
          )
        } else {
          res = JSON.parse(res)
          if (res.code == 0) {
            if (res?.output == '_成功') {
              notification.success({
                message: '外配处方签名成功。',
              })
              sleep(1000)
              notification.success({
                message: '外配处方上传成功。',
              })
              outRecipeRef.current.reset()
              setOutRecipeVisible(false)
              setOutRepiceLoading(false)
              setOutRecipeDisable(true)
              dispatch(
                getTreatment({
                  registrationId,
                  treatmentId,
                })
              )
            } else {
              notification.success({
                message: '外配处方签名成功。',
              })
              const signDigest = res.output.signDigest
              outRecipeParams.rxFile = res.output.rxFile
              outRecipeParams.signDigest = signDigest
              recipeUpload('requestString', outRecipeParams)
            }
          }
        }
      })
      .catch(() => {
        setOutRepiceLoading(false)
      })
  }

  //外配处方上传（苏州）
  const recipeUpload = (
    serverType: any,
    outRecipeParams?: any,
    responseString?: string
  ) => {
    const insuranceItem = insuranceArray.find(
      (v) => v.insuranceCode === treatment?.insuranceCode
    )
    dispatch(
      electronicPrescriptionUploadAsync({
        serverType: serverType,
        body: {
          baseData: {
            ...insuranceItem,
            endTime: moment(insuranceItem?.endTime).format(
              DateTimeFormatSimpleOnlyOne
            ),
            userName,
            userId,
            cycleCode,
            tenantCategory,
            tenantAreaCd,
            name: tenantName,
            address: tenantAddress,
            areaCode: tenantAreaCd,
            responseString: responseString ? responseString : null,
          },
          ...outRecipeParams,
        },
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        if (serverType === 'requestString') {
          success('正在上传外配处方')
          const data = strParse(res)
          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                insuranceItem.countryHospitalNb,
                insuranceItem.secretToken,
              ],
            },
            (call, err) => {
              if (err) {
                setOutRepiceLoading(false)
              } else {
                recipeUpload(
                  'response',
                  outRecipeParams,
                  JSON.stringify(call.netmessage)
                )
              }
            },
            undefined
          )
        } else {
          res = JSON.parse(res)
          if (res.code == 0) {
            notification.success({
              message: '外配处方上传成功。',
            })
            outRecipeRef.current.reset()
            setOutRecipeVisible(false)
            setOutRepiceLoading(false)
            setOutRecipeDisable(true)
            dispatch(
              getTreatment({
                registrationId,
                treatmentId,
              })
            )
          }
        }
      })
      .catch(() => {
        setOutRepiceLoading(false)
      })
  }

  // 7101
  const outRecipe = (
    serverType: any,
    outRecipeParams?: any,
    responseString?: string
  ) => {
    const insuranceItem = insuranceArray.find(
      (v) => v.insuranceCode === treatment?.insuranceCode
    )
    dispatch(
      outRecipeAsync({
        serverType: serverType,
        body: {
          baseData: {
            ...insuranceItem,
            endTime: moment(insuranceItem?.endTime).format(
              DateTimeFormatSimpleOnlyOne
            ),
            userName,
            userId,
            cycleCode,
            tenantCategory,
            tenantAreaCd,
            name: tenantName,
            address: tenantAddress,
            areaCode: tenantAreaCd,
            responseString: responseString ? responseString : null,
          },
          ...outRecipeParams,
        },
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        if (serverType === 'requestString') {
          success('正在上传外配处方')
          const data = strParse(res)
          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                insuranceItem.countryHospitalNb,
                insuranceItem.secretToken,
              ],
            },
            (call, err) => {
              if (err) {
                setOutRepiceLoading(false)
              } else {
                outRecipe(
                  'response',
                  outRecipeParams,
                  JSON.stringify(call.netmessage)
                )
              }
            },
            undefined
          )
        } else {
          // messageNotification('结算成功')
          notification.success({
            message: '外配处方上传成功。',
          })
          outRecipeRef.current.reset()
          setOutRecipeVisible(false)
          setOutRepiceLoading(false)
          setOutRecipeDisable(true)
          dispatch(
            getTreatment({
              registrationId,
              treatmentId,
            })
          )
        }
      })
      .catch(() => {
        setOutRepiceLoading(false)
      })
  }

  const newEditableRecipeCategories = props.toothRegistrationId
    ? ToothEditableRecipeCategories
    : EditableRecipeCategories

  const overlay = (
    <Col className={styles.menus}>
      <div>请选择处方类别</div>
      {newEditableRecipeCategories.map((c) => (
        <div
          key={c}
          className={styles.menu}
          onClick={async () => {
            if (treatment) {
              const recipeId = await dispatch(
                createOrUpdateRecipe({
                  treatmentId: treatment.id,
                  category: c,
                  registrationId: treatment.id ? undefined : registrationId,
                } as Recipe)
              ).then(unwrapResult)
              !treatment.id && getTreatmentsDetail()
              await dispatch(getRecipeList(treatment.id))
              setSelectedRecipeId(recipeId)
            }
          }}
        >
          {getRecipeCategoryName(c)}
        </div>
      ))}
    </Col>
  )

  // 事前提醒
  const beforeAlert = (serverType: serverType, responseString?: string) => {
    setHasBeenAlerted(true)
    if (!treatment || !treatment.id) {
      return
    }
    const insuranceItem = insuranceArray.find(
      (v) => v.insuranceCode === treatment?.insuranceCode
    )
    setBeforeAlertMessage('正在查询医保事前提醒信息...')

    dispatch(
      beforeAlertAsync({
        serverType,
        body: {
          baseData: {
            ...insuranceItem,
            endTime: moment(insuranceItem?.endTime).format(
              DateTimeFormatSimpleOnlyOne
            ),
            userName,
            userId,
            cycleCode,
            tenantCategory,
            tenantAreaCd,
            name: tenantName,
            address: tenantAddress,
            areaCode: tenantAreaCd,
            responseString: responseString ? responseString : null,
          },
          treatmentId: treatment?.id,
          patientId: treatment?.patientId,
        },
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        if (serverType === 'requestString') {
          //长兴走特殊的事前提醒
          if (tenantAreaCd == '330522') {
            const data = strParse(res)
            medicalSendHttp(
              {
                trade_args: [
                  'CX_SQTX',
                  data,
                  null,
                  insuranceItem.countryHospitalNb,
                  insuranceItem.secretToken,
                ],
              },
              (call, err) => {
                if (!err) {
                  if ('200' == call?.netmessage?.code) {
                    const alertList = call?.netmessage.data
                    let message = ''
                    for (let i = 0; i < alertList.length; i++) {
                      const d = alertList[i]
                      message +=
                        i +
                        1 +
                        "：<span style='font-weight: bold'>智能审核触发：" +
                        (d?.prompt_type == 1
                          ? "<span style='color:green'>提示</span>"
                          : d?.prompt_type == 2
                          ? "<span style='color:#8d1f06'>禁止</span>"
                          : d?.prompt_type == 3
                          ? "<span style='color:#b2056e;'>警告</span>"
                          : '其他提醒') +
                        '</span></br>'
                      message += d?.prompt_message + '</br>'
                      if (d?.prompt_type == 2) {
                        setHasBeenAlerted(false)
                      }
                    }
                    if (message) {
                      setBeforeAlertMessage(message)
                    } else {
                      setBeforeAlertMessage(
                        "<span style='font-weight:bold;color:green'>事前提醒校验通过，当前无预警信息。</span>"
                      )
                    }
                  } else {
                    setBeforeAlertMessage(
                      '查询事前提醒失败。' + call?.netmessage?.err_msg
                    )
                  }
                } else {
                  setBeforeAlertMessage(
                    '查询事前提醒失败。' + call?.netmessage?.err_msg
                  )
                }
              }
            )
          } else {
            const data = strParse(res)
            medicalSendHttp(
              {
                trade_args: [
                  data.infno,
                  data.url,
                  data,
                  insuranceItem.countryHospitalNb,
                  insuranceItem.secretToken,
                ],
              },
              (call, err) => {
                if (!err) {
                  beforeAlert('response', JSON.stringify(call.netmessage))
                } else {
                  setBeforeAlertMessage(
                    '查询事前提醒失败。' + call?.netmessage?.err_msg
                  )
                }
              }
            )
          }
        } else {
          if (res && res.length) {
            setBeforeAlertMessage(res)
          } else {
            setBeforeAlertMessage('当前病例暂无事前提醒')
          }
        }
      })
      .catch((e: any) => {
        setBeforeAlertMessage('查询事前提醒失败：' + e?.message)
      })
  }

  const checkTreatmentSubmit = () => {
    if (treatment) {
      dispatch(checkTreatmentAsync(treatment.id))
        .then(unwrapResult)
        .then(() => {
          gotoPayment()
          props.onAction && props.onAction('jiesuan')
        })
    } else {
      notification.info({
        message: '请刷新页面。',
      })
    }
  }
  return (
    <div
      style={{
        display: 'flex',
        height: props?.tag == 'retail' ? '100%' : 'calc(100% - 5px)',
        position: 'relative',
        flexDirection: 'column',
        overflow: 'hidden',
      }}
    >
      <Row
        style={{
          position: 'fixed',
          top: '102px',
          right: '20px',
          fontSize: '1rem',
          padding: '0 20px',
        }}
        align='middle'
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            color: '#027AFF',
          }}
        >
          {hisVersion != 1 &&
            treatment &&
            treatment?.insuranceCode &&
            treatment?.insuranceCode != 'ZIFEI' &&
            recipes?.find((v) => v?.id == selectedRecipeId)?.state !==
              RecipeStatus.Settled &&
            recipes?.find((v) => v?.id == selectedRecipeId)?.state !==
              RecipeStatus.Returned && (
              <a
                onClick={() => {
                  Modal.confirm({
                    title: '',
                    content: (
                      <div>
                        <ExclamationCircleFilled
                          style={{ color: '#FFAE55', fontSize: 18 }}
                        />
                        &nbsp;
                        医保转自费后，该患者本次就诊的所有费用的收费类型都将改成自费结算，请确认此操作!
                        <div>
                          如只需调整某一个药品或处方单的收费类型,请去单独修改药品的收费类型。
                        </div>
                      </div>
                    ),
                    okText: '确认',
                    cancelText: '取消',
                    icon: null,
                    onOk: () => {
                      dispatch(
                        getInsuranceToSelfpay({
                          registrationId: registrationId,
                          treatmentId: treatmentId,
                          patientName: props?.patientName,
                        })
                      )
                        .then(unwrapResult)
                        .then((v: any) => {
                          notification.success({
                            message: '操作成功',
                          })
                          getTreatmentsDetail()
                          props?.refreshTreament && props?.refreshTreament()
                        })
                    },
                  })
                }}
              >
                医保转自费
              </a>
            )}
          {hisVersion != 1 &&
            treatment &&
            insuranceArray.length !== 0 &&
            treatment?.insuranceCode &&
            treatment?.insuranceCode == 'ZIFEI' &&
            recipes?.find((v) => v?.id == selectedRecipeId)?.state !==
            RecipeStatus.Settled &&
            recipes?.find((v) => v?.id == selectedRecipeId)?.state !==
            RecipeStatus.Returned && (
              <a
                onClick={() => {
                  setRegistrationForConvert(patientData?.registration)
                }}
              >
                自费转医保
              </a>
            )}
        </div>
      </Row>
      <Row
        className={styles.container}
        style={{
          // height: '100%',
          // overflow: 'hidden',
          maxHeight: 'calc(100% - 55px)',
          flex: 1,
          marginBottom: '10px',
        }}
        wrap={false}
      >
        <Col
          style={{
            width: 260,
            height: '100%',
            display: 'flex',
            backgroundColor: theme.pn,
            borderRadius: theme.br,
            flexShrink: 0,
          }}
        >
          <Tabs
            centered
            tabBarStyle={{
              borderBottom: `1px solid ${theme.th}`,
              marginBottom: 20,
            }}
            style={{
              width: '100%',
              height: '100%',
              fontSize: '14px',
            }}
          >
            <TabPane
              tab={<span style={{ fontSize: '18px' }}>处方信息</span>}
              key='0'
            >
              <Col
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  overflow: 'hidden',
                  height: '100%',
                  fontSize: '14px',
                }}
              >
                <Table
                  columns={[
                    {
                      align: 'left',
                      title: '类型',
                      key: 'category',
                      className: styles.category,
                      render: (_, r) => {
                        const isSelected = r.id === selectedRecipeId
                        const isSettled =
                          r.state === RecipeStatus.Settled ||
                          r.state === RecipeStatus.Returned
                        const isSettledFlag = r.state === RecipeStatus.Settled
                        const isReturnedFlag = r.state === RecipeStatus.Returned
                        setIsSettledFlag(isSettled)
                        return {
                          props: {
                            style: {
                              color: isSelected
                                ? isSettledFlag
                                  ? theme.s
                                  : isReturnedFlag
                                  ? '#ccc'
                                  : theme.c1
                                : isSettledFlag
                                ? theme.s
                                : isReturnedFlag
                                ? '#ccc'
                                : theme.tc1,
                              fontWeight: isSelected ? 'bold' : 'normal',
                              borderLeft: `4px solid ${
                                isSelected
                                  ? isSettledFlag
                                    ? theme.s
                                    : isReturnedFlag
                                    ? '#ccc'
                                    : theme.c1
                                  : isSettledFlag
                                  ? theme.s
                                  : 'transparent'
                              }`,
                              borderRadius: 2,
                              fontSize: '14px',
                            },
                          },
                          children:
                            getRecipeCategoryName(r.category) +
                            (isSettledFlag
                              ? '（完）'
                              : isReturnedFlag
                              ? '（退）'
                              : ''),
                        }
                      },
                    },
                    {
                      title: '金额',
                      key: 'amount',
                      align: 'right',
                      className: styles.amount,
                      render: (_, r) => {
                        const isSelected = r.id === selectedRecipeId
                        const isSettled =
                          r.state === RecipeStatus.Settled ||
                          r.state === RecipeStatus.Returned
                        const isSettledFlag = r.state === RecipeStatus.Settled
                        const isReturnedFlag = r.state === RecipeStatus.Returned
                        setIsSettledFlag(isSettled)
                        return {
                          props: {
                            style: {
                              color: isSelected
                                ? isSettledFlag
                                  ? theme.s
                                  : isReturnedFlag
                                  ? '#ccc'
                                  : theme.c1
                                : isReturnedFlag
                                ? '#ccc'
                                : theme.tc1,
                            },
                          },
                          children: `¥${r.amount}`,
                        }
                      },
                    },
                    {
                      title: ' ',
                      key: 'close',
                      className: styles.close,
                      render: function CloseButton(_, r) {
                        return (
                          <CloseOutlined
                            onClick={(e) => {
                              if (outRecipeDisable) return
                              const isSettled =
                                r.state === RecipeStatus.Settled ||
                                r.state === RecipeStatus.Returned
                              if (isSettled) {
                                notification.warn({
                                  message: '已结算/退费的处方不能删除。',
                                  duration: 1.5,
                                })
                                return
                              }
                              e.stopPropagation()
                              treatment && dispatch(removeRecipe(r.id))
                            }}
                            style={{
                              color: theme.tc3,
                            }}
                          />
                        )
                      },
                    },
                  ]}
                  dataSource={recipes}
                  pagination={false}
                  rowKey={(r) => r.id}
                  onRow={(r) => ({
                    onClick: () => setSelectedRecipeId(r.id),
                  })}
                  rowSelection={{
                    type: 'radio',
                    selectedRowKeys: [selectedRecipeId],
                    onChange: (ks) => {
                      setSelectedRecipeId((ks[0] as string) || '')
                    },
                  }}
                  className={styles.recipes}
                />
                <Dropdown
                  overlay={overlay}
                  trigger={['click']}
                  disabled={outRecipeDisable}
                >
                  <Button type='primary' className={styles.add}>
                    新增处方
                  </Button>
                </Dropdown>
                <Button
                  type='primary'
                  className={styles.add}
                  onClick={async () => {
                    setPrintTemplateCategory(PrintTemplateCategory.YHT)
                  }}
                >
                  药盒贴打印
                </Button>
              </Col>
            </TabPane>
            <TabPane
              tab={<span style={{ fontSize: '18px' }}>病历信息</span>}
              key='1'
            >
              <Col className={styles.history}>
                <Card title='疾病信息'>
                  {treatment?.disease
                    ?.replace(/#/g, ',')
                    ?.split(',')
                    ?.map((d, i) => (
                      <div key={i}>{d}</div>
                    ))}
                </Card>
                <Card title='诊断描述'>
                  <div>{treatment?.treatmentConclusion}</div>
                </Card>
                <Card title='现病史'>
                  <div>{treatment?.historyOfPresentIllness}</div>
                </Card>
                <Card title='既往史'>
                  <div>{treatment?.pastMedicalHistory}</div>
                </Card>
                <Card title='主诉'>
                  <div>{treatment?.chiefComplaint}</div>
                </Card>
              </Col>
            </TabPane>
          </Tabs>
        </Col>
        {!props.toothRegistrationId &&
          props?.tag != 'retail' &&
          checkedData &&
          checkedData.find((v: any) => v.code === '4') && (
            <div
              className={styles.drawer}
              style={{
                right: isDrawer ? '0' : '-360px',
              }}
            >
              <div className={styles.drawerContainer}>
                <div className={styles.drawerLeft}>
                  <div
                    className={styles.drawerBtn}
                    style={{ background: '#027AFF' }}
                    onClick={() => arouseDrawer()}
                  >
                    辅助诊断
                    {isDrawer ? (
                      <DoubleRightOutlined className={styles.drawerBtnIcon} />
                    ) : (
                      <DoubleLeftOutlined className={styles.drawerBtnIcon} />
                    )}
                  </div>
                  {isDrawer && (
                    <div
                      className={styles.drawerBtn}
                      style={{ marginTop: '10px', background: '#47DF56' }}
                      onClick={setPatientInfo}
                    >
                      刷新
                      <SyncOutlined className={styles.drawerBtnIcon} />
                    </div>
                  )}
                </div>
                <iframe
                  src={iframeUrl}
                  style={{ width: '360px', height: '100%' }}
                  scrolling='auto'
                  frameBorder='0'
                />
              </div>
            </div>
          )}
        <RecipeEditor
          patientName={props?.patientName}
          // 中药帖数
          tipCountNum={(v) => {
            setTipsCount(v)
          }}
          yzcfTag={props?.yzcfTag}
          kqMoveTag={props?.kqMoveTag}
          isSettledFlag={isSettledFlag}
          refresh={() => {
            getTreatmentsDetail()
            props?.refreshTreament && props?.refreshTreament()
          }}
          // onRefreshRecipe={(mats: any) => {
          //   if (mats?.length && isShow) {
          //     const arr = mats
          //       ?.filter((v: any) => {
          //         return v?.memo && v?.memo != '' && v?.memo?.length > 5
          //       })
          //       ?.map((v: any) => {
          //         return {
          //           name: v?.name,
          //           memo: v?.memo,
          //         }
          //       })
          //     const recipeData: any = recipes?.find(
          //       (v) => v?.id == selectedRecipeId
          //     )
          //     if (recipeData?.prescriptionReminders) {
          //       setRecipesMemo([
          //         ...arr,
          //         {
          //           name: getRecipeCategoryName(recipeData?.category),
          //           memo: recipeData?.prescriptionReminders,
          //         },
          //       ])
          //     } else {
          //       setRecipesMemo(arr)
          //     }
          //   } else {
          //     setRecipesMemo([])
          //   }
          // }}
          insuranceCode={
            patientData?.registration.insuranceCode == 'ZIFEI'
              ? insuranceArray[0]?.insuranceCode || 'ZIFEI'
              : patientData?.registration.insuranceCode
          }
          treatmentId={treatment?.id}
          data={data}
          style={{ marginLeft: 10, overflow: 'auto' }}
          recipeId={selectedRecipeId}
          recipeCategory={
            recipes.find((r) => r.id === selectedRecipeId)?.category
          }
          patientId={treatment?.patientId}
          onChange={(recipe) => dispatch(updateRecipe(recipe))}
          RecipeStatus={recipes.find((r) => r.id === selectedRecipeId)?.state}
          RecipeDate={
            recipes.find((r) => r.id === selectedRecipeId)?.recipeTime
          }
          onNewRecipe={(v) => {
            return
          }}
          getPatientData={(v: any) => {
            !patientData && setpatientData(v)
          }}
          disabled={outRecipeDisable}
          toothRegistrationId={props.toothRegistrationId}
        />
      </Row>
      <Row
        style={{
          height: '3.125rem',
          backgroundColor: '#fff',
          paddingRight: '40px',
        }}
        align='middle'
      >
        {hisVersion != 1 ? (
          <Col span={12}>
            <Popover
              placement='topLeft'
              content={
                <div>
                  <div style={{ display: 'inline-grid' }}>
                    {notice && notice.recentlyTreatmentTime && (
                      <div
                        style={{
                          fontSize: '1rem',
                          color: theme.e,
                          cursor: 'pointer',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                        onClick={() => {
                          if (!outRecipeDisable) {
                            setTempType(true)
                            setIsModalVisible(true)
                          }
                        }}
                      >
                        最近就诊：
                        {moment(notice.recentlyTreatmentTime).format(
                          'YYYY/MM/DD'
                        )}
                        <Tooltip title={notice?.disease}>
                          <span
                            style={{
                              marginLeft: 15,
                              maxWidth: 400,
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            疾病名称：{notice?.disease}
                          </span>
                        </Tooltip>
                      </div>
                    )}
                    <span
                      style={{
                        marginTop: '10px',
                        color: 'rgba(158,158,164,0.84)',
                        fontSize: '14px',
                        fontWeight: 'bold',
                      }}
                    >
                      以下是
                      {treatment?.insuranceCode != 'ZIFEI' ? '医保' : '自费'}
                      智能提醒内容，供您参考：
                    </span>
                    {recipesMemo?.map((v: any, i: number) => {
                      return (
                        <div
                          key={i}
                          style={{ marginTop: '10px', width: '700px' }}
                        >
                          <span
                            style={{
                              color: 'rgb(77,72,206)',
                              fontSize: '1rem',
                              fontWeight: 'bold',
                            }}
                          >
                            {i + 1}. {v?.name}
                          </span>
                          <span
                            style={{ marginLeft: '10px', fontSize: '14px' }}
                          >
                            {v?.memo}
                          </span>
                        </div>
                      )
                    })}
                  </div>
                </div>
              }
              trigger='click'
            >
              <div style={{ cursor: 'pointer', marginLeft: '15px' }}>
                <div>
                  {recipesMemo?.length ? (
                    <div
                      className={`${styles.carouselDiv} ${
                        isAnimating ? styles.fadeIn : ''
                      }`}
                    >
                      <span
                        style={{
                          color: 'rgb(77,72,206)',
                          fontSize: '1rem',
                          fontWeight: 'bold',
                        }}
                      >
                        {showMemoStruct?.name}
                      </span>
                      <span style={{ marginLeft: '10px', fontSize: '14px' }}>
                        {showMemoStruct?.memo}
                      </span>
                    </div>
                  ) : (
                    notice &&
                    notice.recentlyTreatmentTime && (
                      <div
                        style={{
                          fontSize: '1rem',
                          color: theme.e,
                          cursor: 'pointer',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                        onClick={() => {
                          if (!outRecipeDisable) {
                            setTempType(true)
                            setIsModalVisible(true)
                          }
                        }}
                      >
                        最近就诊：
                        {moment(notice.recentlyTreatmentTime).format(
                          'YYYY/MM/DD'
                        )}
                        <Tooltip title={notice?.disease}>
                          <span
                            style={{
                              marginLeft: 15,
                              maxWidth: 400,
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            疾病名称：{notice?.disease}
                          </span>
                        </Tooltip>
                      </div>
                    )
                  )}
                </div>
              </div>
            </Popover>
          </Col>
        ) : (
          <Col span={12}>
            {treatment?.insuranceCode && treatment?.insuranceCode != 'ZIFEI' && (
              <div style={{ marginLeft: '20px' }}>
                {treatment?.patientName == undefined
                  ? ' '
                  : treatment?.patientName}{' '}
                {treatment?.insuranceCode !== 'ZIFEI'
                  ? `个账余额：${
                      patientInsurance?.zhye == undefined
                        ? ' '
                        : patientInsurance?.zhye
                    }元 `
                  : ' '}
                {patientInsurance?.memberTypemc &&
                  patientInsurance?.memberTypemc}{' '}
                {'（'}
                {getGenderName(treatment?.patientSex)},
                {getAge(treatment?.patientAge, treatment?.patientMonth, '周岁')}
                {'）'}
                {patientInsurance?.ybType?.toString() ? (
                  <span
                    style={{
                      fontSize: '14px',
                      color: 'rgb(7, 193, 96)',
                    }}
                  >
                    {getYBTypeName(patientInsurance?.ybType?.toString())}
                  </span>
                ) : (
                  ''
                )}
                {patientInsurance?.patientId?.toString() ? (
                  <span
                    style={{
                      fontSize: '14px',
                      color: '#FAB714',
                      marginLeft: '10px',
                    }}
                  >
                    {patientInsurance?.patientId == -1
                      ? '本年首次'
                      : notice?.isFirstDiagnosisThisYear
                      ? '本年首次'
                      : '本年复诊'}
                  </span>
                ) : (
                  ''
                )}
                <Button
                  disabled={searchPsnSumDisabled}
                  type='link'
                  onClick={() => {
                    if (patientInsurance?.ybPersonalNo) {
                      setSearchPsnSumDisabled(true)
                      dispatch(
                        searchPsnSumForLocalAsync({
                          psnNo: patientInsurance.ybPersonalNo,
                        })
                      )
                        .then(unwrapResult)
                        .then((res: any) => {
                          setSumVisible(true)
                          setSumList(res)
                          setSearchPsnSumDisabled(false)
                        })
                        .catch((ex: any) => {
                          setSearchPsnSumDisabled(false)
                        })
                    } else {
                      notification.warn({
                        message: '查询不到该患者的医保信息',
                        duration: 1.5,
                      })
                    }
                  }}
                >
                  查询医保账户累计
                </Button>
              </div>
            )}
            <span></span>
          </Col>
        )}

        <Col span={12} style={{ display: 'flex' }}>
          <div style={{ flex: 1 }} />
          <Space>
            <div
              style={{
                width: '20px',
                height: '20px',
                cursor: 'pointer',
                padding: '5px 10px',
              }}
              onDoubleClick={(v) => {
                if (!openButton) {
                  setOpenButton(true)
                } else {
                  setOpenButton(false)
                }
              }}
            ></div>
            <RemindTips />
            {patientData?.registration.insuranceCode !== 'ZIFEI' && (
              <Button
                type='primary'
                // disabled={!treatment?.id}
                onClick={async () => {
                  beforeAlert('requestString')
                  setIsShowBeforeAlert(true)
                }}
              >
                查询医保事前提醒
              </Button>
            )}

            {patientData?.registration.insuranceCode !== 'ZIFEI' &&
              props.currentTab === 'editor' &&
              props?.tag != 'retail' && (
                <Button
                  type='primary'
                  danger
                  disabled={
                    !recipes.filter(isRecipePayable).length || outRecipeDisable
                  }
                  onClick={() => {
                    setOutRecipeVisible(true)
                  }}
                >
                  处方外配
                </Button>
              )}
            {patientData?.registration.insuranceCode == 'ZIFEI' &&
              openButton &&
              props.currentTab === 'editor' &&
              props?.tag != 'retail' &&
              settlementEnabled && (
                <Button
                  type='primary'
                  danger
                  disabled={
                    !recipes.filter(isRecipePayable).length || outRecipeDisable
                  }
                  onClick={() => {
                    setOutRecipeVisible(true)
                  }}
                >
                  处方外配
                </Button>
              )}
            {props.currentTab === 'editor' && settlementEnabled && (
              <Button
                type='primary'
                loading={readLoading}
                disabled={
                  !recipes.filter(isRecipePayable).length || outRecipeDisable
                }
                onClick={() => {
                  if (props.toothRegistrationId || props?.tag == 'retail') {
                    checkTreatmentSubmit()
                  } else {
                    if (!tipsCount) {
                      notification.info({
                        message: '请输入正确的帖数。',
                      })
                    } else {
                      checkTreatmentSubmit()
                    }
                  }
                }}
              >
                {props.toothRegistrationId
                  ? '结算'
                  : props?.tag == 'retail'
                  ? '预结算'
                  : '诊间结算'}
              </Button>
            )}
            {props.currentTab === 'editor' && (
              <Button
                type='primary'
                disabled={outRecipeDisable}
                loading={finishingState === LoadingState.Loading}
                onClick={() => {
                  // 完成按钮
                  if (props.toothRegistrationId || props?.tag == 'retail') {
                    if (!tipsCount) {
                      notification.info({
                        message: '请输入正确的帖数。',
                      })
                    } else {
                      dispatch(checkTreatmentAsync(treatment?.id || ''))
                        .then(unwrapResult)
                        .then(() => {
                          if (
                            checkedData &&
                            checkedData.find((v: any) => v.code === '4')
                              ?.codeName === 'true' &&
                            isDrawer
                          ) {
                            dispatch(
                              getIframePreScriptionAsync({
                                ...treatmentData,
                                prescriptionReviewRequestType: 1,
                              })
                            )
                              .then(unwrapResult)
                              .then((res: any) => {
                                if (res.iframeUrl) {
                                  setIsDrawerVisible(true)
                                  setErrorIframeUrl(
                                    `${res.iframeUrl}?id=${res.prescriptionReviewId}&ADTAG=${res.hospitalId}&token=${res.token}&module=rational&iframe_width="280px"`
                                  )
                                } else {
                                  if (treatment) {
                                    setFinishingState(LoadingState.Loading)
                                    dispatch(
                                      finishTreatment(treatment.id)
                                    ).then(() => {
                                      setFinishingState(LoadingState.Idle)
                                      dispatch(toothSetPatientInfo({}))
                                      dispatch(
                                        traceRoute({
                                          name: '医生门诊',
                                          path: '/patients',
                                        })
                                      )
                                    })
                                  }
                                }
                              })
                          } else {
                            if (treatment) {
                              setFinishingState(LoadingState.Loading)
                              dispatch(finishTreatment(treatment.id)).then(
                                () => {
                                  setFinishingState(LoadingState.Idle)
                                  if (props.toothRegistrationId) {
                                    props.onAction && props.onAction('wancheng')
                                    return
                                  }
                                  dispatch(toothSetPatientInfo({}))
                                  dispatch(
                                    traceRoute({
                                      name: '医生门诊',
                                      path: '/patients',
                                    })
                                  )
                                }
                              )
                            }
                          }
                        })
                    }
                  } else {
                    // 诊断完毕按钮
                    // inventoryInfoList 库存不足的数据，给出提示
                    if (inventoryInfoList?.length) {
                      setIsTipsModalVisible(true)
                    } else {
                      if (!tipsCount) {
                        notification.info({
                          message: '请输入正确的帖数。',
                        })
                      } else {
                        if (tenantAreaCd == '330522' && !hasBeenAlerted) {
                          //长兴地区 事前提醒检查
                          beforeAlert('requestString')
                          setIsShowBeforeAlert(true)
                        } else {
                          dispatch(checkTreatmentAsync(treatment?.id || ''))
                            .then(unwrapResult)
                            .then(() => {
                              if (
                                checkedData &&
                                checkedData.find((v: any) => v.code === '4')
                                  ?.codeName === 'true' &&
                                isDrawer
                              ) {
                                dispatch(
                                  getIframePreScriptionAsync({
                                    ...treatmentData,
                                    prescriptionReviewRequestType: 1,
                                  })
                                )
                                  .then(unwrapResult)
                                  .then((res: any) => {
                                    if (res.iframeUrl) {
                                      setIsDrawerVisible(true)
                                      setErrorIframeUrl(
                                        `${res.iframeUrl}?id=${res.prescriptionReviewId}&ADTAG=${res.hospitalId}&token=${res.token}&module=rational&iframe_width="280px"`
                                      )
                                    } else {
                                      if (treatment) {
                                        setFinishingState(LoadingState.Loading)
                                        dispatch(
                                          finishTreatment(treatment.id)
                                        ).then(() => {
                                          setFinishingState(LoadingState.Idle)
                                          dispatch(toothSetPatientInfo({}))
                                          dispatch(
                                            traceRoute({
                                              name: '医生门诊',
                                              path: '/patients',
                                            })
                                          )
                                        })
                                      }
                                    }
                                  })
                              } else {
                                if (treatment) {
                                  setFinishingState(LoadingState.Loading)
                                  dispatch(finishTreatment(treatment.id)).then(
                                    () => {
                                      setFinishingState(LoadingState.Idle)
                                      if (props.toothRegistrationId) {
                                        props.onAction &&
                                          props.onAction('wancheng')
                                        return
                                      }
                                      dispatch(toothSetPatientInfo({}))
                                      dispatch(
                                        traceRoute({
                                          name: '医生门诊',
                                          path: '/patients',
                                        })
                                      )
                                    }
                                  )
                                }
                              }
                            })
                        }
                      }
                    }
                  }
                }}
              >
                {props.toothRegistrationId || props?.tag == 'retail'
                  ? '完成'
                  : '诊断完毕'}
              </Button>
            )}
          </Space>
        </Col>
      </Row>
      <Modal
        width={800}
        footer={null}
        title='医保事前审核'
        visible={isShowBeforeAlert}
        onOk={(v) => {
          setIsShowBeforeAlert(false)
        }}
        onCancel={(v) => {
          setIsShowBeforeAlert(false)
        }}
      >
        <div dangerouslySetInnerHTML={{ __html: beforeAlertMessage }} />
      </Modal>
      <Modal
        className={styles.history}
        width={1200}
        style={{ height: 720, maxHeight: 720, overflow: 'hidden' }}
        title='历史就诊信息'
        visible={isModalVisible}
        footer={null}
        onCancel={() => {
          treatmentsRef.current?.reset()
          setIsModalVisible(false)
        }}
        destroyOnClose
      >
        <Treatments
          style={{ height: '100%' }}
          ref={treatmentsRef}
          patientId={registration?.patientId}
          treatmentId={notice?.treatmentId}
          tempType={tempType}
          onCopy={(vs) => {
            const params = vs as CopyTreatmentParams
            setIsModalVisible(false)
            if (params.copyRecipe) {
              setIsCopyReciptModalVisible(true)
              setIsCopyReciptParam(params)
              // Modal.confirm({
              //   title: '是否从处方复制价格？',
              //   cancelText: '使用目录价格',
              //   okText: '使用处方价格',
              //   onOk: () => {
              //     dispatch(
              //       copyTreatment({
              //         ...params,
              //         registrationId,
              //         copyWithAmount: true,
              //       })
              //     ).then(() => {
              //       dispatch(
              //         getTreatment({
              //           registrationId,
              //           treatmentId,
              //           medicalRecord: 3,
              //         })
              //       )
              //     })
              //   },
              //   onCancel: () => {
              //     dispatch(
              //       copyTreatment({
              //         ...params,
              //         registrationId,
              //         copyWithAmount: false,
              //       })
              //     ).then(() => {
              //       dispatch(
              //         getTreatment({
              //           registrationId,
              //           treatmentId,
              //           medicalRecord: 3,
              //         })
              //       )
              //     })
              //   },
              // })
            } else {
              dispatch(copyTreatment({ ...params, registrationId })).then(
                () => {
                  dispatch(
                    getTreatment({
                      registrationId,
                      treatmentId,
                      medicalRecord: 3,
                    })
                  )
                }
              )
            }
          }}
        />
      </Modal>
      <Modal
        width={450}
        title='风险预警'
        visible={isDrawerVisible}
        footer={null}
        onCancel={() => {
          setIsDrawerVisible(false)
        }}
      >
        <iframe
          src={errorIframeUrl}
          style={{ width: '100%', height: '360px' }}
          scrolling='auto'
          frameBorder='0'
        />
      </Modal>
      {/* 外配处方 */}
      <OutRecipeModal
        onOk={(v) => {
          setOutRepiceLoading(true)
          if (treatment?.insuranceCode === 'JIANGSU') {
            recipeInventoryVerification('requestString', {
              ...v,
              treatmentId: Number(treatment?.id),
            })
          } else {
            outRecipe('requestString', {
              ...v,
              treatmentId: Number(treatment?.id),
            })
          }
        }}
        visible={OutRecipeVisible}
        onCancel={() => setOutRecipeVisible(false)}
        ref={outRecipeRef}
        loading={outRepiceLoading}
      />{' '}
      {/* 通用病历引用病历及处方 */}
      <Modal
        visible={isCopyReciptModalVisible}
        footer={null}
        destroyOnClose
        className={styles.modal}
      >
        <div
          style={{
            padding: '32px',
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
          }}
        >
          <InfoCircleOutlined
            style={{
              color: '#faad14',
              marginRight: '10px',
              fontSize: '22px',
            }}
          />
          <span style={{ fontWeight: 'bold', fontSize: '16px' }}>
            是否从处方复制价格？
          </span>
        </div>
        <CloseOutlined
          style={{
            position: 'absolute',
            color: '#8c8c8c',
            right: 10,
            top: 20,
            marginRight: '10px',
            fontSize: '22px',
          }}
          onClick={() => {
            setIsCopyReciptModalVisible(false)
            setcfBtnLoading(false)
            setmlBtnLoading(false)
            setkcBtnLoading(false)
          }}
        />
        <Row
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '20px',
          }}
        >
          <Button
            style={{
              marginRight: '14px',
            }}
            loading={mlBtnLoading}
            onClick={() => {
              setmlBtnLoading(true)
              dispatch(
                copyTreatment({
                  ...isCopyReciptParam,
                  registrationId,
                  copyAmountType: 0,
                })
              )
                .then(unwrapResult)
                .then(() => {
                  setmlBtnLoading(false)
                  setIsCopyReciptModalVisible(false)
                  dispatch(
                    getTreatment({
                      registrationId,
                      treatmentId,
                      medicalRecord: 3,
                    })
                  )
                })
                .catch(() => {
                  setmlBtnLoading(false)
                  setIsCopyReciptModalVisible(false)
                })
            }}
          >
            使用目录价格
          </Button>
          <Button
            style={{
              marginRight: '14px',
            }}
            loading={cfBtnLoading}
            type='primary'
            onClick={() => {
              setcfBtnLoading(true)
              dispatch(
                copyTreatment({
                  ...isCopyReciptParam,
                  registrationId,
                  copyAmountType: 1,
                })
              )
                .then(unwrapResult)
                .then(() => {
                  setIsCopyReciptModalVisible(false)
                  setcfBtnLoading(false)
                  notification.success({
                    message: '病历和处方已复制',
                  })
                  dispatch(
                    getTreatment({
                      registrationId,
                      treatmentId,
                      medicalRecord: 3,
                    })
                  )
                })
                .catch(() => {
                  setcfBtnLoading(false)
                  setIsCopyReciptModalVisible(false)
                })
            }}
          >
            使用处方价格
          </Button>
          <Button
            style={{
              marginRight: '14px',
            }}
            loading={kcBtnLoading}
            type='primary'
            onClick={() => {
              setkcBtnLoading(true)
              dispatch(
                copyTreatment({
                  ...isCopyReciptParam,
                  registrationId,
                  copyAmountType: 2,
                })
              )
                .then(unwrapResult)
                .then(() => {
                  setIsCopyReciptModalVisible(false)
                  setkcBtnLoading(false)
                  notification.success({
                    message: '病历和处方已复制',
                  })
                  dispatch(
                    getTreatment({
                      registrationId,
                      treatmentId,
                      medicalRecord: 3,
                    })
                  )
                })
                .catch(() => {
                  setkcBtnLoading(false)
                  setIsCopyReciptModalVisible(false)
                })
            }}
          >
            使用库存价格
          </Button>
        </Row>
      </Modal>
      <Modal
        className={styles.ModalContent}
        cancelText='返回'
        okButtonProps={{
          style: { display: 'none' },
        }}
        visible={sumVisible}
        title={`${patientInsurance?.name} ${sumList?.insutype} 账户年度累计`}
        onCancel={() => setSumVisible(false)}
      >
        <div style={{ marginTop: '-10px' }}>
          <span style={{ fontWeight: 'bold' }}>
            当年账户余额：{patientInsurance?.dnzhye}
          </span>
          <span style={{ fontWeight: 'bold', marginLeft: '10px' }}>
            历年账户余额：{patientInsurance?.lnzhye}
          </span>
        </div>
        <div>
          <span style={{ fontWeight: 'bold' }}>
            工作单位：{patientInsurance?.brdw}
          </span>
        </div>
        <div style={{ marginBottom: '10px' }}>
          <span style={{ fontWeight: 'bold' }}>
            人员类型：{patientInsurance?.memberTypemc}
          </span>
        </div>
        <div>
          <Table
            scroll={{ y: 300 }}
            dataSource={sumList?.currYearInsCum}
            columns={[
              {
                title: '累计类型名称',
                align: 'left',
                width: '82%',
                render: (_, r) => {
                  return r.name
                },
              },
              {
                title: '累计数',
                align: 'center',
                render: (_, r) => {
                  return r.cum
                },
              },
            ]}
            pagination={false}
          />
        </div>
      </Modal>
      <PrintTemplateModal
        patientId={Number(registration?.patientId)}
        visible={!!printTemplateCategory}
        templateCategory={printTemplateCategory}
        recipeId={selectedRecipeId}
        onCancel={() => setPrintTemplateCategory(undefined)}
        onOk={() => setPrintTemplateCategory(undefined)}
      />
      <Modal
        title='系统提示'
        width={900}
        okText='确认'
        visible={isTipsModalVisible}
        cancelButtonProps={{ style: { display: 'none' } }}
        onCancel={() => setIsTipsModalVisible(false)}
        onOk={() => setIsTipsModalVisible(false)}
      >
        <Table
          className={styles.table}
          dataSource={inventoryInfoList}
          pagination={false}
          size='small'
          bordered
          rowKey={(_, index) => `${index}`}
          columns={[
            {
              title: '序号',
              key: 'no',
              align: 'center',
              width: '3rem',
              render: (_1, _2, index) => `${index + 1}`,
            },
            {
              title: '药品名称',
              key: 'name',
              align: 'center',
              ellipsis: true,
              render: (_, m) => ({
                props: {
                  style: {
                    whiteSpace: 'nowrap',
                  },
                },
                children: m.name,
              }),
            },
            {
              title: '所需数量',
              dataIndex: 'drugCount',
              align: 'center',
              width: '5rem',
            },
            {
              title: '原因',
              key: 'message',
              align: 'center',
              ellipsis: true,
              render: (_, m) => ({
                props: {
                  style: {
                    whiteSpace: 'nowrap',
                  },
                },
                children: m.inventoryInfo || m.unShelveInfo || m.skinTestInfo,
              }),
            },
          ]}
        />
      </Modal>
      <Modal
        title='自费转医保'
        visible={registrationForConvert != null}
        onCancel={() => setRegistrationForConvert(null)}
        footer={null}
      >
        <span>
          您正在操作
          <span style={{ fontWeight: 'bold' }}>
            {' '}
            [{registrationForConvert?.patientName}]
          </span>{' '}
          的自费转医保，请先读卡。
        </span>
        <Row
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '20px',
          }}
        >
          <Button
            loading={readCardLoading}
            style={{
              marginRight: '14px',
            }}
            type='primary'
            onClick={() => {
              readCard(3)
            }}
          >
            读实体卡
          </Button>
          <Button
            loading={readCardLoading}
            style={{
              marginRight: '14px',
            }}
            type='primary'
            onClick={() => {
              readCard(1)
            }}
          >
            读电子凭证
          </Button>
          <Button
            style={{
              marginRight: '14px',
            }}
            onClick={() => {
              setRegistrationForConvert(null)
            }}
          >
            取消
          </Button>
        </Row>
      </Modal>
    </div>
  )
}
