import { Button, Col, Form, Input, Row, Select, Space } from 'antd'
import React, { ReactElement, useEffect, useState } from 'react'
import { SearchOutlined, CaretDownFilled } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import {
  DateRangeType,
  DateSelect,
  getDateRange,
} from '../../../compnents/form/DateSelect'
import { CompletedTreatmentParams } from '../../../services/completedTreatment'
import moment from 'moment'
import { DateTimeFormatSimpleOnly } from '../../../models/datetime'
import { unwrapResult } from '@reduxjs/toolkit'
import { RootDispatch } from '../../../app/store'
import {
  getLatelyOperatorList,
  getProjectList,
} from '../toBeCompletedTreatment/toBeCompletedTreatmentSlice'
import {getUsersInDepartmentAsync} from "../../completion/completionSlice";
import {StationCategory, User} from "../../../models/user";
import {getFullDoctorList} from "../../registration/list/registrationSlice";

const { Option } = Select

export const CompletedTreatmentQuery = (props: {
  dataList: any[]
  onValueChange: (queries: CompletedTreatmentParams) => void
  patientId?: string
  export:()=> void
}): ReactElement => {
  const [form] = Form.useForm()

  const dispatch = useDispatch<RootDispatch>()

  const [doctors, setDoctors] = useState<User[]>([]);

  // const [data, setData] = useState<any[]>([])

  const [latelyOperatorName, setLatelyOperatorName] = useState('')

  const dataParams = JSON.parse(
    sessionStorage.getItem('/medicalSation_CompletedTreatment') || '{}'
  )

  useEffect(() => {
    dispatch(getFullDoctorList())
      .then(unwrapResult)
      .then((ds) => setDoctors(ds))
      .catch(() => {
        // do nothing
      })
  }, [])
  useEffect(() => {
    const [head, tail] = getDateRange(DateRangeType.Today)
    if (Object.keys(dataParams).length && !props.patientId) {
      form.setFieldsValue({
        ...dataParams,
        timeHead: dataParams?.startTime
          ? moment(dataParams?.startTime).format(DateTimeFormatSimpleOnly)
          : head,
        timeTail: dataParams?.endTime
          ? moment(dataParams?.endTime).format(DateTimeFormatSimpleOnly)
          : tail,
      })
    } else {
      form.setFieldsValue({
        timeHead: head,
        timeTail: tail,
      })
    }
    form.submit()
  }, [])

  // useEffect(() => {
  //   dispatch(getProjectList({ state: 1 }))
  //     .then(unwrapResult)
  //     .then((res: any) => {
  //       setData(res)
  //     })
  // }, [])

  const refresh = () => {
    const vs = form.getFieldsValue()
    props.onValueChange({
      ...dataParams,
      ...vs,
      state: 1,
      timeHead: null,
      timeTail: null,
      startTime: vs?.timeHead
        ? moment(vs?.timeHead).format(DateTimeFormatSimpleOnly)
        : undefined,
      endTime: vs?.timeTail
        ? moment(vs?.timeTail).format(DateTimeFormatSimpleOnly)
        : undefined,
      current: 1,
    })
  }

  return (
    <Form
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
      form={form}
      name='control-hooks'
      autoComplete='off'
      onFinish={(vs) => {
        props.onValueChange({
          current: 1,
          ...dataParams,
          state: 1,
          ...vs,
          timeHead: null,
          timeTail: null,
          startTime: vs?.timeHead
            ? moment(vs?.timeHead).format(DateTimeFormatSimpleOnly)
            : null,
          endTime: vs?.timeTail
            ? moment(vs?.timeTail).format(DateTimeFormatSimpleOnly)
            : null,
        })
      }}
    >
      <Row
        style={{ marginBottom: 10, width: '100%', height: '42px' }}
        align='middle'
        justify='space-between'
      >
        <Space>
          {!props.patientId && (
            <Form.Item name='keyword' noStyle>
              <Input
                size='middle'
                placeholder='患者姓名/手机号/助记码'
                allowClear
                onChange={refresh}
                prefix={<SearchOutlined />}
              />
            </Form.Item>
          )}
          <Form.Item name='treatmentServiceName' noStyle>
            <Select
              placeholder='项目'
              style={{ width: 150 }}
              allowClear
              onChange={refresh}
            >
              {props.dataList.map((v) => (
                <Option key={v.id} value={v.treatmentServiceName}>
                  {v.treatmentServiceName}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name='latelyOperatorId' noStyle>
            {/* <Select
              placeholder='请选择执行人'
              allowClear
              suffixIcon={<CaretDownFilled />}
              onChange={refresh}
            >
              {doctorList.map((v: any) => (
                <Option key={v.latelyOperatorId} value={v.latelyOperatorId}>
                  {v.latelyOperatorName}
                </Option>
              ))}
            </Select> */}
            <Select
              allowClear
              style={{ width: 180 }}
              placeholder='执行人'
              onChange={form.submit}
              // suffixIcon={
              //   <CaretDownFilled style={{ color: theme.tc3 }} />
              // }
            >
              {doctors.map((d) => (
                <Select.Option key={d.id} value={d.id}>
                  {d.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name='' noStyle>
            <DateSelect
              placeholder='执行时间范围'
              style={{ width: '11rem' }}
              onChange={refresh}
              labelPrefix=''
              initialValue={DateRangeType.Today}
              namePrefix='time'
            />
          </Form.Item>
          <Button onClick={refresh} type='primary' style={{ float: 'right' }}>
            查询
          </Button>
        </Space>
        <Space>
          <Button
            onClick={() => {
              props?.export && props?.export()
            }}
            type='primary'
          >
            导出
          </Button>
        </Space>
      </Row>
    </Form>
  )
}
