
import { API_PREFIX } from './constant'
import { Request } from './request'

export interface DrugWarningListParams {
  current?: number
  size?: number
  drugName?:any
  type?:any
}

export function drugWarningList(
  params: DrugWarningListParams
): Request {
  return {
    url: `${API_PREFIX}/blade-reminder/reminder/listRules`,
    method: 'GET',
    params,
  }
}

export interface DeleteDrugWarningParams {
  id?:any
}

export function deleteDrugWarning(
  params: DeleteDrugWarningParams
): Request {
  return {
    url: `${API_PREFIX}/blade-reminder/reminder/ruleRemove`,
    method: 'POST',
    params,
  }
}

export interface DrugWarningSubmitParams {
  ageLower?: any
  ageUpper?: any
  dose?:any
  doseUnit?:any
  drugName?:any
  gender?:any
  id?:any
  reminderContent?:any
  type?:any
}

export const drugWarningSubmit =(
  body: DrugWarningSubmitParams
): Request =>{
  return {
    url: `${API_PREFIX}/blade-reminder/reminder/ruleSubmit`,
    method: 'POST',
    body,
  }
}


export interface DrugWarningDetailParams {
  ruleId?: any
}


export const drugWarningDetail =(
  params: DrugWarningDetailParams
): Request =>{
  return {
    url: `${API_PREFIX}/blade-reminder/reminder/ruleDetail`,
    method: 'GET',
    params,
  }
}




