/*
 * @Descripttion: 
 * @version: 
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-05-31 10:33:39
 * @LastEditors: linxi
 * @LastEditTime: 2024-09-29 11:44:10
 */
import { CaretDownFilled } from '@ant-design/icons';
import { unwrapResult } from '@reduxjs/toolkit';
import { Button, Col, Input, message, Modal, notification, Row, Select, Space, TableColumnType } from 'antd';
import React, { ReactElement, useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserName, selectUserId, selectTenantAreaCd, selectInsuranceArray, selectTenantAddress, selectTenantCategory, selectTenantName } from '../../app/applicationSlice';
import { RootDispatch } from '../../app/store';
import { selectInsuranceItem, setInsurance } from '../../layouts/insuranceSlice';
import { getTradeCode, medicalCategory, readTypes,testReadTypes } from '../../models/material';
import { ThemeContext } from '../../theme/ThemeContext';
import { medicalSendHttp, readCardEHttp, readCardSHttp, strParse } from '../../utils/MedicalUtils';
import { updateCardInfoAsync } from '../payment/confirm/paymentConfirmSlice';
import styles from './otherInsuranceAPI.module.css'
import { getPatientInfoAsync } from '../registration/register/registerSlice'
import form from 'antd/lib/form';
import { searchMedical } from '../../services/registration';
import moment from 'moment';
import {DateTimeFormatSimpleOnly, DateTimeFormatSimpleOnlyOne} from '../../models/datetime';
import {
  departmentInfoQueryAsync,
  medicalExecutiveAsync,
  querypsnmedcrcdAsync,
  queryPersonalInsuranceAsync,
  diseaseRecordQueryAsync,
  inHospitalInfoQueryAsync,
  transferInfoQueryAsync,
  queryMdtrtInfoAsync,
  querySetlDiseListAsync,
  querySetlInfoAsync,
  queryFeeListAsync,
  mutualInfoQueryAsync,
  detailInfoQueryAsync,
  downloadCate1301Async,
  searchZd1901,
  queryDyInfoAsync,
  changeCardPwdAsync,
  getTenantInfoAsync,
  downloadCate1399Async,
  search1316Async, search1317Async, monthlySummaryStatusQueryAsync, accountReconciliationPermissionQueryAsync
} from './otherInsuranceSilce';
import { serverType } from "../../services/commodity";
import {fixmedinsType, getQueryName, medTypeOption, personInfoQuery} from '../../models/otherInsurance';
import { EditableList } from '../../compnents/list/EditableList';
import {fileDownloadAsync, medicalTradeAsync, mlDownFilePageParseAsync} from "../../layouts/layoutSlice";

interface otherInsuranceProps {
  onOk?: () => void
  visibility: boolean
  value: number
  readCard: boolean
  code: string
}

export const OtherInsuranceModel = ({
  onOk,
  visibility,
  value,
  readCard,
  code
}: otherInsuranceProps): ReactElement => {
  const {Option} = Select;

  const theme = useContext(ThemeContext)

  const readCardRef = useRef<any>(null)

  const dispatch = useDispatch<RootDispatch>()

  const baseData = useSelector(selectInsuranceItem)

  const userName = useSelector(selectUserName)

  const userId = useSelector(selectUserId)

  const tenantAreaCd = useSelector(selectTenantAreaCd)

  const tenantAddress = useSelector(selectTenantAddress)

  const tenantName = useSelector(selectTenantName)

  const tenantCategory = useSelector(selectTenantCategory)

  const cycleCode = sessionStorage.getItem('cycleCode')

  const insuranceArray = useSelector(selectInsuranceArray)

  const [insuranceItem, setInsuranceItem] = useState<any>()

  const [readCardValue, setReadCardValue] = useState(1)

  const [sfzh, setSfzh] = useState('')

  const [showSfz, setShowSfz] = useState(true)

  const [readLoading, setReadLoading] = useState(false)

  const [info, setInfo] = useState<any>(null)

  const [personType, setPersonType] = useState(0)

  const [bizAppyType, setBizAppyType] = useState('')

  const [insutype, setInsutype] = useState('')

  const [inputFixmedinsType, setInputFixmedinsType] = useState('')

  const [fixmedinsName, setFixmedinsName] = useState('')

  const [medType, setMedType] = useState('')

  const [viewList, setViewList] = useState<any>([])

  const [detail, setDetail] = useState('')

  const [diseDetail, setDiseDetail] = useState('')

  const [twoVisibity, setTwoVisibity] = useState(false)

  const [diseList, setDiseList] = useState<any>([]) //5202 诊断信息列表

  const [showDist, setShowDist] = useState(false) //5202 显示诊断信息

  const [twoValue, setTwoValue] = useState(0)

  const [mdtrtId, setMdtrtId] = useState('')

  const [zdType, setZdType] = useState('')

  const [hilistCode, setHilistCode] = useState('')

  const [noCardPsnNo, setNoCardPsnNo] = useState('')

  const [accountDate, setAccountDate] = useState('')

  const [omsgid, setOmsgid] = useState('')

  const [oinfno, setOinfno] = useState('')

  const [ver, setVer] = useState('')
  const [mlinfno, setMlInfno] = useState('')

  const [path, setPath] = useState('')
  const [startPageNum, setStartPageNum] = useState(0)

  const [progress, setProgress] = useState('当前下载进度：0')


  const [setlId, setSetlId] = useState('')
  let verCnt = 1;let nextVer = "";
  const columns: TableColumnType<any>[] = [
    {
      title: "信息",
      align: "center",
      dataIndex: "msg",
      ellipsis: true,
    },
    {
      title: "操作",
      align: "center",
      width: 100,
      render: function Action(_, t): ReactElement {
        return (<div className={styles.action}>
          {
            value === 5201 ? (
                <>
                  <Button type='primary' size='small' onClick={() => {
                    querySetlDiseList('requestString', t.psnNo, t.mdtrtId)
                    setTwoVisibity(true)
                    setTwoValue(5202)
                  }}>诊断信息</Button>
                  <Button type='primary' size='small' onClick={() => {
                    queryFeeList('requestString', t.psnNo, t.mdtrtId)
                    setTwoVisibity(true)
                    setTwoValue(5204)
                  }}>费用信息</Button>
                </>
            ) : <Button type='primary' size='small' onClick={() => {
              detailInfoQuery('requestString', t.psnNo, t.fixmedinsCode, t.rpotcNo)
              setTwoVisibity(true)
              setTwoValue(5402)
            }}>报告信息</Button>
          }
        </div>)
      }
    },
  ]
  useEffect(() => {
    setDetail('')
    setInfo(null)
    setPersonType(0)
    setShowDist(false)
    setDiseList([])
    setTwoValue(0)
    setSetlId('')
    setMdtrtId('')
      setSfzh('')
      setFixmedinsName('')
      setShowSfz(true)
  }, [value])

  // 保存医保结构体
  useEffect(() => {
    if (insuranceItem && JSON.stringify(insuranceItem) !== '{}') {
      dispatch(
          setInsurance({
            ...insuranceItem,
            userName,
            userId,
            cycleCode,
            tenantCategory,
            tenantAreaCd,
            name: tenantName,
            address: tenantAddress,
            areaCode: tenantAreaCd,
            endTime: moment(insuranceItem.endTime).format(
                DateTimeFormatSimpleOnlyOne
            ),
          })
      )
    }
    return () => {
      dispatch(setInsurance({}))
    }
  }, [insuranceItem])

  // 医保读卡
  const readCardsync = () => {
    // success('读卡中')
    readCardRef.current.blur() //按钮失焦，防止重复点击
    setReadLoading(true)
    const hide = message.loading('读卡中', 0)
    setTimeout(() => {
      hide()
      setReadLoading(false)
    }, 3000)
    if (readCardValue === 1) {
      readEType()
    } else if (readCardValue === 2) {
      if (sfzh) {
        getPatientInfo(sfzh, 'requestString')
      } else {
        notification.info({
          message: '请输入身份证号',
        })
      }
    } else if (readCardValue === 3) {
      readSType()
    }
  }

  useEffect(() => {
    if (insuranceArray.length) {
      setInsuranceItem(insuranceArray[0])
    }
  }, [insuranceArray, code])

  // 电子凭证读卡
  const readEType = () => {
    readCardEHttp(
        {
          trade_args: [
            baseData?.countryHospitalNb,
            '01301',
            '',
            userId,
            userName,
            '',
            '',
          ],
        },
        (data, err) => {
          if (!err) {
            getPatientInfo(data.netmessage, 'requestString')
          } else {
            setReadLoading(false)
          }
        }
    )
  }

  // 实体卡读卡
  const readSType = () => {
    readCardSHttp(
        {
          trade_args: [getTradeCode(tenantAreaCd.slice(0, 4))],
        },
        (data, err) => {
          if (!err) {
            getPatientInfo(JSON.stringify(data.netmessage), 'requestString')
          } else {
            setReadLoading(false)
          }
        }
    )
  }

  const success = (str: string) => {
    setReadLoading(true)
    const hide = message.loading(str, 0)
    setTimeout(() => {
      hide()
      setReadLoading(false)
    }, 3000)
  }

  // 获取参保人信息
  const getPatientInfo = (
      fullCardInfo: string,
      serverType: any,
      responseString?: string
  ) => {
    const cardType = readCardValue
    // setFullCardInfo(fullCardInfo)
    dispatch(
        getPatientInfoAsync({
          serverType,
          body: {
            baseData: {
              ...baseData,
              responseString,
            },
            cardType: String(cardType),
            fullCardInfo,
            otherMap: {},
          },
        })
    )
        .then(unwrapResult)
        .then(async (res) => {
          if (serverType === 'requestString') {
            const data = strParse(res)
            await medicalSendHttp(
                {
                  trade_args: [
                    data.infno,
                    data.url,
                    data,
                    insuranceItem.countryHospitalNb,
                    insuranceItem.secretToken,
                  ],
                },
                (call: any, err) => {
                  if (!err) {
                    success('正在获取人员信息')
                    getPatientInfo(
                        fullCardInfo,
                        'response',
                        JSON.stringify(call.netmessage)
                    )
                  } else {
                    setReadLoading(false)
                  }
                }
            )
          } else {
            notification.success({
              message: '读卡成功',
            })
            setReadLoading(false)
            setInfo(res?.patientInsurance)

          }
        })
        .catch(() => {
          setReadLoading(false)
        })
  }
  // 科室信息查询 5101
  const departmentInfoQuery = (
      serverType: serverType,
      responseString?: string,
  ) => {
    dispatch(departmentInfoQueryAsync(
        {
          serverType,
          body: {
            baseData: {
              ...baseData,
              responseString
            },
          }
        }
    )).then(unwrapResult).then(res => {

      if (serverType === 'requestString') {
        const data = strParse(res)
        medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                baseData?.countryHospitalNb,
                baseData?.secretToken,
              ],
              message: '科室信息查询',
            },
            (call: any, err) => {
              !err && departmentInfoQuery('response', JSON.stringify(call.netmessage))
            }
        )
      } else {
        setDetail(res)
      }

    })
  }
  // 医执人员信息查询 5102
  const medicalExecutive = (
      serverType: serverType,
      pracPsnType: number,
      responseString?: string,
  ) => {
    dispatch(medicalExecutiveAsync(
        {
          serverType,
          body: {
            baseData: {
              ...baseData,
              responseString
            },
            pracPsnType
          }
        }
    )).then(unwrapResult).then(res => {
      if (serverType === 'requestString') {
        const data = strParse(res)
        medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                baseData?.countryHospitalNb,
                baseData?.secretToken,
              ],
              message: '医执人员信息查询',
            },
            (call: any, err) => {
              !err && medicalExecutive('response', pracPsnType, JSON.stringify(call.netmessage))
            }
        )
      } else {
        setDetail(res)
      }

    })
  }
    // 人员待遇享受检查 2001
    const queryDy2001 = (
        serverType: serverType,
        psnNo?: string,
        insutype?:string,
        responseString?: string,
    ) => {
        dispatch(queryDyInfoAsync(
            {
                serverType,
                body: {
                    baseData: {
                        ...baseData,
                        insuplcAdmdvs: info.insuranceRegion,
                        responseString: responseString
                    },
                    psnNo:psnNo,
                    medType:medType,
                    insutype:insutype,
                }
            }
        )).then(unwrapResult).then(res => {
            if (serverType === 'requestString') {
                const data = strParse(res)
                medicalSendHttp(
                    {
                        trade_args: [
                            data.infno,
                            data.url,
                            data,
                            baseData?.countryHospitalNb,
                            baseData?.secretToken,
                        ],
                        message: '人员待遇享受检查',
                    },
                    (call: any, err) => {
                        !err && queryDy2001('response', psnNo,insutype, JSON.stringify(call.netmessage))
                    }
                )
            } else {
                if(res&&res?.trtinfo){
                    const arr = res?.trtinfo;
                    let msg = "查询到以下信息：\r\n";
                    msg += arr.map((v:any)=>{
                        return v.fund_pay_type +" ==> " +v.trt_chk_rslt
                    }).join(",")
                    setDetail(msg)
                }else{
                    setDetail("查询结果为空")
                }
            }

        })
    }

  // 就诊信息查询 5201
  const queryMdtrtInfo = (
      serverType: serverType,
      psnNo?: string,
      responseString?: string,
  ) => {
    dispatch(queryMdtrtInfoAsync(
        {
          serverType,
          body: {
            baseData: {
              ...baseData,
              insuplcAdmdvs: info.insuranceRegion,
              responseString
            },
            psnNo,
            medType
          }
        }
    )).then(unwrapResult).then(res => {
      if (serverType === 'requestString') {
        const data = strParse(res)
        medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                baseData?.countryHospitalNb,
                baseData?.secretToken,
              ],
              message: '就诊信息查询',
            },
            (call: any, err) => {
              !err && queryMdtrtInfo('response', psnNo, JSON.stringify(call.netmessage))
            }
        )
      } else {
        setDiseList(res)
        setShowDist(true)
      }

    })
  }
  // 诊断信息查询 5202
  const querySetlDiseList = (
      serverType: serverType,
      psnNo?: string,
      mdtrtId?: string,
      responseString?: string,
  ) => {
    dispatch(querySetlDiseListAsync(
        {
          serverType,
          body: {
            baseData: {
              ...baseData,
              insuplcAdmdvs: info.insuranceRegion,
              responseString
            },
            psnNo,
            mdtrtId
          }
        }
    )).then(unwrapResult).then(res => {
      if (serverType === 'requestString') {
        const data = strParse(res)
        medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                baseData?.countryHospitalNb,
                baseData?.secretToken,
              ],
              message: '诊断信息查询',
            },
            (call: any, err) => {
              !err && querySetlDiseList('response', psnNo, mdtrtId, JSON.stringify(call.netmessage))
            }
        )
      } else {
        setDiseDetail(res)
      }
    })
  }
  // 结算信息查询 5203
  const querySetlInfo = (
      serverType: serverType,
      psnNo?: string,
      responseString?: string,
  ) => {
    dispatch(querySetlInfoAsync(
        {
          serverType,
          body: {
            baseData: {
              ...baseData,
              insuplcAdmdvs: info.insuranceRegion,
              responseString
            },
            psnNo,
            mdtrtId,
            setlId
          }
        }
    )).then(unwrapResult).then(res => {
      if (serverType === 'requestString') {
        const data = strParse(res)
        medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                baseData?.countryHospitalNb,
                baseData?.secretToken,
              ],
              message: '结算信息查询',
            },
            (call: any, err) => {
              !err && querySetlInfo('response', psnNo, JSON.stringify(call.netmessage))
            }
        )
      } else {
        setDetail(res)
      }
    })
  }
  // 费用信息查询 5204
  const queryFeeList = (
      serverType: serverType,
      psnNo?: string,
      mdtrtId?: string,
      responseString?: string,
  ) => {
    dispatch(queryFeeListAsync(
        {
          serverType,
          body: {
            baseData: {
              ...baseData,
              insuplcAdmdvs: info.insuranceRegion,
              responseString
            },
            psnNo,
            mdtrtId
          }
        }
    )).then(unwrapResult).then(res => {
      if (serverType === 'requestString') {
        const data = strParse(res)
        medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                baseData?.countryHospitalNb,
                baseData?.secretToken,
              ],
              message: '费用信息查询',
            },
            (call: any, err) => {
              !err && queryFeeList('response', psnNo, mdtrtId, JSON.stringify(call.netmessage))
            }
        )
      } else {
        setDiseDetail(res)
      }
    })
  }
  // 人员慢特病用药记录查询 5205
  const querypsnmedcrcd = (
      serverType: serverType,
      psnNo?: string,
      responseString?: string,
  ) => {
    dispatch(querypsnmedcrcdAsync(
        {
          serverType,
          body: {
            baseData: {
              ...baseData,
              insuplcAdmdvs: info.insuranceRegion,
              responseString
            },
            psnNo
          }
        }
    )).then(unwrapResult).then(res => {
      if (serverType === 'requestString') {
        const data = strParse(res)
        medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                baseData?.countryHospitalNb,
                baseData?.secretToken,
              ],
              message: '人员慢特病用药记录查询',
            },
            (call: any, err) => {
              !err && querypsnmedcrcd('response', psnNo, JSON.stringify(call.netmessage))
            }
        )
      } else {
        setDetail(res)
      }
    })
  }
  // 人员定点信息查询 5302
  const queryPersonalInsurance = (
      serverType: serverType,
      psnNo?: string,
      responseString?: string,
  ) => {
    dispatch(queryPersonalInsuranceAsync(
        {
          serverType,
          body: {
            baseData: {
              ...baseData,
              insuplcAdmdvs: info.insuranceRegion,
              responseString
            },
            psnNo,
            bizAppyType
          }
        }
    )).then(unwrapResult).then(res => {
      if (serverType === 'requestString') {
        const data = strParse(res)
        medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                baseData?.countryHospitalNb,
                baseData?.secretToken,
              ],
              message: '人员定点信息查询',
            },
            (call: any, err) => {
              !err && queryPersonalInsurance('response', psnNo, JSON.stringify(call.netmessage))
            }
        )
      } else {
        setDetail(res)
      }

    })
  }
  // 在院信息查询 5303
  const inHospitalInfoQuery = (
      serverType: serverType,
      psnNo?: string,
      responseString?: string,
  ) => {
    dispatch(inHospitalInfoQueryAsync(
        {
          serverType,
          body: {
            baseData: {
              ...baseData,
              insuplcAdmdvs: info.insuranceRegion,
              responseString
            },
            psnNo
          }
        }
    )).then(unwrapResult).then(res => {
      if (serverType === 'requestString') {
        const data = strParse(res)
        medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                baseData?.countryHospitalNb,
                baseData?.secretToken,
              ],
              message: '人员定点信息查询',
            },
            (call: any, err) => {
              !err && inHospitalInfoQuery('response', psnNo, JSON.stringify(call.netmessage))
            }
        )
      } else {
        setDetail(res)
      }

    })
  }
  // 转院信息查询 5304
  const transferInfoQuery = (
      serverType: serverType,
      psnNo?: string,
      responseString?: string,
  ) => {
    dispatch(transferInfoQueryAsync(
        {
          serverType,
          body: {
            baseData: {
              ...baseData,
              insuplcAdmdvs: info.insuranceRegion,
              responseString
            },
            psnNo
          }
        }
    )).then(unwrapResult).then(res => {
      if (serverType === 'requestString') {
        const data = strParse(res)
        medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                baseData?.countryHospitalNb,
                baseData?.secretToken,
              ],
              message: '人员定点信息查询',
            },
            (call: any, err) => {
              !err && transferInfoQuery('response', psnNo, JSON.stringify(call.netmessage))
            }
        )
      } else {
          if(res){
              setDetail(JSON.stringify(res))
          }
      }

    })
  }
  const diseaseRecordQuery = (
      serverType: serverType,
      psnNo?: string,
      responseString?: string,
  ) => {
    dispatch(diseaseRecordQueryAsync(
        {
          serverType,
          body: {
            baseData: {
              ...baseData,
              insuplcAdmdvs: info.insuranceRegion,
              responseString
            },
            psnNo
          }
        }
    )).then(unwrapResult).then(res => {

      if (serverType === 'requestString') {
        const data = strParse(res)
        medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                baseData?.countryHospitalNb,
                baseData?.secretToken,
              ],
              message: '人员慢特病诊断备案查询',
            },
            (call: any, err) => {
              !err && diseaseRecordQuery('response', psnNo, JSON.stringify(call.netmessage))
            }
        )
      } else {
        setDetail(res)
      }

    })
  }
  // 医保冲正
  const medicalReverseTarge = (
      serverType: any,
      responseString?: string
  ) => {
    dispatch(
      medicalTradeAsync({
          serverType,
          body: {
              baseData: {
                  ...insuranceItem,
                  endTime: moment(insuranceItem?.endTime).format(
                      DateTimeFormatSimpleOnlyOne
                  ),
                  userName,
                  userId,
                  cycleCode,
                  tenantCategory,
                  tenantAreaCd,
                  name: tenantName,
                  address: tenantAddress,
                  areaCode: tenantAreaCd,
                  responseString: responseString,
              },
              oinfno: oinfno,
              omsgid: omsgid,
              psnNo: noCardPsnNo,
          },
      }) as any
    )
    .then(unwrapResult)
    .then((res: any) => {
      if (serverType === 'requestString') {
          const data = strParse(res)
          medicalSendHttp(
              {
                  trade_args: [
                      data.infno,
                      data.url,
                      data,
                      insuranceItem?.countryHospitalNb,
                      insuranceItem?.secretToken,
                  ],
                  message: '医保冲正',
              },
              (call, err) => {
                  if (!err) {
                      const requestString = call.netmessage
                      medicalReverseTarge(
                          'response',
                          JSON.stringify(requestString)
                      )
                  }
              }
          )
          // })
      } else {
          notification.success({
              message: '医保冲正成功',
              duration: 2.5,
          })
      }
    })
  }
  //1317医药机构目录匹配信息下载
  const search1317 = (
      serverType: serverType,
      responseString?: string,
  ) => {
      dispatch(search1317Async(
          {
              serverType,
              body: {
                  baseData: {
                      ...baseData,
                      responseString
                  },
                  hilistCode:hilistCode,
                  pageSize:20,
                  pageNum:1
              }
          }
      )).then(unwrapResult).then(res => {
          if (serverType === 'requestString') {
              const data = strParse(res)
              medicalSendHttp(
                  {
                      trade_args: [
                          data.infno,
                          data.url,
                          data,
                          baseData?.countryHospitalNb,
                          baseData?.secretToken,
                      ],
                      message: '查询',
                  },
                  (call: any, err) => {
                      !err && search1317("response",JSON.stringify(call.netmessage))
                  }
              )
          } else {
              if(res&&res?.medListMap){
                  const arr = res?.medListMap;
                  let msg = "医保存在以下匹配编码信息：\r\n";
                  msg += arr.map((v:any)=>{
                      return v.med_list_codg +" ==> " +v.medins_list_name
                  }).join(",")
                  setDetail(msg)
              }else{
                  setDetail("查询结果为空")
              }
          }
      })
  }
    //1316医疗目录与医保目录匹配信息查询
    const search1316 = (
        serverType: serverType,
        responseString?: string,
    ) => {
        dispatch(search1316Async(
            {
                serverType,
                body: {
                    baseData: {
                        ...baseData,
                        responseString
                    },
                    hilistCode:hilistCode,
                    pageSize:20,
                    pageNum:1
                }
            }
        )).then(unwrapResult).then(res => {
            if (serverType === 'requestString') {
                const data = strParse(res)
                medicalSendHttp(
                    {
                        trade_args: [
                            data.infno,
                            data.url,
                            data,
                            baseData?.countryHospitalNb,
                            baseData?.secretToken,
                        ],
                        message: '查询',
                    },
                    (call: any, err) => {
                        !err && search1316("response",JSON.stringify(call.netmessage))
                    }
                )
            } else {
                if(res&&res?.medinsHilistMap){
                    const arr = res?.medinsHilistMap;
                    let msg = "查询到以下信息：\r\n";
                    msg += arr.map((v:any)=>{
                        return v.hilist_code
                    }).join(",")
                    setDetail(msg)
                }else{
                    setDetail("查询结果为空")
                }
            }
        })
    }
  // 获取机构信息 1201
  const searchTenantInfo = (
      serverType: serverType,
      responseString?: string,
  ) => {
      dispatch(getTenantInfoAsync(
          {
              serverType,
              body: {
                  baseData: {
                      ...baseData,
                      responseString
                  },
                  fixmedinsType:inputFixmedinsType,
                  fixmedinsName:fixmedinsName
              }
          }
      )).then(unwrapResult).then(res => {
          if (serverType === 'requestString') {
              const data = strParse(res)
              medicalSendHttp(
                  {
                      trade_args: [
                          data.infno,
                          data.url,
                          data,
                          baseData?.countryHospitalNb,
                          baseData?.secretToken,
                      ],
                      message: '查询',
                  },
                  (call: any, err) => {
                      !err && searchTenantInfo("response",JSON.stringify(call.netmessage))
                  }
              )
          } else {
              if(res&&res?.idetinfo?.length){
                  const arr = res?.idetinfo;
                  let msg = "查询到以下机构信息：\r\n";
                  msg += arr.map((v:any)=>{
                      return v.fixmedins_code +" ==> " +v.fixmedins_name
                  }).join(",")
                  setDetail(msg)
              }else{
                  setDetail("查询结果为空")
              }

          }
      })
  }

    // 修改卡密码 1163
  const changeCardPwd = (
      serverType: serverType,
      responseString?: string,
  ) => {
      dispatch(changeCardPwdAsync(
          {
              serverType,
              body: {
                  baseData: {
                      ...baseData,
                      responseString
                  },
              }
          }
      )).then(unwrapResult).then(res => {
          if (serverType === 'requestString') {
              const data = strParse(res)
              medicalSendHttp(
                  {
                      trade_args: [
                          data.infno,
                          data.url,
                          data,
                          baseData?.countryHospitalNb,
                          baseData?.secretToken,
                      ],
                      message: '修改卡密码',
                  },
                  (call: any, err) => {
                      !err && changeCardPwd("response",JSON.stringify(call.netmessage))
                  }
              )
          } else {
              // setDiseList(res)
              // setShowDist(true)
          }
      })
  }

  // 字典表查询 1901
  const zdSearch1901 = (
    serverType: serverType,
    responseString?: string,
  ) => {
    dispatch(searchZd1901(
        {
            serverType,
            body: {
                baseData: {
                    ...baseData,
                    responseString
                },
                zdType,
            }
        }
    )).then(unwrapResult).then(res => {
        if (serverType === 'requestString') {
            const data = strParse(res)
            medicalSendHttp(
                {
                    trade_args: [
                        data.infno,
                        data.url,
                        data,
                        baseData?.countryHospitalNb,
                        baseData?.secretToken,
                    ],
                    message: '字典表查询',
                },
                (call: any, err) => {
                    !err && zdSearch1901("response",JSON.stringify(call.netmessage))
                }
            )
        } else {
            if(res&&res?.list){
                const arr = res?.list;
                let msg = "查询到以下信息：\r\n";
                msg += arr.map((v:any)=>{
                    return v.label +" ==> " +v.value
                }).join(",")
                setDetail(msg)
            }else{
                setDetail("查询结果为空")
            }
        }
    })
  }
  // 目录下载 1399
  const downloadCate1399 = (
      serverType: serverType,
      ver?: string,
      responseString?: string,
  ) => {
      dispatch(downloadCate1399Async(
        {
          serverType,
          body: {
            baseData: {
              ...baseData,
              responseString
            },
            ver,
            infno:mlinfno
          }
        }
    )).then(unwrapResult).then(res => {
      if (serverType === 'requestString') {
        const data = strParse(res)
        medicalSendHttp(
            {
              trade_args: [
                mlinfno,
                data.url,
                data,
                baseData?.countryHospitalNb,
                baseData?.secretToken,
              ],
              message: '医疗目录下载',
            },
            (call: any, err) => {
              !err && fileDownload(
                  call.netmessage.output,
                  insuranceItem,
                  'requestString',
                  mlinfno
              )
            }
        )
      } else {
        // setDiseList(res)
        // setShowDist(true)
      }
    })
  }

  // 9102 下载文件
  const fileDownload = (
      info: any,
      insuranceItem: any,
      serverType: any,
      infno: any,
      responseString?: string,
  ) => {
    dispatch(
        fileDownloadAsync({
          serverType,
          body: {
            baseData: {
              ...insuranceItem,
              endTime: moment(insuranceItem?.endTime).format(
                  DateTimeFormatSimpleOnlyOne
              ),
              userName,
              userId,
              cycleCode,
              tenantCategory,
              tenantAreaCd,
              name: tenantName,
              address: tenantAddress,
              areaCode: tenantAreaCd,
              responseString: responseString ? responseString : null,
            },
            // accountDate: moment(selectItem?.accountDate).format(
            //   DateTimeFormatSimpleOnlyOne
            // ),
            fileQuryNo: info?.file_qury_no,
            filename: info?.filename,
          },
        })
    )
        .then(unwrapResult)
        .then((res) => {
          if (serverType === 'requestString') {
            const data = strParse(res)
            medicalSendHttp(
                {
                  trade_args: [
                    data.infno,
                    data.url,
                    data,
                    insuranceItem?.countryHospitalNb,
                    insuranceItem?.secretToken,
                  ],
                  notification: '下载文件',
                },
                (call: any, err) => {
                  !err && getPageParse(1, call?.netmessage?.output || call?.netmessage, insuranceItem)
                }
            )
          }
        })
        .catch(() => {
          message.destroy()
        })
  }

  // 10000 解析文件
  const getPageParse = (
      pageNum: number,
      fileUrl: string,
      insuranceItem: any,
  ) => {
      if(!fileUrl || !pageNum ||!mlinfno){
          notification.error({
              message: '请填入必要的信息(文件路径，页数，交易号）'
          })
          return;
      }
    medicalSendHttp(
        {
          trade_args: ['10000', fileUrl, String(pageNum), '500'],
        },
        (call) => {
          if (call?.netmessage?.length) {
              setProgress("当前下载至第："+verCnt+"个版本，该版本下载进度："+String(pageNum) + " * 500条" )
            filePageParse(() => {
                getPageParse(pageNum + 1, fileUrl, insuranceItem)
            },insuranceItem, JSON.stringify(call.netmessage), mlinfno)



          } else {
            //完毕,获取下一个版本号并下载
              setVer(nextVer);
              downloadCate1399('requestString', nextVer)


          }
        }
    )
  }

  const filePageParse = (
      callback: () => void,
      insuranceItem: any,
      responseString?: string,
      infno?: any,

  ) => {
    message.loading('正在解析文件', 0)
    dispatch(
        mlDownFilePageParseAsync({
          baseData: {
            ...insuranceItem,
            endTime: moment(insuranceItem?.endTime).format(
                DateTimeFormatSimpleOnlyOne
            ),
            userName,
            userId,
            cycleCode,
            tenantCategory,
            tenantAreaCd,
            name: tenantName,
            address: tenantAddress,
            areaCode: tenantAreaCd,
            responseString: responseString ? responseString : null,
          },
          infno: infno
        })
    )
        .then(unwrapResult)
        .then((res: any) => {
          if(nextVer != res.ver){
              verCnt ++;
          }
            nextVer = res.ver;
          message.destroy()
          callback();
        }).catch(() => {
            message.destroy()
        })
  }
  // 项目互认信息查询 5401
  const mutualInfoQuery = (
      serverType: serverType,
      psnNo?: string,
      responseString?: string,
  ) => {
    dispatch(mutualInfoQueryAsync(
        {
          serverType,
          body: {
            baseData: {
              ...baseData,
              insuplcAdmdvs: info.insuranceRegion,
              responseString
            },
            psnNo,
          }
        }
    )).then(unwrapResult).then(res => {
      if (serverType === 'requestString') {
        const data = strParse(res)
        medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                baseData?.countryHospitalNb,
                baseData?.secretToken,
              ],
              message: '项目互认信息查询',
            },
            (call: any, err) => {
              !err && mutualInfoQuery('response', psnNo, JSON.stringify(call.netmessage))
            }
        )
      } else {
        setDiseList(res)
        setShowDist(true)
      }
    })
  }
  // 报告信息查询 5402
  const detailInfoQuery = (
      serverType: serverType,
      psnNo?: string,
      fixmedinsCode?: string,
      rpotcNo?: string,
      responseString?: string,
  ) => {
    dispatch(detailInfoQueryAsync(
        {
          serverType,
          body: {
            baseData: {
              ...baseData,
              insuplcAdmdvs: info.insuranceRegion,
              responseString
            },
            psnNo,
            fixmedinsCode,
            rpotcNo
          }
        }
    )).then(unwrapResult).then(res => {
      if (serverType === 'requestString') {
        const data = strParse(res)
        medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                baseData?.countryHospitalNb,
                baseData?.secretToken,
              ],
              message: '报告信息查询',
            },
            (call: any, err) => {
              !err && detailInfoQuery('response', psnNo, fixmedinsCode, rpotcNo, JSON.stringify(call.netmessage))
            }
        )
      } else {
        setDiseDetail(res)
      }
    })
  }
  
  const accountReconciliationPermissionQuery3260 = (serverType: serverType,) => {

    dispatch(accountReconciliationPermissionQueryAsync(
      {
        serverType,
        body: {
          baseData: {
            ...baseData,
          }
        }
      }
    )).then(unwrapResult).then(res => {
      if (serverType === 'requestString') {
        const data = strParse(res)
        medicalSendHttp(
          {
            trade_args: [
              data.infno,
              data.url,
              data,
              baseData?.countryHospitalNb,
              baseData?.secretToken,
            ],
            message: '机构对账权限查询',
          },
          (call: any, err) => {
            //call = JSON.parse('{"netcode":"1","netmessage":{"infcode":"0","inf_refmsgid":"null","refmsg_time":"20240614004022701","respond_time":"20240614004022816","enctype":"","signtype":"","err_msg":null,"output":{"enab_flag":"1","fixmedins_code":"H32050500080","fix_blng_admdvs":"320599","fixmedins_name":"苏州爱心诊所"}}}')
            if (!err && call.netmessage.infcode == 0) {

              const output = call.netmessage.output
              Modal.confirm({
                title: '机构对账权限查询',
                content : (
                  <>
                    <div>医药机构编号：{output.fixmedins_code}</div>
                    <div>医药机构名称：{output.fixmedins_name}</div>
                    <div>医药机构医保区划：{output.fix_blng_admdvs}</div>
                    <div>启用标志：{output.enab_flag == 1 ? '对账功能已启用' : '对账功能未启用'}</div>
                  </>
                )
              })
              console.log(JSON.stringify(call.netmessage))
            }
          }
        )
      }
    })
  }

  const monthlySummaryStatusQuery3261 = (serverType: serverType,) => {

    if (!accountDate || accountDate.length != 6) {
      notification.error({
        message: '请输入正确的业务年月 格式：yyyyMM 例:202306'
      })
      return
    }

    dispatch(monthlySummaryStatusQueryAsync(
      {
        serverType,
        body: {
          baseData: {
            ...baseData,
          },
          accountDate : accountDate
        }
      }
    )).then(unwrapResult).then(res => {
      if (serverType === 'requestString') {
        const data = strParse(res)
        medicalSendHttp(
          {
            trade_args: [
              data.infno,
              data.url,
              data,
              baseData?.countryHospitalNb,
              baseData?.secretToken,
            ],
            message: '机构月度对账状态查询',
          },
          (call: any, err) => {

            //call = JSON.parse('{"netcode":"1","netmessage":{"infcode":"0","inf_refmsgid":"null","refmsg_time":"20240614004147902","respond_time":"20240614004148045","enctype":"","signtype":"","err_msg":null,"output":[{"stmt_type":"0","fixmedins_code":"H32050500080","stmt_status":"2","fix_blng_admdvs":"320599","fixmedins_name":"江苏测试综合门诊部 - 季现猛","upld_btch":"0","stmt_mon":"202406"},{"stmt_type":"0","fixmedins_code":"H32050500080","stmt_status":"1","fix_blng_admdvs":"320599","fixmedins_name":"江苏测试综合门诊部 - 季现猛","upld_btch":"1","stmt_mon":"202406"},{"stmt_type":"1","fixmedins_code":"H32050500080","stmt_status":"2","fix_blng_admdvs":"320599","fixmedins_name":"江苏测试综合门诊部 - 季现猛","upld_btch":null,"stmt_mon":"202406"},{"stmt_type":"2","fixmedins_code":"H32050500080","stmt_status":"1","fix_blng_admdvs":"320599","fixmedins_name":"江苏测试综合门诊部 - 季现猛","upld_btch":"1","stmt_mon":"202406"}]}}')
            if (!err && call.netmessage.infcode == 0) {

              setAccountDate('')
              const output = call.netmessage.output.slice(-1)[0]
              Modal.confirm({
                title: '机构月度对账状态查询',
                content : (
                  <>
                    <div>医药机构编号：{output.fixmedins_code}</div>
                    <div>医药机构名称：{output.fixmedins_name}</div>
                    <div>医药机构医保区划：{output.fix_blng_admdvs}</div>
                    <div>对账状态：{output.stmt_status == 1 ? '对账成功' : output.stmt_status == '2' ? '对账失败' : '未对账'}</div>
                  </>
                )
              })
              console.log(JSON.stringify(call.netmessage))
            }
          }
        )

      }
    }).finally(() => {
      setAccountDate('')
    })
  }
  
  const query = () => {

    switch (value) {
      case 5101: {
        detailInfoQuery('requestString')
        break
      }
      case 5102 : {
        if (!personType) {
          notification.error({
            message: '请先选择医执人员类别'
          })
        } else {
          medicalExecutive('requestString', personType)
        }
        break
      }
      case 7014 : {
        diseaseRecordQuery('requestString', info.ybPersonalNo)
        break
      }
      case 5201 : {
        if (!medType) {
          notification.error({
            message: '请先选择医疗类别'
          })
        } else {
          queryMdtrtInfo('requestString', info.ybPersonalNo)
        }
        break
      }
      case 2001 : {
        if (!medType) {
          notification.error({
            message: '请先选择医疗类别'
          })
        } else {
          queryDy2001('requestString', info.ybPersonalNo,info.cbxz)
        }
        break
      }
      case 5203 : {
        if (!mdtrtId || !setlId) {
          notification.error({
            message: '请补充搜索条件'
          })
        } else {
          querySetlInfo('requestString', info.ybPersonalNo)
        }
        break
      }
      case 5205 : {
        querypsnmedcrcd('requestString', info.ybPersonalNo)
        break
      }
      case 5302 : {
        if (!bizAppyType) {
          notification.error({
            message: '请先选择业务申请类型'
          })
        } else {
          queryPersonalInsurance('requestString', info.ybPersonalNo)
        }
        break
      }
      case 5303 : {
        inHospitalInfoQuery('requestString', info.ybPersonalNo)
        break
      }
      case 5304 : {
        transferInfoQuery('requestString', info.ybPersonalNo)
        break
      }
      case 5401 : {
        mutualInfoQuery('requestString', info.ybPersonalNo)
        break
      }
      case 1399 : {
        downloadCate1399('requestString', ver)
        break
      }
      case 1901 : {
        zdSearch1901('requestString')
        break
      }
      case 2601 : {
        medicalReverseTarge('requestString')
        break
      }
      case 1163 : {
        changeCardPwd('requestString')
        break
      }
      case 1201 : {
        searchTenantInfo('requestString')
        break
      }
      case 1316 : {
        search1316('requestString')
        break
      }
      case 1317 : {
        search1317('requestString')
        break
      }
      case 3260 : {
        accountReconciliationPermissionQuery3260('requestString')
        break
      }
      case 3261 : {
        monthlySummaryStatusQuery3261('requestString')
        break
      }
      default : break
    }
  }
  return (
      <Modal
          visible={visibility}
          title={getQueryName(value)}
          width={"50%"}
          footer={null}
          onCancel={() => onOk && onOk()}
          bodyStyle={{  height: 500,width:"100%" }}

      >
        <Row gutter={20} style={{position: 'relative', height: '32px', marginBottom: '10px'}}>
          {
              readCard && (
                  <div>
                      <Col>
                        <Select
                            dropdownMatchSelectWidth={120}
                            defaultValue={readCardValue}
                            disabled={readLoading}
                            style={{
                              maxWidth: '160px',
                              color: theme.tc3,
                              height: 32,
                              marginLeft: 10,
                              backgroundColor: theme.c3,
                              border: 'none',
                            }}
                            size='middle'
                            // suffixIcon={<CaretDownFilled/>}
                            options={tenantName?.indexOf("测试")>=0 ? testReadTypes :readTypes}
                            onChange={(e) => {
                              setReadCardValue(e)
                                if(e == 2){
                                    setShowSfz(false);
                                }else{
                                    setShowSfz(true);
                                }
                            }}
                        />

                        <Button
                            style={{
                              color: '#fff',
                              height: 32,
                              backgroundColor: '#11a9cb',
                              border: 'none',
                            }}
                            onClick={() => {
                              readCardsync()
                            }}
                            loading={readLoading}
                            ref={readCardRef}
                        >
                          读卡
                        </Button>
                      </Col>
                  </div>
              )
          }
          {
              value === 5102 && (
                  <Col>
                    <Select placeholder='请选择医执人员类别' style={{width: 180}} onChange={(v: number) => {
                      setPersonType(v)
                    }}>
                      {
                        medicalCategory.map(v => (
                            <Option key={v.value} value={v.value}>{v.label}</Option>
                        ))
                      }
                    </Select>
                  </Col>
              )
          }
          {

              <Input  style={{width: 200}} hidden={showSfz} placeholder="请输入身份证号码" value={sfzh} onChange={(e) => {
                  setSfzh(e.target.value.trim())
              }}/>
          }
          {
              (value === 5201 || value === 2001) && (
                  <Col>
                    <Select placeholder='请选择医疗类别' style={{width: 200}} onChange={(v: string) => {
                      setMedType(v)
                    }}>
                      {
                        medTypeOption.map(v => (
                            <Option key={v.value} value={v.value}>{v.label}</Option>
                        ))
                      }
                    </Select>
                  </Col>
              )
          }
          {
              (value === 1201) && (
                  <Col>
                      <Select placeholder='请选择定点医疗服务机构类型' style={{width: 200}} onChange={(v: string) => {
                          setInputFixmedinsType(v)
                      }}>
                          {
                              fixmedinsType.map(v => (
                                  <Option key={v.value} value={v.value}>{v.label}</Option>
                              ))
                          }
                      </Select>
                      <Input  style={{width: 200}}  placeholder="请输入机构名称" onChange={(e) => {
                          setFixmedinsName(e.target.value.trim())
                      }}/>
                  </Col>
              )
          }
          {
              value === 5203 && (
                  <Col>
                    <Space>
                      <Input placeholder="请输入就诊ID" value={mdtrtId} onChange={(e) => {
                        setMdtrtId(e.target.value.trim())
                      }}/>
                      <Input placeholder="请输入结算ID" value={setlId} onChange={(e) => {
                        setSetlId(e.target.value.trim())
                      }}/>
                    </Space>
                  </Col>
              )
          }
          {
              value === 5302 && (
                  <Col>
                    <Select placeholder='请选择业务申请类型' style={{width: 200}} onChange={(v: string) => {
                      setBizAppyType(v)
                    }}>
                      {
                        personInfoQuery.map(v => (
                            <Option key={v.value} value={v.value}>{v.label}</Option>
                        ))
                      }
                    </Select>
                  </Col>
              )
          }
          {
            value === 1901 && (
              <Row>
                  <Col>
                      <Input allowClear placeholder={'请输入字典类型'}  onChange={(e) => {
                          setZdType(e.target.value.trim())
                      }}/>

                  </Col>
              </Row>
            )
          }
          {
            value === 1316 && (
                <Row>
                    <Col>
                        <Input allowClear placeholder={'请输入医保目录编码'}  onChange={(e) => {
                            setHilistCode(e.target.value.trim())
                        }}/>
                    </Col>
                </Row>
            )
          }
          {
            value === 1317 && (
                <Row>
                    <Col>
                        <Input allowClear placeholder={'请输入医保目录编码'}  onChange={(e) => {
                            setHilistCode(e.target.value.trim())
                        }}/>
                    </Col>
                </Row>
            )
          }
          {
              value === 1399 && (
              <div>
                  <Space>输入交易号(支持1301，1302，1303，1305，1306，1307，1308，1309，1310，1311，1313，1314，1315，1320，1321)</Space>
                  <Row>
                      <Col span={12}>
                          <Input allowClear placeholder={'请输入交易号'} value={mlinfno} onChange={(e) => {
                              setMlInfno(e.target.value.trim())
                          }}/>

                      </Col>

                  </Row>
                  <Space>下载目录</Space>
                  <Row>
                      <Col>
                        <Input allowClear placeholder={'请输入当前版本号'} value={ver} onChange={(e) => {
                          setVer(e.target.value.trim())
                        }}/>

                      </Col>
                      <Col>
                        <Input disabled={true} style={{width:"400px",marginLeft:"20px"}}  value={progress}/>

                      </Col>
                  </Row>
                  <Space style={{marginTop:"100px"}}>直接从解析文件开始</Space>
                  <Row >
                      <Col>
                          <Input allowClear placeholder={'输入文件路径'}  onChange={(e) => {
                              setPath(e.target.value.trim())
                          }}/>

                      </Col>
                      <Col>
                          <Input allowClear placeholder={'输入起始页码'} style={{marginLeft:"20px"}} onChange={(e) => {
                              setStartPageNum(parseInt(e.target.value.trim()))
                          }}/>

                      </Col>
                      <Button  type='primary' onClick={() => {
                          getPageParse(startPageNum,path,insuranceItem)
                      }}>{'直接解析文件'}</Button>
                  </Row>
              </div>
              )
          }
          {
            (value === 2601) && (
              <Col>
                  <Space>
                      <Input placeholder="人员编号" onChange={(e) => {
                          setNoCardPsnNo(e.target.value.trim())
                      }}/>
                      <Input placeholder="原发送方报文ID" onChange={(e) => {
                          setOmsgid(e.target.value.trim())
                      }}/>
                      <Input placeholder="原交易编号"  onChange={(e) => {
                          setOinfno(e.target.value.trim())
                      }}/>
                  </Space>
              </Col>
            )
          }

          {
            (value === 3261) && (
              <Col>
                <Space>
                  <Input placeholder="对账月份 格式：yyyyMM" onChange={(e) => {
                    setAccountDate(e.target.value.trim())
                  }}/>
                </Space>
              </Col>
            )
          }
          <Button style={{position: 'absolute', top: 0, right: '10px'}} type='primary' onClick={() => {
            if (!info && readCard) {
              notification.error({
                message: '请先读卡'
              })
              return
            }
            query()
          }}>{value == 1399 ? ('下载') : value == 2601 ? ("冲正") : value == 1163 ? ("读卡并修改密码") : ('查询')}</Button>
        </Row>

        <div className={styles.detail}>
          {
              info && (
                  <Row>
                    <Col span={8}>
                      姓名：{info.name}
                    </Col>
                    <Col span={8}>
                      身份证：{info.idCard}
                    </Col>
                    <Col span={8}>
                      参保状态：{info.treatmentStatusmc}
                    </Col>
                    <Col span={8}>
                      参保类型：{info.cbxzmc}
                    </Col>
                    <Col span={12}>
                      医保编号：{info.ybPersonalNo}
                    </Col>
                  </Row>
              )
          }
          <div dangerouslySetInnerHTML={{__html: detail}}></div>
          {showDist && <EditableList scroll={{y: 400}} dataSource={diseList} rowKey={(_, i) => `${i}`} columns={columns}
                                     pagination={false}/>}
        </div>
        <Modal
            visible={twoVisibity}
            title={getQueryName(twoValue)}
            width={'65%'}
            footer={null}
            onCancel={() => setTwoVisibity(false)}
        >
          <div dangerouslySetInnerHTML={{__html: diseDetail}}></div>
        </Modal>
      </Modal>
  );
}