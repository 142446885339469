/* eslint-disable @typescript-eslint/no-empty-function */
import { unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Card,
  Col,
  Dropdown,
  InputNumber,
  Modal,
  notification,
  Row,
  Space,
  Table,
  Tabs,
} from 'antd'
import moment from 'moment'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../app/store'
import { EditableList } from '../../compnents/list/EditableList'
import { TabPane } from '../../compnents/widgets/TabBar'
import { Material } from '../../models/material'
import {
  getTemplateCategoryName,
  PrintTemplateCategory,
} from '../../models/print'
import {
  getRecipeCategoryName,
  isRecipePayable,
  isTcmRecipeCategory,
  Recipe,
  RecipeCategory,
  RecipeStatus,
} from '../../models/recipe'
import { Treatment, TreatmentNotice } from '../../models/treatment'
import { replaceMakeInfoParams } from '../../services/recipe'
import { ThemeContext } from '../../theme/ThemeContext'
import { useQuery } from '../../utils/HttpUtils'
import { PrintTemplateModal } from '../print/PrintTemplateModal'
import {
  DisposeColumns,
  MedicineMaterialColumns,
  TcmColumns,
} from '../treatment/editor/columns'
import {
  getPrintZXDData,
  getReplaceMakeInfo,
  isdeleteReplaceMakeSetting,
} from '../treatment/editor/recipeEditorSlice'
import {
  editST,
  getTreatmentNotice,
  saveToothAsync,
  syncToothAsync,
  updateItemPayTypeAsync,
} from '../treatment/editor/recipeListEditorSlice'
import { ReplaceMakeSettingModal } from '../treatment/editor/modals/ReplaceMakeSettingModal'
import styles from './RecipeList.module.css'
import {
  getMaterialList,
  getRecipeList,
  getTreatment,
  removeMaterials,
  selectMaterialList,
  selectPageLoading,
  selectRecipeList,
  setMaterials,
  setPageLoading,
} from './recipeListSlice'
import { setUpdateFlagsAsync } from '../payment/confirm/paymentConfirmSlice'
import { setTreatmentId } from '../retail/retailSlice'
import _ from 'lodash'
import { MinusOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons'
import { selectEdition } from '../../app/applicationSlice'
import { DecoctingSettingModal } from '../treatment/editor/modals/DecoctingSettingModal'

interface RecipeListProps {
  noCheckBox?: any
  readMode?: boolean
  yidibz?: string
  treatmentId?: string
  onSelect?: (recipes: Recipe[]) => void
  onSelectCurrentRecipe?: (v?: any) => void
  style?: React.CSSProperties
  onNotice?: (notice: TreatmentNotice) => void
  autoSelect?: boolean
}

export const RecipeList = ({
  noCheckBox,
  readMode = false,
  yidibz,
  treatmentId,
  onSelect,
  onSelectCurrentRecipe,
  style,
  onNotice,
  autoSelect = false,
}: RecipeListProps): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const theme = useContext(ThemeContext)

  const hisVersion = useSelector(selectEdition) //his版本 0通用 1口腔

  // const recipes = useSelector(selectRecipeList)

  const materials = useSelector(selectMaterialList)

  const [treatment, setTreatment] = useState<Treatment | undefined>()

  const [currentRecipe, setCurrentRecipe] = useState<Recipe | undefined>()

  const [printTemplateCategory, setPrintTemplateCategory] = useState<
    PrintTemplateCategory | undefined
  >()

  const [selectedRecipes, setSelectedRecipes] = useState<Recipe[]>([])

  const [selectedMaterialIds, setSelectedMaterialIds] = useState<string[]>([])

  const query = useQuery()

  const [isReplaceMakeModalVisible, setIsReplaceMakeModalVisible] = useState(
    false
  )

  const treatmentIds = query.get('treatmentId') as string

  const [isSetting, setIsSetting] = useState(false) // 是否设置过代煎

  const [machiningTypeMsg, setMachiningTypeMsg] = useState('') //加工类别信息

  const [replaceMakeDisable, setReplaceMakeDisable] = useState(true) // 代煎按钮是否禁用

  const [setID, setSetId] = useState(0)

  const [recipes, setReciptList] = useState<any>()

  const [replaceMakeId, setreplaceMakeId] = useState<any>('')

  const [isOpenReplaceMake, setIsOpenReplaceMake] = useState(false) //是否开通代煎

  const [potionType, setpotionType] = useState(0) //上次的代煎方式

  const [isDisableReplaceMake, setisDisableReplaceMake] = useState(false)

  const [isOpenPriceRatio, setIsOpenPriceRatio] = useState(false)

  const [printZXDData, setPrintZXDData] = useState<any>()

  const [tipCount, setTipCount] = useState(7)

  const pageLoading = useSelector(selectPageLoading)

  const getDetail = () => {
    dispatch(getTreatment(treatmentId || ''))
  }

  const setUpdateFlags = (insuranceFlag: string, updateFlag: string) => {
    dispatch(
      setUpdateFlagsAsync({
        treatmentId,
        insuranceFlag,
        updateFlag,
      })
    )
      .then(unwrapResult)
      .then(() => {
        getDetail()
      })
  }

  useEffect(() => {
    // 处于中药或者颗粒剂且有数据的时候才显示代煎相关配置
    setReplaceMakeDisable(materials.length === 0)
  }, [materials])

  useEffect(() => {
    if (isSetting) {
      const replaceMake = materials.find((v: any) => v.billingCategory === '97')
      setreplaceMakeId(replaceMake?.id)
    }
  }, [isSetting, materials])

  useEffect(() => {
    if (!isReplaceMakeModalVisible) {
      if (
        (currentRecipe?.category === 3 || currentRecipe?.category === 12) &&
        materials.length !== 0
      ) {
        const data: replaceMakeInfoParams = {
          decoctionType: currentRecipe?.category === 3 ? 0 : 1,
          tenantRecipeId: currentRecipe?.id,
        }
        dispatch(getReplaceMakeInfo({ ...data }))
          .then(unwrapResult)
          .then((res: any) => {
            setSetId(res.settingId)
            setIsSetting(res.status === 1)
            setMachiningTypeMsg(res.machiningTypeMsg)
            setIsOpenReplaceMake(res.openType === 0)
            setpotionType(res.potionType)
            setIsOpenPriceRatio(res.openPriceRatio == 0)
          })
      } else {
        setIsSetting(false)
      }
    }
  }, [currentRecipe, materials, isReplaceMakeModalVisible])

  const getRecipeListData = () => {
    dispatch(getRecipeList(treatmentId || treatmentIds))
      .then(unwrapResult)
      .then((v: any) => {
        setReciptList(v)
      })
  }

  useEffect(() => {
    if (treatmentId || treatmentIds) {
      getRecipeListData()
      dispatch(getTreatment(treatmentId || treatmentIds))
        .then(unwrapResult)
        .then((treatment) => {
          setTreatment(treatment)
          return treatment.patientId
        })
        .then((patientId) =>
          dispatch(getTreatmentNotice(patientId)).then(unwrapResult)
        )
        .then((notice) => onNotice && onNotice(notice))
        .catch(() => {
          // do nothing.
        })
    }
  }, [treatmentId, treatmentIds])

  useEffect(() => {
    setCurrentRecipe(undefined)
    if (recipes?.length >= 0) {
      setCurrentRecipe(recipes[0])
      if (autoSelect) {
        setSelectedRecipes(recipes.filter(isRecipePayable))
      }
    }
  }, [recipes])


  const getReciptPrintList = () => {
    dispatch(getPrintZXDData(currentRecipe?.id))
      .then(unwrapResult)
      .then((v: any) => {
        setPrintZXDData(v)
      })
  }

  useEffect(() => {
    dispatch(setMaterials([]))
    if (currentRecipe && !isReplaceMakeModalVisible) {
      dispatch(setPageLoading(true))
      setTimeout(() => {
        dispatch(setPageLoading(false))
      }, 5000)
      dispatch(getMaterialList(currentRecipe.id))
    }
  }, [currentRecipe, isReplaceMakeModalVisible])

  useEffect(() => {
    onSelect && onSelect(selectedRecipes)
  }, [selectedRecipes])
  
  useEffect(() => {
    onSelectCurrentRecipe && onSelectCurrentRecipe(currentRecipe)
  }, [currentRecipe])

  const selectedRecipesZY: any = recipes?.filter(
    (v: any) => v?.id == currentRecipe?.id
  )

    const fValue: any =
      (selectedRecipesZY?.[0]?.recipeNormalFrequency
        ? ',' + selectedRecipesZY?.[0]?.recipeNormalFrequency 
        : '') +
      (selectedRecipesZY?.[0]?.recipeNormalUsemethod
        ? ',' + selectedRecipesZY?.[0]?.recipeNormalUsemethod 
        : '') +
      (selectedRecipesZY?.[0]?.recipeNormalDose
        ? ',' + selectedRecipesZY?.[0]?.recipeNormalDose 
        : '') +
      (selectedRecipesZY?.[0]?.remark
        ? ',' + selectedRecipesZY?.[0]?.remark
        : '')

  return (
    <Row wrap={false} style={{ overflow: 'hidden', ...style }}>
      <Col
        style={{
          width: 290,
          height: '100%',
          flexShrink: 0,
          display: 'flex',
          backgroundColor: theme.pn,
          borderRadius: readMode ? `0 0 0 ${theme.br}px` : `${theme.br}px`,
        }}
      >
        <Tabs
          centered
          style={{ width: '100%', height: '100%' }}
          tabBarStyle={{
            borderBottom: `1px solid ${theme.th}`,
            marginBottom: 20,
          }}
          className={styles.tabs}
        >
          <TabPane
            tab={<span style={{ fontSize: '1.25rem' }}>处方信息</span>}
            key='0'
            style={{ height: '100%' }}
          >
            <Col
              style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden',
              }}
            >
              {yidibz && Number(yidibz) > 0 && (
                <div style={{ color: 'gray', marginLeft: '20px' }}>
                  *异地患者不允许分次结算
                </div>
              )}
              <Table<Recipe>
                className={styles.table}
                rowClassName={(r: any) =>
                  // r.id === currentRecipe?.id
                  //   ? styles.selected
                  //   : r.state !== RecipeStatus.ToSettle &&
                  //     r.state !== RecipeStatus.Unknown &&
                  //     r.state !== RecipeStatus.Returned
                  //   ? styles.settled
                  //   : ''
                  r.id === currentRecipe?.id
                    ? r.state === RecipeStatus.Settled
                      ? styles.settledSelected
                      : r.state === RecipeStatus.Returned
                      ? styles.returnedSelected
                      : ''
                    : styles.elseSelected
                }
                columns={[
                  {
                    title: '类型',
                    key: 'category',
                    render: (_, r) => {
                      const isSelected = r.id === currentRecipe?.id
                      const isSettled =
                        r.state === RecipeStatus.Settled ||
                        r.state === RecipeStatus.Returned
                      const isSettledFlag = r.state === RecipeStatus.Settled
                      const isReturnedFlag = r.state === RecipeStatus.Returned
                      setisDisableReplaceMake(isSettled)
                      return {
                        props: {
                          style: {
                            color: isSelected
                              ? isSettledFlag
                                ? theme.s
                                : isReturnedFlag
                                ? '#ccc'
                                : theme.c1
                              : isSettledFlag
                              ? theme.s
                              : isReturnedFlag
                              ? '#ccc'
                              : theme.tc1,
                            // borderLeft: `4px solid ${
                            //   isSelected
                            //     ? isSettledFlag
                            //       ? theme.s
                            //       : isReturnedFlag
                            //       ? '#ccc'
                            //       : theme.c1
                            //     : isSettledFlag
                            //     ? theme.s
                            //     : 'transparent'
                            // }`,
                            fontWeight: isSelected ? 'bold' : 'normal',
                          },
                        },
                        children:
                          getRecipeCategoryName(r.category) +
                          (isSettledFlag
                            ? '（完）'
                            : isReturnedFlag
                            ? '（退）'
                            : ''),
                      }
                    },
                  },
                  {
                    title: '开单信息',
                    key: 'amount',
                    align: 'right',
                    render: function Element(_, r) {
                      const isSelected = r.id === currentRecipe?.id
                      const isSettledFlag = r.state === RecipeStatus.Settled
                      const isReturnedFlag = r.state === RecipeStatus.Returned
                      return (
                        <span
                          style={{
                            color: isSelected
                              ? isSettledFlag
                                ? theme.s
                                : isReturnedFlag
                                ? '#ccc'
                                : theme.c1
                              : isReturnedFlag
                              ? '#ccc'
                              : theme.tc1,
                          }}
                        >
                          {moment(r.recipeTime).format('HH:mm')}
                        </span>
                      )
                    },
                  },
                ]}
                dataSource={recipes}
                pagination={false}
                rowKey={(r) => r.id}
                onRow={(r) => ({
                  onClick: () => setCurrentRecipe(r),
                })}
                rowSelection={{
                  selectedRowKeys: selectedRecipes.map((r) => r.id),
                  onChange: (ks) => {
                    const ids = ks as string[]
                    setSelectedRecipes(
                      recipes.filter((r: any) => ids.find((id) => id === r.id))
                    )
                  },
                  getCheckboxProps: () => ({
                    disabled: yidibz && Number(yidibz) > 0 ? true : false,
                  }),
                }}
                style={{ padding: '0 10px', flex: 1, overflow: 'auto' }}
              />
            </Col>
          </TabPane>
          <TabPane
            tab={<span style={{ fontSize: '1.25rem' }}>病历信息</span>}
            key='1'
          >
            <Col className={styles.history}>
              <Card title='疾病信息'>
                {treatment?.disease?.split(',')?.map((d, i) => (
                  <div key={i}>{d}</div>
                ))}
              </Card>
              <Card title='诊断描述'>
                <div>{treatment?.treatmentConclusion}</div>
              </Card>
              <Card title='现病史'>
                <div>{treatment?.historyOfPresentIllness}</div>
              </Card>
              <Card title='既往史'>
                <div>{treatment?.pastMedicalHistory}</div>
              </Card>
              <Card title='主诉'>
                <div>{treatment?.chiefComplaint}</div>
              </Card>
            </Col>
          </TabPane>
        </Tabs>
      </Col>
      <Col
        flex={1}
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginLeft: readMode ? 10 : 20,
          backgroundColor: theme.pn,
          borderRadius: readMode ? `0 0 ${theme.br}px 0` : `${theme.br}px`,
          overflowY: 'hidden',
        }}
      >
        <Row
          align='middle'
          style={{
            position: hisVersion == 1 && ('fixed' as any),
            height: '3.5rem',
            padding: '0 20px',
            color: theme.tc1,
            fontSize: '1rem',
          }}
        >
          <div>
            所选处方 编号:{' '}
            <span
              style={{
                color: theme.tc1,
                fontWeight: 'bold',
              }}
            >
              {currentRecipe?.id}
            </span>
          </div>
          <div>
            ，类型:{' '}
            <span
              style={{
                color: theme.tc1,
                fontWeight: 'bold',
              }}
            >
              {currentRecipe
                ? getRecipeCategoryName(currentRecipe.category)
                : ''}
            </span>
          </div>
          <div>
            ，开方时间:{' '}
            <span
              style={{
                color: theme.tc1,
                fontWeight: 'bold',
              }}
            >
              {currentRecipe?.recipeTime}
            </span>
          </div>
          {isSetting && (
            <div>
              ，代煎类别:{' '}
              <span
                style={{
                  color:
                    machiningTypeMsg == '委外代煎袋装液体' ? 'red' : theme.tc1,
                  fontWeight: 'bold',
                }}
              >
                {machiningTypeMsg}
              </span>
            </div>
          )}
        </Row>
        <Row
          style={{
            minWidth: '1249px',
            height: '3.125rem',
            padding: '0 10px',
            backgroundColor: theme.th,
            marginLeft: 20,
            position: hisVersion == 1 && ('relative' as any),
            top: hisVersion == 1 && ('56px' as any),
          }}
        >
          <Space
            style={
              {
                // position: hisVersion == 1 ? ('fixed' as any) : '',
                // top: hisVersion == 1 ? ('240px' as any) : '',
              }
            }
          >
            {!readMode && (
              <Button
                danger
                type='primary'
                disabled={selectedMaterialIds.length === 0}
                onClick={() => {
                  if (!currentRecipe || selectedMaterialIds.length === 0) {
                    return
                  }
                  Modal.confirm({
                    title: '提示',
                    content: '确定删除选中的药品？',
                    cancelText: '取消',
                    okText: '确认',
                    onOk: () =>
                      dispatch(removeMaterials(selectedMaterialIds))
                        .then(unwrapResult)
                        .then(() => {
                          if (
                            selectedMaterialIds.includes(replaceMakeId) &&
                            isSetting
                          ) {
                            dispatch(
                              isdeleteReplaceMakeSetting({
                                status: 1,
                                id: setID,
                              })
                            )
                          }
                          if (currentRecipe) {
                            dispatch(getMaterialList(currentRecipe.id))
                          }
                        })
                        .catch(() => {
                          // do nothing.
                        }),
                  })
                }}
              >
                删除选中行
              </Button>
            )}
            <Button
              type='primary'
              onClick={() =>{ 
                if (currentRecipe?.id) {
                  getReciptPrintList()
                }
                setPrintTemplateCategory(PrintTemplateCategory.Zxd)}
              }
            >
              打印执行单
            </Button>
            {currentRecipe?.category !== RecipeCategory.Tcm &&
              currentRecipe?.category !== RecipeCategory.TcmGranules && (
                <Dropdown
                  trigger={['click']}
                  overlay={
                    <Row
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        padding: 15,
                      }}
                    >
                      <Space direction='vertical' size='large'>
                        <Button
                          type='primary'
                          onClick={() =>
                            setPrintTemplateCategory(
                              PrintTemplateCategory.Xbcfd
                            )
                          }
                        >
                          新版处方单(适用打印偏好设置)
                        </Button>
                        <Button
                          type='primary'
                          onClick={() =>
                            setPrintTemplateCategory(PrintTemplateCategory.Xyy)
                          }
                        >
                          有诊疗
                          <span style={{ fontSize: '12px' }}>
                            (每页最多5个药品)
                          </span>
                        </Button>
                        <Button
                          type='primary'
                          onClick={() =>
                            setPrintTemplateCategory(PrintTemplateCategory.Xyw)
                          }
                        >
                          无诊疗
                          <span style={{ fontSize: '12px' }}>
                            (每页最多5个药品)
                          </span>
                        </Button>
                        <Button
                          type='primary'
                          onClick={() =>
                            setPrintTemplateCategory(
                              PrintTemplateCategory.Xycfjwzl
                            )
                          }
                        >
                          有诊疗
                          <span style={{ fontSize: '12px' }}>
                            (每页最多8个药品)
                          </span>
                        </Button>
                        <Button
                          type='primary'
                          onClick={() =>
                            setPrintTemplateCategory(
                              PrintTemplateCategory.Xycfjyzl
                            )
                          }
                        >
                          无诊疗
                          <span style={{ fontSize: '12px' }}>
                            (每页最多8个药品)
                          </span>
                        </Button>
                        <Button
                          type='primary'
                          onClick={() =>
                            setPrintTemplateCategory(
                              PrintTemplateCategory.Ybwpcfd
                            )
                          }
                        >
                          {getTemplateCategoryName(
                            PrintTemplateCategory.Ybwpcfd
                          )}
                        </Button>
                        <Button
                          type='primary'
                          onClick={() =>
                            setPrintTemplateCategory(
                              PrintTemplateCategory.Zfwpcfd
                            )
                          }
                        >
                          {getTemplateCategoryName(
                            PrintTemplateCategory.Zfwpcfd
                          )}
                        </Button>
                      </Space>
                    </Row>
                  }
                >
                  <Button type='primary' className={styles.button}>
                    处方单打印
                  </Button>
                </Dropdown>
              )}
            {currentRecipe?.category === RecipeCategory.Tcm && (
              <Button
                type='primary'
                className={styles.button}
                onClick={() =>
                  setPrintTemplateCategory(PrintTemplateCategory.Zcy)
                }
              >
                处方单打印
              </Button>
            )}
            {currentRecipe?.category === RecipeCategory.TcmGranules && (
              <Button
                type='primary'
                className={styles.button}
                onClick={() =>
                  setPrintTemplateCategory(PrintTemplateCategory.Klj)
                }
              >
                处方单打印
              </Button>
            )}
            {(currentRecipe?.category === RecipeCategory.Tcm ||
              currentRecipe?.category === RecipeCategory.TcmGranules) && (
              <Button
                style={{ border: '1px solid' }}
                type={isSetting ? 'primary' : 'link'}
                className={styles.button}
                onClick={() => {
                  setIsReplaceMakeModalVisible(true)
                }}
                disabled={
                  (currentRecipe?.state == 0 ? false : true) ||
                  replaceMakeDisable ||
                  isDisableReplaceMake
                }
              >
                代煎设置
              </Button>
            )}
          </Space>
        </Row>
        <EditableList<Material>
          pagination={false}
          bordered
          columns={
            currentRecipe?.category === RecipeCategory.Tcm ||
            currentRecipe?.category === RecipeCategory.TcmGranules
              ? TcmColumns(
                  theme,
                  () => {},
                  false,
                  (itemPayType, v) => {
                    dispatch(
                      updateItemPayTypeAsync({
                        itemPayType,
                        recipeItemId: v.id,
                      })
                    )
                      .then(unwrapResult)
                      .then(() => {
                        setUpdateFlags('1', '1')
                        getRecipeListData()
                        dispatch(getMaterialList(currentRecipe?.id || ''))
                      })
                  },
                  readMode
                )
              : currentRecipe?.category === RecipeCategory.Dispose
              ? DisposeColumns(
                  theme,
                  materials,
                  () => {},
                  false,
                  (itemPayType, v) => {
                    dispatch(
                      updateItemPayTypeAsync({
                        itemPayType,
                        recipeItemId: v.id,
                      })
                    )
                      .then(unwrapResult)
                      .then((res) => {
                        getRecipeListData()
                        dispatch(getMaterialList(currentRecipe?.id || ''))
                        setUpdateFlags('1', '1')
                      })
                  },
                  false,
                  (t, v, tooth, toothType) => {},
                  (v) => {},
                  false
                )
              : MedicineMaterialColumns(
                  theme,
                  materials,
                  () => {},
                  false,
                  (itemPayType, v) => {
                    dispatch(
                      updateItemPayTypeAsync({
                        itemPayType,
                        recipeItemId: v.id,
                      })
                    )
                      .then(unwrapResult)
                      .then(() => {
                        getRecipeListData()
                        setUpdateFlags('1', '1')
                        dispatch(getMaterialList(currentRecipe?.id || ''))
                      })
                  },
                  false,
                  (t, v) => {
                    if (t === 'qupi') {
                      const data = {
                        patientId: Number(currentRecipe?.patientId),
                        recipeId: v.recipeId,
                        skinTestInfo: v.skinTestInfo,
                        tenantRecipeItemId: v.id,
                      }
                      dispatch(editST(data))
                        .then(unwrapResult)
                        .then((res: any) => {
                          notification.success({
                            message: '通知成功',
                          })
                        })
                    }
                  },
                  readMode
                )
          }
          dataSource={materials}
          loading={pageLoading}
          rowKey={(m) => m.id}
          rowSelection={
            noCheckBox
              ? (null as any)
              : {
                  selectedRowKeys: selectedMaterialIds,
                  onChange: (ks) => setSelectedMaterialIds(ks as string[]),
                }
          }
          style={{
            flex: 1,
            margin:
              hisVersion == 1 ? '56px 20px 10px 20px' : '0 20px 10px 20px',
            minWidth: 1250,
            overflow: 'auto',
          }}
        />
        {currentRecipe?.category === RecipeCategory.Tcm && (
          <Row
            align='middle'
            className={styles.tip}
            style={{
              color: theme.tc2,
              fontSize: '0.875rem',
              padding: '0 20px 20px 20px',
              position: 'relative',
              top: '10px',
              width: '100vw',
            }}
          >
            总均帖：
            <div
              style={{
                color: theme.tc1,
                fontSize: '1.125rem',
                marginRight: 10,
              }}
            >
              ¥{selectedRecipesZY?.[0]?.totalTipAmount?.toFixed(2)}
            </div>
            自费均帖：
            <div
              style={{
                color: theme.tc1,
                fontSize: '1.125rem',
                marginRight: 10,
              }}
            >
              ¥ {selectedRecipesZY?.[0]?.zifeiTipAmount?.toFixed(2)}
            </div>
            {currentRecipe?.category === RecipeCategory.Tcm &&
              JSON.parse(localStorage.getItem('credentials') as string)
                .insuranceArray.length != 0 && (
                <>
                  医保均帖：
                  <div
                    style={{
                      color: theme.tc1,
                      fontSize: '1.125rem',
                      marginRight: 10,
                    }}
                  >
                    ¥{selectedRecipesZY?.[0]?.insuranceTipAmount?.toFixed(2)}
                  </div>
                </>
              )}
            {isSetting ? (
              <>
                代煎类别：
                <div
                  style={{
                    color:  machiningTypeMsg == '委外代煎袋装液体'
                        ? 'red'
                        : theme.tc1,
                    marginRight: 10,
                  }}
                >
                  {machiningTypeMsg}
                </div>
              </>
            ) : (
              ''
            )}
            中药帖数：
            <Button icon={<MinusOutlined />} disabled />
            {/* <InputNumber
              disabled
              style={{
                width: '3.5rem',
                color: theme.tc2,
                fontSize: '1rem',
                fontWeight: 'bold',
                textAlign: 'center',
              }}
              controls={false}
              value={selectedRecipesZY?.[0]?.tip || tipCount}
              min={1}
              max={9999}
              bordered={false}
            /> */}
            <span style={{ width: '50px', textAlign: 'center' }}>
              {selectedRecipesZY?.[0]?.tip || tipCount}
            </span>
            <Button
              icon={<PlusOutlined />}
              style={{ marginRight: 10 }}
              disabled
            />
            处方用法：
            <Button
              disabled
              style={{ color: theme.tc2, width: '500px', textAlign: 'left' }}
              icon={<SearchOutlined color={theme.c3} />}
            >
              {selectedRecipesZY?.[0]?.useMethod + (fValue ? fValue : '')}
            </Button>
          </Row>
        )}
      </Col>

      <PrintTemplateModal
       printZXDData={printZXDData}
       pagePrintFlag={'zxdPrint'}
        recipeCategory={currentRecipe?.category} //处方类型
        patientId={Number(currentRecipe?.patientId)}
        visible={!!printTemplateCategory}
        templateCategory={printTemplateCategory}
        recipeId={currentRecipe?.id}
        treatmentId={treatmentId || treatmentIds}
        onCancel={() => setPrintTemplateCategory(undefined)}
        onOk={() => setPrintTemplateCategory(undefined)}
      />
      {/* <ReplaceMakeSettingModal
        visible={isReplaceMakeModalVisible}
        isOpenPriceRatio={isOpenPriceRatio}
        recipeId={Number(currentRecipe?.id)} //处方id
        recipeCategory={currentRecipe?.category} //处方类型
        patientId={Number(currentRecipe?.patientId)} // 患者ID
        isSetting={isSetting} // 是否设置过代煎
        potionType={potionType} // 上次代煎的方式
        isOpenReplaceMake={isOpenReplaceMake} // 是否开启代煎
        // isShowReplaceMakeTable={isShowReplaceMakeTable}
        onCancel={() => {
          setIsReplaceMakeModalVisible(false)
        }}
        onOk={() => {
          setIsReplaceMakeModalVisible(false)
        }}
      /> */}

        {/* 代煎设置 */}
        <DecoctingSettingModal
          isSetting={isSetting} // 是否设置过代煎
          visible={isReplaceMakeModalVisible}
          potionType={potionType} // 上次代煎的方式
          patientId={Number(currentRecipe?.patientId)} // 患者ID
          recipeId={Number(currentRecipe?.id)} //处方id
          recipeCategory={currentRecipe?.category} //处方类型
          isOpenReplaceMake={isOpenReplaceMake} // 是否开启代煎
          onCancel={() => {
            setIsReplaceMakeModalVisible(false)
          }}
          onOk={() => {
            setIsReplaceMakeModalVisible(false)
          }}
        ></DecoctingSettingModal>
    </Row>
  )
}
