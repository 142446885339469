/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { CaretDownFilled, CaretRightFilled, EllipsisOutlined, PlusOutlined } from '@ant-design/icons'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Col,
  Collapse,
  Divider,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Space,
  Image
} from 'antd'
import { RcFile, UploadFile } from 'antd/lib/upload/interface'
import _ from 'lodash'
import moment from 'moment'
import React, {
  forwardRef,
  ReactElement,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import {selectShortName, selectTenantName} from '../../../app/applicationSlice'
import { RootDispatch } from '../../../app/store'
import Editor from '../../../compnents/editor/Editor'
import FileUpload from '../../../compnents/file/FileUpload'
import { Label } from '../../../compnents/widgets/Label'
import { DateTimeFormatSimpleOnly } from '../../../models/datetime'
import { patientInfo } from '../../../models/intelligence'
import { PrintTemplateCategory } from '../../../models/print'
import {
  BeautyValue,
  MedicalValue,
  TemplateDetail,
} from '../../../models/template'
import { TreatmentNotice } from '../../../models/treatment'
import { getGenderName } from '../../../models/user'
import { CopyTreatmentParams } from '../../../services/treatment'
import { ThemeContext } from '../../../theme/ThemeContext'
import { useQuery } from '../../../utils/HttpUtils'
import { imagesType } from '../../../utils/StringUtils'
import { PrintFrame, PrintFrameRef } from '../../print/PrintFrame'
import { PrintTemplateModal } from '../../print/PrintTemplateModal'
import { UploadModal } from '../../putstorelist/Query/uploadModal'
import { SaveTemplateModal } from '../../template/SaveTemplateModal'
import { deleteUploadFileList } from '../diagnosis/diagnosisSlice'
import { PreferencesSetModal } from '../diagnosis/preferencesSetModal'
import { Treatments, TreatmentsRef } from '../list/Treatments'
import styles from './beautyClinic.module.css'
import {
  copyTreatment,
  getBeautyClinicSaveAsync,
  getBeaytyClinicDetail,
  getBeaytyClinicList,
  getMedicalDetailAsync,
  getMedicalListAsync,
  getMedicalRemoveAsync,
  getTreatment,
  getTreatments,
  getUrl,
  getVideoSave,
  historicalRecordsCopy,
  selectRegistration,
  selectTreatmentB,
} from './beautyClinicSlice'
import { HistoricalRecords } from './historicalRecords'
import HistoryTreatmentModal from './HistoryTreatmentzModal'

export interface BeautyClinicRef {
  submit: () => Promise<void>
  showTreatments: () => void
}

interface DiagnosisProps {
  tabKey?: string
  readMode?: boolean
  state?: number
  saveEdit?: () => void
  PreferencesSetChange?: () => void
}

const RealBeautyClinic = (
  { readMode = false, saveEdit, PreferencesSetChange }: DiagnosisProps,
  ref: React.Ref<BeautyClinicRef>
): ReactElement => {
  const printRef = useRef<PrintFrameRef>(null)

  const dispatch = useDispatch<RootDispatch>()

  const location: any = useLocation()

  const [xml, setXml] = useState<any>('')

  const [data, setData] = useState<MedicalValue[]>([])

  const theme = useContext(ThemeContext)

  const query = useQuery()

  const treatmentsRef = useRef<TreatmentsRef>(null)

  const registration = useSelector(selectRegistration)

  const treatment = useSelector(selectTreatmentB)

  const [selectedDiseases, setSelectedDiseases] = useState<any>()

  const [isModalVisible, setIsModalVisible] = useState(false)

  const [diagnosisForm] = Form.useForm()

  const [patientForm] = Form.useForm()

  const [treatmentForm] = Form.useForm()

  const [templateKeyword, setTemplateKeyword] = useState('')

  const [templates, setTemplates] = useState<BeautyValue[]>([])

  const EditorRef = useRef<any>(null)

  const [isPrintModalVisible, setIsPrintModalVisible] = useState(false)

  const [isCreating, setIsCreating] = useState(false)

  const [templateDetails, setTemplateDetails] = useState<
    Record<string, BeautyValue[]>
  >({})

  const TenantName = useSelector(selectTenantName)

  const TenantShortName = useSelector(selectShortName)

  const [notice, setNotice] = useState<TreatmentNotice | undefined>()

  const registrationId = query.get('registrationId') as string

  const treatmentId = query.get('treatmentId') as string

  const [patientData, setpatientData] = useState<patientInfo>()

  const [isDiseaseEditable, setIsDiseaseEditable] = useState(true)

  const [isDiseaseEmpty, setIsDiseaseEmpty] = useState(false)

  const [targetTemplate, setTargetTemplate] = useState<
    TemplateDetail | undefined
  >(undefined)

  const [isDoctorEditable, setIsDoctorEditable] = useState(!readMode)

  const [fileList, setFileList] = useState<UploadFile[]>([])

  const [btnLoading, setBtnLoading] = useState(false)  //上传按钮 loading

  const [historicalRecordsBtn, setHistoricalRecordsBtn] = useState(false)  //上传按钮 loading

  const [visible, setVisible] = useState(false)   //预览图片是否显示

  const [imgSrc, setImgSrc] = useState('')   //预览图片路径

  const [printStr, setPrintStr] = useState('')

  const [editorInit, setEditorInit] = useState('')

  const id = query.get('id') as string  //患者管理-病历信息跳转过来的

  const [preferencesSetShow, setiPreferencesSetShow] = useState(false) //偏好设置

  const [isUploadVisible, setIsUploadVisible] = useState(false)   //上传附件弹窗
  const [recipeCollapse, setRecipeCollapse] = useState<any>([])

  useEffect(() => {
    if (printStr) {
      printRef.current?.print()
    }
    return () => {
      setPrintStr('')
    }
  }, [printStr])

  const downloadFile = (url:any, filename:any) =>{
    fetch(url)
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(error => console.error('Download failed:', error));
  }
  const submit = async () => {
    dispatch(
      getBeautyClinicSaveAsync({
        id: String(selectedDiseases?.id),
        name: String(selectedDiseases!.name),
        registrationId: String(registrationId),
        category: '1',
        version: '3',
        medicalBeautyId: String(selectedDiseases!.medicalBeautyId),
        XMl: EditorRef.current?.getXmltoSde(),
        diseaseType: '1',
      })
    )
      .then(unwrapResult)
      .then((res) => {
        setSelectedDiseases(res)
        notification.success({
          message: '保存成功',
        })
        saveEdit && saveEdit()
        getMedical()
      })
  }

  useImperativeHandle(ref, () => ({
    submit: () => submit(),
    showTreatments: () => setIsModalVisible(true),
  }))

  const getMedical = () => {
    dispatch(
      getMedicalListAsync({
        category: 1,
        showImgFlag: 1,
        version: '1',
        registrationId: Number(registrationId),
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        setData(res)
        const tempArr = res.filter((item: any) => item.coherentVideo != '') //限制上传附件数量
        const fileArr = tempArr.map((val: any) => `${val.coherentVideo}@${val.id}`)
        setFileList(fileArr.map((v: any) => {
          return ({
                name: v.split('@')[0],
                uid: v.split('@')[1],
                url: v.split('@')[2],
            id: v.split('@')[3],
          } as unknown) as UploadFile
        }))

      })
  }
  useEffect(() => {

    dispatch(getTreatment({ registrationId, treatmentId, medicalRecord: 4 }))
    // if (!readMode) {
    //   dispatch(startTreatment(registrationId))
    // }
  }, [dispatch, registrationId, treatmentId])

  useEffect(() => {

    getMedical()
    dispatch(getBeaytyClinicList({ category: 1,version:'1' }))
      .then(unwrapResult)
      .then((res: any) => {
        setTemplates(res)
      })
    !id && dispatch(
      getBeaytyClinicDetail({
        id: 10,
        registrationId: Number(registrationId),
      })
    )
      .then(unwrapResult)
      .then((res: string) => {
        EditorRef.current?.importXmltoSde(res)
        setXml(res)
      })
    setSelectedDiseases({
      medicalBeautyId: 10,
      id: '',
      name: '美容外科门诊病历',
    })
    dispatch(
      getTreatments({
        registrationId,
        treatmentId,
      })
    )
      .then(unwrapResult)
      .then((v: any) => {
        setpatientData(v)
      })

    id && dispatch(
      getMedicalDetailAsync({
        id: Number(id)
      })
    )
      .then(unwrapResult)
      .then((res: string) => {
        EditorRef.current?.importXmltoSde(res)
      })
  }, [dispatch, registration, id])

  // useEffect(() => {
  //   if (xml) {
  //     EditorRef.current?.importXmltoSde(xml)
  //     setSelectedDiseases({
  //       medicalBeautyId: 10,
  //       id: "",
  //       name: "美容外科门诊病历",
  //     })
  //   }
  // }, [xml])

  useEffect(() => {
    if (xml && JSON.stringify(patientData?.registration) !== '{}') {
      EditorRef.current?.sdeSetControlByIdSetValue(
        'sde-parameter-institutionName-1',
        TenantShortName
      )
      EditorRef.current?.sdeSetControlByIdSetValue(
        'sde-parameter-currentDate-1',
        moment().format(DateTimeFormatSimpleOnly)
      )
      EditorRef.current?.sdeSetControlByIdSetValue(
        'sde-parameter-department-1',
        patientData?.registration.treatmentDepartmentName
      )
      EditorRef.current?.sdeSetControlByIdSetValue(
        'sde-parameter-title-1',
        selectedDiseases.name + (patientData?.registration?.receiveTag == 0 ? '（初诊）' : "（复诊）")
      )
      EditorRef.current?.sdeSetControlByIdSetValue(
        'sde-parameter-userName-1',
        patientData?.registration.patientName
      )
      EditorRef.current?.sdeSetControlByIdSetValue(
        'sde-parameter-userSex-1',
        getGenderName(patientData?.registration.patientSex as number)
      )
      EditorRef.current?.sdeSetControlByIdSetValue(
        'sde-parameter-userAge-1',
        patientData?.registration.patientAge
      )
      EditorRef.current?.sdeSetControlByIdSetValue(
        'sde-parameter-mrn-1',
        patientData?.registration.patientId
      )
    }
  }, [patientData, xml, selectedDiseases])
  


  return (
    <div
      style={{
        display: 'flex',
        height: 'calc(100% - 5px)',
        position: 'relative',
        flexDirection: 'column',
      }}
    >
      <Row
        className={styles.container}
        style={{
          height: '100%',
          overflow: 'hidden',
          flex: 1,
          marginBottom: '10px',
        }}
        wrap={false}
      >
        {!readMode && (
          <Col
            style={{
              width: 242,
              height: '100%',
              marginRight: 10,
              backgroundColor: theme.pn,
              borderRadius: theme.br,
              overflow: 'auto',
            }}
          >
            <Label label='诊断模板' style={{ marginLeft: 20 }} />
            <Divider />
            <Input
              value={templateKeyword}
              placeholder='请输入内容检索'
              onChange={(e) =>
                setTemplateKeyword((e.target.value || '').trim())
              }
              style={{
                width: 'calc(100% - 40px)',
                marginTop: 20,
                marginLeft: 20,
              }}
              allowClear
            />
            <Collapse
              className={styles.templates}
              bordered={false}
              expandIcon={(v) =>
                v.isActive ? (
                  <CaretDownFilled style={{ lineHeight: '26px' }} />
                ) : (
                  <CaretRightFilled style={{ lineHeight: '26px' }} />
                )
              }
              style={{
                marginTop: 30,
                marginLeft: 20,
                marginRight: 20,
                backgroundColor: 'transparent',
              }}
              onChange={(ids) => {
                _.forEach(ids, (id) => {
                  if (!templateDetails[id]) {
                    const template = templates.find((t) => t.id === Number(id))
                    if (template) {
                      dispatch(
                        getBeaytyClinicList({
                          category: 1,
                          parentId: Number(id),
                          version:'1'
                        })
                      )
                        .then(unwrapResult)
                        .then((details: any) => {
                          setTemplateDetails({
                            ...templateDetails,
                            [id]: details,
                          })
                        })
                    }
                  }
                })
              }}
            >
              {templates.map((t) => (
                <Collapse.Panel
                  key={t.id}
                  header={
                    <div
                      style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {t.name}
                    </div>
                  }
                >
                  {templateDetails[t.id] &&
                    templateDetails[t.id]
                      .filter(
                        (d) =>
                          !templateKeyword ||
                          d.name.indexOf(templateKeyword) >= 0 ||
                          (d.mnemonicCode &&
                            d.mnemonicCode.indexOf(
                              templateKeyword.toUpperCase()
                            ) >= 0)
                      )
                      .map((d, i) => (
                        <div
                          key={d.id}
                          onClick={() => {
                            if (selectedDiseases?.medicalBeautyId !== d.id) {
                              dispatch(
                                getBeaytyClinicDetail({
                                  id: d.id,
                                  registrationId: Number(registrationId),
                                })
                              )
                                .then(unwrapResult)
                                .then((res: string) => {
                                  EditorRef.current?.importXmltoSde(res)
                                  setXml(res)
                                })
                              setSelectedDiseases({
                                ...d,
                                id: '',
                                medicalBeautyId: d.id,
                              })
                            }
                          }}
                          style={{
                            color: theme.tc2,
                            fontSize: '1.125rem',
                            marginTop: 10,
                            cursor: 'pointer',
                          }}
                        >
                          {i + 1}. {d.name}
                        </div>
                      ))}
                </Collapse.Panel>
              ))}
            </Collapse>

            <FileUpload
              count={fileList.length || 0}
              maxCount={20}
              files={fileList}
              disabled={btnLoading}
              btn={(bol) => setBtnLoading(bol)}
              beforeUpload={(file) => {
                setBtnLoading(true)
                if (imagesType.some((v) => file.type.includes(v))) {
                  if (file.size / 1024 / 1024 < 2) {
                    return true
                  } else {
                    notification.info({ message: '图片大于2M,请重新选择!' })
                    setBtnLoading(false)
                    return false
                  }
                }
                setBtnLoading(false)
                notification.info({ message: '请上传图片' })

                return false
              }}
              onUpload={(v, i) => {
                // 上传影像保存
                dispatch(
                  getVideoSave({
                    id: String(selectedDiseases?.id),
                    registrationId: String(registrationId),
                    coherentVideo: `${v.name}@${v.uid}@${i}`,
                  })
                )
                  .then(unwrapResult)
                  .then((res) => {
                    // submit()
                    getMedical()
                    setBtnLoading(false)
                    notification.success({ message: '上传成功' })
                  })
              }}
            >
              {/* <Button style={{ margin: '20px' }} loading={fileList.length >= 20 ? false : btnLoading} icon={<PlusOutlined />}>上传附件(图片)</Button> */}
            </FileUpload>
            <Button
              style={{ margin: '20px' }}
              loading={btnLoading}
              icon={<PlusOutlined />}
              onClick={() => {
                setBtnLoading(true)
                setIsUploadVisible(true)
              }}
            >
              上传附件
            </Button>
          </Col>
        )}
        <Col
          flex='1'
          style={{
            height: '100%',
            overflow: 'hidden',
            borderRadius: readMode ? `0 0 0 ${theme.br}px` : `${theme.br}px`,
            backgroundColor: theme.pn,
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '0 20px',
            }}
          >
            <Label label='疾病诊断' />
            <div
              style={{ fontSize: '20px', cursor: 'pointer' }}
              onClick={() => setiPreferencesSetShow(true)}
            >
              <EllipsisOutlined />
            </div>
          </div>
          <Divider />
          <Editor ref={EditorRef} />
          <Row
            justify='end'
            // style={{ position: 'absolute', right: '20px', bottom: '15px' }}
          >
            <Space>
              <Button
                type='primary'
                onClick={() => {
                  setIsModalVisible(true)
                }}
              >
                查看/选择 历史病历
              </Button>
              <Button
                type='primary'
                onClick={() => {
                  const regArr: any = []
                  EditorRef.current.getControl()?.forEach((v: any) => {
                    const str = v['TYPE_NAME'] == 'text' ? v.getDesc() : ''
                    if (str.indexOf('请输入') > -1) {
                      regArr.push(str)
                    }
                  })
                  const regStr = Array.from(new Set(regArr))
                    .sort()
                    .reverse()
                    .join('|')
                  const reg = new RegExp(regStr, 'g')
                  const str = EditorRef.current
                    ?.getSdeContent()
                    .replace(reg, '')
                  setPrintStr(str)
                  // EditorRef.current.sdePrint()
                }}
              >
                文书打印
              </Button>
              <Button
                type='primary'
                loading={isCreating}
                onClick={() => {
                  submit()
                  // window.print(EditorRef.current?.getSdeContent())
                }}
              >
                保存
              </Button>
            </Space>
          </Row>
          <Modal
            className={styles.history}
            width={1200}
            style={{ height: 720, maxHeight: 720, overflow: 'hidden' }}
            title='历史就诊信息'
            visible={isModalVisible}
            footer={null}
            onCancel={() => setIsModalVisible(false)}
            destroyOnClose
          >
            <HistoricalRecords
              version={'1,3'}
              patientId={patientData?.registration?.patientId}
              historicalRecordsBtn={historicalRecordsBtn}
              onCopy={(oldId,medicalBeautyId,name) => {
                setHistoricalRecordsBtn(false)
                dispatch(
                  getMedicalDetailAsync({
                    id: oldId,
                  })
                )
                  .then(unwrapResult)
                  .then((res: string) => {
                    EditorRef.current?.importXmltoSde(res)
                    setXml(res)
                    setSelectedDiseases({
                      medicalBeautyId: medicalBeautyId,
                      id: '',
                      name: name,
                    })
                    setIsModalVisible(false)
                    setHistoricalRecordsBtn(true)
                  })
              }}
            />
            {/* <Treatments
              medicalRecord={4}
              ref={treatmentsRef}
              style={{ height: '100%' }}
              patientId={registration?.patientId}
              onCopy={(vs) => {
                const params = vs as CopyTreatmentParams
                setIsModalVisible(false)
                if (params.copyRecipe) {
                  Modal.confirm({
                    title: '是否从处方复制价格？',
                    cancelText: '使用目录价格',
                    okText: '使用处方价格',
                    onOk: () => {
                      dispatch(
                        copyTreatment({
                          ...params,
                          registrationId,
                          copyWithAmount: true,
                        })
                      ).then(() => {
                        dispatch(
                          getTreatment({
                            registrationId,
                            treatmentId,
                          })
                        )
                      })
                    },
                    onCancel: () => {
                      dispatch(
                        copyTreatment({
                          ...params,
                          registrationId,
                          copyWithAmount: false,
                        })
                      ).then(() => {
                        dispatch(
                          getTreatment({
                            registrationId,
                            treatmentId,
                          })
                        )
                      })
                    },
                  })
                } else {
                  dispatch(copyTreatment({ ...params, registrationId })).then(
                    () => {
                      dispatch(
                        getTreatment({
                          registrationId,
                          treatmentId,
                        })
                      )
                    }
                  )
                }
              }}
            /> */}
          </Modal>
        </Col>
        <Col
          style={{
            width: 270,
            height: '100%',
            overflow: 'auto',
            marginLeft: readMode ? 10 : 10,
            backgroundColor: theme.pn,
            borderRadius: readMode ? `0 0 ${theme.br}px 0` : `${theme.br}px`,
          }}
        >
          <Label label='诊断记录' style={{ marginLeft: 20 }} />
          <Divider />
          {data.map((v: any) => (
            <>
              <Row
                key={v.id}
                align='middle'
                justify='space-between'
                wrap={false}
                style={{
                  marginTop: 20,
                  marginLeft: 20,
                  marginRight: 20,
                  flex: 1,
                  overflow: 'auto',
                  backgroundColor: 'transparent',
                  fontWeight: 'normal',
                }}
              >
                {/* <Col span={24}> */}
                <a
                  style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    marginRight: 10,
                    width: '145px',
                    color: '#000',
                  }}
                  title={v.name}
                >
                  {v.name}
                </a>
                <Space className={styles.opBar}>
                  <Button
                    ghost
                    type='link'
                    style={{
                      color: '#027AFF',
                      marginRight: '-20px',
                    }}
                    onClick={() => {
                      if (v.category == 3) {
                        const fname = v?.name;
                        const suf = v?.name?.substr(v?.name?.indexOf(".")+1,v?.name?.length);
                        setImgSrc('')
                        v.coherentVideo.split(',').map((v: any) => {
                          dispatch(getUrl({ fileName: v.split('@')[2] })) //获取图片路径
                            .then(unwrapResult)
                            .then((v: any) => {
                              const proxy =
                                window.location.origin.indexOf(
                                  'his.develop.hydhis.com'
                                ) >= 0
                                  ? '/proxyToOssDevPrivate'
                                  : window.location.origin.indexOf(
                                      'his.test.hydhis.com'
                                    ) >= 0
                                  ? '/proxyToOssTestPrivate'
                                  : window.location.origin.indexOf(
                                      'hydhis.cn'
                                    ) >= 0
                                  ? '/proxyToOssProdPrivate'
                                  : '/proxyToOssProdPrivate'
                                  const url = (window.location.origin?.includes('localhost')
                                  ? 'http://his.develop.hydhis.com'
                                  : window.location.origin) + proxy + v
                              if (
                                ![
                                  'png',
                                  'tiff',
                                  'jpg',
                                  'jpeg',
                                  'bmp',
                                  'heic',
                                  'webp',
                                ].some((v) => v == suf)
                              ) {
                                const aliUrl =
                                  document.location.protocol +
                                  v?.replace('http:', '').replace('https:', '')
                                downloadFile(aliUrl, fname)
                              } else {
                                setImgSrc(url)
                                setVisible(true)
                              }
                            })
                        })
                      } else if (v.category == 1) {
                        setSelectedDiseases(v)
                        dispatch(
                          getMedicalDetailAsync({
                            id: v.id,
                          })
                        )
                          .then(unwrapResult)
                          .then((res: string) => {
                            EditorRef.current?.importXmltoSde(res)
                          })
                      }
                    }}
                  >
                    {(v.category == 3 && (!['png', 'tiff', 'jpg', 'jpeg', 'bmp', 'heic', 'webp'].some((s) => s == v?.name?.substr(v?.name?.indexOf(".")+1,v?.name?.length))))?'下载' : '查看'}
                  </Button>
                  <Button
                    ghost
                    type='link'
                    style={{
                      color: '#FF5D5D',
                    }}
                    onClick={() => {
                      Modal.confirm({
                        title: '是否确认删除?',
                        okText: '确认',
                        cancelText: '取消',
                        onOk: () => {
                          dispatch(getMedicalRemoveAsync({ ids: v.id }))
                            .then(unwrapResult)
                            .then(() => {
                              getMedical()
                              notification.success({
                                message: '删除成功',
                              })
                            })
                        },
                      })
                    }}
                  >
                    删除
                  </Button>
                </Space>
              </Row>
              <Row
                key={v}
                align='middle'
                justify='space-between'
                wrap={false}
                style={{
                  marginLeft: 20,
                  marginRight: 20,
                  flex: 1,
                  overflow: 'auto',
                  backgroundColor: 'transparent',
                  fontWeight: 'normal',
                }}
              >
                {/* <Col span={24}> */}
                <span
                  style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    marginRight: 10,
                  }}
                >
                  {v.createTime}
                </span>
              </Row>
            </>
          ))}
        </Col>
        <PrintTemplateModal
          templateCategory={PrintTemplateCategory.Zds}
          visible={isPrintModalVisible && !!treatment?.id}
          treatmentId={treatment?.id}
          onCancel={() => setIsPrintModalVisible(false)}
        />
        <SaveTemplateModal
          categoryName=''
          detail={targetTemplate}
          visible={!!targetTemplate}
          onCancel={() => setTargetTemplate(undefined)}
          onSave={() => setTargetTemplate(undefined)}
        />
      </Row>
      <PrintFrame ref={printRef} direction={'portrait'}>
        {/* <h3>hello</h3> */}
        {printStr && <div dangerouslySetInnerHTML={{ __html: printStr }}></div>}
        {/* {EditorRef.current?.getSdeContent()} */}
      </PrintFrame>
      {/* 上传附件预览 */}
      <Image
        style={{ display: 'none' }}
        src={imgSrc}
        preview={{
          visible,

          onVisibleChange: (value) => {
            setVisible(value)
          },
        }}
      />
      {/* <HistoryTreatmentModal
        isModalVisible={isModalVisible}
        style={{ height: '100%' }}
        patientId={registration?.patientId}
        onCancel={() => {
          setIsModalVisible(false)
        }}
        onConnect={(v) => {
          dispatch(
            getMedicalDetailAsync({
              id: v.id,
            })
          )
            .then(unwrapResult)
            .then((res: string) => {
              EditorRef.current?.importXmltoSde(res)
              setXml(res)
            })
          setSelectedDiseases({
            medicalBeautyId: v.id,
            id: '',
            name: v.name,
          })
          setIsModalVisible(false)
        }}
      /> */}

      <PreferencesSetModal
        tabNum={'4'}
        isModalVisible={preferencesSetShow}
        handleCancel={() => setiPreferencesSetShow(false)}
        onsubmit={() => {
          PreferencesSetChange && PreferencesSetChange()
          setiPreferencesSetShow(false)
        }}
      />

      <UploadModal
        uploadModalVisible={isUploadVisible}
        accept={'.png,.tiff,.jpg,.jpeg,bmp,.heic,.webp'}
        id={registrationId}
        uploadType={2}
        onCancel={() => {
          setBtnLoading(false)
          setIsUploadVisible(false)
        }}
        maxCount={30}
        onSubmit={(v, i, id) => {
          if (fileList.length + v.length > 30) {
            notification.info({
              message: '已达到最大上传数量，请先删除再上传！',
            })
            return
          }
          dispatch(deleteUploadFileList({ ids: id })).then(unwrapResult)
          // 上传影像保存
          const imgList: any = []
          v.forEach((item: any) => {
            imgList.push(
              new Promise((resolve, reject) => {
                // 上传影像保存
                dispatch(
                  getVideoSave({
                    category: 3,
                    id: String(selectedDiseases?.id),
                    registrationId: String(registrationId),
                    coherentVideo: `${item.name}@${item.uid}@${item.url}`,
                  })
                )
                  .then(unwrapResult)
                  .then((res) => {
                    resolve(res)
                  })
                  .catch(() => reject())
              })
            )
          })
          Promise.all(imgList).then(() => {
            getMedical()
            setBtnLoading(false)
            notification.success({ message: '上传成功' })
          })

          setFileList([...fileList, ...v])
          setIsUploadVisible(false)
        }}
      />
    </div>
  )
}

export const BeautyClinic = forwardRef(RealBeautyClinic)
