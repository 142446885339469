/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2022-12-27 16:20:35
 * @LastEditors: linxi
 * @LastEditTime: 2023-11-15 18:52:13
 */
import { Space, Switch, TableColumnType } from 'antd'
import React, { ReactElement, useState } from 'react'
import { CarryOverDetailEntry } from '../../../../models/carryover'
import { Dimen } from '../../../../models/dimen'
import { decrypt } from '../../../../models/m'
import { getGenderName } from '../../../../models/user'
import { getAge } from '../../../../utils/StringUtils'

type ActionType = 'edit' | 'stateEdit'

export const columns = (
  onClick: (action: ActionType, v: any, state?: any) => void,
  startIndex: number,
  datas?: any
): TableColumnType<any>[] => {
  const cols: TableColumnType<CarryOverDetailEntry>[] = [
    {
      title: '聚合支付',
      dataIndex: 'unifiedPaymentAmount',
      align: 'right',
      width: '8rem',
      ellipsis: true,
      render: (_, t: any) => {
        return t.unifiedPaymentAmount
          ? t.unifiedPaymentAmount?.toFixed(2)
          : '0.00'
      },
    },
    {
      title: '支付宝',
      dataIndex: 'alipayPaymentAmount',
      align: 'right',
      width: '8rem',
      ellipsis: true,
      render: (_, t: any) => {
        return t.alipayPaymentAmount
          ? t.alipayPaymentAmount?.toFixed(2)
          : '0.00'
      },
    },
    {
      title: '会员卡',
      dataIndex: 'vipCardPaymentAmount',
      align: 'right',
      width: '8rem',
      ellipsis: true,
      render: (_, t: any) => {
        return t.vipCardPaymentAmount
          ? t.vipCardPaymentAmount?.toFixed(2)
          : '0.00'
      },
    },
    {
      title: '微信',
      dataIndex: 'wechatPaymentAmount',
      align: 'right',
      width: '6rem',
      ellipsis: true,
      render: (_, t: any) => {
        return t.wechatPaymentAmount
          ? t.wechatPaymentAmount?.toFixed(2)
          : '0.00'
      },
    },
    {
      title: '银联',
      key: 'unionPayPaymentAmount',
      align: 'right',
      width: '6rem',
      ellipsis: true,
      render: (_, t: any) => {
        return t.unionPayPaymentAmount
          ? t.unionPayPaymentAmount?.toFixed(2)
          : '0.00'
      },
    },
    {
      title: '现金',
      dataIndex: 'cashPaymentAmount',
      align: 'right',
      width: '6rem',
      render: (_, t: any) => {
        return t.cashPaymentAmount ? t.cashPaymentAmount?.toFixed(2) : '0.00'
      },
    },
    {
      title: '其他',
      dataIndex: 'otherPaymentAmount',
      align: 'right',
      width: '6rem',
      ellipsis: true,
      render: (_, t: any) => {
        return t.otherPaymentAmount ? t.otherPaymentAmount?.toFixed(2) : '0.00'
      },
    },
  ]
  const allCols: TableColumnType<any>[] = [
    {
      title: '病历号',
      dataIndex: 'patientId',
      align: 'center',
      width: '6rem',
      ellipsis: true,
      render: (_, r) => {
        return r.patientId ? r.patientId : '-'
      },
    },

    {
      title: '姓名',
      dataIndex: 'realname',
      align: 'center',
      width: '8rem',
      ellipsis: true,
      render: (_, r) => {
        return r.realname ? r.realname : '-'
      },
    },
    {
      title: '性别',
      dataIndex: 'jobTitle',
      align: 'center',
      width: '4rem',
      ellipsis: true,
      render: (_, r) => {
        return getGenderName(r?.sex)
      },
    },

    {
      title: '年龄',
      dataIndex: 'adeptAt',
      align: 'center',
      width: '6rem',
      ellipsis: true,
      render: (_, r) => {
        return getAge(r?.ageYear, r?.ageMonth)
      },
    },
    {
      title: '手机号',
      dataIndex: 'phone',
      align: 'center',
      width: '8rem',
      ellipsis: true,
      render: (_, r) => {
       return r?.phone ? decrypt(r?.phone) : '-'
      },
    },
    {
      title: '就诊次数',
      dataIndex: 'visitCount',
      align: 'center',
      width: '6rem',
      ellipsis: true,
      render: (_, r) => {
        return r.visitCount ? r.visitCount : '-'
      },
    },
    {
      title: '总费用',
      dataIndex: 'totalPaymentAmount',
      align: 'right',
      width: '6rem',
      ellipsis: true,
      render: (_, r) => {
        return r.totalPaymentAmount ? r.totalPaymentAmount?.toFixed(2) : '0.00'
      },
    },
    {
      title: '优惠金额',
      dataIndex: 'discountAmount',
      align: 'right',
      width: '6rem',
      ellipsis: true,
      render: (_, r) => {
        return r.discountAmount ? r.discountAmount?.toFixed(2) : '0.00'
      },
    },
    {
      title: '自费实收',
      children: cols,
    } as TableColumnType<CarryOverDetailEntry>,
    {
      title: '医保金额',
      dataIndex: 'medicalInsurancePaymentAmount',
      align: 'right',
      width: '6rem',
      ellipsis: true,
      render: (_, t: any) => {
        return t.medicalInsurancePaymentAmount
          ? t.medicalInsurancePaymentAmount?.toFixed(2)
          : '0.00'
      },
    },
    {
      title: '欠费金额',
      dataIndex: 'arrearsPaymentAmount',
      align: 'right',
      width: '6rem',
      ellipsis: true,
      render: (_, t: any) => {
        return t.arrearsPaymentAmount
          ? t.arrearsPaymentAmount?.toFixed(2)
          : '0.00'
      },
    },
  ]
  const customizePayAmountArrayList = datas?.[0]?.customizePayAmountArray
    ?.map((k: any) => {
      return { ...k }
    })
    ?.sort((a: any, b: any) => a.payMethodName.localeCompare(b.payMethodName))
  if (customizePayAmountArrayList?.length > 0) {
    const iv = customizePayAmountArrayList
    for (let i = 0; i < iv.length; i++) {
      cols.push({
        width: '8rem',
        title: iv[i]?.payMethodName,
        dataIndex: 'otherPay_' + i,
        align: 'right',
        key: 'otherPay_' + i,
        render: (_, r: any) => {
          const value = r?.customizePayAmountArray?.filter(
            (vv: any) => vv.payMethodName == iv[i]?.payMethodName
          )[0]?.payAmount
          return Number(value)?.toFixed(2)
        },
      })
    }
  }
  return allCols
}
