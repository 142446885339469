/* eslint-disable react/display-name */
import { Button, TableColumnType, Typography } from 'antd'
import moment from 'moment'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectUserName } from '../../app/applicationSlice'
import { EditableColumnType } from '../../compnents/list/EditableList'
import { getXzName, getQsName } from '../../models/auth'
import { DateTimeFormat, DateTimeFormatSimple } from '../../models/datetime'
const { Text } = Typography

export const RegisterColumns = (
  onClick: (text: string, id: any, item?: any) => void,
  startIndex: number
): TableColumnType<any>[] => {
  const userName = useSelector(selectUserName)
  return [
    {
      title: '序号',
      align: 'center',
      fixed: 'left',
      render: (_1, _2, index) => (
        <span style={{ color: _2.duizhangjg !== '0' ? '#ff4848' : '#000000' }}>
          {startIndex + index + 1}
        </span>
      ),
    },
    {
      title: '财务日期',
      align: 'center',
      render: (_, t) => {
        return (
          <span style={{ color: t.duizhangjg !== '0' ? '#ff4848' : '#000000' }}>
            {t.accountDate ? moment(t.accountDate).format(DateTimeFormatSimple) : ''}
          </span>
        )
      },
    },
    {
      title: '险种类型',
      align: 'center',
      render: (_, t) => {
        return (
          <span style={{ color: t.duizhangjg !== '0' ? '#ff4848' : '#000000' }}>
            {getXzName(t.xianzhonglx)}
          </span>
        )
      },
    },
    // {
    //   title: '人员性质',
    //   align: 'center',
    //   render: (_, t) => {
    //     return (
    //       <span style={{ color: t.duizhangjg !== '0' ? '#ff4848' : '#000000' }}>
    //         {getQsName(t.qingsuanlb)}
    //       </span>
    //     )
    //   },
    // },
    {
      title: '交易笔数',
      align: 'center',
      render: (_, t) => {
        return (
          <span style={{ color: t.duizhangjg !== '0' ? '#ff4848' : '#000000' }}>
            {t.jiesuanbs}
          </span>
        )
      },
    },
    {
      title: '清算方式',
      align: 'center',
      render: (_, t) => {
        return (
          <span style={{ color: t.duizhangjg !== '0' ? '#ff4848' : '#000000' }}>
            {getQsName(t.qingsuanlb)}
          </span>
        )
      },
    },
    {
      title: '费用总额',
      align: 'center',
      render: (_, t) => {
        return (
          <span style={{ color: t.duizhangjg !== '0' ? '#ff4848' : '#000000' }}>
            {t.yiliaofze}
          </span>
        )
      },
    },
    {
      title: '报销金额',
      align: 'center',
      render: (_, t) => {
        return (
          <span style={{ color: t.duizhangjg !== '0' ? '#ff4848' : '#000000' }}>
            {t.jijinzfze}
          </span>
        )
      },
    },
    {
      title: '个人账户',
      align: 'center',
      render: (_, t) => {
        return (
          <span style={{ color: t.duizhangjg !== '0' ? '#ff4848' : '#000000' }}>
            {t.gerenzhzc}
          </span>
        )
      },
    },
    {
      title: '对账结果',
      align: 'center',
      render: (_, t) => {
        return (
          <span style={{ color: t.duizhangjg !== '0' ? '#ff4848' : '#000000' }}>
            {t.duizhangjg == '0' ? '一致' : '不一致'}
          </span>
        )
      },
    },
    // {
    //   title: '操作人员',
    //   align: 'center',
    //   render: (_, t) => {
    //     return (
    //       <span style={{ color: t.duizhangjg !== '0' ? '#ff4848' : '#000000' }}>
    //         {userName}
    //       </span>
    //     )
    //   },
    // },
    {
      title: '操作',
      align: 'center',
      render: (_, t) => {
        return (
          <>
            <Button
              type='link'
              onClick={() => {
                onClick('detail', t.id, t)
              }}
            >
              详情
            </Button>
            <Button
              type='link'
              onClick={() => {
                onClick('download', t.id, t)
              }}
            >
              查看明细
            </Button>
            <Button
              type='link'
              onClick={() => {
                onClick('register', t.id, t)
              }}
            >
              日对帐
            </Button>
          </>
        )
      },
    },
  ]
}

export const RegisterElseColumns = (
  onClick: (text: string, id: any, item?: any) => void,
  startIndex: number
): TableColumnType<any>[] => {
  const userName = useSelector(selectUserName)
  return [
    {
      title: '序号',
      align: 'center',
      fixed: 'left',

      render: (_1, _2, index) => (_2.id === 0?"合计":( <span style={{ color: _2.duizhangjg !== '0' ? '#ff4848' : '#000000' }}>
          {startIndex + index + 1}
        </span>))
    },
    {
      title: '患者姓名',
      align: 'center',
      fixed: 'left',
      render: (_1, _2, index) => (
        <span style={{ color: _2.duizhangjg !== '0' ? '#ff4848' : '#000000' }}>
          {_2.patientName}
        </span>
      ),
    },
    {
      title: '财务日期',
      align: 'center',
      render: (_, t) => {
        return (
          <span style={{ color: t.duizhangjg !== '0' ? '#ff4848' : '#000000' }}>
            {moment(t.accountDate).format(DateTimeFormat)}
          </span>
        )
      },
    },
    {
      title: '险种类型',
      align: 'center',
      render: (_, t) => {
        return (
          <span style={{ color: t.duizhangjg !== '0' ? '#ff4848' : '#000000' }}>
            {getXzName(t.xianzhonglx)}
          </span>
        )
      },
    },
    // {
    //   title: '人员性质',
    //   align: 'center',
    //   render: (_, t) => {
    //     return (
    //       <span style={{ color: t.duizhangjg !== '0' ? '#ff4848' : '#000000' }}>
    //         {getQsName(t.qingsuanlb)}
    //       </span>
    //     )
    //   },
    // },
    {
      title: '参保行政区划',
      align: 'center',
      render: (_, t) => {
        return (
          <span style={{ color: t.duizhangjg !== '0' ? '#ff4848' : '#000000' }}>
            {t.insuranceRegionName}
          </span>
        )
      },
    },
    // {
    //   title: '交易笔数',
    //   align: 'center',
    //   render: (_, t) => {
    //     return (
    //       <span style={{ color: t.duizhangjg !== '0' ? '#ff4848' : '#000000' }}>
    //         {t.jiesuanbs}
    //       </span>
    //     )
    //   },
    // },
    {
      title: '清算方式',
      align: 'center',
      render: (_, t) => {
        return (
          <span style={{ color: t.duizhangjg !== '0' ? '#ff4848' : '#000000' }}>
            {/* {t.qingsuanlb} */}
            {getQsName(t.qingsuanlb)}
          </span>
        )
      },
    },
    {
      title: '费用总额',
      align: 'center',
      render: (_, t) => {
        return (
          <span style={{ color: t.duizhangjg !== '0' ? '#ff4848' : '#000000' }}>
            {t.yiliaofze}
          </span>
        )
      },
    },
    {
      title: '报销金额',
      align: 'center',
      render: (_, t) => {
        return (
          <span style={{ color: t.duizhangjg !== '0' ? '#ff4848' : '#000000' }}>
            {t.jijinzfze}
          </span>
        )
      },
    },
    // {
    //   title: '个人账户',
    //   align: 'center',
    //   render: (_, t) => {
    //     return (
    //       <span style={{ color: t.duizhangjg !== '0' ? '#ff4848' : '#000000' }}>
    //         {t.gerenzhzc}
    //       </span>
    //     )
    //   },
    // },
    {
      title: '对账结果',
      align: 'center',
      render: (_, t) => {
        return (
          <span style={{ color: t.duizhangjg !== '0' ? '#ff4848' : '#000000' }}>
            {t.duizhangjg == '0' ? '一致' : '不一致'}
          </span>
        )
      },
    },
    {
      title: '结算人',
      align: 'center',
      render: (_, t) => {
        return (
          <span style={{ color: t.duizhangjg !== '0' ? '#ff4848' : '#000000' }}>
            {/* {userName} */}
            {t.createName || '-'}
          </span>
        )
      },
    },
    // {
    //   title: '操作',
    //   align: 'center',
    //   render: (_, t) => {
    //     return (
    //       <>
    //         <Button
    //           type='link'
    //           onClick={() => {
    //             onClick('detail', t.id, t)
    //           }}
    //         >
    //           详情
    //         </Button>
    //         <Button
    //           type='link'
    //           onClick={() => {
    //             onClick('download', t.id, t)
    //           }}
    //         >
    //           查看明细
    //         </Button>
    //         <Button
    //           type='link'
    //           onClick={() => {
    //             onClick('register', t.id, t)
    //           }}
    //         >
    //           日对帐
    //         </Button>
    //       </>
    //     )
    //   },
    // },
  ]
}

export const RegisterDetailColumns = (
  onClick: (text: string, id: any, item?: any) => void,
  isLocal: string
): EditableColumnType<any>[] => {
  return [
    {
      title: '系统结算记录',
      align: 'center',
      children: [
        {
          title: '结算ID',
          align: 'center',
          dataIndex: 'setlId',
          render: (_: any, t: any) => {
            return t.compResults !== '1'
              ? t.setlId !== 'null'
                ? t.setlId
                : ''
              : ''
          },
        },
        {
          title: '交易时间',
          align: 'center',
          render: (_: any, t: any) => {
            return t.compResults !== '1'
              ? t.accountDate
                ? moment(t.accountDate).format(DateTimeFormat)
                : ''
              : ''
          },
        },
        {
          title: '结算类型',
          align: 'center',
          dataIndex: 'refdSetlFlag',
          render: (_: any, t: any) => {
            return t.compResults !== '1'
              ? t.refdSetlFlag == '2207A'
                ? '结算'
                : '退费'
              : ''
          },
        },
        {
          title: '费用总额',
          align: 'center',
          dataIndex: 'localMedfeeSumamt',
          render: (_: any, t: any) => {
            return t.compResults !== '1' ? t.localMedfeeSumamt : ''
          },
        },
        {
          title: isLocal == '0' ? '基金支付' : '报销',
          align: 'center',
          dataIndex: 'localFundPaySumamt',
          render: (_: any, t: any) => {
            if (isLocal == '0') {
              return t.compResults !== '1' ? t.localFundPaySumamt : ''
            } else {
              return t.compResults !== '1' ? t.localOptinsPaySumamt : ''
            }
          },
        },
        {
          title: isLocal == '0' ? '个人账户' : '个人现金支付',
          align: 'center',
          dataIndex: 'localAcctPay',
          render: (_: any, t: any) => {
            if (isLocal == '0') {
              return t.compResults !== '1' ? t.localAcctPay : ''
            } else {
              return t.compResults !== '1' ? t.localPsnCashpay : ''
            }
          },
        },
        // {
        //   title: '现金支付',
        //   align: 'center',
        //   dataIndex: 'localPsnCashpay',
        //   render: (_: any, t: any) => {
        //     return t.compResults !== '1' ? t.localPsnCashpay : ''
        //   },
        // },
        {
          title: '姓名',
          align: 'center',
          dataIndex: 'psnName',
          render: (_: any, t: any) => {
            return t.compResults !== '1' ? t.psnName : ''
          },
        },
      ],
    } as TableColumnType<any>,
    {
      title: '对账结果',
      align: 'center',
      render: (_, t) => {

        if (isLocal === '0') {
          switch (t.compResults) {
            case '0' : {
              return  <span style={{ color: '#15ad31' }}>一致</span>
              break
            }
            default : return  <span style={{ color: '#ff4848' }}>不一致</span>
          }
        }

        switch (t.compResults) {
          case '0' : {
            return  <span style={{ color: '#ff4848' }}>未对账</span>
            break
          }
          case '2' : {
            return  <span style={{ color: '#15ad31' }}>已对账</span>
            break
          }
          default : return  <span style={{ color: '#ff4848' }}>不一致</span>
        }
      },
    },
    {
      title: '医保结算记录',
      align: 'center',
      children: [
        {
          title: '结算ID',
          align: 'center',
          dataIndex: 'setlId',
          render: (_: any, t: any) => {
            return t.compResults !== '2'
              ? t.setlId !== 'null'
                ? t.setlId
                : ''
              : ''
          },
        },
        {
          title: '交易时间',
          align: 'center',
          dataIndex: '',
          render: (_: any, t: any) => {
            return t.compResults !== '2'
              ? t.accountDate
                ? moment(t.accountDate).format(DateTimeFormat)
                : ''
              : ''
          },
        },
        {
          title: '结算类型',
          align: 'center',
          dataIndex: '',
          render: (_: any, t: any) => {
            return t.compResults !== '2'
              ? t.refdSetlFlag == '2207A'
                ? '结算'
                : '退费'
              : ''
          },
        },
        {
          title: '费用总额',
          align: 'center',
          dataIndex: 'medfeeSumamt',
          render: (_: any, t: any) => {
            return t.compResults !== '2' ? t.medfeeSumamt : ''
          },
        },
        {
          title: isLocal == '0' ? '基金支付' : '报销',
          align: 'center',
          dataIndex: 'fundPaySumamt',
          render: (_: any, t: any) => {
            if (isLocal == '0') {
              return t.compResults !== '2' ? t.fundPaySumamt : ''
            } else {
              return t.compResults !== '2' ? t.optinsPaySumamt : ''
            }
          },
        },
        {
          title: isLocal == '0' ? '个人账户' : '个人现金支付',
          align: 'center',
          dataIndex: 'acctPay',
          render: (_: any, t: any) => {
            if (isLocal == '0') {
              return t.compResults !== '2' ? t.acctPay : ''
            } else {
              return t.compResults !== '2' ? t.psnCashpay : ''
            }
          },
        },
        // {
        //   title: '现金支付',
        //   align: 'center',
        //   dataIndex: 'psnCashpay',
        //   render: (_: any, t: any) => {
        //     return t.compResults !== '2' ? t.psnCashpay : ''
        //   },
        // },
        {
          title: '姓名',
          align: 'center',
          dataIndex: 'psnName',
          render: (_: any, t: any) => {
            return t.compResults !== '2' ? t.psnName : ''
          },
        },
        {
          title: '操作',
          align: 'center',
          dataIndex: '',
          render: (_: any, t: any) => {
            if (t.compResults === '1') {
              return (
                <Button
                  type='link'
                  onClick={() => {
                    onClick('medicalTarge', t.id, t)
                  }}
                >
                  医保冲正
                </Button>
              )
            // } else if (t.compResults === '2') {
            //   return (
            //     <Button
            //       type='link'
            //       onClick={() => {
            //         onClick('localTarge', t.id, t)
            //       }}
            //     >
            //       本地冲正
            //     </Button>
            //   )
            } else if (t.compResults === '3') {
              return (
                <Button
                  type='link'
                  onClick={() => {
                    onClick('localCheck', t.id, t)
                  }}
                >
                  本地更正
                </Button>
              )
            } else if (t.compResults === '0') {
              return (
                <Button
                  type='link'
                  onClick={() => {
                    onClick('checkAccount', t.id, t)
                  }}
                >
                  对账
                </Button>
              )
            } else {
              return ''
            }
          },
        },
      ],
    } as TableColumnType<any>,
  ]
}
