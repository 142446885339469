import {
  CaretDownOutlined,
  ExclamationCircleOutlined,
  QuestionCircleTwoTone,
} from '@ant-design/icons'
import { Button, Col, Dropdown, Menu, Popover, Row, Space } from 'antd'
import _ from 'lodash'
import moment from 'moment'
import React, { ReactElement } from 'react'
import { EditableColumnType } from '../../../compnents/list/EditableList'
import { BillingCategoryName } from '../../../models/billingCategory'
import {
  getOrderCategoryName,
  getOtcCategoryName,
  Material,
} from '../../../models/material'
import { SkinTestStatus } from '../../../models/treatment'
import { ThemeData } from '../../../theme/ThemeContext'
import styles from './RecipeEditor.module.css'
import { ToothComponents } from './modals/toothComponents'

const getPayTypeColor = (payType: number) => {
  switch (payType) {
    case 0:
      return '#af623c'
    case 1:
      return '#088f52'
    case 2:
      return '#000000'
    case 3:
      return '#2070d0'
    default:
      return ''
  }

}

export const MedicineSFMaterialColumns = (
  theme: ThemeData,
  materials: Material[],
  onSave?: (material: Material, values: Record<string, unknown>) => void,
  allowedToChangePrice = false,
  onItemChange?: (itemPayType: number, recipeItem: any) => void,
  readMode?: boolean,
  onAction?: (v: string, t: any) => void,
  isShowVisibale?: boolean,
  openEditVisible?: (v: any) => void
): EditableColumnType<Material>[] => {
  const groups = _.chain(materials)
    .groupBy((m) => m.groupNumber)
    .mapValues((ms) => ms.map((m) => m.id))
    .value()

  const menu = (v: any) => (
    <Menu
      onClick={(item) => {
        onItemChange && onItemChange(Number(item.key), v)
      }}
    >
      <Menu.Item style={{ fontWeight: 'bold', color: '#af623c' }} key='0'>
        自费
      </Menu.Item>
      {v.hilistCode && v.insuranceCode !== 'ZIFEI' && (
        <>
          <Menu.Item key='1' style={{ fontWeight: 'bold', color: '#088f52' }}>
            医保
          </Menu.Item>
          <Menu.Item key='3' style={{ fontWeight: 'bold', color: '#027aff' }}>
            历年
          </Menu.Item>
        </>
      )}

      {/* <Menu.Item key='2'>免费</Menu.Item> */}
    </Menu>
  )
  const medicalMenu = (v: any) => {
    return (
      <Menu>
        <Menu.Item key='0'>超出医保最高价格：{v?.limitPrice}元</Menu.Item>
      </Menu>
    )
  }

  const popoverContent = (v: Material) => (
    <div>
      <Button
        style={{ color: '#000' }}
        onClick={() => {
          onAction && onAction('qupi', v)
        }}
      >
        {v.skinTestState == 4
          ? '重新通知皮试'
          : v.skinTestState == 1
          ? '通知皮试'
          : ''}
      </Button>
    </div>
  )

  const getPayTypeName = (payType: number) => {
    switch (payType) {
      case 0:
        return '自费'
      case 1:
        return '医保'
      case 2:
        return '免费'
      case 3:
        return '历年'
      default:
        return ''
    }
  }

  return [
    {
      title: '收费类型',
      key: 'orderCategory',
      align: 'center',
      width: '6rem',
      render: function Element(_, m) {
        // if (m.state === -1) {
        return (
          <Dropdown overlay={menu(m)} trigger={['click']}>
            <Button
              type='link'
              style={{
                color: getPayTypeColor(m.itemPayType),
                fontWeight: 'bold',
              }}
            >
              {getPayTypeName(m.itemPayType)}
              {getPayTypeName(m.itemPayType) ? <CaretDownOutlined /> : ''}
            </Button>
          </Dropdown>
        )
        // } else {
        //   return (
        //     <Button type='link' style={{ color: '#000' }}>
        //       {getPayTypeName(m.itemPayType)}
        //     </Button>
        //   )
        // }
      },
    },
    {
      title: '组号',
      width: '3rem',
      dataIndex: 'groupNumber',
      align: 'center',
      render: (text, m) => {
        const obj = {
          children: text,
          props: {
            rowSpan: 1,
          },
        }
        const members = groups[m.groupNumber]
        if (members && members.indexOf(m.id) === 0) {
          obj.props.rowSpan = members.length
        } else if (members) {
          obj.props.rowSpan = 0
        }
        return obj
      },
    },
    {
      title: '药品信息',
      key: 'name',
      align: 'left',
      width: '10rem',
      render: function MaterialName(_, m: Material) {
        let indicator = 'transparent'
        const expiredInDays = m.deadline
          ? moment.duration(moment(m.deadline).diff(moment())).asDays()
          : 9999
        if (
          m.inventoryInfo ||
          m.unShelveInfo ||
          expiredInDays <= 90 ||
          (m.skinTestTag === SkinTestStatus.Positive && m.skinTestInfo)
        ) {
          indicator = theme.e
        } else if (
          m.skinTestTag === SkinTestStatus.NeedATest &&
          m.skinTestInfo
        ) {
          indicator = theme.w
        } else if (
          (m.skinTestTag === SkinTestStatus.Continue ||
            m.skinTestTag === SkinTestStatus.Negative) &&
          m.skinTestInfo
        ) {
          indicator = theme.s
        }
        return {
          props: {
            style: {
              maxWidth: 300,
              borderLeft: `4px solid ${indicator}`,
              overflow: 'hidden',
            },
          },
          children: (
            <Space
              style={{ display: 'flex', justifyContent: 'space-between' }}
              onDoubleClick={() => {
                openEditVisible && openEditVisible(m)
              }}
            >
              {/* <Popover onVisibleChange={(v) => {
                
                onAction && onAction('change', v)
              }} defaultVisible visible={isShowVisibale} trigger="hover" content={popoverContent(m)}> */}
              <Col>
                <div
                  style={{
                    color: m.limitType === 1 ? '#ff5d5d' : theme.tc1,
                    fontSize: '0.875rem',
                    lineHeight: '0.875rem',
                    fontWeight: 'bold',
                    marginTop: 10,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    cursor: 'pointer',
                  }}
                >
                  {(m.memo === '□' ||
                    m.memo === '◇' ||
                    m.memo === '△' ||
                    m.memo === '注：单味可能不予支付') && (
                    <Popover
                      content={'一般用于标识单品不予支付、相同适应症归类等含义'}
                      placement='bottom'
                      title={
                        '医保目录提醒符号：' +
                        (m.memo === '注：单味可能不予支付' ? '□' : m.memo)
                      }
                    >
                      <span style={{ color: 'red' }}>
                        {m.memo === '注：单味可能不予支付' ? '□' : m.memo}{' '}
                      </span>
                    </Popover>
                  )}
                  <span
                    style={{
                      color:
                        m?.state == 5
                          ? '#ccc'
                          : m?.state == 3
                          ? theme.s
                          : theme.tc1,
                    }}
                  >
                    {m.name}
                  </span>
                  {indicator !== 'transparent' && (
                    <span
                      style={{
                        color: indicator,
                        fontSize: '0.875rem',
                        fontWeight: 'normal',
                      }}
                    >
                      ({m.inventoryInfo}
                      {expiredInDays <= 90 &&
                        `有效期：${Math.floor(expiredInDays)}天`}
                      {m.unShelveInfo}
                      {m.skinTestInfo})
                      <span
                        style={{
                          display:
                            m.skinTestState == 4
                              ? 'inline-block'
                              : m.skinTestState == 1
                              ? 'inline-block'
                              : 'none',
                          color: m.alreadyNoticed > 0 ? '#fff' : '#000',
                          marginLeft: '10px',
                          fontSize: '13px',
                          backgroundColor:
                            m.alreadyNoticed > 0 ? '#ccc' : '#fff',
                          border: '1px solid #ccc',
                          padding: '5px',
                          cursor:
                            m.alreadyNoticed > 0 ? 'not-allowed' : 'pointer',
                        }}
                        onClick={() => {
                          if (m.alreadyNoticed > 0) {
                            return
                          } else {
                            onAction && onAction('qupi', m)
                          }
                        }}
                      >
                        {m.alreadyNoticed > 0
                          ? '已通知皮试'
                          : m.skinTestState == 4
                          ? '重新通知皮试'
                          : m.skinTestState == 1
                          ? '通知皮试'
                          : ''}
                      </span>
                    </span>
                  )}
                </div>
                <Popover
                  overlayClassName={styles.popverContent}
                  content={getSmallTextByType(m, 0)}
                  placement='bottom'
                  title={m.name}
                >
                  <div
                    style={{
                      color: m.limitType === 1 ? '#ff5d5d' : theme.tc3,
                      fontSize: '0.75rem',
                      lineHeight: '0.75rem',
                      marginBottom: 10,
                      marginTop: 4,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {getSmallTextByType(m, 0)}
                  </div>
                </Popover>
              </Col>
              {m.limitType === 1 && (
                <Dropdown overlay={medicalMenu(m)} placement='bottomLeft'>
                  <ExclamationCircleOutlined style={{ color: '#ff5d5d' }} />
                </Dropdown>
              )}
              {/* </Popover> */}
            </Space>
          ),
        }
      },
    },
    {
      title: '数量',
      dataIndex: 'drugCount',
      align: 'center',
      width: '3rem',
      editable: !!onSave && !readMode,
      nextDataIndex: 'normalDose',
      onSave: onSave,
      colSpan: 2,
    },
    {
      dataIndex: 'accountUnit',
      align: 'center',
      width: '3rem',
      colSpan: 0,
      editable: !!onSave && !readMode,
      onSave: onSave,
      inputType: 'accountUnit',
      render: (_, m) => (m.splitTag ? m.splitUnit : m.accountUnit),
    },
    {
      title: '频次',
      dataIndex: 'normalFrequency',
      align: 'center',
      width: '5.25rem',
      editable: !!onSave && !readMode,
      onSave: onSave,
      inputType: 'normalFrequency',
      // ellipsis: true,
      render: (_, m) => (m.normalFrequency <= '0' ? '' : m.normalFrequency),
    },
    {
      title: '用法',
      dataIndex: 'normalUsemethod',
      align: 'center',
      width: '5.25rem',
      editable: !!onSave && !readMode,
      onSave: onSave,
      inputType: 'normalUsemethod',
      render: (_, m) => (m.normalUsemethod <= '0' ? '' : m.normalUsemethod),
    },
    {
      title: '每次用量',
      dataIndex: 'normalDose',
      align: 'center',
      width: '3.125rem',
      editable: !!onSave && !readMode,
      nextDataIndex: 'useDays',
      onSave: onSave,
      colSpan: 2,
      render: (_, m) => (m.normalDose <= 0 ? '' : m.normalDose.toString()),
    },
    {
      dataIndex: 'doseUnit',
      align: 'center',
      width: '3.125rem',
      colSpan: 0,
      editable: !!onSave && !readMode,
      onSave: onSave,
      inputType: 'doseUnit',
    },
    {
      title: '天数',
      dataIndex: 'useDays',
      align: 'center',
      editable: !!onSave && !readMode,
      nextDataIndex: 'retailPrice',
      onSave: onSave,
      width: '4rem',
      render: (_, m) => (m.useDays <= '0' ? '' : m.useDays),
    },
    {
      title: '单价',
      dataIndex: 'retailPrice',
      align: 'right',
      width: '7rem',
      // ellipsis: true,
      editable: !!onSave && allowedToChangePrice && !readMode,
      onSave: onSave,

      render: (_, m) => m.retailPrice?.toFixed(5),
    },
    {
      title: '金额',
      key: 'drugAmount',
      align: 'right',
      width: '6rem',
      // ellipsis: true,
      render: (_, r) => r.drugAmount?.toFixed(2),
    },
    {
      title: '甲/乙',
      key: 'orderCategory',
      align: 'center',
      width: '4.25rem',
      render: (_, m) => getOrderCategoryName(m.orderCategory),
    },
    {
      align: 'center',
      title: '备注',
      dataIndex: 'remark',
      key: 'remark',
      width: '6.625rem',
      ellipsis: true,
      editable: !!onSave && !readMode,
      onSave: onSave,
      render: (_, r: any) => r?.otcRemark,
      // render: (_, m) => {
      //   let vs
      //   if (m.remark) {
      //     vs = [getOtcCategoryName(m.otc) || '暂无', m.remark]
      //   } else {
      //     vs = [getOtcCategoryName(m.otc)] || '暂无'
      //   }
      //   return vs.join('，')
      // },
    },
  ]
}
// 医嘱处方-西药方、检查、治疗、其他表头
export const MedicineMaterialColumns = (
  theme: ThemeData,
  materials: Material[],
  onSave?: (material: Material, values: Record<string, unknown>) => void,
  allowedToChangePrice = false,
  onItemChange?: (itemPayType: number, recipeItem: any) => void,
  readMode?: boolean,
  onAction?: (v: string, t: any) => void,
  isShowVisibale?: boolean,
  openEditVisible?: (v: any) => void
): EditableColumnType<Material>[] => {
  const groups = _.chain(materials)
    .groupBy((m) => m.groupNumber)
    .mapValues((ms) => ms.map((m) => m.id))
    .value()

  const menu = (v: any) => (
    <Menu
      onClick={(item) => {
        onItemChange && onItemChange(Number(item.key), v)
      }}
    >
      <Menu.Item style={{ fontWeight: 'bold', color: '#af623c' }} key='0'>
        自费
      </Menu.Item>
      {v.hilistCode && v.insuranceCode !== 'ZIFEI' && (
        <>
          <Menu.Item key='1' style={{ fontWeight: 'bold', color: '#088f52' }}>
            医保
          </Menu.Item>
          <Menu.Item key='3' style={{ fontWeight: 'bold', color: '#027aff' }}>
            历年
          </Menu.Item>
        </>
      )}

      {/* <Menu.Item key='2'>免费</Menu.Item> */}
    </Menu>
  )
  const medicalMenu = (v: any) => {
    return (
      <Menu>
        <Menu.Item key='0'>超出医保最高价格：{v?.limitPrice}元</Menu.Item>
      </Menu>
    )
  }

  const popoverContent = (v: Material) => (
    <div>
      <Button
        style={{ color: '#000' }}
        onClick={() => {
          onAction && onAction('qupi', v)
        }}
      >
        {v.skinTestState == 4
          ? '重新通知皮试'
          : v.skinTestState == 1
          ? '通知皮试'
          : ''}
      </Button>
    </div>
  )

  const getPayTypeName = (payType: number) => {
    switch (payType) {
      case 0:
        return '自费'
      case 1:
        return '医保'
      case 2:
        return '免费'
      case 3:
        return '历年'
      default:
        return ''
    }
  }

  return [
    {
      title: '收费类型',
      key: 'orderCategory',
      align: 'center',
      width: '6rem',
      render: function Element(_, m) {
        // if (m.state === -1) {
        return (
          <Dropdown overlay={menu(m)} trigger={['click']}>
            <Button
              type='link'
              style={{
                color: getPayTypeColor(m.itemPayType),
                fontWeight: 'bold',
              }}
            >
              {getPayTypeName(m.itemPayType)}
              {getPayTypeName(m.itemPayType) ? <CaretDownOutlined /> : ''}
            </Button>
          </Dropdown>
        )
        // } else {
        //   return (
        //     <Button type='link' style={{ color: '#000' }}>
        //       {getPayTypeName(m.itemPayType)}
        //     </Button>
        //   )
        // }
      },
    },
    {
      title: '组号',
      width: '3rem',
      dataIndex: 'groupNumber',
      align: 'center',
      render: (text, m) => {
        const obj = {
          children: text,
          props: {
            rowSpan: 1,
          },
        }
        const members = groups[m.groupNumber]
        if (members && members.indexOf(m.id) === 0) {
          obj.props.rowSpan = members.length
        } else if (members) {
          obj.props.rowSpan = 0
        }
        return obj
      },
    },
    {
      title: '药品信息',
      key: 'name',
      align: 'left',
      // width: '20rem',
      render: function MaterialName(_, m: Material) {
        let indicator = 'transparent'
        const expiredInDays = m.deadline
          ? moment.duration(moment(m.deadline).diff(moment())).asDays()
          : 9999
        if (
          m.inventoryInfo ||
          m.unShelveInfo ||
          expiredInDays <= 90 ||
          (m.skinTestTag === SkinTestStatus.Positive && m.skinTestInfo)
        ) {
          indicator = theme.e
        } else if (
          m.skinTestTag === SkinTestStatus.NeedATest &&
          m.skinTestInfo
        ) {
          indicator = theme.w
        } else if (
          (m.skinTestTag === SkinTestStatus.Continue ||
            m.skinTestTag === SkinTestStatus.Negative) &&
          m.skinTestInfo
        ) {
          indicator = theme.s
        }
        return {
          props: {
            style: {
              maxWidth: 300,
              borderLeft: `4px solid ${indicator}`,
              overflow: 'hidden',
            },
          },
          children: (
            <Space
              style={{ display: 'flex', justifyContent: 'space-between' }}
              onDoubleClick={() => {
                openEditVisible && openEditVisible(m)
              }}
            >
              {/* <Popover onVisibleChange={(v) => {
                
                onAction && onAction('change', v)
              }} defaultVisible visible={isShowVisibale} trigger="hover" content={popoverContent(m)}> */}
              <Col>
                <div
                  style={{
                    color: m.limitType === 1 ? '#ff5d5d' : theme.tc1,
                    fontSize: '0.875rem',
                    lineHeight: '0.875rem',
                    fontWeight: 'bold',
                    marginTop: 10,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    cursor: 'pointer',
                  }}
                >
                  {(m.memo === '□' ||
                    m.memo === '◇' ||
                    m.memo === '△' ||
                    m.memo === '注：单味可能不予支付') && (
                    <Popover
                      content={'一般用于标识单品不予支付、相同适应症归类等含义'}
                      placement='bottom'
                      title={
                        '医保目录提醒符号：' +
                        (m.memo === '注：单味可能不予支付' ? '□' : m.memo)
                      }
                    >
                      <span style={{ color: 'red' }}>
                        {m.memo === '注：单味可能不予支付' ? '□' : m.memo}{' '}
                      </span>
                    </Popover>
                  )}
                  <span
                    style={{
                      color:
                        m?.state == 5
                          ? '#ccc'
                          : m?.state == 3
                          ? theme.s
                          : theme.tc1,
                    }}
                  >
                    {m.name}
                  </span>
                  {indicator !== 'transparent' && (
                    <span
                      style={{
                        color: indicator,
                        fontSize: '0.875rem',
                        fontWeight: 'normal',
                      }}
                    >
                      ({m.inventoryInfo}
                      {expiredInDays <= 90 &&
                        `有效期：${Math.floor(expiredInDays)}天`}
                      {m.unShelveInfo}
                      {m.skinTestInfo})
                      <span
                        style={{
                          display:
                            m.skinTestState == 4
                              ? 'inline-block'
                              : m.skinTestState == 1
                              ? 'inline-block'
                              : 'none',
                          color: m.alreadyNoticed > 0 ? '#fff' : '#000',
                          marginLeft: '10px',
                          fontSize: '13px',
                          backgroundColor:
                            m.alreadyNoticed > 0 ? '#ccc' : '#fff',
                          border: '1px solid #ccc',
                          padding: '5px',
                          cursor:
                            m.alreadyNoticed > 0 ? 'not-allowed' : 'pointer',
                        }}
                        onClick={() => {
                          if (m.alreadyNoticed > 0) {
                            return
                          } else {
                            onAction && onAction('qupi', m)
                          }
                        }}
                      >
                        {m.alreadyNoticed > 0
                          ? '已通知皮试'
                          : m.skinTestState == 4
                          ? '重新通知皮试'
                          : m.skinTestState == 1
                          ? '通知皮试'
                          : ''}
                      </span>
                    </span>
                  )}
                </div>
                <Popover
                  overlayClassName={styles.popverContent}
                  content={getSmallTextByType(m, 0)}
                  placement='bottom'
                  title={m.name}
                >
                  <div
                    style={{
                      color: m.limitType === 1 ? '#ff5d5d' : theme.tc3,
                      fontSize: '0.75rem',
                      lineHeight: '0.75rem',
                      marginBottom: 10,
                      marginTop: 4,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {getSmallTextByType(m, 0)}
                  </div>
                </Popover>
              </Col>
              {m.limitType === 1 && (
                <Dropdown overlay={medicalMenu(m)} placement='bottomLeft'>
                  <ExclamationCircleOutlined style={{ color: '#ff5d5d' }} />
                </Dropdown>
              )}
              {/* </Popover> */}
            </Space>
          ),
        }
      },
    },
    {
      title: '数量',
      dataIndex: 'drugCount',
      align: 'center',
      width: '3rem',
      editable: !!onSave && !readMode,
      nextDataIndex: 'normalDose',
      onSave: onSave,
      colSpan: 2,
    },
    {
      dataIndex: 'accountUnit',
      align: 'center',
      width: '3rem',
      colSpan: 0,
      editable: !!onSave && !readMode,
      onSave: onSave,
      inputType: 'accountUnit',
      render: (_, m) => (m.splitTag ? m.splitUnit : m.accountUnit),
    },
    {
      title: '频次',
      dataIndex: 'normalFrequency',
      align: 'center',
      width: '5.25rem',
      editable: !!onSave && !readMode,
      onSave: onSave,
      inputType: 'normalFrequency',
      // ellipsis: true,
      render: (_, m) => (m.normalFrequency <= '0' ? '' : m.normalFrequency),
    },
    {
      title: '用法',
      dataIndex: 'normalUsemethod',
      align: 'center',
      width: '5.25rem',
      editable: !!onSave && !readMode,
      onSave: onSave,
      inputType: 'normalUsemethod',
      render: (_, m) => (m.normalUsemethod <= '0' ? '' : m.normalUsemethod),
    },
    {
      title: '每次用量',
      dataIndex: 'normalDose',
      align: 'center',
      width: '3.125rem',
      editable: !!onSave && !readMode,
      nextDataIndex: 'useDays',
      onSave: onSave,
      colSpan: 2,
      render: (_, m) => (m.normalDose <= 0 ? '' : m.normalDose.toString()),
    },
    {
      dataIndex: 'doseUnit',
      align: 'center',
      width: '3.125rem',
      colSpan: 0,
      editable: !!onSave && !readMode,
      onSave: onSave,
      inputType: 'doseUnit',
    },
    {
      title: '天数',
      dataIndex: 'useDays',
      align: 'center',
      editable: !!onSave && !readMode,
      nextDataIndex: 'retailPrice',
      onSave: onSave,
      width: '4rem',
      render: (_, m) => (m.useDays <= '0' ? '' : m.useDays),
    },
    {
      title: '单价',
      dataIndex: 'retailPrice',
      align: 'right',
      width: '7rem',
      // ellipsis: true,
      editable: !!onSave && allowedToChangePrice && !readMode,
      onSave: onSave,

      render: (_, m) => m.retailPrice?.toFixed(5),
    },
    {
      title: '金额',
      key: 'drugAmount',
      align: 'right',
      width: '6rem',
      // ellipsis: true,
      render: (_, r) => r.drugAmount?.toFixed(2),
    },
    {
      title: '甲/乙',
      key: 'orderCategory',
      align: 'center',
      width: '4.25rem',
      render: (_, m) => getOrderCategoryName(m.orderCategory),
    },
    {
      align: 'center',
      title: '备注',
      dataIndex: 'remark',
      key: 'remark',
      width: '6.625rem',
      ellipsis: true,
      editable: !!onSave && !readMode,
      onSave: onSave,
      render: (_, r: any) => r?.otcRemark,
      // render: (_, m) => {
      //   let vs
      //   if (m.remark) {
      //     vs = [getOtcCategoryName(m.otc) || '暂无', m.remark]
      //   } else {
      //     vs = [getOtcCategoryName(m.otc)] || '暂无'
      //   }
      //   return vs.join('，')
      // },
    },
  ]
}

// 医嘱处方-西药方、检查、治疗、其他表头
export const MedicineMaterialDiscountColumns = (
  theme: ThemeData,
  materials: Material[],
  onSave?: (material: Material, values: Record<string, unknown>) => void,
  allowedToChangePrice = false,
  onItemChange?: (itemPayType: number, recipeItem: any) => void,
  readMode?: boolean,
  onAction?: (v: string, t: any) => void,
  isShowVisibale?: boolean,
  openEditVisible?: (v: any) => void
): EditableColumnType<Material>[] => {
  const groups = _.chain(materials)
    .groupBy((m) => m.groupNumber)
    .mapValues((ms) => ms.map((m) => m.id))
    .value()

  const menu = (v: any) => (
    <Menu
      onClick={(item) => {
        onItemChange && onItemChange(Number(item.key), v)
      }}
    >
      <Menu.Item style={{ fontWeight: 'bold', color: '#af623c' }} key='0'>
        自费
      </Menu.Item>
      {v.hilistCode && v.insuranceCode !== 'ZIFEI' && (
        <>
          <Menu.Item key='1' style={{ fontWeight: 'bold', color: '#088f52' }}>
            医保
          </Menu.Item>
          <Menu.Item key='3' style={{ fontWeight: 'bold', color: '#027aff' }}>
            历年
          </Menu.Item>
        </>
      )}

      {/* <Menu.Item key='2'>免费</Menu.Item> */}
    </Menu>
  )
  const medicalMenu = (v: any) => {
    return (
      <Menu>
        <Menu.Item key='0'>超出医保最高价格：{v?.limitPrice}元</Menu.Item>
      </Menu>
    )
  }

  const popoverContent = (v: Material) => (
    <div>
      <Button
        style={{ color: '#000' }}
        onClick={() => {
          onAction && onAction('qupi', v)
        }}
      >
        {v.skinTestState == 4
          ? '重新通知皮试'
          : v.skinTestState == 1
          ? '通知皮试'
          : ''}
      </Button>
    </div>
  )

  const getPayTypeName = (payType: number) => {
    switch (payType) {
      case 0:
        return '自费'
      case 1:
        return '医保'
      case 2:
        return '免费'
      case 3:
        return '历年'
      default:
        return ''
    }
  }

  return [
    {
      title: '收费类型',
      key: 'orderCategory',
      align: 'center',
      width: '5rem',
      render: function Element(_, m) {
        // if (m.state === -1) {
        return (
          <Dropdown overlay={menu(m)} trigger={['click']}>
            <Button
              type='link'
              style={{
                color: getPayTypeColor(m.itemPayType),
                fontWeight: 'bold',
              }}
            >
              {getPayTypeName(m.itemPayType)}
              {getPayTypeName(m.itemPayType) ? <CaretDownOutlined /> : ''}
            </Button>
          </Dropdown>
        )
        // } else {
        //   return (
        //     <Button type='link' style={{ color: '#000' }}>
        //       {getPayTypeName(m.itemPayType)}
        //     </Button>
        //   )
        // }
      },
    },
    {
      title: '组号',
      width: '3rem',
      dataIndex: 'groupNumber',
      align: 'center',
      render: (text, m) => {
        const obj = {
          children: text,
          props: {
            rowSpan: 1,
          },
        }
        const members = groups[m.groupNumber]
        if (members && members.indexOf(m.id) === 0) {
          obj.props.rowSpan = members.length
        } else if (members) {
          obj.props.rowSpan = 0
        }
        return obj
      },
    },
    {
      title: '药品信息',
      key: 'name',
      align: 'left',
      width: '16rem',
      render: function MaterialName(_, m: Material) {
        let indicator = 'transparent'
        const expiredInDays = m.deadline
          ? moment.duration(moment(m.deadline).diff(moment())).asDays()
          : 9999
        if (
          m.inventoryInfo ||
          m.unShelveInfo ||
          expiredInDays <= 90 ||
          (m.skinTestTag === SkinTestStatus.Positive && m.skinTestInfo)
        ) {
          indicator = theme.e
        } else if (
          m.skinTestTag === SkinTestStatus.NeedATest &&
          m.skinTestInfo
        ) {
          indicator = theme.w
        } else if (
          (m.skinTestTag === SkinTestStatus.Continue ||
            m.skinTestTag === SkinTestStatus.Negative) &&
          m.skinTestInfo
        ) {
          indicator = theme.s
        }
        return {
          props: {
            style: {
              maxWidth: 300,
              borderLeft: `4px solid ${indicator}`,
              overflow: 'hidden',
            },
          },
          children: (
            <Space
              style={{ display: 'flex', justifyContent: 'space-between' }}
              onDoubleClick={() => {
                openEditVisible && openEditVisible(m)
              }}
            >
              {/* <Popover onVisibleChange={(v) => {
                
                onAction && onAction('change', v)
              }} defaultVisible visible={isShowVisibale} trigger="hover" content={popoverContent(m)}> */}
              <Col>
                <div
                  style={{
                    color: m.limitType === 1 ? '#ff5d5d' : theme.tc1,
                    fontSize: '0.875rem',
                    lineHeight: '0.875rem',
                    fontWeight: 'bold',
                    marginTop: 10,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    cursor: 'pointer',
                  }}
                >
                  {(m.memo === '□' ||
                    m.memo === '◇' ||
                    m.memo === '△' ||
                    m.memo === '注：单味可能不予支付') && (
                    <Popover
                      content={'一般用于标识单品不予支付、相同适应症归类等含义'}
                      placement='bottom'
                      title={
                        '医保目录提醒符号：' +
                        (m.memo === '注：单味可能不予支付' ? '□' : m.memo)
                      }
                    >
                      <span style={{ color: 'red' }}>
                        {m.memo === '注：单味可能不予支付' ? '□' : m.memo}{' '}
                      </span>
                    </Popover>
                  )}
                  <span
                    style={{
                      color:
                        m?.state == 5
                          ? '#ccc'
                          : m?.state == 3
                          ? theme.s
                          : theme.tc1,
                    }}
                  >
                    {m.couponsItemName || m.name}
                  </span>
                  {indicator !== 'transparent' && (
                    <span
                      style={{
                        color: indicator,
                        fontSize: '0.875rem',
                        fontWeight: 'normal',
                      }}
                    >
                      ({m.inventoryInfo}
                      {expiredInDays <= 90 &&
                        `有效期：${Math.floor(expiredInDays)}天`}
                      {m.unShelveInfo}
                      {m.skinTestInfo})
                      <span
                        style={{
                          display:
                            m.skinTestState == 4
                              ? 'inline-block'
                              : m.skinTestState == 1
                              ? 'inline-block'
                              : 'none',
                          color: m.alreadyNoticed > 0 ? '#fff' : '#000',
                          marginLeft: '10px',
                          fontSize: '13px',
                          backgroundColor:
                            m.alreadyNoticed > 0 ? '#ccc' : '#fff',
                          border: '1px solid #ccc',
                          padding: '5px',
                          cursor:
                            m.alreadyNoticed > 0 ? 'not-allowed' : 'pointer',
                        }}
                        onClick={() => {
                          if (m.alreadyNoticed > 0) {
                            return
                          } else {
                            onAction && onAction('qupi', m)
                          }
                        }}
                      >
                        {m.alreadyNoticed > 0
                          ? '已通知皮试'
                          : m.skinTestState == 4
                          ? '重新通知皮试'
                          : m.skinTestState == 1
                          ? '通知皮试'
                          : ''}
                      </span>
                    </span>
                  )}
                </div>
                <Popover
                  overlayClassName={styles.popverContent}
                  content={getSmallTextByType(m, 0)}
                  placement='bottom'
                  title={m.couponsItemName || m.name}
                >
                  <div
                    style={{
                      color: m.limitType === 1 ? '#ff5d5d' : theme.tc3,
                      fontSize: '0.75rem',
                      lineHeight: '0.75rem',
                      marginBottom: 10,
                      marginTop: 4,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {getSmallTextByType(m, 0)}
                  </div>
                </Popover>
              </Col>
              {m.limitType === 1 && (
                <Dropdown overlay={medicalMenu(m)} placement='bottomLeft'>
                  <ExclamationCircleOutlined style={{ color: '#ff5d5d' }} />
                </Dropdown>
              )}
              {/* </Popover> */}
            </Space>
          ),
        }
      },
    },
    {
      title: '折后单价',
      dataIndex: 'retailPrice',
      align: 'right',
      width: '5rem',
      ellipsis: false,
      onSave: onSave,
      render: (_, m) => m.retailPrice?.toFixed(5),
    },
    {
      title: '折前单价',
      dataIndex: '',
      align: 'right',
      width: '5rem',
      ellipsis: false,
      render: (_, m) =>
        m.retailPrice && m?.discount
          ? (Number(m.retailPrice) / Number(m.discount))?.toFixed(5)
          : '-',
    },
    {
      title: '折扣%',
      dataIndex: 'discount',
      align: 'center',
      width: '4rem',
      render: (_, m) =>
        m?.discount ? (Number(m?.discount) * 100)?.toFixed(0) : '-',
    },
    {
      title: '单价',
      key: 'drugAmount',
      align: 'right',
      width: '6rem',
      // ellipsis: true,
      render: (_, r) => r.drugAmount?.toFixed(2),
    },
    {
      title: '数量',
      dataIndex: 'drugCount',
      align: 'center',
      width: '3rem',
      editable: !!onSave && !readMode,
      nextDataIndex: 'normalDose',
      onSave: onSave,
      colSpan: 2,
    },
    {
      dataIndex: 'accountUnit',
      align: 'center',
      width: '3rem',
      colSpan: 0,
      editable: !!onSave && !readMode,
      onSave: onSave,
      inputType: 'accountUnit',
      render: (_, m) => (m.splitTag ? m.splitUnit : m.accountUnit),
    },
    {
      title: '频次',
      dataIndex: 'normalFrequency',
      align: 'center',
      width: '5.25rem',
      editable: !!onSave && !readMode,
      onSave: onSave,
      inputType: 'normalFrequency',
      // ellipsis: true,
      render: (_, m) => (m.normalFrequency <= '0' ? '' : m.normalFrequency),
    },
    {
      title: '用法',
      dataIndex: 'normalUsemethod',
      align: 'center',
      width: '5.25rem',
      editable: !!onSave && !readMode,
      onSave: onSave,
      inputType: 'normalUsemethod',
      render: (_, m) => (m.normalUsemethod <= '0' ? '' : m.normalUsemethod),
    },
    {
      title: '每次用量',
      dataIndex: 'normalDose',
      align: 'center',
      width: '3.125rem',
      editable: !!onSave && !readMode,
      nextDataIndex: 'useDays',
      onSave: onSave,
      colSpan: 2,
      render: (_, m) => (m.normalDose <= 0 ? '' : m.normalDose.toString()),
    },
    {
      dataIndex: 'doseUnit',
      align: 'center',
      width: '3.125rem',
      colSpan: 0,
      editable: !!onSave && !readMode,
      onSave: onSave,
      inputType: 'doseUnit',
    },
    {
      title: '天数',
      dataIndex: 'useDays',
      align: 'center',
      editable: !!onSave && !readMode,
      nextDataIndex: 'retailPrice',
      onSave: onSave,
      width: '4rem',
      render: (_, m) => (m.useDays <= '0' ? '' : m.useDays),
    },
    {
      title: '甲/乙',
      key: 'orderCategory',
      align: 'center',
      width: '3rem',
      render: (_, m) => getOrderCategoryName(m.orderCategory),
    },
    {
      align: 'center',
      title: '备注',
      dataIndex: 'remark',
      key: 'remark',
      width: '6.625rem',
      ellipsis: true,
      editable: !!onSave && !readMode,
      onSave: onSave,
      render: (_, r: any) => r?.otcRemark,
      // render: (_, m) => {
      //   let vs
      //   if (m.remark) {
      //     vs = [getOtcCategoryName(m.otc) || '暂无', m.remark]
      //   } else {
      //     vs = [getOtcCategoryName(m.otc)] || '暂无'
      //   }
      //   return vs.join('，')
      // },
    },
  ]
}

//获取药品/诊疗项目名字下方的小字文本，
//type 0：西药或其他，1：中草药（或配方颗粒），2：处置项目
const getSmallTextByType = (m: any, type: number) => {
  const batchNo = location.pathname == '/payment/cart' && m?.batchNo  ? ('/' +'批号:' + m?.batchNo) :''
  if (type === 0) {
    return (
      (m.drugType === 1
        ? `诊疗/${m.hilistCode}`
        : `${m.spec}/${m.packSpec}/${m.accountUnit}/${m.doseCategory}/${m.tenantSupplierName}/${m.hilistCode}${batchNo}`) +
      (m.limitPrice > 0 && m.limitPrice < 99999
        ? '/医保限价：' + `${m.limitPrice}`
        : '')
    )
  } else if (type === 1) {
    return (
      (m.billingCategory !== '97'
        ? `${m.spec}/${m.packSpec}/${m.accountUnit}/${m.doseCategory}/${m.tenantSupplierName}/${m.hilistCode}${batchNo}`
        : `${m.spec}/${m.packSpec}/${m.accountUnit}/${m.hilistCode}${batchNo}`) +
      (m.limitPrice > 0 && m.limitPrice < 99999
        ? '/医保限价：' + `${m.limitPrice}`
        : '')
    )
  } else if (type === 2) {
    return (
      (m.drugType === 1
        ? `诊疗/${m.hilistCode}`
        : `${m.spec}/${m.packSpec}/${m.accountUnit}/${m.doseCategory}/${m.tenantSupplierName}/${m.hilistCode}${batchNo}`) +
      (m.limitPrice > 0 && m.limitPrice < 99999
        ? '/医保限价：' + `${m.limitPrice}`
        : '')
    )
  } else {
    return ''
  }
}

// 医嘱处方-中药方表头
export const TcmColumns = (
  theme: ThemeData,
  onSave?: (material: Material, values: Record<string, unknown>) => void,
  allowedToChangePrice = false,
  onItemChange?: (itemPayType: number, recipeItem: any) => void,
  recipeCategory?: any,
  openEditVisible?: (v: any) => void,
  readMode?: boolean,
  insuranceArray?: any
): EditableColumnType<Material>[] => {
  const menu = (v: any) => (
    <Menu
      onClick={(item) => {
        onItemChange && onItemChange(Number(item.key), v)
      }}
    >
      <Menu.Item style={{ fontWeight: 'bold', color: '#af623c' }} key='0'>
        自费
      </Menu.Item>
      {v.hilistCode && v.insuranceCode !== 'ZIFEI' && (
        <>
          <Menu.Item key='1' style={{ fontWeight: 'bold', color: '#088f52' }}>
            医保
          </Menu.Item>
          <Menu.Item key='3' style={{ fontWeight: 'bold', color: '#027aff' }}>
            历年
          </Menu.Item>
        </>
      )}

      {/* <Menu.Item key='2'>免费</Menu.Item> */}
    </Menu>
  )
  const medicalMenu = (v: any) => {
    return (
      <Menu>
        <Menu.Item key='0'>
          {v.quarterType == 1 && insuranceArray?.[0]?.chinese25pcAlert == 1
            ? '中草药单价不能超过成本25%,'
            : ''}{' '}
          {v.limitType == 1 ? `超出医保最高价格：${v?.limitPrice}元` : ''}
        </Menu.Item>
        {/* <Menu.Item key='0'>超出医保最高价格：{v?.limitPrice}元</Menu.Item> */}
      </Menu>
    )
  }

  const getPayTypeName = (payType: number) => {
    switch (payType) {
      case 0:
        return '自费'
      case 1:
        return '医保'
      case 2:
        return '免费'
      case 3:
        return '历年'
      default:
        return ''
    }
  }

  return [
    {
      title: '收费类型',
      key: 'orderCategory',
      align: 'center',
      width: '5rem',
      render: function Element(_, m) {
        // if (m.state === -1) {
        return (
          <Dropdown overlay={menu(m)} trigger={['click']}>
            <Button
              className={styles.sfType}
              type='link'
              style={{
                color: getPayTypeColor(m.itemPayType),
                fontWeight: 'bold',
              }}
            >
              {getPayTypeName(m.itemPayType)}
              {getPayTypeName(m.itemPayType) ? <CaretDownOutlined /> : ''}
            </Button>
          </Dropdown>
        )
        // } else {
        //   return (
        //     <Button type='link' style={{ color: '#000' }}>
        //       {getPayTypeName(m.itemPayType)}
        //     </Button>
        //   )
        // }
      },
    },
    {
      title: '序号',
      key: 'order',
      align: 'center',
      width: '3rem',
      render: (_1, _2, index) => (index + 1).toString(),
    },
    {
      title: '药品信息',
      key: 'name',
      align: 'left',
      // width: '20rem',
      ellipsis: true,
      render: function MaterialName(_, m: Material) {
        let indicator = 'transparent'
        const expiredInDays = m.deadline
          ? moment.duration(moment(m.deadline).diff(moment())).asDays()
          : 9999
        if (m.inventoryInfo || m.unShelveInfo || expiredInDays <= 90) {
          indicator = theme.e
        }
        return {
          props: {
            style: {
              borderLeft: `4px solid ${indicator}`,
            },
          },
          children: (
            <Space
              style={{ display: 'flex', justifyContent: 'space-between' }}
              onDoubleClick={() => {
                openEditVisible && openEditVisible(m)
              }}
            >
              <Row align='bottom'>
                <div
                  style={{
                    color:
                      m.quarterType == 1 &&
                      insuranceArray?.[0]?.chinese25pcAlert == 1
                        ? '#ff5d5d'
                        : theme.tc1,
                    fontSize: '0.875rem',
                    lineHeight: '0.875rem',
                    fontWeight: 'bold',
                    cursor: 'pointer',
                  }}
                >
                  {(m.memo === '□' ||
                    m.memo === '◇' ||
                    m.memo === '△' ||
                    m.memo === '注：单味可能不予支付') && (
                    <Popover
                      content={'一般用于标识单品不予支付、相同适应症归类等含义'}
                      placement='bottom'
                      title={
                        '医保目录提醒符号：' +
                        (m.memo === '注：单味可能不予支付' ? '□' : m.memo)
                      }
                    >
                      <span style={{ color: 'red' }}>
                        {m.memo === '注：单味可能不予支付' ? '□' : m.memo}{' '}
                      </span>
                    </Popover>
                  )}
                  <span
                    style={{
                      color:
                        m?.state == 5
                          ? '#ccc'
                          : m?.state == 3
                          ? theme.s
                          : theme.tc1,
                    }}
                  >
                    {m.name}
                  </span>
                  {indicator !== 'transparent' && (
                    <span style={{ color: indicator }}>
                      ({m.inventoryInfo}
                      {expiredInDays <= 90 &&
                        `有效期：${Math.floor(expiredInDays)}天`}
                      {m.unShelveInfo})
                    </span>
                  )}
                </div>

                <Popover
                  overlayClassName={styles.popverContent}
                  content={getSmallTextByType(m, 1)}
                  placement='bottom'
                  title={m.name}
                >
                  <div
                    style={{
                      color:
                        m.quarterType == 1 &&
                        insuranceArray?.[0]?.chinese25pcAlert == 1
                          ? '#ff5d5d'
                          : theme.tc3,
                      fontSize: '0.75rem',
                      lineHeight: '0.75rem',
                      marginLeft: 4,
                    }}
                  >
                    {getSmallTextByType(m, 1)}
                  </div>
                </Popover>
              </Row>
              {((m.quarterType == 1 &&
                insuranceArray?.[0]?.chinese25pcAlert == 1) ||
                m.limitType == 1) && (
                <Dropdown overlay={medicalMenu(m)} placement='bottomLeft'>
                  <ExclamationCircleOutlined style={{ color: '#ff5d5d' }} />
                </Dropdown>
              )}
            </Space>
          ),
        }
      },
    },
    {
      title: '每帖数量',
      dataIndex: 'countPerTip',
      nextDataIndex: 'retailPrice',
      align: 'center',
      width: '6rem',
      ellipsis: true,
      editable: !!onSave && !readMode,
      onSave: onSave,
    },
    {
      title: '总数量',
      dataIndex: 'drugCount',
      align: 'center',
      width: '6rem',
      ellipsis: true,
    },
    {
      title: '用法',
      dataIndex: 'normalUsemethod',
      align: 'center',
      width: '6rem',
      // ellipsis: true,
      editable: !!onSave && !readMode,
      onSave: onSave,
      inputType: 'normalUsemethod',
      recipeCategory: recipeCategory,
    },
    {
      title: '单价',
      dataIndex: 'retailPrice',
      align: 'right',
      width: '5rem',
      ellipsis: true,
      editable: !!onSave && allowedToChangePrice && !readMode,
      onSave: onSave,
      render: (_, r) => r.retailPrice?.toFixed(5),
    },
    {
      title: '金额',
      key: 'drugAmount',
      align: 'right',
      width: '5rem',
      ellipsis: true,
      render: (_, r) => r.drugAmount?.toFixed(2),
    },
  ]
}

export const TcmDiscountColumns = (
  theme: ThemeData,
  onSave?: (material: Material, values: Record<string, unknown>) => void,
  allowedToChangePrice = false,
  onItemChange?: (itemPayType: number, recipeItem: any) => void,
  recipeCategory?: any,
  openEditVisible?: (v: any) => void,
  readMode?: boolean,
  insuranceArray?: any
): EditableColumnType<Material>[] => {
  const menu = (v: any) => (
    <Menu
      onClick={(item) => {
        onItemChange && onItemChange(Number(item.key), v)
      }}
    >
      <Menu.Item style={{ fontWeight: 'bold', color: '#af623c' }} key='0'>
        自费
      </Menu.Item>
      {v.hilistCode && v.insuranceCode !== 'ZIFEI' && (
        <>
          <Menu.Item key='1' style={{ fontWeight: 'bold', color: '#088f52' }}>
            医保
          </Menu.Item>
          <Menu.Item key='3' style={{ fontWeight: 'bold', color: '#027aff' }}>
            历年
          </Menu.Item>
        </>
      )}

      {/* <Menu.Item key='2'>免费</Menu.Item> */}
    </Menu>
  )
  const medicalMenu = (v: any) => {
    return (
      <Menu>
        <Menu.Item key='0'>
          {v.quarterType == 1 && insuranceArray?.[0]?.chinese25pcAlert == 1
            ? '中草药单价不能超过成本25%,'
            : ''}
          {v.limitType == 1 ? `超出医保最高价格：${v?.limitPrice}元` : ''}
        </Menu.Item>
        {/* <Menu.Item key='0'>超出医保最高价格：{v?.limitPrice}元</Menu.Item> */}
      </Menu>
    )
  }

  const getPayTypeName = (payType: number) => {
    switch (payType) {
      case 0:
        return '自费'
      case 1:
        return '医保'
      case 2:
        return '免费'
      case 3:
        return '历年'
      default:
        return ''
    }
  }

  return [
    {
      title: '收费类型',
      key: 'orderCategory',
      align: 'center',
      width: '4.5rem',
      render: function Element(_, m) {
        // if (m.state === -1) {
        return (
          <Dropdown overlay={menu(m)} trigger={['click']}>
            <Button
              type='link'
              style={{
                color: getPayTypeColor(m.itemPayType),
                fontWeight: 'bold',
              }}
            >
              {getPayTypeName(m.itemPayType)}
              {getPayTypeName(m.itemPayType) ? <CaretDownOutlined /> : ''}
            </Button>
          </Dropdown>
        )
        // } else {
        //   return (
        //     <Button type='link' style={{ color: '#000' }}>
        //       {getPayTypeName(m.itemPayType)}
        //     </Button>
        //   )
        // }
      },
    },
    {
      title: '序号',
      key: 'order',
      align: 'center',
      width: '3rem',
      render: (_1, _2, index) => (index + 1).toString(),
    },
    {
      title: '药品信息',
      key: 'name',
      align: 'left',
      width: '16rem',
      ellipsis: true,
      render: function MaterialName(_, m: Material) {
        let indicator = 'transparent'
        const expiredInDays = m.deadline
          ? moment.duration(moment(m.deadline).diff(moment())).asDays()
          : 9999
        if (m.inventoryInfo || m.unShelveInfo || expiredInDays <= 90) {
          indicator = theme.e
        }
        return {
          props: {
            style: {
              borderLeft: `4px solid ${indicator}`,
            },
          },
          children: (
            <Space
              style={{ display: 'flex', justifyContent: 'space-between' }}
              onDoubleClick={() => {
                openEditVisible && openEditVisible(m)
              }}
            >
              <Row align='bottom'>
                <div
                  style={{
                    color:
                      m.quarterType == 1 &&
                      insuranceArray?.[0]?.chinese25pcAlert == 1
                        ? '#ff5d5d'
                        : theme.tc1,
                    fontSize: '0.875rem',
                    lineHeight: '0.875rem',
                    fontWeight: 'bold',
                    cursor: 'pointer',
                  }}
                >
                  {(m.memo === '□' ||
                    m.memo === '◇' ||
                    m.memo === '△' ||
                    m.memo === '注：单味可能不予支付') && (
                    <Popover
                      content={'一般用于标识单品不予支付、相同适应症归类等含义'}
                      placement='bottom'
                      title={
                        '医保目录提醒符号：' +
                        (m.memo === '注：单味可能不予支付' ? '□' : m.memo)
                      }
                    >
                      <span style={{ color: 'red' }}>
                        {m.memo === '注：单味可能不予支付' ? '□' : m.memo}{' '}
                      </span>
                    </Popover>
                  )}

                  <span
                    style={{
                      color:
                        m?.state == 5
                          ? '#ccc'
                          : m?.state == 3
                          ? theme.s
                          : theme.tc1,
                    }}
                  >
                    {m.name}
                  </span>
                  {indicator !== 'transparent' && (
                    <span style={{ color: indicator }}>
                      ({m.inventoryInfo}
                      {expiredInDays <= 90 &&
                        `有效期：${Math.floor(expiredInDays)}天`}
                      {m.unShelveInfo})
                    </span>
                  )}
                </div>

                <Popover
                  overlayClassName={styles.popverContent}
                  content={getSmallTextByType(m, 1)}
                  placement='bottom'
                  title={m.name}
                >
                  <div
                    style={{
                      color:
                        m.quarterType == 1 &&
                        insuranceArray?.[0]?.chinese25pcAlert == 1
                          ? '#ff5d5d'
                          : theme.tc3,
                      fontSize: '0.75rem',
                      lineHeight: '0.75rem',
                      marginLeft: 4,
                    }}
                  >
                    {getSmallTextByType(m, 1)}
                  </div>
                </Popover>
              </Row>
              {((m.quarterType == 1 &&
                insuranceArray?.[0]?.chinese25pcAlert == 1) ||
                m.limitType == 1) && (
                <Dropdown overlay={medicalMenu(m)} placement='bottomLeft'>
                  <ExclamationCircleOutlined style={{ color: '#ff5d5d' }} />
                </Dropdown>
              )}
            </Space>
          ),
        }
      },
    },
    {
      title: '折后单价',
      dataIndex: 'retailPrice',
      align: 'right',
      width: '5rem',
      ellipsis: true,
      onSave: onSave,
      render: (_, m) => m.retailPrice?.toFixed(5),
    },
    {
      title: '折前单价',
      dataIndex: '',
      align: 'right',
      width: '5rem',
      ellipsis: true,
      render: (_, m) =>
        m.retailPrice && m?.discount
          ? (Number(m.retailPrice) / Number(m.discount))?.toFixed(5)
          : '-',
    },
    {
      title: '折扣%',
      dataIndex: 'discount',
      align: 'center',
      width: '5rem',
      render: (_, m) =>
        m?.discount ? (Number(m?.discount) * 100)?.toFixed(0) : '-',
    },
    {
      title: '单价',
      key: 'drugAmount',
      align: 'right',
      width: '4rem',
      render: (_, r) => r.drugAmount?.toFixed(2),
    },
    {
      title: '每帖数量',
      dataIndex: 'countPerTip',
      nextDataIndex: 'retailPrice',
      align: 'center',
      width: '5rem',
      editable: !!onSave && !readMode,
      onSave: onSave,
    },
    {
      title: '总数量',
      dataIndex: 'drugCount',
      align: 'center',
      width: '4rem',
    },
    {
      title: '用法',
      dataIndex: 'normalUsemethod',
      align: 'center',
      width: '6rem',
      // ellipsis: true,
      editable: !!onSave && !readMode,
      onSave: onSave,
      inputType: 'normalUsemethod',
      recipeCategory: recipeCategory,
    },
  ]
}

// 医嘱处方-处置项目表头
interface DisposeColumnsProps {
  theme: ThemeData
  materials: Material[]
  onSave?: (material: Material, values: Record<string, unknown>) => void
  allowedToChangePrice?: boolean
  onItemChange?: (itemPayType: number, recipeItem: any) => void
  readMode?: boolean
  onAction?: (v: string, t: any) => void
}

export const DisposeColumns = (
  theme: ThemeData,
  materials: Material[],
  onSave?: (material: Material, values: Record<string, unknown>) => void,
  allowedToChangePrice = false,
  onItemChange?: (itemPayType: number, recipeItem: any) => void,
  readMode?: boolean,
  onAction?: (v: string, t: any, tooth?: string, toothType?: number) => void,
  openEditVisible?: (v: any) => void,
  isEdit?: boolean
): EditableColumnType<Material>[] => {
  const groups = _.chain(materials)
    .groupBy((m) => m.groupNumber)
    .mapValues((ms) => ms.map((m) => m.id))
    .value()

  const menu = (v: any) => (
    <Menu
      onClick={(item) => {
        onItemChange && onItemChange(Number(item.key), v)
      }}
    >
      <Menu.Item style={{ fontWeight: 'bold', color: '#af623c' }} key='0'>
        自费
      </Menu.Item>
      {v.hilistCode && v.insuranceCode !== 'ZIFEI' && (
        <>
          <Menu.Item key='1' style={{ fontWeight: 'bold', color: '#088f52' }}>
            医保
          </Menu.Item>
          <Menu.Item key='3' style={{ fontWeight: 'bold', color: '#027aff' }}>
            历年
          </Menu.Item>
        </>
      )}

      {/* <Menu.Item key='2'>免费</Menu.Item> */}
    </Menu>
  )
  const medicalMenu = (v: any) => {
    return (
      <Menu>
        <Menu.Item key='0'>超出医保最高价格：{v?.limitPrice}元</Menu.Item>
      </Menu>
    )
  }
  const getPayTypeName = (payType: number) => {
    switch (payType) {
      case 0:
        return '自费'
      case 1:
        return '医保'
      case 2:
        return '免费'
      case 3:
        return '历年'
      default:
        return ''
    }
  }

  return [
    {
      title: '收费类型',
      key: 'orderCategory',
      align: 'center',
      width: '5.25rem',
      render: function Element(_, m) {
        // if (m.state === -1) {
        return (
          <Dropdown overlay={menu(m)} trigger={['click']}>
            <Button
              type='link'
              style={{
                color: getPayTypeColor(m.itemPayType),
                fontWeight: 'bold',
              }}
            >
              {getPayTypeName(m.itemPayType)}
              {getPayTypeName(m.itemPayType) ? <CaretDownOutlined /> : ''}
            </Button>
          </Dropdown>
        )
      },
    },
    {
      title: '序号',
      key: 'order',
      align: 'center',
      width: '3rem',
      render: (_1, _2, index) => (index + 1).toString(),
    },
    {
      title: '牙位',
      key: 'toothStruct',
      width: '10rem',
      align: 'center',
      render: function MaterialName(_, m: Material) {
        return (
          <ToothComponents
            data={m.toothStruct}
            isEdit={isEdit}
            onChange={(v: any, type = 0) => {
              const tooth = v.map((v: any) => (v ? v : '--'))
              onAction && onAction('toothEdit', m, tooth.join('@'), type)
            }}
          />
          // 3La-B,5,6@3D-P,5O@--@2L
        )
      },
    },
    {
      title: '处置信息',
      key: 'name',
      align: 'left',
      render: function MaterialName(_, m: Material) {
        let indicator = 'transparent'
        const expiredInDays = m.deadline
          ? moment.duration(moment(m.deadline).diff(moment())).asDays()
          : 9999
        if (
          m.inventoryInfo ||
          m.unShelveInfo ||
          expiredInDays <= 90 ||
          (m.skinTestTag === SkinTestStatus.Positive && m.skinTestInfo)
        ) {
          indicator = theme.e
        } else if (
          m.skinTestTag === SkinTestStatus.NeedATest &&
          m.skinTestInfo
        ) {
          indicator = theme.w
        } else if (
          (m.skinTestTag === SkinTestStatus.Continue ||
            m.skinTestTag === SkinTestStatus.Negative) &&
          m.skinTestInfo
        ) {
          indicator = theme.s
        }
        return {
          props: {
            style: {
              maxWidth: 200,
              borderLeft: `4px solid ${indicator}`,
              overflow: 'hidden',
            },
          },
          children: (
            <Space
              style={{ display: 'flex', justifyContent: 'space-between' }}
              onDoubleClick={() => {
                openEditVisible && openEditVisible(m)
              }}
            >
              <Col>
                <div
                  style={{
                    color: m.limitType === 1 ? '#ff5d5d' : theme.tc1,
                    fontSize: '0.875rem',
                    lineHeight: '0.875rem',
                    fontWeight: 'bold',
                    marginTop: 10,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    cursor: 'pointer',
                  }}
                >
                  {m.name}
                  {indicator !== 'transparent' && (
                    <span
                      style={{
                        color: indicator,
                        fontSize: '0.875rem',
                        fontWeight: 'normal',
                      }}
                    >
                      ({m.inventoryInfo}
                      {expiredInDays <= 90 &&
                        `有效期：${Math.floor(expiredInDays)}天`}
                      {m.unShelveInfo}
                      {m.skinTestInfo})
                      <span
                        style={{
                          display:
                            m.skinTestState == 4
                              ? 'inline-block'
                              : m.skinTestState == 1
                              ? 'inline-block'
                              : 'none',
                          color: m.alreadyNoticed > 0 ? '#fff' : '#000',
                          marginLeft: '10px',
                          fontSize: '13px',
                          backgroundColor:
                            m.alreadyNoticed > 0 ? '#ccc' : '#fff',
                          border: '1px solid #ccc',
                          padding: '5px',
                          cursor:
                            m.alreadyNoticed > 0 ? 'not-allowed' : 'pointer',
                        }}
                        onClick={() => {
                          if (m.alreadyNoticed > 0) {
                            return
                          } else {
                            onAction && onAction('qupi', m)
                          }
                        }}
                      >
                        {m.alreadyNoticed > 0
                          ? '已通知皮试'
                          : m.skinTestState == 4
                          ? '重新通知皮试'
                          : m.skinTestState == 1
                          ? '通知皮试'
                          : ''}
                      </span>
                    </span>
                  )}
                </div>

                <Popover
                  content={getSmallTextByType(m, 2)}
                  placement='bottom'
                  title={m.name}
                >
                  <div
                    style={{
                      color: m.limitType === 1 ? '#ff5d5d' : theme.tc3,
                      fontSize: '0.75rem',
                      lineHeight: '0.75rem',
                      marginBottom: 10,
                      marginTop: 4,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {getSmallTextByType(m, 2)}
                  </div>
                </Popover>
              </Col>
              {m.limitType === 1 && (
                <Dropdown overlay={medicalMenu(m)} placement='bottomLeft'>
                  <ExclamationCircleOutlined style={{ color: '#ff5d5d' }} />
                </Dropdown>
              )}
              {/* </Popover> */}
            </Space>
          ),
        }
      },
    },
    {
      title: '项目类别',
      key: 'orderCategory',
      align: 'center',
      width: '5.25rem',
      render: (_, m) => getOrderCategoryName(m.orderCategory),
    },
    {
      title: '数量',
      dataIndex: 'drugCount',
      align: 'center',
      width: '3rem',
      editable: !!onSave && !readMode,
      nextDataIndex: 'normalDose',
      onSave: onSave,
      colSpan: 2,
    },
    {
      dataIndex: 'accountUnit',
      align: 'center',
      width: '3rem',
      colSpan: 0,
      editable: !!onSave && !readMode,
      onSave: onSave,
      inputType: 'accountUnit',
      render: (_, m) => (m.splitTag ? m.splitUnit : m.accountUnit),
    },
    {
      title: '用法',
      dataIndex: 'normalUsemethod',
      align: 'center',
      width: '3.75rem',
      render: (_, m) => (m.normalUsemethod <= '0' ? '' : m.normalUsemethod),
    },
    {
      title: '单价',
      dataIndex: 'retailPrice',
      align: 'right',
      width: '4rem',
      ellipsis: true,
      editable: !!onSave && allowedToChangePrice && !readMode,
      onSave: onSave,
      render: (_, m) => m.retailPrice?.toFixed(5),
    },
    {
      title: '金额',
      key: 'drugAmount',
      width: '4rem',
      render: function Element(_, r) {
        // if (m.state === -1) {
        return (
          <div
            style={{
              textAlign: 'right',
              overflow: 'hidden',
            }}
          >
            {r.drugAmount?.toFixed(2)}
          </div>
        )
      },
    },
    {
      title: '甲/乙',
      key: 'orderCategory',
      align: 'center',
      width: '4.25rem',
      render: (_, m) => getOrderCategoryName(m.orderCategory),
    },
    {
      align: 'center',
      title: '备注',
      key: 'remark',
      width: '6.625rem',
      render: (_, m) => {
        let vs
        if (m.remark) {
          if (getOtcCategoryName(m.otc)) {
            vs = [getOtcCategoryName(m.otc), m.remark]
          } else {
            vs = [m.remark]
          }
        } else {
          vs = [getOtcCategoryName(m.otc)]
        }
        return vs.join('，')
      },
    },
  ]
}

export const toothDisposeColumns = (
  theme: ThemeData,
  materials: Material[]
): EditableColumnType<Material>[] => {
  const groups = _.chain(materials)
    .groupBy((m) => m.groupNumber)
    .mapValues((ms) => ms.map((m) => m.id))
    .value()

  const menu = (v: any) => (
    <Menu>
      <Menu.Item style={{ fontWeight: 'bold', color: '#af623c' }} key='0'>
        自费
      </Menu.Item>
      {v.hilistCode && v.insuranceCode !== 'ZIFEI' && (
        <>
          <Menu.Item key='1' style={{ fontWeight: 'bold', color: '#088f52' }}>
            医保
          </Menu.Item>
          <Menu.Item key='3' style={{ fontWeight: 'bold', color: '#027aff' }}>
            历年
          </Menu.Item>
        </>
      )}

      {/* <Menu.Item key='2'>免费</Menu.Item> */}
    </Menu>
  )
  const medicalMenu = (v: any) => {
    return (
      <Menu>
        <Menu.Item key='0'>超出医保最高价格：{v?.limitPrice}元</Menu.Item>
      </Menu>
    )
  }
  const getPayTypeName = (payType: number) => {
    switch (payType) {
      case 0:
        return '自费'
      case 1:
        return '医保'
      case 2:
        return '免费'
      case 3:
        return '历年'
      default:
        return ''
    }
  }

  return [
    {
      title: '收费类型',
      key: 'orderCategory',
      align: 'center',
      width: '5.25rem',
      render: function Element(_, m) {
        // if (m.state === -1) {
        return (
          <Dropdown overlay={menu(m)} trigger={['click']}>
            <Button
              type='link'
              style={{
                color: getPayTypeColor(m.itemPayType),
                fontWeight: 'bold',
              }}
            >
              {getPayTypeName(m.itemPayType)}
              {getPayTypeName(m.itemPayType) ? <CaretDownOutlined /> : ''}
            </Button>
          </Dropdown>
        )
      },
    },
    {
      title: '序号',
      key: 'order',
      align: 'center',
      width: '3rem',
      render: (_1, _2, index) => (index + 1).toString(),
    },
    {
      title: '牙位',
      key: 'toothStruct',
      width: '10rem',
      align: 'center',
      render: function MaterialName(_, m: Material) {
        return (
          <ToothComponents
            data={m.toothStruct}
            isEdit={false}
            onChange={(v: any, type = 0) => {
              const tooth = v.map((v: any) => (v ? v : '--'))
            }}
          />
          // 3La-B,5,6@3D-P,5O@--@2L
        )
      },
    },
    {
      title: '处置信息',
      key: 'name',
      align: 'left',
      render: function MaterialName(_, m: Material) {
        let indicator = 'transparent'
        const expiredInDays = m.deadline
          ? moment.duration(moment(m.deadline).diff(moment())).asDays()
          : 9999
        if (
          m.inventoryInfo ||
          m.unShelveInfo ||
          expiredInDays <= 90 ||
          (m.skinTestTag === SkinTestStatus.Positive && m.skinTestInfo)
        ) {
          indicator = theme.e
        } else if (
          m.skinTestTag === SkinTestStatus.NeedATest &&
          m.skinTestInfo
        ) {
          indicator = theme.w
        } else if (
          (m.skinTestTag === SkinTestStatus.Continue ||
            m.skinTestTag === SkinTestStatus.Negative) &&
          m.skinTestInfo
        ) {
          indicator = theme.s
        }
        return {
          props: {
            style: {
              maxWidth: 200,
              borderLeft: `4px solid ${indicator}`,
              overflow: 'hidden',
            },
          },
          children: (
            <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Col>
                <div
                  style={{
                    color: m.limitType === 1 ? '#ff5d5d' : theme.tc1,
                    fontSize: '0.875rem',
                    lineHeight: '0.875rem',
                    fontWeight: 'bold',
                    marginTop: 10,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {m.name}
                  {indicator !== 'transparent' && (
                    <span
                      style={{
                        color: indicator,
                        fontSize: '0.875rem',
                        fontWeight: 'normal',
                      }}
                    >
                      ({m.inventoryInfo}
                      {expiredInDays <= 90 &&
                        `有效期：${Math.floor(expiredInDays)}天`}
                      {m.unShelveInfo}
                      {m.skinTestInfo})
                      <span
                        style={{
                          display:
                            m.skinTestState == 4
                              ? 'inline-block'
                              : m.skinTestState == 1
                              ? 'inline-block'
                              : 'none',
                          color: m.alreadyNoticed > 0 ? '#fff' : '#000',
                          marginLeft: '10px',
                          fontSize: '13px',
                          backgroundColor:
                            m.alreadyNoticed > 0 ? '#ccc' : '#fff',
                          border: '1px solid #ccc',
                          padding: '5px',
                          cursor:
                            m.alreadyNoticed > 0 ? 'not-allowed' : 'pointer',
                        }}
                      >
                        {m.alreadyNoticed > 0
                          ? '已通知皮试'
                          : m.skinTestState == 4
                          ? '重新通知皮试'
                          : m.skinTestState == 1
                          ? '通知皮试'
                          : ''}
                      </span>
                    </span>
                  )}
                </div>
                <div
                  style={{
                    color: m.limitType === 1 ? '#ff5d5d' : theme.tc3,
                    fontSize: '0.75rem',
                    lineHeight: '0.75rem',
                    marginBottom: 10,
                    marginTop: 4,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {m.drugType === 1
                    ? `诊疗/${m.hilistCode}`
                    : `${m.packSpec}/${m.accountUnit}/${m.doseCategory}/${m.tenantSupplierName}/${m.hilistCode}`}
                </div>
              </Col>
              {m.limitType === 1 && (
                <Dropdown overlay={medicalMenu(m)} placement='bottomLeft'>
                  <ExclamationCircleOutlined style={{ color: '#ff5d5d' }} />
                </Dropdown>
              )}
              {/* </Popover> */}
            </Space>
          ),
        }
      },
    },
    {
      title: '项目类别',
      key: 'orderCategory',
      align: 'center',
      width: '5.25rem',
      render: (_, m) => getOrderCategoryName(m.orderCategory),
    },
    {
      title: '数量',
      dataIndex: 'drugCount',
      align: 'center',
      width: '3rem',
      nextDataIndex: 'normalDose',
      colSpan: 2,
    },
    {
      dataIndex: 'accountUnit',
      align: 'center',
      width: '3rem',
      colSpan: 0,
      render: (_, m) => (m.splitTag ? m.splitUnit : m.accountUnit),
    },
    {
      title: '用法',
      dataIndex: 'normalUsemethod',
      align: 'center',
      width: '3.75rem',
      render: (_, m) => (m.normalUsemethod <= '0' ? '' : m.normalUsemethod),
    },
    {
      title: '单价',
      dataIndex: 'retailPrice',
      align: 'right',
      width: '3.375rem',
      render: (_, m) => m.retailPrice?.toFixed(5),
    },
    {
      title: '金额',
      key: 'drugAmount',
      align: 'right',
      width: '4rem',
      render: (_, r) => r.drugAmount?.toFixed(2),
    },
    {
      title: '甲/乙',
      key: 'orderCategory',
      align: 'center',
      width: '4.25rem',
      render: (_, m) => getOrderCategoryName(m.orderCategory),
    },
    {
      align: 'center',
      title: '备注',
      key: 'remark',
      width: '6.625rem',
      render: (_, m) => {
        let vs
        if (m.remark) {
          if (getOtcCategoryName(m.otc)) {
            vs = [getOtcCategoryName(m.otc), m.remark]
          } else {
            vs = [m.remark]
          }
        } else {
          vs = [getOtcCategoryName(m.otc)]
        }
        return vs.join('，')
      },
    },
  ]
}
export const WorkDisposeColumns = (
  //工作站-随访-处置记录
  theme: ThemeData,
  onSave?: (material: Material, values: Record<string, unknown>) => void,
  allowedToChangePrice = false,
  onItemChange?: (itemPayType: number, recipeItem: any) => void,
  readMode?: boolean,
  onAction?: (v: string, t: any, tooth?: string, toothType?: number) => void,
  isEdit?: boolean
): EditableColumnType<Material>[] => {
  const menu = (v: any) => (
    <Menu
      onClick={(item) => {
        onItemChange && onItemChange(Number(item.key), v)
      }}
    >
      <Menu.Item style={{ fontWeight: 'bold', color: '#af623c' }} key='0'>
        自费
      </Menu.Item>
      {v.hilistCode && v.insuranceCode !== 'ZIFEI' && (
        <>
          <Menu.Item key='1' style={{ fontWeight: 'bold', color: '#088f52' }}>
            医保
          </Menu.Item>
          <Menu.Item key='3' style={{ fontWeight: 'bold', color: '#027aff' }}>
            历年
          </Menu.Item>
        </>
      )}

      {/* <Menu.Item key='2'>免费</Menu.Item> */}
    </Menu>
  )
  const medicalMenu = (v: any) => {
    return (
      <Menu>
        <Menu.Item key='0'>超出医保最高价格：{v?.limitPrice}元</Menu.Item>
      </Menu>
    )
  }
  const getPayTypeName = (payType: number) => {
    switch (payType) {
      case 0:
        return '自费'
      case 1:
        return '医保'
      case 2:
        return '免费'
      case 3:
        return '历年'
      default:
        return ''
    }
  }

  return [
    {
      title: '牙位',
      key: 'toothStruct',
      width: '10rem',
      align: 'center',
      render: function MaterialName(_, m: Material) {
        return (
          <ToothComponents
            data={m.toothStruct}
            isEdit={isEdit}
            onChange={(v: any, type = 0) => {
              const tooth = v.map((v: any) => (v ? v : '--'))
              onAction && onAction('toothEdit', m, tooth.join('@'), type)
            }}
          />
          // 3La-B,5,6@3D-P,5O@--@2L
        )
      },
    },

    {
      title: '项目类别',
      key: 'orderCategory',
      align: 'center',
      width: '5.25rem',
      render: (_, m) => getOrderCategoryName(m.orderCategory),
    },
    {
      title: '数量',
      dataIndex: 'drugCount',
      align: 'center',
      width: '3rem',
      editable: !!onSave && !readMode,
      nextDataIndex: 'normalDose',
      onSave: onSave,
      colSpan: 2,
    },
    {
      dataIndex: 'accountUnit',
      align: 'center',
      width: '3rem',
      colSpan: 0,
      render: (_, m) => (m.splitTag ? m.splitUnit : m.accountUnit),
    },

    {
      title: '单价',
      dataIndex: 'retailPrice',
      align: 'right',
      width: '3.375rem',
      editable: !!onSave && allowedToChangePrice && !readMode,
      onSave: onSave,
      render: (_, m) => m.retailPrice?.toFixed(5),
    },
    {
      title: '金额',
      key: 'drugAmount',
      align: 'right',
      width: '4rem',
      render: (_, r) => r.drugAmount?.toFixed(2),
    },
  ]
}

// 治疗计划
export const CurePlanColumns = (
  onSave: (
    material: Material,
    values: Record<string, unknown>,
    isSaveTooth?: boolean
  ) => void,
  readMode = false,
  isEdit = true,
  isShowSave = true
): EditableColumnType<any>[] => {
  return [
    {
      title: '序号',
      key: 'no',
      align: 'center',
      width: '4rem',
      render: (_1, _2, i) => `${i + 1}`,
    },
    {
      title: '牙位',
      key: 'toothStruct',
      width: '10rem',
      align: 'center',
      render: function MaterialName(_, m: Material) {
        return (
          <ToothComponents
            data={m.toothStruct}
            isShowSave={isShowSave}
            isEdit={isEdit}
            onChange={(v, type = 0) => {
              const tooth = v.map((v: any) => (v ? v : '--'))
              onSave(m, { toothStruct: tooth.join('@') }, type == 0)
            }}
          />
          // 3La-B,5,6@3D-P,5O@--@2L
        )
      },
    },
    {
      title: '项目名称',
      dataIndex: 'name',
      align: 'center',
    },
    {
      title: '项目类别',
      dataIndex: 'billingCategory',
      align: 'center',
      width: '10rem',
      render: (_, m) => BillingCategoryName(m.billingCategory),
    },
    {
      title: '数量',
      dataIndex: 'drugCount',
      align: 'center',
      width: '5rem',
      editable: !!onSave && !readMode,
      nextDataIndex: 'normalDose',
      onSave: onSave,
      colSpan: 2,
    },
    {
      dataIndex: 'accountUnit',
      align: 'center',
      width: '5rem',
      colSpan: 0,
      render: (_, m) => (m.splitTag ? m.splitUnit : m.accountUnit),
    },
    {
      title: '单价',
      dataIndex: 'retailPrice',
      align: 'right',
      width: '10rem',
      editable: !!onSave && !readMode,
      onSave: onSave,
      render: (_, m) => Number(m?.retailPrice)?.toFixed(5),
    },
  ]
}

// 中医病历表头
export const TcmMedicineMaterialColumns = (
  theme: ThemeData
): EditableColumnType<Material>[] => {
  return [
    {
      title: '药品信息',
      align: 'left',
      dataIndex: 'name',
      ellipsis: true,
      render: function MaterialName(_, m): ReactElement {
        return (
          <Popover
            overlayClassName={styles.popverContent}
            zIndex={999999}
            placement='bottom'
            content={
              <>
                <div>{m.name}（{m?.tenantSupplierName}）</div>
              </>
            }
          >
            <Col style={{ cursor: 'pointer' }}>
              <div>{m.name}</div>
            </Col>
          </Popover>
        )
      },
    },
    {
      title: '数量',
      dataIndex: 'drugCount',
      align: 'center',
      colSpan: 2,
      width: '4rem',
    },
    {
      dataIndex: 'accountUnit',
      align: 'center',
      colSpan: 0,
      width: '4rem',
      render: (_, m) => (m.splitTag ? m.splitUnit : m.accountUnit),
    },
  ]
}
