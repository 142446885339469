import { Col, Form, Row, Select } from 'antd'

import { useDispatch } from 'react-redux'
import { RootDispatch } from '../../app/store'
import React, { ReactElement, useContext, useEffect, useState } from 'react'

import { UploadFile } from 'antd/lib/upload/interface'
import { NavBar } from '../../compnents/nav/NavBar'
import { CloseOutlined } from '@ant-design/icons'
import { useLocation } from 'react-router-dom'
import { unwrapResult } from '@reduxjs/toolkit'
import { getExcuteDetailList } from './toBeCompletedOperation/toBeCompletedOperationSlice'
import { getGenderName } from '../../models/user'
import styles from './operationDetail.module.css'
import { getUrl } from '../treatment/oralCases/oralCasesSlice'
import moment from 'moment'
import {
  DateTimeFormat,
  DateTimeFormatSimpleOnlyOne,
} from '../../models/datetime'
import { type } from 'node:os'
import { ToothComponents } from '../treatment/editor/modals/toothComponents'
import { getAge } from '../../utils/StringUtils'

type OperationDetailProps = {
  patientId?: string
  toothPatient?: boolean //口腔专业版
  Ctag?: number
}

export const OperationDetail = ({
  toothPatient,
  patientId,
  Ctag,
}: OperationDetailProps): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const [form] = Form.useForm()

  const [detail, setDetail] = useState<any>()

  const [operatingContent, setOperatingContent] = useState<any>([])

  const [helperContent, setHelperContent] = useState<any>([])

  const location: any = useLocation()

  const [operatingToothAfter, setoperatingToothAfter] = useState('')

  const [operatingToothBefore, setoperatingToothBefore] = useState('')

  const labelStyle = {
    style: {
      color: '#101010',
      fontSize: '1rem',
      textAlign: 'right',
      marginBottom: 4,
    } as React.CSSProperties,
  }
  const layout = {
    style: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      width: '80%',
    } as React.CSSProperties,
  }

  const valueStyle = {
    flex: 1,
    style: {
      color: '#333333',
      fontSize: '1rem',
      marginBottom: 4,
    } as React.CSSProperties,
  }

  const [fileList, setFileList] = useState<UploadFile[]>([])

  useEffect(() => {
    form.setFieldsValue({
      whiteList: [''],
    })
  }, [])

  useEffect(() => {
    if (location?.state?.id || patientId)
      dispatch(getExcuteDetailList({ id: location?.state?.id || patientId }))
        .then(unwrapResult)
        .then((res: any) => {
          const operatingContentList =
            res.operatingContent[0] === '['
              ? JSON.parse(res.operatingContent)
              : []
          const helperList = res.helper[0] === '[' ? JSON.parse(res.helper) : []
          setoperatingToothBefore(res.operatingToothBefore)
          setoperatingToothAfter(res.operatingToothAfter)
          setDetail(res)
          setOperatingContent(
            operatingContentList.map((v: any) => {
              return `${v.surgIcalName}/${toothPatient ? '' : v.bw + '/'}${
                v.dj
              }/${v.qk}`
            })
          )
          setHelperContent(
            helperList.map((v: any) => {
              if (v?.assistant) {
                return `${v?.assistant}/${v?.zz}`
              } else {
                return '-'
              }
            })
          )
        })
  }, [dispatch, location, patientId])

  useEffect(() => {
    if (detail)
      setFileList(
        detail?.coherentVideo &&
          detail?.coherentVideo.split(',').map((v: any) => {
            return ({
              name: v.split('@')[0],
              uid: v.split('@')[1],
              url: v.split('@')[2],
            } as unknown) as UploadFile
          })
      )
  }, [detail])

  const tag = location?.state?.tag || Ctag

  return (
    <Col
      style={{
        height: 'calc(100% - 20px)',
        overflow: 'hidden',
        position: 'relative',
        marginTop: 10,
        marginBottom: 10,
        padding: '0px 20px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {!toothPatient && (
        <NavBar
          where={[
            tag == 1 ? '待完成手术' : tag == 2 ? '已结束手术' : '',
            '手术详情',
          ]}
          backtrace={{
            name: tag == 1 ? '待完成手术' : tag == 2 ? '已结束手术' : '',
            path: '/operationStation',
            routeName: '手术',
            state: {
              selectKey: tag == 1 ? '0' : tag == 2 ? '3' : '',
            },
          }}
        />
      )}
      <Col
        style={{
          fontSize: '14px',
          flex: 1,
          borderRadius: 10,
          padding: '10px 15px 10px 15px',
          display: 'flex',
          flexDirection: 'column',
          background: '#fff',
          overflowY: 'scroll',
        }}
      >
        <div style={{ display: 'flex', borderBottom: '1px solid #efefef' }}>
          <Col
            span={18}
            style={{
              lineHeight: '60px',
              marginBottom: '20px',
              paddingLeft: '20px',
              fontSize: '16px',
            }}
          >
            <Row>
              {!toothPatient && (
                <>
                  <Col span={8} {...layout}>
                    患者姓名：{detail?.patientName}
                  </Col>
                  <Col span={8} {...layout}>
                    性别：{getGenderName(detail?.patientSex)}
                  </Col>
                  <Col span={8} {...layout}>
                    年龄：
                    {getAge(detail?.patientAge, detail?.patientMonth)}
                  </Col>
                  <Col span={8} {...layout}>
                    手术名称：{operatingContent[0]}
                  </Col>
                </>
              )}
              <Col span={8} {...layout}>
                开单人：{detail?.createUserName}
              </Col>
              <Col span={8} {...layout}>
                开单时间：
                {detail?.createTime
                  ? moment(detail?.createTime).format(DateTimeFormat)
                  : '-'}
              </Col>
            </Row>
          </Col>
          <div
            className={
              tag == 2 && (detail?.state == 0 || detail?.state == 1)
                ? styles.over
                : tag == 2 && detail?.state == 2
                ? styles.cancel
                : ''
            }
          ></div>
        </div>
        <Row
          gutter={10}
          style={{
            fontSize: '16px',
            marginLeft: '20px',
            lineHeight: '50px',
          }}
        >
          {toothPatient && (
            <Col span={12}>
              <Row>
                <Col {...labelStyle}>就诊信息：</Col>
                <Col {...valueStyle}>
                  {`${detail?.registrationTime} ${
                    detail?.treatmentDoctorName
                  } ${
                    detail?.insuranceCode == 'ZIFEI'
                      ? '自费'
                      : detail?.insuranceCode == '' ||
                        detail?.insuranceCode == null
                      ? ' 未知险种'
                      : '医保刷卡'
                  } (${detail?.treatmentDepartmentName}) ${detail?.disease}`}
                  {/* {detail?.appointmentTime
                    ? moment(detail?.appointmentTime).format(DateTimeFormat)
                    : '-'} */}
                </Col>
              </Row>
            </Col>
          )}
          <Col span={12}>
            <Row>
              <Col {...labelStyle}>手术时间：</Col>
              <Col {...valueStyle}>
                {detail?.appointmentTime
                  ? moment(detail?.appointmentTime).format(DateTimeFormat)
                  : '-'}
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row>
              <Col {...labelStyle}>手术时长：</Col>
              <Col {...valueStyle}>
                {detail?.appointmentDuration
                  ? detail?.appointmentDuration + '  min'
                  : '-'}
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row>
              <Col {...labelStyle}>手术室：</Col>
              <Col {...valueStyle}>
                {detail?.operatingRoomName ? detail?.operatingRoomName : '-'}
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row>
              <Col {...labelStyle}>手术科室：</Col>
              <Col {...valueStyle}>
                {detail?.treatmentDepartmentName
                  ? detail?.treatmentDepartmentName
                  : '-'}
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row>
              <Col {...labelStyle}>
                {toothPatient ? '术前诊断：' : '手术诊断：'}
              </Col>
              <Col {...valueStyle}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <ToothComponents data={operatingToothBefore} isEdit={false} />
                  <span
                    style={{
                      marginLeft: 20,
                    }}
                  >
                    {detail?.operatingDiagnoseBefore
                      ? detail?.operatingDiagnoseBefore
                      : '-'}
                  </span>
                </div>
              </Col>
            </Row>
          </Col>
          {toothPatient && (
            <Col span={24}>
              <Row>
                <Col {...labelStyle}>术后诊断：</Col>
                <Col {...valueStyle}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <ToothComponents
                      data={operatingToothAfter}
                      isEdit={false}
                    />
                    <span
                      style={{
                        marginLeft: 20,
                      }}
                    >
                      {detail?.operatingDiagnoseAfter
                        ? detail?.operatingDiagnoseAfter
                        : '-'}
                    </span>
                  </div>
                </Col>
              </Row>
            </Col>
          )}
          {operatingContent.map((v: any, i: number) => (
            <Col span={24} key={i}>
              <Row>
                <Col {...labelStyle}>手术名称：</Col>
                <Col {...valueStyle}>{v}</Col>
              </Row>
            </Col>
          ))}
          <Col span={12}>
            <Row>
              <Col {...labelStyle}>麻醉方式：</Col>
              <Col {...valueStyle}>
                {detail?.anesthesiaMethod ? detail?.anesthesiaMethod : '-'}
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row>
              <Col {...labelStyle}>体位：</Col>
              <Col {...valueStyle}>
                {detail?.position ? detail?.position : '-'}
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row>
              <Col {...labelStyle}>相关影像：</Col>
              {fileList ? (
                <Col {...valueStyle}>
                  {fileList &&
                    fileList.map((item) => (
                      <Row
                        gutter={24}
                        key={item.uid}
                        style={{
                          marginTop: '-1px',
                          width: '300px',
                        }}
                      >
                        <Col flex={1}>
                          <span
                            style={{
                              display: 'inline-block',
                              maxWidth: '60px',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              verticalAlign: 'middle',
                            }}
                          >
                            {item.name.split('.')[0]}
                          </span>
                          <span
                            style={{
                              verticalAlign: 'middle',
                            }}
                          >
                            .{item.name.split('.')[1]}
                          </span>
                        </Col>
                        <Col span={6}>
                          <a
                            onClick={() => {
                              dispatch(getUrl({ fileName: item.url }))
                                .then(unwrapResult)
                                .then((v: any) => {
                                  const proxy =
                                    window.location.origin.indexOf(
                                      'his.develop.hydhis.com'
                                    ) >= 0
                                      ? '/proxyToOssDevPrivate'
                                      : window.location.origin.indexOf(
                                          'his.test.hydhis.com'
                                        ) >= 0
                                      ? '/proxyToOssTestPrivate'
                                      : window.location.origin.indexOf(
                                          'hydhis.cn'
                                        ) >= 0
                                      ? '/proxyToOssProdPrivate'
                                      : '/proxyToOssProdPrivate'
                                      const url = (window.location.origin?.includes('localhost')
                                      ? 'http://his.develop.hydhis.com'
                                      : window.location.origin) + proxy + v
                                  window.open(url, '_blank')
                                })
                            }}
                          >
                            查看
                          </a>
                        </Col>
                        {/* <Col span={6}>
                          <CloseOutlined
                            onClick={() => {
                              //  e.stopPropagation();
                              setFileList(() =>
                                fileList.filter((ite) => ite.uid !== item.uid)
                              )
                            }}
                          />
                        </Col> */}
                      </Row>
                    ))}
                </Col>
              ) : (
                '暂无相关影像'
              )}
            </Row>
          </Col>
          <Col span={12}>
            <Row>
              <Col {...labelStyle}>主刀医生：</Col>
              <Col {...valueStyle}>{detail?.mainDoctorName}</Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row>
              <Col {...labelStyle}>麻醉医生：</Col>
              <Col {...valueStyle}>
                {detail?.anesthesiaDoctorName
                  ? detail?.anesthesiaDoctorName
                  : '-'}
              </Col>
            </Row>
          </Col>
          {helperContent.map((v: any, i: any) => (
            <Col span={9} key={i}>
              <Row>
                <Col {...labelStyle}>助手：</Col>
                <Col {...valueStyle}>{v}</Col>
              </Row>
            </Col>
          ))}

          <Col span={24}>
            <Row>
              <Col {...labelStyle}>备注：</Col>
              <Col {...valueStyle}>{detail?.remark ? detail?.remark : '-'}</Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Col>
  )
}
