import { unwrapResult } from '@reduxjs/toolkit'
import { Button, Col, Form, Modal, notification, Row, Spin, Upload } from 'antd'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import styles from './Putstorelist.module.css'
import _ from 'lodash'
import { RootDispatch } from '../../app/store'
import React from 'react'
import { UploadOutlined } from '../../compnents/icons/Icons'
import { getImportExcelAsync, getListExport } from './putstorelistSlice'
import { DownloadOutlined } from '@ant-design/icons'
import {
  getCommodityImportExcel,
  getcommoditytExcel,
  getexaminetreatExcel,
} from '../commodity/commoditySlice'
import Dragger from 'antd/lib/upload/Dragger'
import { getSuppierImportExcelAsync } from '../wms/supplierSlice'
import { getTakeStockImportExcelAsync } from '../Inventorystock/InventStockSlice'
import { getSetMealImportExcelAsync } from '../setmeal/setmealSlice'

export const PurchaseModal = (props: {
  id?: any
  isPurchaseModalVisible?: boolean
  state?: any
  refresh: () => void
  onOk: () => void
  onCancel: () => void
  storehouseId?: any
}) => {
  const dispatch = useDispatch<RootDispatch>()

  const [form] = Form.useForm()

  const [fileList, setFileList] = useState<any>()

  const [pageLoading, setPageLoading] = useState(false)

  const [title, setTitle] = useState(props.state ? '导入' : '导入库存')

  useEffect(() => {
    console.log(props.state)
    switch (props.state) {
      case 5:
        setTitle('导入盘点')
        break
      default:
        break
    }
  }, [])

  const getUrl = (state?: number) => {
    switch (state) {
      case 2: //商品目录模板
        return 'https://hydhis-public.oss-cn-hangzhou.aliyuncs.com/misc/%E5%95%86%E5%93%81%E7%9B%AE%E5%BD%95%E6%A8%A1%E6%9D%BF-20230213.xlsx'
      case 3: //诊疗项目模板
        return 'https://hydhis-public.oss-cn-hangzhou.aliyuncs.com/misc/%E8%AF%8A%E7%96%97%E9%A1%B9%E7%9B%AE%E6%A8%A1%E6%9D%BF-20240308.xlsx'
      case 4: //供应商导入模板
        return 'https://hydhis-public.oss-cn-hangzhou.aliyuncs.com/misc/%E4%BE%9B%E5%BA%94%E5%95%86%E5%AF%BC%E5%85%A5%E6%A8%A1%E7%89%88.xlsx'
      case 5: //导入库存盘点
        return 'https://hydhis-public.oss-cn-hangzhou.aliyuncs.com/misc/%E5%BA%93%E5%AD%98%E7%9B%98%E7%82%B9%E6%A8%A1%E7%89%88.xlsx'
      case 99: //套餐导入模板
        return 'https://hydhis-public.oss-cn-hangzhou.aliyuncs.com/misc/%E5%A5%97%E9%A4%90%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx'
      default: //购入入库明细导入
        return 'https://hydhis-public.oss-cn-hangzhou.aliyuncs.com/misc/%E8%B4%AD%E5%85%A5%E5%85%A5%E5%BA%93%E6%98%8E%E7%BB%86%E5%AF%BC%E5%85%A5%E4%B8%8B%E8%BD%BD%E6%A1%88%E4%BE%8B20220524.xlsx'
    }
  }

  const exportInventoryExcel = () => {
    dispatch(
      getListExport({
        storehouseId: props?.storehouseId,
        tenantInventoryReceiptsId: props?.id,
        isTemplate: 1,
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        const content = res
        const blob = new Blob([content])
        const fileName = '导入盘点模版.xlsx'
        const selfURL = window[window.webkitURL ? 'webkitURL' : 'URL']
        const elink = document.createElement('a')
        if ('download' in elink) {
          elink.download = fileName
          elink.style.display = 'none'
          elink.href = selfURL['createObjectURL'](blob)
          document.body.appendChild(elink)
          // 触发链接
          elink.click()
          selfURL.revokeObjectURL(elink.href)
          document.body.removeChild(elink)
        } else {
          return (navigator as any).msSaveBlob(blob, fileName)
        }
      })
      .catch((res: any) => {
        return
      })
  }

  return (
    <>
      <Modal
        className={styles.main}
        width={550}
        title={title}
        okText='提交'
        footer={null}
        centered
        onOk={() => {
          props.onOk()
        }}
        onCancel={() => {
          props.onCancel()
        }}
        visible={props.isPurchaseModalVisible}
        destroyOnClose
      >
        {/*<Spin spinning={pageLoading}>*/}
        <Form
          form={form}
          name='basic'
          autoComplete='off'
          onFinish={(v) => {
            return
          }}
        >
          <Form.Item label='上传文件' style={{ margin: '25px' }}>
            <Dragger
              accept='.xls,.xlsx'
              maxCount={1}
              beforeUpload={(file) => {
                setFileList(file)
                return false
              }}
            >
              <div className={styles.upload}>
                <UploadOutlined mode='large'></UploadOutlined>
                <div>
                  将文件拖至此处，或{' '}
                  <span style={{ color: '#3894ff' }}>点击上传</span>
                </div>
              </div>
            </Dragger>
          </Form.Item>
          <Row style={{ marginBottom: '20px', marginTop: '-10px' }}>
            <p style={{ marginLeft: '90px' }}>只能上传.xls,.xlxs等文件</p>
            <a
              style={{ marginLeft: '140px' }}
              href={getUrl(props.state)}
              id='link1'
              onClick={(e) => {
                if (props.state === 5) {
                  //盘点导出模版为机构真实数据
                  e.preventDefault()
                  exportInventoryExcel()
                }
              }}
            >
              <DownloadOutlined />
              下载模板
            </a>
            {/* {props.state === 2 ? (
              <a
                style={{ marginLeft: "140px" }}
                href='https://hydhis-public.oss-cn-hangzhou.aliyuncs.com/misc/%E5%95%86%E5%93%81%E7%9B%AE%E5%BD%95%E6%A8%A1%E6%9D%BF-20220527.xlsx?versionId=CAEQPBiBgMDTgaONiBgiIDU3ZjNkYWFhNDgxOTQzZjA5MTQwMjc2MDIwMTJjOWM4'
                id='link1'
              >
                <DownloadOutlined />
                下载模板
              </a>
            ) : (
              <a
                style={{ marginLeft: "140px" }}
                href='https://hydhis-public.oss-cn-hangzhou.aliyuncs.com/misc/%E8%B4%AD%E5%85%A5%E5%85%A5%E5%BA%93%E6%98%8E%E7%BB%86%E5%AF%BC%E5%85%A5%E4%B8%8B%E8%BD%BD%E6%A1%88%E4%BE%8B20211216.xlsx?versionId=CAEQIRiBgMDEyseJ7hciIDUyOWM0N2VlYzFkMDRjZWJiNjEwNDk2ZDRjZWM2OWJh'
                id='link1'
              >
                <DownloadOutlined />
                下载模板
              </a>
            )} */}
          </Row>
        </Form>
        <Row gutter={20}>
          <Col span={12}>
            <Button
              style={{ float: 'right' }}
              onClick={() => {
                form.resetFields()
                props.onCancel()
              }}
            >
              关闭
            </Button>
          </Col>
          <Col span={12}>
            <Button
              loading={pageLoading}
              type='primary'
              htmlType='submit'
              onClick={() => {
                if (fileList instanceof File) {
                  if (props.state === 2) {
                    dispatch(
                      getcommoditytExcel({
                        file: fileList,
                      })
                    )
                      .then(unwrapResult)
                      .then((res: any) => {
                        notification.success({
                          message: '导入成功',
                          duration: 3,
                        })
                        form.resetFields()
                        props?.onOk()
                        props?.refresh()
                      })
                      .catch(() => {
                        props?.onOk()
                        props?.refresh()
                      })
                  } else if (props.state === 3) {
                    dispatch(
                      getexaminetreatExcel({
                        file: fileList,
                      })
                    )
                      .then(unwrapResult)
                      .then((res: any) => {
                        notification.success({
                          message: '导入成功',
                          duration: 3,
                        })
                        form.resetFields()
                        props?.onOk()
                        props?.refresh()
                      })
                      .catch(() => {
                        props?.onOk()
                        props?.refresh()
                      })
                  } else if (props.state === 4) {
                    dispatch(
                      getSuppierImportExcelAsync({
                        file: fileList,
                      })
                    )
                      .then(unwrapResult)
                      .then((res: any) => {
                        notification.success({
                          message: '导入成功',
                          duration: 3,
                        })
                        form.resetFields()
                        props?.onOk()
                      })
                      .catch(() => {
                        props?.onOk()
                        props?.refresh()
                      })
                  } else if (props.state === 5) {
                    //导入盘点
                    setPageLoading(true)
                    dispatch(
                      getTakeStockImportExcelAsync({
                        file: fileList,
                        inventoryReceiptsId: props?.id,
                      })
                    )
                      .then(unwrapResult)
                      .then((res: any) => {
                        notification.success({
                          message: '导入成功',
                          duration: 3,
                        })
                        form.resetFields()
                        props?.onOk()
                      })
                      .catch(() => {
                        props?.onOk()
                        props?.refresh()
                      })
                      .finally(() => {
                        setPageLoading(false)
                      })
                  } else if (props.state === 99) {
                    //导入套餐
                    setPageLoading(true)
                    dispatch(
                      getSetMealImportExcelAsync({
                        file: fileList,
                        inventoryReceiptsId: props?.id,
                      })
                    )
                      .then(unwrapResult)
                      .then((res: any) => {
                        notification.success({
                          message: '导入成功',
                          duration: 3,
                        })
                        props?.refresh()
                        form.resetFields()
                        props?.onOk()
                      })
                      .catch(() => {
                        props?.onOk()
                        props?.refresh()
                      })
                      .finally(() => {
                        setPageLoading(false)
                      })
                  } else {
                    setPageLoading(true)
                    dispatch(
                      getImportExcelAsync({
                        file: fileList,
                        tenantInventoryReceiptsId: props?.id,
                      })
                    )
                      .then(unwrapResult)
                      .then((res: any) => {
                        notification.success({
                          message: '导入成功',
                          duration: 3,
                        })
                        form.resetFields()
                        props?.refresh()
                        props?.onOk()
                      })
                      .catch(() => {
                        props?.onOk()
                        form.resetFields()
                      })
                      .finally(() => {
                        setPageLoading(false)
                      })
                  }
                }
              }}
            >
              确认提交
            </Button>
          </Col>
        </Row>
        {/*</Spin>*/}
      </Modal>
    </>
  )
}
