import { unwrapResult } from '@reduxjs/toolkit'
import { Button, Col, Modal, Row, Tooltip } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../../app/store'
import {
  EditableColumnType,
  EditableList,
} from '../../../compnents/list/EditableList'
import { DateTimeFormat } from '../../../models/datetime'
import { medicalSendHttp, strParse } from '../../../utils/MedicalUtils'
import { searchMARecordListAsync } from '../../registration/register/registerSlice'
import styles from './TcmCases.module.css'
export type serverType = 'requestString' | 'response'

interface MedicationAdministrationRecordProps {
  visible?: any
  registration: any
  baseData?: any
  insuranceBean?: any
  patientId?: any
  doctorId?: any
  onOK?: () => void
  onCancel?: () => void
}

export const MedicationAdministrationRecordModal = ({
  visible,
  registration,
  baseData,
  insuranceBean,
  patientId,
  doctorId,
  onOK,
  onCancel,
}: MedicationAdministrationRecordProps) => {
  const dispatch = useDispatch<RootDispatch>()

  const [params, setParams] = useState<any>({
    current: 1,
    size: 10,
  })

  const [page, setPage] = useState<any>({
    total: 0,
    items: [],
  })

  useEffect(() => {
    if (!visible) {
      setParams({
        current: 1,
        size: 10,
      })
    }
  }, [visible])

  useEffect(() => {
    if (visible) {
      if (params?.current)
        searchMedicationAdministrationRecordList(
          'requestString',
          params?.current,
          params?.size,
          patientId,
          doctorId
        )
    } else {
      setPage({ total: 0, items: [] })
    }
  }, [visible, params])

  // 查询近三个月的医保用药记录
  const searchMedicationAdministrationRecordList = (
    serverType: serverType,
    current: any,
    size: any,
    patientId: any,
    doctorId: any,
    responseString?: string
  ) => {
    dispatch(
      searchMARecordListAsync({
        serverType,
        body: {
          baseData: {
            ...baseData,
            responseString,
          },
          currentPage: current,
          pageSize: size,
          patientId,
          doctorId,
        },
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        if (serverType === 'requestString') {
          const data = strParse(res)
          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                insuranceBean?.countryHospitalNb,
                insuranceBean?.secretToken,
              ],
              message: '医保用药记录查询',
            },
            (call: any, err) => {
              if (!err) {
                searchMedicationAdministrationRecordList(
                  'response',
                  params?.current,
                  params?.size,
                  patientId,
                  doctorId,
                  JSON.stringify(call.netmessage)
                )
              } else {
                return
              }
            }
          )
        } else {
          setPage({
            total: res?.res?.total,
            items: res?.res?.lists,
          })
        }
      })
      .catch(() => {
        return
      })
  }

  const Columns = (startIndex?: any): EditableColumnType<any>[] => {
    return [
      {
        title: '序号',
        key: 'no',
        align: 'center',
        width: '5rem',
        render: (_1, _2, index) => `${startIndex + index + 1}`,
      },
      {
        title: '日期',
        dataIndex: 'feeOcurTime',
        align: 'center',
        width: '10rem',
        ellipsis: true,
        render: (_, t) =>
          t?.feeOcurTime ? moment(t?.feeOcurTime)?.format(DateTimeFormat) : '-',
      },
      {
        title: '项目名称',
        dataIndex: 'hiListName',
        align: 'center',
        width: '21rem',
        ellipsis: true,
        render: (_, t) => (t?.hiListName ? t?.hiListName : '-'),
      },
      {
        title: '数量',
        dataIndex: 'cnt',
        width: '6rem',
        align: 'center',
        ellipsis: true,
      },
      {
        title: '剂型',
        dataIndex: 'dosformName',
        align: 'center',
        width: '6rem',
        ellipsis: true,
        render: (_, t) =>
          t?.dosformName &&
          t?.dosformName != 'null' &&
          t?.dosformName != 'NaN' &&
          t?.dosformName != '-1'
            ? t?.dosformName
            : '-',
      },
      {
        title: '使用频次描述',
        dataIndex: 'usedFrquDscr',
        width: '8rem',
        ellipsis: true,
        align: 'center',
        render: (_, t) =>
          t?.usedFrquDscr &&
          t?.usedFrquDscr != 'null' &&
          t?.usedFrquDscr != 'NaN' &&
          t?.usedFrquDscr != '-1'
            ? t?.usedFrquDscr
            : '-',
      },
      {
        title: '单次剂量描述',
        dataIndex: 'sinDosDscr',
        align: 'center',
        width: '8rem',
        ellipsis: true,
        render: (_, t) =>
          t?.sinDosDscr &&
          t?.sinDosDscr != 'null' &&
          t?.sinDosDscr != 'NaN' &&
          t?.sinDosDscr != '-1'
            ? Number(t?.sinDosDscr)?.toFixed(2)
            : '-',
      },
    ]
  }

  return (
    <Modal
      title={registration?.patientName + '近三个月的医保用药记录'}
      visible={visible}
      footer={null}
      onCancel={() => {
        onCancel && onCancel()
      }}
      width={1080}
      bodyStyle={{
        height: 600,
        padding: '10px 15px 20px 25px',
      }}
    >
      <div className={styles.MedicationAdministrationRecordContent}>
        <EditableList
          style={{ height: '470px', overflow: 'hidden' }}
          scroll={{ y: 410 }}
          bordered
          page={{
            current: params.current,
            size: params.size,
            total: page.total,
            items: page.items,
          }}
          columns={Columns(params.size * (params.current - 1))}
          onChangePage={(current, size) =>
            setParams({
              ...params,
              current,
              size: size || 10,
            })
          }
        />
      </div>
      <Row
        justify='end'
        style={{
          marginTop: 8,
        }}
        onClick={() => {
          onCancel && onCancel()
        }}
      >
        <Button>返回（ESC）</Button>
      </Row>
    </Modal>
  )
}
