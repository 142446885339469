import { Space, TableColumnType } from 'antd'
import React, { ReactElement } from 'react'
import { StateSwitch } from '../../compnents/widgets/StateSwitch'
import { Dimen } from '../../models/dimen'
import {
  getGenderName,
  getStationCategoryName,
  Role,
  User,
} from '../../models/user'
import femaleHead from '../weChart/images/female.png'
import maleHead from '../weChart/images/male.png'

type ActionType = 'menus' | 'toggle' | 'reset' | 'setting' | 'login'

export const columns = (
  onClick: (action: ActionType, user: User) => void,
  startIndex: number,
  tenantId: string | undefined
): TableColumnType<User>[] => {
  return [
    {
      title: '序号',
      key: 'order',
      align: 'center',
      width: Dimen.Num,
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
    {
      title: '头像',
      dataIndex: 'name',
      width: '6rem',
      align: 'center',
      ellipsis: true,
      render: function ShowActions(_, r) {
        const href = window.location.origin?.includes('localhost')
        ? 'http://his.develop.hydhis.com'
        : window.location?.origin
        const headPhotoList = r?.headPhoto?.split('/')
        return r.headPhoto && headPhotoList?.[0] == 'proxyToOss' ? (
          <img
            src={href + '/' + r.headPhoto}
            alt=''
            style={{ width: '30px', height: '30px', borderRadius: '50%' }}
          ></img>
        ) : r.headPhoto && headPhotoList?.[0] != 'proxyToOss' ? (
          <img
            src={r.headPhoto}
            alt=''
            style={{ width: '30px', height: '30px', borderRadius: '50%' }}
          ></img>
        ) : r.gender == 2 ? (
          <img
            src={femaleHead}
            alt=''
            style={{ width: '30px', height: '30px', borderRadius: '50%' }}
          ></img>
        ) : (
          <img
            src={maleHead}
            alt=''
            style={{ width: '30px', height: '30px', borderRadius: '50%' }}
          ></img>
        )
      },
    },
    {
      title: '姓名',
      dataIndex: 'name',
      width: Dimen.Name,
      align: 'center',
      ellipsis: true,
    },
    {
      title: '医保国码',
      dataIndex: 'countryCode',
      width: Dimen.Name,
      align: 'center',
      ellipsis: true,
    },
    {
      title: '性别',
      key: 'gender',
      align: 'center',
      width: Dimen.Sex,
      render: (_: string, u: User) => getGenderName(u.gender),
    },
    {
      title: '手机',
      dataIndex: 'phone',
      align: 'center',
      width: Dimen.TelPhone,
      ellipsis: true,
    },
    {
      title: '是否管理员',
      key: 'role',
      align: 'center',
      width: Dimen.Role,
      render: (_: string, u: User) => (u.role === Role.User ? '否' : '是'),
    },
    {
      title: '所属科室',
      dataIndex: 'departmentName',
      align: 'center',
      width: Dimen.Department,
      ellipsis: true,
    },
    {
      title: '岗位',
      key: 'stationCategory',
      align: 'center',
      width: Dimen.Category,
      render: (_: string, u: User) => getStationCategoryName(u.stationCategory),
    },
    {
      title: '状态',
      key: 'state',
      align: 'center',
      width: Dimen.State,
      render: function UserStatus(_, u): ReactElement {
        return (
          <StateSwitch
            value={u.state === 1}
            onChange={() => onClick('toggle', u)}
          />
        )
      },
    },
    {
      title: '权限',
      key: 'permissions',
      align: 'center',
      width: Dimen.Permissions,
      render: function UserPermissions(_: string, u: User): ReactElement {
        return (
          <Space>
            <a onClick={() => onClick('menus', u)}>菜单</a>
          </Space>
        )
      },
    },
    {
      title: '重置密码',
      key: 'actions',
      width: '6rem',
      align: 'center',
      render: function UserSettings(_, u): ReactElement {
        return <a onClick={() => onClick('reset', u)}>重置密码</a>
      },
    },
    {
      title: '一键登录',
      key: 'actions',
      width: tenantId ? '6rem' : '0rem',
      align: 'center',
      render: function UserSettings(_, u) {
        if (tenantId) {
          return <a onClick={() => onClick('login', u)}>一键登录</a>
        }
      },
    },
    {
      title: '操作',
      key: 'actions',
      width: '6rem',
      align: 'center',
      render: function UserSettings(_, u): ReactElement {
        return <a onClick={() => onClick('setting', u)}>设置</a>
      },
    },
  ]
}
