/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-04-27 17:22:57
 * @LastEditors: linxi
 * @LastEditTime: 2024-10-08 14:26:34
 */
import {Button, Checkbox, Col, Modal, notification, Pagination, Row} from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import styles from '../putstorage/putstorage.module.css'
import {
  InventoryColumns,
  PrintInventoryColumns,
  PrintQingDouColumns,
  QingDouColumns,
} from './columns'
import { useDispatch, useSelector } from 'react-redux'
import { PlusOutlined } from '@ant-design/icons'
import {
  finishPutstorage,
  pagePutstorage,
  selectPutstorageData,
  selectTotal,
  selectCurrent,
  setCurrent,
  setCategory,
  savelistPutstorageAsync,
  rectPutstorageAsync,
  pagePutstorageAsync,
  checkSheetUploadAsync,
  purchaseUploadAsync,
  selectPageLoading,
  setPageLoading, multifunctionalUpAccountAsync, purchaseRepealAsync,
} from '../putstorage/putstorageSlice'
import { useHistory, useLocation } from 'react-router-dom'
import { InventoryCheckingQuery } from './query'
import { putStorePageParams } from '../../services/putstorage'
import { TableRowSelection } from 'antd/lib/table/interface'
import { NavBar } from '../../compnents/nav/NavBar'
import { BillName, CategoryName } from '../../models/category'
import { DataType } from '../putstorage/putstorage'
import { EditableList } from '../../compnents/list/EditableList'
import { RootDispatch } from '../../app/store'
import { unwrapResult } from '@reduxjs/toolkit'
import { getDepartment } from '../putstorelist/putstorelistSlice'
import { AccountOutlined, PrintOutlined } from '../../compnents/icons/Icons'
import { PrintFrame, PrintFrameRef } from '../print/PrintFrame'
import moment from 'moment'
import { strParse, medicalSendHttp } from '../../utils/MedicalUtils'
import {
  selectInsuranceArray,
  selectUserName,
  selectTenantAddress,
  selectTenantAreaCd,
  selectTenantName,
  selectUserId,
  selectTenantCategory,
} from '../../app/applicationSlice'
import { selectInsuranceItem, setInsurance } from '../../layouts/insuranceSlice'
import {
  DateTimeFormatSimple,
  DateTimeFormatSimpleOnlyOne,
} from '../../models/datetime'
import { serverType } from '../../services/commodity'
import { getincDataType } from '../../models/InventStock'
import { printBodyInventoryCheck } from '../../models/InventoryCheckPrint'
import {traceRoute} from "../../layouts/layoutSlice";

export const InventoryChecking = (props: {
  tockSalesUploadCategory?: string
  station?:any
}) => {
  const printRef = useRef<PrintFrameRef>(null)

  const history = useHistory()

  const location: any = useLocation()

  const [selectState, setSelect] = useState(false)

  const [size, setSize] = useState(10)

  const [Ids, setIds] = useState('')

  const [selectIds, setSelectIds] = useState<any>('')

  const [isModalVisible, setIsModalVisible] = useState(false)

  const [Id, setId] = useState(0)

  const [text, setText] = useState('')

  const [queries, setQueries] = useState<putStorePageParams>({ current: 0 })

  const [printData, setPrintData] = useState<any>([])

  const [selectionType, setSelectionType] = useState<'checkbox' | 'radio'>(
    'checkbox'
  )

  const data = useSelector(selectPutstorageData)

  const dispatch = useDispatch<RootDispatch>()

  const total = useSelector(selectTotal)

  const Current = useSelector(selectCurrent)

  const [local, setlocal] = useState<any>()

  const baseData = useSelector(selectInsuranceItem)

  const [insuranceItem, setInsuranceItem] = useState<any>()

  const insuranceArray = useSelector(selectInsuranceArray)

  const userName = useSelector(selectUserName)

  const tenantAddress = useSelector(selectTenantAddress)

  const tenantAreaCd = useSelector(selectTenantAreaCd)

  const tenantName = useSelector(selectTenantName)

  const userId = useSelector(selectUserId)

  const tenantCategory = useSelector(selectTenantCategory)

  const cycleCode = sessionStorage.getItem('cycleCode')

  const [fullAmountflag, setFullAmountflag] = useState(false)

  const pageLoadingRX = useSelector(selectPageLoading)

  const layout = {
    width: '18px',
    height: '18x',
    marginRight: '4px',
  }

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      if (selectedRows.length > 1) {
        setSelect(true)
      }
      setSelectIds(selectedRowKeys)
      setIds(selectedRowKeys.join(','))
    },
  }


  useEffect(() => {
    dispatch(setCurrent(1))
    setlocal(JSON.parse(localStorage.getItem('credentials') as string))

    const fullAmountInventoryFlag = localStorage.getItem('fullAmountInventoryFlag')
    if (fullAmountInventoryFlag && fullAmountInventoryFlag === 'true') {
      setFullAmountflag(true)
    }
  }, [])

  const getPage = () => {
    dispatch(
      pagePutstorage({
        ...queries,
        current: Current,
        size,
        storehouseTag: props?.tockSalesUploadCategory
          ? '4'
          : queries?.storehouseTag,
        category: props?.tockSalesUploadCategory || location?.state?.category,
      })
    )
    setTimeout(() => {
      dispatch(setPageLoading(false))
    }, 3000)
  }

  useEffect(() => {
    dispatch(setPageLoading(true))
    getPage()

    dispatch(
      setCategory(props.tockSalesUploadCategory || location.state.category)
    )
    if (queries?.current) {
      if (location?.state?.category == '8') {
        sessionStorage.setItem(
          location.pathname + '_' + location?.state?.category,
          JSON.stringify({
            ...queries,
            current: Current,
            size: size,
            category: location?.state?.category,
          })
        )
      }
    }
  }, [queries, Current, size])

  useEffect(() => {
    if (!props.tockSalesUploadCategory) {
      dispatch(
        pagePutstorageAsync({
          current: 1,
          size: 1000,
          category: location.state.category,
          storehouseTag: '1',
          recepitsDateStart: queries?.recepitsDateStart || undefined,
          recepitsDateEnd: queries?.recepitsDateEnd || undefined,
        })
      )
        .then(unwrapResult)
        .then((res: any) => {
          if (res.records.length === 0) {
            setPrintData(res.records)
          } else {
            if (location.state.category === '10') {
              setPrintData(res.records)
            } else {
              setPrintData([
                ...res.records,
                {
                  id: 0,
                  shouldPayMoney: res.records.reduce(function (
                    prev: any,
                    next: any
                  ) {
                    return prev + next.shouldPayMoney
                  },
                  2),
                  realPayMoney: res.records.reduce(function (
                    prev: any,
                    next: any
                  ) {
                    return prev + next.realPayMoney
                  },
                  2),
                  totalUnitPrice: res.records.reduce(function (
                    prev: any,
                    next: any
                  ) {
                    return prev + next.totalUnitPrice
                  },
                  2),
                  totalRetailPrice: res.records.reduce(function (
                    prev: any,
                    next: any
                  ) {
                    return prev + next.totalRetailPrice
                  },
                  0),
                },
              ])
            }
          }
        })
    }
  }, [queries, props.tockSalesUploadCategory])

  useEffect(() => {
    dispatch(getDepartment({ current: 1, size: 1000, state: 1 }))
  }, [])

  // 保存医保结构体
  useEffect(() => {
    if (insuranceItem && JSON.stringify(insuranceItem) !== '{}') {
      dispatch(
        setInsurance({
          ...insuranceItem,
          userName,
          userId,
          cycleCode,
          tenantCategory,
          tenantAreaCd,
          name: tenantName,
          address: tenantAddress,
          areaCode: tenantAreaCd,
          endTime: moment(insuranceItem.endTime).format(
            DateTimeFormatSimpleOnlyOne
          ),
        })
      )
    }
    return () => {
      dispatch(setInsurance({}))
    }
  }, [insuranceItem])

  useEffect(() => {
    if (insuranceArray.length !== 0 && props?.tockSalesUploadCategory) {
      setInsuranceItem(insuranceArray[0])
    }
  }, [insuranceArray])

  // 盘点单上传
  const checkSheetUpload = (
    serverType: serverType,
    inventoryReceiptsId: number,
    responseString?: string,
    inventoryReceiptsItemId?: string
  ) => {
    dispatch(
      checkSheetUploadAsync({
        serverType,
        body: {
          baseData: {
            ...baseData,
            responseString,
          },
          inventoryReceiptsId,
          inventoryReceiptsItemId: inventoryReceiptsItemId || undefined,
        },
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        if (serverType === 'requestString') {
          if(strParse(res)?.result == "no data to upload"){
            notification.success({
              message: '上传完毕。',
            })
            getPage()
          }else{
            const data = strParse(res)
            const itemId = data.inventoryReceiptsItemId
            medicalSendHttp(
              {
                trade_args: [
                  data.infno,
                  data.url,
                  data,
                  baseData?.countryHospitalNb,
                  baseData?.secretToken,
                ],
                message: '盘存单上传',
              },
              (call: any, err) => {
                !err &&
                checkSheetUpload(
                  'response',
                  inventoryReceiptsId,
                  JSON.stringify(call.netmessage),
                  itemId
                )
              }
            )
          }

        } else {
          if (res.successCode === '1') {
            checkSheetUpload('requestString', inventoryReceiptsId)
          } else {
            getPage()
          }
        }
      })
  }

  // 采购单 出库单 盘点 冲正上传
  const purchaseRepeal = (
    serverType: serverType,
    inventoryReceiptsId: number,
    responseString?: string,
    inventoryReceiptsItemId?: string
  ) => {
    dispatch(
      purchaseRepealAsync({
        serverType,
        body: {
          baseData: {
            ...baseData,
            responseString,
          },
          invDataType: getincDataType(props.tockSalesUploadCategory),
          inventoryReceiptsId,
          inventoryReceiptsItemId: inventoryReceiptsItemId || undefined,
        },
      })
    )
      .then(unwrapResult)
      .then((res) => {
        if (serverType === 'requestString') {
          const data = strParse(res)
          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                baseData?.countryHospitalNb,
                baseData?.secretToken,
              ],
              message: '冲正上传',
            },
            (call: any, err) => {
              !err &&
                purchaseRepeal(
                  'response',
                  inventoryReceiptsId,
                  JSON.stringify(call.netmessage)
                )
            }
          )
        } else {
          getPage()
        }
      })
  }

  const dataMainList = {
    tenantName: tenantName?.split('-')?.[0],
    userName: userName,
    dataList: printData?.map((v: any) => {
      return {
        ...v,
        createTime: v?.createTime
          ? moment(v?.createTime).format(DateTimeFormatSimple)
          : '',
        recepitsDate: v?.recepitsDate
          ? moment(v?.recepitsDate).format(DateTimeFormatSimple)
          : '',
        totalUnitPrice:
          v.totalUnitPrice !== -1
            ? parseFloat(v.totalUnitPrice).toFixed(2)
            : '0.00',
      }
    }),
  }

  const contentInventoryChecking = printBodyInventoryCheck(dataMainList)

  const massUpload = () => {
    if (selectIds?.length === 0){
      notification.info({
        message: '请选择上传的数据',
      })
    }
    if (selectIds?.length > 10) {
      notification.info({
        message: '一次最多上传10条',
      })
    } else {
      selectIds?.map((id: any) => {
        const item = (data.find((v:any)=> {return v.id===id})) as any

        if (item?.ybUploadFlag==='0'||item?.ybUploadFlag==='1'){
          checkSheetUpload('requestString', id)
        }

      })
    }
  }

  return (
    <div style={{ width: '100%', height: '100%' }}>
      {!props.tockSalesUploadCategory && (
        <Col
          style={{
            margin: '10px 20px',
          }}
        >
          <NavBar
            where={[
              '库存管理',
              CategoryName(
                props?.tockSalesUploadCategory || location?.state?.category
              ) as string,
            ]}
            backtrace={{
              name: '库房工作站',
              path: '/storage/station',
              state: {
                station: location.state?.station,
              },
              routeName: '库房工作站',
            }}
            buttons={
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                <Button
                  type='text'
                  icon={
                    <AccountOutlined
                      style={{
                        fill: '#666',
                        width: '16px',
                        height: '16px',
                        marginRight: '4px',
                      }}
                    />
                  }
                  onClick={() => {
                    if (!Ids) {
                      notification.error({
                        message: '请选择要登账的信息!',
                        duration: 3,
                      })
                    } else {
                      setText('登账')
                      setIsModalVisible(true)
                    }
                  }}
                  style={{
                    color: '#666',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  登账
                </Button>

                {/* <ReactToPrint
                trigger={() => (
                  <Button
                    type="text"
                    style={{
                      color: "#666",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <PrintOutlined
                      style={{
                        fill: "#666",
                        width: "18px",
                        height: "18px",
                        marginRight: "8px",
                      }}
                    />
                    打印
                  </Button>
                )}
                content={() => tableRef.current}
              /> */}
                <Button
                  type='text'
                  style={{
                    color: '#666',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  onClick={() => {
                    if (
                      (props?.tockSalesUploadCategory ||
                        location?.state?.category) === '8'
                    ) {
                      const page: any = window.open('', '_blank') // 打开一个新窗口，用于打印
                      page.document.write(contentInventoryChecking) // 写入打印页面的内容
                      page.print() // 打印
                      page?.close()
                    } else {
                      printRef.current?.print()
                    }
                  }}
                >
                  <PrintOutlined
                    style={{
                      fill: '#666',
                      width: '18px',
                      height: '18px',
                      marginRight: '4px',
                    }}
                  />
                  打印
                </Button>
                <Button
                  type='primary'
                  icon={<PlusOutlined />}
                  onClick={() => {
                    // if (data.every((v: any) => v.storehouseTag !== 0)) {
                    history.push({
                      pathname: '/debitOut',
                      state: {
                        category:
                          props?.tockSalesUploadCategory ||
                          location?.state?.category,
                      },
                    })
                    // }
                    // else{
                    //   notification.error({
                    //     message: "本库房有未登账记录",
                    //     duration: 3,
                    //   });
                    // }
                  }}
                >
                  新增
                </Button>
                {/* {(props?.tockSalesUploadCategory ||
                  location?.state?.category) === '8' ? (
                  <Button type='primary' style={{ marginLeft: '10px' }}>
                    导出
                  </Button>
                ) : (
                  ''
                )} */}
              </div>
            }
          />
        </Col>
      )}
      <div
        style={{
          height: props?.tockSalesUploadCategory
            ? 'calc(100% - 20px)'
            : 'calc(100% - 70px)',
          margin: props?.tockSalesUploadCategory ? '10px 0' : '10px 20px',
          padding: '20px',
          background: '#fff',
          borderRadius: '10px',
          position: 'relative',
        }}
      >
        <InventoryCheckingQuery
          YBUploadType={!!props.tockSalesUploadCategory}
          onValueChange={(v) => {
            setQueries({ ...queries, ...v })
          }}
          massUpload={() => massUpload()}
        />
        <div style={{ height: 'calc(100% - 92px)' }}>
          <EditableList
            style={{ height: '100%', overflowY: 'auto' }}
            loading={pageLoadingRX}
            pagination={false}
            page={{
              current: Current,
              size: size,
              total,
              items: data,
            }}
            onChangePage={(current, pageSize) => {
              setSize(pageSize as number)
              dispatch(setCurrent(current))
            }}
            columns={
              (props?.tockSalesUploadCategory || location?.state?.category) ===
                '8' ||
              (props?.tockSalesUploadCategory || location?.state?.category) ===
                '9'
                ? InventoryColumns(
                    (id, t) => {
                      switch (t) {
                        case '查看详情':
                          dispatch(
                            setCategory(
                              props?.tockSalesUploadCategory ||
                                location.state.category
                            )
                          )
                          history.push({
                            pathname:
                              (props?.tockSalesUploadCategory ||
                                location.state.category) === '9'
                                ? '/putlist'
                                : '/InventoryStock',
                            state: {
                              station:props?.station,
                              tockSalesUploadCategory:props?.tockSalesUploadCategory,
                              category:
                                props?.tockSalesUploadCategory ||
                                location.state.category,
                              selectId: id,
                            },
                          })
                          break
                        case '登账':
                          setIds(String(id))
                          setText('登账')
                          setIsModalVisible(true)
                          break
                        case '冲正':
                          setIds(String(id))
                          setText('冲正')
                          setIsModalVisible(true)
                          break
                        case '作废':
                          setId(id)
                          setText('作废')
                          setIsModalVisible(true)
                          break
                        case '盘点单上传':
                          checkSheetUpload('requestString', id)
                          break
                        case '盘点单冲正上传':
                          purchaseRepeal('requestString', id)
                          break
                        default:
                          break
                      }
                    },
                    size * (Current - 1),
                    props?.tockSalesUploadCategory
                  )
                : (props?.tockSalesUploadCategory ||
                    location.state.category) === '10'
                ? QingDouColumns((id, t) => {
                    switch (t) {
                      case '查看详情':
                        dispatch(
                          setCategory(
                            props?.tockSalesUploadCategory ||
                              location.state.category
                          )
                        )
                        history.push({
                          pathname: '/InventoryDetail',
                          state: {
                            category:
                              props?.tockSalesUploadCategory ||
                              location.state.category,
                            selectId: id,
                          },
                        })
                        break
                      case '登账':
                        setIds(String(id))
                        setText('登账')
                        setIsModalVisible(true)
                        break
                      case '冲正':
                        setIds(String(id))
                        setText('冲正')
                        setIsModalVisible(true)
                        break
                      case '作废':
                        setId(id)
                        setText('作废')
                        setIsModalVisible(true)
                        break
                      default:
                        break
                    }
                  }, size * (Current - 1))
                : []
            }
            rowSelection={
              {
                type: selectionType,
                ...rowSelection,
              } as TableRowSelection<any>
            }
            rowClassName={(record: any) => {
              return record.storehouseTag === 2 ? styles.gray : ''
            }}
            onRow={(d: any) => ({
              onDoubleClick: () => {
                history.push({
                  pathname:
                    (props?.tockSalesUploadCategory ||
                      location.state.category) === '10'
                      ? '/InventoryDetail'
                      : (props?.tockSalesUploadCategory ||
                          location.state.category) === '9'
                      ? '/putlist'
                      : '/InventoryStock',
                  state: {
                    station:props?.station,
                    tockSalesUploadCategory:props?.tockSalesUploadCategory,
                    category:
                      props?.tockSalesUploadCategory || location.state.category,
                    selectId: d.id,
                  },
                })
              },
            })}
          />
        </div>
        <Pagination
          total={total}
          current={Current}
          pageSize={size}
          showSizeChanger
          showQuickJumper
          pageSizeOptions={['10', '20', '50', '100', '500']}
          showTotal={(total) => `共 ${total} 条`}
          style={{
            position: 'absolute',
            bottom: '20px',
            right: '20px',
          }}
          onChange={(current, size) => {
            setSize(size as number)
            dispatch(setCurrent(current))
          }}
        />
      </div>
      <Modal
        title='信息'
        visible={isModalVisible}
        onCancel={() => {
          setIsModalVisible(false)
        }}
        okText='确认'
        cancelText='取消'
        footer={
          <Row>
            {location?.state?.category == 8 && text === '登账' && (//盘点
              <Col style={{display:'flex',alignItems:'center'}}>
                <Checkbox checked={fullAmountflag} onChange={(v) => {
                  const aFlag = v.target.checked
                  setFullAmountflag(aFlag)
                  localStorage.setItem('fullAmountInventoryFlag',String(aFlag))
                }}></Checkbox>
                &nbsp;&nbsp;同时保存没有变动的数量
              </Col>
            )}
            <Col flex={1}>
              <Button  onClick={() => {
                setIsModalVisible(false)
              }}>取消</Button>
              <Button danger={text === "冲正"} type={'primary'} onClick={() => {
                setIsModalVisible(false)
                if (text === '登账') {
                  dispatch(multifunctionalUpAccountAsync({tenantInventoryReceiptsIds:Ids,fullTakeStockFlag:fullAmountflag}))
                    .then(unwrapResult)
                    .then(() => {
                      notification.success({
                        message: '登账成功',
                        duration: 3,
                      })
                      dispatch(
                        pagePutstorage({
                          ...queries,
                          current: Current,
                          size,
                          category:
                            props?.tockSalesUploadCategory || location.state.category,
                        })
                      )
                    })
                    .catch(() => {
                      notification.error({
                        message: '登账失败',
                        duration: 3,
                      })
                    })
                } else if (text === '冲正') {
                  dispatch(rectPutstorageAsync(Ids))
                    .then(unwrapResult)
                    .then(() => {
                      notification.success({
                        message: '冲正成功',
                        duration: 3,
                      })
                      dispatch(
                        pagePutstorage({
                          ...queries,
                          current: Current,
                          size,
                          category:
                            props?.tockSalesUploadCategory || location.state.category,
                        })
                      )
                    })
                    .catch((e) => {
                      notification.error({
                        message: '冲正失败',
                        duration: 3,
                      })
                    })
                } else {
                  dispatch(finishPutstorage(Id))
                    .then(unwrapResult)
                    .then((v) => {
                      notification.success({
                        message: '作废成功',
                        duration: 3,
                      })
                      dispatch(
                        pagePutstorage({
                          ...queries,
                          current: Current,
                          size,
                          category:
                            props?.tockSalesUploadCategory || location.state.category,
                        })
                      )
                    })
                    .catch((e) => {
                      notification.error({
                        message: e.message,
                        duration: 3,
                      })
                    })
                }
              }}>{text === "冲正" ? '确认冲正' : '确认'}</Button>
            </Col>
          </Row>
        }
      >
        {text === "冲正" ? 
        <h3>冲正后该业务单据所产生的所有库存变动都会回退，请谨慎操作。</h3> 
        : <h3>是否{text}？</h3>
        }
        {text === '登账' ? (
          <p>请确认各项信息是否填写正确，{text}后将不可修改</p>
        ) : (
          <></>
        )}
      </Modal>
      <PrintFrame
        ref={printRef}
        title='库存业务单'
        subTitle={BillName(
          props?.tockSalesUploadCategory || location?.state?.category
        )}
      >
        <Row>
          <Col span={12}>单位：{local?.tenantName}</Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            金额：元
          </Col>
        </Row>
        <EditableList
          pagination={false}
          page={{
            current: Current,
            size: size,
            total,
            items: printData,
          }}
          onChangePage={(current, pageSize) => {
            setSize(pageSize as number)
            dispatch(setCurrent(current))
          }}
          columns={
            (props?.tockSalesUploadCategory || location?.state?.category) ===
            '9'
              ? PrintInventoryColumns(size * (Current - 1))
              : (props?.tockSalesUploadCategory ||
                  location?.state?.category) === '10'
              ? PrintQingDouColumns(size * (Current - 1))
              : []
          }
        />
        <Row style={{ lineHeight: '32px', marginTop: '20px' }}>
          <Col span={8}>单位负责人：</Col>
          <Col span={8}>仓管员：</Col>
          <Col span={8}>财务：</Col>
          <Col span={8}>审核：</Col>
          <Col span={8}>制单人：{local?.name}</Col>
          <Col span={8}>制单日期：{moment().format()}</Col>
        </Row>
      </PrintFrame>
    </div>
  )
}
