import { Button, Col, Form, Input, Row, Select, Space } from 'antd'
import React, { ReactElement, useEffect, useState } from 'react'
import { SearchOutlined, CaretDownFilled } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import {
  DateRangeType,
  DateSelect,
  getDateRange,
} from '../../../compnents/form/DateSelect'
import { ToBeCompletedTreatmentParams } from '../../../services/toBeCompletedTreatment'
import moment from 'moment'
import { DateTimeFormatSimpleOnly } from '../../../models/datetime'
import { unwrapResult } from '@reduxjs/toolkit'
import { RootDispatch } from '../../../app/store'
import {
  getLatelyOperatorList,
  getProjectList,
} from './toBeCompletedTreatmentSlice'

const { Option } = Select

export const ToBeCompletedTreatmentquery = (props: {
  dataList: any[]
  DoctorList: any[]
  onValueChange: (queries: ToBeCompletedTreatmentParams) => void
  onSelectIpt: (str: string) => void
  patientId?: string
  export:()=> void
}): ReactElement => {
  const [form] = Form.useForm()

  const dispatch = useDispatch<RootDispatch>()

  // const [data, setData] = useState<any[]>([])

  const dataParams = JSON.parse(
    sessionStorage.getItem(location.pathname) || '{}'
  )

  // useEffect(() => {
  //   dispatch(getProjectList({ state: 0 }))
  //     .then(unwrapResult)
  //     .then((res: any) => {
  //       setData(res)
  //     })
  // }, [])

  useEffect(() => {
    const [head, tail] = getDateRange(DateRangeType.Today)
    if (Object.keys(dataParams).length && !props.patientId) {
      form.setFieldsValue({
        ...dataParams,
        timeHead: dataParams?.startTime
          ? moment(dataParams?.startTime).format(DateTimeFormatSimpleOnly)
          : head,
        timeTail: dataParams?.endTime
          ? moment(dataParams?.endTime).format(DateTimeFormatSimpleOnly)
          : tail,
      })
    } else {
      form.setFieldsValue({
        timeHead: head,
        timeTail: tail,
      })
    }
    form.submit()
  }, [])

  const refresh = () => {
    const vs = form.getFieldsValue()
    props.onValueChange({
      ...dataParams,
      ...vs,
      state: 0,
      timeHead: null,
      timeTail: null,
      startTime: vs?.timeHead
        ? moment(vs?.timeHead).format(DateTimeFormatSimpleOnly)
        : undefined,
      endTime: vs?.timeTail
        ? moment(vs?.timeTail).format(DateTimeFormatSimpleOnly)
        : undefined,
      current: 1,
    })
  }

  return (
    <Form
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
      form={form}
      name='control-hooks'
      autoComplete='off'
      onFinish={(vs) => {
        props.onValueChange({
          current: 1,
          ...dataParams,
          state: 0,
          ...vs,
          timeHead: null,
          timeTail: null,
          startTime: vs?.timeHead
            ? moment(vs?.timeHead).format(DateTimeFormatSimpleOnly)
            : null,
          endTime: vs?.timeTail
            ? moment(vs?.timeTail).format(DateTimeFormatSimpleOnly)
            : null,
        })
      }}
    >
      <Row
        style={{ marginBottom: 10, width: '100%', height: '42px' }}
        align='middle'
        justify='space-between'
      >
        <Space>
          {!props.patientId && (
            <Form.Item name='keyword' noStyle>
              <Input
                size='middle'
                placeholder='患者姓名/手机号/助记码'
                allowClear
                onChange={refresh}
                prefix={<SearchOutlined />}
              />
            </Form.Item>
          )}
          <Form.Item name='treatmentServiceName' noStyle>
            <Select
              placeholder='项目'
              style={{ width: 150 }}
              allowClear
              onChange={refresh}
            >
              {props.dataList.map((v) => (
                <Option key={v.id} value={v.treatmentServiceName}>
                  {v.treatmentServiceName}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name='latelyOperatorId' noStyle>
            {/* <Select
              placeholder='请选择执行人'
              allowClear
              suffixIcon={<CaretDownFilled />}
              onChange={refresh}
            >
              {props.DoctorList.map((v: any) => (
                <Option key={v.latelyOperatorId} value={v.latelyOperatorId}>
                  {v.latelyOperatorName}
                </Option>
              ))}
            </Select> */}
            {/*<Select*/}
            {/*  style={{ width: 150 }}*/}
            {/*  showSearch*/}
            {/*  placeholder='执行人'*/}
            {/*  defaultActiveFirstOption={false}*/}
            {/*  showArrow={false}*/}
            {/*  allowClear*/}
            {/*  filterOption={false}*/}
            {/*  onSearch={(v: string) => props.onSelectIpt(v)}*/}
            {/*  onChange={() => refresh()}*/}
            {/*>*/}
            {/*  {props.DoctorList?.map((v: any) => (*/}
            {/*    <Select.Option*/}
            {/*      key={v.latelyOperatorId}*/}
            {/*      value={v.latelyOperatorId}*/}
            {/*    >*/}
            {/*      {v.latelyOperatorName}*/}
            {/*    </Select.Option>*/}
            {/*  ))}*/}
            {/*</Select>*/}
          </Form.Item>
          <Form.Item name='' noStyle>
            <DateSelect
              placeholder='时间范围'
              style={{ width: '11rem' }}
              onChange={refresh}
              initialValue={DateRangeType.Today}
              labelPrefix=''
              namePrefix='time'
              allowClear={false}
            />
          </Form.Item>
          <Button onClick={refresh} type='primary' style={{ float: 'right' }}>
            查询
          </Button>
        </Space>
        <Space>
          <Button
            onClick={() => {
              props?.export && props?.export()
            }}
            type='primary'
          >
            导出
          </Button>
        </Space>
      </Row>
    </Form>
  )
}
