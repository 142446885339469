import { Row, Form, Modal, Col, Radio, Select, notification } from 'antd'
import React, { ReactElement, useEffect, useState } from 'react'
import {} from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../app/store'
import { GenderOptions, getGenderName } from '../../models/user'
import {
  getSourceTypeName,
  RegistrationCategory,
  SourceTypeCategories,
} from '../../models/registration'
import {
  getDepartmentDoctorList,
  getDoctorDepartmentList,
  getDoctorList,
} from '../sales/DoctorAccont/doctorAuditSlice'
import { Department } from '../../models/department'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  getDoctorsInDepartment,
  getFullDeparmentList,
} from '../completion/completionSlice'
import styles from './Registration.module.css'
import { selectInsuranceArray } from '../../app/applicationSlice'
import { MedicelListValue } from '../../models/setting'
import { selectDoctors } from './list/registrationSlice'

export const PreferencesSettingModal = (props: {
  visible: boolean
  onOk: () => void
  onCancel: () => void
}): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const insuranceArray = useSelector(selectInsuranceArray)

  const [departments, setDepartments] = useState<Department[]>([])

  const [departmentIds, setDepartmentId] = useState<any>()

  const [doctorList, setDoctorList] = useState<any>([]) //医生数据

  const doctors = useSelector(selectDoctors)

  const [departmentDoctorRadio, setDepartmentDoctorRadio] = useState<any>(1)

  const [form] = Form.useForm()

  const { Option } = Select

  const localData: any = JSON.parse(
    localStorage.getItem('preferencesSettingData') || '{}'
  )
  ;('')



  useEffect(() => {
    if (props?.visible) {
      if (departmentIds || localData?.departmentId) {
        // dispatch(getDoctorsInDepartment(storedepartmentId))
        dispatch(
          getDoctorDepartmentList(departmentIds || localData?.departmentId)
        )
          .then(unwrapResult)
          .then((doctors: any) => {
            setDoctorList(doctors?.records)
          })
      } else {
        dispatch(getDoctorDepartmentList(''))
          .then(unwrapResult)
          .then((doctors: any) => {
            setDoctorList(doctors?.records)
          })
      }

      dispatch(getDepartmentDoctorList())
        .then(unwrapResult)
        .then((departments: any) => {
          setDepartments(departments?.records)
        })
    } else {
      // dispatch(getDoctorsInDepartment(''))
      localStorage.setItem(
        'preferencesSettingData',
        JSON.stringify({
          ...localData,
          sex: localData?.sex ? Number(localData?.sex) : 0,
          source: localData?.source ? Number(localData?.source) : 0,
          atDoctor: localData?.atDoctor?.toString()
            ? Number(localData?.atDoctor)
            : 1,
        })
      )
    }
  }, [props?.visible, departmentIds, localData?.departmentId])

  useEffect(() => {
    if (props?.visible) {
      setDepartmentDoctorRadio(
        localData?.atDoctor?.toString() ? Number(localData?.atDoctor) : 1
      )
      form.setFieldsValue({
        insuranceStatus: localData?.insuranceStatus
          ? localData?.insuranceStatus
          : 'ZIFEI',
        sex: localData?.sex ? Number(localData?.sex) : 0,
        source: localData?.source ? Number(localData?.source) : 0,
        atDoctor: localData?.atDoctor?.toString()
          ? Number(localData?.atDoctor)
          : 1,
        departmentId: localData?.departmentId
          ? localData?.departmentId
          : undefined,
        doctorId: localData?.doctorId ? localData?.doctorId : undefined,
      })
    }
  }, [props?.visible])

  return (
    <>
      <Modal
        title={`设置您的偏好`}
        width='500px'
        okText='确定'
        cancelText='取消'
        visible={props.visible}
        onOk={(e) => {
          form
            .validateFields()
            .then((values) => {
              localStorage.setItem(
                'preferencesSettingData',
                JSON.stringify({ ...values })
              )
            })
            .catch(() => {
              // Do nothing.
            })
          // form.resetFields()
          props.onOk()
          notification.success({
            message: '保存成功，将再下次进入系统时生效',
          })
        }}
        onCancel={() => {
          form.resetFields()
          props.onCancel()
        }}
      >
        <Form
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
          form={form}
        >
          <Row
            style={{ marginBottom: 10, width: '100%' }}
            align='middle'
            justify='space-between'
          >
            <Col span={24}>
              <div className={styles.title}>挂号类别 - 默认设置为：</div>
              <Col span={24} className={styles.main}>
                <Form.Item
                  label=''
                  name='insuranceStatus'
                  noStyle
                >
                  <Radio.Group>
                    <Radio
                      value={'ZIFEI'}
                      key={RegistrationCategory.SelfPay}
                      onClick={() => {
                        return
                      }}
                    >
                      自费
                    </Radio>
                    {insuranceArray.length > 0 &&
                      insuranceArray.map((v: any) => (
                        <Radio key={JSON.stringify(v)} value={v.insuranceCode}>
                          {v.insuranceName}
                        </Radio>
                      ))}
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Col>
            <Col span={24}>
              <div className={styles.title}>自费患者性别 - 默认为：</div>
              <Col span={24} className={styles.main}>
                <Form.Item label='' name='sex'  noStyle>
                  <Radio.Group>
                    {GenderOptions?.map((g) => (
                      <Radio key={g} value={g}>
                        {getGenderName(g)}
                      </Radio>
                    ))}
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Col>
            <Col span={24}>
              <div className={styles.title}>患者来源 - 默认为：</div>
              <Col span={24} className={styles.main}>
                <Form.Item label='' name='source' noStyle>
                  <Select
                    placeholder='患者来源'
                    allowClear
                    style={{ width: '240px' }}
                  >
                    {SourceTypeCategories.map((v) => {
                      return (
                        <Option value={v} key={v}>
                          {getSourceTypeName(v)}
                        </Option>
                      )
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Col>
            <Col span={24}>
              <div className={styles.title}>科室医生-选择一个默认项</div>
              <Col span={24} className={styles.main}>
                <Form.Item label='' name='atDoctor'  noStyle>
                  <Radio.Group
                    onChange={(v: any) => {
                      setDepartmentDoctorRadio(v?.target?.value)
                    }}
                  >
                    <Radio value={0} key={0}>
                      默认空
                    </Radio>
                    <Radio key={1} value={1}>
                      默认选中上一次挂号医生
                    </Radio>
                    <Radio value={2} key={2}>
                      选中特定医生
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              {departmentDoctorRadio == 2 && (
                <Row>
                  <Col
                    span={8}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Form.Item label='' name='departmentId' noStyle>
                      <Select
                        placeholder='科室'
                        style={{ width: '8rem' }}
                        allowClear
                        onChange={(v) => {
                          setDepartmentId(v)
                          form.setFieldsValue({
                            doctorId: '',
                          })
                          form.submit()
                        }}
                        showSearch
                      >
                        {departments?.map((d) => (
                          <Select.Option key={d.id} value={d.id?.toString()}>
                            {d.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label='' name='doctorId' noStyle>
                      <Select
                        placeholder='医生'
                        style={{ width: '8rem' }}
                        allowClear
                        onChange={() => {
                          form.submit()
                        }}
                        showSearch
                      >
                        {doctorList?.map((d: any) => (
                          <Select.Option key={d.id} value={d.id?.toString()}>
                            {d.realname}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  )
}
