import { unwrapResult } from '@reduxjs/toolkit'
import { Col, notification } from 'antd'
import React, {
  forwardRef,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useDispatch } from 'react-redux'
import { RootDispatch } from '../../app/store'
import { EditableList } from '../../compnents/list/EditableList'
import { Columns } from './columns'
import { Query } from './Query'
import { ThemeContext } from '../../theme/ThemeContext'
import { AddDrugModal } from './addDrug/addDrugModal'
import { getDeleteDrugWarning, getDrugWarningList } from './drugWarningSlice'

const DrugWarning = (): ReactElement => {
  const theme = useContext(ThemeContext)

  const dispatch = useDispatch<RootDispatch>()

  const [scrollHeight, setScrollHeight] = useState<any>()

  const [pageLoading, setPageLoading] = useState(false)

  const [isAddDrugModalVisible, setIsAddDrugModalVisible] = useState(false)

  const [id, setId] = useState<any>()

  const [params, setParams] = useState<any>({
    current: 1,
    size: 10,
  })

  const [page, setPage] = useState<any>()

  useEffect(() => {
    setPageLoading(true)
    getPage()
  }, [params])

  const getPage = () => {
    dispatch(getDrugWarningList({ ...params, selectedTab: undefined }))
      .then(unwrapResult)
      .then((v: any) => {
        onResize() // 初始化设置高度
        window.addEventListener('resize', onResize)
        setPage({
          items: v?.records,
          size: v?.size,
          total: v?.total,
          current: v?.current,
        })
        setPageLoading(false)
      })
  }

  const onResize = () => {
    const table = document.getElementById('tableHeights')
    if (table) {
      const height = table.offsetHeight - 60 - 80 - 20
      setScrollHeight(height)
    } else {
      return '100%'
    }
  }
  return (
    <>
      <Col
        style={{
          backgroundColor: theme.pn,
          borderRadius: 10,
          display: 'flex',
          flexDirection: 'column',
          height: 'calc(100% - 10px)',
          margin: '10px 0px',
          overflow: 'hidden',
          padding: '10px 15px 0 15px',
        }}
      >
        <Query
          onValueChange={(v: any) => {
            const paramData = {
              ...params,
              ...v,
              current: 1,
            }
            setParams({
              ...paramData,
            })
          }}
          addDrug={() => {
            setIsAddDrugModalVisible(true)
          }}
        />
        <div
          id='tableHeights'
          style={{
            width: '100%',
            height: '100%',
          }}
        >
          <EditableList
            scroll={{
              y: Number(scrollHeight),
              x: 1000,
            }}
            style={{
              width: '100%',
              height: '89%',
              overflow: 'hidden',
            }}
            rowKey={(m: any) => m.paymentId}
            loading={pageLoading}
            page={page}
            columns={Columns(
              page?.size * (page?.current - 1),
              (text: any, t: any) => {
                if (text == 'delete') {
                  dispatch(getDeleteDrugWarning({ id: t?.id }))
                    .then(unwrapResult)
                    .then((v: any) => {
                      setParams({
                        current: 1,
                        size: 10,
                      })
                    })
                }
              }
            )}
            onChangePage={(current, size) =>
              setParams({
                ...params,
                current,
                size: size || 10,
              })
            }
            onRow={(res) => ({
              onDoubleClick: () => {
                setId(res?.id)
                setIsAddDrugModalVisible(true)
              },
            })}
          />
        </div>
      </Col>
      <AddDrugModal
        id={id}
        visible={isAddDrugModalVisible}
        onOk={() => {
          setIsAddDrugModalVisible(false)
          getPage()
          setId('')
        }}
        onCancel={() => {
          setIsAddDrugModalVisible(false)
          getPage()
          setId('')
        }}
      ></AddDrugModal>
    </>
  )
}

export const DrugWarningList = forwardRef(DrugWarning)
