/*
 * @Descripttion:
 * @version:
 * @Author: sujun
 * @email: 1373842098@qq.com
 * @Date: 2021-12-31 11:05:42
 * @LastEditors: linxi
 * @LastEditTime: 2023-03-09 18:06:29
 */
import { TableColumnType, Button, Popconfirm } from 'antd'
import React from 'react'
import { ReactElement } from 'react'
import { getUploadStatus } from '../../../models/OReplaceMakeSlice'

export const Columns = (onClick: (type: 'upload' | 'cancelUpload', tenant: any) => void, startIndex: number,  loading?: any,selectId?:any): TableColumnType<any>[] => {
  return [
    {
      title: '序号',
      key: 'no',
      align: 'center',
      width: '3rem',
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
    {
      title: '门诊号',
      align: 'center',
      dataIndex: 'outpatientNum',
    },
    {
      title: '患者姓名',
      align: 'center',
      dataIndex: 'recipientName',
    },
    {
      title: '处方金额',
      align: 'center',
      dataIndex: 'money',
    },
    {
      title: '代煎成本价',
      align: 'center',
      dataIndex: 'totalPriceRatio',
      render: (_, t) => (t.totalPriceRatio && t.totalPriceRatio > 0) ? t.totalPriceRatio.toFixed(2) : '-'
    },
    {
      title: '药方金额',
      align: 'center',
      key: 'drugMoney',
      render: (_, t) => t.drugMoney.toFixed(2)
    },
    {
      title: '代煎饮片厂',
      align: 'center',
      dataIndex: 'supplierName',
    },
    {
      title: '代煎类别',
      align: 'center',
      dataIndex: 'typeName',
    },
    {
      title: '上传状态',
      align: 'center',
      key: 'status',
      render: (_, t) => (
        t.type === 1 ? getUploadStatus(t.status) : '-'
      )
    },
    {
      title: '结算状态',
      align: 'center',
      key: 'state',
      render: (_, t) => (
        t.state === 5 ? '已退费' : '已结算'
      )
    },
    {
      title: '开单时间',
      align: 'center',
      dataIndex: 'createTime',
      key: 'createTime',
    },
    {
      title: '上传时间',
      align: 'center',
      key: 'uploadTime',
      render: (_, t) => (
        t.type === 1 ? t.uploadTime : '-'
      )
    },

    {
      title: '操作',
      key: 'action',
      align: 'center',
      width: '10em',
      render: function actoin(_, t) {
        if (t.type === 1 && t.state !== 5) {
          if (t.status === 0 || t.status === 3 || t.status === 2) {
            return (
              <Button
                type='primary'
                loading={selectId == t?.id ? loading : false}
                onClick={() => {
                  onClick('upload', t)
                }}
              >
                上传
              </Button>
            )
          } else if (t.status === 1) {
            return (
              <Popconfirm
                placement='top'
                title={'是否取消上传'}
                onConfirm={() => {
                  onClick('cancelUpload', t)
                }}
                okText='确认'
                cancelText='取消'
              >
                <Button type='primary'>取消上传</Button>
              </Popconfirm>
            )
          }
        }
      },
    },
  ]
}
