/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-04-09 20:47:13
 * @LastEditors: linxi
 * @LastEditTime: 2024-09-18 15:40:44
 */
import { CaretDownFilled } from '@ant-design/icons'
import { DatePicker, Form, Input, Select } from 'antd'
import moment from 'moment'
import React, { ReactElement, useContext, useEffect } from 'react'
import { DateTimeFormat } from '../../models/datetime'
import { ThemeContext } from '../../theme/ThemeContext'

export enum DateRangeType {
  Today = 0,
  Yesterday = 1,
  ThisWeek = 2,
  ThisMonth = 3,
  NextMonth = 4,
  ThisYear = 5,
  Range = 6,
  Auth = 7,
  Invaild = 8,
  RencentMunth = 9,
  RecentQuarter = 10,
  HalfAYear = 11,
  MoreYear = 12,
  Tomorrow = 13,
  RecentSevenDays = 14,
  RecentHalfAYear = 15,
  RecentThreeMonth = 16,
  ThreeMonths = 17,
  LastMonth = 18,
  NextYear = 19,
}
const DateRangeTypes = [
  DateRangeType.Today,
  DateRangeType.Yesterday,
  DateRangeType.ThisWeek,
  DateRangeType.ThisMonth,
  DateRangeType.ThisYear,
  DateRangeType.Range,
]

const DateRangeTypeNames = [
  '今天',
  '昨天',
  '本周',
  '本月',
  '下月',
  '今年',
  '自选',
  '全部',
  '已失效',
  '近一个月',
  '近三个月',
  '近六个月',
  '大于六个月',
  '明天',
  '近七天',
  '近半年',
  '近三个月',
  '近三个月',
  '上月',
  '明年',
]

export const RecentTimes = [
  DateRangeType.Auth,
  DateRangeType.Invaild,
  DateRangeType.RencentMunth,
  DateRangeType.RecentQuarter,
  DateRangeType.HalfAYear,
  DateRangeType.MoreYear,
]

export const RecentTimesRealThreeMonths = [
  DateRangeType.Auth,
  DateRangeType.Invaild,
  DateRangeType.RencentMunth,
  DateRangeType.ThreeMonths,
  DateRangeType.HalfAYear,
  DateRangeType.MoreYear,
]
export const RecentTime = [
  DateRangeType.Today,
  DateRangeType.ThisWeek,
  DateRangeType.ThisMonth,
  DateRangeType.Range,
]

export const DateRangeTypeNotInvoicedQuery = [
  DateRangeType.Today,
  DateRangeType.Yesterday,
  DateRangeType.ThisWeek,
  DateRangeType.ThisMonth,
  DateRangeType.Range,
]


export const TJFXTime = [
  DateRangeType.Today,
  DateRangeType.Yesterday,
  DateRangeType.ThisWeek,
  DateRangeType.ThisMonth,
  DateRangeType.LastMonth,
  DateRangeType.ThisYear,
  DateRangeType.Range,
]
export const ToothDay = [
  DateRangeType.Today,
  DateRangeType.Tomorrow,
  DateRangeType.ThisWeek,
  DateRangeType.ThisMonth,
  DateRangeType.ThisYear,
  DateRangeType.Range,
]
export const FollowUpDay = [
  DateRangeType.Today,
  DateRangeType.Tomorrow,
  DateRangeType.ThisWeek,
  DateRangeType.ThisMonth,
  DateRangeType.NextMonth,
  DateRangeType.ThisYear,
  DateRangeType.NextYear,
  DateRangeType.Range,
]
export const HistoryTimeSelect = [
  DateRangeType.Today,
  DateRangeType.Yesterday,
  DateRangeType.ThisWeek,
  DateRangeType.ThisMonth,
]

export const DateRangeChargeHZGLTypes = [
  DateRangeType.Auth,
  DateRangeType.Today,
  DateRangeType.Yesterday,
  DateRangeType.ThisWeek,
  DateRangeType.ThisMonth,
  DateRangeType.ThisYear,
  DateRangeType.RecentSevenDays,
  DateRangeType.RecentHalfAYear,
  DateRangeType.Range,
]
export const DateRangeChargeTypes = [
  DateRangeType.Today,
  DateRangeType.Yesterday,
  DateRangeType.ThisWeek,
  DateRangeType.ThisMonth,
  DateRangeType.ThisYear,
  DateRangeType.RecentSevenDays,
  DateRangeType.RecentHalfAYear,
  DateRangeType.Range,
]

export const DateRangeDrugStaticTypes = [
  DateRangeType.Today,
  DateRangeType.Yesterday,
  DateRangeType.ThisWeek,
  DateRangeType.ThisMonth,
  DateRangeType.LastMonth,
  DateRangeType.RecentThreeMonth,
  DateRangeType.Range,
]
interface DateSelectProps {
  defaultTimeFlag?: any
  labelPrefix: string
  namePrefix: string
  placeholder?: string
  disabledValue?: any
  disabledDate?:any
  value?: DateRangeType
  onChange?: (value?: DateRangeType) => void
  onCalendarChange?: (value?: DateRangeType) => void
  options?: DateRangeType[]
  initialValue?: DateRangeType
  style?: React.CSSProperties
  allowClear?: boolean
  onlyTime?: string
}

export function getDateRange(
  type?: DateRangeType
): [string | undefined, string | undefined] {
  let head = undefined
  let tail = undefined
  switch (type) {
    case DateRangeType.Today:
      head = moment().startOf('day').format(DateTimeFormat)
      tail = moment().endOf('day').format(DateTimeFormat)
      break

    case DateRangeType.Yesterday:
      head = moment().subtract(1, 'day').startOf('day').format(DateTimeFormat)
      tail = moment().subtract(1, 'day').endOf('day').format(DateTimeFormat)
      break
    case DateRangeType.ThisWeek:
      head = moment().startOf('week').format(DateTimeFormat)
      tail = moment().endOf('week').format(DateTimeFormat)
      break
    case DateRangeType.ThisMonth:
      head = moment().startOf('month').format(DateTimeFormat)
      tail = moment().endOf('month').format(DateTimeFormat)
      break
      case DateRangeType.LastMonth:
        head = moment().subtract(1,'month').startOf('month').format(DateTimeFormat)
        tail = moment().subtract(1,'month').endOf('month').format(DateTimeFormat)
        break
    case DateRangeType.NextMonth:
      head = moment().add(1, 'month').startOf('month').format(DateTimeFormat)
      tail = moment().add(1, 'month').endOf('month').format(DateTimeFormat)
      break
    case DateRangeType.ThisYear:
      head = moment().startOf('year').format(DateTimeFormat)
      tail = moment().endOf('year').format(DateTimeFormat)
      break
    case DateRangeType.NextYear:
      head = moment().add(1, 'year').startOf('year').format(DateTimeFormat)
      tail = moment().add(1, 'year').endOf('year').format(DateTimeFormat)
      break
    case DateRangeType.Auth:
      head = undefined
      tail = undefined
      break
    case DateRangeType.Invaild:
      head = undefined
      tail = moment().format(DateTimeFormat)
      break
    case DateRangeType.RencentMunth:
      head = moment().format(DateTimeFormat)
      tail = moment().add(30, 'days').endOf('days').format(DateTimeFormat)
      break
    case DateRangeType.RecentQuarter:
      head = moment().format(DateTimeFormat)
      tail = moment().add(90, 'days').endOf('days').format(DateTimeFormat)
      break
    case DateRangeType.RecentThreeMonth:
      head = moment().subtract(3, 'month').endOf('month').format(DateTimeFormat)
      tail = moment().endOf('days').format(DateTimeFormat)
      break
    case DateRangeType.HalfAYear:
      head = moment().format(DateTimeFormat)
      tail = moment().add(180, 'days').endOf('days').format(DateTimeFormat)
      break
    case DateRangeType.ThreeMonths:
      head = moment().format(DateTimeFormat)
      tail = moment().add(3, 'month').endOf('days').format(DateTimeFormat)
      break
    case DateRangeType.MoreYear:
      head = moment().add(6, 'month').endOf('month').format(DateTimeFormat)
      tail = undefined
      break
    case DateRangeType.Tomorrow:
      head = moment().add(1, 'days').startOf('day').format(DateTimeFormat)
      tail = moment().add(1, 'days').endOf('day').format(DateTimeFormat)
      break
    case DateRangeType.RecentSevenDays:
      head = moment().subtract(6, 'days').startOf('day').format(DateTimeFormat)
      tail = moment().endOf('days').format(DateTimeFormat)
      break
    case DateRangeType.RecentHalfAYear:
      head = moment().subtract(6, 'month').endOf('month').format(DateTimeFormat)
      tail = moment().endOf('days').format(DateTimeFormat)
      break
  }
  return [head, tail]
}

export const DateRangeFieldName = '_dateRange'

export const DateSelect = ({
  defaultTimeFlag,
  labelPrefix,
  namePrefix,
  placeholder,
  disabledValue,
  disabledDate,
  value,
  onChange,
  onCalendarChange,
  options = DateRangeTypes,
  initialValue,
  allowClear = true,
  onlyTime = '', // 同一页面有两个时间选择框使用
  ...othersProps
}: DateSelectProps): ReactElement => {
  const theme = useContext(ThemeContext)

  const onlyName = onlyTime + DateRangeFieldName

  const headName = `${namePrefix}Head`
  const tailName = `${namePrefix}Tail`
  const select = (
    setFieldsValue: (values: Record<string, unknown>) => void,
    type?: DateRangeType
  ) => {
    const [head, tail] = getDateRange(type)
    setFieldsValue({
      [headName]: head,
      [tailName]: tail,
    })

    onChange && onChange(type)
  }

  return (
    <>
      <Form.Item style={{ display: 'none' }} name={headName}>
        <Input type='hidden' disabled={disabledValue}/>
      </Form.Item>
      <Form.Item style={{ display: 'none' }} name={tailName}>
        <Input type='hidden' disabled={disabledValue}/>
      </Form.Item>
      <Form.Item
        noStyle
        shouldUpdate={(pv, cv) => pv[onlyName] !== cv[onlyName]}
      >
        {({ setFieldsValue }) => (
          <Form.Item noStyle name={onlyName} initialValue={initialValue}>
            <Select
            disabled={disabledValue}
              {...othersProps}
              value={value}
              placeholder={placeholder}
              allowClear={allowClear}
              // suffixIcon={<CaretDownFilled style={{ color: theme.tc3 }} />}
              onChange={(v) => {
                select(setFieldsValue, v)
              }}
            >
              {options.map((t) => (
                <Select.Option key={t} value={t}>
                  {`${labelPrefix}${DateRangeTypeNames[t]}`}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
      </Form.Item>
      <Form.Item noStyle dependencies={[onlyName]}>
        {({ getFieldValue, setFieldsValue }) => {
          return getFieldValue(onlyName) === DateRangeType.Range ? (
            <Form.Item noStyle>
              <DatePicker.RangePicker
              onCalendarChange={(ts:any) => {
                onCalendarChange && onCalendarChange(ts)
            }}
              disabledDate ={disabledDate}
               disabled={disabledValue}
                allowClear={defaultTimeFlag ? false : true}
                style={{ marginLeft: '8px' }}
                defaultValue={
                  defaultTimeFlag && [
                    getFieldValue(headName)
                      ? moment(getFieldValue(headName))
                      : moment().startOf('day'),
                    getFieldValue(tailName)
                      ? moment(getFieldValue(tailName))
                      : moment().endOf('day'),
                  ]
                }
                onChange={(ts) => {
                    setFieldsValue({
                      [headName]: ts?.[0]
                        ?.startOf('day')
                        ?.format(DateTimeFormat),
                      [tailName]: ts?.[1]?.endOf('day')?.format(DateTimeFormat),
                    })
                  onChange && onChange(value)
                }}
              />
            </Form.Item>
          ) : undefined
        }}
      </Form.Item>
    </>
  )
}
