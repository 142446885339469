import {
  CaretDownFilled,
  CheckCircleFilled,
  CheckCircleOutlined,
  CloseOutlined,
  ExclamationCircleFilled,
  SmileOutlined,
} from '@ant-design/icons'
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  message,
  Modal,
  notification,
  Radio,
  Row,
  Select,
  Space,
  Table,
  Tooltip,
} from 'antd'
import React, { useState, useEffect, useRef, useContext } from 'react'
import { PaymentConfirmation } from '../../../../models/confirmation'
import {
  PaymentConfirm,
  PaymentConfirmRef,
} from '../../../payment/confirm/PaymentConfirm'
import { SettlementModal } from './settlementModal'
import styles from '../ToothHome.module.css'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../../../app/store'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  getAllDoctorList,
  getCounselor,
  getRegistrationDetail,
  getTransferTreatment,
} from '../toothHomeSlice'
import {
  getDepartments,
  selectDepartments,
} from '../../../payment/list/paymentSlice'
import { getSexData } from '../../../../models/ToothHome'
import { FastInput } from '../../../treatment/tcmCases/fastInput'
import {
  getAcceptsType,
  getFeeList,
  getMedicalNum,
  getOutpatientDepartment,
  getPatientInfoAsync,
  getReceiveTagOfOralAsync,
  medicalRegisterAsync,
  register,
  registerValue,
  searchPsnSumAsync,
  searchQueryPsnAsync,
  selectDoctors,
  selectFeeList,
  setAppointmentIdSlice,
  updateInsuranceCardInfoAsync,
} from '../../../registration/register/registerSlice'
import * as P from '../../../../models/patient'
import * as R from '../../../../models/registration'
import { Fee } from '../../../../models/fee'
import {
  otherPatientInsuranceItemParams,
  PaymentRegistrationParams,
} from '../../../../models/payment'
import {
  FeeListUpAsync,
  pay,
  preSettlementAsync,
  saveSettlementAsync,
} from '../../../payment/confirm/paymentConfirmSlice'
import {
  medicalSendHttp,
  readCardEHttp,
  readCardSHttp,
  strParse,
} from '../../../../utils/MedicalUtils'
import {
  selectInsuranceItem,
  setInsurance,
} from '../../../../layouts/insuranceSlice'
import { serverType } from '../../../../services/commodity'
import { PrintTemplateModal } from '../../../print/PrintTemplateModal'
import { PrintTemplateCategory } from '../../../../models/print'
import { setSettings } from '../../../settings/settingsSlice'
import { Settings } from '../../../../models/setting'
import { ThemeContext } from '../../../../theme/ThemeContext'
import { getTradeCode } from '../../../../models/material'
import {
  selectInsuranceArray,
  selectTenantAddress,
  selectTenantAreaCd,
  selectTenantCategory,
  selectTenantName,
  selectUserId,
  selectUserName,
} from '../../../../app/applicationSlice'
import moment from 'moment'
import {
  DateTimeFormatSimple,
  DateTimeFormatSimpleOnlyOne,
} from '../../../../models/datetime'
import {
  BoyIcon,
  DoctorIcon,
  FemaleFilled,
  GirlIcon,
  MaleFilled,
} from '../../../../compnents/icons/Icons'
import { getAge } from '../../../../utils/StringUtils'
import { DiseaseInput } from '../../../../compnents/input/DiseaseInput'
import {
  getTreatmentCategoryName,
  TreatmentCategories,
  TreatmentNotice,
} from '../../../../models/treatment'
import { Disease } from '../../../../models/disease'
import { setTreatmentId } from '../../../retail/retailSlice'
import { getYBTypeName } from '../../../../models/ybType'
import { getTreatmentNotice } from '../../../treatment/editor/recipeListEditorSlice'

interface RegisteredModalProps {
  cardType?: any //读卡类型
  title: number //0挂号  1转诊
  patientId?: string //患者 ID
  appointmentId?: string //预约 ID
  registrationId?: string //挂号 ID
  visible: boolean
  onOk: () => void
  onCancel: () => void
}
export const RegisteredModal = ({
  cardType,
  title,
  patientId,
  appointmentId,
  registrationId,
  visible,
  onOk,
  onCancel,
}: RegisteredModalProps) => {
  const dispatch = useDispatch<RootDispatch>()

  const theme = useContext(ThemeContext)

  const [form] = Form.useForm()

  const readCardRef = useRef<any>(null)

  const [modalBtnLoading, setModalBtnLoading] = useState(false) //  弹窗确定按钮 loading

  const [registeredType, setRegisteredType] = useState(1) //自费 or 浙江医保

  const [counselorList, setCounselorList] = useState<any[]>() //咨询师数据

  const departments = useSelector(selectDepartments) // 医生科室数据

  const [doctorList, setDoctorList] = useState<any>([]) //  医生数据

  const [doctorStyle, setDoctorStyle] = useState<any>({}) //医生选中状态

  const [settlementModal, setTettlementModal] = useState(false) //结算弹窗

  const [modalDeatail, setModalDetail] = useState<any>({}) //  弹窗详情

  const fees = useSelector(selectFeeList)

  const [ybJiuzhenid, setYbJiuzhenid] = useState('')

  const [treatmentNum, setReatmentNum] = useState<any>()

  const [patientInsurance, setPatientInsurance] = useState<any>({})

  const [
    otherPatientInsuranceItem,
    setOtherPatientInsuranceItem,
  ] = useState<otherPatientInsuranceItemParams>({})

  const [fullCardInfo, setFullCardInfo] = useState<string>()

  const [insuranceItem, setInsuranceItem] = useState<any>()
  const [isDiseaseEmpty, setIsDiseaseEmpty] = useState(false)
  const [selectedDiseases, setSelectedDiseases] = useState<Disease[]>([])

  const [registerData, setRegisterData] = useState<registerValue>()

  const [modalRegistrationId, setModalRegistrationId] = useState('')

  const [isHisCode, setIsHisCode] = useState(false)

  const [hasCard, setHasCard] = useState(false)

  const [TriageDetailId, setTriageDetailId] = useState('')

  const [sumList, setSumList] = useState<any>()

  const [isModalVisible, setIsModalVisible] = useState(false)

  const [showStopAction, setShowStopAction] = useState(false)

  const baseData = useSelector(selectInsuranceItem)

  const [readLoading, setReadLoading] = useState(false)

  const doctors = useSelector(selectDoctors)

  const [source, setSource] = useState(0)

  const [confirmation, setConfirmation] = useState<
    PaymentConfirmation | undefined
  >(undefined)

  const ref = useRef<PaymentConfirmRef>(null)

  const [isInsurance, setIsInsurance] = useState(false)

  const tenantAreaCd = useSelector(selectTenantAreaCd)

  const userId = useSelector(selectUserId)

  const userName = useSelector(selectUserName)

  const cycleCode = sessionStorage.getItem('cycleCode')

  const tenantCategory = useSelector(selectTenantCategory)

  const tenantName = useSelector(selectTenantName)

  const tenantAddress = useSelector(selectTenantAddress)

  const insuranceArray = useSelector(selectInsuranceArray)

  const [sumVisible, setSumVisible] = useState(false)

  const [loading, setLoading] = useState(false)

  const [notice, setNotice] = useState<TreatmentNotice | undefined>()

  const getCounselorFn = () => {
    //获取咨询师
    dispatch(
      getCounselor({
        current: 1,
        size: 100,
        stationCategory: 4,
      })
    )
      .then(unwrapResult)
      .then(({ records }) =>
        setCounselorList(
          records
            .filter((v: any) => v.kssxbz != 1)
            .filter((v: any) => v.state == 1)
        )
      )
  }

  const getType = () => {
    //获取就诊类型
    dispatch(
      getReceiveTagOfOralAsync({
        patientId: patientId,
      })
    )
      .then(unwrapResult)
      .then((res) => {
        //标记为"有挂号记录的默认复诊"时，去查一下患者有无挂号记录
        form.setFieldsValue({
          receiveTag: res == 2 ? '1' : '0',
        })
      })
  }

  const getAllDoctor = (id?: string) => {
    //获取医生数据
    dispatch(
      getAllDoctorList({
        size: 1000,
        state: 1,
        stationCategory: 1,
        outpatientDepartmentId: id ? id : undefined,
      })
    )
      .then(unwrapResult)
      .then(({ records }) => {
        if (!records.some((v: any) => v.id == doctorStyle.id) && id) {
          //医生不在科室下方清除医生
          setDoctorStyle({})
        }
        setDoctorList(records.filter((v: any) => v.kssxbz != 1)) // 筛选已注销科室的医生
      })
  }

  const getDetail = () => {
    //获取详情信息
    dispatch(
      getRegistrationDetail({
        appointmentId,
        patientId,
        registrationId,
        removePatientInsurance: cardType ? null : '1',
      })
    )
      .then(unwrapResult)
      .then((res) => {
        setModalDetail(res)
      })
  }

  const onSubmit = (
    //挂号保存
    values: Record<string, unknown>,
    params?: PaymentRegistrationParams
  ) => {
    setModalBtnLoading(true)
    const patient = {
      ...P.fromJson(values),
      id: patientId || '',
      name: modalDeatail.patientName,
      sex: modalDeatail.patientSex,
      ageMonth: modalDeatail.ageMonth == -1 ? 0 : Number(modalDeatail.ageMonth),
      birthday: modalDeatail.birthday,
      ageYear: Number(modalDeatail.patientAge),
      phone: modalDeatail.patientPhone,
      source: modalDeatail.source,
    }
    const fee = fees.find((f) => f.id === values.registrationFeeId) as Fee
    const tempObj = counselorList?.find(
      (v: any) => v.id == values.consultDoctorId
    )

    const registration = {
      ...R.fromJson({
        ...values,
        ybJiuzhenid: values.ybJiuzhenid || ybJiuzhenid,
        appointmentId: appointmentId || undefined,
        treatmentNo: treatmentNum,
        receiveInAdvance: values.receiveInAdvance ? 1 : 0,
        registrationDoctorId: doctorStyle.id,
        registrationDoctorName: doctorStyle?.realname,
        treatmentDoctorId: doctorStyle.id,
        treatmentDoctorName: doctorStyle?.realname,
        ageYear: Number(modalDeatail.patientAge),
        name: modalDeatail.patientName,
        sex: modalDeatail.patientSex,
        ageMonth:
          modalDeatail.ageMonth == -1 ? 0 : Number(modalDeatail.ageMonth),
        birthday: modalDeatail.birthday,
        reason: values.reason,
        consultDoctorId: values.consultDoctorId,
        consultDoctorName: tempObj?.realname,
        phone: modalDeatail.patientPhone,
        patientId: modalDeatail.patientId,
        patientName: modalDeatail.patientName,
        patientPhone: modalDeatail.patientPhone,
        source: modalDeatail.source,
        registrationCategory: registeredType == 1 ? 0 : 1,
      }),
    }
    const patientInsuranceItem: any =
      registeredType == 2
        ? {
            ...R.patientFromJson(patientInsurance),
            ...otherPatientInsuranceItem,
            fullCardInfo: fullCardInfo || '',
            insuranceCode: insuranceItem?.insuranceCode,
            cardType: values.readType,
          }
        : {}
    const department = departments.find(
      (d) => d.id === registration.registrationDepartmentId
    )
    registration.treatmentDepartmentId = registration.registrationDepartmentId
    if (department) {
      registration.registrationDepartmentName = department.name
      registration.treatmentDepartmentName = department.name
    }
    registration.treatmentDoctorId = registration.registrationDoctorId
    const doctor = doctorList.find(
      (d: any) => d.id === registration.registrationDoctorId
    )
    if (doctor) {
      registration.registrationDoctorName = doctor.name
      registration.treatmentDoctorName = doctor.name
    }

    if (values.receiveInAdvance && fee && !fee?.hilistCode && !params) {
      registerPayment(values)
    } else {
      dispatch(
        register({
          patient,
          fee,
          payment: params,
          patientInsurance: patientInsuranceItem,
          registration,
          disease: selectedDiseases.map((d) => `${d.name}/${d.icds}`).join(','),
        })
      )
        .then(unwrapResult)
        .then((data: any) => {
          setRegisterData(data)

          dispatch(setAppointmentIdSlice(''))
          notification.success({
            message: '挂号成功!',
            duration: 3,
          })
          setModalBtnLoading(false)
          const vs = form.getFieldsValue()

          getResiterMedicalNum()
          if (isHisCode) {
            form.setFieldsValue({
              registrationCategory: insuranceItem,
              readType: vs.readType,
            })
          }
          setHasCard(false)
          setTriageDetailId('')
          setSumList({})
          setIsModalVisible(false)
          if (insuranceItem?.insuranceCode && fee?.hilistCode) {
            setShowStopAction(true)
            FeeListUp('requestString', data, values)
          } else {
            form.resetFields()
            values?.skipPrinting && onOk()
            registeredType == 2 && onOk()
          }
          // values?.skipPrinting && onOk()
          // registeredType == 2 && onOk()
          if (!values.skipPrinting && !insuranceItem?.insuranceCode) {
            // 打印
            setModalRegistrationId(String(data?.registrationId))
          }
        })
        .finally(() => {
          setModalBtnLoading(false)
          // form.resetFields();
        })
    }
  }

  const onSave = () => {
    //转诊保存
    setModalBtnLoading(true)
    const department = departments.find(
      (d) => d.id === form.getFieldValue('registrationDepartmentId')
    )
    dispatch(
      getTransferTreatment({
        departmentId: department?.id || '',
        departmentName: department?.name || '',
        doctorId: doctorStyle.id,
        doctorName: doctorStyle?.realname,
        registrationId: registrationId || '',
      })
    )
      .then(unwrapResult)
      .then(() => {
        notification.success({ message: '转诊成功' })
        onOk()
      })
      .finally(() => setModalBtnLoading(false))
  }
  const getResiterMedicalNum = () => {
    dispatch(getMedicalNum())
      .then(unwrapResult)
      .then((res) => {
        setReatmentNum(res)
        // form.setFieldsValue({
        //   treatmentNo: res,
        // })
      })
  }
  useEffect(() => {
    if (!visible) {
      setModalDetail({})
    }
    if (!visible) return
    getResiterMedicalNum()
    let timer: any = null
    timer = setInterval(() => {
      getResiterMedicalNum()
    }, 5000)
    return () => {
      timer && clearInterval(timer)
    }
  }, [dispatch, visible])
  // 2204
  const FeeListUp = (
    serverType: serverType,
    treatmentData: registerValue,
    values: Record<string, unknown>,
    responseString?: string
  ) => {
    dispatch(
      FeeListUpAsync({
        serverType: serverType,
        body: {
          baseData: {
            ...baseData,
            responseString,
          },
          exp_content: '',
          treatmentId: treatmentData?.treatmentId,
          registerFlag: 1,
        },
      })
    )
      .then(unwrapResult)
      .then((res) => {
        if (serverType === 'requestString') {
          success('正在上传费用')
          const data = strParse(res)
          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                baseData?.countryHospitalNb,
                baseData?.secretToken,
              ],
              message: '费用上传',
            },
            (call, err) => {
              if (err) {
                setReadLoading(false)
                setShowStopAction(false)
              } else {
                FeeListUp(
                  'response',
                  treatmentData,
                  values,
                  JSON.stringify(call.netmessage)
                )
              }
            },
            undefined
          )
        } else {
          preSettlement('requestString', treatmentData, values)
        }
      })
  }

  // 2206
  const preSettlement = (
    serverType: serverType,
    treatmentData: registerValue,
    values: Record<string, unknown>,
    responseString?: string
  ) => {
    dispatch(
      preSettlementAsync({
        serverType: serverType,
        body: {
          baseData: {
            ...baseData,
            responseString,
          },
          chrg_bchno: '2',
          exp_content: '',
          treatmentId: treatmentData?.treatmentId,
          patientId: String(treatmentData?.patientId),
          registrationId: String(treatmentData?.registrationId),
        },
      })
    )
      .then(unwrapResult)
      .then((res) => {
        if (serverType === 'requestString') {
          success('正在挂号费预结算')
          const data = strParse(res)
          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                baseData?.countryHospitalNb,
                baseData?.secretToken,
              ],
            },
            (call, err) => {
              if (err) {
                setReadLoading(false)
                setShowStopAction(false)
              } else {
                preSettlement(
                  'response',
                  treatmentData,
                  values,
                  JSON.stringify(call.netmessage)
                )
              }
            }
          )
        } else {
          registerPayment(values, res, treatmentData.recipeId)
        }
      })
      .catch(() => {
        setReadLoading(false)
        setShowStopAction(false)
      })
  }

  const registerPayment = (
    values: Record<string, unknown>,
    prePay?: any,
    recipeId?: number
  ) => {
    const patient = {
      ...P.fromJson(values),
      id: patientId,
      ageMonth: parseInt(values.ageMonth as string) || 0,
      ageYear:
        source == 1
          ? parseInt(values.age as string) || 0
          : parseInt(values.ageYear as string) || 0,
    }
    const fee = fees.find((f) => f.id === values.registrationFeeId) as Fee
    setConfirmation({
      patient: patient.name,
      amount: fee.unitPrice,
      prePay,
      ids: recipeId,
      slots: [
        {
          name: '挂号费',
          value: fee.unitPrice,
          children: [
            {
              name: fee.name,
              value: fee.unitPrice,
              children: [],
            },
          ],
        },
      ],
      errorMaterials: [],
    })
    ref.current?.reset()
    setModalBtnLoading(false)
    setShowStopAction(false)
    setIsModalVisible(true)
  }

  // 2207
  const saveSettlement = (
    serverType: serverType,
    treatmentData?: registerValue,
    params?: PaymentRegistrationParams,
    responseString?: string
  ) => {
    dispatch(
      saveSettlementAsync({
        serverType: serverType,
        body: {
          baseData: {
            ...baseData,
            responseString,
          },
          chrg_bchno: '2',
          exp_content: '',
          cpNo: 'FC0',
          treatmentId: treatmentData?.treatmentId,
          patientId: String(treatmentData?.patientId),
          registrationId: String(treatmentData?.registrationId),
          doPayVO:
            serverType === 'requestString'
              ? { ...params, insuranceTradeId: '', settlementCategory: 1 }
              : undefined,
        },
      })
    )
      .then(unwrapResult)
      .then((res) => {
        if (serverType === 'requestString') {
          success('门诊结算中')
          const data = strParse(res)
          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                baseData?.countryHospitalNb,
                baseData?.secretToken,
              ],
            },
            (call) => {
              saveSettlement(
                'response',
                treatmentData,
                params,
                JSON.stringify(call.netmessage)
              )
            }
          )
        } else {
          doPay(params, res.insuranceTradeId)
          // messageNotification('结算成功')
        }
      })
  }

  const doPay = (params: any, insuranceTradeId?: string) => {
    dispatch(pay({ ...params, insuranceTradeId, settlementCategory: 1 }))
      .then(unwrapResult)
      .then((v:any) => {
        notification.success({
          message: '挂号费支付成功',
          duration: 1.5,
        })
        setIsModalVisible(false)
      })
      .catch(() => {
        notification.info({
          message: '可以到待收费页面中继续收挂号费',
          duration: 1.5,
        })
        setIsModalVisible(false)
      })
  }

  const success = (str: string) => {
    setReadLoading(true)
    const hide = message.loading(str, 0)
    setTimeout(() => {
      hide()
      setReadLoading(false)
    }, 3000)
  }

  const successMessage = (str: string) => {
    setHasCard(true)
    notification.success({
      message: str,
    })
  }

  const getIdCardInfo = (idCard: string) => {
    const date = new Date()

    const getMonth = date.getMonth() + 1 //当前月份
    const month = parseInt(idCard?.substr(10, 2)) //身份证上的月份

    const getFullYear = date.getFullYear() //当前年份
    const year = parseInt(idCard?.substr(6, 4)) //身份证上的年份

    const birthday = moment(idCard?.substr(6, 8)).format(DateTimeFormatSimple)

    //  身份证上的年月日
    if (idCard) {
      if (idCard.length === 18) {
        if (getMonth - month > 0) {
          form.setFieldsValue({
            ageYear: getFullYear - year,
            age: getFullYear - year,
            ageMonth: getMonth - month,
            birthday: idCard.length === 18 ? birthday : '',
          })
        } else if (getMonth - month < 0) {
          form.setFieldsValue({
            ageYear: getFullYear - year - 1,
            age: getFullYear - year - 1,
            ageMonth: 12 - (month - getMonth),
            birthday: idCard.length === 18 ? birthday : '',
          })
        } else if (getMonth - month === 0) {
          form.setFieldsValue({
            ageYear: getFullYear - year - 1,
            age: getFullYear - year - 1,
            ageMonth: 0,
            birthday: idCard.length === 18 ? birthday : '',
          })
        }
      } else if (idCard.length < 18 || idCard === '') {
        form.setFieldsValue({
          ageYear: '',
          age: '',
          ageMonth: '',
          birthday: '',
        })
      }
    }
  }

  // 查询人员累计信息
  const searchPsnSum = (
    isShowModal: boolean,
    serverType: serverType,
    psnNo: string,
    insuranceRegion: string,
    responseString?: string
  ) => {
    dispatch(
      searchPsnSumAsync({
        serverType,
        body: {
          baseData: {
            ...baseData,
            responseString,
          },
          psnNo,
          insuranceRegion,
        },
      })
    )
      .then(unwrapResult)
      .then((res) => {
        if (serverType === 'requestString') {
          const data = strParse(res)
          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                insuranceItem.countryHospitalNb,
                insuranceItem.secretToken,
              ],
              message: '人员累计信息查询',
            },
            (call: any, err) => {
              if (!err) {
                searchPsnSum(
                  isShowModal,
                  'response',
                  psnNo,
                  insuranceRegion,
                  JSON.stringify(call.netmessage)
                )
              }
            }
          )
        } else {
          const obj: otherPatientInsuranceItemParams = {}
          if (isShowModal) {
            setSumVisible(true)
            setSumList(res)
          }
          const again = res?.currYearInsCumWithoutYear.filter((v: any) => {
            return (
              v.name === '大病补充起付金额累计' ||
              v.name === '门诊起付金额累计' ||
              v.name === '门诊慢病起付金额累计'
            )
          })
          again.forEach((v: any) => {
            if (v.name === '大病补充起付金额累计') {
              obj.dbbcqflj = v.cum
            } else if (v.name === '门诊起付金额累计') {
              obj.dnqfxljPt = v.cum
            } else if (v.name === '门诊慢病起付金额累计') {
              obj.dnqfxljMb = v.cum
            }
          })
          setOtherPatientInsuranceItem(obj)
        }
      })
  }

  // 获取患者特慢病信息
  const searchMedical = (
    res: any,
    serverType: any,
    responseString?: string
  ) => {
    dispatch(
      searchQueryPsnAsync({
        serverType,
        body: {
          baseData: {
            ...insuranceItem,
            endTime: moment(insuranceItem.endTime).format(
              DateTimeFormatSimpleOnlyOne
            ),
            userName,
            userId,
            cycleCode,
            tenantCategory,
            tenantAreaCd,
            name: tenantName,
            address: tenantAddress,
            areaCode: tenantAreaCd,
            responseString: responseString ? responseString : null,
          },
          insuplcAdmdvs: res?.insuranceRegion,
          psnNo: res?.ybPersonalNo,
          // 测试代码
          // insuplcAdmdvs: '330599',
          // psnNo: '33050099000010000000525114',
        },
      }) as any
    )
      .then(unwrapResult)
      .then(async (result: any) => {
        if (serverType === 'requestString') {
          const data = strParse(result)
          await medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                insuranceItem.countryHospitalNb,
                insuranceItem.secretToken,
              ],
              noSuccess: true,
            },
            (call: any) => {
              searchMedical(res, 'response', JSON.stringify(call.netmessage))
            }
          )
        }
      })
  }

  // 获取参保人信息
  const getPatientInfo = (
    fullCardInfo: string,
    serverType: any,
    responseString?: string
  ) => {
    const cardType = form.getFieldsValue().readType
    setFullCardInfo(fullCardInfo)
    dispatch(
      getPatientInfoAsync({
        serverType,
        body: {
          baseData: {
            ...baseData,
            responseString,
          },
          cardType: String(cardType),
          fullCardInfo,
          otherMap: {},
        },
      })
    )
      .then(unwrapResult)
      .then(async (res) => {
        if (serverType === 'requestString') {
          const data = strParse(res)
          await medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                insuranceItem.countryHospitalNb,
                insuranceItem.secretToken,
              ],
            },
            (call: any, err) => {
              if (!err) {
                success('正在获取人员信息')
                getPatientInfo(
                  fullCardInfo,
                  'response',
                  JSON.stringify(call.netmessage)
                )
              } else {
                setReadLoading(false)
              }
            }
          )
        } else {
          setReadLoading(false)
          if (res?.patientInsurance.name !== modalDeatail.patientName) {
            notification.error({
              message: '人卡不一致，患者姓名：'+modalDeatail.patientName+";卡姓名："+res?.patientInsurance.name,
            })
            setReadLoading(false)
            return
          }
          successMessage('读卡成功')
          dispatch(
            updateInsuranceCardInfoAsync({
              ...res?.patientInsurance,
              fullCardInfo: fullCardInfo || '',
              insuranceCode: insuranceItem.insuranceCode,
              cardType,
              patientId: modalDeatail.patientId || patientId,
            })
          )
           if (
             res?.patientInsurance.patientId &&
             res?.patientInsurance.patientId != -1
           ) {
             dispatch(getTreatmentNotice(res?.patientInsurance.patientId))
               .then(unwrapResult)
               .then((notice) => setNotice(notice))
               .catch(() => {
                 // do nothing
               })
           }
          setPatientInsurance(res?.patientInsurance)
          searchMedical(res?.patientInsurance, 'requestString')
          form.setFieldsValue({
            // ybkh: res?.patientInsurance.CardNum,
            name: res?.patientInsurance.name,
            idCardNumber: res?.patientInsurance.idCard,
            cbxzmc: res?.patientInsurance.cbxzmc,
            memberTypemc: res?.patientInsurance.memberTypemc,
            treatmentStatusmc: res?.patientInsurance.treatmentStatusmc,
            zhye: res?.patientInsurance.zhye,
            dnzhye: res?.patientInsurance.dnzhye,
            lnzhye: res?.patientInsurance.lnzhye,
            companyName: res?.patientInsurance.brdw,
            ybPersonalNo: res?.patientInsurance.ybPersonalNo,
            sex: Number(res?.patientInsurance.sex),
          })
        }
      })
      .catch(() => {
        setReadLoading(false)
      })
  }

  const medicalSubmit = (vs: any, serverType: any, responseString?: string) => {
    const treatmentItem: any = departments.find(
      (v) => v.id === vs.registrationDepartmentId
    )
    const doctorItem: any = doctorList.find((v: any) => v.id === doctorStyle.id)

    dispatch(
      medicalRegisterAsync({
        serverType,
        body: {
          baseData: {
            ...baseData,
            responseString,
          },
          begntime: moment().format(DateTimeFormatSimpleOnlyOne),
          cardType: vs.readType,
          cbxz: patientInsurance.cbxz,
          doctorCode: doctorItem?.countryCode,
          doctorName: doctorItem?.realname,
          fullCardInfo: fullCardInfo || '',
          gwybz: patientInsurance.gwybz,
          insuranceRegion: patientInsurance.insuranceRegion,
          psnType: patientInsurance.memberType,
          treatmentDepartmentCode: treatmentItem.code,
          treatmentDepartmentName: treatmentItem.name,
          treatmentNo: treatmentNum,
          patientId: undefined,
          ybKebie: treatmentItem.ybKebie,
          ybPersonalNo: vs.ybPersonalNo,
          name: patientInsurance.name,
        },
      })
    )
      .then(unwrapResult)
      .then(async (res) => {
        if (serverType === 'requestString') {
          const data = strParse(res)
          await medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                insuranceItem.countryHospitalNb,
                insuranceItem.secretToken,
              ],
              massage: '医保挂号',
            },
            (call: any, err) => {
              if (!err) {
                medicalSubmit(vs, 'response', JSON.stringify(call.netmessage))
              } else {
                setModalBtnLoading(false)
              }
            }
          )
        } else {
          setYbJiuzhenid(res.ybJiuzhenid)
          // resolve()
          onSubmit({ ...vs, ybJiuzhenid: res.ybJiuzhenid }, undefined)
        }
      })
      .catch(() => {
        setModalBtnLoading(false)
      })
  }

  // 电子凭证读卡
  const readEType = () => {
    readCardEHttp(
      {
        trade_args: [
          insuranceItem.countryHospitalNb,
          '01301',
          '',
          userId,
          userName,
          '',
          '',
        ],
      },
      (data, err) => {
        if (!err) {
          const infoList = data.netmessage.split('|')
          getIdCardInfo(infoList[1])
          form.setFieldsValue({
            idCardNumber: infoList[1],
            ybkh: infoList[1],
          })
          getPatientInfo(data.netmessage, 'requestString')
        } else {
          setReadLoading(false)
        }
      }
    )
  }

  // 实体卡读卡
  const readSType = () => {
    readCardSHttp(
      {
        trade_args: [getTradeCode(tenantAreaCd.slice(0, 4))],
      },
      (data, err) => {
        if (!err) {
          getPatientInfo(baseData?.insuranceCode == 'JIANGSU' ? data.netmessage : JSON.stringify(data.netmessage), 'requestString')
          form.setFieldsValue({
            ybkh: data?.netmessage.CardNum,
          })
        } else {
          setReadLoading(false)
        }
      }
    )
  }

  const readCard = () => {
    setOtherPatientInsuranceItem({}) // 防止误用他人信息
    readCardRef.current.blur() //按钮失焦，防止重复点击
    const readType = form.getFieldValue('readType')

    if (!readType) {
      notification.info({
        message: '请选择读卡类型',
      })
      return
    }
    success('读卡中')
    if (readType == 1) {
      readEType()
    } else if (readType == 2) {
      const idCardNumber = form.getFieldsValue().idCardNumber
      if (idCardNumber) {
        getPatientInfo(idCardNumber, 'requestString')
      } else {
        notification.info({
          message: '请输入身份证号',
        })
      }
    } else if (readType == 3) {
      readSType()
    }
  }

  useEffect(() => {
    if (!visible) return
    setSelectedDiseases([])
    getDetail()
  }, [visible, appointmentId, patientId, registrationId])

  useEffect(() => {
    if (Object.keys(modalDeatail).length == 0) return
    if (Object.keys(modalDeatail?.patientInsurance).length > 0) {
      setPatientInsurance(modalDeatail.patientInsurance)
       if (
         modalDeatail?.patientInsurance.patientId &&
         modalDeatail?.patientInsurance.patientId != -1
       ) {
         dispatch(getTreatmentNotice(modalDeatail?.patientInsurance.patientId))
           .then(unwrapResult)
           .then((notice) => setNotice(notice))
           .catch(() => {
             // do nothing
           })
       }
      form.setFieldsValue({
        registeredType: '2',
        name: modalDeatail?.patientInsurance.name,
        idCardNumber: modalDeatail?.patientInsurance.idCard,
        cbxzmc: modalDeatail?.patientInsurance.cbxzmc,
        memberTypemc: modalDeatail?.patientInsurance.memberTypemc,
        treatmentStatusmc: modalDeatail?.patientInsurance.treatmentStatusmc,
        zhye: modalDeatail?.patientInsurance.zhye,
        dnzhye: modalDeatail?.patientInsurance.dnzhye,
        lnzhye: modalDeatail?.patientInsurance.lnzhye,
        companyName: modalDeatail?.patientInsurance.brdw,
        ybPersonalNo: modalDeatail?.patientInsurance.ybPersonalNo,
        sex: Number(modalDeatail?.patientInsurance.sex),
        ybkh: modalDeatail.patientInsurance.medicalCardNo,
        readType: modalDeatail.patientInsurance.cardInfo ? '3' : '1',
      })
      setFullCardInfo(modalDeatail.patientInsurance.fullCardInfo)
      setRegisteredType(2)
    }
    //先判断是否有预约医生，如果有的话默认选中预约医生为挂号医生
    const yyObj = doctorList?.find(
      (v: any) => v.id == modalDeatail.appointmentDoctorId
    )
    if (yyObj && JSON.stringify(yyObj) != '{}') {
      if (yyObj && JSON.stringify(yyObj) != '{}') {
        setDoctorStyle(yyObj)
        form.setFieldsValue({
          registrationDepartmentId: String(yyObj?.outpatientDepartmentId),
        })
      }
    } else {
      //再判断主治医生，如果有的话默认选中主治医生为挂号医生
      const tempObj = doctorList?.find(
        (v: any) => v.id == modalDeatail.attendingDoctorId
      )
      if (tempObj && JSON.stringify(tempObj) != '{}') {
        setDoctorStyle(tempObj)
        form.setFieldsValue({
          registrationDepartmentId: String(tempObj?.outpatientDepartmentId),
        })
      }
    }

    const doctorIsDisabled = counselorList?.find(
      (f) => f.id === modalDeatail.counselorId
    )
    form.setFieldsValue({
      consultDoctorId:
        modalDeatail.counselorId != -1
          ? doctorIsDisabled != undefined
            ? modalDeatail.counselorId
            : undefined
          : undefined,
    })
  }, [modalDeatail, doctorList])

  useEffect(() => {
    const session_print = localStorage.getItem('registrationPrint')
    const session_charge = localStorage.getItem('registrationCharge')

    if (visible) {
      getAllDoctor()
      getCounselorFn()
      dispatch(getDepartments())
      setPatientInsurance({})
      getType()
      setModalBtnLoading(false)
      form.setFieldsValue({
        skipPrinting: session_print ? JSON.parse(session_print) : false,
        receiveInAdvance: session_charge ? JSON.parse(session_charge) : false,
        consultDoctorId: modalDeatail.counselorId,
      })
    } else {
      form.resetFields()
      setRegisteredType(1)
      setDoctorStyle({})
    }
  }, [visible])

  useEffect(() => {
    if (registeredType == 1) {
      setInsuranceItem({})
    } else {
      if (insuranceArray.length) {
        setInsuranceItem(insuranceArray[0])
      }
    }
  }, [registeredType])

  useEffect(() => {
    //医保信息
    if (!visible) return
    if (insuranceItem && JSON.stringify(insuranceItem) !== '{}') {
      dispatch(
        setInsurance({
          ...insuranceItem,
          userName,
          userId,
          cycleCode,
          tenantCategory,
          tenantAreaCd,
          name: tenantName,
          address: tenantAddress,
          areaCode: tenantAreaCd,
          endTime: moment(insuranceItem.endTime).format(
            DateTimeFormatSimpleOnlyOne
          ),
        })
      )
    } else {
      dispatch(setInsurance({}))
    }
  }, [insuranceItem, visible])

  useEffect(() => {
    if (visible) {
      dispatch(getFeeList(insuranceItem?.insuranceCode))
    }
    if (insuranceItem && JSON.stringify(insuranceItem) !== '{}') {
      dispatch(getOutpatientDepartment())
    }
  }, [dispatch, insuranceItem, visible])
  return (
    <Modal
      className={styles.RegisteredModal}
      title={title == 0 ? '挂号' : '转诊'}
      width={1000}
      visible={visible}
      confirmLoading={modalBtnLoading}
      destroyOnClose
      centered
      onOk={() => {
        title == 0 ? form.submit() : onSave()
        // setTettlementModal(true)
      }}
      maskClosable={false}
      onCancel={() => {
        onCancel()
        setPatientInsurance({})
      }}
    >
      <div className={styles.modalWrap}>
        {/* 头部信息 */}
        <div className={styles.modalHeaderWraps}>
          <div className={styles.headerUserWrap}>
            <div className={styles.fl}>
              <div className={styles.icon} style={{ fill: '#fff' }}>
                {modalDeatail?.patientSex == 2 ? (
                  <GirlIcon
                    style={{
                      width: 48,
                      height: 48,
                    }}
                  />
                ) : (
                  <BoyIcon
                    style={{
                      width: 48,
                      height: 48,
                    }}
                  />
                )}
              </div>
              <div className={styles.user}>
                {modalDeatail.patientName}({' '}
                {getSexData(Number(modalDeatail.patientSex))}/
                {getAge(
                  modalDeatail.patientAge,
                  modalDeatail?.patientMonth,
                  '岁'
                )}
                )
              </div>
              {modalDeatail.arrearageAmountSum != 0 && (
                <div className={styles.money}>
                  (欠费金额：{modalDeatail.arrearageAmountSum})
                </div>
              )}
            </div>
            <div className={styles.num}>病历号：{modalDeatail.patientId}</div>
          </div>
          <Row>
            <Col span={5}>
              预约科室：{modalDeatail.appointmentDepartmentName}
            </Col>
            <Col span={5}>预约医生：{modalDeatail.appointmentDoctorName}</Col>
            <Col span={6}>预约项目：{modalDeatail.appointmentItem}</Col>

            <Col span={7}>
              <Tooltip title={modalDeatail.appointmentComment}>
                <div className={styles.remark}>
                  预约备注：{modalDeatail.appointmentComment}
                </div>
              </Tooltip>
            </Col>
          </Row>
        </div>
        {/* 挂号信息 */}
        <Form
          form={form}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 16 }}
          onFinish={(values_) => {
            //   <Col span={8}>险种类别：{patientInsurance.cbxzmc}</Col>
            //   <Col span={8}>人员类别：{patientInsurance.memberTypemc}</Col>
            //   <Col span={8}>
            //     参保状态：{patientInsurance.treatmentStatusmc}
            //   </Col>
            // </Row>
            //   <Row>
            //     <Col span={8}>账户余额：{patientInsurance.zhye}</Col>
            //     <Col span={8}>当年余额：{patientInsurance.dnzhye}</Col>
            //     <Col span={8}>历年余额：{patientInsurance.lnzhye}</Col>
            //   </Row>
            //   <Row>
            //     <Col span={8}>医保卡号：{patientInsurance.ybkh}</Col>
            //     <Col span={8}>个人编号：{patientInsurance.ybPersonalNo}</Col>
            const values = {
              ...values_,
              ybPersonalNo: patientInsurance.ybPersonalNo,
              ybkh: patientInsurance.ybkh,
            }
            setModalBtnLoading(true)
            if (JSON.stringify(doctorStyle) == '{}') {
              notification.error({ message: '请选择医生' })
              setModalBtnLoading(false)
              return
            }
            if (registeredType == 1) {
              onSubmit({
                ...values,
                id: '',
                registrationDoctorId: '',
              })
            } else {
              if (Object.keys(patientInsurance).length) {
                const fee = fees.find(
                  (f) => f.id === values.registrationFeeId
                ) as Fee
                if (
                  values.receiveInAdvance &&
                  fee?.hilistCode &&
                  String(tenantAreaCd).slice(0, 4) !=
                    patientInsurance.insuranceRegion.slice(0, 4)
                ) {
                  notification.info({
                    message:
                      '异地患者暂时不支持预收挂号费用，请在医生处方中手动开具挂号费',
                  })
                  setModalBtnLoading(false)
                } else {
                  //重庆不调用
                  if (
                    modalDeatail?.patientInsurance?.ybPersonalNo &&
                    (insuranceItem?.insuranceCode == 'ZHEJIANG' || insuranceItem?.insuranceCode == 'JIANGSU')
                  ) {
                    searchPsnSum(
                      false,
                      'requestString',
                      modalDeatail?.patientInsurance?.ybPersonalNo,
                      modalDeatail?.patientInsurance?.insuranceRegion
                    )
                  }
                  medicalSubmit(values, 'requestString')
                }
              } else {
                notification.info({
                  message: '请先读卡',
                })
                setModalBtnLoading(false)
              }
            }
          }}
        >
          {title == 0 && (
            <Row style={{ marginLeft: '-40px' }}>
              <Col span={12}>
                <Form.Item name='registeredType' label='挂号类别' required>
                  <div className={styles.fl}>
                    <div
                      className={
                        registeredType == 1
                          ? styles.tyleZIFEI
                          : styles.tyleZIFEIActive
                      }
                      onClick={() => {
                        setRegisteredType(1)
                      }}
                    >
                      自费
                    </div>
                    {insuranceArray.length !== 0 && (
                      <div
                        className={
                          registeredType == 2
                            ? styles.tyleZIFEI
                            : styles.tyleZIFEIActive
                        }
                        onClick={() => {
                          setRegisteredType(2)
                          form.setFieldsValue({
                            readType: '1',
                          })
                        }}
                      >
                        智慧医保
                      </div>
                    )}
                  </div>
                </Form.Item>
              </Col>
            </Row>
          )}
          {registeredType == 2 && (
            <>
              <Row style={{ marginLeft: '-40px' }}>
                <Col span={12}>
                  <Form.Item name='readType' label='读卡类型'>
                    <Radio.Group>
                      <Radio value='1'>电子凭证</Radio>
                      {tenantName?.indexOf('测试') >= 0 && (
                        <Radio value='2'>身份证</Radio>
                      )}
                      <Radio value='3'>医保卡</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span={2}>
                  <Button
                    type='primary'
                    onClick={() => {
                      readCard()
                    }}
                    ref={readCardRef}
                    loading={readLoading}
                  >
                    读卡
                  </Button>
                </Col>
                <Col
                  span={8}
                  style={{ display: 'flex', justifyContent: 'flex-start', margin:'6px 0 0 10px'}}
                >
                  {patientInsurance?.ybType?.toString() ? (
                    <p
                      style={{
                        fontSize: '14px',
                        color: 'rgb(7, 193, 96)',
                      }}
                    >
                      {getYBTypeName(patientInsurance?.ybType?.toString())}
                    </p>
                  ) : (
                    ''
                  )}
                  {patientInsurance?.patientId?.toString() ? (
                    <p
                      style={{
                        fontSize: '14px',
                        color: '#FAB714',
                        marginLeft: '20px',
                      }}
                    >
                      {patientInsurance?.patientId == -1
                        ? '本年首次'
                        : notice?.isFirstDiagnosisThisYear
                        ? '本年首次'
                        : '本年复诊'}
                    </p>
                  ) : (
                    ''
                  )}
                </Col>
              </Row>
              <div className={styles.modalHeaderWrapsYBContent}>
                <Row>
                  <Col span={8}>险种类别：{patientInsurance.cbxzmc}</Col>
                  <Col span={8}>人员类别：{patientInsurance.memberTypemc}</Col>
                  <Col span={8}>
                    参保状态：{patientInsurance.treatmentStatusmc}
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>账户余额：{patientInsurance.zhye}</Col>
                  <Col span={8}>当年余额：{patientInsurance.dnzhye}</Col>
                  <Col span={8}>历年余额：{patientInsurance.lnzhye}</Col>
                </Row>
                <Row>
                  <Col span={8}>医保卡号：{patientInsurance.ybkh}</Col>
                  <Col span={8}>个人编号：{patientInsurance.ybPersonalNo}</Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <div
                      className={styles.itemNum}
                      style={
                        {
                          // marginLeft: 103,
                        }
                      }
                      onClick={() => {
                        if (patientInsurance?.ybPersonalNo) {
                          // if (sumList && JSON.stringify(sumList) !== '{}') {
                          //   setSumVisible(true)
                          // } else {
                          searchPsnSum(
                            true,
                            'requestString',
                            patientInsurance.ybPersonalNo,
                            patientInsurance?.insuranceRegion
                          )
                          // }
                        } else {
                          notification.warn({
                            message: '请先读卡',
                            duration: 1.5,
                          })
                        }
                      }}
                    >
                      查询患者医保账户累计信息
                    </div>
                  </Col>
                </Row>
              </div>
              {/* <Row style={{ marginLeft: '-40px' }}>
                <Col span={12}>
                  <Form.Item name='cbxzmc' label='险种类别'>
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name='memberTypemc' label='人员类别'>
                    <Input disabled />
                  </Form.Item>
                </Col>
              </Row> */}
              {/* <Row style={{ marginLeft: '-40px' }}>
                <Col span={12}>
                  <Form.Item name='treatmentStatusmc' label='参保状态'>
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name='zhye' label='账户余额'>
                    <Input disabled />
                  </Form.Item>
                </Col>
              </Row> */}
              {/* <Row style={{ marginLeft: '-40px' }}>
                <Col span={12}>
                  <Form.Item name='dnzhye' label='当年余额'>
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name='lnzhye' label='历年余额'>
                    <Input disabled />
                  </Form.Item>
                </Col>
              </Row> */}
              {/* <Row style={{ marginLeft: '-40px' }}>
                <Col span={12}>
                  <Form.Item
                    name='ybkh'
                    label='医保卡号'
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    <Input disabled />
                  </Form.Item>
                  <div
                    className={styles.itemNum}
                    style={{
                      marginLeft: 103,
                    }}
                    onClick={() => {
                      if (patientInsurance?.ybPersonalNo) {
                        // if (sumList && JSON.stringify(sumList) !== '{}') {
                        //   setSumVisible(true)
                        // } else {
                        searchPsnSum(
                          true,
                          'requestString',
                          patientInsurance.ybPersonalNo,
                          patientInsurance?.insuranceRegion
                        )
                        // }
                      } else {
                        notification.warn({
                          message: '请先读卡',
                          duration: 1.5,
                        })
                      }
                    }}
                  >
                    查询患者医保账户累计信息
                  </div>
                </Col>
                <Col span={12}>
                  <Form.Item name='ybPersonalNo' label='个人编号'>
                    <Input disabled />
                  </Form.Item>
                </Col>
              </Row> */}
            </>
          )}
          {title == 0 && (
            <Row
              style={{
                marginLeft: '-40px',
              }}
            >
              <Col span={12}>
                <Form.Item name='receiveTag' label='就诊类型'>
                  <Radio.Group>
                    <Radio value='0'>初诊</Radio>
                    <Radio value='1'>复诊</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name='consultDoctorId' label='咨询师'>
                  <Select
                    placeholder='请选择'
                    allowClear
                    showSearch
                    filterOption={(input, option: any) =>
                      (option?.children ?? '')
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {counselorList?.map((v: any) => {
                      return (
                        <Select.Option key={v.id} value={v.id}>
                          {v.realname}
                        </Select.Option>
                      )
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          )}
          <Row style={{ marginLeft: '-40px' }}>
            <Col span={12}>
              <Form.Item
                name='registrationDepartmentId'
                label='医生科室'
                rules={[{ required: true }]}
              >
                <Select
                  placeholder='请选择'
                  allowClear
                  onChange={(e) => getAllDoctor(e)}
                  showSearch
                  filterOption={(input, option: any) =>
                    (option?.children ?? '')
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                >
                  {departments.map((d) => (
                    <Select.Option key={d.id} value={d.id}>
                      {d.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {title == 0 && (
              <Col span={12}>
                <Form.Item name='reason' label='就诊原因'>
                  <FastInput action='jiuzhenyuanyin' />
                </Form.Item>
              </Col>
            )}
          </Row>

          {registeredType == 2 && (
            <Row style={{ marginLeft: '-40px' }}>
              <Col span={12}>
                <Form.Item
                  label='初步诊断'
                  name='disease'
                  rules={[
                    {
                      max: 500,
                      message: '最长500位!',
                    },
                    () => ({
                      validator: () => {
                        if (isDiseaseEmpty) {
                          return Promise.reject('请输入主要疾病')
                        }
                        return Promise.resolve()
                      },
                    }),
                  ]}
                >
                  <DiseaseInput
                    isMedical={2}
                    insuranceCode={insuranceItem?.insuranceCode}
                    placeholder='请输入主要疾病'
                    onChange={() => setIsDiseaseEmpty(false)}
                    onSelect={(disease) => {
                      if (
                        !selectedDiseases.find((d) => d.icds === disease.icds)
                      ) {
                        setSelectedDiseases(selectedDiseases.concat(disease))
                        form.resetFields(['disease'])
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              <Col
                span={12}
                flex='1'
                style={{
                  borderRadius: 2,
                  border: `1px solid ${theme.wbd}`,
                  padding: '10px 0 0 10px',
                  marginBottom: 10,
                  display: selectedDiseases.length === 0 ? 'none' : 'flex',
                }}
              >
                {selectedDiseases.map((d) => (
                  <div key={d.icds} className={styles.modalDisease}>
                    {`${d.name}/${d.icds}`}{' '}
                    <CloseOutlined
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setSelectedDiseases(
                          selectedDiseases.filter((i) => i.icds !== d.icds)
                        )
                      }}
                    />
                  </div>
                ))}
              </Col>
            </Row>
          )}

          <div className={styles.doctorCardWrap}>
            <Row>
              <div className={styles.doctorWrap}>
                <p
                  style={{
                    color: '#ff4d4f',
                    fontFamily: 'SimSun, sans-serif',
                  }}
                >
                  *{' '}
                </p>{' '}
                &nbsp;
                <p>医生 :</p>
              </div>
            </Row>
            <Row
              gutter={[24, 16]}
              wrap
              style={{
                margin: 0,
                paddingTop: 5,
                height: 298,
                overflow: 'auto',
              }}
            >
              {doctorList?.map((v: any) => {
                return (
                  <Col key={v.id} className='gutter-row' span={6}>
                    <div
                      className={
                        doctorStyle.id == v.id
                          ? styles.itemActiveWrap
                          : styles.itemWrap
                      }
                      onClick={() => {
                        setDoctorStyle(v)
                        form.setFieldsValue({
                          registrationDepartmentId: String(
                            v.outpatientDepartmentId
                          ),
                        })
                      }}
                    >
                      <div className={styles.itemUser}>
                        <div className={styles.itemIcon}>
                          <DoctorIcon
                            style={{
                              opacity: 0.8,
                            }}
                          />
                        </div>
                        <div>
                          <div className={styles.itemName}>{v.realname}</div>
                          <div className={styles.itemDepartment}>
                            {v.outpatientDepartmentName}
                          </div>
                        </div>
                      </div>
                      <div>
                        <div>
                          已挂号：
                          <span className={styles.itemNum}>
                            {v.registrationCnt}
                          </span>
                        </div>
                        <div>
                          待接诊：
                          <span className={styles.itemNum}>
                            {v.notReceivedCnt}
                          </span>
                        </div>
                        <div>
                          接诊中：
                          <span className={styles.itemNum}>
                            {v.receivingCnt}
                          </span>
                        </div>
                      </div>
                      {doctorStyle.id == v.id && (
                        <div className={styles.itemActiveIcon}>
                          <CheckCircleFilled />
                        </div>
                      )}
                    </div>
                  </Col>
                )
              })}
            </Row>
          </div>

          {title == 0 && (
            <Row style={{ position: 'absolute', width: '80%', bottom: 0 }}>
              <Col span={12}>
                <Row>
                  <Col span={14}>
                    <Form.Item name='skipPrinting' valuePropName='checked'>
                      <Checkbox
                        onChange={(e) => {
                          dispatch(
                            setSettings({
                              [Settings.Registration.SkipPrinting]:
                                e.target.checked,
                            })
                          )
                          localStorage.setItem(
                            'registrationPrint',
                            JSON.stringify(e.target.checked)
                          )
                        }}
                      >
                        跳过打印挂号小票
                      </Checkbox>
                    </Form.Item>
                  </Col>
                  <Col span={10}>
                    <Form.Item name='receiveInAdvance' valuePropName='checked'>
                      <Checkbox
                        onChange={(e) => {
                          if (e.target.checked && registeredType == 2) {
                            Modal.confirm({
                              centered: true,
                              title: '确认操作',
                              content: (
                                <div>
                                  <ExclamationCircleFilled
                                    style={{ color: '#FFAE55', fontSize: 18 }}
                                  />
                                  &nbsp;异地医保一次挂号只能结算一次，建议您挂号费在处置收费
                                  时再一起收费结算，否则无法进行门诊收费。
                                </div>
                              ),
                              okText: '后续收费',
                              cancelText: '继续收费（只收挂号费）',
                              icon: null,
                              onOk: () => {
                                form.setFieldsValue({
                                  receiveInAdvance: false,
                                })
                              },
                              cancelButtonProps: {
                                style: {
                                  backgroundColor: '#FFB433',
                                  color: '#FFF',
                                },
                              },
                            })
                          } else {
                            if (!e.target.checked) {
                              form.setFieldsValue({
                                registrationFeeId: undefined,
                              })
                            }
                          }

                          localStorage.setItem(
                            'registrationCharge',
                            JSON.stringify(e.target.checked)
                          )
                        }}
                      >
                        挂号费用
                      </Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Form.Item noStyle dependencies={['receiveInAdvance']}>
                  {({ getFieldValue }) =>
                    getFieldValue('receiveInAdvance') ? (
                      <Form.Item
                        label='门诊挂号费'
                        name='registrationFeeId'
                        required
                        // {...secondColumnLayout}
                        rules={[
                          { required: true, message: '请选择门诊挂号费' },
                        ]}
                      >
                        <Select
                          allowClear
                          placeholder='请选择门诊挂号费'
                          // suffixIcon={
                          //   <CaretDownFilled style={{ color: theme.tc3 }} />
                          // }
                          onChange={(v, s: any) => {
                            setIsInsurance(!!s.className)
                          }}
                        >
                          {fees.map((f) => (
                            <Select.Option
                              className={f.hilistCode}
                              key={f.id}
                              value={f.id}
                            >{`${f.name}/${f.unitPrice?.toFixed(2) || 'N/A'}元${
                              f.hilistCode ? '' : '（未匹配）'
                            }`}</Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    ) : undefined
                  }
                </Form.Item>
              </Col>
            </Row>
          )}
        </Form>
        <SettlementModal
          visible={settlementModal}
          onCancel={() => setTettlementModal(false)}
        />

        <Modal
          title='预收挂号费'
          width={900}
          cancelText='取消'
          okText='确认'
          visible={isModalVisible}
          onCancel={() => {
            setIsModalVisible(false)
            setModalBtnLoading(false)
            setLoading(false)
            if (isInsurance) {
              notification.info({
                message: '挂号成功，可以到待收费页面中继续收挂号费',
              })
              onOk()
            }
          }}
          onOk={() => {
            setLoading(true)
            ref.current?.submit()
          }}
          bodyStyle={{ padding: 0 }}
          confirmLoading={loading}
        >
          <PaymentConfirm
            chrgBchno={2}
            ref={ref}
            membersStatus={0}
            confirmation={confirmation}
            YUSHOUGUAHAOFEI={true}
            YUSHOUtreatment={registerData}
            onPay={(params) => {
              setLoading(false)
              if (params.success) {
                notification.success({
                  message:
                    params.success == '1'
                      ? '挂号费支付成功'
                      : '可以到待收费页面中继续收挂号费',
                  duration: 1.5,
                })
                if (params.success == '1') {
                  form.resetFields()
                }
                setIsModalVisible(false)
                onOk()
              } else {
                form.validateFields().then((values) => {
                  setIsModalVisible(false)
                  onSubmit(values, params)
                })
              }
              // if (params?.recipeIdList && params?.recipeIdList.length && params?.recipeIdList[0] !== 'undefined') {
              //   saveSettlement('requestString', registerData, params)
              // } else {
              // form.validateFields().then((values) => {
              //   setIsModalVisible(false)
              //   onSubmit(values, params)
              // })
              // }
            }}
          />
        </Modal>

        <Modal
          cancelText='返回'
          okButtonProps={{
            style: { display: 'none' },
          }}
          visible={sumVisible}
          title={`${patientInsurance?.name} ${sumList?.insutype} 账户年度累计`}
          onCancel={() => setSumVisible(false)}
        >
          <Table
            dataSource={sumList?.currYearInsCum}
            columns={[
              {
                title: '累计类型名称',
                align: 'left',
                render: (_, r) => {
                  return r.name
                },
              },
              {
                title: '累计数',
                align: 'center',
                render: (_, r) => {
                  return r.cum
                },
              },
            ]}
            pagination={false}
          />
        </Modal>

        <PrintTemplateModal
          visible={!!modalRegistrationId}
          templateCategory={PrintTemplateCategory.Reg}
          registrationId={modalRegistrationId}
          onCancel={() => {
            onOk()
            setModalRegistrationId('')
          }}
        />

        {showStopAction && (
          <div className={styles.stopAction}>
            正在进行挂号费结算...
            <br />
            请不要离开
          </div>
        )}
      </div>
    </Modal>
  )
}
