/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-06-29 11:13:58
 * @LastEditors: linxi
 * @LastEditTime: 2024-05-31 10:32:29
 */
import { ExclamationCircleFilled, LeftOutlined, ReloadOutlined, RightOutlined } from '@ant-design/icons'
import {
  Row,
  Space,
  Button,
  DatePicker,
  Tag,
  Popconfirm,
  Modal,
  notification,
  message,
  Checkbox,
  Spin,
} from 'antd'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import styles from './appointment.module.css'
import FullCalendar, {
  DateSelectArg,
  EventClickArg,
  EventHoveringArg,
} from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import resourceTimeGrid from '@fullcalendar/resource-timegrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import scrollPlugin from '@fullcalendar/scrollgrid'
import {
  AppointmentingIcon,
  AppointmentingSureIcon,
  OutpatientedIcon,
  OverTimeIcon,
  RegistrationedIcon,
  RunOffIcon,
} from '../../compnents/icons/Icons'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../app/store'
import {
  AddAppointmentInfoType,
  cancelAppointmentAsync,
  dayWeekAsync,
  selectDayTime,
  selectIsUpdate,
  setAddAppointmentInfo,
  setDayTime,
  setIsUpdate,
  sureAppointmentAsync,
} from './appointmentSelice'
import { unwrapResult } from '@reduxjs/toolkit'
import { getGenderName } from '../../models/user'
import { traceRoute } from '../../layouts/layoutSlice'
import { getBgc, getSVGSrc } from '../../models/appointment'
import { useHistory } from 'react-router-dom'
import { addAppointmentAsync, getDoctorsTimesAsync, setAppointmentIdSlice } from '../registration/register/registerSlice'
import { AppointmentRegister } from '../registration/register/appointmentRegister'
import { selectTenant } from '../user/setting/settingSlice'
import { selectEdition } from '../../app/applicationSlice'
import { RegisteredModal } from '../A-toothModule/toothHome/modal/registeredModal'
import { AppointmentDetail } from './list/detail'
import { SubscribeModal } from '../A-toothModule/toothHome/modal/subscribeModal'
import { AddPatientModal } from '../A-toothModule/toothHome/modal/addPatientModal'
import { getAge } from '../../utils/StringUtils'
import { updatePatientTimeAsync } from '../A-toothModule/patient/patientSlice'
import { Registration } from '../../models/registration'

export const AppointmentDay = (props: {
  activeKey: any
  onlyThisAccount?: any
}) => {
  const edtion = useSelector(selectEdition) //区分口腔版还是普通

  const isUpdate = useSelector(selectIsUpdate)

  const history = useHistory()

  const dispatch = useDispatch<RootDispatch>()

  const modalRef = useRef<any>()

  const [isModalVisible, setIsModalVisible] = useState(false)

  const [workTime, setWorkTime] = useState(['06:00:00', '21:01:00']) // 营业时间

  // const [businessHours, setBusinessHours] = useState('21:01:00') // 营业时间

  const [timeStep, setTimeStep] = useState('00:15')

  const [allData, setAllData] = useState<any>([])

  const [cardData, setCardData] = useState<any>([])

  const [createTime, setCreateTime] = useState<any>({})

  const monthDayTime = useSelector(selectDayTime)

  // const versionsShow: any = useSelector(selectTenant)  // 区分通用版 和 口腔版

  const hisVersion = useSelector(selectEdition) //his版本 0通用 1口腔

  const [modalType, setModalType] = useState(0) // 弹窗状态

  const [tableEditId, setTableEditId] = useState('') // 修改 ID

  const [registeredModal, setRegisteredModal] = useState(false) //挂号弹窗

  const [detailVisible, setDetailVisible] = useState(false) //详情弹窗

  const matchList = [
    {
      id: '-1',
      title: '暂无数据',
    },
  ]

  const [header, serHeader] = useState<any>()

  const [appointmentRegisterVisible, setAppointmentRegisterVisible] = useState(
    false
  ) // his 版新增预约

  const [addPatientModal, setAddPatientModal] = useState(false) // 新增患者弹窗

  const [modalPatientID, setModalPatientID] = useState('') //弹窗 患者ID

  const [subscribeModal, setSubscribeModal] = useState(false) // 工作站-预约弹窗

  const [visibleTag, setVisibleTag] = useState('false')

  const [loading, setLoading] = useState(false)

  const [sureVisible, setSureVisible] = useState(false)

  const [sureData, setSureData] = useState<any>({})

  const [checked, setChecked] = useState(false)

  const [isSendMessage, setIsSendMessage] = useState<any>(false)

  const [isEditable, setIsEditable] = useState<any>(true);

  useEffect(() => {
    if (detailVisible) {
      setVisibleTag('true')
    } else {
      setVisibleTag('false')
    }
  }, [detailVisible])

  useEffect(() => {
    serHeader(matchList)
  }, [])
  const ref = useRef<any>()

  const [data, setData] = useState<any>()

  const dateFormat = 'YYYY-MM-DD'

  const [day, setDay] = useState<any>()

  useEffect(() => {
    if (!ref.current) return
    const container = document.getElementById('dayContainer')
    if (!container) return
    const height = container?.clientHeight - 33

    const el =
      container &&
      container.getElementsByClassName('fc-scroller-liquid-absolute')
    Array.from(el || [])?.forEach((v: any) => {
      v.style.height = height + 'px'
    })
  }, [ref])

  useEffect(() => {
    if (monthDayTime) {
      setDay(monthDayTime)

      ref?.current?._calendarApi.gotoDate(monthDayTime)
    }
    dispatch(setDayTime(''))
  }, [monthDayTime])

  useEffect(() => {
    const data = JSON.parse(sessionStorage.getItem(location.pathname) || '{}')
    if (data.day) {
      setDay(data.day)
      ref?.current?._calendarApi.gotoDate(data.day)
    } else {
      setDay(moment().format('YYYY-MM-DD'))
    }
  }, [])

  useEffect(() => {
    if (props?.activeKey == 1) {
      if (props?.onlyThisAccount == true || props?.onlyThisAccount == false) {
        getpageData()
      }
    }
  }, [props?.onlyThisAccount, props?.activeKey])

  const getpageData = () => {
    const data = {
      type: 0,
      endTime: day,
      startTime: day,
      onlyDisplayThisAccount: props?.onlyThisAccount,
    }
    dispatch(dayWeekAsync(data))
      .then(unwrapResult)
      .then((res: any) => {
        if (res && Object.keys(res).length) {
          setAllData(res.appointmentRecord)
          // setWorkTime([`${res.businessStartTime}:59`, `${res.businessEndTime.slice(0, 4)}2:59`])
          setWorkTime([
            `${res.businessStartTime}:59`,
            `${res.businessEndTime}:59`,
          ])
          // setBusinessHours(`${res.businessEndTime}`)
          serHeader(
            res?.appointmentRecordDoctorVOList?.map((el: any) => ({
              id: el.doctorId,
              title: el.doctorName,
              departmentId: el.appointmentDepartmentId,
              resourceId: el.appointmentDepartmentId,
              // businessHours: { //工作时间
              //   startTime: '10:00',
              //   endTime: '18:00'
              // }
            }))
          )
          const disableList = res.appointmentRecordByDisabled?.map(
            (el: any, i: number) => {
              return {
                frontContent: {},
                id: el.id,
                resourceId: el.appointmentDoctorId.toString(),
                editable: false,
                start:
                  el.appointmentDate + "T" + el.appointmentStartTime + ":00",
                end: el.appointmentDate + "T" + el.appointmentEndTime + ":00",
                title: el.name,
                backgroundColor: "#e8e8e8",
                StyleSheet: {
                  // border: "none",
                },
              };
            }
          )
          const useList = res.appointmentRecord?.map((el: any) => {
            return {
              frontContent: {
                sex: getGenderName(el?.sex),
                sexValue: el?.sex,
                ageYear: el?.ageYear,
                appointmentDepartmentId: el?.appointmentDepartmentId,
                age: el?.ageYear || "1",
                phone: el?.phone || "",
                appointmentTime:
                  el?.appointmentDate +
                    " " +
                    el?.appointmentStartTime +
                    "~" +
                    el?.appointmentEndTime || "",
                appointmentDeptName: el?.appointmentDepartmentName || "",
                appointmentDoctorName: el?.appointmentDoctorName || "",
                appointmentItem: el?.appointmentItem || "",
                appointmentComment: el?.appointmentComment || "",
                icon: el.appointmentState,
                doctorId: el.appointmentDoctorId.toString(),
              },
              id: el.id,
              resourceId: el.appointmentDoctorId.toString(),
              start: el.appointmentDate + "T" + el.appointmentStartTime + ":00",
              end: el.appointmentDate + "T" + el.appointmentEndTime + ":00",
              title: el.name,

              color: getBgc(el.appointmentState),
              StyleSheet: {
                overflow: "hidden",
              },
              editable: true,
            };
          })
          setData([...disableList, ...useList])
          setTimeStep(`00:${res.timeScale}`)
          dispatch(setIsUpdate(false))
          setIsEditable(true)
        }
      })
    setIsModalVisible(false)
  }

  useEffect(() => {
    if (!day) return
    setTimeout(() => {
      getpageData()
    }, 100)
    sessionStorage.setItem(
      location.pathname,
      JSON.stringify({ day: day, tabBar: '1' })
    )
  }, [day])

  useEffect(() => {
    if (!isUpdate) return
    setTimeout(() => {
      getpageData()
    }, 100)
    sessionStorage.setItem(
      location.pathname,
      JSON.stringify({ day: day, tabBar: '1' })
    )
  }, [isUpdate])

  // 表格拖动
  // const eventDrop = (e: any) => {
  //   const data = {
  //     start: moment(e.startStr.slice(0, e.startStr.length - 1)).format('hh:mm'),
  //     end: moment(e.endStr.slice(0, e.endStr.length - 1)).format('hh:mm')
  //   }
  //   setCreateTime(data)
  //   return true
  // }

  // 点击表格的事件
  const handleDateSelect = (selectInfo: DateSelectArg) => {
    if (header?.some((v: any) => v.id == '-1')) {
      notification.info({
        message: '请先设置排班医生',
      })
      return
    }
    const isBefore = moment(
      selectInfo.endStr.slice(0, selectInfo.endStr.length - 1)
    ).isBefore(new Date())
    const addAppointmentInfo: AddAppointmentInfoType = {
      appointmentDoctorId: selectInfo.resource?.id,
      appointmentDepartmentId:
        selectInfo.resource?._resource.extendedProps.departmentId,
      appointmentDate: moment(
        selectInfo.endStr.slice(0, selectInfo.endStr.length - 1)
      ).format('YYYY-MM-DD'),
      appointmentStartTime: moment(
        selectInfo.startStr.slice(0, selectInfo.startStr.length - 1)
      ).format('HH:mm'),
      appointmentEndTime: moment(
        selectInfo.endStr.slice(0, selectInfo.endStr.length - 1)
      ).format('HH:mm'),
    }
    if (isBefore) {
      Modal.info({
        title: `该时间段不可预约`,
        okText: '确定',
        maskClosable: true,
        keyboard: true,
      })
      setIsModalVisible(false)
    } else {
      dispatch(setAddAppointmentInfo(addAppointmentInfo))

      Modal.confirm({
        title: `新增预约`,
        okText: '确定',
        cancelText: `取消`,
        maskClosable: true,
        content:
          moment(
            selectInfo.startStr.slice(0, selectInfo.startStr.length - 1)
          ).format('HH:mm') +
          '~' +
          moment(
            selectInfo.endStr.slice(0, selectInfo.endStr.length - 1)
          ).format('HH:mm'),
        keyboard: true,
        onOk: () => {
          if (isBefore) return
          if (hisVersion == 1) {
            setModalType(1)
            setAppointmentRegisterVisible(true)
          } else {
            history.push({
              pathname: '/addEditAppointment',
              state: {
                id: 0,
                activeKey: props?.activeKey,
              },
            })
          }
        },
      })
    }

    setIsModalVisible(false)
  }
  const handleEventLeave = (clickInfo: EventHoveringArg) => {
    setIsModalVisible(false)
  }
  const handleEventHover = (clickInfo: EventHoveringArg) => {
    if (parseInt(clickInfo?.event?.id) < 1) {
      return
    }
    setCardData(allData.find((el: any) => el.id == clickInfo.event.id))
    setIsModalVisible(true)
    const x = clickInfo.jsEvent.pageX
    const y = clickInfo.jsEvent.pageY
    const divWidth = clickInfo.el.offsetWidth
    const left = x + 300
    let positionX = 0
    let positionY = 0
    // 若为右侧，则框弹出位置在左侧
    if (left > document.documentElement.clientWidth - 300) {
      positionX = x - modalRef.current.clientWidth - 3
    } else {
      positionX = x + 3
    }
    if (
      y + modalRef.current.clientHeight >
      document.documentElement.clientHeight
    ) {
      positionY =
        document.documentElement.clientHeight - modalRef.current.clientHeight
    } else {
      positionY = y
    }

    modalRef.current.style.top = positionY + 'px'
    modalRef.current.style.left = positionX + 'px'
  }

  // 表格已有事件，删除
  const handleEventClick = (clickInfo: EventClickArg) => {
    if (clickInfo.event.backgroundColor == '#e8e8e8') {
      notification.warn({
        message: '该时间段不可预约',
      })
      return
    }
    setCardData(allData.find((el: any) => el.id == clickInfo.event.id))
    setIsModalVisible(true)
    const x = clickInfo.jsEvent.pageX
    const y = clickInfo.jsEvent.pageY
    const divWidth = clickInfo.el.offsetWidth
    const left = x + 300
    let positionX = 0
    let positionY = 0
    // 若为右侧，则框弹出位置在左侧
    if (left > document.documentElement.clientWidth - 300) {
      positionX = x - modalRef.current.clientWidth - 3
    } else {
      positionX = x + 3
    }
    if (
      y + modalRef.current.clientHeight >
      document.documentElement.clientHeight
    ) {
      positionY =
        document.documentElement.clientHeight - modalRef.current.clientHeight
    } else {
      positionY = y
    }

    modalRef.current.style.top = positionY + 'px'
    modalRef.current.style.left = positionX + 'px'
  }

  const flag: any =
    localStorage.getItem("isSendMessage") == "true" ? true : false;
  
  useEffect(() => {
    setIsSendMessage(flag);
  }, [flag]);

  return (
    <div className={styles.dayMain}>
      <Row justify="space-between" align="middle">
        <Space style={{ marginTop: "4px" }}>
          <Button
            type="primary"
            onClick={() => {
              setDay(moment().format("YYYY-MM-DD"));
              ref?.current?._calendarApi.today();
            }}
          >
            回到今天
          </Button>
          <div
            className="input-group"
            style={{ display: "inline-flex" }}
            id="div_week_date"
          >
            <Button
              icon={<LeftOutlined />}
              onClick={() => {
                setDay(moment(day).subtract(1, "d").format(dateFormat));
                ref?.current?._calendarApi.prev();
              }}
            />
            <div style={{ margin: "0 10px" }}>
              <DatePicker
                allowClear={false}
                // disabled
                value={moment(day, dateFormat)}
                format={dateFormat}
                onChange={(v) => {
                  setDay(moment(v).format(dateFormat));
                  ref?.current?._calendarApi.gotoDate(
                    moment(v).format(dateFormat)
                  );
                }}
              />
            </div>

            <Button
              icon={<RightOutlined />}
              onClick={() => {
                setDay(moment(day).add(1, "d").format(dateFormat));
                ref?.current?._calendarApi.next();
              }}
            />
          </div>
        </Space>
        {/* {
        createTime?.start && (
          <div>当前选择时间：{createTime?.start}-{createTime?.end}</div>
        )
      } */}
        <ReloadOutlined
          style={{ fontSize: "20px", marginRight: 40 }}
          onClick={() => {
            getpageData();
          }}
        />
      </Row>
      <div
        id="dayContainer"
        className={`${styles.containers} ${styles.dayContainer}`}
        style={{ overflow: "hidden" }}
      >
        {!isEditable ? <div className={styles.modalbg}>
          <Spin size="large" />
        </div> : ''}
        {/* {renderSidebar()} */}
        <FullCalendar
          schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
          ref={ref}
          timeZone="UTC"
          plugins={[
            interactionPlugin,
            dayGridPlugin,
            scrollPlugin,
            timeGridPlugin,
            resourceTimeGrid,
          ]}
          // headerToolbar={{
          //   left: 'prev,next today',
          //   center: 'title',
          //   right: 'dayGridMonth,timeGridWeek,timeGridDay'
          // }}
          // initialView='timeGridDay'
          initialView="resourceTimeGridDay" // 视图模式
          resources={header}
          eventOverlap={false}
          editable={isEditable} //是否可拖动
          selectable={true}
          selectOverlap={false}
          selectMirror={true}
          events={data}
          select={handleDateSelect}
          unselectAuto={true}
          // eventMouseLeave={handleEventLeave}
          // eventMouseEnter={handleEventHover}
          eventClick={handleEventClick}
          // selectAllow={eventDrop} // 表格选中
          allDaySlot={false} // 是否展示全天
          slotDuration={`${timeStep}:00`} // 时间间隔
          slotEventOverlap={false} // 是否可叠加覆盖
          locale={"zh-cn"} // 语言模式
          stickyHeaderDates={true} // 表头固定
          weekNumbers={true} // 是否显示第几周
          firstDay={1} // 从周几开始
          buttonText={{
            today: "回到本周",
          }}
          // nowIndicator={true} // 当前（今天）是否高亮显示
          slotLabelInterval={timeStep} //显示时段的间隔
          slotMinTime={workTime[0]} // 左侧开始时间
          slotMaxTime={workTime[1]} // 左侧结束时间
          weekText={"No."} // 左上角展示的第几周
          //   slotMinWidth={150}
          //   resourceAreaWidth={150}
          //   eventMinWidth={150}
          dayMinWidth={230}
          // eventMinHeight={40}
          eventResize={(info) => {
            const givenTime = new Date(info.event.endStr).getTime();
            // 获取当前时间
            const now = `${moment().format("YYYY-MM-DD")}T${moment().format(
              "HH:mm"
            )}:00Z`;
            const nowTime = new Date(now).getTime();
            if (givenTime < nowTime) {
              info.revert()
              return false
            }
            Modal.confirm({
              centered: true,
              title: '确认操作',
              content: (
                <div>
                  <ExclamationCircleFilled
                    style={{ color: '#FFAE55', fontSize: 18 }}
                  />
                  &nbsp;确定要修改预约吗？
                </div>
              ),
              okText: '确认',
              cancelText: '取消',
              icon: null,
              onOk: () => {
                setIsEditable(false)
                dispatch(
                  getDoctorsTimesAsync(
                    info.event.extendedProps.frontContent.doctorId
                  )
                )
                .then(unwrapResult)
                .then((res: any) => {
                  const scheduleObj: any = res?.find(
                    (v: any) =>
                      v.scheduleDate == info.event.startStr.split("T")[0]
                    )
                    const data = {
                      id: info.event.id,
                      appointmentDate: info.event.startStr.split("T")[0],
                      appointmentEndTime: moment
                        .utc(info.event.endStr)
                        .format("HH:mm"),
                      appointmentStartTime: moment
                        .utc(info.event.startStr)
                        .format("HH:mm"),
                      scheduleId: scheduleObj.id,
                      appointmentDoctorId:
                        info.event.extendedProps.frontContent.doctorId,
                      sex: info.event.extendedProps.frontContent.sexValue,
                      ageYear: info.event.extendedProps.frontContent.ageYear,
                      appointmentDepartmentId:
                        info.event.extendedProps.frontContent
                          .appointmentDepartmentId,
                    }
                    dispatch(addAppointmentAsync(data))
                      .then(unwrapResult)
                      .then(() => {
                        getpageData()
                        setIsEditable(true)
                      })
                  })
              },
              onCancel: () => {
                info.revert()
                return false
              }
            })
          }}
          eventDrop={(info: any) => {
            console.log(info);
            if (info.event.id == "-1") {
              info.revert()
              return false
            }
            // 解析时间字符串
            const givenTime = new Date(info.event.startStr).getTime();
            // 获取当前时间
            const now = `${moment().format("YYYY-MM-DD")}T${moment().format(
              "HH:mm"
            )}:00Z`;
            const nowTime = new Date(now).getTime()
            if (givenTime < nowTime) {
              info.revert()
              return false
            }
            Modal.confirm({
              centered: true,
              title: '确认操作',
              content: (
                <div>
                  <ExclamationCircleFilled
                    style={{ color: '#FFAE55', fontSize: 18 }}
                  />
                  &nbsp;确定要修改预约吗？
                </div>
              ),
              okText: '确认',
              cancelText: '取消',
              icon: null,
              onOk: () => {
                setIsEditable(false)
                dispatch(
                  getDoctorsTimesAsync(
                    info.newResource
                      ? info.newResource.id
                      : info.event.extendedProps.frontContent.doctorId
                  )
                )
                .then(unwrapResult)
                .then((res: any) => {
                  const scheduleObj: any = res?.find(
                    (v: any) =>
                      v.scheduleDate == info.event.startStr.split("T")[0]
                  );

                  const data = {
                    id: info.event.id,
                    appointmentDate: info.event.startStr.split("T")[0],
                    appointmentEndTime: moment
                      .utc(info.event.endStr)
                      .format("HH:mm"),
                    appointmentStartTime: moment
                      .utc(info.event.startStr)
                      .format("HH:mm"),
                    scheduleId: scheduleObj.id,
                    appointmentDoctorId: info.newResource
                      ? info.newResource.id
                      : info.event.extendedProps.frontContent.doctorId,
                    sex: info.event.extendedProps.frontContent.sexValue,
                    ageYear: info.event.extendedProps.frontContent.ageYear,
                    appointmentDepartmentId:
                      info.event.extendedProps.frontContent
                        .appointmentDepartmentId,
                  };
                  console.log(data)

                  dispatch(addAppointmentAsync(data))
                    .then(unwrapResult)
                    .then(() => {
                      getpageData()
                    })
                });
              },
              onCancel: () => {
                info.revert()
                return false
              }
            })
          }}
          eventContent={(arg) => {
            if (!arg.backgroundColor) return; // 显示当前用户已选时间段
            const node = document.createElement("div")
            node.innerHTML =
              arg.backgroundColor != "#e8e8e8"
                ? `<div style="display: flex; align-items:start;justify-content: flex-start;">
            <img
              src="${getSVGSrc(
                Number(arg.event?.extendedProps?.frontContent.icon)
              )}"
              width="14"
              height="14"
              style="margin-top:4px"
            />
            <span style="font-size:14px;color:#222;margin-left:4px">
              <span style="font-size:17px;color:#222;margin-left:4px">${
                arg.event.title
              }</span>&nbsp;&nbsp;${
                    arg.event?.extendedProps?.frontContent.sex
                  }&nbsp;${arg.event?.extendedProps?.frontContent.age}岁
            <span>${
              arg.event?.extendedProps?.frontContent.appointmentItem
                ? "</br>预约项目：" +
                  arg.event?.extendedProps?.frontContent.appointmentItem
                : ""
            }
            </br>预约时间：${
              arg.event?.extendedProps?.frontContent.appointmentTime
            } 
            </span>
            </span>
              </div>`
                : "";
            const arrayOfDomNodes = [node];
            return { domNodes: arrayOfDomNodes }
          }}
        />
        {isModalVisible && (
          <div ref={modalRef} className={styles.modal}>
            <h2>
              <a
                onClick={() => {
                  if (cardData?.patientId) {
                    if (1 === edtion) {
                      dispatch(updatePatientTimeAsync(cardData?.patientId));
                      history.push({
                        pathname: "/toothPatient",
                        state: { selectId: cardData?.patientId },
                      });
                    } else {
                      dispatch(
                        traceRoute({
                          name: "门诊统计",
                          path: "/patient/detail",
                          query: `patientId=${cardData?.patientId}`,
                        })
                      );
                    }
                  }
                }}
              >
                {cardData.name}{" "}
              </a>
              <Tag color="#FFB453">
                {cardData?.receiveTag == 1 ? "复诊" : "初诊"}
              </Tag>
            </h2>
            <section>性别：{getGenderName(cardData?.sex)}</section>
            <section>
              年龄：
              {getAge(cardData?.ageYear, cardData?.ageMonth)}
            </section>
            <section>手机号：{cardData?.phone}</section>
            <section>
              预约时间：{cardData?.appointmentDate + "  "}
              {cardData?.appointmentStartTime}-{cardData?.appointmentEndTime}
            </section>
            <section>预约科室：{cardData?.appointmentDepartmentName}</section>
            <section>预约医生：{cardData?.appointmentDoctorName}</section>
            <section>预约项目：{cardData?.appointmentItem || "-"}</section>
            <section>预约备注：{cardData?.appointmentComment}</section>
            <Row justify="center" style={{ marginTop: 15 }}>
              {(cardData?.appointmentState == 0 ||
                cardData?.appointmentState == 4 ||
                cardData?.appointmentState == 9) && (
                <Space>
                  {cardData?.appointmentState == 0 && (
                    // <Popconfirm
                    //   title='确认进行此操作？'
                    //   onConfirm={() => {
                    //     dispatch(sureAppointmentAsync(cardData?.id))
                    //       .then(unwrapResult)
                    //       .then(() => {
                    //         getpageData()
                    //       })
                    //   }}
                    //   okText='确认'
                    //   cancelText='取消'
                    // >
                    <Button
                      size="small"
                      type="primary"
                      onClick={() => {
                        setSureData(cardData);
                        setSureVisible(true);
                        setChecked(false);
                      }}
                    >
                      确认
                    </Button>
                    // {/* </Popconfirm> */}
                  )}
                  <Button
                    size="small"
                    type="primary"
                    onClick={() => {
                      if (hisVersion == 1) {
                        setTableEditId(cardData.id);
                        setModalPatientID(cardData.patientId || "");
                        setRegisteredModal(true);
                      } else {
                        dispatch(
                          traceRoute({
                            name: "挂号",
                            path: "/registration",
                          })
                        );
                        dispatch(setAppointmentIdSlice(cardData?.id));
                      }
                    }}
                  >
                    挂号
                  </Button>
                  <Button
                    size="small"
                    type="primary"
                    onClick={() => {
                      if (hisVersion == 1) {
                        setTableEditId(cardData.id);
                        setModalType(2);
                        setAppointmentRegisterVisible(true);
                      } else {
                        history.push({
                          pathname: "/addEditAppointment",
                          state: {
                            id: cardData?.id,
                            activeKey: props?.activeKey,
                          },
                        });
                      }
                    }}
                  >
                    修改
                  </Button>
                  <Button
                    size="small"
                    type="primary"
                    onClick={() => {
                      if (hisVersion == 1) {
                        setTableEditId(cardData.id);
                        setDetailVisible(true);
                      } else {
                        history.push({
                          pathname: "/appointmentDetail",
                          state: {
                            id: cardData?.id,
                            appointmentState: cardData?.appointmentState,
                            tag: '0', //专业版标志位
                          },
                        });
                      }
                    }}
                  >
                    查看详情
                  </Button>
                </Space>
              )}
              {(cardData?.appointmentState == 0 ||
                cardData?.appointmentState == 4 ||
                cardData?.appointmentState == 9 ||
                cardData?.appointmentState == -1) && (
                <Popconfirm
                  title={
                    <div>
                      <p>确认进行此操作？</p>
                      <p style={{ position: "relative", left: "-20px" }}>
                        <Checkbox
                          checked={isSendMessage}
                          onChange={(e) => {
                            setIsSendMessage(e.target.checked);
                            localStorage.setItem(
                              "isSendMessage",
                              JSON.stringify(e.target.checked)
                            );
                          }}
                        >
                          是否向患者发送取消预约的通知短信
                        </Checkbox>
                      </p>
                    </div>
                  }
                  onConfirm={() => {
                    dispatch(
                      cancelAppointmentAsync({
                        id: cardData?.id,
                        sendSMSFlag: Number(isSendMessage),
                      })
                    )
                      .then(unwrapResult)
                      .then(() => {
                        getpageData();
                      });
                  }}
                  okText="确认"
                  cancelText="取消"
                >
                  <Button size="small" style={{ marginLeft: "8px" }}>
                    取消预约
                  </Button>
                </Popconfirm>
              )}
              {(cardData?.appointmentState == 1 ||
                cardData?.appointmentState == 2) && (
                <Space>
                  <Button
                    size="small"
                    type="primary"
                    onClick={() => {
                      if (cardData?.invalidFlag == 1) {
                        notification.info({
                          message: "挂号已取消，不能继续就诊",
                        });
                      } else {
                        if (1 === edtion) {
                          dispatch(updatePatientTimeAsync(cardData?.patientId));
                          history.push({
                            pathname: "/toothPatient",
                            state: { selectId: cardData?.patientId },
                          });
                        } else {
                          if (cardData?.registrationId) {
                            dispatch(
                              traceRoute({
                                name: "医生门诊",
                                path: "/treatment",
                                query: `registrationId=${cardData?.registrationId}`,
                              })
                            );
                          }
                        }
                      }
                    }}
                  >
                    就诊
                  </Button>
                </Space>
              )}
            </Row>
          </div>
        )}
      </div>
      <Row justify="end" style={{ marginTop: 5 }}>
        <Space>
          <AppointmentingIcon className={styles.appointmentIcon} /> 待确认
          <AppointmentingSureIcon className={styles.appointmentIcon} /> 已确认
          <OverTimeIcon className={styles.appointmentIcon} /> 超时
          <RegistrationedIcon className={styles.appointmentIcon} /> 已挂号
          <OutpatientedIcon className={styles.appointmentIcon} /> 已就诊
          <RunOffIcon className={styles.appointmentIcon} /> 流失
        </Space>
      </Row>
      <Modal
        width={1000}
        title={modalType == 1 ? "新增预约" : "修改预约"}
        destroyOnClose
        visible={appointmentRegisterVisible}
        onCancel={() => setAppointmentRegisterVisible(false)}
        footer={null}
      >
        <AppointmentRegister
          status={modalType}
          AppointmentId={Number(tableEditId)}
          onsubmit={() => {
            getpageData();
            setAppointmentRegisterVisible(false)
            setTableEditId("")
          }}
          onCancel={() => {
            setAppointmentRegisterVisible(false)
            setTableEditId("");
          }}
          addPatient={() => {
            setAppointmentRegisterVisible(false)
            setAddPatientModal(true)
          }}
        />
      </Modal>

      {/* 工作站-挂号 */}
      <RegisteredModal
        title={0}
        appointmentId={tableEditId}
        patientId={modalPatientID} //新增患者-保存并挂号
        visible={registeredModal}
        onOk={() => {
          getpageData()
          setRegisteredModal(false)
        }}
        onCancel={() => {
          setRegisteredModal(false)
        }}
      />

      <Modal
        width={1000}
        title="查看详情"
        destroyOnClose
        visible={detailVisible}
        onCancel={() => setDetailVisible(false)}
        footer={null}
      >
        <div>
          <AppointmentDetail
            id={tableEditId}
            status={String(hisVersion)}
            visibleTag={visibleTag}
          />
        </div>
      </Modal>

      {/* 新增患者弹窗 */}
      <AddPatientModal
        visible={addPatientModal}
        onOk={(num, patientId) => {
          setModalPatientID(patientId || "");
          if (num == 2) {
            setRegisteredModal(true);
          } else if (num == 3) {
            setSubscribeModal(true);
          }
          setAddPatientModal(false);
        }}
        onCancel={() => {
          setAddPatientModal(false);
        }}
      />

      {/* 工作站-预约 */}
      <SubscribeModal
        visible={subscribeModal}
        patientId={modalPatientID} //患者 ID
        treatmentDoctorId={""} //就诊医生 ID
        onOk={() => {
          // notification.success({
          //   message: '新增成功，请刷新页面'
          // })
          setSubscribeModal(false);
        }}
        onCancel={() => setSubscribeModal(false)}
      />

      <Modal
        width={500}
        title="确认操作"
        confirmLoading={loading}
        destroyOnClose
        visible={sureVisible}
        onCancel={() => setSureVisible(false)}
        onOk={() => {
          setLoading(true);
          dispatch(
            sureAppointmentAsync({ id: sureData?.id, flag: checked ? 1 : 0 })
          )
            .then(unwrapResult)
            .then(() => {
              setLoading(false)
              setSureVisible(false)
              getpageData()
            });
        }}
      >
        <div style={{ paddingLeft: "10px" }}>
          <Checkbox
            checked={checked}
            onChange={() => {
              setChecked(!checked)
            }}
          >
            发送短信 {sureData?.tenantName && "-"} 您已成功预约 请在
            {sureData?.appointmentDate}&nbsp;&nbsp;
            {sureData?.appointmentStartTime}
            {sureData?.appointmentStartTime && sureData?.appointmentEndTime
              ? "~" + sureData?.appointmentEndTime
              : ""}
            到挂号处登记信息。地址：{sureData?.tenantAddress || "-"} 电话：
            {sureData?.tenantPhone || "-"}
          </Checkbox>
          {/* {sureData?.smsSendFlg == 1 ? (<p>该预约已发送过短信</p>) : ''} */}
        </div>
      </Modal>
    </div>
  )
}
