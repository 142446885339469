import { unwrapResult } from '@reduxjs/toolkit'
import { Col, notification, Table } from 'antd'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { RootDispatch } from '../../../app/store'
import { EditableList } from '../../../compnents/list/EditableList'

import { ThemeContext } from '../../../theme/ThemeContext'
import { Columns } from './Columuns'
import { Query } from './Query'
import styles from './order.module.css'
import { getreplaceMakeOrderList } from '../OReplaceMakeSlice'
import { useHistory, useLocation } from 'react-router-dom'
import { isAutoUpload, isCancelUpload } from '../../payment/result/paymentResultSlice'
import { getUploadStatus } from '../../../models/OReplaceMakeSlice'
import { getDateRange, DateRangeType } from '../../../compnents/date/TimeSelect'

export interface CellParams {
  name?: string
  moneySum?: number
  drugMoneySum?: string
}
// 代煎订单详情
export const ReplaceMakeOrder = (): ReactElement => {
  const theme = useContext(ThemeContext)

  const history = useHistory()

  const dispatch = useDispatch<RootDispatch>()

  const [data, setData] = useState<any>([])

  const [pageLoading, setPageLoading] = useState(false) //表格 loading

  const [total, setTotal] = useState(0)

  const [cell, setCell] = useState<CellParams>({})

  const [loading, setLoading] = useState(false)

  const [selectId, setSelectId] = useState<any>()

  // const oldParams = sessionStorage.getItem('REPLACEMAKEORDER') ? JSON.parse(sessionStorage.getItem('REPLACEMAKEORDER')!) : null

  const [head, tail] = getDateRange(DateRangeType.ThisMonth)

  const [params, setParams] = useState<any>({
    current: 1,
    size: 10,
    createListTimeHead: head,
    createListTimeTail: tail,
  })

  useEffect(() => {
    init()
  }, [params])

  const init = () => {
    setPageLoading(true)
    dispatch(
      getreplaceMakeOrderList({
        ...params,
        createListTimeHead: params?.createListTimeHead || head,
        createListTimeTail: params?.createListTimeTail || tail,
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        setCell({
          name: res?.name,
          moneySum: res?.moneySum, // 处方合计金额
          drugMoneySum: res?.drugMoneySum, // 药方合计金额
        })
        setData(res?.res?.records)
        setTotal(res?.res?.total)
        // oldParams && sessionStorage.removeItem('REPLACEMAKEORDER')
      })
      .finally(() => {
        setPageLoading(false)
      })
  }

  // 代煎订单导出
  const onExport = () => {
    const param = {
      ...params,
      current: 1,
      size: 1000
    }
    const theader = [
      "门诊号",
      "患者姓名",
      "处方金额",
      "药方金额",
      "代煎类别",
      "上传状态",
      "结算状态",
      "开单时间",
      "上传时间"
    ];
    const filterVal = [
      'outpatientNum',
      'recipientName',
      'money',
      'drugMoney',
      'typeName',
      'status',
      'state',
      'createTime',
      'uploadTime',
    ]
    dispatch(getreplaceMakeOrderList({ ...param }))
      .then(unwrapResult)
      .then((res: any) => {
        const data = res?.res?.records
          .map((v: any) => ({
            ...v,
            // drugMoney: (v.drugMoney + v.money).toFixed(2),
            status: v.type === 1 ? getUploadStatus(v.status) : '-',
            state: v.state === 5 ? '已退费' : '已结算',
            uploadTime: v.type === 1 ? v.uploadTime : '-',
          }))
          ?.map((s: any) => filterVal?.map((j: any) => s[j]))
        import('../../../ExportXLSX/Export2Excel').then((excel) => {
          excel.export_json_to_excel({
            header: theader,
            data,
            filename: '代煎订单',
            autoWidth: true,
          })
        })
      })

  }

  return (
    <Col
      style={{
        height: 'calc(100% - 20px) ',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Col
        style={{
          flex: 1,
          overflow: 'hidden',
          backgroundColor: theme.pn,
          marginTop: 10,
          borderRadius: 10,
          padding: '10px 15px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Query
          // initValue={oldParams}
          onValueChange={(v) => {
            setParams({
              ...params,
              ...v,
              current: 1
            })
          }}
          onExport={onExport}
        />
        <EditableList
          page={{
            items: data,
            current: params.current || 1,
            size: params.size || 10,
            total,
          }}
          loading={pageLoading}
          rowKey={(_, i) => `${i}`}
          summary={() => (
            <Table.Summary fixed>
              <Table.Summary.Row className={styles.total}>
                <Table.Summary.Cell index={0}></Table.Summary.Cell>
                <Table.Summary.Cell className={styles.footingTitle} index={1}>
                  {cell.name}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2}></Table.Summary.Cell>
                <Table.Summary.Cell align='center' index={3}>
                  {cell.moneySum}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={4}></Table.Summary.Cell>
                <Table.Summary.Cell align='center' index={5}>
                  {cell.drugMoneySum}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={6}></Table.Summary.Cell>
                <Table.Summary.Cell index={7}></Table.Summary.Cell>
                <Table.Summary.Cell index={8}></Table.Summary.Cell>
                <Table.Summary.Cell index={9}></Table.Summary.Cell>
                <Table.Summary.Cell index={10}></Table.Summary.Cell>
                <Table.Summary.Cell index={11}></Table.Summary.Cell>
                <Table.Summary.Cell index={12}></Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          )}
          style={{ color: theme.tc3 }}
          className={styles.table}
          columns={Columns((action, t: any) => {
            switch (action) {
              case 'upload':
                setLoading(true)
                setSelectId(t.id)
                dispatch(isAutoUpload(t.id)).then(unwrapResult).then((res) => {
                  notification.success({ message: '代煎订单上传成功', duration: 5 })
                  init()
                  setLoading(false)
                }).catch(() => {
                  notification.error({ message: '代煎订单上传失败,请重新上传' })
                  setLoading(false)
                })
                break
              case 'cancelUpload':
                dispatch(isCancelUpload({
                  recipeId: t.id,
                  potionRecipeStatus: 3
                })).then(unwrapResult).then(() => {
                  notification.success({ message: '代煎订单取消上传成功', duration: 5 })
                  init()
                })
                //     .catch(() => {
                //   notification.error({ message: '代煎订单取消上传失败,请至详情页强制取消' })
                // })
                break
            }
          }, (params.size) * (params.current - 1),loading,selectId)}
          onChangePage={(current: number, size?: number) => {
            setParams({
              ...params,
              current: current,
              size: size || 10,
            })
          }}
          onRow={(t) => ({
            onDoubleClick: () => {
              sessionStorage.setItem('REPLACEMAKEORDER', JSON.stringify(params))
              history.push({
                pathname: '/OReplaceMake/OrderDetail',
                state: {
                  id: t.id,
                  status: t.status,
                  type: t.type,
                  settlementState:t?.state,
                },
              })
            },
          })}
        />
      </Col>
    </Col>
  )
}
