/*
 * @Descripttion:
 * @version:
 * @Author: suiyue
 * @email: 2194421430@qq.com
 * @Date: 2021-04-19 12:05:04
 * @LastEditors: linxi
 * @LastEditTime: 2024-10-18 14:11:55
 */
import { Button, Col, Form, Input, Modal, notification, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectEdition } from '../../../app/applicationSlice'
import { RootDispatch } from '../../../app/store'
import { TabBar, TabPane } from '../../../compnents/widgets/TabBar'
import styles from './insuranceContorl.module.css'
import {
  setSettingVisibile,
  settingDetailAsync,
  selectSettingVisibile,
  settingSaveAsync,
} from './InsuranceContorlSlice'
import { LimitTabBar } from './limit/Limit'
import { MedicineTabBar } from './medicine/Medicine'
import { MoneyTabBar } from './money/Money'
import { QRcode } from './QRcode/QRcode'
import { stringTrim } from '../../../utils/StringUtils'
import moment from 'moment'
import { Checks } from '../../../models/setting'
import { unwrapResult } from '@reduxjs/toolkit'
import { SelfRateInquirys } from './selfRateInquiry/selfRateInquiry'

export const InsuranceContorlTabBar = () => {
  const dispatch = useDispatch<RootDispatch>()

  const [settingForm] = Form.useForm()

  const [nextSendDay, setNextSendDay] = useState('')

  const hisVersion = useSelector(selectEdition) //his版本 0通用 1口腔

  const [isSettingVisible, setIsSettingVisible] = useState<any>()

  const [selectKey, setSelectKey] = useState(hisVersion == 1 ? '2' : '3')

  const rules = [
    {
      pattern: new RegExp(/^([0-9]+[\d]*(.[0-9]{1,2})?)$/, 'g'),
      message: '请输入保留小数两位以内的数',
    },
  ]

  const phoneRules = [
    {
      pattern: /^1\d{10}$/,
      message: '请输入正确的手机号',
    },
  ]

  const invoiceLayout = {
    labelCol: {
      span: 10,
    },
  }

  useEffect(() => {
    if (!isSettingVisible) {
      return
    } else {
      dispatch(settingDetailAsync())
        .then(unwrapResult)
        .then((res: any) => {
          const data: any = {}
          res.forEach((v: any) => {
            data[v.type] = v.value
          })
          setNextSendDay(
            res?.find((v: any) => v?.type == 27)?.updateTime?.split(' ')[0]
          )
          settingForm.setFieldsValue({
            ...data,
          })
        })
    }
  }, [isSettingVisible])

  return (
    <Col className={styles.container}>
      <TabBar
        defaultActiveKey={hisVersion == 1 ? '2' : '3'}
        destroyInactiveTabPane
        onChange={((v)=>{
          setSelectKey(v)
        })}
      >
        <TabPane key='3' tab='医保费用'>
          <MoneyTabBar />
        </TabPane>
        {hisVersion != 1 && (
          <TabPane key='1' tab='中草药帖均'>
            <MedicineTabBar />
          </TabPane>
        )}
        <TabPane key='2' tab='电子凭证扫码率'>
          <QRcode />
        </TabPane>

        <TabPane key='5' tab='医保支付限额'>
          <LimitTabBar />
          
        </TabPane>
        <TabPane key='4' tab='自费率查询'>
          <SelfRateInquirys selectedTab={selectKey}/>
        </TabPane>
      </TabBar>
      <Button
        type='primary'
        onClick={() => {
          // dispatch(setSettingVisibile(true))
          setIsSettingVisible(true)
        }}
        className={styles.btn}
      >
        设置预警线
      </Button>

      {/* 设置预警线 */}
      <Modal
        title='设置预警线'
        width={900}
        visible={isSettingVisible}
        onOk={() => settingForm.submit()}
        onCancel={() => {
          // dispatch(setSettingVisibile(false))
          setIsSettingVisible(false)
        }}
      >
        <Form
          form={settingForm}
          onFinish={(values) => {
            if (
              (values[Checks.SJHM] && !values[Checks.TSPL]) ||
              (!values[Checks.SJHM] && values[Checks.TSPL])
            ) {
              notification.error({
                message: '请完善手机号码或推送频率',
              })
              return
            }
            const params = Object.keys(values).map((v: any) => {
              return {
                type: v,
                value: values[v],
              }
            })
            dispatch(settingSaveAsync(params))
              .then(unwrapResult)
              .then(() => {
                notification.success({
                  message: '设置成功，请刷新页面',
                })
                setIsSettingVisible(false)
                // dispatch(setSettingVisibile(false))
              })
          }}
        >
          <Row style={{ marginLeft: '40px' }}>
            <Col style={{ marginLeft: '20px' }} span={18}>
              {hisVersion == 1 ? (
                ' '
              ) : (
                <Form.Item
                  {...invoiceLayout}
                  label='中草药年度帖均额度'
                  rules={rules}
                  getValueFromEvent={stringTrim}
                  name={Checks.ZCYNDJTED}
                >
                  <Input addonAfter='元' />
                </Form.Item>
              )}

              <Form.Item
                {...invoiceLayout}
                label='医保基金年度支付限额'
                rules={rules}
                getValueFromEvent={stringTrim}
                name={Checks.YBJJNDZFXE}
              >
                <Input addonAfter='元' />
              </Form.Item>
              <Form.Item
                {...invoiceLayout}
                label='医保列支医疗费年度支付限额'
                rules={rules}
                getValueFromEvent={stringTrim}
                name={Checks.YBLZYLFNDZFXE}
              >
                <Input addonAfter='元' />
              </Form.Item>
              <Form.Item
                {...invoiceLayout}
                label='人次人头比'
                rules={rules}
                getValueFromEvent={stringTrim}
                name={Checks.RCRTB}
              >
                <Input addonAfter='%' />
              </Form.Item>
              <Form.Item
                {...invoiceLayout}
                label='次均年度费用限额'
                rules={rules}
                getValueFromEvent={stringTrim}
                name={Checks.CJNDFYXE}
              >
                <Input addonAfter='元' />
              </Form.Item>
              <Form.Item
                {...invoiceLayout}
                label='人均年度费用限额'
                rules={rules}
                getValueFromEvent={stringTrim}
                name={Checks.RJNDFYXE}
              >
                <Input addonAfter='元' />
              </Form.Item>
              <Form.Item
                {...invoiceLayout}
                label='手机号码'
                rules={phoneRules}
                getValueFromEvent={stringTrim}
                name={Checks.SJHM}
              >
                <Input maxLength={11} />
              </Form.Item>
              <Form.Item name={Checks.TSPL} label='推送频率' {...invoiceLayout}>
                <Form.Item
                  rules={[
                    {
                      pattern: /^\d+$/,
                      message: '请输入正确数字',
                    },
                  ]}
                  getValueFromEvent={stringTrim}
                  name={Checks.TSPL}
                >
                  <Input
                    addonAfter='天/次'
                    onChange={(e) => {
                      const day = moment(new Date())
                        .add(e.target.value, 'days')
                        .format('YYYY/MM/DD')
                      setNextSendDay(day)
                      // setNextSendDay(day)
                    }}
                  />
                </Form.Item>
                <span>下次推送时间：{nextSendDay}</span>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </Col>
  )
}
