/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-09-06 13:53:43
 * @LastEditors: linxi
 * @LastEditTime: 2024-10-18 14:14:45
 */

import { createAsyncThunk } from '@reduxjs/toolkit'
import _ from 'lodash'
import { api, sendAsync } from '../../app/applicationSlice'
import { RootState, RootThunkApi } from '../../app/store'
import * as T from '../../services/FeeLimit'

export const getFeeLimitList = createAsyncThunk<
  void,
  T.FeeLimitListParams,
  RootThunkApi
>('payment/getFeeLimitList', async (params, api) => {
  return sendAsync(T.FeeLimitList(params), api)
})

