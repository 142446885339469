/*
 * @Descripttion:
 * @version:
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-09-27 14:33:56
 * @LastEditors: linxi
 * @LastEditTime: 2024-09-23 16:53:12
 */
import { SearchOutlined } from '@ant-design/icons'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Col,
  Form,
  Input,
  notification,
  Row,
  Select,
  Space,
  Table,
} from 'antd'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { RootDispatch } from '../../app/store'
import {
  DateRangeType,
  DateSelect,
  getDateRange,
} from '../../compnents/form/DateSelect'
import { EditableList } from '../../compnents/list/EditableList'
import { ThemeContext } from '../../theme/ThemeContext'
import { getPatientLabels } from '../patient/list/patientListSlice'
import { AddMembersModal } from './addMembersModal'
import { TopUpModal } from './topUpModal'
import { TopUpRecordModal } from './topUpRecordModal'
import { Columns } from './columns'
import styles from './MembersManagementPage.module.css'
import { useHistory } from 'react-router-dom'
import {
  getAddMembers,
  getMembersPage,
  getMembersTopUp,
} from './MembersManagementPageSlice'
interface MembersManagementPageProps {
  state?: string
  isMenu?: boolean
}

export const MembersManagementPage = ({
  state,
  isMenu = false,
}: MembersManagementPageProps): ReactElement => {
  const history = useHistory()

  const dispatch = useDispatch<RootDispatch>()

  const [form] = Form.useForm()

  const { Option } = Select

  // const [head, tail] = getDateRange(DateRangeType.ThisWeek)

  const theme = useContext(ThemeContext)

  const [labelList, setLabelList] = useState<any>([])

  const [params, setParams] = useState({ current: 0, size: 10 })

  const [page, setPage] = useState({ items: [], total: 0, balanceMoneySUM: 0 })

  const [pageLoading, setPageLoading] = useState(false) // 表格 loading

  const [addMembersVisible, setAddMembersVisible] = useState(false) // 新增会员弹窗

  const [addBtnType, setAddBtnType] = useState(false) // 新增会员弹窗

  const [topUpModalVisible, setTopUpModalVisible] = useState(false) // 充值 or 退款 弹窗

  const [membersId, setMembersId] = useState(0) // 会员 ID

  const [topUpRecordModalVisible, setTopUpRecordModalVisible] = useState(false) // 充值记录弹窗

  const [modalText, setModalText] = useState(0) // 充值 or 退款 弹窗标题

  const getTablePage = () => {
    setPageLoading(true)
    dispatch(getMembersPage(params))
      .then(unwrapResult)
      .then((res: any) => {
        setPage({
          balanceMoneySUM: res?.balanceMoneySUM,
          items: res?.memberPageVO?.records,
          total: res?.memberPageVO?.total,
        })
      })
      .finally(() => setPageLoading(false))
  }

  const getLabelFn = (data: any) => {
    dispatch(getPatientLabels(data))
      .then(unwrapResult)
      .then((res: any) => setLabelList(res))
  }

  useEffect(() => {
    !isMenu &&
      sessionStorage.setItem(location.pathname, JSON.stringify({ state }))
  }, [state])
  useEffect(() => {
    params.current && getTablePage()
  }, [params])

  useEffect(() => {
    getLabelFn({ current: 1, size: 50 })
    // form.setFieldsValue({
    //   actionTimeHead: head,
    //   actionTimeTail: tail,
    // })
    form.submit()
  }, [])

  return (
    <Col className={styles.wrap}>
      <Form
        form={form}
        autoComplete='off'
        onFinish={(values) => {
          setParams({
            ...params,
            ...values,
            labelIds: values?.labelIds?.join(','),
            startTime: values?.actionTimeHead,
            endTime: values?.actionTimeTail,
            actionTimeHead: undefined,
            actionTimeTail: undefined,
            current: 1,
          })
        }}
      >
        <Row
          style={{ marginBottom: 15 }}
          align='middle'
          justify='space-between'
        >
          <Space>
            <Form.Item name='keyWord' noStyle>
              <Input
                allowClear
                style={{ width: 315 }}
                onChange={form.submit}
                placeholder='请输入患者姓名/手机号/助记码'
                prefix={<SearchOutlined style={{ color: theme.tc3 }} />}
              />
            </Form.Item>
            <Form.Item name='labelIds' noStyle>
              <Select
                showSearch
                placeholder='标签选择'
                optionFilterProp='children'
                style={{ width: 180 }}
                mode='multiple'
                onChange={form.submit}
                onSearch={(v) => getLabelFn({ current: 1, size: 50, name: v })}
                filterOption={false}
              >
                {labelList.length &&
                  labelList.map((v: any) => (
                    <Option key={v.id} value={v.id}>
                      {v.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
            <DateSelect
              style={{ width: '8.5rem' }}
              labelPrefix=''
              namePrefix='actionTime'
              placeholder='时间范围-时间'
              // initialValue={DateRangeType.ThisWeek}
              onChange={form.submit}
            />
            <Button type='primary' htmlType='submit'>
              查询
            </Button>
          </Space>
          <Space>
            <Button type='primary' onClick={() => setAddMembersVisible(true)}>
              新增会员
            </Button>
          </Space>
        </Row>
      </Form>

      <EditableList
        className={styles.patientLable}
        loading={pageLoading}
        page={{
          items: page.items,
          current: params.current,
          size: params.size,
          total: page.total,
        }}
        onChangePage={(current, size) =>
          setParams({ ...params, current, size: size || 10 })
        }
        columns={Columns((text, t) => {
          if (text === 'topUp') {
            setMembersId(t.id)
            setTopUpModalVisible(true)
            setModalText(0)
          } else if (text == 'refund') {
            setTopUpModalVisible(true)
            setModalText(1)
          } else if (text == 'topUpRecord') {
            setTopUpRecordModalVisible(true)
          } else if (text == 'detail') {
            history.push({
              pathname: '/statistics/outpatient/membersManagement',
              state: {
                membersId: t.id,
                isMenu,
              },
            })
          }
          setMembersId(t.id)
        }, params.size * (params.current - 1))}
        summary={() => (
          <Table.Summary fixed='bottom'>
            <Table.Summary.Row className={styles.total}>
              <Table.Summary.Cell index={0}></Table.Summary.Cell>
              <Table.Summary.Cell index={1} align='center'>
                合计
              </Table.Summary.Cell>
              <Table.Summary.Cell index={2}></Table.Summary.Cell>
              <Table.Summary.Cell index={3}></Table.Summary.Cell>
              <Table.Summary.Cell index={4} align='center'>{page?.balanceMoneySUM?.toFixed(2)}</Table.Summary.Cell>
              <Table.Summary.Cell index={5}></Table.Summary.Cell>
              <Table.Summary.Cell index={6}></Table.Summary.Cell>
              <Table.Summary.Cell index={7}></Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )}
        onRow={(t) => ({
          onDoubleClick: () => {
            history.push({
              pathname: '/statistics/outpatient/membersManagement',
              state: {
                membersId: t.id,
                isMenu,
              },
            })
          },
        })}
      />

      <AddMembersModal // 新增会员
        visible={addMembersVisible}
        btnType={addBtnType}
        onCancel={() => setAddMembersVisible(false)}
        onOk={(data) => {
          dispatch(getAddMembers(data))
            .then(unwrapResult)
            .then(() => {
              setAddMembersVisible(false)
              setParams({
                ...params,
              })
            })
            .finally(() => setAddBtnType(!addBtnType))
        }}
      />
      <TopUpModal //充值 or 退款
        visible={topUpModalVisible}
        btnType={addBtnType}
        membersId={membersId}
        modalText={modalText}
        onCancel={() => setTopUpModalVisible(false)}
        onOk={(data) => {
          dispatch(getMembersTopUp(data))
            .then(unwrapResult)
            .then(() => {
              setTopUpModalVisible(false)
              setParams({
                ...params,
              })
              notification.success({
                message: modalText ? '退款成功' : '充值成功',
              })
            })
            .finally(() => setAddBtnType(!addBtnType))
        }}
      />
      <TopUpRecordModal // 充值记录
        visible={topUpRecordModalVisible}
        membersId={membersId}
        onCancel={() => setTopUpRecordModalVisible(false)}
      />
    </Col>
  )
}
