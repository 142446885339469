import { createAsyncThunk } from '@reduxjs/toolkit'
import _ from 'lodash'
import { api, sendAsync } from '../../../../app/applicationSlice'
import { RootState, RootThunkApi } from '../../../../app/store'
import * as T from '../../../../services/selfRateInquiry'

export const getSelfRateInquiryList = createAsyncThunk<
  void,
  T.SelfRateInquiryListParams,
  RootThunkApi
>('payment/getSelfRateInquiryList', async (params, api) => {
  return sendAsync(T.SelfRateInquiryList(params), api)
})

