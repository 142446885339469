import { CaretDownFilled, SearchOutlined } from '@ant-design/icons'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Form,
  Input,
  Row,
  Select,
  Space,
  Cascader as Cascaded,
} from 'antd'
import React, {
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { selectInsuranceArray } from '../../../app/applicationSlice'
import { RootDispatch } from '../../../app/store'
import {
  DateRangeType,
  DateSelect,
  getDateRange,
} from '../../../compnents/form/DateSelect'
import { PaymentListParams } from '../../../services/payment'
import { ThemeContext } from '../../../theme/ThemeContext'
import { uploadOptions } from '../../InventoryChecking/query'
import {
  getAllDepartmentUser,
  selectDepartments,
} from '../../payment/list/paymentSlice'

export const Query = (props: {
  onValueChange: (params: PaymentListParams) => void
  allUpload:()=>void
  state?: any
  buttonLoading?:any

}): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const theme = useContext(ThemeContext)

  const [form] = Form.useForm()

  const inputRef = useRef<any>(null)

  const { Option } = Select

  const location: any = useLocation()

  const departments = useSelector(selectDepartments)

  const insuranceArray = useSelector(selectInsuranceArray)

  const storageParams = JSON.parse(
    sessionStorage.getItem(location.pathname + '_medicalRecordList') || '{}'
  )

  const [allList, setAllList] = useState<any>([])

  const loadData = (selectedOptions: any) => {
    const targetOption = selectedOptions[selectedOptions.length - 1]
    targetOption.loading = true
  }

  const getAllDepartment = () => {
    dispatch(getAllDepartmentUser())
      .then(unwrapResult)
      .then((res: any) => {
        setAllList(
          res.map((v: any) => {
            return {
              label: v.name,
              value: v.id,
              children: v.children.map((i: any) => {
                return {
                  label: i.name,
                  value: i.id,
                }
              }),
            }
          })
        )
      })
  }

  useEffect(() => {
    getAllDepartment()
    if (
      Object.keys(storageParams).length &&
      props.state == storageParams.state
    ) {
      form.setFieldsValue({
        ...storageParams,
        // param: '', // 防止缓存数据输入
      })
      if (
        storageParams.treatmentDepartmentId &&
        storageParams.treatmentDoctorId
      ) {
        form.setFieldsValue({
          allData: [
            storageParams.treatmentDepartmentId,
            storageParams.treatmentDoctorId,
          ],
        })
      } else if (storageParams.treatmentDepartmentId) {
        form.setFieldsValue({
          allData: [storageParams.treatmentDepartmentId],
        })
      }
    } else {
      const [head, tail] = getDateRange(DateRangeType.Today)
      form.setFieldsValue({
        _dateRange: 0,
        registrationTimeHead: head,
        registrationTimeTail: tail,
      })
    }
    inputRef.current!.focus({
      //获取输入框焦点
      cursor: 'start',
    })
    form.submit()
  }, [])

  const refresh = () => {
    const tempArr = form.getFieldsValue().allData
    props.onValueChange({
      ...storageParams,
      ...form.getFieldsValue(),
      treatmentDepartmentId: tempArr?.length ? tempArr[0] : undefined,
      treatmentDoctorId: tempArr?.length == 2 ? tempArr[1] : undefined,
      treatmentDepartmentName: tempArr?.length
        ? allList.filter((v: any) => v.value == tempArr[0])[0].label
        : undefined,
      treatmentDoctorName:
        tempArr?.length == 2
          ? allList
              .filter((v: any) => v.value == tempArr[0])[0]
              .children.filter((i: any) => i.value == tempArr[1])[0].label
          : undefined,
      allData: undefined,
      current: 1,
      state: props.state,
    })
  }

  return (
    <Form
      form={form}
      onFinish={(values) => {
        const data = storageParams
        props.onValueChange({
          ...data,
          ...values,
          current: data.current || 1,
          state: 3,
          allData: undefined,
        })
      }}
    >
      <Row style={{ marginTop: 10, marginBottom: 20 }} justify='space-between'>
        <Space>
          <Form.Item noStyle name='param'>
            <Input
              ref={inputRef}
              autoComplete='off'
              prefix={<SearchOutlined />}
              placeholder='患者姓名/手机号/病历号'
              style={{ width: '16rem' }}
              allowClear
              onChange={refresh}
            />
          </Form.Item>
          {insuranceArray && insuranceArray.length !== 0 && (
            <Form.Item name='insuranceCode' noStyle>
              <Select
                placeholder='险种类型'
                style={{ width: '8.25rem' }}
                allowClear
                // suffixIcon={<CaretDownFilled />}
                onChange={refresh}
              >
                <Select.Option value={''}>全部</Select.Option>
                <Select.Option value={'ZIFEI'}>自费</Select.Option>
                {insuranceArray.map((v) => (
                  <Select.Option value={v.insuranceCode} key={v.id}>
                    {v.insuranceName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
          <Form.Item name='wjUploadFlag' noStyle>
            <Select
              placeholder='上传状态'
              style={{ width: '8.25rem' }}
              allowClear
              // suffixIcon={<CaretDownFilled />}
              onChange={refresh}
            >
              <Select.Option value={0}>全部</Select.Option>
              <Select.Option value={1}>已上传</Select.Option>
              <Select.Option value={2}>未上传</Select.Option>
            </Select>
          </Form.Item>
          <DateSelect
            allowClear={false}
            style={{ width: '8.5rem' }}
            labelPrefix=''
            namePrefix='registrationTime'
            placeholder='时间范围-时间'
            // initialValue={DateRangeType.Today}
            onChange={refresh}
          />
          <Button type='primary' onClick={refresh}>
            查询
          </Button>
        </Space>
        <Space>
          <Button
            type='primary'
            loading={props?.buttonLoading}
            onClick={() => {
              props?.allUpload && props?.allUpload()
            }}
          >
            批量上传
          </Button>
        </Space>
      </Row>
    </Form>
  )
}
