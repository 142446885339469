/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2022-12-27 16:20:35
 * @LastEditors: linxi
 * @LastEditTime: 2024-09-24 17:12:03
 */
import { Space, Switch, TableColumnType } from 'antd'
import moment from 'moment'
import { DateTimeFormatSimpleOnly } from '../../../../models/datetime'

type ActionType = 'edit' | 'stateEdit'

export const columns = (
  onClick: (action: ActionType, v: any, state?: any) => void,
  startIndex: number
): TableColumnType<any>[] => {
  return [
    {
      title: '门诊日期',
      dataIndex: 'registionDate',
      align: 'center',
      ellipsis: true,
      render: (_, r) => {
        return r.registionDate
          ? moment(r.registionDate).format(DateTimeFormatSimpleOnly)
          : '-'
      },
    },

    {
      title: '初诊人次',
      dataIndex: 'fristVisitNumber',
      align: 'center',
      ellipsis: true,
      render: (_, r) => {
        return r.fristVisitNumber ? r.fristVisitNumber : '0'
      },
    },
    {
      title: '复诊人次',
      dataIndex: 'returnVisitNumber',
      align: 'center',
      ellipsis: true,
      render: (_, r) => {
        return r.returnVisitNumber ? r.returnVisitNumber : '0'
      },
    },

    {
      title: '总人次',
      dataIndex: 'visitNumber',
      align: 'center',
      ellipsis: true,
      render: (_, r) => {
        return r.visitNumber ? r.visitNumber : '0'
      },
    },
    {
      title: '挂号费',
      dataIndex: 'realPayAmount',
      align: 'right',
      ellipsis: true,
      render: (_, r) => {
        return r.realPayAmount > 0 ? r.realPayAmount?.toFixed(2) : '0.00'
      },
    },
    {
      title: '总金额',
      dataIndex: 'totalPayAmount',
      align: 'center',
      ellipsis: true,
      render: (_, r) => {
        return r.totalPayAmount > 0 ? r.totalPayAmount?.toFixed(2) : '0.00'
      },
    },
    {
      title: '现金金额',
      dataIndex: 'cashAmount',
      align: 'center',
      ellipsis: true,
      render: (_, r) => {
        return r.cashAmount > 0 ? r.cashAmount?.toFixed(2) : '0.00'
      },
    },
    {
      title: '医保金额',
      dataIndex: 'insurancePayAmount',
      align: 'center',
      ellipsis: true,
      render: (_, r) => {
        return r.insurancePayAmount > 0 ? r.insurancePayAmount?.toFixed(2) : '0.00'
      },
    },
  ]
}
