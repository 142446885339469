/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-09-06 13:53:28
 * @LastEditors: linxi
 * @LastEditTime: 2023-09-19 09:52:26
 */
import { unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Form,
  Row,
  Select,
  Space,
  Cascader as Cascaded,
  DatePicker,
  Checkbox,
} from 'antd'
import { unwatchFile } from 'fs'
import moment from 'moment'
import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectInsuranceArray,
  selectTenantCountyCode,
} from '../../app/applicationSlice'
import { RootDispatch } from '../../app/store'
import {
  DateTimeFormatSimple,
  DateTimeFormatSimpleOnlyOne,
} from '../../models/datetime'
import { getAnnualCapitalFundAreaList } from './AnnualCapitalFundSlice'

export const Query = (props: {
  onValueChange: (params: any) => void
}): ReactElement => {
  const [form] = Form.useForm()

  const { RangePicker } = DatePicker

  const dispatch = useDispatch<RootDispatch>()

  const [flag, setFlag] = useState<any>()

  const [timeList, setTimeList] = useState<any>()

  const [areaList, setAreaList] = useState<any>()

  const insuranceArray = useSelector(selectInsuranceArray)

  const tenantCountyCode = useSelector(selectTenantCountyCode)

  useEffect(() => {
    setTimeList([moment().startOf('year'), moment().endOf('day')])
    form.setFieldsValue({
      time: [moment().startOf('year'), moment().endOf('day')],
      // codes: areaList?.map((v: any) => v?.regionCode),
      codes: tenantCountyCode?.split(','),
    })
    form.submit()
  }, [tenantCountyCode])

  const disabledDate: any = (current: any) => {
    return timeList?.[0]
      ? moment(current)?.format('yyyy') != moment(timeList?.[0])?.format('yyyy')
      : false
  }

  useEffect(() => {
    if (flag == '0') {
      dispatch(getAnnualCapitalFundAreaList())
        .then(unwrapResult)
        .then((v) => {
          if (v?.length) {
            setAreaList(v)
          } else {
            setAreaList([])
          }
        })
    }
  }, [flag])

  return (
    <Form
      form={form}
      onFinish={(values) => {
        const startTime = moment(values?.time?.[0])?.format(
          DateTimeFormatSimpleOnlyOne
        )
        const endTime = moment(values?.time?.[1])?.format(
          DateTimeFormatSimpleOnlyOne
        )
        props.onValueChange({
          ...values,
          timeHead: startTime,
          timeTail: endTime,
          time: undefined,
          codes: values?.yidibz == '0' ? values?.codes : [],
        })
      }}
    >
      <Row style={{ marginLeft: 4, marginBottom: 10 }} justify='space-between'>
        <Space>
          <Form.Item name='time' noStyle>
            <RangePicker
              format={DateTimeFormatSimple}
              style={{ width: '16em' }}
              disabledDate={disabledDate}
              onCalendarChange={(v: any) => {
                setTimeList(v)
              }}
            />
          </Form.Item>
          <Form.Item name='xianzhonglx' noStyle>
            <Select
              placeholder='医保险种'
              style={{ width: '10rem' }}
              allowClear
            >
              <Select.Option value={'310'}>职工医疗保险</Select.Option>
              <Select.Option value={'390'}>居民医疗保险</Select.Option>
              <Select.Option value={'340'}>离休人员医疗保险</Select.Option>
              <Select.Option value={'510'}>职工生育保险</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name='yidibz' noStyle>
            <Select
              placeholder='状态'
              style={{ width: '8rem' }}
              allowClear
              onChange={(v) => {
                setFlag(v)
              }}
            >
              <Select.Option value={''}>全部</Select.Option>
              <Select.Option value={'0'}>本地</Select.Option>
              <Select.Option value={'1'}>省内异地</Select.Option>
              <Select.Option value={'2'}>省外异地</Select.Option>
            </Select>
          </Form.Item>

          <Button
            type='primary'
            onClick={() => {
              form.submit()
            }}
          >
            查询
          </Button>
        </Space>
      </Row>
      {flag == '0' && (
        <Row style={{ marginRight: 4, minHeight: '40px' }}>
          <Form.Item
            noStyle
            label=''
            name='codes'
            rules={[
              {
                required: true,
                message: '请至少勾选一项',
              },
            ]}
          >
            <Checkbox.Group>
              <Row>
                {areaList?.map((v: any) => {
                  return (
                    <>
                      <Checkbox
                        value={v?.regionCode}
                        style={{ lineHeight: '30px' }}
                      >
                        {v?.regionName}
                      </Checkbox>
                    </>
                  )
                })}
              </Row>
            </Checkbox.Group>
          </Form.Item>
        </Row>
      )}
      <Row style={{ marginLeft: 4, marginBottom: 10 }}>
        <span>时间不支持跨年度查询；</span>
        <span style={{ color: 'red', marginRight: '4px' }}> * </span>
        <span>
          查询时间范围内的结算记录中，身份证重复的算1人头；统筹基金 =
          基金支付总额 - 公务员补助；人头基金 = （基金支付总额 + 当年账户支出
          ）/ 人头数；注：统计时将剔除单据（统筹+公补+当年账户）支出为0的数据
        </span>
      </Row>
    </Form>
  )
}
