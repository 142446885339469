/* eslint-disable react/display-name */
import {
  AlipayCircleFilled,
  CreditCardFilled,
  DollarCircleFilled,
  QuestionCircleOutlined,
  SettingOutlined,
  WechatFilled,
} from '@ant-design/icons'
import Decimal from 'decimal.js'

import { unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Col,
  Divider,
  Input,
  message,
  Modal,
  notification,
  Form,
  Row,
  Select,
  Space,
  Table,
  Tooltip,
  Typography,
  Checkbox,
  Tag,
  Popover,
} from 'antd'
import moment from 'moment'
import React, {
  forwardRef,
  ReactElement,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import {
  selectUserName,
  selectUserId,
  selectTenantAddress,
  selectTenantAreaCd,
  selectTenantName,
  selectTenantCategory,
  selectInsuranceArray,
  selectEdition,
  selectTenantId,
} from '../../../app/applicationSlice'
import { RootDispatch } from '../../../app/store'
import { GlobalKeyboardAction } from '../../../compnents/actions/GlobalKeyboardAction'
import { NavBar } from '../../../compnents/nav/NavBar'
import { Label } from '../../../compnents/widgets/Label'
import {
  selectInsuranceItem,
  setInsurance,
} from '../../../layouts/insuranceSlice'
import { Breadcrumb, traceRoute } from '../../../layouts/layoutSlice'
import { fromJson, PaymentConfirmation } from '../../../models/confirmation'
import { DateTimeFormatSimpleOnlyOne } from '../../../models/datetime'
import { getTradeCode, readTypes } from '../../../models/material'
import {
  getIconImg,
  getPayMethodName,
  PaymentRegistrationParams,
  PayMethod,
} from '../../../models/payment'
import { Checks, Settings } from '../../../models/setting'
import { serverType } from '../../../services/commodity'
import { ThemeContext } from '../../../theme/ThemeContext'
import { useQuery } from '../../../utils/HttpUtils'
import {
  strParse,
  medicalSendHttp,
  medicalFaceSendHttp,
  readCardSHttp,
  readCardEHttp,
  QZMedicalFaceSendHttp,
  getTradeName,
  timeOut,
  doCheckDoctorAttendance,
  BaoJiaQi,
  LSMedicalFaceSendHttp,
} from '../../../utils/MedicalUtils'
import { selectNumberSetting } from '../../settings/settingsSlice'
import styles from './PaymentConfirm.module.css'
import {
  clearConfirmation,
  confirmPayment,
  pay,
  selectConfirmation,
  saveSettlementAsync,
  faceCallAsync,
  inprocessAnalysisAsync,
  updateCardInfoAsync,
  JuhePayFn,
  SearchJuhePay,
  JuhePayCancle,
  QZFaceCallAsync,
  LSFaceCallAsync,
  getSingleDiscount,
  doBatchPayAsync,
  getPaymentWayList,
} from './paymentConfirmSlice'
import {
  selectSettings,
  selectTenant,
} from '../../../features/user/setting/settingSlice'
import { JuhePay, MembersIcon } from '../../../compnents/icons/Icons'
import { printTemplate } from '../../print/printSlice'
import { SearchMembersModal } from '../../membersManagement/searchMembersModal'
import { AddMembersModal } from '../../membersManagement/addMembersModal'
import { MembersSettlementModal } from './membersSettlementModal'
import {
  getAddMembers,
  getMembersTopUp,
  getSearchMembers,
} from '../../membersManagement/MembersManagementPageSlice'
import { TopUpModal } from '../../membersManagement/topUpModal'
import {
  setActiveObj,
  updatePatientTimeAsync,
} from '../../A-toothModule/patient/patientSlice'
import { JuheIptShowModal } from './JuheIptShowModal'
import { ChargeSettingModal } from './ChargeSettingModal'
import { sum } from 'lodash'
import { getCardType } from '../JuhePayPage/columns'
import { PrintTemplateCategory } from '../../../models/print'
import { PrintTemplateModal } from '../../print/PrintTemplateModal'

const { Text } = Typography
const { TextArea } = Input
let timer: any = null

export interface PaymentConfirmRef {
  reset: () => void
  submit: () => void
}
interface PaymentConfirmParams {
  forceCash?: boolean
  confirmation?: PaymentConfirmation
  membersStatus?: number
  status?: number //his 口腔收费
  onChargeSubmit?: () => void //his 口腔收费
  onChargeCancel?: () => void //his 口腔收费
  onPay?: (params: PaymentRegistrationParams) => void
  batchFlag?: boolean //批量结算标志
  YUSHOUGUAHAOFEI?: boolean // 判断换是否为预收挂号费
  YUSHOUtreatment?: any //预收挂号费
  isPayPage?: boolean //收费页面的预收挂号费需要走2205 2204 2206 2207
  chrgBchno?: any //挂号2
}

const RealPaymentConfirm = (
  props: PaymentConfirmParams,
  ref: React.Ref<PaymentConfirmRef>
): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const [isModalVisible, setIsModalVisible] = useState(false)

  const { CheckableTag } = Tag

  const theme = useContext(ThemeContext)

  const location = useLocation<{
    where: [string, string]
    backtrace: Breadcrumb
    routerLink: boolean
    fromToothPatient?: any
    prePay: any
    treatment: any
    settlementCategory?: any
    backHome?: boolean
    toothRegistrationId?: boolean
  }>()

  const query = useQuery()

  useImperativeHandle(ref, () => ({
    reset: () => {
      setAmount('')
      setReceivedAmount('')
      setPayMethod([PayMethod.Cash])
    },
    submit: () => {
      if (!payMethod?.length) {
        notification.error({
          message: '请至少选择一种收费方式',
        })
      } else {
        if (props.onPay) {
          const recipeIdList = props.confirmation?.ids
            ? String(props.confirmation?.ids).split(',')
            : []
          if (props.YUSHOUGUAHAOFEI) {
            if (
              recipeIdList &&
              recipeIdList.length &&
              recipeIdList[0] !== 'undefined'
            ) {
              // 医保预收挂号费
              // saveSettlement('requestString', registerData, params)
              saveSettlement()
            } else {
              props.onPay({
                // 自费预收挂号费
                payMethod: payMethod?.join(','),
                systemPayMethod: !systemPayMethod.length
                  ? [
                      {
                        payAmount: '0.00',
                        payMethod: 1,
                      },
                    ]
                  : systemPayMethod,
                realPayAmount: oweMoneNumShow
                  ? amount
                  : combinationChargeFlag
                  ? zHSFAllMoney
                  : receivedAmount,
                receivePayAmount: combinationChargeFlag
                  ? zHSFAllMoney
                  : receivedAmount,
                recipeIdList,
                shouldPayAmount: amount,
              })
            }
          } else {
            props.onPay({
              // 自费预收挂号费
              payMethod: payMethod?.join(','),
              systemPayMethod: !systemPayMethod.length
                ? [
                    {
                      payAmount: '0.00',
                      payMethod: 1,
                    },
                  ]
                : systemPayMethod,
              realPayAmount: oweMoneNumShow
                ? amount
                : combinationChargeFlag
                ? zHSFAllMoney
                : receivedAmount,
              receivePayAmount: combinationChargeFlag
                ? zHSFAllMoney
                : receivedAmount,
              recipeIdList,
              shouldPayAmount: amount,
            })
          }
        }
      }
    },
  }))

  const readCardRef = useRef<any>(null)

  const inputRef = useRef<any>(null)

  const remoteConfirmation = useSelector(selectConfirmation)

  const [confirmation, setConfirmation] = useState<
    PaymentConfirmation | undefined
  >()
  const [sendBjqFlag, setSendBjqFlag] = useState(false)

  const [amount, setAmount] = useState('')
  //是否手动触发过二次读卡
  const [twiceReadDone, setTwiceReadDone] = useState(false)
  const [singleDiscount, setSingleDiscount] = useState('100')

  const [submitFlag, setSubmitFlag] = useState(false)

  const [loading, setLoading] = useState(false)

  const [receivedAmount, setReceivedAmount] = useState('')

  const [payCode, setPayCode] = useState('')

  const [discount, setDiscount] = useState('0.00') //找零金额

  const [oweMoneNum, setOweMoneNum] = useState('0.00') //欠费金额

  const [settlementBtnText, setSettlementBtnText] = useState('结算（空格）') //结算按钮文本

  const [oweMoneNumShow, setOweMoneNumShow] = useState(false) //欠费显示

  const [prePay, setPrePay] = useState<any>()

  const [treatment, setTreatment] = useState<any>()

  const [inprocessAnalysisList, setInprocessAnalysisList] = useState<any[]>()

  const baseData = useSelector(selectInsuranceItem)

  const userName = useSelector(selectUserName)

  const userId = useSelector(selectUserId)

  const tenantAddress = useSelector(selectTenantAddress)

  const tenantAreaCd = useSelector(selectTenantAreaCd)

  const tenantName = useSelector(selectTenantName)

  const insuranceArray = useSelector(selectInsuranceArray)

  const insuranceItem =
    insuranceArray.find((v) => v.insuranceCode === treatment?.insuranceCode) ||
    insuranceArray[0]

  const tenantCategory = useSelector(selectTenantCategory)

  const cycleCode = sessionStorage.getItem('cycleCode')

  const edtion = useSelector(selectEdition) //区分口腔版还是普通

  const [readLoading, setReadLoading] = useState(false)

  const [readCardValue, setReadCardValue] = useState(1)

  const JuheShow: any = useSelector(selectTenant) //是否显示聚合支付按钮/type 是否欠费

  const [form] = Form.useForm()

  const [cancelPayBtn, setCancelPayBtn] = useState(false) //撤销收款按钮是否显示

  const [JuheIptShow, setJuheIptShow] = useState(false) //扫码输入框是否显示

  const [printTemplateCategory, setPrintTemplateCategory] = useState<
    PrintTemplateCategory | undefined
  >()
  const [
    isChargeSettingModalVisible,
    setIsChargeSettingModalVisible,
  ] = useState(false)

  const [zdzkShow, setZdzkShow] = useState(false) //整单折扣计算中

  const [JuheIptDisabled, setJuheIptDisabled] = useState(false) //扫码输入框是否禁用

  const [alreadyCalledApi, setAlreadyCalledApi] = useState<any>()

  const [juhePayFlag, setJuhePayFlag] = useState(false) //聚合支付内容是否显示

  const [isJuheModalVisible, setIsJuheModalVisible] = useState(false) //聚合支付模态框

  const [juhePayId, setjuhePayId] = useState() //聚合支付保存ID

  const [timerFlag, setTimerFlag] = useState(false) //聚合循环调用接口

  const [JuheText, setJuheText] = useState('请扫描（微信/支付宝）付款码') //聚合循环调用接口

  const JuheCost =
    (parseFloat(amount) && parseFloat(amount)?.toFixed(2)) ||
    confirmation?.amount?.toFixed(2) //聚合支付应收费用

  const [urgencyAccount, setUrgencyAccount] = useState(false) //紧急结算

  const [urgencyVisible, setUrgencyVisible] = useState(false)

  const defaultPayMethod = useSelector(
    selectNumberSetting(Settings.Payment.DefautMethod)
  ) as PayMethod

  const [payMethod, setPayMethod] = useState<any[]>([PayMethod.Cash])

  const [isHyModalVisible, setIsHyModalVisible] = useState(false)

  const [membersInfo, setMembersInfo] = useState<any>() //会员信息弹窗

  const [membersStatus, setMembersStatus] = useState(0) //会员登出

  const [searchMembersModalVisible, setSearchMembersModalVisible] = useState(
    false
  ) //搜索会员弹窗

  const [addMembersVisible, setAddMembersVisible] = useState(false) // 新增会员弹窗

  const [addBtnType, setAddBtnType] = useState(false) // 新增会员弹窗

  const [membersSettlementModal, setMembersSettlementModal] = useState(false) // 会员结算弹窗

  const [topUpModalVisible, setTopUpModalVisible] = useState(false) // 充值 or 退款 弹窗

  const [paymentWayList, setPaymentWayList] = useState<any>()

  const [allPaymentWayList, setAllPaymentWayList] = useState<any>()

  const [selectZuHeOrNoZuHeList, setSelectZuHeOrNoZuHeList] = useState<any>()

  const toothHomeReturnTag = sessionStorage.getItem('toothHomeReturnTag')

  const [combinationChargeFlag, setCombinationChargeFlag] = useState(false)

  const [systemPayMethod, setSystemPayMethod] = useState<any[]>([])

  const [zHSFAllMoney, setZHSFAllMoney] = useState<any>()

  const inputRefs = selectZuHeOrNoZuHeList?.map(() => React.createRef())

  const fromToothPatient = location?.state?.fromToothPatient

  const settings: any = useSelector(selectSettings)
  const tenantId = useSelector(selectTenantId)

  const isJYYSKQ = settings[Checks.JYYSKQ]

  const tableUpdata = (id: string, num: string) => {
    dispatch(updatePatientTimeAsync(id))
      .then(unwrapResult)
      .then(() => {
        // history.push({
        //   pathname: '/toothPatient',
        // })
        dispatch(
          traceRoute({
            name: '患者管理',
            path: '/toothPatient',
          })
        )
        dispatch(
          setActiveObj({
            tabsActiveKey: '3',
            patientId: id,
            rightTabsActiveKey: num,
          })
        )
      })
  }

  const success = (str: string) => {
    message.loading(str, 3)
  }
  const [payList, setPayList] = useState([
    {
      icon: DollarCircleFilled,
      color: '#69A6FD',
      method: PayMethod.Cash,
      type: 1,
    },
    {
      icon: WechatFilled,
      color: '#00BC00',
      method: PayMethod.Weipay,
      type: 1,
    },
    {
      icon: AlipayCircleFilled,
      color: '#00A9F2',
      method: PayMethod.Alipay,
      type: 1,
    },
    {
      icon: DollarCircleFilled,
      color: '#69A6FD',
      method: PayMethod.Juhe,
      display: 'none',
      type: 0,
    },
    {
      icon: CreditCardFilled,
      color: '#107C84',
      method: PayMethod.UnionPay,
      type: 1,
    },
    {
      icon: CreditCardFilled,
      color: '#107C84',
      method: PayMethod.HY,
      type: 2,
    },
  ])

  const messageNotification = async (str: string) => {
    await message.destroy()
    notification.success({
      message: str,
      duration: 1,
    })
  }

  useEffect(() => {
    if (submitFlag)
      if (singleDiscount && Number(singleDiscount) >= 10) {
        calcZdzk()
      }
  }, [singleDiscount, submitFlag])

  const calcZdzk = () => {
    // if (Number(singleDiscount) < 10) {
    //   notification.error({
    //     message: '整单折扣请输入大于10的数字！',
    //   })
    // } else {
    setZdzkShow(true)
    setSettlementBtnText('折扣计算中...')
    dispatch(
      getSingleDiscount({
        discount: Number(singleDiscount) / 100,
        ids: query.get('ids'),
      })
    )
      .then(unwrapResult)
      .then((res) => {
        setConfirmation(fromJson(res))
        setZdzkShow(false)
        // notification.success({
        //   message: '整单折扣修改成功',
        // })
        setSubmitFlag(false)
        setSettlementBtnText('结算（空格）')
      })
      .catch(() => {
        setSubmitFlag(false)
        setZdzkShow(false)
        notification.success({
          message: '整单折扣修改失败',
        })
        setSettlementBtnText('结算（空格）')
      })
    // }
  }

  // const calcZdzkDiscount = (discountAmount: any) => {
  //   // if (Number(singleDiscount) < 10) {
  //   //   notification.error({
  //   //     message: '整单折扣请输入大于10的数字！',
  //   //   })
  //   // } else {
  //   setZdzkShow(true)
  //   setSettlementBtnText('折扣计算中...')
  //   dispatch(
  //     getSingleDiscount({
  //       discountAmount: Number(discountAmount),
  //       ids: query.get('ids'),
  //     })
  //   )
  //     .then(unwrapResult)
  //     .then((res: any) => {
  //       setConfirmation(fromJson(res))
  //       setZdzkShow(false)
  //       setSingleDiscount((Number(res?.discount) * 100)?.toString())
  //       // notification.success({
  //       //   message: '整单折扣修改成功',
  //       // })
  //       setSubmitFlag(false)
  //       setSettlementBtnText('结算（空格）')
  //     })
  //     .catch(() => {
  //       setSubmitFlag(false)
  //       setZdzkShow(false)
  //       notification.success({
  //         message: '整单折扣修改失败',
  //       })
  //       setSettlementBtnText('结算（空格）')
  //     })
  //   // }
  // }

  const loadState = () => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 1000 * 10)
  }

  useEffect(() => {
    /**
     * 默认
     */
    if (props?.forceCash) {
      setPayList([
        {
          icon: DollarCircleFilled,
          color: '#69A6FD',
          method: PayMethod.Cash,
          type: 1,
        },
      ])
    } else {
      setPayList([
        {
          icon: DollarCircleFilled,
          color: '#69A6FD',
          method: PayMethod.Cash,
          type: 1,
        },
        {
          icon: WechatFilled,
          color: '#00BC00',
          method: PayMethod.Weipay,
          type: 1,
        },
        {
          icon: AlipayCircleFilled,
          color: '#00A9F2',
          method: PayMethod.Alipay,
          type: 1,
        },
        {
          icon: CreditCardFilled,
          color: '#107C84',
          method: PayMethod.UnionPay,
          type: 1,
        },
        {
          icon: CreditCardFilled,
          color: '#107C84',
          method: PayMethod.HY,
          type: 2,
        },
      ])
      if (
        !props.YUSHOUGUAHAOFEI &&
        (JuheShow?.openHubPay == 1 ||
          JuheShow?.openHubPayJiali == 1 ||
          JuheShow?.openHubPayYinlian == 1 ||
          JuheShow?.openHubPayKanglin == 1)
      ) {
        setPayList([
          {
            icon: DollarCircleFilled,
            color: '#69A6FD',
            method: PayMethod.Cash,
            type: 1,
          },
          {
            icon: DollarCircleFilled,
            color: '#69A6FD',
            method: PayMethod.Juhe,
            display: 'none',
            type: 0,
          },
          {
            icon: WechatFilled,
            color: '#00BC00',
            method: PayMethod.Weipay,
            type: 1,
          },

          {
            icon: AlipayCircleFilled,
            color: '#00A9F2',
            method: PayMethod.Alipay,
            type: 1,
          },
          {
            icon: CreditCardFilled,
            color: '#107C84',
            method: PayMethod.UnionPay,
            type: 1,
          },
          {
            icon: CreditCardFilled,
            color: '#69A6FD',
            method: PayMethod.HY,
            type: 2,
          },
        ])
      }
      if (props.YUSHOUGUAHAOFEI) {
        setPayList([
          {
            icon: DollarCircleFilled,
            color: '#69A6FD',
            method: PayMethod.Cash,
            type: 1,
          },
          {
            icon: DollarCircleFilled,
            color: '#69A6FD',
            method: PayMethod.Juhe,
            display: 'none',
            type: 0,
          },
          {
            icon: WechatFilled,
            color: '#00BC00',
            method: PayMethod.Weipay,
            type: 1,
          },

          {
            icon: AlipayCircleFilled,
            color: '#00A9F2',
            method: PayMethod.Alipay,
            type: 1,
          },
          {
            icon: CreditCardFilled,
            color: '#107C84',
            method: PayMethod.UnionPay,
            type: 1,
          },
        ])
      }
    }
  }, [JuheShow, props.YUSHOUGUAHAOFEI])

  useEffect(() => {
    setSubmitFlag(false)
  }, [])

  useEffect(() => {
    // 组合支付选中的列表
    const selectZuHeList = allPaymentWayList?.filter((v: any) => {
      return payMethod?.some((i: any) => i == v?.method)
    })

    // 使用排序函数对数组进行排序
    const sortedData = selectZuHeList?.sort(
      (a: any, b: any) => a.method - b.method
    )
    setSelectZuHeOrNoZuHeList(sortedData)
  }, [allPaymentWayList, payMethod])

  useEffect(() => {
    const data = prePay
      ? confirmation?.payment?.shouldPayAmount
      : props.confirmation?.amount || confirmation?.payment?.shouldPayAmount
    setAmount(JuheShow?.openRounding == 1 ? data?.toFixed(1) : data) //是否开通金额抹零
    setReceivedAmount(JuheShow?.openRounding == 1 ? data?.toFixed(1) : data)
    // 默认进来开通组合支付
    if (combinationChargeFlag) {
      if (selectZuHeOrNoZuHeList?.length == 1) {
        setSystemPayMethod(
          selectZuHeOrNoZuHeList?.map((v: any) => {
            return {
              payAmount: props.YUSHOUGUAHAOFEI ? amount : data,
              payMethod: v?.method,
            }
          })
        )
        // 默认进来开通组合支付将第一个设置为应收金额 其余为0.00元
      } else if (
        selectZuHeOrNoZuHeList?.length == 2 ||
        selectZuHeOrNoZuHeList?.length == 3
      ) {
        setSystemPayMethod(
          selectZuHeOrNoZuHeList?.map((v: any, index: any) => {
            return {
              payAmount:
                index == 0
                  ? props.YUSHOUGUAHAOFEI
                    ? amount
                    : data?.toFixed(2)
                  : '0.00',
              payMethod: v?.method,
            }
          })
        )
      }
    }
  }, [prePay, confirmation, props.YUSHOUGUAHAOFEI, props.confirmation])


  useEffect(() => {
    // 没有开启组合收费
    if (!combinationChargeFlag) {
      const value =
        receivedAmount || receivedAmount == '' ? receivedAmount : amount
      if (amount)
        setSystemPayMethod(
          selectZuHeOrNoZuHeList?.map((v: any) => {
            return {
              payAmount: value,
              payMethod: v?.method,
            }
          })
        )
      setReceivedAmount(value)
    } else {
      // 开启组合收费
      if (amount) {
        const value =
          receivedAmount || receivedAmount == '' ? receivedAmount : amount
        setSystemPayMethod(
          selectZuHeOrNoZuHeList?.map((v: any, index: any) => {
            return {
              payAmount: index == 0 ? value : '0.00',
              payMethod: v?.method,
            }
          })
        )
      }
    }
  }, [selectZuHeOrNoZuHeList, receivedAmount])

  useEffect(() => {
    // 开启组合收费的所有值的总合
    if (combinationChargeFlag) {
      const addZuHePayMoney: any = sum(
        systemPayMethod?.map((a: any) =>
          isNaN(Number(a?.payAmount)) ? 0 : Number(a?.payAmount)
        )
      )
      setZHSFAllMoney(Number(addZuHePayMoney)?.toFixed(2))
    }
  }, [systemPayMethod, combinationChargeFlag])

  useEffect(() => {
    !!confirmation?.patient && getIsMembers(Number(query.get('phone')))
  }, [confirmation])

  useEffect(() => {
    //3秒内只允许播报一次，防止重复调用,给海宁康顺门诊部定制的功能
    if (tenantId == 8123 && !sendBjqFlag && amount) {
      BaoJiaQi({
        trade_args: [amount + 'J'],
      })
      setSendBjqFlag(true)
      setTimeout(() => {
        setSendBjqFlag(false)
      }, 3000)
    }
  }, [amount])

  // 找零 or 欠费
  useEffect(() => {
    const money = combinationChargeFlag ? zHSFAllMoney : receivedAmount
    const num = Number(money) - Number(amount)
    if (num < 0) {
      //欠费
      setDiscount('0.00')
      setOweMoneNum(Math.abs(num).toFixed(2))
      // setOweMoneNum(num.toFixed(2))
    } else if (num > 0) {
      //找零
      setOweMoneNum('0.00')
      setDiscount(num.toFixed(2))
    } else if (num == 0) {
      //清零
      setOweMoneNum('0.00')
      setDiscount('0.00')
    }
  }, [receivedAmount, amount, zHSFAllMoney])

  // 医保提示信息
  const medicalText = () => {
    return (
      <Space direction='vertical'>
        <Text style={{ color: '#ffffff' }}>
          基金支付总额：{prePay?.setlinfo.jijinzfze}{' '}
        </Text>
        <Text style={{ color: '#ffffff' }}>
          基本统筹基金：{prePay?.setlinfo.jibenylbxtcjjzc}
        </Text>
        <Text style={{ color: '#ffffff' }}>
          基本统筹支付比例：{prePay?.setlinfo.jibenylbxtcjjzfbl}
        </Text>
        <Text style={{ color: '#ffffff' }}>
          个人账户支出：{prePay?.setlinfo.gerenzhzc}
        </Text>
        <Text style={{ color: '#ffffff' }}>
          个人账户余额：{prePay?.setlinfo.yue}
        </Text>
      </Space>
    )
  }

  // 医保提示信息
  const medicalModalText = () => {
    return (
      <Space direction='vertical'>
        <Text style={{ color: '#ffffff' }}>
          基金支付总额：{props.confirmation?.prePay?.setlinfo.jijinzfze}{' '}
        </Text>
        <Text style={{ color: '#ffffff' }}>
          基本统筹基金：{props.confirmation?.prePay?.setlinfo.jibenylbxtcjjzc}
        </Text>
        <Text style={{ color: '#ffffff' }}>
          基本统筹支付比例：
          {props.confirmation?.prePay?.setlinfo.jibenylbxtcjjzfbl}
        </Text>
        <Text style={{ color: '#ffffff' }}>
          个人账户支出：{props.confirmation?.prePay?.setlinfo.gerenzhzc}
        </Text>
        <Text style={{ color: '#ffffff' }}>
          个人账户余额：{props.confirmation?.prePay?.setlinfo.yue}
        </Text>
      </Space>
    )
  }

  // 聚合支付保存
  const juheFn = async () => {
    const quickTreatment = remoteConfirmation?.payment?.treatmentId

    const treatmentId = treatment?.id
      ? treatment?.id
      : props.confirmation
      ? props.confirmation.treatmentId
      : quickTreatment
    // 查询聚合支付状态
    dispatch(
      SearchJuhePay({
        treatmentId,
        recipeIdList: props.status
          ? confirmation?.recipeIdList
          : query.get('ids')?.split(',') || [],
      })
    )
      .then(unwrapResult)
      .then((res) => {
        setjuhePayId(res.payId)
        if (res.status == -1) {
          //2支付失败 3已退费 4 撤销支付
          setJuheIptDisabled(false)
          setTimerFlag(false) //关闭定时器
          setJuheText('用户尚未支付，请扫码')
          setPayCode('')
          setCancelPayBtn(false)
        } else if (res.status == 0) {
          //支付中
          setTimerFlag(true) //开启定时器
          setJuheText('用户正在支付')
          setJuheIptDisabled(true)
          setCancelPayBtn(true) //撤销支付按钮
        } else if (res.status == 1) {
          //支付成功
          setTimerFlag(false) //关闭定时器
          setJuheIptDisabled(false)
          setJuheText('已完成支付，请稍等')
          setPayCode('')
          loadState()
          if (prePay) {
            saveSettlement()
          } else {
            next('', res.payId)
          }
          setCancelPayBtn(false)
        } else if (res.status == 3) {
          //3已退费
          setJuheIptDisabled(false)
          setJuheText('该笔聚合支付已退费，请重新扫码支付')
        }
        if (res.status == 2 || res.status == 4) {
          //2支付失败 3已退费 4 撤销支付
          setJuheIptDisabled(false)
          setTimerFlag(false) //关闭定时器
          setJuheText('支付失败或撤销支付，请重新扫码进行支付')
          setPayCode('')
          setCancelPayBtn(false)
        }
      })
  }
  // 系统结算以及下一步操作
  const next = async (insuranceTradeId = '', payId = 0) => {
    if (props.YUSHOUGUAHAOFEI) {
      const params: any = {
        // 自费预收挂号费
        payMethod: payMethod?.join(','),
        systemPayMethod: !systemPayMethod.length
          ? [
              {
                payAmount: '0.00',
                payMethod: 1,
              },
            ]
          : systemPayMethod,
        realPayAmount: combinationChargeFlag
          ? (oweMoneNumShow && Number(oweMoneNum)) || 0 > 0
            ? zHSFAllMoney
            : amount
          : (oweMoneNumShow && Number(oweMoneNum)) || 0 > 0
          ? receivedAmount
          : amount,
        // realPayAmount: oweMoneNumShow
        //   ? receivedAmount
        //   : combinationChargeFlag
        //   ? zHSFAllMoney
        //   : amount,
        receivePayAmount: combinationChargeFlag ? zHSFAllMoney : receivedAmount,
        recipeIdList: String(props.confirmation?.ids).split(','),
        shouldPayAmount: amount,
      }
      dispatch(pay({ ...params, insuranceTradeId, settlementCategory: 1 }))
        .then(unwrapResult)
        .then((v: any) => {
          const data = {
            ...v?.payment,
            systemPayMethod: v?.systemPayMethod,
            recipeIdList: v?.recipeIdList,
          }
          GUAHAOFLAG('1', data)
          if (v?.autoDispenseErrMsg) {
            notification.error({
              message: v?.autoDispenseErrMsg,
            })
          }
        })
        .catch(() => {
          GUAHAOFLAG()
        })
    } else {
      // setLoading(false)
      if (confirmation) {
        if (confirmation.errorMaterials.length > 0) {
          setIsModalVisible(true)
          setLoading(false)
        } else {
          const settlementCategory =
            JSON.parse(localStorage.getItem('credentials')!)?.tenantCategory ===
            1
              ? 3
              : 2
          const req = props.batchFlag ? doBatchPayAsync : pay
          const result = await dispatch(
            req({
              insuranceTradeId: insuranceTradeId,
              hunPayId: payId,
              memberId: membersInfo?.id,
              arrearageAmount: !prePay ? oweMoneNum : undefined,
              realPayAmount: combinationChargeFlag
                ? (oweMoneNumShow && Number(oweMoneNum)) || 0 > 0
                  ? zHSFAllMoney
                  : amount
                : (oweMoneNumShow && Number(oweMoneNum)) || 0 > 0
                ? receivedAmount
                : amount,
              payMethod: payMethod?.join(','),
              systemPayMethod: !systemPayMethod.length
                ? [
                    {
                      payAmount: '0.00',
                      payMethod: 1,
                    },
                  ]
                : systemPayMethod,
              receivePayAmount: combinationChargeFlag
                ? zHSFAllMoney
                : receivedAmount,
              settlementCategory:
                location.state?.settlementCategory === 'retail'
                  ? settlementCategory
                  : 0,
              recipeIdList: props.status
                ? confirmation?.recipeIdList
                : query.get('ids')?.split(',') || [],
              discount: Number(singleDiscount) / 100,
              shouldPayAmount: amount,
            })
          )
            .then(unwrapResult)
            .finally(() => setLoading(false))
          if (result?.autoDispenseErrMsg && !props.batchFlag) {
            notification.error({
              message: result?.autoDispenseErrMsg,
            })
          }

          if (props.status) {
            props.onChargeSubmit && props.onChargeSubmit()
          } else {
            const backtrace =
              location.state.backtrace?.path === '/treatment'
                ? {
                    name: '医生门诊',
                    path: '/patients',
                  }
                : location?.state?.backtrace
            dispatch(
              traceRoute({
                name: location.state?.backtrace?.name || '收费',
                path: '/payment/result',
                query: `paymentId=${
                  result?.id
                }&&status=8&print&fromToothPatient=${
                  fromToothPatient ? fromToothPatient : ''
                }&zddyFlag=${true}`,
                state: backtrace,
              })
            )
          }
        }
      }
    }
  }

  // }

  // 杭州人脸识别
  const faceConfirmSend = (serverType: any, responseString?: string) => {
    dispatch(
      faceCallAsync({
        serverType: serverType,
        body: {
          baseData: {
            ...baseData,
            responseString: responseString,
          },
          treatmentId: treatment?.id,
          chrgBchno: props?.chrgBchno ? props?.chrgBchno : 1,
        },
      })
    )
      .then(unwrapResult)
      .then((res) => {
        if (serverType === 'requestString') {
          if (res === 'skip') {
            message.loading('异地患者，跳过人脸识别')
            insuranceSubmit('requestString')
          } else {
            const data = strParse(res)
            medicalFaceSendHttp(
              {
                trade_args: ['FACE', data],
              },
              (call, err, message) => {
                if (!err) {
                  if (message) {
                    Modal.confirm({
                      title: '提示',
                      content: message,
                      okText: '确认',
                      cancelText: '取消',
                      onOk: () => {
                        if (
                          message.indexOf(
                            '人脸验证不通过，请重新发起结算并验证!'
                          ) > -1
                        ) {
                          setLoading(false)
                        } else {
                          insuranceSubmit('requestString')
                        }
                      },
                    })
                  } else {
                    insuranceSubmit('requestString')
                  }
                } else {
                  setLoading(false)
                  if (props.YUSHOUGUAHAOFEI) {
                    GUAHAOFLAG()
                    return
                  }
                }
              }
            )
          }
        }
      })
      .catch(() => {
        setLoading(false)
        if (props.YUSHOUGUAHAOFEI) {
          GUAHAOFLAG()
          return
        }
      })
  }

  // 丽水
  const LSFaceConfirmSend = (serverType: any, responseString?: string) => {
    dispatch(
      LSFaceCallAsync({
        serverType: serverType,
        body: {
          baseData: {
            ...baseData,
            responseString: responseString,
          },
          treatmentId: treatment?.id,
          chrgBchno: props?.chrgBchno ? props?.chrgBchno : 1,
        },
      })
    )
      .then(unwrapResult)
      .then((res) => {
        if (serverType === 'requestString') {
          if (res === 'skip') {
            message.loading('异地患者，跳过人脸识别')
            insuranceSubmit('requestString', undefined, 'FC0')
          } else {
            const data = strParse(res)
            const code = tenantAreaCd?.slice(0, 4)
            LSMedicalFaceSendHttp(
              {
                trade_args: ['FACE_LS', data],
              },
              (call, err, message) => {
                insuranceSubmit('requestString')
              }
            )
          }
        }
      })
      .catch(() => {
        setLoading(false)
        if (props.YUSHOUGUAHAOFEI) {
          GUAHAOFLAG()
          return
        }
      })
  }

  // 衢州人脸识别
  const QZFaceConfirmSend = (serverType: any, responseString?: string) => {
    dispatch(
      QZFaceCallAsync({
        serverType: serverType,
        body: {
          baseData: {
            ...baseData,
            responseString: responseString,
          },
          treatmentId: treatment?.id,
          chrgBchno: props?.chrgBchno ? props?.chrgBchno : 1,
        },
      })
    )
      .then(unwrapResult)
      .then((res) => {
        if (serverType === 'requestString') {
          if (res === 'skip') {
            message.loading('异地患者，跳过人脸识别')
            insuranceSubmit('requestString', undefined, 'FC0')
          } else {
            const data = strParse(res)
            const code = tenantAreaCd?.slice(0, 4)
            QZMedicalFaceSendHttp(
              {
                trade_args: ['FACE_QZ', data],
              },
              (call, err, message) => {
                if (!err) {
                  if (message) {
                    Modal.confirm({
                      title: '提示',
                      content: message,
                      okText: '确认',
                      cancelText: '取消',
                      onOk: () => {
                        // if (
                        //   message.indexOf(
                        //     '人脸验证不通过，请重新发起结算并验证!'
                        //   ) > -1
                        // ) {
                        setLoading(false)
                        // } else {
                        //   insuranceSubmit('requestString')
                        // }
                      },
                    })
                  } else {
                    insuranceSubmit(
                      'requestString',
                      undefined,
                      call?.netmessage?.authId
                    )
                  }
                } else {
                  setLoading(false)
                  if (props.YUSHOUGUAHAOFEI) {
                    GUAHAOFLAG()
                    return
                  }
                }
              }
            )
          }
        }
      })
      .catch(() => {
        setLoading(false)
        if (props.YUSHOUGUAHAOFEI) {
          GUAHAOFLAG()
          return
        }
      })
  }
  // 是否人脸
  const faceConfirm = () => {
    setLoading(true)
    if (isJYYSKQ && isJYYSKQ == 1) {
      //是否校验医生考勤
      const code = tenantAreaCd?.slice(0, 4)
      if (
        code == '3301' &&
        confirmation &&
        confirmation.doctorInfo &&
        confirmation.doctorInfo.idCard &&
        confirmation.doctorInfo.name &&
        baseData?.attendanceKey
      ) {
        //目前只做了杭州,杭州的接口表示传1.0
        checkDoctorAttendance(
          confirmation.doctorInfo.name,
          confirmation.doctorInfo.idCard,
          baseData?.attendanceKey,
          '1.0'
        )
      } else {
        readyToFaceConfirm()
      }
    } else {
      readyToFaceConfirm()
    }
  }

  const checkDoctorAttendance = (
    doctorName: string,
    doctorIdCardNumber: string,
    attendanceKey: string,
    interfaceVersion: string
  ) => {
    doCheckDoctorAttendance(
      {
        trade_args: [
          'HL_FACE_CHECK',
          doctorName,
          doctorIdCardNumber,
          attendanceKey,
          interfaceVersion,
          baseData?.countryHospitalNb,
        ],
      },
      (call, err) => {
        if (!err) {
          readyToFaceConfirm()
        } else {
          setLoading(false)
        }
      }
    )
  }

  const readyToFaceConfirm = () => {
    //挂号收费时，不触发人脸识别
    if (props?.chrgBchno == 2) {
      // insuranceSubmit('requestString')
      insuranceSubmit('requestString', undefined, 'FC0')
    } else if (insuranceItem?.isFaceCall) {
      const code = tenantAreaCd?.slice(0, 4)
      if (code == '3308') {
        // 衢州
        if (urgencyAccount) {
          insuranceSubmit('requestString', undefined, 'FC0')
        } else {
          QZFaceConfirmSend('requestString')
        }
      } else if (code == '3311') {
        // 丽水（异步，不做拦截）
        LSFaceConfirmSend('requestString')
      } else {
        // 杭州
        faceConfirmSend('requestString')
      }
    } else {
      insuranceSubmit('requestString')
    }
  }

  // 医保结算 2207
  const insuranceSubmit = (
    serverType: any,
    responseString?: string,
    cpNo?: string
  ) => {
    let doPayVO: any = {}
    if (props.YUSHOUGUAHAOFEI) {
      doPayVO = {
        // 自费预收挂号费
        realPayAmount: oweMoneNumShow
          ? amount
          : combinationChargeFlag
          ? zHSFAllMoney
          : receivedAmount,
        receivePayAmount: combinationChargeFlag ? zHSFAllMoney : receivedAmount,
        recipeIdList: String(props.confirmation?.ids).split(','),
        insuranceTradeId: undefined,
        settlementCategory: 1,
        payMethod: payMethod?.join(','),
        systemPayMethod: !systemPayMethod.length
          ? [
              {
                payAmount: '0.00',
                payMethod: 1,
              },
            ]
          : systemPayMethod,
      }
    } else {
      // setLoading(false)
      if (confirmation) {
        if (confirmation.errorMaterials.length > 0) {
          setIsModalVisible(true)
          setLoading(false)
        } else {
          const settlementCategory =
            JSON.parse(localStorage.getItem('credentials')!)?.tenantCategory ===
            1
              ? 3
              : 2
          doPayVO = {
            insuranceTradeId: undefined,
            memberId: membersInfo?.id,
            arrearageAmount: !prePay ? oweMoneNum : undefined,
            realPayAmount: oweMoneNumShow
              ? amount
              : combinationChargeFlag
              ? zHSFAllMoney
              : receivedAmount,
            payMethod: payMethod?.join(','),
            systemPayMethod: !systemPayMethod.length
              ? [
                  {
                    payAmount: '0.00',
                    payMethod: 1,
                  },
                ]
              : systemPayMethod,
            receivePayAmount: combinationChargeFlag
              ? zHSFAllMoney
              : receivedAmount,
            settlementCategory:
              location.state?.settlementCategory === 'retail'
                ? settlementCategory
                : 0,
            recipeIdList: props.status
              ? confirmation?.recipeIdList
              : query.get('ids')?.split(',') || [],
            discount: Number(singleDiscount) / 100,
          }
        }
      }
    }

    dispatch(
      saveSettlementAsync({
        serverType: serverType,
        body: {
          baseData: {
            ...baseData,
            responseString,
          },
          chrg_bchno: props.YUSHOUGUAHAOFEI ? '2' : '1',
          exp_content: '',
          cpNo,
          treatmentId: treatment?.id,
          patientId: treatment?.patientId,
          registrationId: treatment?.registrationId,
          doPayVO: serverType === 'requestString' ? doPayVO : undefined,
          recipeIdList: props.YUSHOUGUAHAOFEI
            ? String(props.confirmation?.ids).split(',')
            : props.status
            ? confirmation?.recipeIdList
            : query.get('ids')?.split(',') || [],
        },
      })
    )
      .then(unwrapResult)
      .then((res) => {
        if (serverType === 'requestString') {
          success('正在结算')
          const data = strParse(res)

          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                baseData?.countryHospitalNb,
                baseData?.secretToken,
              ],
            },
            (call, err) => {
              if (!err) {
                insuranceSubmit('response', JSON.stringify(call.netmessage))
              } else {
                setLoading(false)
                if (props.YUSHOUGUAHAOFEI) {
                  GUAHAOFLAG()
                  return
                }
              }
            }
          )
        } else {
          messageNotification('结算成功')
          next(res?.insuranceTradeId)
        }
      })
      .catch((err) => {
        if (props.YUSHOUGUAHAOFEI) {
          GUAHAOFLAG()
          return
        }
        if (err?.message?.indexOf('该笔结算正在进行中') >= 0) {
          //donothing
        } else {
          dispatch(traceRoute(location?.state?.backtrace))
          setLoading(false)
        }
      })
  }
  const GUAHAOFLAG = (flag = '2', paymentData?: any) => {
    console.log(paymentData, 'registerData?.payment')
    // 1 成功 2失败
    if (props.YUSHOUGUAHAOFEI && props.onPay) {
      props.onPay({ success: flag, paymentData: paymentData })
    }
  }

  // 结算提交
  const saveSettlement = () => {
    if (confirmation && confirmation.errorMaterials.length > 0) {
      setIsModalVisible(true)
      setLoading(false)
    } else {
      readSType()
      // faceConfirm()
    }
  }

  // 实体卡读卡
  const readSType = () => {
    //已经手动触发任何形式的过二次读卡
    if (twiceReadDone) {
      faceConfirm()
    } else {
      readSTypeNext()
    }
  }

  const readSTypeNext = () => {
    if (prePay?.cardType === '3') {
      readCardSHttp(
        {
          trade_args: [getTradeCode(tenantAreaCd.slice(0, 4))],
        },
        (data, err) => {
          const tradeType = getTradeName()

          const { prePay: AprePay } = location.state || props.confirmation
          if (!err) {
            if (tradeType === 'CQ_ZHYB') {
              //重庆医保有动态库强制读卡，不需要二次读卡校验
              faceConfirm()
            } else if (tradeType === 'JS_ZHYB') {
              //其他地区需要做二次读卡校验
              const idCardRead = data?.netmessage?.split('|')[1]
              if (
                idCardRead?.trim() == AprePay?.IdCard?.trim() ||
                idCardRead?.trim() == prePay?.IdCard?.trim()
              ) {
                faceConfirm()
              } else {
                setLoading(false)
                Modal.confirm({
                  title: '提示',
                  content:
                    `就诊人信息与卡片信息不一致，请确认是否插错卡,就诊人身份证：` +
                    prePay?.IdCard +
                    '，卡面身份证：' +
                    idCardRead,
                  okText: '确认',
                  cancelText: '取消',
                })
              }
            } else {
              //其他地区需要做二次读卡校验
              if (
                data?.netmessage.IDNum?.trim() === AprePay?.IdCard?.trim() ||
                data?.netmessage.IDNum?.trim() === prePay?.IdCard?.trim()
              ) {
                faceConfirm()
              } else {
                setLoading(false)
                Modal.confirm({
                  title: '提示',
                  content: `就诊人信息与卡片信息不一致，请确认是否插错卡`,
                  okText: '确认',
                  cancelText: '取消',
                })
              }
            }
          } else {
            setLoading(false)
          }
        }
      )
    } else if (prePay?.cardType === '1') {
      //杭州地区 非快速开单 需要强制二次扫码
      const code = tenantAreaCd?.slice(0, 4)
      if (code == '3301' && treatment?.orderSource == 1) {
        readEType((xmsg, success) => {
          if (success) {
            faceConfirm()
          }
        })
      } else {
        faceConfirm()
      }
    } else {
      faceConfirm()
    }
  }

  console.log( treatment?.insuranceCode," treatment?.insuranceCode");
  
  // 电子凭证读卡
  const readEType = (callback: (msg: string, success?: boolean) => void) => {
    readCardEHttp(
      {
        trade_args: [
          baseData?.countryHospitalNb,
          '01301',
          '',
          userId,
          userName,
          '',
          '',
        ],
      },
      (data, err) => {
        if (!err) {
          dispatch(
            updateCardInfoAsync({
              fullCardInfo: data.netmessage,
              patientId: confirmation?.patientId,
              cardType: readCardValue,
              registrationId: treatment?.registrationId,
            })
          )
            .then(unwrapResult)
            .then((res) => {
              setReadLoading(false)
              notification.success({
                message: res,
              })
              callback(res, true)
            })
            .catch((err2) => {
              setLoading(false)
              callback('error', false)
            })
        } else {
          callback('扫码失败，请再试一次', false)
          setReadLoading(false)
        }
      }
    )
  }

  // 仅读卡
  const onlyreadSType = () => {
    readCardSHttp(
      {
        trade_args: [getTradeCode(tenantAreaCd.slice(0, 4))],
      },
      (data, err) => {
        if (!err) {
          dispatch(
            updateCardInfoAsync({
              fullCardInfo: JSON.stringify(data.netmessage),
              patientId: confirmation?.patientId,
              cardType: readCardValue,
              registrationId: treatment?.registrationId,
            })
          )
            .then(unwrapResult)
            .then((res) => {
              setReadLoading(false)
              notification.success({
                message: res,
              })
              setTwiceReadDone(true)
            })
            .catch((ex) => {
              setReadLoading(false)
              // notification.error({
              //   message: ex.message,
              // })
              setTwiceReadDone(false)
            })
        } else {
          setTwiceReadDone(false)
          setReadLoading(false)
        }
      }
    )
  }

  const reCalcPrice = (editIndex: any) => {
    const hasXj = systemPayMethod.find((v) => v.payMethod == '1')
    const nextInput = editIndex + 1 >= inputRefs.length ? 0 : editIndex + 1
    const currentPayAmount = new Decimal(
      inputRefs?.[editIndex]?.current.input.value || 0
    )
    const yingshouAmount = new Decimal(amount)

    const shengyuAmount = yingshouAmount.minus(currentPayAmount)
    let pageTotalAmount = new Decimal(0) //页面合计的收款金额
    inputRefs.forEach((ref: any, index: any) => {
      //遍历付款金额并计算页面合计的收款金额（不用zHSFAllMoney是因为此时还没有触发effect）
      pageTotalAmount = pageTotalAmount.plus(ref.current.input.value || 0)
    })
    const currentPayMethod = systemPayMethod?.[editIndex]?.payMethod
    const paymethods: any = []
    if ('1' == currentPayMethod) {
      const comparisonResult = currentPayAmount.comparedTo(yingshouAmount)
      //如果当前输入框是现金，则在金额超出应收金额的时候，把另外两个输入框都设置为0
      if (comparisonResult >= 0) {
        inputRefs.forEach((ref: any, index: any) => {
          if (index != editIndex) {
            ref.current.input.value = 0
          }
        })
      }
      inputRefs.forEach((ref: any, index: any) => {
        paymethods.push({
          payAmount: ref.current.input.value,
          payMethod: systemPayMethod?.[index]?.payMethod,
        })
      })
      setSystemPayMethod(paymethods)
      return
    }
    //如果当前修改的金额不是现金，则在大于等于应收金额时，将另一个清空，将当前输入框设置为应收金额
    if (
      '1' != currentPayMethod &&
      currentPayAmount.comparedTo(yingshouAmount) > 0
    ) {
      inputRefs.forEach((ref: any, index: any) => {
        if (index == editIndex) {
          inputRefs[index].current.input.value = yingshouAmount
        } else {
          inputRefs[index].current.input.value = 0
        }
      })
      notification.error({
        message: '收款金额不能大于应收金额',
        duration: 2,
      })
      inputRefs.forEach((ref: any, index: any) => {
        paymethods.push({
          payAmount: ref.current.input.value,
          payMethod: systemPayMethod?.[index]?.payMethod,
        })
      })
      setSystemPayMethod(paymethods)
      return
      //在小于等于应收金额时，判断另一个输入框是不是现金
    }
    //2种付款方式时
    if (inputRefs.length == 2) {
      //如果当前的输入框是现金
      if ('1' == currentPayMethod) {
        //如果当前修改的金额是现金，则在小于等于应收金额时，自动计算第二个输入框为剩余金额，大于应收金额时，把另外一个清空
        if (currentPayAmount.comparedTo(yingshouAmount) <= 0) {
          inputRefs[1].current.input.value = yingshouAmount.minus(
            currentPayAmount
          )
        } else {
          inputRefs[1].current.input.value = 0
        }
      } else {
        //如果另一个输入框不是现金，则当另一个输入框不是现金的时候，自动计算另一个输入框的金额为应收金额 - 当前输入框的金额
        if (!hasXj) {
          //允许欠费 输入一个金额时 与另一个金额相加小于应收金额时 不自动计算
          const editValue = inputRefs[editIndex].current.input.value
          const nextValue = inputRefs[nextInput].current.input.value
          try {
            if (
              new Decimal(editValue)
                .add(new Decimal(nextValue))
                .comparedTo(yingshouAmount) >= 0
            ) {
              inputRefs[nextInput].current.input.value = shengyuAmount
            }
          } catch (err) {
            return undefined
          }
        }
      }
    }
    //3种付款方式时
    if (inputRefs.length == 3) {
      const firstAmount = new Decimal(inputRefs[0].current.input.value || 0)
      const secondAmount = new Decimal(inputRefs[1].current.input.value || 0)
      const thirdAmount = new Decimal(inputRefs[2].current.input.value || 0)
      //如果当前在编辑第一个输入框，且不是现金的情况下
      if (editIndex == 0) {
        //如果当前输入框不是现金（第一个框不是现金的情况下，即没有现金）
        //如果第一个+第三个的钱 小于应收，则把第二个设置为 应收 - 第一个 - 第三个
        if (firstAmount.plus(thirdAmount).comparedTo(yingshouAmount) <= 0) {
          inputRefs[1].current.input.value = yingshouAmount
            .minus(firstAmount)
            .minus(thirdAmount)
        } else {
          //否则就把第二个设置为0，把第三个设置为应收 - 第一个
          inputRefs[1].current.input.value = 0
          inputRefs[2].current.input.value = yingshouAmount.minus(firstAmount)
        }
        //如果当前在编辑第二个输入框，则在第一个金额+第二个金额小于等于应收金额的时候，修改第三个金额，
      } else if (editIndex == 1) {
        if (firstAmount.plus(secondAmount).comparedTo(yingshouAmount) <= 0) {
          inputRefs[2].current.input.value = yingshouAmount.minus(
            firstAmount.plus(secondAmount)
          )
        } else {
          //在第一个金额+第二个金额大于应收金额的时候，如果没现金，则把第三个金额改为0，第一个金额改为应收金额 - 第二个金额
          inputRefs[2].current.input.value = 0
          if (!hasXj) {
            inputRefs[0].current.input.value = yingshouAmount.minus(
              secondAmount
            )
          }
        }
        //如果当前在编辑第三个输入框
      } else if (editIndex == 2) {
        //如果总额全部相加小于等于应收金额，则不做处理
        //如果总额全部相加大于应收金额，则只处理第二个金额 = 应收金额 - 第一个金额 + 第三个金额，当结果小于0时，也设置为0（即找零），
        if (pageTotalAmount.comparedTo(yingshouAmount) > 0) {
          const restAmount = pageTotalAmount.minus(yingshouAmount)
          if (hasXj) {
            const chaochuAmount = firstAmount.minus(restAmount)
            //有现金 并且超出部分小于现金时，不做处理，否则 在第二个金额上减去超出部分
            if (chaochuAmount.comparedTo(new Decimal(0)) < 0) {
              inputRefs[1].current.input.value = secondAmount.plus(
                chaochuAmount
              )
            }
          } else {
            //无现金，并且超出部分小于第二个金额时，在第二个金额上做扣减，否则，多出部分在第一个金额上做扣减
            if (secondAmount.comparedTo(restAmount) >= 0) {
              inputRefs[1].current.input.value = secondAmount.minus(restAmount)
            } else {
              inputRefs[0].current.input.value = firstAmount.plus(
                secondAmount.minus(restAmount)
              )
              inputRefs[1].current.input.value = 0
            }
          }
        }
      }
    }
    inputRefs.forEach((ref: any, index: any) => {
      paymethods.push({
        payAmount: ref.current.input.value,
        payMethod: systemPayMethod?.[index]?.payMethod,
      })
    })
    setSystemPayMethod(paymethods)
  }

  // 医保读卡
  const readCard = () => {
    // success('读卡中')
    readCardRef.current.blur() //按钮失焦，防止重复点击
    setReadLoading(true)
    const hide = message.loading('读卡中', 0)
    setTimeout(() => {
      hide()
      setReadLoading(false)
    }, 3000)
    if (readCardValue === 1) {
      readEType((msg, success) => {
        if (!success) {
          notification.error({
            message: msg,
            duration: timeOut(msg),
          })
          setTwiceReadDone(false)
        } else {
          setTwiceReadDone(true)
        }
      })
    } else if (readCardValue === 2) {
      // const idCardNumber = form.getFieldsValue().idCardNumber
      // if (idCardNumber) {
      //   getPatientInfo(idCardNumber, 'requestString')
      // } else {
      //   notification.info({
      //     message: '请输入身份证号',
      //   })
      // }
    } else if (readCardValue === 3) {
      onlyreadSType()
    }
  }

  // 事中提醒
  const inprocessAnalysis = (
    serverType: serverType,
    patientId: number,
    treatmentId: number,
    responseString?: string
  ) => {
    dispatch(
      inprocessAnalysisAsync({
        serverType,
        body: {
          baseData: {
            ...baseData,
            responseString,
          },
          patientId,
          treatmentId,
        },
      })
    )
      .then(unwrapResult)
      .then((res) => {
        if (serverType === 'requestString') {
          const data = strParse(res)
          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                baseData?.countryHospitalNb,
                baseData?.secretToken,
              ],
            },
            (call, err) => {
              if (!err) {
                inprocessAnalysis(
                  'response',
                  patientId,
                  treatmentId,
                  JSON.stringify(call.netmessage)
                )
              }
            }
          )
        } else {
          setInprocessAnalysisList(res)
        }
      })
  }

  // 是否是会员
  const getIsMembers = (data: number) => {
    dispatch(getSearchMembers({ memberPhone: data ? data : 0 }))
      .then(unwrapResult)
      .then((res: any) => {
        setMembersInfo(Object.keys(res).length ? res : undefined)
        if (combinationChargeFlag) {
          setPayMethod(
            Object.keys(res).length
              ? [...payMethod]
              : payMethod || [defaultPayMethod]
          )
        } else {
          setPayMethod(
            Object.keys(res).length
              ? [PayMethod.HY]
              : payMethod || [defaultPayMethod]
          )
        }

        setTopUpModalVisible(false)
        if (Object.keys(res).length > 0) {
          if (confirmation) {
            if (
              confirmation.patient !== res?.memberName &&
              confirmation.patient !== '零售患者'
            ) {
              if (!isHyModalVisible) {
                setIsHyModalVisible(true)
                Modal.info({
                  title: '请注意，非本人会员卡！',
                  okText: '确定',
                  maskClosable: true,
                  keyboard: true,
                  content:
                    '会员姓名：' +
                    res?.memberName +
                    '，当前余额：' +
                    res?.balanceMoney +
                    '元',
                })
              }
            } else {
              Modal.info({
                title: '已激活会员卡！',
                okText: '确定',
                maskClosable: true,
                keyboard: true,
                content:
                  '会员姓名：' +
                  res?.memberName +
                  '，当前余额：' +
                  res?.balanceMoney +
                  '元',
              })
            }
          }
          // if (confirmation?.padtient != \'unefined' && confirmation?.patient !== res?.memberName && confirmation?.patient !== '零售患者') {
          //   Modal.info({
          //     title: '请注意，非本人会员卡！',
          //     okText: '确定',
          //     maskClosable: true,
          //     keyboard: true,
          //     content: "会员姓名：" + res?.memberName + "，当前余额：" + res?.balanceMoney + "元"
          //
          //   })
          // } else {
          //   Modal.info({
          //     title: '已激活会员卡！',
          //     okText: '确定',
          //     maskClosable: true,
          //     keyboard: true,
          //     content: "会员姓名：" + res?.memberName + "，当前余额：" + res?.balanceMoney + "元"
          //   })
          // }
        }
      })
  }

  useEffect(() => {
    if (props?.forceCash) {
      setPayMethod([PayMethod.Cash])
    } else {
      if (defaultPayMethod) {
        // defaultPayMethod == 2 ? setJuheIptShow(true) : setJuheIptShow(false)
        if (
          props.membersStatus != 0 &&
          query.get('phone') &&
          query.get('phone') != 'undefined'
        ) {
          //限制挂号预收费会员支付
          // getIsMembers(Number(query.get('phone')))
        } else if (props.membersStatus == 0) {
          setPayMethod([PayMethod.Cash])
          return
        }
        setPayMethod([defaultPayMethod])
        // setPayMethod(PayMethod.HY)
      }
    }
  }, [defaultPayMethod])

  // useEffect(() => {
  //   // if (combinationChargeFlag) {
  //   // setJuheIptShow(payMethod?.includes(PayMethod.Juhe))
  //   // } else {
  //   // setJuheIptShow(payMethod == PayMethod.Juhe)
  //   // }
  // }, [payMethod])

  // useEffect(() => {
  //   //聚合支付扫码获取焦点
  //   JuheIptShow &&
  //     inputRef.current!.focus({
  //       cursor: 'start',
  //     })
  // }, [JuheIptShow])

  useEffect(() => {
    const ids = query.get('ids')

    const id = props.confirmation?.ids
    if (ids) {
      dispatch(confirmPayment(ids))
    }
    if (id) {
      dispatch(confirmPayment(id))
    }
  }, [dispatch, props.confirmation?.ids])

  useEffect(() => {
    const value = combinationChargeFlag ? zHSFAllMoney : receivedAmount
    if (value === '') {
      setDiscount('0.00')
    } else {
      const receivedValue = parseFloat(value)
      const amountValue = parseFloat(amount)
      const discount =
        receivedValue -
        ((isNaN(amountValue) ? confirmation?.amount : amountValue) || NaN)
      if (discount >= 0) {
        setDiscount(discount.toFixed(2))
      } else {
        setDiscount('0.00')
      }
    }
  }, [amount, combinationChargeFlag, receivedAmount, zHSFAllMoney])

  useEffect(() => {
    if (props.confirmation) {
      //his 口腔版-收费-批量预结算
      setConfirmation(props.confirmation)
      if (props.YUSHOUGUAHAOFEI) {
        setPrePay(props.confirmation.prePay)
        setTreatment({
          id: props.YUSHOUtreatment?.treatmentId || props.YUSHOUtreatment?.id,
          ...props.YUSHOUtreatment,
        })
      }
    }
    if (remoteConfirmation) {
      if (remoteConfirmation.errorMaterials.length > 0) {
        setIsModalVisible(true)
      } else {
        setIsModalVisible(false)
      }
      setConfirmation(remoteConfirmation)
    }
  }, [remoteConfirmation, props.confirmation])

  useEffect(() => {
    setTwiceReadDone(false)
    return () => {
      dispatch(clearConfirmation())
      dispatch(setInsurance({}))
      setJuheIptShow(false)
      setTimerFlag(false)
    }
  }, [])

  useEffect(() => {
    if (location.state && !location?.state?.backHome) {
      // 暂时处理药品零售预结算问题
      if (location.state?.backtrace?.path !== '/retail') {
        if (!location.state?.routerLink && !props.YUSHOUGUAHAOFEI) {
          history.go(-1)
        } else {
          setPrePay(location.state.prePay)
          setTreatment(location.state.treatment)
        }

        // 判断是否欠费是否显示
        if (JuheShow?.openOwe == 1 && !location.state.prePay) {
          setOweMoneNumShow(true)
        }
      } else if (location.state?.backtrace?.path === '/retail') {
        setPrePay(location.state.prePay)
        setTreatment(location.state.treatment)
      } else {
        //其他页面跳转情况，不做处理
      }
    }
    if (location.state && location.state?.toothRegistrationId) {
      dispatch(
        setActiveObj({
          tabsActiveKey: '3',
        })
      )
    }
  }, [location])

  useEffect(() => {
    const insuranceItem = insuranceArray.find(
      (v) => v.insuranceCode === treatment?.insuranceCode
    )
    if (insuranceItem && JSON.stringify(insuranceItem) !== '{}') {
      dispatch(
        setInsurance({
          ...insuranceItem,
          userName,
          userId,
          cycleCode,
          tenantCategory,
          tenantAreaCd,
          name: tenantName,
          address: tenantAddress,
          areaCode: tenantAreaCd,
          endTime: moment(insuranceItem.endTime).format(
            DateTimeFormatSimpleOnlyOne
          ),
        })
      )
    }
    // return () => {
    //   dispatch(setInsurance({}))
    // }
  }, [treatment])
  //
  useEffect(() => {
    if (
      baseData?.insuranceCode &&
      remoteConfirmation?.payment?.patientId &&
      !props.confirmation?.ids &&
      treatment?.insuranceCode &&
      treatment?.insuranceCode !== 'ZIFEI'
    ) {
      // 3102 重庆地区需要调用
      // if (baseData?.insuranceCode === 'CHONGQING') {
      inprocessAnalysis(
        'requestString',
        remoteConfirmation?.payment?.patientId,
        remoteConfirmation?.treatmentId
      )
      // }
    }
  }, [remoteConfirmation])

  useEffect(() => {
    if (timerFlag && !timer) {
      timer = setInterval(() => {
        juheFn()
      }, 2000)
    }
    if (!timerFlag && timer) {
      clearInterval(timer)
      timer = null
    }
    return () => {
      timer && clearInterval(timer)
      timer = null
    }
  }, [timerFlag])

  // 组合收费
  const getSFFSList = () => {
    dispatch(getPaymentWayList())
      .then(unwrapResult)
      .then((v: any) => {
        const data = v?.records?.filter((v: any) => v?.status == 1)
        setPaymentWayList(data)
        const list: any = []
        const allList = list.concat(
          payList?.map((c: any) => {
            return { ...c, methodName: getPayMethodName(c.method) }
          }),
          data?.map((item: any) => {
            return {
              ...item,
              icon: getIconImg(item?.methodIcon),
              method: item?.id,
            }
          })
        )
        // 所有的支付方式
        setAllPaymentWayList(allList)
      })
  }
  useEffect(() => {
    getSFFSList()
  }, [])

  // useEffect(() => {
  //   console.log(confirmation,"confirmation")
  // }, [confirmation])

  const handleChange = (tag: any, checked: boolean) => {
    const nextSelectedTags = checked
      ? combinationChargeFlag
        ? payMethod
          ? [...payMethod, tag]
          : [tag]
        : [tag]
      : combinationChargeFlag
      ? payMethod?.filter((t: any) => t !== tag)
      : []

    if (nextSelectedTags?.length > 3) {
      notification.info({
        message: '组合收费支持最多选中3个',
      })

      setPayMethod(nextSelectedTags?.slice(0, 3))
    } else {
      setPayMethod(nextSelectedTags)
    }
  }

  useEffect(() => {
    if (props?.forceCash) {
      setCombinationChargeFlag(false)
    } else {
      const flag: any = localStorage.getItem('combinationChargeFlag')
      setCombinationChargeFlag(flag == 'true' ? true : false)
    }
  }, [props?.forceCash])

  const confirmButton = () => {
    if (!payMethod?.length) {
      notification.error({
        message: '请至少选择一种收费方式',
      })
    } else {
      if (Number(amount) == 0) {
        if (payMethod?.length > 1) {
          notification.error({
            message: '应收金额为0时，只允许现金支付',
          })
        } else if (payMethod?.length == 1) {
          if (!payMethod?.find((v) => v == 1)) {
            notification.error({
              message: '应收金额为0时，只允许现金支付',
            })
          } else {
            if (confirmation) {
              //库存是否充足
              if (confirmation.errorMaterials.length > 0) {
                setIsModalVisible(true)
                setLoading(false)
              } else {
                if (
                  Number(receivedAmount) > 1000000 ||
                  Number(amount) > 1000000
                ) {
                  notification.error({
                    message: '金额输入有误，请确认。',
                  })
                  return
                }
                if (location.state?.backtrace?.path == '/retail') {
                  //药品零售页面过来
                  if (Number(receivedAmount) < Number(amount)) {
                    notification.error({
                      message: '收款金额不能小于应收金额!',
                    })
                    return
                  }
                }
                if (Number(singleDiscount) < 10) {
                  notification.error({
                    message: '整单折扣请输入大于10的数字！',
                  })
                  return
                }
                if (!combinationChargeFlag) {
                  if (
                    membersInfo &&
                    Number(membersInfo?.balanceMoney) <
                      Number(receivedAmount) &&
                    payMethod?.find((v) => v == 6)
                  ) {
                    setMembersSettlementModal(true)
                    return
                  }
                } else {
                  const money = systemPayMethod?.find((v) => v?.payMethod == 6)
                    ?.payAmount
                  if (
                    membersInfo &&
                    Number(membersInfo?.balanceMoney) < Number(money) &&
                    payMethod?.find((v) => v == 6)
                  ) {
                    setMembersSettlementModal(true)
                    return
                  }
                }

                loadState()
                if (payMethod?.find((v) => v == 2)) {
                  setJuheIptShow(true)
                  setPayCode('')
                  setCancelPayBtn(false)
                } else {
                  if (prePay) {
                    saveSettlement()
                  } else {
                    next()
                  }
                }
              }
            }
          }
        }
      } else {
        if (confirmation) {
          //库存是否充足
          if (confirmation.errorMaterials.length > 0) {
            setIsModalVisible(true)
            setLoading(false)
          } else {
            if (Number(receivedAmount) > 1000000 || Number(amount) > 1000000) {
              notification.error({
                message: '金额输入有误，请确认。',
              })
              return
            }
            if (location.state?.backtrace?.path == '/retail') {
              //药品零售页面过来
              if (Number(receivedAmount) < Number(amount)) {
                notification.error({
                  message: '收款金额不能小于应收金额!',
                })
                return
              }
            }
            if (Number(singleDiscount) < 10) {
              notification.error({
                message: '整单折扣请输入大于10的数字！',
              })
              return
            }
            if (!combinationChargeFlag) {
              if (
                membersInfo &&
                Number(membersInfo?.balanceMoney) < Number(receivedAmount) &&
                payMethod?.find((v) => v == 6)
              ) {
                setMembersSettlementModal(true)
                return
              }
            } else {
              const money = systemPayMethod?.find((v) => v?.payMethod == 6)
                ?.payAmount
              if (
                membersInfo &&
                Number(membersInfo?.balanceMoney) < Number(money) &&
                payMethod?.find((v) => v == 6)
              ) {
                setMembersSettlementModal(true)
                return
              }
            }

            loadState()
            if (payMethod?.find((v) => v == 2)) {
              setJuheIptShow(true)
              setPayCode('')
              setCancelPayBtn(false)
            } else {
              if (prePay) {
                saveSettlement()
              } else {
                next()
              }
            }
          }
        }
      }
    }
  }

  const getSmallTextByType = (m: any,) => {
    console.log(m,"222222222");
    const batchNo =
       m?.batchNo
        ? '/' + '批号:' + m?.batchNo
        : ''
      return (
        `${m.tenantSupplierName ? m.tenantSupplierName :''}${batchNo}`) 
  }

  return (
    <div className={styles.prePay}>
      <GlobalKeyboardAction
        onSpacePress={() => {
          confirmButton()
          // if (
          //   topUpModalVisible ||
          //   membersSettlementModal ||
          //   addMembersVisible ||
          //   searchMembersModalVisible
          // )
          //   return
          // if (isJuheModalVisible) return
          // // if (payMethod == 2) {
          // //   juheFn()
          // //   form.resetFields()
          // //   form.setFieldsValue({
          // //     remarks:
          // //       confirmation?.slots
          // //         .map((item) => {
          // //           return `${item.name}: ¥${item.value?.toFixed(2)}`
          // //         })
          // //         .toString() + '. ',
          // //   })
          // //   setIsJuheModalVisible(true)
          // // } else {
          // //   loadState()
          // //   if (prePay) {
          // //     saveSettlement()
          // //   } else {
          // //     next()
          // //   }
          // // }
        }}
      />
      {/* <GlobalKeyboardAction onSpacePress={() => next()} /> */}
      <Col
        style={{
          height: 'calc(100%)',
          padding: '10px 20px',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: theme.bg,
          overflow: 'hidden',
        }}
      >
        {location.state &&
          location.state?.where?.length &&
          (edtion == 1 && fromToothPatient ? (
            <NavBar
              zidingyiClick={() => {
                if (location.state) {
                  if (location.state.backtrace.path == '/toothPatient') {
                    tableUpdata(treatment?.patientId, '4')
                    return
                  }
                  dispatch(
                    traceRoute({
                      ...location?.state?.backtrace,
                      state: {
                        backSelectTab: 'editor',
                      },
                    })
                  )
                }
              }}
              onClick={() => {
                const obj = JSON.parse(localStorage.getItem('localObj') || '{}')
                const objs = {
                  ...obj,
                  state: 2,
                }
                localStorage.setItem('localObj', JSON.stringify(objs))
              }}
              where={location.state.where}
              // backtrace={location.state.backtrace}
              backtrace={{
                name: location?.state?.backtrace?.name,
                path: toothHomeReturnTag
                  ? '/toothPatient'
                  : location?.state?.backtrace?.path,
                query: location?.state?.backtrace?.query,
                state: {
                  backSelectTab: 'editor',
                },
              }}
              buttons={
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                  }}
                >
                  {treatment?.insuranceCode &&
                  treatment?.insuranceCode !== 'ZIFEI' ? (
                    <>
                      <Select
                        dropdownMatchSelectWidth={120}
                        defaultValue={readCardValue}
                        disabled={readLoading}
                        style={{
                          maxWidth: '160px',
                          color: theme.tc3,
                          height: 32,
                          marginLeft: 10,
                          backgroundColor: theme.c3,
                          border: 'none',
                        }}
                        size='middle'
                        // suffixIcon={<CaretDownFilled />}
                        options={readTypes}
                        onChange={(e) => {
                          setReadCardValue(e)
                        }}
                      />
                      <Button
                        style={{
                          color: '#fff',
                          height: 32,
                          backgroundColor: '#11a9cb',
                          border: 'none',
                        }}
                        onClick={() => {
                          readCard()
                        }}
                        loading={readLoading}
                        ref={readCardRef}
                      >
                        重新读卡
                      </Button>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              }
            />
          ) : (
            <NavBar
              onClick={() => {
                const obj = JSON.parse(localStorage.getItem('localObj') || '{}')
                const objs = {
                  ...obj,
                  state: 2,
                }
                localStorage.setItem('localObj', JSON.stringify(objs))
              }}
              where={location.state.where}
              // backtrace={location.state.backtrace}
              backtrace={{
                name: location?.state?.backtrace?.name,
                path: toothHomeReturnTag
                  ? '/toothPatient'
                  : location?.state?.backtrace?.path,
                query: location?.state?.backtrace?.query,
                state: {
                  backSelectTab: 'editor',
                },
              }}
              buttons={
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                  }}
                >
                  {treatment?.insuranceCode &&
                  treatment?.insuranceCode !== 'ZIFEI' ? (
                    <>
                      <Select
                        dropdownMatchSelectWidth={120}
                        defaultValue={readCardValue}
                        disabled={readLoading}
                        style={{
                          maxWidth: '160px',
                          color: theme.tc3,
                          height: 32,
                          marginLeft: 10,
                          backgroundColor: theme.c3,
                          border: 'none',
                        }}
                        size='middle'
                        // suffixIcon={<CaretDownFilled />}
                        options={readTypes}
                        onChange={(e) => {
                          setReadCardValue(e)
                        }}
                      />
                      <Button
                        style={{
                          color: '#fff',
                          height: 32,
                          backgroundColor: '#11a9cb',
                          border: 'none',
                        }}
                        onClick={() => {
                          readCard()
                        }}
                        loading={readLoading}
                        ref={readCardRef}
                      >
                        重新读卡
                      </Button>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              }
            />
          ))}
        <Row
          wrap={false}
          style={{
            flex: 1,
            marginTop: location.state ? 10 : 0,
            overflow: 'hidden',
          }}
        >
          <Col
            style={{
              width: '25rem',
              borderRadius: theme.br,
              backgroundColor: theme.pn,
              overflow: 'hidden',
            }}
          >
            <div className={styles.print}>
              <Label label='药品区域' style={{ marginLeft: 10 }} />
              <Button
                type='primary'
                size='small'
                className={styles.add}
                onClick={async () => {
                  setPrintTemplateCategory(PrintTemplateCategory.YHT)
                }}
              >
                药盒贴打印
              </Button>
            </div>
            <Divider style={{ borderColor: theme.c3, margin: 0 }} />
            <div
              style={{
                margin: '15px 20px 8px 20px',
                fontSize: '0.875rem',
                color: theme.tc2,
              }}
            >
              {confirmation?.patient} 本次费用明细如下：
            </div>
            <div
              className={
                treatment?.insuranceCode && treatment?.insuranceCode !== 'ZIFEI'
                  ? styles.ybLeft
                  : ''
              }
            >
              {confirmation?.slots?.map((s, i) => (
                <Table
                  className={styles.leftTableBack}
                  style={{
                    margin: '5px 10px',
                    border: `1px solid ${theme.wbd}`,
                  }}
                  key={i}
                  dataSource={s.children}
                  columns={
                   (treatment?.insuranceCode && treatment?.insuranceCode !== 'ZIFEI')
                      ? [
                        {
                          title: (
                            <span
                              style={{
                                color: theme.tc1,
                                fontSize: '0.875rem',
                                fontWeight: 'bold',
                              }}
                            >
                              {s.name}
                            </span>
                          ),
                          dataIndex: 'name',
                          render: (_, r: any) => ({
                            props: {
                              style: {
                                color: theme.tc2,
                                fontSize: '0.875rem',
                              },
                            },
                            children: (
                              <>
                                <div>{r.name}</div>
                                <Popover
                                  overlayClassName={styles.popverContent}
                                  content={getSmallTextByType(r)}
                                  placement='bottom'
                                  title={r.name}
                                >
                                  <div
                                    style={{
                                      color:
                                        r.quarterType == 1 &&
                                        insuranceArray?.[0]
                                          ?.chinese25pcAlert == 1
                                          ? '#ff5d5d'
                                          : theme.tc3,
                                      fontSize: '0.75rem',
                                      lineHeight: '0.75rem',
                                      width: '80%',
                                      whiteSpace: 'nowrap',
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                    }}
                                  >
                                    {getSmallTextByType(r)}
                                  </div>
                                </Popover>
                                {location.state?.prePay ? (
                                  r.payType == 0 ? (
                                    <div>(强制自费)</div>
                                  ) : (
                                    <></>
                                  )
                                ) : (
                                  <></>
                                )}
                              </>
                            ),
                          }),
                        },
                        {
                          title: (
                            <span
                              style={{
                                color: theme.tc1,
                                fontSize: '0.875rem',
                                fontWeight: 'bold',
                              }}
                            >
                              ¥{s.value?.toFixed(2)}
                            </span>
                          ),
                          align: 'right',
                          dataIndex: 'value',
                          render: (_, r) => ({
                            proprs: {
                              style: {
                                color: theme.tc2,
                                fontSize: '0.875rem',
                              },
                            },
                            children: `¥${r.value?.toFixed(2)}`,
                          }),
                        },
                        {
                          title: (
                            <span
                              style={{
                                color: theme.tc1,
                                fontSize: '0.875rem',
                                fontWeight: 'bold',
                              }}
                            >
                              {s.medicareDeductible}
                            </span>
                          ),
                          align: 'right',
                          dataIndex: 'value',
                          render: (_, r) => ({
                            proprs: {
                              style: {
                                color: theme.tc2,
                                fontSize: '0.875rem',
                              },
                            },
                            children: `¥${r.ybzfje?.toFixed(2)}`,
                          }),
                        },
                        ]
                      : [
                          {
                            title: (
                              <span
                                style={{
                                  color: theme.tc1,
                                  fontSize: '0.875rem',
                                  fontWeight: 'bold',
                                }}
                              >
                                {s.name}
                              </span>
                            ),
                            dataIndex: 'name',
                            render: (_, r: any) => ({
                              props: {
                                style: {
                                  color: theme.tc2,
                                  fontSize: '0.875rem',
                                },
                              },
                              children: (
                                <>
                                  <div>{r.name}</div>
                                  <Popover
                                    overlayClassName={styles.popverContent}
                                    content={getSmallTextByType(r)}
                                    placement='bottom'
                                    title={r.name}
                                  >
                                    <div
                                      style={{
                                        color:
                                          r.quarterType == 1 &&
                                          insuranceArray?.[0]
                                            ?.chinese25pcAlert == 1
                                            ? '#ff5d5d'
                                            : theme.tc3,
                                        fontSize: '0.75rem',
                                        lineHeight: '0.75rem',
                                        width: '80%',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                      }}
                                    >
                                      {getSmallTextByType(r)}
                                    </div>
                                  </Popover>
                                  {location.state?.prePay ? (
                                    r.payType == 0 ? (
                                      <div>(强制自费)</div>
                                    ) : (
                                      <></>
                                    )
                                  ) : (
                                    <></>
                                  )}
                                </>
                              ),
                            }),
                          },
                          {
                            title: (
                              <span
                                style={{
                                  color: theme.tc1,
                                  fontSize: '0.875rem',
                                  fontWeight: 'bold',
                                }}
                              >
                                ¥{s.value?.toFixed(2)}
                              </span>
                            ),
                            align: 'right',
                            dataIndex: 'value',
                            render: (_, r) => ({
                              proprs: {
                                style: {
                                  color: theme.tc2,
                                  fontSize: '0.875rem',
                                },
                              },
                              children: `¥${r.value?.toFixed(2)}`,
                            }),
                          },
                        ]
                  }
                  pagination={false}
                  size='small'
                />
              ))}
            </div>
            {prePay &&
              treatment?.insuranceCode &&
              treatment?.insuranceCode !== 'ZIFEI' && (
                <div className={styles.print}>
                  <Label
                    label='医保支付信息'
                    style={{ marginLeft: 10, marginTop: 10 }}
                  />
                </div>
              )}
            {prePay &&
              treatment?.insuranceCode &&
              treatment?.insuranceCode !== 'ZIFEI' && (
                <div
                  style={{
                    margin: '0 15px',
                    maxHeight: '40%',
                    overflow: 'auto',
                    paddingRight:'40px'
                  }}
                >
                  <table style={{width:'100%',}}>
                    <tr>
                      <td style={{ fontWeight: 'bold', }}>
                        统筹支付：
                        <span style={{ color: 'red' }}>
                          {Number(prePay?.setlinfo.jijinzfze)?.toFixed(2)}
                        </span>
                      </td>
                      <td style={{ textAlign: 'right', fontWeight: 'bold', }}>
                        个账支付：
                        <span style={{ color: 'red' }}>
                          {Number(prePay?.setlinfo.gerenzhzc)?.toFixed(2)}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ fontWeight: 'bold' }}>
                        实际起付线：
                        <span style={{ color: 'red' }}>
                          {Number(prePay?.setlinfo.shijizfqfx)?.toFixed(2)}
                        </span>
                      </td>
                      <td style={{ textAlign: 'right', fontWeight: 'bold' }}>
                        账户余额：
                        <span style={{ color: 'red' }}>
                          {Number(prePay?.setlinfo.yue)?.toFixed(2)}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        费用总额：
                        {confirmation?.payment?.totalPayAmount
                          ? Number(
                              confirmation?.payment?.totalPayAmount
                            )?.toFixed(2)
                          : '-'}
                      </td>
                      <td style={{ textAlign: 'right' }}>
                        全自费金额：
                        {Number(prePay?.setlinfo.quanzifje)?.toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        纳入政策金额：
                        {Number(prePay?.setlinfo.fuhezcfwje)?.toFixed(2)}
                      </td>
                      <td style={{ textAlign: 'right' }}>
                        个人负担：
                        {Number(prePay?.setlinfo.gerenfdzje)?.toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        超限价自费：
                        {Number(prePay?.setlinfo.chaoxianjzffy)?.toFixed(2)}
                      </td>
                      <td style={{ textAlign: 'right' }}>
                        医院承担：
                        {Number(prePay?.setlinfo.yiyuancdfy)?.toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        公务员补助：
                        {Number(prePay?.setlinfo.gongwuyylbzzjzc)?.toFixed(2)}
                      </td>
                      <td style={{ textAlign: 'right' }}>
                        账户共济支付：
                        {Number(prePay?.setlinfo.gerenzhgjzfje)?.toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        企业补充基金：
                        {Number(prePay?.setlinfo.qiyebcylbxjjzc)?.toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        居民大病保险：
                        {Number(prePay?.setlinfo.jumindbbxjjzc)?.toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        职工大额医疗基金：
                        {Number(prePay?.setlinfo.zhigongdeylfybzjjzc)?.toFixed(
                          2
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        医疗救助基金支出：
                        {Number(prePay?.setlinfo.yiliaojzjjzc)?.toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        其他支出：{Number(prePay?.setlinfo.qitazc)?.toFixed(2)}
                      </td>
                    </tr>
                  </table>
                </div>
              )}
          </Col>
          <Col
            flex={1}
            style={{
              position: 'relative',
            }}
          >
            <div
              style={{
                flex: 1,
                height: '100%',
                color: theme.tc1,
                fontSize: '1rem',
                paddingBottom: 30,
                borderRadius: theme.br,
                backgroundColor: theme.pn,
                marginLeft: 20,
                overflow: 'auto',
              }}
            >
              <Label label='收银结算' style={{ marginLeft: 10 }} />
              <Divider style={{ borderColor: theme.c3, margin: 0 }} />
              {treatment?.insuranceCode &&
                treatment?.insuranceCode !== 'ZIFEI' &&
                prePay?.cardType && (
                  <Row
                    style={{
                      marginTop:
                        location.state && !props.YUSHOUGUAHAOFEI ? '3rem' : 20,
                    }}
                    align='bottom'
                    wrap={false}
                  >
                    <Col flex='7rem' style={{ textAlign: 'right' }}>
                      读卡方式：
                    </Col>
                    <Col
                      style={{
                        marginLeft: 10,
                      }}
                    >
                      {getCardType(prePay?.cardType)}
                    </Col>
                  </Row>
                )}

              {location.state?.prePay && (
                <>
                  <Row
                    style={{
                      marginTop: 20,
                    }}
                    align='bottom'
                    wrap={false}
                  >
                    <Col flex='7rem' style={{ textAlign: 'right' }}>
                      总金额：
                    </Col>
                    <Col
                      style={{
                        color: theme.e,
                        fontSize: '1.25rem',
                        fontWeight: 'bold',
                        marginLeft: 10,
                      }}
                    >
                      {confirmation?.payment?.totalPayAmount
                        ? Number(
                            confirmation?.payment?.totalPayAmount
                          )?.toFixed(2)
                        : '-'}
                      {/* {Number(prePay?.setlinfo.yiliaofze).toFixed(2)} */}
                    </Col>
                  </Row>
                  <Row style={{ marginTop: 20 }} align='bottom' wrap={false}>
                    <Col flex='7rem' style={{ textAlign: 'right' }}>
                      医保支付：
                    </Col>
                    <Col
                      style={{
                        color: '#088f52',
                        fontSize: '1.25rem',
                        fontWeight: 'bold',
                        marginLeft: 10,
                      }}
                    >
                      {Number(
                        confirmation?.payment?.insurancePayAmount
                      )?.toFixed(2)}
                      {/* {(
                      Number(prePay?.setlinfo.yiliaofze) -
                      Number(prePay?.setlinfo.gerenxjzc)
                    ).toFixed(2)} */}
                      <Tooltip placement='right' title={medicalText}>
                        <QuestionCircleOutlined
                          style={{ color: '#ccc', margin: '0 10px' }}
                        />
                      </Tooltip>
                    </Col>
                  </Row>
                </>
              )}
              {props.confirmation?.prePay && (
                <>
                  <Row
                    style={{
                      marginTop:
                        location.state && props.YUSHOUGUAHAOFEI ? 80 : 20,
                    }}
                    align='bottom'
                    wrap={false}
                  >
                    <Col flex='7rem' style={{ textAlign: 'right' }}>
                      总金额：
                    </Col>
                    <Col
                      style={{
                        color: theme.e,
                        fontSize: '1.25rem',
                        fontWeight: 'bold',
                        marginLeft: 10,
                      }}
                    >
                      {confirmation?.payment?.totalPayAmount
                        ? Number(
                            confirmation?.payment?.totalPayAmount
                          )?.toFixed(2)
                        : '-'}
                      {/* {Number(prePay?.setlinfo.yiliaofze).toFixed(2)} */}
                    </Col>
                  </Row>
                  <Row style={{ marginTop: 20 }} align='bottom' wrap={false}>
                    <Col flex='7rem' style={{ textAlign: 'right' }}>
                      医保支付：
                    </Col>
                    <Col
                      style={{
                        color: '#088f52',
                        fontSize: '1.25rem',
                        fontWeight: 'bold',
                        marginLeft: 10,
                      }}
                    >
                      {Number(
                        confirmation?.payment?.insurancePayAmount
                      )?.toFixed(2)}
                      {/* {(
                      Number(prePay?.setlinfo.yiliaofze) -
                      Number(prePay?.setlinfo.gerenxjzc)
                    ).toFixed(2)} */}
                      <Tooltip placement='right' title={medicalModalText}>
                        <QuestionCircleOutlined
                          style={{ color: '#ccc', margin: '0 10px' }}
                        />
                      </Tooltip>
                    </Col>
                  </Row>
                </>
              )}
              <Row
                style={{
                  marginTop:
                    location.state &&
                    !location.state.prePay &&
                    !props.YUSHOUGUAHAOFEI
                      ? 80
                      : 20,
                }}
                align='bottom'
                wrap={false}
              >
                <Col flex='7rem' style={{ textAlign: 'right' }}>
                  {treatment?.insuranceCode &&
                  treatment?.insuranceCode !== 'ZIFEI'
                    ? '现金金额：'
                    : '总金额：'}
                </Col>
                <Col
                  style={{
                    color: theme.e,
                    fontSize: '1.25rem',
                    fontWeight: 'bold',
                    marginLeft: 10,
                  }}
                >
                  {treatment?.insuranceCode &&
                  treatment?.insuranceCode !== 'ZIFEI'
                    ? prePay
                      ? Number(confirmation?.payment?.shouldPayAmount)?.toFixed(
                          2
                        ) || '-'
                      : props.confirmation?.amount?.toFixed(2)
                      ? props.confirmation?.amount?.toFixed(2)
                      : confirmation?.payment?.shouldPayAmount
                      ? Number(confirmation?.payment?.shouldPayAmount)?.toFixed(
                          2
                        )
                      : '0.00'
                    : prePay
                    ? Number(confirmation?.payment?.totalPayAmount)?.toFixed(
                        2
                      ) || '-'
                    : props.confirmation?.amount?.toFixed(2)
                    ? props.confirmation?.amount?.toFixed(2)
                    : confirmation?.payment?.totalPayAmount
                    ? Number(confirmation?.payment?.totalPayAmount)?.toFixed(2)
                    : '0.00'}

                  {JuheShow?.openRounding == 1 && (
                    <span>
                      ≈{' '}
                      {prePay
                        ? Number(
                            confirmation?.payment?.shouldPayAmount
                          )?.toFixed(1)
                        : props.confirmation?.amount?.toFixed(1) ||
                          Number(
                            confirmation?.payment?.shouldPayAmount
                          )?.toFixed(1)}
                    </span>
                  )}
                </Col>
              </Row>

              <Row align='middle' style={{ marginTop: 30 }}>
                <Col style={{ marginRight: '40px' }}>
                  <Row align='middle' wrap={false}>
                    <Col flex='7rem' style={{ textAlign: 'right' }}>
                      应收金额：
                    </Col>
                    <Col>
                      <Input
                        maxLength={12}
                        autoFocus
                        style={{
                          width: '18rem',
                          marginLeft: 10,
                          fontSize: '1rem',
                          color: theme.tc1,
                        }}
                        disabled={JuheIptDisabled || props.status == 2}
                        inputMode='decimal'
                        value={amount}
                        onChange={(e) => {
                          const { value } = e.target
                          const reg = /^\d*(\.\d*)?$/
                          if (reg.test(value) || value === '') {
                            setAmount(value)
                            // calcZdzkDiscount(value)
                            setReceivedAmount(value)
                          }
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col>
                  {props.membersStatus != 0 && (
                    <Row align='bottom' wrap={false}>
                      {/* <Col flex='7rem' style={{ textAlign: 'right' }}>
                      整单折扣：
                    </Col> */}
                      <Col>
                        <Input
                          maxLength={
                            singleDiscount?.split('.')?.[0]?.length == 2 ? 5 : 4
                          }
                          style={{
                            width: '5rem',
                            marginLeft: 10,
                            fontSize: '1rem',
                            color: theme.tc1,
                          }}
                          disabled={
                            (treatment?.insuranceCode &&
                              treatment?.insuranceCode !== 'ZIFEI') ||
                            props.status == 2
                          }
                          inputMode='decimal'
                          value={singleDiscount}
                          onChange={(e) => {
                            const { value } = e.target
                            const reg = /^(?!0)\d{1,2}\.\d{1,2}$|^(?!0)\d{1,2}$|^100$/

                            if (
                              (Number(value) > 0 && Number(value) < 100) ||
                              !value
                            ) {
                              setSingleDiscount(value)
                            }
                            if (reg.test(value) || value == '') {
                              if (Number(value) >= 10) {
                                setSubmitFlag(true)
                              } else {
                                return
                              }
                            }
                          }}
                          // onPressEnter={() => {
                          //   calcZdzk()
                          // }}
                          // onBlur={() => {
                          //   // calcZdzk()
                          //   if (Number(receivedAmount)){
                          //      notification.error({
                          //       message: '整单折扣请输入大于10的数字！',
                          //     })
                          //   }
                          // }}
                        />
                      </Col>
                      <span style={{ margin: '5px' }}>%</span>
                      <span
                        style={{
                          margin: '8px 4px',
                          fontStyle: 'oblique',
                          color: '#9b9898',
                          fontSize: '12px',
                        }}
                      >
                        (默认100%时不打折)
                      </span>
                    </Row>
                  )}
                </Col>
              </Row>
              {/* <Row style={{ marginTop: 26, display: JuheShow?.openOwe == 1 && location.state?.settlementCategory !== 'retail' ? 'none' : '' }} align='bottom'>
              <Col flex='7rem' style={{ textAlign: 'right' }}>
                还需收费：
              </Col>
              <Col
                style={{
                  color: theme.e,
                  fontSize: '1.25rem',
                  fontWeight: 'bold',
                  marginLeft: 10,
                }}
              >
                {JuheCost}
              </Col>
            </Row> */}
              <Row
                align='middle'
                style={{
                  marginTop: 30,
                  display: 'flex',
                  alignItems: 'flex-start',
                }}
              >
                <Col
                  flex='7rem'
                  style={{ textAlign: 'right', width: '30%', marginTop: '45x' }}
                >
                  收费方式：
                </Col>
                <Col
                  style={{ marginLeft: 10, flex: 1 }}
                  className={styles.paymentWay}
                >
                  <Space size='large' wrap>
                    {payList?.map(({ icon, color, method, type }) => {
                      const Icon = icon
                      return (
                        <Tooltip
                          key={method}
                          overlay={getPayMethodName(method)}
                        >
                          <CheckableTag
                            style={{
                              width: 34,
                              height: 34,
                              border: '1px solid #d2d2d2',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                            key={method}
                            checked={payMethod?.find((v) => v == method)}
                            onChange={(checked: any) =>
                              handleChange(method, checked)
                            }
                          >
                            <div
                              className={
                                payMethod?.find((v) => v == method)
                                  ? styles.selectIcon
                                  : ''
                              }
                            >
                              <Icon
                                style={{
                                  fontSize: '1.5rem',
                                  color,
                                  display: type == 1 ? 'block' : 'none',
                                  borderRadius: 2,
                                  padding: '4px',
                                }}
                              />

                              <div>
                                <JuhePay
                                  mode='small'
                                  style={{
                                    display: type == 0 ? 'block' : 'none',
                                    borderRadius: 2,
                                    padding: '4px',
                                    width: '36px',
                                    height: '36px',
                                    fontSize: '30px',
                                    cursor: 'pointer',
                                  }}
                                />
                              </div>
                              {props.membersStatus != 0 && (
                                <div
                                  onClick={() => {
                                    if (!membersInfo) {
                                      //是否有会员
                                      if (payMethod?.find((v) => v == method)) {
                                        setSearchMembersModalVisible(false)
                                      } else {
                                        setSearchMembersModalVisible(true)
                                      }
                                    }
                                    setPayMethod([method])
                                    setJuheIptShow(false)
                                  }}
                                >
                                  <MembersIcon
                                    mode='small'
                                    style={{
                                      display: props?.YUSHOUGUAHAOFEI
                                        ? 'none'
                                        : type == 2
                                        ? 'block'
                                        : 'none',
                                      borderRadius: 2,
                                      padding: '4px',
                                      width: '34px',
                                      height: '34px',
                                      fontSize: '30px',
                                      cursor: 'pointer',
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                          </CheckableTag>
                        </Tooltip>
                      )
                    })}
                    {!props?.forceCash &&
                      paymentWayList?.map((v: any) => {
                        const icon = getIconImg(v?.methodIcon)
                        return (
                          <Tooltip key={v?.id} overlay={v?.methodName}>
                            <div
                              className={
                                payMethod?.find((i) => i == v?.id)
                                  ? styles.selectIcon
                                  : ''
                              }
                            >
                              <CheckableTag
                                style={{
                                  width: 34,
                                  height: 34,
                                  border: '1px solid #d2d2d2',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}
                                key={v?.id}
                                checked={payMethod?.find((i) => i == v?.id)}
                                onChange={(checked: any) =>
                                  handleChange(v?.id, checked)
                                }
                              >
                                <div>
                                  <img
                                    src={icon}
                                    style={{
                                      borderRadius: 2,
                                      padding: '4px',
                                      width: '32px',
                                      height: '32px',
                                      fontSize: '30px',
                                      cursor: 'pointer',
                                    }}
                                  />
                                </div>
                              </CheckableTag>
                            </div>
                          </Tooltip>
                        )
                      })}
                    {!props?.forceCash ? (
                      <>
                        <Checkbox
                          checked={combinationChargeFlag}
                          onChange={(e: any) => {
                            setPayMethod([defaultPayMethod || PayMethod.Cash])
                            setCombinationChargeFlag(e.target.checked)
                            localStorage.setItem(
                              'combinationChargeFlag',
                              e.target.checked
                            )
                            setReceivedAmount(amount)
                          }}
                        >
                          组合收费
                        </Checkbox>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <SettingOutlined
                            style={{
                              fontSize: '18px',
                              color: '#027AFF',
                            }}
                          />
                          <span
                            style={{
                              marginLeft: '6px',
                              fontSize: '14px',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              setIsChargeSettingModalVisible(true)
                            }}
                          >
                            收费设置
                          </span>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </Space>
                </Col>
              </Row>

              {/* {JuheIptShow && (
              <Row align='middle' style={{ marginTop: 40 }}>
                <Col flex='7rem' style={{ textAlign: 'right' }}>
                  {'扫描收款码：'}
                </Col>
                <Col style={{ position: 'relative' }}>
                  <div className={styles.title}>{JuheText}</div>
                  <Input
                    ref={inputRef}
                    style={{
                      width: '18rem',
                      marginLeft: 10,
                      fontSize: '20px',
                      color: 'green',
                    }}
                    disabled={JuheIptDisabled}
                    inputMode='decimal'
                    maxLength={18}
                    value={payCode}
                    onChange={(e) => {
                      const { value } = e.target
                      const reg = /^[0-9]+$/
                      if (reg.test(value) || value === '') {
                        setPayCode(value)
                        if (value.length == 18) {
                          // 输入款是否满18位
                          if (confirmation) {
                            if (confirmation.errorMaterials.length > 0) {
                              // 是否有库存不足
                              setIsModalVisible(true)
                              setLoading(false)
                            } else {
                              setJuheIptDisabled(true)
                              const quickTreatment =
                                remoteConfirmation?.payment?.treatmentId
                              const treatmentId = treatment?.id
                                ? treatment?.id
                                : props.confirmation
                                ? props.confirmation.treatmentId
                                : quickTreatment
                              dispatch(
                                JuhePayFn({
                                  payCode: value,
                                  payPrice: Number(receivedAmount) || 0,
                                  treatmentId,
                                  patientName: treatment?.patientName
                                    ? treatment?.patientName
                                    : '零售患者',
                                  type: treatment?.id ? 0 : 1,
                                })
                              )
                                .then(unwrapResult)
                                .then((res) => {
                                  setjuhePayId(res.data)
                                  clearInterval(timer)
                                  timer = null
                                  setTimerFlag(true)
                                })
                                .catch(() => {
                                  setJuheIptDisabled(false)
                                  setTimerFlag(false) //关闭定时器
                                  setPayCode('')
                                })
                            }
                          }
                        }
                      }
                    }}
                  />
                </Col>
                <Col>
                  {cancelPayBtn && (
                    <Button
                      style={{ marginLeft: '10px' }}
                      onClick={() => {
                        dispatch(
                          JuhePayCancle({
                            payId: juhePayId,
                          })
                        )
                          .then(unwrapResult)
                          .then((res) => {
                            notification.success({
                              message:
                                '撤销成功，请告知患者取消支付，如需再次支付，请重新扫码。',
                            })
                            setJuheIptDisabled(false)
                            setTimerFlag(false) //关闭定时器
                            setJuheText(
                              '支付失败或撤销支付，请重新扫码进行支付'
                            )
                            setPayCode('')
                            setCancelPayBtn(false)
                          })
                      }}
                    >
                      撤销收款
                    </Button>
                  )}
                  <Button
                    loading={loading}
                    disabled={loading}
                    style={{ marginLeft: '10px' }}
                    onClick={() => {
                      if (!timerFlag) {
                        juheFn()
                      }
                    }}
                  >
                    查询付款状态
                  </Button>
                </Col>
              </Row>
            )} */}
              {!combinationChargeFlag ? (
                <Row
                  align='middle'
                  style={{ textAlign: 'right', marginTop: 30 }}
                >
                  <Col flex='7rem'>{'收款：'}</Col>
                  <Col>
                    <Input
                      style={{
                        width: '18rem',
                        marginLeft: 10,
                        fontSize: '1rem',
                        color: theme.tc1,
                      }}
                      ref={inputRef}
                      onClick={() => {
                        inputRef?.current?.select() // 选择文本
                      }}
                      maxLength={12}
                      inputMode='decimal'
                      disabled={JuheIptDisabled || props.status == 2}
                      value={receivedAmount}
                      onChange={(e) => {
                        const { value } = e.target
                        const reg = /^\d*(\.\d*)?$/
                        if (reg.test(value) || value === '') {
                          // setAmount(value)
                          setReceivedAmount(value)
                        }
                      }}
                    />
                  </Col>
                </Row>
              ) : (
                <>
                  {selectZuHeOrNoZuHeList?.map((k: any, index: any) => {
                    return (
                      <>
                        <Row
                          align='middle'
                          style={{ textAlign: 'right', marginTop: 30 }}
                        >
                          <Col flex='7rem' style={{ marginLeft: '-10px' }}>
                            {k?.methodName + ' : '}
                          </Col>
                          <Col style={{ marginLeft: '10px' }}>
                            <Input
                              style={{
                                width: '18rem',
                                marginLeft: 10,
                                fontSize: '1rem',
                                color: theme.tc1,
                              }}
                              maxLength={12}
                              ref={inputRefs[index]} // 分配 inputRef 给 Input
                              onClick={() => {
                                systemPayMethod?.[index]?.payAmount &&
                                  inputRefs[index].current?.select() // 选择文本
                              }}
                              inputMode='decimal'
                              disabled={JuheIptDisabled || props.status == 2}
                              value={systemPayMethod
                                ?.find((n) => n?.payMethod == k?.method)
                                ?.payAmount?.toString()}
                              defaultValue={'0.00'}
                              onChange={(e) => {
                                const { value } = e.target
                                // 使用正则表达式验证输入值
                                const regex = /^\d+(\.\d{0,2})?$/
                                if (regex.test(value) || value === '') {
                                  const updatedValues: any = systemPayMethod
                                    ? [...systemPayMethod]
                                    : []

                                  updatedValues[index] = {
                                    payAmount: Number(value).toFixed(2),
                                    payMethod: k?.method,
                                  }
                                  setSystemPayMethod(updatedValues)
                                  reCalcPrice(index)
                                }
                              }}
                            />
                          </Col>
                        </Row>
                      </>
                    )
                  })}
                </>
              )}

              <Row align='bottom' style={{ marginTop: 30 }}>
                <Col flex='7rem' style={{ textAlign: 'right' }}>
                  {'找　　零：'}
                </Col>
                <Col
                  style={{
                    color: theme.e,
                    fontSize: '1.25rem',
                    fontWeight: 'bold',
                    marginLeft: 10,
                  }}
                >
                  {discount}
                </Col>
                {oweMoneNumShow && (
                  <>
                    <Col flex='7rem' style={{ textAlign: 'right' }}>
                      {'欠　　费：'}
                    </Col>
                    <Col
                      style={{
                        color: theme.e,
                        fontSize: '1.25rem',
                        fontWeight: 'bold',
                        marginLeft: 10,
                      }}
                    >
                      {oweMoneNum}
                    </Col>
                  </>
                )}
              </Row>
              {(!props.confirmation || props.status) && (
                <Row style={{ marginTop: '3rem', paddingLeft: 156 }}>
                  <Space>
                    <Button
                      onClick={() => {
                        if (props.status == 1 || props.status == 2) {
                          //his 口腔收费取消
                          props.onChargeCancel && props.onChargeCancel()
                        } else {
                          if (location.state) {
                            if (
                              location.state.backtrace.path == '/toothPatient'
                            ) {
                              tableUpdata(treatment?.patientId, '4')
                              return
                            }
                            dispatch(
                              traceRoute({
                                ...location?.state?.backtrace,
                                state: {
                                  backSelectTab: 'editor',
                                },
                              })
                            )
                          }
                        }
                      }}
                    >
                      取消
                    </Button>
                    <Button
                      type='primary'
                      onKeyDown={(e) => e.preventDefault()}
                      loading={loading}
                      disabled={JuheIptShow || zdzkShow}
                      onClick={() => {
                        confirmButton()
                      }}
                    >
                      {settlementBtnText}
                    </Button>
                    {props?.chrgBchno == 2 ? (
                      <></>
                    ) : (
                      prePay &&
                      tenantAreaCd?.slice(0, 4) == '3308' && (
                        <Checkbox
                          checked={urgencyAccount}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setUrgencyVisible(true)
                            }
                            setUrgencyAccount(e.target.checked)
                          }}
                          style={{ marginLeft: 50 }}
                        >
                          紧急结算（跳过人脸识别）
                        </Checkbox>
                      )
                    )}
                  </Space>
                </Row>
              )}
              {props?.chrgBchno == 2 ? (
                <> </>
              ) : (
                tenantAreaCd?.slice(0, 4) == '3308' &&
                props.YUSHOUGUAHAOFEI &&
                props.confirmation?.prePay && (
                  <Row style={{ marginTop: 20, paddingLeft: 156 }}>
                    <Checkbox
                      checked={urgencyAccount}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setUrgencyVisible(true)
                        }
                        setUrgencyAccount(e.target.checked)
                      }}
                      style={{ marginLeft: 50 }}
                    >
                      紧急结算（跳过人脸识别）
                    </Checkbox>
                  </Row>
                )
              )}
              {/* {prePay && tenantAreaCd?.slice(0, 4) == '3308' && (
                    <Checkbox
                      checked={urgencyAccount}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setUrgencyVisible(true)
                        }
                        setUrgencyAccount(e.target.checked)
                      }}
                      style={{ marginLeft: 50 }}
                    >
                      紧急结算（跳过人脸识别）
                    </Checkbox>
                  )}
                </Space>
              </Row>
            )} */}
              {/* {tenantAreaCd?.slice(0, 4) == '3308' &&
              props.YUSHOUGUAHAOFEI &&
              props.confirmation?.prePay && (
                <Row style={{ marginTop: 20, paddingLeft: 156 }}>
                  <Checkbox
                    checked={urgencyAccount}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setUrgencyVisible(true)
                      }
                      setUrgencyAccount(e.target.checked)
                    }}
                    style={{ marginLeft: 50 }}
                  >
                    紧急结算（跳过人脸识别）
                  </Checkbox>
                </Row>
              )} */}
            </div>
            {membersInfo && (
              <div className={styles.membersInfoWrap}>
                <span style={{ fontWeight: 'bold' }}>会员信息</span>
                <Row style={{ marginTop: 10 }}>
                  <Col span={10} style={{ textAlign: 'right' }}>
                    手机号：
                  </Col>
                  <Col span={12} style={{ textAlign: 'left' }}>
                    {membersInfo?.memberPhone}
                  </Col>
                </Row>
                <Row style={{ margin: '10px 0' }}>
                  <Col span={10} style={{ textAlign: 'right' }}>
                    姓名：
                  </Col>
                  <Col span={12} style={{ textAlign: 'left' }}>
                    {membersInfo?.memberName}
                  </Col>
                </Row>
                <Row>
                  <Col span={10} style={{ textAlign: 'right' }}>
                    账户余额：
                  </Col>
                  <Col span={12} style={{ textAlign: 'left' }}>
                    {membersInfo?.balanceMoney?.toFixed(2)}
                  </Col>
                </Row>
                <div style={{ marginTop: 20 }}>
                  <Button
                    size='middle'
                    type='primary'
                    onClick={() => {
                      setMembersInfo(undefined)
                      setMembersStatus(1)
                      setPayMethod([defaultPayMethod])
                      notification.success({ message: '登出成功' })
                    }}
                  >
                    登出当前会员
                  </Button>
                </div>
              </div>
            )}
          </Col>
          {location.state?.prePay && (
            <Col
              style={{
                width: '18rem',
                borderRadius: theme.br,
                backgroundColor: theme.pn,
                overflow: 'auto',
                marginLeft: 20,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Label label='医保智能审核' style={{ marginLeft: 10 }} />
              <Divider style={{ borderColor: theme.c3, margin: 0 }} />
              <div
                style={{
                  margin: '15px 20px 8px 20px',
                  fontSize: '1rem',
                  color: theme.tc2,
                }}
              >
                {inprocessAnalysisList && inprocessAnalysisList.length
                  ? '请注意，本次收费的审核结果如下：'
                  : '本次收费没有审核异常信息'}
              </div>
              {inprocessAnalysisList && inprocessAnalysisList.length && (
                <div
                  style={{
                    flex: 1,
                  }}
                >
                  <Table
                    style={{
                      margin: '5px 20px',
                      border: `1px solid ${theme.wbd}`,
                    }}
                    dataSource={inprocessAnalysisList}
                    columns={[
                      {
                        title: '序号',
                        align: 'center',
                        width: '5em',
                        render: (_1, _2, index) => `${index + 1}`,
                      },
                      {
                        title: '异常原因',
                        align: 'center',
                        dataIndex: 'value',
                        render: (_, r) => {
                          return <div style={{ textAlign: 'left' }}>{r}</div>
                        },
                      },
                    ]}
                    pagination={false}
                    size='small'
                  />
                </div>
              )}
              {inprocessAnalysisList && inprocessAnalysisList.length && (
                <div
                  style={{
                    padding: '20px',
                    fontStyle: 'italic',
                    fontSize: '12px',
                  }}
                >
                  <Row>*本次智能审核由智慧医保接口提供。</Row>
                  <Row>如您收费的处方存在异常或违规，医保可能不予支付。</Row>
                  <Row>
                    您可以重新编辑处方后再次预结算。若您选择忽略该审核结果，请继续结算。
                  </Row>
                </div>
              )}
            </Col>
          )}
        </Row>
      </Col>
      <Modal
        title='系统提示'
        width={900}
        okText='确认'
        visible={isModalVisible}
        cancelButtonProps={{ style: { display: 'none' } }}
        onCancel={() => setIsModalVisible(false)}
        onOk={() => setIsModalVisible(false)}
      >
        <Table
          className={styles.table}
          dataSource={confirmation?.errorMaterials}
          pagination={false}
          size='small'
          bordered
          rowKey={(_, index) => `${index}`}
          columns={[
            {
              title: '序号',
              key: 'no',
              align: 'center',
              width: '3rem',
              render: (_1, _2, index) => `${index + 1}`,
            },
            {
              title: '药品名称',
              key: 'name',
              align: 'center',
              ellipsis: true,
              render: (_, m) => ({
                props: {
                  style: {
                    whiteSpace: 'nowrap',
                  },
                },
                children: m.name,
              }),
            },
            {
              title: '所需数量',
              dataIndex: 'drugCount',
              align: 'center',
              width: '5rem',
            },
            {
              title: '原因',
              key: 'message',
              align: 'center',
              ellipsis: true,
              render: (_, m) => ({
                props: {
                  style: {
                    whiteSpace: 'nowrap',
                  },
                },
                children: m.inventoryInfo || m.unShelveInfo || m.skinTestInfo,
              }),
            },
          ]}
        />
      </Modal>

      {/* 紧急结算弹框 */}
      <Modal
        title='紧急结算须知'
        width={550}
        cancelText='返回'
        visible={urgencyVisible}
        onCancel={() => {
          setUrgencyAccount(false)
          setUrgencyVisible(false)
        }}
        onOk={() => setUrgencyVisible(false)}
      >
        <h3>应医保局要求，紧急结算仅在以下场景可使用：</h3>
        <p>1.摄像头损坏不可用时。</p>
        <p>2.实名制APP不可用时。</p>
        <p>3.实名制网络出现问题时。</p>
        <p className={styles.urgencyTips}>
          紧急结算相当于绕开了实名制认证，地区医保局会严格监管紧急结算行为，发现操作人员恶意滥用紧急结算将对所在的两定机构进行严肃处理。
        </p>
      </Modal>

      <SearchMembersModal
        visible={searchMembersModalVisible}
        onCancel={() => {
          if (combinationChargeFlag) {
            setPayMethod(payMethod?.filter((v) => v != 6))
          } else {
            setPayMethod([defaultPayMethod])
          }
          setSearchMembersModalVisible(false)
        }}
        onOk={(data) => {
          // setMembersInfo(data)
          getIsMembers(data.memberPhone)
          setSearchMembersModalVisible(false)
          // notification.success({ message: '查询成功' })
        }}
        openFn={() => {
          setAddMembersVisible(true)
          setSearchMembersModalVisible(false)
        }}
      />
      <AddMembersModal
        visible={addMembersVisible}
        btnType={addBtnType}
        onCancel={() => setAddMembersVisible(false)}
        onOk={(data) => {
          dispatch(getAddMembers(data))
            .then(unwrapResult)
            .then(() => notification.success({ message: '新增成功' }))
            .finally(() => {
              setAddBtnType(!addBtnType)
              setAddMembersVisible(false)
              getIsMembers(Number(data.memberPhone))
            })
        }}
      />
      <MembersSettlementModal
        visible={membersSettlementModal}
        membersInfo={membersInfo}
        onCancel={() => setMembersSettlementModal(false)}
        onOk={() => {
          setMembersSettlementModal(false)
          setTopUpModalVisible(true)
        }}
      />
      <JuheIptShowModal
        juheText={JuheText}
        JHZFMoney={
          combinationChargeFlag
            ? systemPayMethod?.find((v) => v?.payMethod == 2)?.payAmount
            : receivedAmount
        }
        visible={JuheIptShow}
        JuheIptDisabled={JuheIptDisabled}
        cancelPayBtn={cancelPayBtn}
        loading={loading}
        payCode={payCode}
        JHZFInputChange={(value) => {
          setPayCode(value)
          if (value.length == 18) {
            // 设置标志，表示已经调用过接口
            // 输入款是否满18位
            if (confirmation) {
              if (confirmation.errorMaterials.length > 0) {
                // 是否有库存不足
                setIsModalVisible(true)
                setLoading(false)
              } else {
                setJuheIptDisabled(true)
                const quickTreatment = remoteConfirmation?.payment?.treatmentId
                const treatmentId = treatment?.id
                  ? treatment?.id
                  : props.confirmation
                  ? props.confirmation.treatmentId
                  : quickTreatment
                dispatch(
                  JuhePayFn({
                    payCode: value,
                    payPrice: combinationChargeFlag
                      ? Number(
                          systemPayMethod?.find((v) => v?.payMethod == 2)
                            ?.payAmount
                        ) || 0
                      : Number(receivedAmount) || 0,
                    treatmentId,
                    patientName: treatment?.patientName
                      ? treatment?.patientName
                      : '零售患者',
                    type: treatment?.id ? 0 : 1,
                    recipeIdList: props.status
                      ? confirmation?.recipeIdList
                      : query.get('ids')?.split(',') || [],
                  })
                )
                  .then(unwrapResult)
                  .then((res) => {
                    setjuhePayId(res.data)
                    clearInterval(timer)
                    timer = null
                    setTimerFlag(true)
                    if (payMethod?.find((v) => v == 2)) {
                      setJuheIptShow(true)
                      setPayCode('')
                      setCancelPayBtn(false)
                    } else {
                      if (prePay) {
                        saveSettlement()
                      } else {
                        next()
                      }
                    }
                  })
                  .catch(() => {
                    setJuheIptDisabled(false)
                    setTimerFlag(false) //关闭定时器
                    setPayCode('')
                  })
              }
            }
          }
        }}
        cancelPayMoney={() => {
          setLoading(false)
          dispatch(
            JuhePayCancle({
              payId: juhePayId,
            })
          )
            .then(unwrapResult)
            .then((res) => {
              notification.success({
                message:
                  '撤销成功，请告知患者取消支付，如需再次支付，请重新扫码。',
              })
              setJuheIptDisabled(false)
              setTimerFlag(false) //关闭定时器
              setJuheText('支付失败或撤销支付，请重新扫码进行支付')
              setPayCode('')
              setCancelPayBtn(false)
            })
        }}
        searchPayState={() => {
          if (!timerFlag) {
            juheFn()
          }
        }}
        onCancel={() => {
          setJuheIptShow(false)
          setLoading(false)
        }}
        onOk={() => {
          setJuheIptShow(false)
        }}
      />
      <ChargeSettingModal
        membersStatus={props.membersStatus}
        visible={isChargeSettingModalVisible}
        onCancel={() => {
          setIsChargeSettingModalVisible(false)
          getSFFSList()
        }}
        onOk={() => {
          setIsChargeSettingModalVisible(false)
          getSFFSList()
          setSystemPayMethod(
            selectZuHeOrNoZuHeList?.map((v: any, index: any) => {
              return {
                payAmount: index == 0 ? Number(amount)?.toFixed(2) : '0.00',
                payMethod: v?.method,
              }
            })
          )
        }}
      />
      <TopUpModal // 0.充值 or 1.退款
        visible={topUpModalVisible}
        btnType={addBtnType}
        membersId={membersInfo?.id}
        modalText={0}
        onCancel={() => setTopUpModalVisible(false)}
        onOk={(data) => {
          dispatch(getMembersTopUp(data))
            .then(unwrapResult)
            .then(() => {
              getIsMembers(Number(membersInfo.memberPhone))
              notification.success({ message: '充值成功' })
            })
            .finally(() => setAddBtnType(!addBtnType))
        }}
      />
      <PrintTemplateModal
        patientId={Number(treatment?.patientId)}
        visible={!!printTemplateCategory}
        templateCategory={printTemplateCategory}
        treatmentId={treatment?.id}
        onCancel={() => setPrintTemplateCategory(undefined)}
        onOk={() => setPrintTemplateCategory(undefined)}
      />
    </div>
  )
}

export const PaymentConfirm = forwardRef(RealPaymentConfirm)
