/*
 * @Descripttion: 
 * @version: 
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2024-10-21 11:09:13
 * @LastEditors: linxi
 * @LastEditTime: 2024-10-21 11:53:24
 */
import { TableColumnType } from 'antd'
import moment from 'moment'
import React, { ReactElement } from 'react'
import { DateTimeFormat, DateTimeFormatSimpleOnly } from '../../../../models/datetime'

export const Column = (): TableColumnType<any>[] => {
  return [
    {
      title: '名称 ',
      key: 'no',
      align: 'center',
      ellipsis: true,
      fixed: 'left',
      render: (_, t) => t?.rateName ? t?.rateName :'-'
    },
    {
      title: '占比',
      dataIndex: 'headCount',
      align: 'center',
      ellipsis: true,
      render: (_, r) => {
        return r?.rate ? (Number(r?.rate) * 100 )?.toFixed(2) + '%' : '0%'
      },
    },
   
  ]
}