import React, {
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react'
import {
    Button,
    Col,
    Collapse,
    Divider,
    Popover,
    Select,
    Input,
    Row,
    Spin,
    Form,
    Radio,
    Modal,
    notification, AutoComplete, Space
} from "antd";
import {Label} from "../../compnents/widgets/Label";
import {
    CaretDownFilled,
    CaretRightFilled, PlusOutlined,
    SearchOutlined
} from "@ant-design/icons";
import {ThemeContext} from '../../theme/ThemeContext'
import Handle_EventShowContextMenu from "./ContextMenuSetting.js"

import DCWriterControl, {
    EditorRendering,
    ImportComponent,
    setWriterControl_OnLoad, StringToUtf8Base64,
    toolsEventHandling
} from "./DCWriterControl";
import {useDispatch} from "react-redux";
import {RootDispatch} from "../../app/store";
import {unwrapResult} from "@reduxjs/toolkit";
import {saveBeautyTemplateClassify} from "./MedicalBeautyTemplateSlice";
import {getBeaytyClinicDetail, getBeaytyClinicList} from "../treatment/beautyClinic/beautyClinicSlice";
import {DelectOutlined, EditsOutlined} from "../../compnents/icons/Icons";
import styles from "./MedicalBeautyTemplateEditor.module.css";
import {Option} from "antd/es/mentions";
import _ from "lodash";
import {getTempalteDetailList} from "../template/templateSlice";
import {count} from "echarts/types/src/component/dataZoom/history";

const moduleClassify = [
    {
        id: 1,
        title: '机构基础信息'
    }, {
        id: 2,
        title: '患者基础信息'
    }, {
        id: 3,
        title: '就诊信息'
    }
]

// "ContentReadonly": null,
//     "UserEditable": false,
//     "ToolTip": "请输入年龄",
//     "BackgroundText": "年龄",
//     "Text": "30",
//     "InnerValue": "30",
//     "ValidateStyle": "Required:True;ValueType:Numeric;CheckMaxValue:True;MaxValue:150;CheckMinValue:True;MinValue:0",
//     "EnableValueValidate": true,
//     "Name": "年龄",
//     "ID": "txtAge",
//     "Alignment": "Near",
//     "BackgroundTextColor": '#BFBFBF',
//     "TextColor": "red",
//     "SpecifyWidth": "0",
//     "BackgroundColor": "yellow",
//     "StartBorderText": "【",
//     "EndBorderText": "】",
//     "LabelText": "年龄：",
//     "UnitText": "岁"

//词条数据
const moduleData = [
    {
        "input": {
            "ContentReadonly": null,
            "UserEditable": true,
            "ToolTip": "请输入年龄",
            "BackgroundText": "年龄",
            "InnerValue": "",
            "ValidateStyle": "Required:True;ValueType:Numeric;CheckMaxValue:True;MaxValue:150;CheckMinValue:True;MinValue:0",
            "EnableValueValidate": true,
            "Name": "年龄",
            "ID": "txtAge",
            "Alignment": "Near",
            "BackgroundTextColor": '#BFBFBF',
            "SpecifyWidth": "0",
            "LabelText": "年龄：",
        },
        title: "年龄",
        modId: 2,
        id: 0
    }, {
        "input": {
            "ContentReadonly": null,
            "UserEditable": true,
            "ToolTip": "请输入疾病诊断",
            "BackgroundText": "诊断",
            "InnerValue": "",
            "ValidateStyle": "Required:True;ValueType:Numeric;CheckMaxValue:True;MaxValue:150;CheckMinValue:True;MinValue:0",
            "EnableValueValidate": true,
            "Name": "诊断",
            "ID": "disease",
            "Alignment": "Near",
            "BackgroundTextColor": '#BFBFBF',
            "SpecifyWidth": "0",
            "LabelText": "诊断：",
        },
        title: "疾病诊断",
        modId: 3,
        id: 1
    }, {
        "input": {
            "ContentReadonly": null,
            "UserEditable": true,
            "ToolTip": "请输入既往史",
            "BackgroundText": "既往史",
            "InnerValue": "",
            "ValidateStyle": "Required:True;ValueType:Numeric;CheckMaxValue:True;MaxValue:150;CheckMinValue:True;MinValue:0",
            "EnableValueValidate": true,
            "Name": "既往史",
            "ID": "pastMedicalHistory",
            "Alignment": "Near",
            "BackgroundTextColor": '#BFBFBF',
            "SpecifyWidth": "0",
            "LabelText": "既往史：",
        },
        title: "既往史",
        modId: 3,
        id: 2
    }, {
        "input": {
            "ContentReadonly": null,
            "UserEditable": true,
            "ToolTip": "请输入主诉",
            "BackgroundText": "主诉",
            "ValidateStyle": "Required:True;ValueType:Numeric;CheckMaxValue:True;MaxValue:150;CheckMinValue:True;MinValue:0",
            "EnableValueValidate": true,
            "Name": "主诉",
            "ID": "chiefComplaint",
            "Alignment": "Near",
            "BackgroundTextColor": '#BFBFBF',
            "SpecifyWidth": "0",
            "LabelText": "主诉：",
        },
        title: "主诉",
        modId: 3,
        id: 3
    }, {
        "input": {
            "ContentReadonly": null,
            "UserEditable": true,
            "ToolTip": "请输入现病史",
            "BackgroundText": "现病史",
            "InnerValue": "",
            "ValidateStyle": "Required:True;ValueType:Numeric;CheckMaxValue:True;MaxValue:150;CheckMinValue:True;MinValue:0",
            "EnableValueValidate": true,
            "Name": "现病史",
            "ID": "historyOfPresentIllness",
            "Alignment": "Near",
            "BackgroundTextColor": '#BFBFBF',
            "SpecifyWidth": "0",
            "LabelText": "现病史：",
        },
        title: "现病史",
        modId: 3,
        id: 4
    }, {
        "input": {
            "ContentReadonly": null,
            "UserEditable": true,
            "ToolTip": "请输入个人史",
            "BackgroundText": "个人史",
            "InnerValue": "",
            "ValidateStyle": "Required:True;ValueType:Numeric;CheckMaxValue:True;MaxValue:150;CheckMinValue:True;MinValue:0",
            "EnableValueValidate": true,
            "Name": "个人史",
            "ID": "personalHistory",
            "Alignment": "Near",
            "BackgroundTextColor": '#BFBFBF',
            "SpecifyWidth": "0",
            "LabelText": "个人史：",
        },
        title: "个人史",
        modId: 3,
        id: 5
    }, {
        "input": {
            "ContentReadonly": null,
            "UserEditable": true,
            "ToolTip": "请输入患者姓名",
            "BackgroundText": "患者姓名",
            "InnerValue": "",
            "ValidateStyle": "Required:True;ValueType:Numeric;CheckMaxValue:True;MaxValue:150;CheckMinValue:True;MinValue:0",
            "EnableValueValidate": true,
            "Name": "患者姓名",
            "ID": "patientName",
            "Alignment": "Near",
            "BackgroundTextColor": '#BFBFBF',
            "SpecifyWidth": "0",
            "LabelText": "患者姓名：",
        },
        title: "患者姓名",
        modId: 2,
        id: 6
    }, {
        "radio": {
            "Name": "patientGender", //单选框的Name属性相同
            "Type": "radio", //radio、checkbox
            "ListItems": [
                {
                    "ID": "patientGenderChk1",
                    "ToolTip": "男性",
                    "MultiLine": "true",
                    "Checked": "true",
                    "StringTag": "",
                    "VisualStyle": "RadioBox", //"Default", "CheckBox", "RadioBox", "SystemDefault", "SystemCheckBox", "SystemRadioBox"
                    "Text": "男性",
                    "Value": "1",
                    "CaptionFlowLayout": "true"
                },
                {
                    "ID": "patientGenderChk2",
                    "ToolTip": "女性",
                    "MultiLine": "false",
                    "Checked": "false",
                    "StringTag": "",
                    "VisualStyle": "RadioBox", //"Default", "CheckBox", "RadioBox", "SystemDefault", "SystemCheckBox", "SystemRadioBox"
                    "Text": "女性",
                    "Value": "2",
                    "CaptionFlowLayout": "true"
                }
            ]
        },
        title: "患者性别",
        modId: 2,
        id: 7
    }, {
        "input": {
            "ContentReadonly": null,
            "UserEditable": true,
            "ToolTip": "请输入患者手机号",
            "BackgroundText": "患者手机号",
            "InnerValue": "",
            "ValidateStyle": "Required:True;ValueType:Numeric;CheckMaxValue:True;MaxValue:150;CheckMinValue:True;MinValue:0",
            "EnableValueValidate": true,
            "Name": "患者手机号",
            "ID": "patientPhone",
            "Alignment": "Near",
            "BackgroundTextColor": '#BFBFBF',
            "SpecifyWidth": "0",
            "LabelText": "患者手机号：",
        },
        title: "患者手机号",
        modId: 2,
        id: 8
    }, {
        "input": {
            "ContentReadonly": null,
            "UserEditable": true,
            "ToolTip": "请输入机构名称",
            "BackgroundText": "机构名称",
            "InnerValue": "",
            "ValidateStyle": "Required:True;ValueType:Numeric;CheckMaxValue:True;MaxValue:150;CheckMinValue:True;MinValue:0",
            "EnableValueValidate": true,
            "Name": "机构名称",
            "ID": "tenantName",
            "Alignment": "Near",
            "BackgroundTextColor": '#BFBFBF',
            "SpecifyWidth": "0",
            "LabelText": "机构名称：",
        },
        title: "机构名称",
        modId: 1,
        id: 9
    }, {
        "input": {
            "ContentReadonly": null,
            "UserEditable": true,
            "ToolTip": "请输入机构地址",
            "BackgroundText": "机构地址",
            "InnerValue": "",
            "ValidateStyle": "Required:True;ValueType:Numeric;CheckMaxValue:True;MaxValue:150;CheckMinValue:True;MinValue:0",
            "EnableValueValidate": true,
            "Name": "机构地址",
            "ID": "tenantAddress",
            "Alignment": "Near",
            "BackgroundTextColor": '#BFBFBF',
            "SpecifyWidth": "0",
            "LabelText": "机构地址：",
        },
        title: "机构地址",
        modId: 1,
        id: 10
    }, {
        "input": {
            "ContentReadonly": null,
            "UserEditable": true,
            "ToolTip": "请输入机构联系电话",
            "BackgroundText": "机构联系电话",
            "InnerValue": "",
            "ValidateStyle": "Required:True;ValueType:Numeric;CheckMaxValue:True;MaxValue:150;CheckMinValue:True;MinValue:0",
            "EnableValueValidate": true,
            "Name": "机构联系电话",
            "ID": "tenantPhone",
            "Alignment": "Near",
            "BackgroundTextColor": '#BFBFBF',
            "SpecifyWidth": "0",
            "LabelText": "机构联系电话：",
        },
        title: "机构联系电话",
        modId: 1,
        id: 11
    }, {
        "input": {
            "ContentReadonly": null,
            "UserEditable": true,
            "ToolTip": "请输入紧急联系人",
            "BackgroundText": "紧急联系人",
            "InnerValue": "",
            "ValidateStyle": "Required:True;ValueType:Numeric;CheckMaxValue:True;MaxValue:150;CheckMinValue:True;MinValue:0",
            "EnableValueValidate": true,
            "Name": "紧急联系人",
            "ID": "emergencyContactName",
            "Alignment": "Near",
            "BackgroundTextColor": '#BFBFBF',
            "SpecifyWidth": "0",
            "LabelText": "紧急联系人：",
        },
        title: "紧急联系人",
        modId: 2,
        id: 12
    }, {
        "input": {
            "ContentReadonly": null,
            "UserEditable": true,
            "ToolTip": "请输入紧急联系方式",
            "BackgroundText": "紧急联系方式",
            "InnerValue": "",
            "ValidateStyle": "Required:True;ValueType:Numeric;CheckMaxValue:True;MaxValue:150;CheckMinValue:True;MinValue:0",
            "EnableValueValidate": true,
            "Name": "紧急联系方式",
            "ID": "emergencyContactPhone",
            "Alignment": "Near",
            "BackgroundTextColor": '#BFBFBF',
            "SpecifyWidth": "0",
            "LabelText": "紧急联系方式：",
        },
        title: "紧急联系方式",
        modId: 2,
        id: 13
    }, {
        "input": {
            "ContentReadonly": null,
            "UserEditable": true,
            "ToolTip": "请输入出生日期",
            "BackgroundText": "出生日期",
            "InnerValue": "",
            "ValidateStyle": "Required:True;ValueType:Numeric;CheckMaxValue:True;MaxValue:150;CheckMinValue:True;MinValue:0",
            "EnableValueValidate": true,
            "Name": "出生日期",
            "ID": "birthday",
            "Alignment": "Near",
            "BackgroundTextColor": '#BFBFBF',
            "SpecifyWidth": "0",
            "LabelText": "出生日期：",
        },
        title: "出生日期",
        modId: 2,
        id: 14
    }, {
        "input": {
            "ContentReadonly": null,
            "UserEditable": true,
            "ToolTip": "请输入身份证号",
            "BackgroundText": "身份证号",
            "InnerValue": "",
            "ValidateStyle": "Required:True;ValueType:Numeric;CheckMaxValue:True;MaxValue:150;CheckMinValue:True;MinValue:0",
            "EnableValueValidate": true,
            "Name": "身份证号",
            "ID": "patientIdCard",
            "Alignment": "Near",
            "BackgroundTextColor": '#BFBFBF',
            "SpecifyWidth": "0",
            "LabelText": "身份证号：",
        },
        title: "身份证号",
        modId: 2,
        id: 15
    }, {
        "input": {
            "ContentReadonly": null,
            "UserEditable": true,
            "ToolTip": "请输入工作单位",
            "BackgroundText": "工作单位",
            "InnerValue": "",
            "ValidateStyle": "Required:True;ValueType:Numeric;CheckMaxValue:True;MaxValue:150;CheckMinValue:True;MinValue:0",
            "EnableValueValidate": true,
            "Name": "工作单位",
            "ID": "patientCompanyName",
            "Alignment": "Near",
            "BackgroundTextColor": '#BFBFBF',
            "SpecifyWidth": "0",
            "LabelText": "工作单位：",
        },
        title: "工作单位",
        modId: 2,
        id: 16
    }, {
        "input": {
            "ContentReadonly": null,
            "UserEditable": true,
            "ToolTip": "请输入详细地址",
            "BackgroundText": "详细地址",
            "InnerValue": "",
            "ValidateStyle": "Required:True;ValueType:Numeric;CheckMaxValue:True;MaxValue:150;CheckMinValue:True;MinValue:0",
            "EnableValueValidate": true,
            "Name": "详细地址",
            "ID": "patientAddress",
            "Alignment": "Near",
            "BackgroundTextColor": '#BFBFBF',
            "SpecifyWidth": "0",
            "LabelText": "详细地址：",
        },
        title: "详细地址",
        modId: 2,
        id: 17
    }, {
        "input": {
            "ContentReadonly": null,
            "UserEditable": true,
            "ToolTip": "请输入身高",
            "BackgroundText": "身高",
            "InnerValue": "",
            "ValidateStyle": "Required:True;ValueType:Numeric;CheckMaxValue:True;MaxValue:150;CheckMinValue:True;MinValue:0",
            "EnableValueValidate": true,
            "Name": "身高",
            "ID": "patientHeight",
            "Alignment": "Near",
            "BackgroundTextColor": '#BFBFBF',
            "SpecifyWidth": "0",
            "LabelText": "身高：",
        },
        title: "身高",
        modId: 2,
        id: 18
    }, {
        "input": {
            "ContentReadonly": null,
            "UserEditable": true,
            "ToolTip": "请输入体重",
            "BackgroundText": "体重",
            "InnerValue": "",
            "ValidateStyle": "Required:True;ValueType:Numeric;CheckMaxValue:True;MaxValue:150;CheckMinValue:True;MinValue:0",
            "EnableValueValidate": true,
            "Name": "体重",
            "ID": "patientWeight",
            "Alignment": "Near",
            "BackgroundTextColor": '#BFBFBF',
            "SpecifyWidth": "0",
            "LabelText": "体重：",
        },
        title: "体重",
        modId: 2,
        id: 19
    }
    // }, {
    //     "input": {
    //         "ContentReadonly": null,
    //         "UserEditable": true,
    //         "ToolTip": "请输入民族",
    //         "BackgroundText": "民族",
    //         "InnerValue": "",
    //         "ValidateStyle": "Required:True;ValueType:Numeric;CheckMaxValue:True;MaxValue:150;CheckMinValue:True;MinValue:0",
    //         "EnableValueValidate": true,
    //         "Name": "民族",
    //         "ID": "patientNation",
    //         "Alignment": "Near",
    //         "BackgroundTextColor": '#BFBFBF',
    //         "SpecifyWidth": "0",
    //         "LabelText": "民族：",
    //     },
    //     title: "体重",
    //     modId: 2,
    //     id: 20
    // }
        , {
        "input": {
            "ContentReadonly": null,
            "UserEditable": true,
            "ToolTip": "请输入婚姻状况",
            "BackgroundText": "婚姻状况",
            "InnerValue": "",
            "ValidateStyle": "Required:True;ValueType:Numeric;CheckMaxValue:True;MaxValue:150;CheckMinValue:True;MinValue:0",
            "EnableValueValidate": true,
            "Name": "婚姻状况",
            "ID": "patientMaritalStatus",
            "Alignment": "Near",
            "BackgroundTextColor": '#BFBFBF',
            "SpecifyWidth": "0",
            "LabelText": "婚姻状况：",
        },
        title: "婚姻状况",
        modId: 2,
        id: 21
    }, {
        "input": {
            "ContentReadonly": null,
            "UserEditable": true,
            "ToolTip": "请输入微信号",
            "BackgroundText": "微信号",
            "InnerValue": "",
            "ValidateStyle": "Required:True;ValueType:Numeric;CheckMaxValue:True;MaxValue:150;CheckMinValue:True;MinValue:0",
            "EnableValueValidate": true,
            "Name": "微信号",
            "ID": "patientWechat",
            "Alignment": "Near",
            "BackgroundTextColor": '#BFBFBF',
            "SpecifyWidth": "0",
            "LabelText": "微信号：",
        },
        title: "微信号",
        modId: 2,
        id: 22
    }, {
        "input": {
            "ContentReadonly": null,
            "UserEditable": true,
            "ToolTip": "请输入邮箱地址",
            "BackgroundText": "邮箱地址",
            "InnerValue": "",
            "ValidateStyle": "Required:True;ValueType:Numeric;CheckMaxValue:True;MaxValue:150;CheckMinValue:True;MinValue:0",
            "EnableValueValidate": true,
            "Name": "邮箱地址",
            "ID": "patientEmailAddress",
            "Alignment": "Near",
            "BackgroundTextColor": '#BFBFBF',
            "SpecifyWidth": "0",
            "LabelText": "邮箱地址：",
        },
        title: "邮箱地址",
        modId: 2,
        id: 23
    }, {
        "input": {
            "ContentReadonly": null,
            "UserEditable": true,
            "ToolTip": "请输入科室",
            "BackgroundText": "科室",
            "InnerValue": "",
            "ValidateStyle": "Required:True;ValueType:Numeric;CheckMaxValue:True;MaxValue:150;CheckMinValue:True;MinValue:0",
            "EnableValueValidate": true,
            "Name": "科室",
            "ID": "registrationDepartmentName",
            "Alignment": "Near",
            "BackgroundTextColor": '#BFBFBF',
            "SpecifyWidth": "0",
            "LabelText": "科室：",
        },
        title: "科室",
        modId: 3,
        id: 24
    }, {
        "input": {
            "ContentReadonly": null,
            "UserEditable": true,
            "ToolTip": "请输入医生",
            "BackgroundText": "医生",
            "InnerValue": "",
            "ValidateStyle": "Required:True;ValueType:Numeric;CheckMaxValue:True;MaxValue:150;CheckMinValue:True;MinValue:0",
            "EnableValueValidate": true,
            "Name": "医生",
            "ID": "registrationDoctorName",
            "Alignment": "Near",
            "BackgroundTextColor": '#BFBFBF',
            "SpecifyWidth": "0",
            "LabelText": "医生：",
        },
        title: "医生",
        modId: 3,
        id: 25
    }, {
        "input": {
            "ContentReadonly": null,
            "UserEditable": true,
            "ToolTip": "请输入挂号时间",
            "BackgroundText": "挂号时间",
            "InnerValue": "",
            "ValidateStyle": "Required:True;ValueType:Numeric;CheckMaxValue:True;MaxValue:150;CheckMinValue:True;MinValue:0",
            "EnableValueValidate": true,
            "Name": "挂号时间",
            "ID": "registrationTime",
            "Alignment": "Near",
            "BackgroundTextColor": '#BFBFBF',
            "SpecifyWidth": "0",
            "LabelText": "挂号时间：",
        },
        title: "挂号时间",
        modId: 3,
        id: 26
    }, {
        "input": {
            "ContentReadonly": null,
            "UserEditable": true,
            "ToolTip": "请输入病历号",
            "BackgroundText": "病历号",
            "InnerValue": "",
            "ValidateStyle": "Required:True;ValueType:Numeric;CheckMaxValue:True;MaxValue:150;CheckMinValue:True;MinValue:0",
            "EnableValueValidate": true,
            "Name": "病历号",
            "ID": "outpatientNo",
            "Alignment": "Near",
            "BackgroundTextColor": '#BFBFBF',
            "SpecifyWidth": "0",
            "LabelText": "病历号：",
        },
        title: "病历号",
        modId: 3,
        id: 27
    }
]

const templateNames = [
    {
        id: 1,
        name: '个人模版',
        type: 3
    },
    {
        id: 2,
        name: '机构模版',
        type: 2
    },
    {
        id: 3,
        name: '系统模版',
        type: 1
    },
]

export interface TemplateCategory {
    id: number
    name?: string
    parentId?: number
    level: number
    templateType?: number
}

export interface BeautyTemplateDetail {
    id: number
    name: string
    parentId?: number
    level: number
    content: string
}

// 导入组件完成的回调
const WriterControlOnLoadA = (rootElement: any) => {

    console.log("执行回调")

    rootElement.style.display = null;

    //右键菜单
    rootElement.EventShowContextMenu = Handle_EventShowContextMenu

    // 拖拽
    rootElement.EventCanInsertObject = (eventSender: any, eventArgs: any) => {
        eventArgs.Result = true;
    };

    rootElement.EventInsertObject = function (eventSender: any, eventArgs: any) {
        // 这个eventArgs 类型定义在WriterControl_Event.js中的InsertObjectEventArgs类中。
        if (eventArgs?.GetDataPresent("Text") && eventArgs?.InputSource === "DragDrop") {
            let txt = eventArgs.GetData("Text");
            txt = JSON.parse(txt);
            for (const name in txt) {
                switch (name) {
                    case 'input':
                    case 'DropdownListInput':
                    case 'DateTime':
                        rootElement.DCExecuteCommand("InsertInputField", false, txt[name]);
                        break;
                    case 'radio':
                        rootElement.DCExecuteCommand("InsertCheckBoxOrRadio", false, txt[name]);
                        break;
                    case 'xml':
                        rootElement.InsertXmlString(txt[name]);
                        break;
                    case 'barcode':
                        rootElement.DCExecuteCommand("InsertBarcodeElement", false, txt[name]);
                        break;
                    case 'tdbarcode':
                        rootElement.DCExecuteCommand("InsertTDBarcodeElement", false, txt[name]);
                        break;
                    case 'pageinfo':
                        rootElement.DCExecuteCommand("InsertPageInfoElement", false, txt[name]);
                        break;
                    case 'FourValues1':
                    case 'FourValues2':
                    case 'ThreeValues':
                    case 'FourValues':
                    case 'ThreeValues2':
                    case 'StrabismusSymbol':
                    case 'FetalHeart':
                    case 'Pupil':
                    case 'PermanentTeethBitmap':
                    case 'DeciduousTeech':
                    case 'PainIndex':
                    case 'DiseasedTeethTop':
                    case 'DiseasedTeethBotton':
                    case 'Fraction':
                        rootElement.DCExecuteCommand("insertmedicalexpression", false, txt[name]);
                        break;
                    case 'checkbox':
                        rootElement.DCExecuteCommand("insertcheckboxorradio", false, txt[name]);
                        break;
                    case 'insertLabel':
                        rootElement.DCExecuteCommand("InsertLabelElement", false, txt[name]);
                        break;
                    case 'horizontalLine':
                        rootElement.DCExecuteCommand("InsertHorizontalLine", false, txt[name]);
                        break;
                    case 'button1':
                        rootElement.DCExecuteCommand("InsertButton", false, txt[name]);
                        break;
                    // case 'img1':
                    //     // 插入图片
                    //     const file = document.createElement("input");
                    //     file.setAttribute("id", "dcInputFile");
                    //     file.setAttribute("type", "file");
                    //     file.setAttribute("accept", "image/png");
                    //     file.style.display = 'none';
                    //     rootElement.appendChild(file);
                    //     file.click();
                    //     //file文件选中事件
                    //     file.onchange = function () {
                    //         let imgFile;
                    //         if (this.files) {
                    //             imgFile = this.files[0];
                    //         }
                    //         if (imgFile && imgFile.type === "image/png") {
                    //             const reader = new FileReader();
                    //             reader.readAsDataURL(imgFile);
                    //             reader.onload = function () {
                    //                 const base64 = reader.result;
                    //                 const options = {
                    //                     Src: base64
                    //                 };
                    //                 return rootElement.__DCWriterReference.invokeMethod("DCExecuteCommand", "InsertImage", false, options);
                    //             };
                    //         }
                    //     }
                    //     //在编辑器的window重新获取焦点时,确保点击取消或X时能正确删除file
                    //     // eslint-disable-next-line no-loop-func
                    //     window.addEventListener('focus', function () {
                    //         setTimeout(function () {
                    //             file.remove();
                    //         }, 100);
                    //     }, { once: true });
                    //     break;
                    case 'comment':
                        rootElement.DCExecuteCommand("insertcomment", false, txt[name]);
                        break;
                    case 'table':
                        rootElement.DCExecuteCommand("InsertTable", false, txt[name]);
                        break;
                    default:
                        break;
                }
            }
            eventArgs.Handled = true; // 设置Handle=true,表示事件已经处理了，不需要后续的默认处理。
        }
    }

}

export const MedicalBeautyTemplateEditor = () => {

    const [categoryForm] = Form.useForm()

    const [saveForm] = Form.useForm()

    const theme = useContext(ThemeContext)

    const [isSaveModal, setSaveModal] = useState(false)

    const refWriterControl = useRef<any>(null);

    const [selectedTemplateId, setSelectedTemplateId] = useState<any>('')

    const [categories, setCategories] = useState<Record<string,TemplateCategory[]>>(
        {}
    )

    const categoryRef = useRef<Input>(null)

    const dispatch = useDispatch<RootDispatch>()


    const [templateKeyword, setTemplateKeyword] = useState('')

    const [modules, setModuleData] = useState(moduleData)

    const [selectedCategoryId, setSelectedCategoryId] = useState<number>(-1)

    const [isCategoryModalVisible, setIsCategoryModalVisibile] = useState(false)

    const [selectLevel, setSelectLevel] = useState(0)

    const [templates, setTemplates] = useState<Record<string, BeautyTemplateDetail[]>>(
        {}
    )

    const [loading, setLoading] = useState(false)

    ImportComponent()

    const layOut = {
        labelCol: {span: 6},
        wrapperCol: {span: 18},
    }

    //词条拖拽事件
    const handleDragStart = (event: any) => {

        const options = JSON.stringify(moduleData.filter(item => item.id == event.target.getAttribute('data-markid'))[0]);
        event.dataTransfer.setData("text/plain", options);
    };

    const reload = (templateType:number) => {
        dispatch(
            getBeaytyClinicList({
                category: 1,
                version: '2',
                templateType: templateType,
            })
        ).then(unwrapResult).then((cs) => {
            setCategories({
                ...categories,
                [templateType]:cs,
            })
        }).catch(() => {
            return
        })
    }

    const load = async (parentid: number,templateType:number): Promise<number> => {
        return dispatch(getBeaytyClinicList({
            category: 1,
            version: '2',
            parentId: parentid,
            templateType: templateType,
        }))
            .then(unwrapResult)
            .then(async (details) => {
                setTemplates({
                    ...templates,
                    [parentid]: details
                })
                return details.length
            })
            .finally(() => {
                setLoading(false)
            })
        return Promise.resolve(0)
    }

    const reflesh = (parentid: number,templateType:number) : number => {
        dispatch(getBeaytyClinicList({
            category: 1,
            version: '2',
            parentId: parentid,
            templateType: templateType,
        }))
            .then(unwrapResult)
            .then(async (details) => {
                setTemplates({
                    ...templates,
                    [parentid]: details
                })
                return details.length
            })
            .finally(() => {
                setLoading(false)
            })
        return 0
    }

    //组件回调
    const updateParentState = (event: string) => {
        switch (event) {
            case 'Save' : {

                let name: string | undefined = '';
                if (selectedCategoryId && selectedCategoryId > 0 && selectedTemplateId !== '') {
                    name = templates[selectedCategoryId].find(i => i.id === selectedTemplateId)?.name
                }
                saveForm.setFieldsValue({name:name,parentId: selectLevel > 0 && selectedCategoryId > 0 ? selectLevel + '-' +selectedCategoryId : null})

                if (selectLevel === 1) {
                    saveForm.setFieldsValue({name:name,parentId: null})
                }
                setSaveModal(true)
                break
            }
            default :
                break
        }
    };

    function groupByTemplateType(templates: TemplateCategory[]): Record<string, TemplateCategory[]> {
        return templates.reduce((acc: any, currentTemplate) => {
            const { templateType } = currentTemplate;

            if (templateType !== undefined) {
                acc[templateType.toString()] = [...(acc[templateType.toString()] || []), currentTemplate];
            } else {
                acc['default'] = [...(acc['default'] || []), currentTemplate];
            }

            return acc;
        }, {}) as Record<string, TemplateCategory[]>;
    }

    useEffect(() => {

        //初次初始化
        if (categories[99]) {
            setCategories(groupByTemplateType(categories[99]))
        }
    }, [categories]);

    useEffect(() => {

        //初次初始化 通过99获取所有模版分类
        reload(99)
        //设置回调函数
        setWriterControl_OnLoad(WriterControlOnLoadA)
    }, []);

    return (
        <>
            <Row style={{paddingTop: 10, height: 'calc(100%)'}}>
                <Col
                    style={{
                        width: 260,
                        height: '100%',
                        marginRight: 10,
                        backgroundColor: theme.pn,
                        borderRadius: theme.br,
                        display: 'flex',
                        flexDirection: 'column',
                        overflow: 'auto',
                    }}
                >
                    <Label label='诊断模板' style={{marginLeft: 20}}/>
                    <Divider style={{margin: 0, borderTopColor: theme.c3}}/>
                    <Input
                        value={templateKeyword}
                        prefix={<SearchOutlined style={{color: theme.tc3}}/>}
                        placeholder='请输入内容检索'
                        onChange={(e) => setTemplateKeyword((e.target.value || '').trim())}
                        style={{
                            width: 'calc(100% - 40px)',
                            marginTop: 20,
                            marginLeft: 20,
                            marginRight: 20,
                        }}
                        allowClear
                    />
                    <Spin spinning={loading}>
                        <Collapse
                            className={styles.templates}
                            bordered={false}
                            expandIcon={(v) =>
                                v.isActive ? (
                                    <CaretDownFilled style={{lineHeight: '26px'}}/>
                                ) : (
                                    <CaretRightFilled style={{lineHeight: '26px'}}/>
                                )
                            }
                            style={{
                                marginTop: 20,
                                marginLeft: 20,
                                marginRight: 20,
                                flex: 1,
                                overflow: 'auto',
                                backgroundColor: 'transparent',
                            }}
                            onChange={(types) => {
                                _.forEach(types, (type) => {
                                    if (categories[type]) {
                                        return
                                    }
                                    reload(Number(type))
                                })
                            }}
                        >
                            {templateNames.map((t: any, index: number) => (
                                <Collapse.Panel
                                    key={t.type}
                                    header={
                                        <Row
                                            align='middle'
                                            justify='space-between'
                                            wrap={false}
                                            style={{
                                                color: undefined,
                                                fontWeight: 'normal',
                                                width: '100%',
                                            }}
                                        >
                                        <span
                                            style={{
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                marginRight: 10,
                                            }}
                                        >
                                          {t.name}
                                        </span>
                                        </Row>
                                    }
                                >
                                    <Collapse ghost={true} className={styles.secondLevel}
                                              expandIcon={(v) =>
                                                  v.isActive ? (
                                                      <CaretDownFilled style={{lineHeight: '26px'}}/>
                                                  ) : (
                                                      <CaretRightFilled style={{lineHeight: '26px'}}/>
                                                  )
                                              }
                                              onChange={(ids) => {
                                                  _.forEach(ids, (id) => {
                                                      if (templates[id]) {
                                                          return
                                                      }
                                                      reflesh(Number(id),Number(t.type))
                                                  })
                                              }}
                                    >
                                        {categories[t.type] && categories[t.type].map((c) => (
                                            <Collapse.Panel key={c.id}
                                                 header={
                                                     <Row
                                                         align='middle'
                                                         justify='space-between'
                                                         wrap={false}
                                                         style={{
                                                             color: undefined,
                                                             fontWeight: 'normal',
                                                             width: '100%',
                                                         }}
                                                     >
                                                        <span
                                                            style={{
                                                                overflow: 'hidden',
                                                                textOverflow: 'ellipsis',
                                                                whiteSpace: 'nowrap',
                                                                marginRight: 10,
                                                            }}
                                                        >
                                                          {c.name}
                                                        </span>
                                                          { t.type != 1 &&  //系统模版不显示
                                                              <Col>
                                                                  <Space>
                                                                      <PlusOutlined
                                                                          style={{
                                                                              fontSize: 16,
                                                                              color: '#027AFF',
                                                                              width: '16px',
                                                                              height: '16px',
                                                                              paddingBottom: 4,
                                                                          }}
                                                                          onClick={(e) => {
                                                                              e.stopPropagation()
                                                                              setSelectLevel(t.type)
                                                                              setSelectedCategoryId(c.id)
                                                                              setSelectedTemplateId('')
                                                                              saveForm.setFieldsValue({name:'',parentId:t.type + '-' + c.id})
                                                                              setSaveModal(true)
                                                                              toolsEventHandling(refWriterControl.current, 'Clear', null)
                                                                          }}
                                                                      />
                                                                      <EditsOutlined
                                                                          style={{
                                                                              fill: theme.c1,
                                                                              width: '16px',
                                                                              height: '16px',
                                                                          }}
                                                                          onClick={(e) => {
                                                                              categoryForm.setFieldsValue({name:c.name,useScope:t.type})
                                                                              setSelectLevel(t.type)
                                                                              setSelectedCategoryId(c.id)
                                                                              setIsCategoryModalVisibile(true)
                                                                          }}
                                                                      />
                                                                      <DelectOutlined
                                                                          style={{
                                                                              fill: theme.e,
                                                                              width: '16px',
                                                                              height: '16px',
                                                                          }}
                                                                          onClick={async (e) => {
                                                                              setSelectLevel(t.type)
                                                                              e.stopPropagation()
                                                                              let count = templates[c.id]?.length || 0
                                                                              if (templates[c.id] === undefined) {
                                                                                  count = await load(c.id,t.type)
                                                                              }
                                                                              if (count > 0) {
                                                                                  Modal.confirm({
                                                                                      content: (
                                                                                          <div>
                                                                                              <p>请先删除分类内模版</p>
                                                                                          </div>
                                                                                      )
                                                                                  })
                                                                                  return;
                                                                              }

                                                                              Modal.confirm({
                                                                                  title: '提示',
                                                                                  content: (
                                                                                      <div>
                                                                                          <p>确定删除「{c.name}」分类？</p>
                                                                                      </div>
                                                                                  ),
                                                                                  onOk: async () => {
                                                                                      setSelectedCategoryId(-1)
                                                                                      dispatch(
                                                                                          saveBeautyTemplateClassify({
                                                                                              id: c.id,
                                                                                              version: '2',
                                                                                              level: 1,
                                                                                              category: 1,
                                                                                              isDeleted: 1,
                                                                                          })
                                                                                      )
                                                                                          .then(unwrapResult)
                                                                                          .then((res) => {
                                                                                              reload(t.type)
                                                                                              return
                                                                                          })
                                                                                  },
                                                                              })
                                                                              return
                                                                          }}
                                                                      />
                                                                  </Space>
                                                              </Col>
                                                          }
                                                     </Row>
                                                 }
                                            >
                                                {templates[c.id] &&
                                                    templates[c.id]
                                                        ?.filter(
                                                            (d) =>
                                                                !templateKeyword ||
                                                                d.name.indexOf(templateKeyword) >= 0
                                                        )
                                                        ?.map((d, i) => (
                                                            <Row
                                                                key={d.id}
                                                                onClick={() => {
                                                                    if (selectedTemplateId === d.id) {
                                                                        // setSelectedTemplateId("")
                                                                    } else {
                                                                        setSelectedCategoryId(c.id)
                                                                        setSelectedTemplateId(d.id)
                                                                    }
                                                                    setSelectLevel(Number(t.type))
                                                                    dispatch(
                                                                        getBeaytyClinicDetail({
                                                                            id: d.id,
                                                                            registrationId: 0,
                                                                            version: '2'
                                                                        })
                                                                    )
                                                                        .then(unwrapResult)
                                                                        .then((res: string) => {
                                                                            EditorRendering(refWriterControl.current, res,false);
                                                                        })
                                                                }}
                                                                style={{
                                                                    color:
                                                                        d.id === selectedTemplateId ? theme.c1 : theme.tc2,
                                                                    fontSize: '1.125rem',
                                                                    marginTop: 10,
                                                                    cursor: 'pointer',
                                                                    marginLeft: -12
                                                                }}
                                                                justify='space-between'
                                                                align='middle'
                                                            >
                                                                <Col>
                                                                    {i + 1}. {d.name}
                                                                </Col>
                                                                {t.type != 1 &&
                                                                    <Col>
                                                                        <Space>
                                                                            <EditsOutlined
                                                                                style={{
                                                                                    fill: theme.c1,
                                                                                    width: '16px',
                                                                                    height: '16px',
                                                                                }}
                                                                                onClick={(e) => {
                                                                                    e.stopPropagation()
                                                                                    setSelectedCategoryId(c.id)
                                                                                    setSelectedTemplateId(d.id)
                                                                                    setSelectLevel(t.type)
                                                                                    setSaveModal(true)
                                                                                    saveForm.setFieldsValue({name:d.name,parentId:t.type + '-' + c.id})
                                                                                }}
                                                                            />
                                                                            <DelectOutlined
                                                                                style={{
                                                                                    fill: theme.e,
                                                                                    width: '16px',
                                                                                    height: '16px',
                                                                                }}
                                                                                onClick={(e) => {
                                                                                    setSelectedCategoryId(c.id)
                                                                                    Modal.confirm({
                                                                                        title: `确定删除「${d.name}」`,
                                                                                        onOk: async () => {
                                                                                            if (selectedTemplateId === d.id) {///
                                                                                                setSelectedTemplateId('')
                                                                                                toolsEventHandling(refWriterControl.current, 'Clear', null)
                                                                                            }
                                                                                            dispatch(
                                                                                                saveBeautyTemplateClassify({
                                                                                                    id: d.id,
                                                                                                    version: '2',
                                                                                                    level: 2,
                                                                                                    category: 1,
                                                                                                    isDeleted: 1,
                                                                                                })
                                                                                            )
                                                                                                .then(unwrapResult)
                                                                                                .then((res) => {
                                                                                                    reflesh(c.id,t.type)
                                                                                                    return
                                                                                                })
                                                                                        },
                                                                                    })
                                                                                }}
                                                                            />
                                                                        </Space>
                                                                    </Col>
                                                                }
                                                            </Row>
                                                ))}
                                            </Collapse.Panel>
                                        ))}
                                    </Collapse>
                                </Collapse.Panel>
                            ))}
                        </Collapse>
                    </Spin>
                    <Button
                        type='primary'
                        onClick={() => {
                            setSelectedCategoryId(-1)
                            categoryForm.setFieldsValue({name: '',useScope: 3})
                            setIsCategoryModalVisibile(true)
                        }}
                        style={{
                            width: '6.5rem',
                            marginRight: 20,
                            marginTop: 20,
                            marginBottom: 20,
                            alignSelf: 'flex-end',
                        }}
                    >
                        添加分类
                    </Button>
                </Col>
                <Modal
                    title={`${selectedCategoryId > 0 ? '修改' : '添加'}模版分类`}
                    visible={isCategoryModalVisible}
                    onCancel={() => setIsCategoryModalVisibile(false)}
                    onOk={async () => {
                        try {
                            const values = await categoryForm.validateFields()
                            await dispatch(
                                saveBeautyTemplateClassify({
                                    ...values,
                                    id: selectedCategoryId,
                                    level: 1,
                                    version: '2',
                                    category: 1,
                                    templateType: values.useScope
                                })
                            )
                            categoryForm.resetFields()
                            reload(values.useScope)

                            setTemplateKeyword('')
                            setIsCategoryModalVisibile(false)
                        } catch {
                            // do nothing.
                        }
                    }}
                >
                    <Form form={categoryForm} autoComplete='off' {...layOut}>
                        <Form.Item
                            name='name'
                            label='分类名称'
                            rules={[
                                {
                                    required: true,
                                    message: '请输入分类名称',
                                },
                                {
                                    pattern: /^[^\s]*$/,
                                    message: '模版分类不能存在空格',
                                },
                            ]}
                        >
                            <Input ref={categoryRef} autoFocus/>
                        </Form.Item>
                        <Form.Item
                            name='useScope'
                            label='使用范围'
                        >
                            <Radio.Group>
                                <Radio value={3} defaultChecked>个人</Radio>
                                <Radio value={2}>机构</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Form>
                </Modal>
                <Col
                    flex='1'
                    style={{
                        height: '100%',
                        borderRadius: theme.br,
                        backgroundColor: theme.pn,
                        display: 'flex',
                        flexDirection: 'column',
                        overflow: 'hidden',
                        marginRight: 10,
                    }}
                >
                    <Divider style={{ margin: 0, borderTopColor: theme.th }} />
                    <DCWriterControl onParentStateUpdate={updateParentState} ref={refWriterControl} editorStyle={{width:"100%",marginTop:"1px",height:"calc(-220px + 95vh)"}} page={'Editor'}/>
                    <Row
                        justify='end'
                        style={{marginTop:'10px',marginRight:'10px'}}
                    >
                        <Space>
                            <Button
                                type='primary'
                                onClick={() => {
                                    updateParentState('Save')
                                }}
                            >
                                保存
                            </Button>
                        </Space>
                    </Row>
                </Col>
                <Modal
                    title="模版存为"
                    visible={isSaveModal}
                    onCancel={() => setSaveModal(false)}
                    onOk={() => {
                        saveForm.validateFields()
                            .then((values: any) => {
                                const xmlStr = StringToUtf8Base64(refWriterControl.current.XMLTextForSave)

                                const split = values?.parentId.split('-')

                                dispatch(
                                    saveBeautyTemplateClassify({
                                        name: values?.name,
                                        id: selectLevel === 1 ? null : selectedTemplateId,
                                        parentId: split[1],
                                        templateType: split[0],
                                        version: '2',
                                        level: 2,
                                        category: 1,
                                        content: xmlStr,
                                    })
                                )
                                    .then(unwrapResult)
                                    .then((res) => {
                                        reflesh(split[1],split[0])
                                        if (selectLevel !== 1 && selectedCategoryId !== split[1]) {
                                            setTimeout(() => reflesh(selectedCategoryId,selectLevel),1000)
                                        }
                                        return
                                    })
                                toolsEventHandling(refWriterControl.current, 'LineHeight', null)
                                //setSelectedTemplateId('')
                                saveForm.resetFields()
                                setSaveModal(false)
                                notification.success({
                                    message: '保存成功',
                                })
                            })

                            .catch(() => {
                                // do nothing.
                            })
                    }}
                >
                    <Form
                        colon={false}
                        autoComplete="off"
                        labelCol={{flex: "5rem"}}
                        wrapperCol={{flex: 1}}
                        form={saveForm}
                    >
                        <Form.Item
                            name='parentId'
                            label='模版分类'
                            rules={[{required: true, message: "请选择模版分类"}]}
                        >
                            <Select>
                                {categories[2] && categories[2].map((c,index) => (
                                    <Option value={'2-' + c.id}  key={String(c.id)}>{'机构模版 > ' + c.name}</Option>
                                ))}
                                {categories[3] && categories[3].map((c,index) => (
                                    <Option value={'3-' + c.id}  key={String(c.id)}>{'个人模版 > ' + c.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="模版名称"
                            name="name"
                            required
                            rules={[{required: true, message: "请输入模版名称"}]}
                        >
                            <Input maxLength={20}/>
                        </Form.Item>
                    </Form>
                </Modal>
                <Col
                    style={{
                        width: 180,
                        height: '100%',
                        marginRight: 10,
                        backgroundColor: theme.pn,
                        borderRadius: theme.br,
                        display: 'flex',
                        flexDirection: 'column',
                        overflow: 'auto',
                    }}
                >
                    {/*<Label label='文书模块' style={{marginLeft: 20}}/>*/}
                    <Divider style={{margin: 0, borderTopColor: theme.c3}}/>
                    <Input
                        prefix={<SearchOutlined style={{color: theme.tc3}}/>}
                        placeholder='请输入内容检索'
                        onChange={(e) => {
                            const keyword = e.target.value.trim();
                            if (keyword === '') {
                                setModuleData(moduleData)
                            } else {
                                setModuleData(moduleData.filter(i => i.title.indexOf(keyword) >= 0))
                            }
                        }}
                        style={{
                            width: 'calc(100% - 40px)',
                            marginTop: 20,
                            marginLeft: 20,
                            marginRight: 20,
                        }}
                        allowClear
                    />
                    <Collapse ghost={true} style={{marginTop: "10px"}} className={styles.threeLevel}
                              expandIcon={(v) =>
                                  v.isActive ? (
                                      <CaretDownFilled style={{lineHeight: '26px'}}/>
                                  ) : (
                                      <CaretRightFilled style={{lineHeight: '26px'}}/>
                                  )
                              }
                    >
                        {moduleClassify.map(mod => (
                            <Collapse.Panel header={mod.title} key={mod.id}>
                                {modules.filter(item => item.modId === mod.id).map((data, index) => (
                                    <div draggable={true} key={index} data-markid={data.id} className={styles.dragItem}
                                         style={{marginLeft: "20px"}}
                                         onDragStart={handleDragStart}>
                                        {data.title}
                                    </div>
                                ))}
                            </Collapse.Panel>
                        ))}
                    </Collapse>
                </Col>

            </Row>
        </>
    )
}

export default MedicalBeautyTemplateEditor