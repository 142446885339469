/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-04-07 15:05:46
 * @LastEditors: linxi
 * @LastEditTime: 2024-10-17 15:59:33
 */
import { API_PREFIX } from './constant'
import { ContentType, MultiPartFormData, Request } from './request'

export interface stordetailBody {
  id: number
  name?: string
  genericName?: string
  tempName?: string
  productInfo?: string
  productDate?: string
  deadline?: string
  batchNo?: string
  unitPrice?: number
  retailPrice?: number
  count?: number
  totalPrice?: any
  tenantMaterialsCatalogId?: number
  tenantInventoryReceiptsId?: number
  coherentVideo?: string
  invoiceNb?: string
}

export function storedetailPage(tenantInventoryReceiptsId: number): Request {
  return {
    url: `${API_PREFIX}/blade-store/inventoryreceiptsitem/page`,
    method: 'GET',
    params: {
      tenantInventoryReceiptsId,
    },
  }
}

export function storedetailSave(body: stordetailBody): Request {
  return {
    url: `${API_PREFIX}/blade-store/inventoryreceiptsitem/submit`,
    method: 'POST',
    body,
  }
}

// 保存发票号
export function storedetailInvoiceNbSave(body: stordetailBody): Request {
  return {
    url: `${API_PREFIX}/blade-store/inventoryreceiptsitem/changeInvoicenb`,
    method: 'POST',
    body,
  }
}

export function storedetailRemove(id: string): Request {
  return {
    url: `${API_PREFIX}/blade-store/inventoryreceiptsitem/remove`,
    method: 'POST',
    body: {
      ids: id,
    },
  }
}

export interface ExportExcelParams {
  tenantInventoryReceiptsId?: string
}
// 领用出库导出
export function getExportLYCKExcel(
  tenantInventoryReceiptsId?: string
): Request {
  return {
    url: `${API_PREFIX}/blade-store/inventoryreceiptsitem/log/exportexcel`,
    method: 'POST',
    responseType: 'blob',
    body: { tenantInventoryReceiptsId },
  }
}
//借记出库导出
export function getExportJJCKExcel(
  tenantInventoryReceiptsId?: string
): Request {
  return {
    url: `${API_PREFIX}/blade-store/inventoryreceiptsitem/debitout/exportexcel`,
    method: 'POST',
    responseType: 'blob',
    body: { tenantInventoryReceiptsId },
  }
}
//借记还库导出
export function getExportJJHKExcel(
  tenantInventoryReceiptsId?: string
): Request {
  return {
    url: `${API_PREFIX}/blade-store/inventoryreceiptsitem/debitin/exportexcel`,
    method: 'POST',
    responseType: 'blob',
    body: { tenantInventoryReceiptsId },
  }
}
//中药清斗导出
export function getExportZYQDExcel(tenantInventoryReceiptsId?: string): Request {
  return {
    url: `${API_PREFIX}/blade-store/`,
    method: 'POST',
    responseType: 'blob',
    body: { tenantInventoryReceiptsId },
  }
}
//报损导出
export function getExportBSExcel(tenantInventoryReceiptsId?: string): Request {
  return {
    url: `${API_PREFIX}/blade-store/inventoryreceiptsitem/damaged/exportexcel`,
    method: 'POST',
    responseType: 'blob',
    body: { tenantInventoryReceiptsId },
  }
}
export interface listExportBody {
  tenantInventoryReceiptsId: number
  current?: any
  size?: string
  storehouseId?: number
  isTemplate?: number
}
export function listExport(params: listExportBody): Request {
  return {
    url: `${API_PREFIX}/blade-store/inventoryledger/checklistExportexcel`,
    method: 'get',
    responseType: 'blob',
    params,
  }
}

// 导入
export interface ImportExcelParams {
  file?: any
  tenantInventoryReceiptsId?: any
}
// 导入
export function getImportExcel(params: ImportExcelParams): Request {
  return {
    url: `${API_PREFIX}/blade-store/inventoryreceiptsitem/log/importexcel`,
    headers: {
      [ContentType]: MultiPartFormData,
    },
    method: 'POST',
    params,
  }
}

