/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-07-12 15:46:28
 * @LastEditors: linxi
 * @LastEditTime: 2024-10-21 15:32:32
 */
import {
  CaretDownFilled,
  CaretUpFilled,
  ExclamationCircleFilled,
} from '@ant-design/icons'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  Row,
  Space,
  Button,
  Col,
  Checkbox,
  Radio,
  Tag,
  notification,
  Modal,
} from 'antd'
import React, {
  forwardRef,
  ReactElement,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectUserName,
  selectTenantName,
} from '../../../../app/applicationSlice'
import { RootDispatch } from '../../../../app/store'
import {
  getDateRange,
  DateRangeType,
} from '../../../../compnents/form/DateSelect'
import { EditableList } from '../../../../compnents/list/EditableList'
import { PrintZLJHBody } from '../../../../models/curePlanListPrint'
import { ReplaceMackRecipeDetailPrint } from '../../../../models/replaceMackRecipeDetail'
import { ThemeContext } from '../../../../theme/ThemeContext'
import { SubscribeModal } from '../../../A-toothModule/toothHome/modal/subscribeModal'
import { data } from '../../../composing/modal'
import { selectAddVisibleData } from '../../../followUp/followUpPageSlice'
import { CurePlanColumns } from '../columns'
import JsBarcode from 'jsbarcode'
import {
  selectCurePlanListVisible,
  setAddCurePlanModalVisible,
  setCurePlanListVisible,
  setEditPlanId,
  setPlanCount,
} from '../recipeEditorSlice'
import {
  curePlanListAsync,
  importCurePlanAsync,
  removeCurePlanAsync,
} from '../recipeListEditorSlice'
import styles from './RecipeItemModal.module.css'
import { getPrintTemplateSettingDetail } from '../../../print/printSlice'

type toothActionType = 'appointment' | 'delete' | 'done' | 'edit'
interface CurePlanListModalProps {
  selectTab?:any
  printFlagDetail?: any
  statusType?: number
  onOk?: (v?: any) => void
  onLoad?: (c?: any) => void
  type?: 'radio' | 'check' | '0'
  treatmentData?: any
  selectKeys?: any
  treatmentId?: string
  patientId?: string
  visible?: any
  queryParam?: any
  insuranceCode?: string
  recipeId?: string
  bindId?: any
  showName?: any
  tag?: any
  noTime?: any
  sourceType?: number
  toothRegistrationId?: string
  toothAction?: (action: toothActionType, t: any) => void
}

export interface CurePlanListRefProps {
  refreshPage: () => void
}

export const CurePlanList = (
  {
    selectTab,
    printFlagDetail,
    statusType,
    onOk,
    onLoad,
    type = 'check',
    treatmentData,
    selectKeys = '1',
    treatmentId = '0',
    patientId = '0',
    visible,
    queryParam,
    insuranceCode,
    recipeId,
    bindId,
    showName = false,
    tag = false,
    noTime = false,
    sourceType = 1, // 标志来源 0 为患者管理进入
    toothRegistrationId, // 口腔专业版 的医嘱处方入口
    toothAction, // 口腔专业版患者管理入口
  }: CurePlanListModalProps,
  ref: React.Ref<CurePlanListRefProps>
): ReactElement => {
  const { Group } = type == 'check' ? Checkbox : Radio

  const dispatch = useDispatch<RootDispatch>()

  const theme = useContext(ThemeContext)

  const userName = useSelector(selectUserName)

  const tenantName = useSelector(selectTenantName)

  const curePlanListVisible = useSelector(selectCurePlanListVisible)

  const [pageData, setpageData] = useState<any>([])

  const [selectKey, setSelectKey] = useState<any>([])

  const [checkDataId, setCheckDataId] = useState<any>()

  const [head, tail] = getDateRange(DateRangeType.Today)

  const [loading, setloading] = useState(false)

  const isAddVisibel: any = useSelector(selectAddVisibleData)

  const [loadingMes, setLoadingMes] = useState('加载中...')

  const [subscribeModal, setSubscribeModal] = useState(false) // 工作站-预约弹窗

  const [tablePatientId, setTablePatientId] = useState('') // 表格 patientId

  useEffect(() => {
    const name = pageData?.filter((v: any) => {
      return v?.id == checkDataId
    })
    onOk && onOk(name)
  }, [checkDataId])

  console.log(printFlagDetail, 'printFlagDetail')

  const getPage = () => {
    // if (!treatmentData?.patientId && !patientId) return
    onLoad && onLoad(true)
    dispatch(
      curePlanListAsync({
        // treatmentId: treatmentData?.id || treatmentId,
        patientId: treatmentData?.patientId || patientId,
        parameter: queryParam?.name,
        startTime: noTime ? queryParam?.startTime || head : undefined,
        endTime: noTime ? queryParam?.endTime || tail : undefined,
        tpsId: bindId,
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        if (!res.length) {
          setLoadingMes('该患者暂无治疗计划，请先创建一个计划')
        }
        curePlanListVisible &&
          dispatch(
            setPlanCount(
              res ? res.filter((v: any) => v.tpsStatus == 0)?.length : 0
            )
          )
        onLoad && onLoad(false)
        setpageData(
          res.map((v: any) => ({
            ...v,
            open: false,
          }))
        )
        JsBarcode('#barcode', res?.[0]?.patientId)
      })
  }

  useEffect(() => {
    if (curePlanListVisible && selectKeys == '1' || (selectTab == '治疗计划')) {
      getPage()
    } else {
      close()
    }
  }, [curePlanListVisible, selectKeys,selectTab])

  useEffect(() => {
    if (toothAction) {
      getPage()
    }
  }, [patientId])

  useEffect(() => {
    if (
      isAddVisibel &&
      selectKeys == '1' &&
      queryParam &&
      !curePlanListVisible
    ) {
      getPage()
    }
  }, [isAddVisibel, queryParam, selectKeys])

  useEffect(() => {
    if (!isAddVisibel && !curePlanListVisible) {
      close()
    }
  }, [isAddVisibel])

  const close = () => {
    dispatch(setCurePlanListVisible(false))
    setSelectKey([])
    setloading(false)
    setCheckDataId(undefined)
  }

  useImperativeHandle(ref, () => ({
    refreshPage: getPage,
  }))

  return (
    <div>
      <img id='barcode' style={{ display: 'none' }}></img>
      {!!sourceType && !toothRegistrationId && statusType != 1 && (
        <Row justify='end' style={{ marginBottom: 10 }}>
          {!isAddVisibel && (
            <Space>
              <Button
                type='primary'
                danger
                disabled={selectKey.length == 0}
                onClick={() => {
                  // if (!selectKey.length) {
                  //   notification.error({
                  //     message: '请选择需要删除的计划',
                  //   })
                  //   return
                  // }
                  dispatch(removeCurePlanAsync(selectKey))
                    .then(unwrapResult)
                    .then(() => {
                      notification.success({
                        message: '删除成功',
                      })
                      setSelectKey([])
                      getPage()
                    })
                }}
              >
                删除计划
              </Button>
              <Button
                type='primary'
                onClick={() => {
                  dispatch(setAddCurePlanModalVisible(true))
                  dispatch(setCurePlanListVisible(false))
                }}
              >
                创建新计划
              </Button>
            </Space>
          )}
        </Row>
      )}
      <Group
        style={{
          fontSize: 14,
        }}
        className={visible ? styles.followUpContainer : styles.container}
        onChange={(v: any) => {
          if (type == 'check') {
            setSelectKey(v)
          } else if (type == 'radio') {
            setCheckDataId(v?.target.value)
          }
        }}
        value={type == 'check' ? selectKey : checkDataId}
      >
        {pageData.length ? (
          <>
            {pageData.map((t: any, i: number) => {
              // const rs: any = recipes[t.id];
              return (
                <Col
                  key={t.id}
                  onClick={() => {
                    if (type == 'check') {
                      return
                    } else if (type == 'radio') {
                      setCheckDataId(t.id)
                    }
                  }}
                >
                  <Row
                    style={{
                      height: '3rem',
                      border: `solid 1px ${theme.wbd}`,
                      color: theme.tc1,
                      fontSize: '1rem',
                      padding: '0 10px',
                      whiteSpace: 'nowrap',
                      marginTop: i ? 10 : 0,
                    }}
                    onClick={() => {
                      setpageData(
                        pageData?.map((v: any) => {
                          if (v.id == t.id) {
                            return {
                              ...v,
                              open: !v.open,
                            }
                          } else {
                            return v
                          }
                        })
                      )
                    }}
                    align='middle'
                    wrap={false}
                    justify='space-between'
                  >
                    <div
                      onClick={(e) => {
                        e.stopPropagation()
                      }}
                    >
                      {type != '0' ? (
                        type == 'check' ? (
                          <Checkbox value={t.id} />
                        ) : (
                          <Radio value={t.id} />
                        )
                      ) : (
                        ''
                      )}
                    </div>
                    {showName != true ? (
                      <>
                        <Col style={{ marginLeft: 10 }}>
                          计划时间：{t.tpsTime}
                        </Col>
                        <Col style={{ marginLeft: 10 }}>
                          计划名称：{t.tpsName}
                        </Col>
                        <Col style={{ marginLeft: 10 }}>
                          医生：{t?.performDoctorName || '-'}
                        </Col>
                        <Col
                          style={{
                            marginLeft: 10,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            color: theme.c1,
                            fontWeight: 'bold',
                          }}
                        >
                          {t.disease}
                        </Col>
                        <Col flex={1}>备注：{t.comment || '-'}</Col>
                      </>
                    ) : (
                      <>
                        <Col>{t.tpsTime}</Col>
                        <Col style={{ marginLeft: 10 }}>{t.patientName}</Col>
                        <Col style={{ marginLeft: 10 }}>
                          {t?.performDoctorName || '-'}
                        </Col>
                        <Col style={{ marginLeft: 10 }}>
                          （{t.departmentName || '-'}）
                        </Col>
                        <Col
                          style={{
                            marginLeft: 10,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            color: theme.c1,
                            fontWeight: 'bold',
                          }}
                          flex={1}
                        >
                          {t.disease}
                        </Col>
                      </>
                    )}
                    <Col style={{ marginLeft: 10, cursor: 'pointer' }}>
                      {toothAction && (
                        <Space onClick={(e) => e.stopPropagation()}>
                          {t.tpsStatus == 0 ? (
                            <>
                              <Button
                                type='link'
                                size='small'
                                onClick={() => {
                                  dispatch(setEditPlanId(t.id))
                                  dispatch(setAddCurePlanModalVisible(true))
                                }}
                              >
                                编辑
                              </Button>
                            </>
                          ) : (
                            <Button
                              type='link'
                              size='small'
                              onClick={() => {
                                setTablePatientId(t.patientId)
                                setSubscribeModal(true)
                              }}
                            >
                              预约
                            </Button>
                          )}
                          <Button
                            type='link'
                            size='small'
                            onClick={() => {
                              Modal.confirm({
                                centered: true,
                                title: '确认操作',
                                content: (
                                  <div>
                                    <ExclamationCircleFilled
                                      style={{ color: '#FFAE55', fontSize: 18 }}
                                    />
                                    &nbsp;是否确认删除该治疗计划？
                                  </div>
                                ),
                                okText: '确认',
                                cancelText: '取消',
                                icon: null,
                                onOk: async () => {
                                  await dispatch(removeCurePlanAsync([t.id]))
                                  getPage()
                                },
                              })
                            }}
                          >
                            删除
                          </Button>
                        </Space>
                      )}
                      <a
                        style={{ marginRight: '10px', fontSize: '14px' }}
                        onClick={() => {
                          if (t.treatmentTpsDetail?.length) {
                            const page: any = window.open('', '_blank') // 打开一个新窗口，用于打印
                            const dataMainList = {
                              userName: userName,
                              tenantName: tenantName?.split('-')?.[0],
                              message: {
                                tpsName: t?.tpsName,
                                performDoctorName: t?.performDoctorName,
                                comment: t?.comment,
                                tpsTime: t?.tpsTime,
                                patientName: t?.patientName,
                              },
                              dataList: t.treatmentTpsDetail,
                              amount: t.amount > 0 ? t.amount : 0,
                              codeImg: document
                                .getElementById('barcode')
                                ?.getAttribute('src'),
                              showFlag: {
                                blhFlag: printFlagDetail?.functionList?.filter(
                                  (v: any) => v?.type == 32
                                )?.[0]?.value,
                              },
                            }
                            page?.document?.write(PrintZLJHBody(dataMainList)) // 写入打印页面的内容
                            setTimeout(function () {
                              page?.print() // 打印
                              page?.close()
                              document
                                .getElementById('barcode')
                                ?.setAttribute('src', '')
                            }, 200)
                          } else {
                            notification.info({
                              message: '暂无可打印治疗计划',
                            })
                          }
                        }}
                      >
                        打印
                      </a>
                      {showName != true && (
                        <>
                          {t.tpsStatus == 0 ? (
                            <Tag color='#FFA500'>未完成</Tag>
                          ) : (
                            <Tag color='#0195B7'>已完成</Tag>
                          )}
                        </>
                      )}
                      {tag != true &&
                        (t?.open ? (
                          <CaretUpFilled style={{ color: '#999999' }} />
                        ) : (
                          <CaretDownFilled style={{ color: '#999999' }} />
                        ))}
                    </Col>
                  </Row>
                  {(t?.open || tag == true) && (
                    <Row>
                      <Col
                        style={{
                          alignItems: 'stretch',
                          borderLeft: `solid 1px ${theme.wbd}`,
                          borderRight: `solid 1px ${theme.wbd}`,
                          borderBottom: `solid 1px ${theme.wbd}`,
                          paddingTop: 20,
                          paddingBottom: 10,
                        }}
                        flex={1}
                      >
                        <EditableList
                          className={styles.curePlanModalTabel}
                          style={{ maxHeight: 300 }}
                          dataSource={t.treatmentTpsDetail}
                          columns={CurePlanColumns(
                            (m, values: any, isSaveTooth) => {
                              return
                            },
                            true,
                            false
                          )}
                          pagination={false}
                          size='small'
                          bordered
                          rowKey={(m) => m.id}
                          sticky
                        />
                        <Row
                          justify='end'
                          style={{
                            backgroundColor: '#F5F5F5',
                            padding: '5px 20px',
                          }}
                        >
                          总金额：￥{t.amount > 0 ? t.amount : 0}
                        </Row>
                      </Col>
                    </Row>
                  )}
                </Col>
              )
            })}
          </>
        ) : (
          <div className={styles.tips}>{loadingMes}</div>
        )}
      </Group>
      {!isAddVisibel && !!sourceType && (
        <Row justify='end' style={{ marginTop: 10 }}>
          <Space>
            <Button
              type='primary'
              loading={loading}
              disabled={selectKey.length == 0}
              onClick={() => {
                setloading(true)
                dispatch(
                  importCurePlanAsync({
                    insuranceCode,
                    recipeId,
                    treatmentId,
                    tpsId: selectKey,
                  })
                )
                  .then(unwrapResult)
                  .then(() => {
                    close()
                    notification.success({
                      message: '导入成功',
                    })
                    onOk && onOk()
                  })
                  .finally(() => {
                    setloading(false)
                  })
              }}
            >
              导入计划
            </Button>
            <Button
              onClick={() => {
                close()
              }}
            >
              返回
            </Button>
          </Space>
        </Row>
      )}

      {/* 工作站-预约 */}
      <SubscribeModal
        visible={subscribeModal}
        patientId={tablePatientId} //患者 ID
        treatmentDoctorId={''} //就诊医生 ID
        onOk={() => {
          getPage()
          setSubscribeModal(false)
        }}
        onCancel={() => setSubscribeModal(false)}
      />
    </div>
  )
}

export const CurePlanListModal = forwardRef(CurePlanList)
