import {
  Button,
  Cascader,
  Checkbox,
  Col,
  Form,
  Input,
  Popover,
  Row,
  Select,
  Space,
  TreeSelect,
  TreeSelectProps,
} from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { SearchOutlined, CaretDownFilled } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import { pageSupplier, selectSupplierdata } from '../wms/supplierSlice'
import { commodityPageParams } from '../../services/commodity'
import { getOtcCategoryName, OtcCategories } from '../../models/Otccategory'
import { setCurrent } from './commoditySlice'
import styles from './Commodity.module.css'
import {
  getMaterialsCategoryName,
  getMaterialsName,
  Materiales,
  MaterialsCategories,
} from '../../models/commodity'
import {
  selectInsuranceArray,
  selectTenantName,
} from '../../app/applicationSlice'
import { DefaultOptionType } from 'antd/lib/select'
import {
  getCodeCompletions,
  getCommonCodeCompletionsAsync,
} from '../completion/completionSlice'
import { CommonCodeCategory } from '../../models/commoncode'
import { RootDispatch } from '../../app/store'
import { unwrapResult } from '@reduxjs/toolkit'
import { FilterIconOutlined } from '../../compnents/icons/Icons'
import { ThemeContext } from '../../theme/ThemeContext'

const { Option } = Select

const { TreeNode } = TreeSelect

export const Commodityquery = (props: {
  onValueChange: (queries: commodityPageParams) => void
}) => {
  const [form] = Form.useForm()

  const [popoverForm] = Form.useForm()

  const [open, setOpen] = useState(false)

  const theme = useContext(ThemeContext)

  const dispatch = useDispatch<RootDispatch>()

  const tenantName = useSelector(selectTenantName)

  const data = useSelector(selectSupplierdata)

  const insuranceArray = useSelector(selectInsuranceArray)

  const [treeData, setTreeData] = useState<any>([])

  const getYPFL = () => {
    const data = Materiales.map((v) => {
      return {
        id: v,
        value: v.toString(),
        label: getMaterialsName(v),
        children: [],
      }
    })

    dispatch(
      getCommonCodeCompletionsAsync({
        category: CommonCodeCategory.Durg,
        keyword: '',
      })
    )
      .then(unwrapResult)
      .then((res) => {
        const tree = data.map((v) => {
          if (v.id == 0) {
            return {
              ...v,
              children: res.records.map((el: any) => ({
                value: el.codeName,
                label: el.codeName,
              })),
            }
          } else if (v.id == 1) {
            return {
              ...v,
              children: [
                { value: '材料', label: '材料' },
                { value: '器械', label: '器械' },
                { value: '消耗品', label: '消耗品' },
                { value: '其他', label: '其他' },
              ],
            }
          } else if (v.id == 2) {
            return {
              ...v,
              children: [
                { value: '第一类医疗器械', label: '第一类医疗器械' },
                { value: '第二类医疗器械', label: '第二类医疗器械' },
                { value: '第三类医疗器械', label: '第三类医疗器械' },
              ],
            }
          } else {
            return v
          }
        })

        setTreeData(tree)
      })
  }

  useEffect(() => {
    if (insuranceArray.length) {
      form.setFieldsValue({
        insuranceCode: insuranceArray[0].insuranceCode,
      })
    }
  }, [insuranceArray])

  const dataParams = JSON.parse(sessionStorage.getItem('/commodity') || '{}')

  useEffect(() => {
    getYPFL()
     dispatch(pageSupplier({ supplierCategory: 1,size:20 }))
    if (Object.keys(dataParams).length) {
      form.setFieldsValue({
        ...dataParams,
        materialsCategory: dataParams?.materialsCategoryList,
      })
    }
    form.submit()
  }, [])

  const refresh = () => {
    const category = form.getFieldsValue()?.materialsCategory
    const data = {
      ...dataParams,
      ...form.getFieldsValue(),
      materialsCategoryList: category,
      materialsCategory: Array.isArray(category) ? category?.[0] : undefined,
      category: Array.isArray(category) ? category?.[1] : undefined,
      current: 1,
      ...popoverForm.getFieldsValue(),
      chrgitmLvs: popoverForm.getFieldsValue()?.chrgitmLvs
        ? popoverForm.getFieldsValue()?.chrgitmLvs?.join(',')
        : '',
      anaesthesiaTag: popoverForm.getFieldsValue()?.anaesthesiaTag
        ? Number(popoverForm.getFieldsValue()?.anaesthesiaTag)
        : null,
      antibioticTag: popoverForm.getFieldsValue()?.antibioticTag
        ? Number(popoverForm.getFieldsValue()?.antibioticTag)
        : null,
      basicMedicineTag: popoverForm.getFieldsValue()?.basicMedicineTag
        ? Number(popoverForm.getFieldsValue()?.basicMedicineTag)
        : null,
      natNegoDrugFlag: popoverForm.getFieldsValue()?.natNegoDrugFlag
        ? Number(popoverForm.getFieldsValue()?.natNegoDrugFlag)
        : null,
    }
    props.onValueChange(data)
  }

  return (
    <Form
      form={form}
      name='control-hooks'
      autoComplete='off'
      onFinish={(values) => {
        props.onValueChange({
          ...dataParams,
          ...values,
          current: dataParams.current || 1,
        })
      }}
      initialValues={{
        state: 1,
      }}
    >
      <Row gutter={11} style={{ height: '42px' }}>
        <Col span={3}>
          <Form.Item name='allStr'>
            <Input
              size='middle'
              placeholder='搜索关键词'
              allowClear
              prefix={<SearchOutlined />}
              onChange={refresh}
            />
          </Form.Item>
        </Col>
        {/* <Col span={3}>
          <Form.Item name='approveFlg'>
            <Select
              placeholder='医保状态'
              allowClear
              dropdownClassName={styles.select}
              suffixIcon={<CaretDownFilled />}
              onChange={refresh}
            >
              <Option value='0'>未上传</Option>
              <Option value='-1'>审批未通过 </Option>
              <Option value='1'>审批已通过</Option>
              <Option value='2'>已上传待审批</Option>
            </Select>
          </Form.Item>
        </Col> */}
        <Col span={2}>
          <Form.Item name='materialsCategory'>
            <Cascader
              options={treeData}
              onChange={(v: any) => {
                refresh()
              }}
              changeOnSelect
              placeholder='商品类别'
            />
            {/* <TreeSelect
              placeholder="商品类别"
              allowClear
              onChange={refresh}
            >
              {
                treeData.length && treeData.map((v: any, index: number) => (
                  <TreeNode value={v.id} title={v.title} key={v.id}>
                    {
                      !!v.children.length && v.children.map((el: any) => (
                        <TreeNode value={el.id} title={el.title} key={el.id} />
                      ))
                    }
                  </TreeNode>))
              }
            </TreeSelect> */}
          </Form.Item>
        </Col>

        <Col span={2}>
          <Form.Item name='allMaterials'>
            <Select
              placeholder='药品范围'
              allowClear
              dropdownClassName={styles.select}
              // suffixIcon={<CaretDownFilled />}
              onChange={refresh}
            >
              <Option value='0'>兴奋剂</Option>
              <Option value='1'>麻黄碱</Option>
              <Option value='2'>抗生素</Option>
              <Option value='3'>基药</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item name='tenantSupplierId'>
            <Select
              placeholder='生产厂家'
              allowClear
              showSearch
              onChange={refresh}
              optionFilterProp='children'
              // suffixIcon={<CaretDownFilled />}
              dropdownClassName={styles.select}
              onSearch={(v:any) => {
                dispatch(pageSupplier({ name: v, supplierCategory: 1 ,size:20}))
              }}
            >
              {data.map((v: any, i) => {
                return (
                  <Option value={v.id} key={v.id}>
                    {v.companyName}
                  </Option>
                )
              })}
            </Select>
          </Form.Item>
        </Col>

        <Col span={2}>
          <Form.Item name='state'>
            <Select
              placeholder='状态'
              // defaultValue={1}
              onChange={refresh}
              // suffixIcon={<CaretDownFilled />}
            >
              <Option value={0}>注销</Option>
              <Option value={1}>启用</Option>
            </Select>
          </Form.Item>
        </Col>

        <Col span={2}>
          <Form.Item name='otc'>
            <Select
              placeholder='OTC'
              allowClear
              onChange={refresh}
              // suffixIcon={<CaretDownFilled />}
            >
              {OtcCategories.map((v) => {
                return (
                  <Option value={v} key={v}>
                    {getOtcCategoryName(v)}
                  </Option>
                )
              })}
            </Select>
          </Form.Item>
        </Col>
        {insuranceArray.length !== 0 && (
          <>
            <Col span={2}>
              <Form.Item name='insuranceCode'>
                <Select
                  placeholder='医保险种'
                  // suffixIcon={<CaretDownFilled />}
                  // value={insuranceArray[0]}
                  onChange={refresh}
                >
                  {insuranceArray.map((v) => {
                    return (
                      <Option value={v.insuranceCode} key={v.id}>
                        {v.insuranceName}
                      </Option>
                    )
                  })}
                </Select>
              </Form.Item>
            </Col>
            {tenantName?.indexOf('余姚健齿口腔诊所有限公司') < 0 &&
              tenantName?.indexOf('余姚康民内科诊所') < 0 && (
                <Col span={2}>
                  <Form.Item name='isMapping'>
                    <Select
                      placeholder='匹配状态'
                      allowClear
                      onChange={refresh}
                      // style={{ width: '100px' }}
                      // suffixIcon={<CaretDownFilled />}
                    >
                      <Option value='0'>未匹配</Option>
                      <Option value='1'>未上传</Option>
                      <Option value='2'>已上传</Option>
                    </Select>
                  </Form.Item>
                </Col>
              )}
            <Col flex={1}>
              <Popover
                visible={open}
                overlayClassName={styles.formQueryPopover}
                content={
                  <>
                    <Form
                      form={popoverForm}
                      onFinish={(val) => {
                        refresh()
                        setOpen(false)
                      }}
                    >
                      <Col span={24} style={{ marginLeft: '24px' }}>
                        <Form.Item label='等级' name='chrgitmLvs'>
                          <Checkbox.Group>
                            <Checkbox value={'01'}>甲</Checkbox>
                            <Checkbox value={'02'}>乙</Checkbox>
                            <Checkbox value={'03'}>丙</Checkbox>
                          </Checkbox.Group>
                        </Form.Item>
                      </Col>
                      <Row>
                        <Col style={{ margin: '4px 0 0 0 ' }}>药品范围：</Col>
                        <Col span={5}>
                          <Form.Item
                            label=''
                            name='natNegoDrugFlag'
                            valuePropName='checked'
                          >
                            <Checkbox>国谈药</Checkbox>
                          </Form.Item>
                        </Col>
                        <Col span={5}>
                          <Form.Item
                            label=''
                            name='antibioticTag'
                            valuePropName='checked'
                          >
                            <Checkbox>抗生素</Checkbox>
                          </Form.Item>
                        </Col>
                        <Col span={5}>
                          <Form.Item
                            label=''
                            name='basicMedicineTag'
                            valuePropName='checked'
                          >
                            <Checkbox>基药</Checkbox>
                          </Form.Item>
                        </Col>
                        <Col span={5}>
                          <Form.Item
                            label=''
                            name='anaesthesiaTag'
                            valuePropName='checked'
                          >
                            <Checkbox>毒麻类</Checkbox>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row
                        style={{
                          height: '1px',
                          background: '#f0f0f0',
                          marginTop: '10px',
                        }}
                      ></Row>
                      <Space
                        style={{
                          display: 'flex',
                          justifyContent: 'end',
                          marginTop: '10px',
                        }}
                      >
                        <Button
                          size='middle'
                          type={'ghost'}
                          onClick={() => {
                            setOpen(false)
                          }}
                        >
                          取消
                        </Button>
                        <Button size='middle' type='primary' htmlType='submit'>
                          确定
                        </Button>
                      </Space>
                    </Form>
                  </>
                }
                title=''
                trigger='click'
                placement='bottom'
              >
                <Form.Item name='' initialValue={'筛选'}>
                  <Button
                    style={{
                      color: theme.tc2,
                      width: '78px',
                      textAlign: 'left',
                      // overflow: 'hidden',
                      // textOverflow: 'ellipsis',
                    }}
                    icon={
                      <FilterIconOutlined
                        style={{
                          width: '14px',
                          height: '14px',
                          position: 'relative',
                          top: 2,
                          left: -2,
                          color: '#666',
                        }}
                      />
                    }
                    onClick={() => {
                      setOpen(true)
                    }}
                  >
                    筛选
                  </Button>
                </Form.Item>
              </Popover>
            </Col>
          </>
        )}

        <Col offset={insuranceArray.length !== 0 ? 3 : 6} span={1}>
          <Button onClick={refresh} type='primary' style={{ float: 'right' }}>
            查询
          </Button>
        </Col>
      </Row>
    </Form>
  )
}
