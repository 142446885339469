/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-04-20 14:39:51
 * @LastEditors: linxi
 * @LastEditTime: 2024-10-31 10:03:31
 */
import {
  Button,
  Col,
  notification,
  Row,
  Space,
  Image,
  Popconfirm as Reconfirm,
  Modal,
} from 'antd'
import React, { useState, useEffect } from 'react'
import styles from '../Putstorelist.module.css'
import { Category, CategoryName } from '../../../models/category'
import moment from 'moment'
import { DateTimeFormatSimple } from '../../../models/datetime'
import { useHistory } from 'react-router-dom'
import { PurchaseModal } from '../purchaseModal'
import FileUpload from '../../../compnents/file/FileUpload'
import { RcFile, UploadFile } from 'antd/lib/upload/interface'
import { CloseOutlined, PlusOutlined } from '@ant-design/icons'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  deleteUploadFileList,
  getUrl,
} from '../../treatment/diagnosis/diagnosisSlice'
import { useDispatch } from 'react-redux'
import { RootDispatch } from '../../../app/store'
import { setDetail } from '../../putstorage/putstorageSlice'
import { UploadModal } from './uploadModal'
export const PutlistQuery = (props: {
  category: Category
  Detail: any
  refresh: () => void
  // calbackImg: (imgArr: any) => void
  fileSave: (num: number, imgArr: any) => void
  exportExcel: () => void
}) => {
  const dispatch = useDispatch<RootDispatch>()

  const history = useHistory()

  const [isPurchaseModalVisible, setIsPurchaseModalVisible] = useState(false)

  const [fileList, setFileList] = useState<UploadFile[]>([])

  const [imgUrl, setImgUrl] = useState<any[]>([])

  const [btnLoading, setBtnLoading] = useState(false)

  const [visible, setVisible] = useState(false)

  const [recVisible, setRecVisible] = useState(null)

  const [imgSrc, setImgSrc] = useState('') //预览图片路径

  const [isUploadVisible, setIsUploadVisible] = useState(false) //上传附件弹窗

  const getImgUrl = (obj: any) => {
    dispatch(getUrl({ fileName: obj.url })) //获取图片路径
      .then(unwrapResult)
      .then((v: any) => {
        const proxy =
          window.location.origin.indexOf('his.develop.hydhis.com') >= 0
            ? '/proxyToOssDevPrivate'
            : window.location.origin.indexOf('his.test.hydhis.com') >= 0
            ? '/proxyToOssTestPrivate'
            : window.location.origin.indexOf('hydhis.cn') >= 0
            ? '/proxyToOssProdPrivate'
            : '/proxyToOssProdPrivate'
            const url = (window.location.origin?.includes('localhost')
            ? 'http://his.develop.hydhis.com'
            : window.location.origin) + proxy + v
        setImgUrl((el) => {
          return [
            ...el,
            {
              src: url,
              ...obj,
            },
          ]
        })
      })
  }

  useEffect(() => {
    return () => {
      dispatch(setDetail({}))
    }
  }, [])
  // useEffect(() => {
  //   props.fileSave(1, [...fileList])   //传给父组件用来保存
  // }, [fileList])

  useEffect(() => {
    setImgUrl([])
    setFileList([])
    if (props.Detail?.fileList?.length) {
      setFileList(props.Detail?.fileList) //附件回显时限制上传附件数量
      props.fileSave(2, [...props.Detail?.fileList]) //防止修改单据时清空上传附件
      props.Detail?.fileList.forEach((item: any) => {
        item.url && getImgUrl(item)
      })
    }
  }, [props.Detail])

  return (
    <>
      <Row
        className={styles.titletext}
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            fontSize: '18px',
            fontWeight: 'bold',
            paddingLeft: '10px',
            width: '100%',
          }}
        >
          <div style={{ display: 'flex' }}>
            <span
              style={{
                width: '2px',
                height: '16px',
                background: ' #0052D9',
                position: 'relative',
                top: '6px',
                marginRight: '10px',
              }}
            ></span>
            {CategoryName(props.category)}业务单据
          </div>
          <div className={styles.btnWrap}>
            <Space
              style={{
                display: 'flex',
                justifyContent: 'end',
                marginTop: '-30px',
              }}
            >
              {imgUrl.length != 0 &&
                imgUrl.map((item) => {
                  return (
                    <>
                      <Reconfirm
                        overlayClassName={styles.box}
                        visible={recVisible == item.uid}
                        title={
                          <>
                            <div
                              className={styles.iconWrap}
                              onClick={() => setRecVisible(null)}
                            >
                              <CloseOutlined />
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginTop: 20,
                              }}
                            >
                              <Button
                                type='primary'
                                onClick={() => {
                                  setImgSrc(item.src)
                                  setVisible(true)
                                  setRecVisible(null)
                                }}
                              >
                                预览
                              </Button>
                              <Button
                                type='primary'
                                style={{ marginLeft: 10 }}
                                disabled={props.Detail.storehouseTag !== 0}
                                onClick={() => {
                                  props.fileSave(
                                    1,
                                    imgUrl.filter(
                                      (val: any) => val.uid != item.uid
                                    )
                                  ) //传给父组件用来保存
                                  setRecVisible(null)
                                }}
                              >
                                删除
                              </Button>
                            </div>
                          </>
                        }
                        icon=''
                      >
                        <img
                          style={{ width: 30, height: 30, cursor: 'pointer' }}
                          src={`${item.src}`}
                          onClick={() => setRecVisible(item.uid)}
                        />
                      </Reconfirm>
                    </>
                  )
                })}
              <FileUpload
                count={fileList.length || 0}
                maxCount={3}
                files={fileList}
                disabled={btnLoading}
                btn={(bol) => setBtnLoading(bol)}
                beforeUpload={(file) => {
                  setBtnLoading(true)
                  if (
                    file.type.includes('png') ||
                    file.type.includes('tiff') ||
                    file.type.includes('jpg') ||
                    file.type.includes('jpeg') ||
                    file.type.includes('bmp') ||
                    file.type.includes('heic') ||
                    file.type.includes('webp')
                  ) {
                    if (file.size / 1024 / 1024 < 5) {
                      return true
                    } else {
                      notification.info({ message: '图片大于5M,请重新选择!' })
                      setBtnLoading(false)
                      return false
                    }
                  }
                  notification.info({ message: '请上传图片' })
                  setBtnLoading(false)
                  return false
                }}
                onUpload={(v, i) => {
                  setFileList([
                    ...fileList,
                    {
                      uid: v.uid,
                      size: v.size,
                      name: v.name,
                      type: v.type,
                      originFileObj: {} as RcFile,
                      url: i,
                    },
                  ])
                  dispatch(getUrl({ fileName: i })) //获取图片路径
                    .then(unwrapResult)
                    .then((res: any) => {
                      const proxy =
                        window.location.origin.indexOf(
                          'his.develop.hydhis.com'
                        ) >= 0
                          ? '/proxyToOssDevPrivate'
                          : window.location.origin.indexOf(
                              'his.test.hydhis.com'
                            ) >= 0
                          ? '/proxyToOssTestPrivate'
                          : window.location.origin.indexOf('hydhis.cn') >= 0
                          ? '/proxyToOssProdPrivate'
                          : '/proxyToOssProdPrivate'
                      const url = (window.location.origin?.includes(
                        'localhost'
                      )
                        ? 'http://his.develop.hydhis.com'
                        : window.location.origin) + proxy + res
                      setImgUrl([
                        ...imgUrl,
                        { src: url, name: v.name, uid: v.uid, url: i },
                      ])
                      notification.success({ message: '上传成功' })
                      props.fileSave(1, [
                        ...fileList,
                        {
                          uid: v.uid,
                          size: v.size,
                          name: v.name,
                          type: v.type,
                          originFileObj: {} as RcFile,
                          url: i,
                        },
                      ]) //传给父组件用来保存
                    })
                    .finally(() => setBtnLoading(false))
                }}
              >
                {/* <Button loading={imgUrl.length >= 3 ? false : btnLoading} disabled={props.Detail.storehouseTag !== 0} icon={<PlusOutlined />}>上传附件</Button> */}
              </FileUpload>
              <Button
                icon={<PlusOutlined />}
                onClick={() => {
                  setIsUploadVisible(true)
                }}
                disabled={props.Detail.storehouseTag !== 0}
              >
                上传附件
              </Button>
              {props?.category == '1' || props?.category == '2' ? (
                <Button
                  type='primary'
                  onClick={() => {
                    props?.exportExcel()
                  }}
                >
                  导出
                </Button>
              ) : (
                ''
              )}
              {props?.category == '2' ? (
                <></>
              ) : (
                <Button
                  type='primary'
                  disabled={props.Detail.storehouseTag !== 0}
                  onClick={() => {
                    setIsPurchaseModalVisible(true)
                  }}
                >
                  导入库存
                </Button>
              )}
              <Button
                type='primary'
                disabled={props.Detail.storehouseTag !== 0}
                onClick={() => {
                  switch (props.category) {
                    case '1':
                    case '2':
                      history.push({
                        pathname: '/updatabus',
                        state: {
                          category: props.category,
                          detail: props.Detail,
                        },
                      })
                      break
                    case '3':
                      history.push({
                        pathname: '/transferOut',
                        state: {
                          category: props.category,
                          detail: props.Detail,
                        },
                      })
                      break
                    default:
                      break
                  }
                }}
              >
                修改业务单据
              </Button>
            </Space>
          </div>
        </div>
      </Row>
      <Row
        style={{
          marginTop: '10px',
          lineHeight: '34px',
          paddingLeft: '20px',
          marginBottom: props?.category == '1' ? '6px' : '12px',
          fontSize: '18px',
        }}
      >
        <Col span={6}>库房名称：{props.Detail.storehouseName}</Col>
        <Col span={6}>业务部门：{props.Detail.departmentName}</Col>
        <Col span={6}>业务人员：{props.Detail.userName}</Col>
        <Col span={6}>供应商：{props.Detail.supplierName}</Col>
        {Number(props.category) > 1 ? (
          // <Col span={6}>业务单号：{props.Detail.recepitsNumber}</Col>
          ''
        ) : (
          <Col span={6}>采购单号：{props.Detail.recepitsNumber}</Col>
        )}
        <Col span={6}>
          业务日期：
          {moment(props.Detail.recepitsDate).format(DateTimeFormatSimple)}
        </Col>
        {/* 显示入库单号、来源库房、来源科室、来源操作人员、对应出库单号 */}
        <Col span={24}></Col>
      </Row>
      <PurchaseModal
        refresh={props?.refresh}
        id={props?.Detail?.id}
        isPurchaseModalVisible={isPurchaseModalVisible}
        onOk={() => {
          setIsPurchaseModalVisible(false)
        }}
        onCancel={() => {
          setIsPurchaseModalVisible(false)
        }}
      />
      {/* <Image
           style={{ display: 'none' }}
         width={30}
         height={30}
         src={`${item}`}
           preview={{
             visible: false,
             onVisibleChange: value => {
               setVisible(value);
             },
           }}
       /> */}
      <Image
        style={{ display: 'none' }}
        src={imgSrc}
        preview={{
          visible,

          onVisibleChange: (value) => {
            setVisible(value)
          },
        }}
      />

      <UploadModal
        uploadModalVisible={isUploadVisible}
        accept={'.png,.tiff,.jpg,.jpeg,bmp,.heic,.webp'}
        id={props?.Detail?.id}
        uploadType={1}
        onCancel={() => {
          setIsUploadVisible(false)
        }}
        maxCount={3}
        onSubmit={(v, i, id) => {
          if (imgUrl.length + v.length > 3) {
            notification.info({
              message: '已达到最大上传数量，请先删除再上传！',
            })
            return
          }
          dispatch(deleteUploadFileList({ ids: id })).then(unwrapResult)
          setFileList([...fileList, ...v])
          setImgUrl([...imgUrl, ...i])
          props.fileSave(1, [...fileList, ...v]) //传给父组件用来保存
          setIsUploadVisible(false)
        }}
      />
    </>
  )
}
