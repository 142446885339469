/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-11-01 12:00:34
 * @LastEditors: linxi
 * @LastEditTime: 2024-11-06 14:54:21
 */

import { ExclamationCircleOutlined } from '@ant-design/icons'
import { unwrapResult } from '@reduxjs/toolkit'
import { Button, Col, Form, Input, Modal, notification, Row } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { RootDispatch } from '../../../app/store'
import styles from './PaymentConfirm.module.css'
import { getJHZFEditXJSubmitAsync } from './paymentConfirmSlice'

interface JuheIptShowModalProps {
  paymentId?: any
  ysghfFlag?: any
  juheText?: any
  visible: boolean
  JHZFMoney?: any
  onCancel: () => void
  onOk: () => void
  JHZFInputChange: (v?: any) => void
  cancelPayMoney: () => void
  searchPayState: () => void
  JuheIptDisabled?: any
  cancelPayBtn?: any
  loading?: any
  payCode?: any
}
export const JuheIptShowModal = ({
  paymentId,
  ysghfFlag,
  juheText,
  visible,
  JHZFMoney,
  onCancel,
  onOk,
  JHZFInputChange,
  cancelPayMoney,
  searchPayState,
  JuheIptDisabled,
  cancelPayBtn,
  // loading,
  payCode,
}: JuheIptShowModalProps): ReactElement => {
  const [form] = Form.useForm()

  const inputRef = useRef<any>(null)

  const dispatch = useDispatch<RootDispatch>()

  const cancelPay = ()=>{
    if (ysghfFlag) {
      Modal.confirm({
        icon: <ExclamationCircleOutlined />,
        content: '取消聚合收款：系统将认定为现金支付，请确认。',
        okText: '确认',
        cancelText: '取消',
        onOk: () => {
          // 聚合支付改为现金
          dispatch(getJHZFEditXJSubmitAsync({ paymentId: paymentId }))
            .then(unwrapResult)
            .then((v: any) => {
              notification.success({
                message: '取消成功!',
                duration: 3,
              })
              onCancel()
            })
        },
      })
    } else {
      onCancel()
    }
  }

  return (
    <Modal
      title='聚合支付结算'
      width={600}
      visible={visible}
      maskClosable={false}
      footer={null}
      onCancel={() => {
        cancelPay()
      }}
      onOk={() => {
        onOk()
      }}
      destroyOnClose
      maskStyle={{
        overflow: 'hidden',
      }}
    >
      <Form
        name='basic'
        form={form}
        onFinish={(values) => {
          return
        }}
      >
        <Row align='middle' style={{ marginBottom: '20px' }}>
          <Col flex='7rem' style={{ textAlign: 'right' }}>
            {'聚合支付：'}
          </Col>
          <Col style={{ position: 'relative' }}>
            <Input
              disabled
              style={{
                width: '18rem',
                marginLeft: 10,
                fontSize: '16px',
              }}
              value={JHZFMoney || 0}
            />
          </Col>
        </Row>
        <Row align='middle'>
          <Col flex='7rem' style={{ textAlign: 'right' }}>
            {'扫描收款码：'}
          </Col>
          <Col style={{ position: 'relative' }}>
            <Input
              autoFocus
              style={{
                width: '18rem',
                marginLeft: 10,
                fontSize: '16px',
                color: 'green',
              }}
              disabled={JuheIptDisabled}
              inputMode='decimal'
              maxLength={18}
              value={payCode}
              onChange={(e) => {
                const { value } = e.target
                const reg = /^[0-9]+$/
                if (reg.test(value) || value === '') {
                  JHZFInputChange && JHZFInputChange(value)
                }
              }}
            />
          </Col>
          <Col>
            {cancelPayBtn && (
              <Button
                style={{ marginLeft: '10px' }}
                onClick={() => {
                  cancelPayMoney && cancelPayMoney()
                }}
              >
                撤销收款
              </Button>
            )}
            {!cancelPayBtn && (
              <Button
                // loading={loading}
                // disabled={loading}
                type='primary'
                ghost
                style={{ marginLeft: '10px' }}
                onClick={() => {
                  searchPayState()
                }}
              >
                查询付款状态
              </Button>
            )}
          </Col>
          <Col span={24}>
            <div
              style={{
                color: 'green',
                marginLeft: '122px',
                marginTop: '16px',
                fontStyle: 'italic',
              }}
            >
              {juheText}
            </div>
          </Col>
        </Row>
        <Row
          style={{
            display: 'flex',
            width: '558px',
            justifyContent: 'flex-end',
            marginTop:'20px'
          }}
        >
          <Button
            htmlType='button'
            onClick={() => {
              cancelPay()
            }}
            style={{
              float: 'right',
            }}
          >
            取消
          </Button>
          {ysghfFlag ? (
            <></>
          ) : (
            <Button
              type='primary'
              htmlType='submit'
              style={{ marginLeft: '10px' }}
            >
              确认
            </Button>
          )}
        </Row>
      </Form>
    </Modal>
  )
}
