/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-05-06 15:25:30
 * @LastEditors: linxi
 * @LastEditTime: 2024-06-19 14:43:09
 */
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Pagination,
  Row,
  Table,
} from 'antd'
import { TableRowSelection } from 'antd/lib/table/interface'
import React, { useState, useEffect, useRef } from 'react'
import {
  PlusOutlined,
  RollbackOutlined,
  StockOutlined,
  UpSquareFilled,
} from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import { NavBar } from '../../compnents/nav/NavBar'
import { InventoryPriceParams } from '../../services/InventPrice'
import { DataType } from '../putstorage/putstorage'
import { Columns } from './columns'
import { InventoryModal } from './InventoryModal'
import {
  cancelChangeAsync,
  changePricesAsync,
  getInventoryPage,
  getInventoryPageAsync,
  ImplementInventoryAsync,
  RemoveInventoryAsync,
  selectPriceCurrent,
  selectPriceData,
  selectPriceTotal,
  setPriceCurrent,
  getInventoryPriceSliceExoprt,
  selectPageLoading,
  setPageLoading,
  selectPriceSumData,
} from './inventoryPriceSlice'
import { InventoryQuery } from './InventoryQuery'
import { RootDispatch } from '../../app/store'
import { EditableList } from '../../compnents/list/EditableList'
import styles from './InventoryPrice.module.css'
import moment from 'moment'
import { DateTimeFormat, DateTimeFormatSimple } from '../../models/datetime'
import { PrintFrame, PrintFrameRef } from '../print/PrintFrame'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  DelectOutlined,
  MoreBatchNumFilled,
  PrintOutlined,
} from '../../compnents/icons/Icons'
import { stringTrim } from '../../utils/StringUtils'

export const InventoryPrice = () => {
  const [modalBtnLoading, setModalBtnLoading] = useState(false) //  弹窗确定按钮 loading

  const [scrollHeight, setScrollHeight] = useState<any>()

  const printRef = useRef<PrintFrameRef>(null)

  const [form] = Form.useForm()

  const tableRef = useRef(null)

  const dispatch = useDispatch<RootDispatch>()

  const data = useSelector(selectPriceData)

  const sumTotalData: any = useSelector(selectPriceSumData)

  const total = useSelector(selectPriceTotal)

  const current = useSelector(selectPriceCurrent)

  const [Ids, setIds] = useState('')

  const [queries, setQueries] = useState<InventoryPriceParams>({
    executeDateStart: moment().startOf('day').format(DateTimeFormat),
    executeDateEnd: moment().endOf('day').format(DateTimeFormat),
  })

  const [size, setSize] = useState(10)

  const [isvisible, setisvisible] = useState(false)

  const [local, setlocal] = useState<any>()

  const [printData, setPrintData] = useState<any>([])

  const [printSumTotalData, setPrintSumTotalData] = useState<any>()

  const [selectionType] = useState<'checkbox' | 'radio'>('checkbox')

  const [cancelVisible, setCancelVisible] = useState(false)

  const [changeVisible, setChangeVisible] = useState(false)

  const [tJIsModalVisible, setTJIsModalVisible] = useState(false)

  const pageLoadingRX = useSelector(selectPageLoading)

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      setIds(selectedRowKeys.join(','))
    },
  }

  useEffect(() => {
    setlocal(JSON.parse(localStorage.getItem('credentials') as string))
  }, [])

  const getPage = () => {
    dispatch(setPageLoading(true))
    onResize() // 初始化设置高度
    window.addEventListener('resize', onResize)
    dispatch(
      getInventoryPage({
        ...queries,
        size,
        current,
      })
    )
    setTimeout(() => {
      setPageLoading(false)
    }, 3000)
  }

  useEffect(() => {
    getPage()
  }, [queries, size, current])

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 15 },
  }

  const outCluedExport = () => {
    dispatch(
      getInventoryPriceSliceExoprt({
        ...queries,
        size,
        current,
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        const content = res
        const blob = new Blob([content])
        const fileName = '库存调价.xlsx'
        const selfURL = window[window.webkitURL ? 'webkitURL' : 'URL']
        const elink = document.createElement('a')
        if ('download' in elink) {
          elink.download = fileName
          elink.style.display = 'none'
          elink.href = selfURL['createObjectURL'](blob)
          document.body.appendChild(elink)
          // 触发链接
          elink.click()
          selfURL.revokeObjectURL(elink.href)
          document.body.removeChild(elink)
        } else {
          navigator.msSaveBlob(blob, fileName)
        }
      })
      .then(() => {
        notification.success({
          message: '导出成功',
          duration: 3,
        })
      })
      .catch((res: any) => {
        return
      })
  }

  const onResize = () => {
    const table = document.getElementById('tableHeightInventoryPrice')
    if (table) {
      const height = table.offsetHeight - 60 - 80 
      setScrollHeight(height)
    } else {
      return '100%'
    }
  }
  return (
    <>
      <NavBar
        style={{ margin: '10px 20px 0 20px' }}
        where={['库房业务', '库存调价']}
        backtrace={{
          name: '库房工作站',
          path: '/storage/station',
          state: {
            station: '1',
          },
        }}
        buttons={
          <div
            style={{
              display: 'flex',
            }}
          >
            <Button
              type='text'
              style={{
                color: '#666',
                display: 'flex',
                alignItems: 'center',
              }}
              onClick={() => {
                dispatch(
                  getInventoryPageAsync({
                    size: 1000,
                    current: 1,
                    executeDateStart: queries.executeDateStart,
                    executeDateEnd: queries.executeDateEnd,
                  })
                )
                  .then(unwrapResult)
                  .then((res: any) => {
                    setPrintData(res.page?.records)
                    setPrintSumTotalData({
                      adjustAmountSum: res.adjustAmountSum,
                      inventorSplitCountSum:res.inventorSplitCountSum,
                      inventoryCountSum:res.inventoryCountSum,
                    })
                    printRef.current?.print()
                  })
              }}
            >
              <PrintOutlined
                style={{
                  fill: '#666',
                  width: '18px',
                  height: '18px',
                  marginRight: '4px',
                }}
              />
              打印
            </Button>
            <Button
              type='text'
              style={{ float: 'right', display: 'flex', alignItems: 'center' }}
              onClick={() => {
                if (Ids === '') {
                  notification.error({
                    message: '请选择要删除的内容',
                    duration: 3,
                  })
                } else {
                  dispatch(RemoveInventoryAsync(Ids)).then(() => {
                    getPage()
                  })
                }
              }}
            >
              <DelectOutlined
                style={{
                  // fill: "#FF5D5D",
                  width: '14px',
                  height: '14px',
                  marginRight: '4px',
                }}
              />
              删除
            </Button>
            <Button
              type='text'
              style={{ float: 'right', display: 'flex', alignItems: 'center' }}
              icon={<RollbackOutlined />}
              onClick={() => {
                if (!Ids) {
                  notification.error({
                    message: '请选择需要撤回的数据',
                  })
                  return
                }
                if (
                  data
                    .filter((v: any) => Ids.includes(v.id.toString()))
                    .some((el: any) => !el.state || el.state === 2)
                ) {
                  notification.error({
                    message: '请选择已执行的数据',
                  })
                  return
                }
                setCancelVisible(true)
              }}
            >
              撤回调价
            </Button>
            <Button
              type='text'
              style={{ float: 'right', display: 'flex', alignItems: 'center' }}
              icon={<StockOutlined />}
              onClick={() => {
                setChangeVisible(true)
              }}
            >
              中草药调价
            </Button>
            <Button
              type='text'
              style={{ display: 'flex', alignItems: 'center' }}
              onClick={() => {
                setTJIsModalVisible(true)
              }}
            >
              <UpSquareFilled
                style={{
                  color: '#666',
                  width: '18px',
                  height: '18px',
                  marginRight: '-4px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              />
              执行调价
            </Button>

            <Button
              type='primary'
              icon={<PlusOutlined />}
              onClick={() => setisvisible(true)}
              style={{ float: 'right', marginRight: '20px' }}
            >
              新增
            </Button>
          </div>
        }
      />
      <div className={styles.content}>
        <InventoryQuery
          table={tableRef}
          onValueChange={(v: any) => {
            setQueries({
              ...queries,
              ...v,
              executeDateStart: v.state === 1 ? v.timeHead : null,
              executeDateEnd: v.state === 1 ? v.timeTail : null,
              timeHead: null,
              timeTail: null,
            })
          }}
          outClued={() => {
            outCluedExport()
          }}
        />
        <div
          id='tableHeightInventoryPrice'
          style={{ width: '100%', height: '100%', }}
        >
          <EditableList
            scroll={{ y: Number(scrollHeight), x: '30rem' }}
            style={{
              width: '100%',
              overflow: 'hidden',
            }}
            className={styles.inventoryPrice}
            loading={pageLoadingRX}
            columns={Columns(size * (current - 1))}
            page={{
              current: current,
              size: size,
              total,
              items: data,
            }}
            summary={() => (
              <Table.Summary fixed>
                <Table.Summary.Row className={styles.total}>
                  <Table.Summary.Cell index={0}></Table.Summary.Cell>
                  <Table.Summary.Cell index={1}>合计</Table.Summary.Cell>
                  <Table.Summary.Cell index={2}></Table.Summary.Cell>
                  <Table.Summary.Cell index={3}></Table.Summary.Cell>
                  <Table.Summary.Cell index={4}></Table.Summary.Cell>
                  <Table.Summary.Cell index={5}></Table.Summary.Cell>
                  <Table.Summary.Cell index={6}></Table.Summary.Cell>
                  <Table.Summary.Cell index={7}></Table.Summary.Cell>
                  <Table.Summary.Cell index={8} align='center'>
                    {sumTotalData?.inventoryCountSum
                      ? parseFloat(sumTotalData?.inventoryCountSum)
                      : '0'}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={9} align='center'>
                    {sumTotalData?.inventorSplitCountSum
                      ? parseFloat(sumTotalData?.inventorSplitCountSum)
                      : '0'}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={10} align='center'>
                    {sumTotalData?.adjustAmountSum
                      ? parseFloat(sumTotalData?.adjustAmountSum).toFixed(2)
                      : '0.00'}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={11}></Table.Summary.Cell>
                  <Table.Summary.Cell index={12}></Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            )}
            onChangePage={(current, size) => {
              setSize(size as number)
              dispatch(setPriceCurrent(current))
            }}
            rowSelection={
              {
                type: selectionType,
                ...rowSelection,
              } as TableRowSelection<any>
            }
            bordered
          />
        </div>
      </div>
      <InventoryModal
        visible={isvisible}
        onOk={() => {
          getPage()
          setisvisible(false)
        }}
        onCancel={() => {
          setisvisible(false)
        }}
      />
      {/* 撤回调价 */}
      <Modal
        title='撤回调价'
        visible={cancelVisible}
        onCancel={() => {
          setCancelVisible(false)
        }}
        onOk={() => {
          dispatch(cancelChangeAsync(Ids))
            .then(unwrapResult)
            .then(() => {
              getPage()
              setIds('')
              notification.success({
                message: '撤回成功',
              })
              setCancelVisible(false)
            })
        }}
      >
        <p>确认撤回调价?</p>
      </Modal>
      {/* 中草药调价、 */}
      <Modal
        title='新增库存批量调价商品'
        visible={changeVisible}
        onCancel={() => {
          setChangeVisible(false)
          form.resetFields()
        }}
        confirmLoading={modalBtnLoading}
        onOk={() => {
          form.submit()
        }}
      >
        <Form
          form={form}
          name='InventoryPrice-hooks'
          {...layout}
          onFinish={(values) => {
            if (Number(values.note) > 1.25) {
              notification.warn({
                message: '医保局规定售价必须控制在进价的1.25倍以内',
              })
              return
            }
            const data = {
              scale: Number(values.scale),
              state: values.state ? 1 : 0,
            }
            setModalBtnLoading(true)
            dispatch(changePricesAsync(data))
              .then(unwrapResult)
              .then(() => {
                notification.success({
                  message: '批量调价成功',
                })
                setModalBtnLoading(false)
                getPage()
                form.resetFields()
                setChangeVisible(false)
              })
              .catch(() => {
                setModalBtnLoading(false)
              })
          }}
        >
          <Form.Item
            getValueFromEvent={stringTrim}
            name='scale'
            label='中草药调价比例'
            validateTrigger='onBlur'
            rules={[
              { required: true },
              {
                pattern: /^(([1-9]\d*)|(0{1}))(\.\d{0,3})?$/,
                message: '仅支持三位小数的数字',
              },
            ]}
          >
            <Input allowClear />
          </Form.Item>
          <Row>
            <Col offset={6}>
              <span className={styles.limit}>
                医保局规定售价必须控制在进价的{' '}
                <strong className={styles.limitItem}>1.25倍</strong> 以内
              </span>
            </Col>
          </Row>
          <Form.Item name='state' label='药品目录价格' valuePropName='checked'>
            <Checkbox>同时变更</Checkbox>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title='执行调价'
        visible={tJIsModalVisible}
        onCancel={() => {
          setTJIsModalVisible(false)
          form.resetFields()
        }}
        confirmLoading={modalBtnLoading}
        onOk={() => {
          form.submit()
        }}
      >
        <Form
          form={form}
          name='InventoryPrice-hooks'
          {...layout}
          onFinish={(values) => {
            if (Ids === '') {
              notification.error({
                message: '请选择要调价的内容',
                duration: 3,
              })
            } else {
              setModalBtnLoading(true)
              dispatch(
                ImplementInventoryAsync({
                  ids: Ids,
                  state: values.state ? 1 : 0,
                })
              )
                .then(() => {
                  setIds('')
                  setModalBtnLoading(false)
                  getPage()
                  form.resetFields()
                  setTJIsModalVisible(false)
                })
                .catch(() => {
                  setIds('')
                  form.resetFields()
                  setTJIsModalVisible(false)
                })
            }
          }}
        >
          <Form.Item name='state' label='药品目录价格' valuePropName='checked'>
            <Checkbox>同时变更</Checkbox>
          </Form.Item>
        </Form>
      </Modal>

      <PrintFrame ref={printRef} title={local?.tenantName} subTitle='调价单'>
        <Row style={{ marginBottom: '10px', lineHeight: '32px' }}>
          <Col span={12}>库房名称：</Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            调价时间：
            {moment(queries.executeDateStart).format(DateTimeFormatSimple)}到
            {moment().format(DateTimeFormatSimple)}
          </Col>
        </Row>
        <EditableList
          style={{ height: 'auto' }}
          className={styles.inventoryPrice}
          columns={Columns(size * (current - 1))}
          pagination={false}
          page={{
            current: current,
            size: size,
            total,
            items: printData,
          }}
          summary={() => (
            <Table.Summary fixed>
              <Table.Summary.Row className={styles.total}>
                <Table.Summary.Cell index={0}></Table.Summary.Cell>
                <Table.Summary.Cell index={1}>合计</Table.Summary.Cell>
                <Table.Summary.Cell index={2}></Table.Summary.Cell>
                <Table.Summary.Cell index={3}></Table.Summary.Cell>
                <Table.Summary.Cell index={4}></Table.Summary.Cell>
                <Table.Summary.Cell index={5}></Table.Summary.Cell>
                <Table.Summary.Cell index={6}></Table.Summary.Cell>
                <Table.Summary.Cell index={7}  align='center'> {printSumTotalData?.inventoryCountSum
                    ? parseFloat(printSumTotalData?.inventoryCountSum)
                    : '0'}</Table.Summary.Cell>
                <Table.Summary.Cell index={8} align='center'>
                {printSumTotalData?.inventorSplitCountSum
                    ? parseFloat(printSumTotalData?.inventorSplitCountSum)
                    : '0'}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={9} align='center'>
                {printSumTotalData?.adjustAmountSum
                    ? parseFloat(printSumTotalData?.adjustAmountSum).toFixed(2)
                    : '0.00'}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={10} >
                </Table.Summary.Cell>
                <Table.Summary.Cell index={11}></Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          )}
          bordered
        />
        <Row style={{ lineHeight: '32px', marginTop: '20px' }}>
          <Col span={8}>单位负责人：</Col>
          <Col span={8}>仓管员：</Col>
          <Col span={8}>财务：</Col>
          <Col span={8}>审核：</Col>
          <Col span={8}>制单人：</Col>
          <Col span={8}>制单日期：{moment().format(DateTimeFormatSimple)}</Col>
        </Row>
      </PrintFrame>
    </>
  )
}
