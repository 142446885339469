import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { api, sendAsync } from "../../app/applicationSlice"
import { RootState, RootThunk, RootThunkApi } from "../../app/store"
import {
  putstoragePage,
  putstorageDetail,
  putstorageSave,
  putstorageUpdata,
  putstorageSavelist,
  putstorageFinish,
  putlistDetail,
  putStorageparams,
  putStorePageParams,
  putstorageRect,
  PurchaseBody,
  purchaseUpload,
  purchaseRepeal,
  outboundUpload,
  checkSheetUpload,
  drugUpload,
  returnDrugUpload,
  drugBody,
  otherUpload,
  getPutstorageExoprtFn,
  putstorageCopy,
  exportDetailListExoprt,
  exportDetailOutListExoprt,
  readyToPrintPrintData,
  takeStock,
  multifunctionalUpAccountReq,
  multifunctionalUpAccount,
  drugUploadA,
  drugBodyA,
  returnDrugUploadA,
  traceableCodeList,
  traceableCodeListParams,
  traceableCodeSave,
  traceableCodeSubmitBody
} from '../../services/putstorage'
import {
  GetDepartmentListParams,
  getDepartmentList,
} from '../../services/department'
import { Category } from '../../models/category'
import {
  setDurgData,
  setlistTotal,
  setSelectId,
} from '../putstorelist/putstorelistSlice'
import moment from 'moment'
import { DateTimeFormatSimple } from '../../models/datetime'
import { UploadFile } from 'antd/lib/upload/interface'

const initialState = {
  PutstorageData: [],
  PutStorageUser: [],
  Total: 0,
  Detail: {},
  ID: 0,
  Success: false,
  department: [],
  Current: 1,
  Category: Category.GRRK,
  amountTotal: {
    totalRealPayAmount: 0,
    totalShouldPayAmount: 0,
    subTotalRetailPrice: 0,
    subTotalUnitPrice: 0,
  },
  pageLoading: false,
}

export const putstorageSlice = createSlice({
  name: 'putStorage',
  initialState,
  reducers: {
    setData(state, action) {
      state.PutstorageData = action.payload
    },
    setUser(state, action) {
      state.PutStorageUser = action.payload
    },
    setTotal(state, action) {
      state.Total = action.payload
    },
    setDetail(state, action) {
      state.Detail = action.payload
    },
    setId(state, action) {
      state.ID = action.payload
    },
    setSuccess(state, action) {
      state.Success = action.payload
    },
    setDepart(state, action) {
      state.department = action.payload
    },
    setCurrent(state, action) {
      state.Current = action.payload
    },
    setCategory(state, action) {
      state.Category = action.payload
    },
    setAmountTotalt(state, action) {
      state.amountTotal = action.payload
    },
    setPageLoading(state, action) {
      state.pageLoading = action.payload
    },
  },
})

export const {
  setData,
  setUser,
  setTotal,
  setDetail,
  setCategory,
  setId,
  setCurrent,
  setSuccess,
  setDepart,
  setAmountTotalt,
  setPageLoading,
} = putstorageSlice.actions

// 分页
export function pagePutstorage(params: putStorePageParams): RootThunk {
  return api(putstoragePage(params), (data: any, dispatch) => {
    dispatch(setData(data.records))
    dispatch(setTotal(data.total))
    const amountTotal = data.records.length
      ? {
          totalRealPayAmount: data.records[0].totalRealPayAmount,
          totalShouldPayAmount: data.records[0].totalShouldPayAmount,
          subTotalUnitPrice: data.records[0].subTotalUnitPrice,
          subTotalRetailPrice: data.records[0].subTotalRetailPrice,
        }
      : {
          totalRealPayAmount: 0,
          totalShouldPayAmount: 0,
          subTotalUnitPrice: 0,
          subTotalRetailPrice: 0,
        }
    dispatch(setAmountTotalt(amountTotal))
    dispatch(setPageLoading(false))
  })
}

export const pagePutstorageAsync = createAsyncThunk<
  void,
  putStorePageParams,
  RootThunkApi<void>
>('Putstorage/savelist', async (params, api) => {
  return sendAsync(putstoragePage(params), api, true)
})

//业务人员
export function pagePutstorageUser(params: putStorePageParams): RootThunk {
  return api(putstoragePage(params), (data: any, dispatch) => {
    dispatch(setUser(data.records))
  })
}

//部门
export function DepartmentDate(params: GetDepartmentListParams): RootThunk {
  return api(getDepartmentList(params), (data: any, dispatch) => {
    dispatch(setDepart(data.records))
  })
}

//详情
export function detailPutstorage(id: number): RootThunk {
  return api(putstorageDetail(id), (data: any, dispatch) => {
    data.inventoryReceipts.fileList = data.inventoryReceipts.coherentVideo
      ? data.inventoryReceipts.coherentVideo.split(',').map((v: any) => {
          return ({
            name: v.split('@')[0],
            uid: v.split('@')[1],
            url: v.split('@')[2],
          } as unknown) as UploadFile
        })
      : []
    dispatch(setDetail({...data.inventoryReceipts,storehouseCategory:data?.storehouseCategory}))
    dispatch(
      setDurgData(
        data.outInvReceiptsItemList.map((v: any) => {
          return {
            ...v,
            count: Number(v.count) > 0 ? v.count : undefined,
            totalPrice: Number(v.totalPrice) > 0 ? v.totalPrice : undefined,
            deadline: v.deadline
              ? moment(v.deadline).format(DateTimeFormatSimple)
              : undefined,
            productDate: v.productDate
              ? moment(v.productDate).format(DateTimeFormatSimple)
              : undefined,
          }
        })
      )
    )
    dispatch(setlistTotal(data.outInvReceiptsItemList.total))
  })
}

// 新增
export function savePutstorage(params: putStorageparams): RootThunk {
  return api(putstorageSave(params), (data: any, dispatch) => {
    dispatch(setSelectId(data))
  })
}

// 修改
export function updataPutstorage(params: putStorageparams): RootThunk {
  return (dispatch, getState, args) => {
    api(putstorageUpdata(params), (data: any, dispatch) => {
      dispatch(detailPutstorage(params.id as number)) //删除后获取详情
    })(dispatch, getState, args)
  }
}
//修改2
export const updataPutstorage2 = createAsyncThunk<
  void,
  putStorageparams,
  RootThunkApi<void>
>('Putstorage/updataPutstorage2', async (params, api) => {
  return sendAsync(putstorageUpdata(params), api, true)
})

//登账
export const savelistPutstorageAsync = createAsyncThunk<
  void,
  string,
  RootThunkApi<void>
>('Putstorage/savelist', async (id, api) => {
  return sendAsync(putstorageSavelist(id), api, true)
})

//登账
export const multifunctionalUpAccountAsync = createAsyncThunk<
  void,
  multifunctionalUpAccountReq,
  RootThunkApi<void>
>('Putstorage/savelist', async (params, api) => {
  return sendAsync(multifunctionalUpAccount(params), api, true)
})

//
export const saveTakeStockAsync = createAsyncThunk<
  void,
  multifunctionalUpAccountReq,
  RootThunkApi<void>
>('Putstorage/savelist', async (params, api) => {
  return sendAsync(takeStock(params), api, true)
})

//冲正
export const rectPutstorageAsync = createAsyncThunk<
  void,
  string,
  RootThunkApi<void>
>('Putstorage/savelist', async (id, api) => {
  return sendAsync(putstorageRect(id), api, true)
})

// //作废
// export function finishPutstorage(id: number): RootThunk {
//   return (dispatch, getState, args) => {
//     const category = getState().putstorage.Category
//     const current = getState().putstorage.Current
//     api(putstorageFinish(id), (data) => {
//       dispatch(pagePutstorage({ current, size: 10, category }))
//     })(dispatch, getState, args)
//   }
// }

//作废
export const finishPutstorage = createAsyncThunk<
  void,
  any,
  RootThunkApi<void>
>('Putstorage/savelist', async (id, api) => {
  return sendAsync(putstorageFinish(id), api, true)
})



// 医保 采购单上传
export const purchaseUploadAsync = createAsyncThunk<
  string,
  PurchaseBody,
  RootThunkApi
>('/department/purchaseUploadAsync', async (body, api) => {
  return sendAsync(purchaseUpload(body), api)
})

// 医保 出库单上传
export const outboundUploadAsync = createAsyncThunk<
  string,
  PurchaseBody,
  RootThunkApi
>('/department/outboundUploadAsync', async (body, api) => {
  return sendAsync(outboundUpload(body), api)
})

// 医保 发药单上传
export const drugUploadAsync = createAsyncThunk<string, drugBody, RootThunkApi>(
  '/department/drugUploadAsync',
  async (body, api) => {
    return sendAsync(drugUpload(body), api)
  }
)

// 医保 发药单批量上传
export const drugUploadAAsync = createAsyncThunk<string, drugBodyA, RootThunkApi>(
  '/department/drugUploadAAsync',
  async (body, api) => {
    return sendAsync(drugUploadA(body), api)
  }
)

// 医保 退药单上传
export const returnDrugUploadAsync = createAsyncThunk<
  string,
  drugBody,
  RootThunkApi
>('/department/returnDrugUploadAsync', async (body, api) => {
  return sendAsync(returnDrugUpload(body), api)
})

// 医保 退药单上传
export const returnDrugUploadAAsync = createAsyncThunk<
  string,
  drugBodyA,
  RootThunkApi
>('/department/returnDrugUploadAAsync', async (body, api) => {
  return sendAsync(returnDrugUploadA(body), api)
})

// 医保 盘点单上传
export const checkSheetUploadAsync = createAsyncThunk<
  string,
  PurchaseBody,
  RootThunkApi
>('/department/checkSheetUploadAsync', async (body, api) => {
  return sendAsync(checkSheetUpload(body), api)
})

// 医保 其他上传
export const otherUploadAsync = createAsyncThunk<
  string,
  PurchaseBody,
  RootThunkApi
>('/department/checkSheetUploadAsync', async (body, api) => {
  return sendAsync(otherUpload(body), api)
})

// 医保 采购单 , 出库单 ，盘点单、其他单据 冲正上传
export const purchaseRepealAsync = createAsyncThunk<
  string,
  PurchaseBody,
  RootThunkApi
>('/department/purchaseRepealAsync', async (body, api) => {
  return sendAsync(purchaseRepeal(body), api)
})

// 导出
export const getPutstorageExoprt = createAsyncThunk<
  void,
  putStorePageParams,
  RootThunkApi
>('doctorAudit/getPutstorageExoprt ', async (params, api) => {
  return sendAsync(getPutstorageExoprtFn(params), api)
})

// 复制单据
export const putstorageCopyAsync = createAsyncThunk<void, string, RootThunkApi>(
  'doctorAudit/getPutstorageExoprt ',
  async (params, api) => {
    return sendAsync(putstorageCopy(params), api)
  }
)

// 购入入库打印
export const getReadyToPrintPrintData = createAsyncThunk<
  void,
  putStorePageParams,
  RootThunkApi
>('doctorAudit/getReadyToPrintPrintData ', async (params, api) => {
  return sendAsync(readyToPrintPrintData(params), api)
})

// 购入入库详情导出
export const getExportDetailListExoprt = createAsyncThunk<
  void,
  any,
  RootThunkApi
>('doctorAudit/getExportDetailListExoprt ', async (params, api) => {
  return sendAsync(exportDetailListExoprt(params), api)
})

// 购入退库详情导出
export const getExportDetailOutListExoprt = createAsyncThunk<
  void,
  any,
  RootThunkApi
>('doctorAudit/getExportDetailOutListExoprt ', async (params, api) => {
  return sendAsync(exportDetailOutListExoprt(params), api)
})


// 购入入库查询追溯码
export const getTraceableCodeList = createAsyncThunk<
  void,
  traceableCodeListParams,
  RootThunkApi
>('doctorAudit/getTraceableCodeList ', async (params, api) => {
  return sendAsync(traceableCodeList(params), api)
})

// 保存购入入库追溯码
export const getTraceableCodeSave = createAsyncThunk<
  void,
  traceableCodeSubmitBody,
  RootThunkApi
>('doctorAudit/getTraceableCodeSavegetTraceableCodeSave ', async (params, api) => {
  return sendAsync(traceableCodeSave(params), api)
})


export const selectPutstorageData = (state: RootState) =>
  state.putstorage.PutstorageData

export const selectPutstorageUser = (state: RootState) =>
  state.putstorage.PutStorageUser

export const selectTotal = (state: RootState) => state.putstorage.Total

export const selectDetail = (state: RootState) => state.putstorage.Detail

export const selectAmountTotalt = (state: RootState) =>
  state.putstorage.amountTotal

export const selectId = (state: RootState) => state.putstorage.ID

export const selectDepartData = (state: RootState) =>
  state.putstorage.department

export const selectCurrent = (state: RootState) => state.putstorage.Current

export const selectCategory = (state: RootState) => state.putstorage.Category

export const selectPageLoading = (state: RootState) =>
  state.putstorage.pageLoading

export default putstorageSlice.reducer
