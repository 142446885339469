/*
 * @Descripttion: 
 * @version: 
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2024-10-18 14:14:20
 * @LastEditors: linxi
 * @LastEditTime: 2024-10-18 16:57:06
 */


import { API_PREFIX } from './constant'
import { Request } from './request'

export interface SelfRateInquiryListParams {
  timeHead?: any
  timeTail?: any
}

export function SelfRateInquiryList(params: SelfRateInquiryListParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/payment/paymentRatioInquiry`,
    method: 'GET',
    params,
  }
}
