import {
  Button,
  Col,
  Form,
  Modal,
  notification,
  Input,
  Row,
  Popover,
  Switch,
  Checkbox,
  message,
} from 'antd'
import moment from 'moment'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { EditableList } from '../../compnents/list/EditableList'
import { NavBar } from '../../compnents/nav/NavBar'
import { Category, CategoryName, StorehouseTag } from '../../models/category'
import { DeliveryItem } from '../delivery/deliverySlice'
import {
  detailPutstorage,
  getExportDetailListExoprt,
  getExportDetailOutListExoprt,
  getTraceableCodeList,
  multifunctionalUpAccountAsync,
  savelistPutstorageAsync,
  saveTakeStockAsync,
  selectDetail,
  updataPutstorage,
  updataPutstorage2,
} from '../putstorage/putstorageSlice'
import {
  BaosunColumns,
  CollectColumns,
  DebitInColumns,
  DebitOutColumns,
  InventoryCloumn,
  PurchaseDeliveryColumns,
  PurchaseStockColumns,
  TransferInColumns,
  TransferOutColumns,
} from './columns'
import { newData } from './newdata'
import styles from './Putstorelist.module.css'
import {
  Removestoredetail,
  saveStoreDetailAsync,
  selectorPutstoreData,
  addRow,
  deleteRow,
  changeRow,
  getExportLYCKExcelAsync,
  getExportJJHKExcelAsync,
  getExportBSExcelAsync,
  getExportJJCKxcelAsync,
  getExportZYQDExcelAsync,
  saveStoreDetailInvoiceNbAsync,
} from './putstorelistSlice'
import { PutlistQuery } from './Query/purchaseQuery'
import { AllocationQuery } from './Query/allocationQuery'
import { DebitOutQuery } from './Query/debitOutQuery'
import { RootDispatch } from '../../app/store'
import { unwrapResult } from '@reduxjs/toolkit'
import { traceRoute } from '../../layouts/layoutSlice'
import { stordetailBody } from '../../services/storedetail'
import TextArea from 'antd/lib/input/TextArea'
import { isSameName } from '../../utils/fieldsUtils'
import {
  DateTimeFormat,
  DateTimeFormatSimple,
  DateTimeFormatSimpleOnly,
  DateTimeFormatSimpleText,
} from '../../models/datetime'
import { UploadFile } from 'antd/lib/upload/interface'
import {
  AccountOutlined,
  PrintOutlined,
  YSOutlined,
} from '../../compnents/icons/Icons'
import { PrintFrame, PrintFrameRef } from '../print/PrintFrame'
import { Dimen } from '../../models/dimen'
import { selectTenantName, selectUserName } from '../../app/applicationSlice'
import { sum } from 'lodash'
import {
  printBody,
  printBodyBS,
  printBodyGRTK,
  printBodySeven,
} from '../../models/putstorelistPrint'
import {
  generateAcceptanceInfoAsync,
  generateAcceptancePrintInfoAsync,
} from '../PurchaseAcceptance/PurchaseAcceptanceSlice'
import { ThemeContext } from '../../theme/ThemeContext'
import { PrintBody } from '../../models/PurchaseAcceptancePrint'
import { number } from 'echarts/types/src/echarts.all'
import acceptanc from '../../compnents/icons/png/acceptanc.png'
import { QuestionCircleTwoTone } from '@ant-design/icons'
import { TraceCodeInputModal } from '../payment/list/TraceCodeInputModal'

export const Putstortlist = (props: {
  putNumber?: string
  category?: string
  selectId?: number
}) => {
  const location: any = useLocation()

  const categoryTag = props?.category || location.state?.category

  const selectIdTag = props?.selectId || location.state?.selectId

  const printRef = useRef<PrintFrameRef>(null)

  const dispatch = useDispatch<RootDispatch>()

  const data = useSelector(selectorPutstoreData)

  const tenantName = useSelector(selectTenantName)

  const userName = useSelector(selectUserName)

  const detail: any = useSelector(selectDetail)

  const [isModalVisible, setIsModalVisible] = useState(false)

  const [form] = Form.useForm()

  const [Uitlprice, setUitlprice] = useState<string>()

  const [inputPrice, setInputPrice] = useState(0)

  const [realPayMoney, setRealPayMoney] = useState('')

  const [requiredList, setRequiredList] = useState<any>()

  const [deleteSubmitTag, setDeleteSubmitTag] = useState<any>()

  const [salculateSalePrice, setSalculateSalePrice] = useState<any>(false)

  const [dataSynchronismFlag, setDataSynchronismFlag] = useState<any>(true)

  const [traceabilityCode, setTraceabilityCode] = useState<any>(true)

  const [showCodeInputModal, setShowCodeInputModal] = useState(false)

  const [addFlag, setAddFlag] = useState<any>(false)

  const [result, setResult] = useState<any>()

  const [salculateSalePriceNum, setSalculateSalePriceNum] = useState<any>(25)

  const [imgList, setImgList] = useState<any[]>([]) //保存上传附件

  const [hoverIndex, setHoverIndex] = useState(-1)

  const [printData, setPrintData] = useState<any>()

  const theme = useContext(ThemeContext)

  const [isVisible, setIsVisible] = useState(false)

  const [pageLoading, setPageLoading] = useState(false) //表格 loading

  const [buttonLoading, setButtonLoading] = useState(false)

  const [priceBackfillFlag, setPriceBackfillFlag] = useState(false)

  const [messageApi, contextHolder] = message.useMessage()

  const tableHeight: any =
    document.documentElement.clientHeight || document.body.clientHeight

  const [scrollHeight, setScrollHeight] = useState(tableHeight - 520)

  const [receiptsItemId, setReceiptsItemId] = useState<any>('')

  const [allData, setAllData] = useState<any>()

  window.onresize = function () {
    const scrollHeigth: any = tableHeight - 520
    setScrollHeight(scrollHeigth)
  }

  useEffect(() => {
    const flag = data?.filter((v: any) => v?.id == '')
    if (data?.length && flag?.length) {
      setAllData(data)
      if (addFlag) {
        const list: any = data
        const length = data?.length - 2
        setReceiptsItemId(list?.[length]?.id)
      }
    } else if(data?.length){
      if (detail.storehouseTag > 0) {
        setAllData(data)
      }
    }
  }, [data, addFlag])

  useEffect(() => {
    // 查询追溯码
    if (showCodeInputModal) {
      if (receiptsItemId)
        dispatch(getTraceableCodeList({ receiptsItemId: receiptsItemId }))
          .then(unwrapResult)
          .then((val: any) => {
            if (val?.length) {
              const thisData: any = allData?.filter((v: any) => {
                return v?.id == receiptsItemId
              })?.[0]
              const list = val?.map((v: any) => {
                return {
                  ...v,
                  name: v?.itemName,
                  count: thisData?.count,
                  splitTag: thisData?.splitTag,
                  splitUnit: thisData?.splitUnit,
                  accountUnit: thisData?.accountUnit,
                }
              })
              setResult(list)
            } else {
              const thisData = allData?.filter((v: any) => {
                return v?.id == receiptsItemId
              })
              setResult(thisData)
            }
          })
    } else {
      setResult(null)
      setReceiptsItemId('')
      setAddFlag(false)
    }
  }, [showCodeInputModal, allData])

  // 查询追溯码
  // const getTraceableCodePage = (receiptsItemId?: any) => {

  // }

  useEffect(() => {
    const priceBackfillFlag = localStorage.getItem('priceBackfillFlag')
    if (priceBackfillFlag && priceBackfillFlag === 'true') {
      setPriceBackfillFlag(true)
    }
  }, [])

  const dateCompare = (prev: string, next: string) => {
    const datePrev = new Date(prev)
    const dateNext = new Date(next)
    if (datePrev.getTime() - dateNext.getTime() <= 0) {
      return true
    } else {
      return false
    }
  }

  const notificationMessage = (flag: boolean) => {
    flag
      ? messageApi.open({
          type: 'error',
          content: '有效期需要大于当前日期',
          className: 'custom-class',
        })
      : messageApi.open({
          type: 'error',
          content: '生产日期需要小于当前日期',
          className: 'custom-class',
        })
  }

  const handleSave = (obj: stordetailBody, flag?: any) => {
    // if (obj.tempName != obj.name) {   //防止乱改药品名称
    //   notification.info({
    //     message: '请重新选择药品！'
    //   })
    //   return
    // }
    if (obj.id) {
      //修改单据
      setPageLoading(true)
      const removePrice: any = data.find((v: any) => v.id === obj.id)
      if (flag == 'invoiceNbFlag') {
        dispatch(saveStoreDetailInvoiceNbAsync(obj))
          .then(unwrapResult)
          .then(() => {
            setInputPrice(0)
            messageApi.open({
              type: 'success',
              content: '保存成功',
              className: 'custom-class',
            })
            setPageLoading(false)
            dispatch(detailPutstorage(selectIdTag))
          })
          .catch(() => {
            setPageLoading(false)
            // notification.error({
            //   message: "添加药品失败,请检查",
            // });
          })
      } else {
        dispatch(saveStoreDetailAsync(obj))
          .then(unwrapResult)
          .then(() => {
            // dispatch(detailPutstorage(selectIdTag))  //获取详情
            setInputPrice(0)
            dispatch(
              updataPutstorage2({
                ...detail,
                coherentVideo: imgList
                  .map((v: any) => `${v.name}@${v.uid}@`.concat(v.url || ''))
                  .join(','),
                realPayMoney:
                  Number(
                    realPayMoney ? parseFloat(realPayMoney).toFixed(2) : 0
                  ) -
                  Number(
                    removePrice?.totalPrice
                      ? parseFloat(removePrice?.totalPrice).toFixed(2)
                      : 0
                  ) +
                  Number(
                    obj.totalPrice ? parseFloat(obj.totalPrice).toFixed(2) : 0
                  ),
                shouldPayMoney:
                  Number(
                    realPayMoney ? parseFloat(realPayMoney).toFixed(2) : 0
                  ) -
                  Number(
                    removePrice?.totalPrice
                      ? parseFloat(removePrice?.totalPrice).toFixed(2)
                      : 0
                  ) +
                  Number(
                    obj.totalPrice ? parseFloat(obj.totalPrice).toFixed(2) : 0
                  ),
              })
            ).then(() => {
              messageApi.open({
                type: 'success',
                content: '保存成功',
                className: 'custom-class',
              })
              setPageLoading(false)
              dispatch(detailPutstorage(selectIdTag))
              if (
                ((obj as any)?.billingCategory == '01' ||
                  (obj as any)?.billingCategory == '02') &&
                categoryTag == 1 &&
                traceabilityCode
              ) {
                setShowCodeInputModal(true)
                setReceiptsItemId(obj?.id)
              }
            }) //获取详情
          })
          .catch(() => {
            setPageLoading(false)
            // notification.error({
            //   message: "添加药品失败,请检查",
            // });
          })
      }
    } else {
      setPageLoading(true)
      //保存单据
      dispatch(saveStoreDetailAsync(obj))
        .then(unwrapResult)
        .then(() => {
          setInputPrice(0)
          dispatch(
            updataPutstorage2({
              ...detail,
              coherentVideo: imgList
                .map((v: any) => `${v.name}@${v.uid}@`.concat(v.url || ''))
                .join(','),
              realPayMoney: Number(realPayMoney) + Number(obj.totalPrice),
              shouldPayMoney: Number(realPayMoney) + Number(obj.totalPrice),
            })
          ).then(() => {
            messageApi.open({
              type: 'success',
              content: '保存成功',
              className: 'custom-class',
            })
            setPageLoading(false)
            dispatch(detailPutstorage(selectIdTag))
            if (
              ((obj as any)?.billingCategory == '01' ||
                (obj as any)?.billingCategory == '02') &&
              categoryTag == 1 &&
              traceabilityCode
            ) {
              setShowCodeInputModal(true)
              setAddFlag(true)
              // getTraceableCodePage(((data?.[data?.length]) as any)?.id)
            }
          }) //获取详情
        })
        .catch(() => {
          setPageLoading(false)
          // notification.error({
          //   message: "添加药品失败,请检查",
          // })
        })
    }
  }

  const handleChange = (prev: any, next: any) => {
    if (categoryTag != 1) {
      if (prev.name && next.name && prev.name != next.name) return //防止名称乱改
    }
    const num =
      (Number(salculateSalePriceNum) / 100 + 1) * Number(next.unitPrice)

    const firstData: any = data?.[0]

    // 未开启数据同步的药
    const datas = {
      ...prev,
      ...next,
      // invoiceNb: next.invoiceNb ? next.invoiceNb : prev.invoiceNb,
      stockCount: next.count ? next.count : prev.count,
      productInfo:
        next?.spec ||
        next.packSpec ||
        next.accountUnit ||
        next.doseCategory ||
        next.tenantSupplierName
          ? ` ${next?.spec} /${next.packSpec} /${next.accountUnit} /${next.doseCategory} /${next.tenantSupplierName}`
          : `${prev.productInfo}`,
      id: prev.id,
      retailPrice: getNums(prev, next)
        ? getNums(prev, next)
        : num
        ? num
        : next.retailPrice
        ? next.retailPrice
        : prev.retailPrice,
      lastInstockPrice:
        next?.lastInstockPrice > 0 ? next?.lastInstockPrice : next?.retailPrice,
    }
    // 开启数据同步的药
    const dataSynchronismNewData = {
      ...datas,
      productDate: next.productDate
        ? next.productDate
        : prev.productDate
        ? prev.productDate
        : dataSynchronismFlag
        ? firstData?.productDate
        : null,
      deadline: next.deadline
        ? next.deadline
        : prev.deadline
        ? prev.deadline
        : dataSynchronismFlag
        ? firstData?.deadline
        : null,
      batchNo: next.batchNo
        ? next.batchNo
        : prev.batchNo
        ? prev.batchNo
        : dataSynchronismFlag
        ? firstData?.batchNo
        : null,
    }
    const newdata = dataSynchronismFlag ? dataSynchronismNewData : datas

    dispatch(
      changeRow(
        data.map((v: any) => (v.id === newdata.id ? newdata : { ...v }))
      )
    )
  }

  const getNums = (prev: any, next: any) => {
    const num = (
      (Number(salculateSalePriceNum) / 100 + 1) *
      Number(prev.unitPrice)
    )
      ?.toFixed(6)
      ?.slice(0, -1)

    const newNum = (
      (Number(salculateSalePriceNum) / 100 + 1) *
      Number(prev.unitPrice)
    )?.toFixed(5)

    const nums = (
      (Number(salculateSalePriceNum) / 100 + 1) *
      Number(next.unitPrice)
    )?.toFixed(5)

    const newNums = (
      (Number(salculateSalePriceNum) / 100 + 1) *
      Number(next.unitPrice)
    )
      ?.toFixed(6)
      ?.slice(0, -1)

    // 开启按钮
    if (salculateSalePrice) {
      if (
        ((Number(salculateSalePriceNum) / 100 + 1) * prev?.unitPrice)
          ?.toFixed(6)
          ?.slice(0, -1) == num
      ) {
        // 点击过零售单价
        if (next?.retailPrice) {
          // 没有修改过零售单价，选用修改前的*利润
          if (prev.retailPrice == next?.retailPrice) {
            return newNum
            // 修改后的零售单价跟之前的单价不同,选用修改后的*利润
          } else if (prev.retailPrice != next?.retailPrice) {
            if (typeof next?.retailPrice == 'string') {
              return next.retailPrice ? next.retailPrice : prev.retailPrice
            } else {
              return newNum
            }
          }
          // 没有点击过零售单价
        } else {
          if (prev.retailPrice == Number(next?.retailPrice)) {
            // 修改过入库单价，
            return newNum
          } else if (next?.unitPrice) {
            // 修改过入库单价，修改后的入库单价跟之前的入库单价不同，重新计算  (最新的入库单价*利润)
            if (prev.unitPrice != Number(next?.unitPrice)) {
              return newNums
            } else {
              return next.retailPrice ? next.retailPrice : prev.retailPrice
            }
            // 零售单价跟入库单价都没点击修改过,选用之前的
          } else {
            return num
          }
        }
      } else {
        return next.retailPrice ? next.retailPrice : prev.retailPrice
      }
    } else {
      return next.retailPrice || next.retailPrice == 0
        ? next.retailPrice?.toString()
        : prev.retailPrice
    }
  }

  const id = String(selectIdTag)
  // 借记出库(5)
  const outJJCKClued = () => {
    dispatch(getExportJJCKxcelAsync(id))
      .then(unwrapResult)
      .then((res: any) => {
        const content = res
        const blob = new Blob([content])
        const fileName = '借记出库.xlsx'
        const selfURL = window[window.webkitURL ? 'webkitURL' : 'URL']
        const elink = document.createElement('a')
        if ('download' in elink) {
          elink.download = fileName
          elink.style.display = 'none'
          elink.href = selfURL['createObjectURL'](blob)
          document.body.appendChild(elink)
          // 触发链接
          elink.click()
          selfURL.revokeObjectURL(elink.href)
          document.body.removeChild(elink)
        } else {
          return (navigator as any).msSaveBlob(blob, fileName)
        }
      })
      .then(() => {
        messageApi.open({
          type: 'success',
          content: '导出成功',
          className: 'custom-class',
        })
      })
      .catch((res: any) => {
        return
      })
  }
  //  借记还库(6)
  const outJJHKClued = () => {
    dispatch(getExportJJHKExcelAsync(id))
      .then(unwrapResult)
      .then((res: any) => {
        const content = res
        const blob = new Blob([content])
        const fileName = '借记还库.xlsx'
        const selfURL = window[window.webkitURL ? 'webkitURL' : 'URL']
        const elink = document.createElement('a')
        if ('download' in elink) {
          elink.download = fileName
          elink.style.display = 'none'
          elink.href = selfURL['createObjectURL'](blob)
          document.body.appendChild(elink)
          // 触发链接
          elink.click()
          selfURL.revokeObjectURL(elink.href)
          document.body.removeChild(elink)
        } else {
          return (navigator as any).msSaveBlob(blob, fileName)
        }
      })
      .then(() => {
        messageApi.open({
          type: 'success',
          content: '导出成功',
          className: 'custom-class',
        })
      })
      .catch((res: any) => {
        return
      })
  }

  //   7领用出库
  const outLYCKClued = () => {
    dispatch(getExportLYCKExcelAsync(id))
      .then(unwrapResult)
      .then((res: any) => {
        const content = res
        const blob = new Blob([content])
        const fileName = '领用出库.xlsx'
        const selfURL = window[window.webkitURL ? 'webkitURL' : 'URL']
        const elink = document.createElement('a')
        if ('download' in elink) {
          elink.download = fileName
          elink.style.display = 'none'
          elink.href = selfURL['createObjectURL'](blob)
          document.body.appendChild(elink)
          // 触发链接
          elink.click()
          selfURL.revokeObjectURL(elink.href)
          document.body.removeChild(elink)
        } else {
          return (navigator as any).msSaveBlob(blob, fileName)
        }
      })
      .then(() => {
        messageApi.open({
          type: 'success',
          content: '导出成功',
          className: 'custom-class',
        })
      })
      .catch((res: any) => {
        return
      })
  }

  //  9报损
  const outBSClued = () => {
    dispatch(getExportBSExcelAsync(id))
      .then(unwrapResult)
      .then((res: any) => {
        const content = res
        const blob = new Blob([content])
        const fileName = '报损.xlsx'
        const selfURL = window[window.webkitURL ? 'webkitURL' : 'URL']
        const elink = document.createElement('a')
        if ('download' in elink) {
          elink.download = fileName
          elink.style.display = 'none'
          elink.href = selfURL['createObjectURL'](blob)
          document.body.appendChild(elink)
          // 触发链接
          elink.click()
          selfURL.revokeObjectURL(elink.href)
          document.body.removeChild(elink)
        } else {
          return (navigator as any).msSaveBlob(blob, fileName)
        }
      })
      .then(() => {
        messageApi.open({
          type: 'success',
          content: '导出成功',
          className: 'custom-class',
        })
      })
      .catch((res: any) => {
        return
      })
  }

  useEffect(() => {
    if (selectIdTag) {
      dispatch(detailPutstorage(selectIdTag))
    }
  }, [location, props?.selectId])

  useEffect(() => {
    if (JSON.stringify(detail) !== '{}') {
      form.setFieldsValue({
        ...detail,
        realPayMoney:
          Number(inputPrice) || detail.realPayMoney !== -1
            ? Number(detail.realPayMoney)
            : 0,
      })
      setUitlprice(
        parseFloat(
          ((detail.shouldPayMoney - detail.realPayMoney) as unknown) as string
        ).toFixed(2)
      )

      // dispatch(updataPutstorage2({...detail}))
    }
  }, [detail])
  useEffect(() => {
    if (!data.length) {
      if (!detail.storehouseTag) {
        dispatch(addRow([...data, newData]))
      }
    } else {
      setRealPayMoney(
        parseFloat(
          data.reduce(function (prev: any, next: any) {
            const prevNum = prev ? parseFloat(prev).toFixed(2) : ''
            const nextNum = next.totalPrice
              ? parseFloat(next.totalPrice).toFixed(2)
              : ''
            return Number(prevNum) + (Number(nextNum) > 0 ? Number(nextNum) : 0)
          }, 0)
        ).toFixed(2)
      )
    }
    if (data.every((v: any) => v.id)) {
      if (!detail.storehouseTag) {
        dispatch(addRow([...data, newData]))
      }
    }
  }, [data])

  useEffect(() => {
    if (realPayMoney) {
      form.setFieldsValue({
        realPayMoney: realPayMoney,
      })
      if (deleteSubmitTag?.id) {
        dispatch(
          updataPutstorage2({
            ...detail,
            coherentVideo: imgList
              .map((v: any) => `${v.name}@${v.uid}@`.concat(v.url || ''))
              .join(','),
            realPayMoney: Number(realPayMoney),
            shouldPayMoney: Number(realPayMoney),
          })
        )
      }
    }
  }, [realPayMoney, deleteSubmitTag])

  const outCluedExport = () => {
    dispatch(getExportDetailListExoprt(selectIdTag))
      .then(unwrapResult)
      .then((res: any) => {
        const content = res
        const blob = new Blob([content])
        const fileName = '购入入库.xlsx'
        const selfURL = window[window.webkitURL ? 'webkitURL' : 'URL']
        const elink = document.createElement('a')
        if ('download' in elink) {
          elink.download = fileName
          elink.style.display = 'none'
          elink.href = selfURL['createObjectURL'](blob)
          document.body.appendChild(elink)
          // 触发链接
          elink.click()
          selfURL.revokeObjectURL(elink.href)
          document.body.removeChild(elink)
        } else {
          return (navigator as any).msSaveBlob(blob, fileName)
        }
      })
      .then(() => {
        messageApi.open({
          type: 'success',
          content: '导出成功',
          className: 'custom-class',
        })
      })
      .catch((res: any) => {
        return
      })
  }

  //退库
  const intCluedExport = () => {
    dispatch(getExportDetailOutListExoprt(selectIdTag))
      .then(unwrapResult)
      .then((res: any) => {
        const content = res
        const blob = new Blob([content])
        const fileName = '购入退库.xlsx'
        const selfURL = window[window.webkitURL ? 'webkitURL' : 'URL']
        const elink = document.createElement('a')
        if ('download' in elink) {
          elink.download = fileName
          elink.style.display = 'none'
          elink.href = selfURL['createObjectURL'](blob)
          document.body.appendChild(elink)
          // 触发链接
          elink.click()
          selfURL.revokeObjectURL(elink.href)
          document.body.removeChild(elink)
        } else {
          return (navigator as any).msSaveBlob(blob, fileName)
        }
      })
      .then(() => {
        messageApi.open({
          type: 'success',
          content: '导出成功',
          className: 'custom-class',
        })
      })
      .catch((res: any) => {
        return
      })
  }

  const filterData = data?.filter((v: any) => {
    return v?.name != ''
  })
  const dataMainList = {
    tenantName: tenantName?.split('-')?.[0],
    userName: userName,
    detail: {
      ...detail,
      realPayMoney:
        detail?.realPayMoney > 0 ? detail?.realPayMoney?.toFixed(2) : '0.00',
      recepitsDate: moment(detail?.recepitsDate).format(
        DateTimeFormatSimpleText
      ),
      recepitsDates: moment(detail?.recepitsDate).format(
        DateTimeFormatSimpleOnly
      ),
      putNumber: location?.state?.putNumber || props?.putNumber,
    },
    dataList: filterData?.map((v: any) => {
      const unitPrices: any = v.unitPrice ? parseFloat(v.unitPrice) : 0
      const retailPrices: any = v.retailPrice ? parseFloat(v.retailPrice) : 0,
        unitPriceAllPrice: any =
          v?.count > 0 ? (v?.count * unitPrices).toFixed(2) : '',
        retailPriceAllPrice: any =
          v?.count > 0 ? (v?.count * retailPrices).toFixed(2) : ''
      return {
        ...v,
        allCount: sum(v?.count > 0 ? v?.count : 0)
          ? sum(v?.count > 0 ? v?.count : 0)
          : 0,
        hilistCode: v?.hilistCode ? v?.hilistCode : '',
        productInfo: v.productInfo
          ? v.productInfo
          : v.packSpec ||
            v.accountUnit ||
            v.doseCategory ||
            v.tenantSupplierName
          ? v.packSpec / v.accountUnit / v.doseCategory / v.tenantSupplierName
          : '',
        productDate:
          v?.productDate !== undefined &&
          v?.productDate !== '' &&
          v?.productDate !== 'Invalid date'
            ? moment(v?.productDate).format(DateTimeFormatSimpleOnly)
            : '',
        deadline:
          v?.deadline &&
          v?.deadline !== undefined &&
          v?.deadline !== '' &&
          v?.deadline !== 'Invalid date'
            ? moment(v?.deadline).format(DateTimeFormatSimpleOnly)
            : '',
        unitPrice: v.unitPrice ? parseFloat(v.unitPrice).toFixed(4) : '',
        unitPriceAllPrice: unitPriceAllPrice,
        retailPriceAllPrice: retailPriceAllPrice,
        differencePrice:
          retailPriceAllPrice && unitPriceAllPrice
            ? (retailPriceAllPrice - unitPriceAllPrice)?.toFixed(2)
            : '',
        retailPrice: v.retailPrice ? parseFloat(v.retailPrice).toFixed(4) : '',
        totalPrice: v.totalPrice ? parseFloat(v.totalPrice).toFixed(5) : '',
      }
    }),
  }

  const content = printBody(dataMainList)

  const dataMainLists = {
    storehouseTag: detail.storehouseTag,
    tenantName: tenantName?.split('-')?.[0],
    userName: userName,
    detail: {
      ...detail,
      recepitsDate: moment(detail?.recepitsDate).format(
        DateTimeFormatSimpleOnly
      ),
    },
    dataList: filterData?.map((v: any) => {
      return {
        ...v,
        lyckIcludePrice:
          v.unitPrice * v.count
            ? parseFloat(
                ((v.unitPrice * v.count) as unknown) as string
              ).toFixed(2)
            : '',
        lyckOutIcludePrice:
          v.retailPrice * v.count
            ? parseFloat(
                ((v.retailPrice * v.count) as unknown) as string
              ).toFixed(2)
            : '',
      }
    }),
  }

  const dataMainYSList = {
    tenantName: tenantName?.split('-')?.[0],
    userName: userName,
    detail: {
      ...detail,
      recepitsDate: moment(detail?.recepitsDate).format(
        DateTimeFormatSimpleOnly
      ),
    },
    dataList: printData,
  }

  const YSContent = PrintBody(dataMainYSList)

  const contentSeven = printBodySeven(dataMainLists)

  const contentBS = printBodyBS(dataMainList, detail.storehouseTag)

  // 购入退库打印
  const dataMainGRTKList = {
    tenantName: tenantName?.split('-')?.[0],
    userName: userName,
    detail: {
      ...detail,
      recepitsDate: moment(detail?.recepitsDate).format(
        DateTimeFormatSimpleOnly
      ),
    },
    dataList: filterData?.map((v: any) => {
      return {
        ...v,
        deadline:
          v?.deadline && v?.deadline !== 'undefined'
            ? moment(v?.deadline).format(DateTimeFormatSimple)
            : '',
        invCount: detail?.storehouseTag > 0 ? v.invCount + v.count : v.invCount,
        totalPrice:
          v.unitPrice * v.count
            ? parseFloat(
                ((v.unitPrice * v.count) as unknown) as string
              ).toFixed(2)
            : '',
        unitPrice: v.unitPrice ? parseFloat(v.unitPrice).toFixed(5) : '',
      }
    }),
  }

  const contentTGRTK = printBodyGRTK(dataMainGRTKList)

  useEffect(() => {
    if (selectIdTag)
      dispatch(generateAcceptancePrintInfoAsync(selectIdTag))
        .then(unwrapResult)
        .then((v: any) => {
          setPrintData(v)
        })
  }, [selectIdTag])

  const menus = ['入库单', '验收单']

  useEffect(() => {
    if (detail?.autoPriceRatio && detail?.autoPriceRatio != '-1') {
      setSalculateSalePrice(true)
      setSalculateSalePriceNum(detail?.autoPriceRatio || 25)
    } else {
      setSalculateSalePrice(false)
      setSalculateSalePriceNum('')
    }
  }, [detail?.autoPriceRatio])

  const getNum = (prev: any, next: any) => {
    const num = (
      (Number(salculateSalePriceNum) / 100 + 1) *
      Number(prev.unitPrice)
    )?.toFixed(5)

    const newNum = (
      (Number(salculateSalePriceNum) / 100 + 1) *
      Number(prev.unitPrice)
    )
      ?.toFixed(6)
      ?.slice(0, -1)

    const nums = (
      (Number(salculateSalePriceNum) / 100 + 1) *
      Number(next.unitPrice)
    )?.toFixed(5)

    const newNums = (
      (Number(salculateSalePriceNum) / 100 + 1) *
      Number(next.unitPrice)
    )
      ?.toFixed(6)
      ?.slice(0, -1)
    // 开启按钮
    if (salculateSalePrice) {
      if (
        ((Number(salculateSalePriceNum) / 100 + 1) * prev?.unitPrice)?.toFixed(
          5
        ) == num
      ) {
        // 点击过零售单价
        if (next?.retailPrice) {
          // 没有修改过零售单价，选用修改前的*利润
          if (prev.retailPrice == next?.retailPrice) {
            return newNum
            // 修改后的零售单价跟之前的单价不同,选用修改后的*利润
          } else if (prev.retailPrice != next?.retailPrice) {
            if (typeof next.retailPrice !== 'string') {
              return newNums
            } else {
              return next.retailPrice ? next.retailPrice : prev.retailPrice
            }
          }
          // 没有点击过零售单价
        } else {
          // 修改过零售单价，零售单价修改后跟修改前一杨，选用修改前的*利润
          if (prev.retailPrice == Number(next?.retailPrice)) {
            // 修改过入库单价，
            return newNum
          } else if (next?.unitPrice) {
            // 修改过入库单价，修改后的入库单价跟之前的入库单价不同，重新计算  (最新的入库单价*利润)
            if (prev.unitPrice != Number(next?.unitPrice)) {
              return newNums
            } else {
              return next.retailPrice ? next.retailPrice : prev.retailPrice
            }
            // 零售单价跟入库单价都没点击修改过,选用之前的
          } else {
            return next.retailPrice ? next.retailPrice : prev.retailPrice
          }
        }
      }
    } else {
      return next.retailPrice ? next.retailPrice : prev.retailPrice
    }
  }

  useEffect(() => {
    const traceabilityCode = localStorage.getItem('traceabilityCode')
    setTraceabilityCode(traceabilityCode == 'true' ? true : false)
  }, [])

  const grrkColumn = PurchaseStockColumns
  return (
    <>
      {contextHolder}
      <div className={styles.putStorage}>
        {!props?.selectId ? (
          <NavBar
            style={{ margin: '0px 20px' }}
            where={[
              Number(categoryTag) < 8 ? '库房业务' : '库存管理',
              CategoryName(categoryTag) as string,
            ]}
            backtrace={{
              name: location.state?.tockSalesUploadCategory
                ? '进销存上传'
                : Number(location.state?.source) == 1
                ? '药品购进记录'
                : '单据列表',
              path: location.state?.tockSalesUploadCategory
                ? '/stockSalesUpload'
                : Number(location.state?.source) == 1
                ? '/recardDrug'
                : Number(categoryTag) < 9
                ? '/putstorage'
                : '/InventoryCheck',
              state: location.state?.tockSalesUploadCategory
                ? { station: location.state?.station }
                : { category: categoryTag },
              routeName: location.state?.tockSalesUploadCategory
                ? '进销存上传'
                : '库房工作站',
            }}
            buttons={
              <>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                  }}
                >
                  {categoryTag == 1 ? (
                    <Button
                      style={{
                        border: 'none',
                        background: '#FFFFFF',
                        display: 'flex',
                        alignItems: 'center',
                        marginRight: '8px',
                      }}
                      size='small'
                      onClick={() => {
                        if (detail.storehouseTag > 0) {
                          dispatch(generateAcceptanceInfoAsync(selectIdTag))
                            .then(unwrapResult)
                            .then((v: any) => {
                              dispatch(
                                generateAcceptancePrintInfoAsync(selectIdTag)
                              )
                                .then(unwrapResult)
                                .then((v: any) => {
                                  setPrintData(v)
                                })
                              messageApi.open({
                                type: 'success',
                                content: '验收成功',
                                className: 'custom-class',
                              })
                            })
                            .catch((i: any) => {
                              messageApi.open({
                                type: 'error',
                                content: i?.message,
                                className: 'custom-class',
                              })
                            })
                        } else {
                          messageApi.open({
                            type: 'info',
                            content: '请先完成登账操作',
                            className: 'custom-class',
                          })
                        }
                      }}
                    >
                      <img
                        src={acceptanc}
                        style={{
                          width: '20px',
                          height: '20px',
                          marginRight: '3px',
                        }}
                      />
                      生成验收信息
                    </Button>
                  ) : (
                    ''
                  )}
                  {categoryTag == 1 ? (
                    <Popover
                      placement='right'
                      content={
                        <Col>
                          {menus.map((m: any) => (
                            <Row
                              key={m}
                              align='middle'
                              justify='center'
                              style={{
                                width: '6.25rem',
                                height: '3.125rem',
                                flex: 1,
                                fontSize: '1rem',
                                color: theme.c1,
                                cursor: 'pointer',
                              }}
                              className={styles.menu}
                              onClick={() => {
                                setIsVisible(false)
                                // onAction(m as any)
                                if (m == '入库单') {
                                  const page: any = window.open('', '_blank') // 打开一个新窗口，用于打印
                                  page.document.write(content) // 写入打印页面的内容
                                  page.print() // 打印
                                  page?.close()
                                } else {
                                  // 验收单打印
                                  const page: any = window.open('', '_blank') // 打开一个新窗口，用于打印
                                  page.document.write(YSContent) // 写入打印页面的内容
                                  page.print() // 打印
                                  page?.close()
                                }
                              }}
                            >
                              {m}
                            </Row>
                          ))}
                        </Col>
                      }
                      className={styles.popover}
                      overlayClassName={
                        hoverIndex === 1
                          ? styles['overlay-hover']
                          : styles.overlay
                      }
                      visible={isVisible}
                      mouseLeaveDelay={0.3}
                      onVisibleChange={(v) => {
                        if (!v) {
                          setHoverIndex(-1)
                        }
                        setIsVisible(v)
                      }}
                    >
                      <Button
                        style={{
                          border: 'none',
                          background: '#FFFFFF',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                        size='small'
                      >
                        <PrintOutlined
                          style={{
                            fill: '#666',
                            width: '18px',
                            height: '18px',
                            marginRight: '8px',
                          }}
                        />
                        打印
                      </Button>
                    </Popover>
                  ) : (
                    ''
                  )}

                  {categoryTag == 2 || categoryTag == 7 ? (
                    <>
                      <Button
                        style={{
                          border: 'none',
                          background: '#FFFFFF',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                        size='small'
                        // loading={printLoading}
                        onClick={() => {
                          // 领用出库打印
                          if (categoryTag == 7) {
                            const page: any = window.open('', '_blank') // 打开一个新窗口，用于打印
                            page.document.write(contentSeven) // 写入打印页面的内容
                            page.print() // 打印
                            page?.close()
                          } else if (categoryTag == 2) {
                            const page: any = window.open('', '_blank') // 打开一个新窗口，用于打印
                            page.document.write(contentTGRTK) // 写入打印页面的内容
                            page.print() // 打印
                            page?.close()
                          } else {
                            printRef.current?.print()
                          }
                        }}
                      >
                        <PrintOutlined
                          style={{
                            fill: '#666',
                            width: '18px',
                            height: '18px',
                            marginRight: '8px',
                          }}
                        />
                        打印
                      </Button>
                    </>
                  ) : (
                    <></>
                  )}
                  {detail.storehouseTag > 0 ? (
                    <></>
                  ) : (
                    <Button
                      type='link'
                      onClick={() => {
                        setIsModalVisible(true)
                      }}
                      style={{
                        color: '#333333',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <AccountOutlined
                        style={{
                          fill: '#666',
                          width: '16px',
                          height: '16px',
                          marginRight: '4px',
                        }}
                      />
                      登账
                    </Button>
                  )}
                  {categoryTag == 9 ? (
                    <>
                      <Button
                        style={{
                          border: 'none',
                          background: '#FFFFFF',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                        size='small'
                        // loading={printLoading}
                        onClick={() => {
                          if (categoryTag == 9) {
                            const page: any = window.open('', '_blank') // 打开一个新窗口，用于打印
                            page.document.write(contentBS) // 报损打印
                            page.print() // 打印
                            page?.close()
                          } else {
                            printRef.current?.print()
                          }
                        }}
                      >
                        <PrintOutlined
                          style={{
                            fill: '#666',
                            width: '18px',
                            height: '18px',
                            marginRight: '8px',
                          }}
                        />
                        打印
                      </Button>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </>
            }
          />
        ) : (
          <></>
        )}

        <div className={styles.content}>
          {categoryTag === '1' || categoryTag === '2' ? (
            <PutlistQuery
              category={categoryTag}
              Detail={detail}
              refresh={() => {
                dispatch(detailPutstorage(selectIdTag))
              }}
              exportExcel={() => {
                categoryTag === '1' ? outCluedExport() : intCluedExport()
              }}
              fileSave={(num, arr: any) => {
                if (num == 1) {
                  //保存单据
                  dispatch(
                    updataPutstorage2({
                      ...detail,
                      coherentVideo: arr
                        .map((v: any) =>
                          `${v.name}@${v.uid}@`.concat(v.url || '')
                        )
                        .join(','),
                      realPayMoney: Number(realPayMoney),
                      shouldPayMoney: Number(realPayMoney),
                    })
                  ).then((res: any) => {
                    dispatch(detailPutstorage(selectIdTag)) //获取详情
                  })
                } else if (num == 2) {
                  //防止修改单据时清空上传附件
                  setImgList(arr)
                }
              }}
            />
          ) : categoryTag === '3' || categoryTag === '4' ? (
            <AllocationQuery category={categoryTag} Detail={detail} />
          ) : categoryTag === '5' ||
            categoryTag === '6' ||
            categoryTag === '7' ||
            categoryTag === '8' ||
            categoryTag === '9' ? (
            <DebitOutQuery
              category={categoryTag}
              Detail={detail}
              outClued={() => {
                if (categoryTag === '5') {
                  outJJCKClued()
                } else if (categoryTag === '6') {
                  outJJHKClued()
                } else if (categoryTag === '7') {
                  outLYCKClued()
                } else if (categoryTag === '9') {
                  outBSClued()
                }
              }}
            />
          ) : (
            <></>
          )}
          {categoryTag === '1' ? (
            <>
              {detail?.storehouseTag > 0 ? (
                <></>
              ) : (
                <Row style={{ height: '50px', lineHeight: '50px' }}>
                  <div style={{ display: 'flex', width: '70%' }}>
                    <Form.Item
                      label='自动计算售价'
                      rules={[{ max: 256, message: '最大256位!' }]}
                    >
                      <Switch
                        checked={salculateSalePrice}
                        onChange={(v) => {
                          setSalculateSalePrice(v)
                          setSalculateSalePriceNum(salculateSalePriceNum || 25)
                          dispatch(
                            updataPutstorage2({
                              ...detail,
                              autoPriceRatio: v
                                ? salculateSalePriceNum || 25
                                : 0,
                            })
                          ).then((res: any) => {
                            return
                          })
                        }}
                      />
                    </Form.Item>
                    {salculateSalePrice ? (
                      <>
                        <div
                          style={{
                            height: '32px',
                            lineHeight: '32px',
                            margin: '0 10px',
                          }}
                        >
                          根据
                        </div>
                        <Form.Item label=''>
                          <Input
                            value={salculateSalePriceNum}
                            defaultValue={25}
                            style={{ width: '80px' }}
                            width={150}
                            onChange={(v) => {
                              const reg = new RegExp(
                                new RegExp(/^(([1-9]\d*)|(0{1}))(\.\d{0,2})?$/)
                              )
                              if (Number(v.target.value) > 1000) {
                                messageApi.open({
                                  type: 'info',
                                  content:
                                    '值的范围在 1 ~ 1000 ，最多两位小数，请正确输入',
                                  className: 'custom-class',
                                })
                              } else {
                                if (reg.test(v.target.value)) {
                                  setSalculateSalePriceNum(v.target.value)
                                } else {
                                  messageApi.open({
                                    type: 'info',
                                    content:
                                      '值的范围在 1 ~ 1000 ，最多两位小数，请正确输入',
                                    className: 'custom-class',
                                  })
                                }
                              }
                            }}
                            onBlur={() => {
                              dispatch(
                                updataPutstorage2({
                                  ...detail,
                                  autoPriceRatio: salculateSalePrice
                                    ? salculateSalePriceNum || 25
                                    : '',
                                })
                              ).then((res: any) => {
                                return
                              })
                            }}
                          />
                        </Form.Item>
                        <div
                          style={{
                            height: '32px',
                            lineHeight: '32px',
                            marginLeft: '10px',
                          }}
                        >
                          %利润计算售价
                        </div>
                      </>
                    ) : (
                      ''
                    )}
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      width: '30%',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Form.Item
                      label='追溯码录入'
                      rules={[{ max: 256, message: '最大256位!' }]}
                      style={{ marginRight: 10 }}
                    >
                      <Switch
                        checkedChildren='开启'
                        unCheckedChildren='关闭'
                        checked={traceabilityCode}
                        onChange={(v) => {
                          localStorage.setItem('traceabilityCode', v.toString())
                          setTraceabilityCode(v)
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      label='数据同步'
                      rules={[{ max: 256, message: '最大256位!' }]}
                    >
                      <Switch
                        checkedChildren='开启'
                        unCheckedChildren='关闭'
                        checked={dataSynchronismFlag}
                        onChange={(v) => {
                          setDataSynchronismFlag(v)
                        }}
                      />
                    </Form.Item>
                    <Popover
                      content={
                        <div>
                          <p>
                            开启数据同步后，生产日期、有效期、批次号这三列内
                          </p>
                          <p>容会默认回填录入的第一个商品的内容</p>
                        </div>
                      }
                      placement='bottom'
                      title='说明'
                    >
                      <QuestionCircleTwoTone
                        style={{ margin: '9px 0 0 10px', fontSize: '16px' }}
                      />
                    </Popover>
                  </div>
                </Row>
              )}
            </>
          ) : (
            <div></div>
          )}

          <EditableList<DeliveryItem>
            className={styles.table}
            // sticky
            scroll={{
              x:
                categoryTag === '1'
                  ? '150rem'
                  : categoryTag === '2' ||
                    categoryTag === '3' ||
                    categoryTag === '4' ||
                    categoryTag === '5' ||
                    categoryTag === '6' ||
                    categoryTag === '7' ||
                    categoryTag === '9'
                  ? '100rem'
                  : 'auto',
              // y:
              //   categoryTag === '1'
              //     ? '23.5'
              //     : categoryTag === '4'
              //     ? '25.5rem'
              //     : categoryTag === '3' ||
              //       categoryTag === '5' ||
              //       categoryTag === '6' ||
              //       categoryTag === '7'
              //     ? '30rem '
              //     : categoryTag === '9'
              //     ? '29rem'
              //     : '28rem',
              y: scrollHeight,
            }}
            bordered
            loading={pageLoading}
            columns={
              categoryTag === '1'
                ? grrkColumn(
                    detail.storehouseTag,
                    (text, t, flag) => {
                      if (text === 'add') {
                        if (data.every((v: any) => v.id)) {
                          dispatch(addRow([...data, newData]))
                        }
                      } else if (text === 'remove') {
                        if (t.id) {
                          setInputPrice(0)
                          setDeleteSubmitTag(t)
                          dispatch(Removestoredetail(t.id, selectIdTag))
                        } else {
                          dispatch(
                            deleteRow(
                              data.filter((item: { id: any }) => item.id)
                            )
                          )
                        }
                      } else if (text === 'edit') {
                        handleChange(t.prev, t.next)
                      } else if (text === 'submit') {
                        let num = 0
                        if (salculateSalePrice) {
                          if (t.prev?.unitPrice) {
                            num =
                              (Number(salculateSalePriceNum) / 100 + 1) *
                              Number(t.prev.unitPrice)
                          }
                        }
                        const newdata = {
                          ...t.prev,
                          ...t.next,
                          tempName: t.tempName,
                          invoiceNb: t.next.invoiceNb
                            ? t.next.invoiceNb
                            : t.prev.invoiceNb,
                          productDate: t.next.productDate
                            ? moment(t.next.productDate).format(DateTimeFormat)
                            : moment(t.prev.productDate).format(DateTimeFormat),
                          deadline: t.next.deadline
                            ? moment(t.next.deadline)
                                .endOf('day')
                                .format(DateTimeFormat)
                            : moment(t.prev.deadline)
                                .endOf('day')
                                .format(DateTimeFormat),
                          totalPrice: t.next.unitPrice
                            ? t.prev.count * t.next.unitPrice
                            : t.next.count
                            ? t.next.count * t.prev.unitPrice
                            : t.prev.count * t.prev.unitPrice,
                          retailPrice: getNum(t.prev, t.next),
                          // retailPrice:
                          //  ( salculateSalePrice &&
                          //   (Number(salculateSalePriceNum) / 100 + 1) *
                          //     t.prev?.unitPrice ==
                          //     num &&
                          //   t.prev.retailPrice == t.next?.retailPrice)
                          //     ? num
                          //     : (t.next?.retailPrice &&
                          //       t.prev.retailPrice != t.next?.retailPrice)
                          //     ?( (Number(salculateSalePriceNum) / 100 + 1) *
                          //       Number(t.next?.unitPrice))
                          //     : t.next.retailPrice
                          //     ? t.next.retailPrice
                          //     : t.prev.retailPrice,
                        }

                        if (
                          newdata.productDate &&
                          newdata.deadline &&
                          newdata.batchNo &&
                          newdata.count
                        ) {
                          if (
                            dateCompare(
                              moment().startOf('day').format(DateTimeFormat),
                              newdata.deadline
                            )
                          ) {
                            handleSave(
                              {
                                ...newdata,
                                category: categoryTag,
                                tenantInventoryReceiptsId: selectIdTag,
                              },
                              flag
                            )
                          } else {
                            notificationMessage(true)
                          }
                        } else {
                          handleChange(t.prev, t.next)
                        }
                      } else if (text === 'save') {
                        if (
                          t.productDate &&
                          t.deadline &&
                          t.batchNo &&
                          t.count
                        ) {
                          handleSave({
                            ...t,
                            tempName: t.name,
                            productDate: moment(t.productDate).format(
                              DateTimeFormat
                            ),
                            deadline: moment(t.deadline)
                              .endOf('day')
                              .endOf('day')
                              .format(DateTimeFormat),
                            category: categoryTag,
                            tenantInventoryReceiptsId: selectIdTag,
                            unitPrice: t?.unitPrice ? t?.unitPrice : 0.0,
                            totalPrice: t?.totalPrice ? t?.totalPrice : 0.0,
                            // unitPrice
                          })
                        } else {
                          const data = [
                            {
                              name: '生产日期',
                              required: true,
                              value: t?.productDate,
                            },
                            {
                              name: '有效期',
                              required: true,
                              value: t?.deadline,
                            },
                            {
                              name: '批号',
                              required: true,
                              value: t?.batchNo,
                            },
                            {
                              name: '数量',
                              required: true,
                              value: t?.count,
                            },
                          ]
                          const required = data.filter(
                            (v) => v.required && !v.value
                          )
                          if (required.length) {
                            setRequiredList(required)
                            messageApi.open({
                              type: 'error',
                              content: '请填写必填项' + required?.[0]?.name,
                              className: 'custom-class',
                            })
                          }
                        }
                      } else if (text === 'code') {
                        setShowCodeInputModal(true)
                        // getTraceableCodePage(t.id)
                        setReceiptsItemId(t.id)
                      }
                    },
                    detail?.storehouseCategory
                  )
                : categoryTag === '2'
                ? PurchaseDeliveryColumns(
                    detail.storehouseId,
                    detail.storehouseTag,
                    (text, t) => {
                      if (text === 'add') {
                        if (data.every((v: any) => v.id)) {
                          dispatch(addRow([...data, newData]))
                        }
                      } else if (text === 'remove') {
                        if (t.id) {
                          setInputPrice(0)
                          setDeleteSubmitTag(t)
                          // dispatch(
                          //   updataPutstorage({
                          //     ...detail,
                          //     realPayMoney:
                          //       Number(realPayMoney) - Number(t.totalPrice),
                          //     shouldPayMoney:
                          //       Number(realPayMoney) - Number(t.totalPrice),
                          //   })
                          // )
                          dispatch(Removestoredetail(t.id, selectIdTag))
                        } else {
                          dispatch(
                            deleteRow(
                              data.filter((item: { id: any }) => item.id)
                            )
                          )
                        }
                      } else if (text === 'edit') {
                        handleChange(t.prev, t.next)
                      } else if (text === 'submit') {
                        const newdata = {
                          ...t.prev,
                          ...t.next,
                          tempName: t.tempName,
                          productDate: moment(t.prev.productDate).format(
                            DateTimeFormat
                          ),
                          deadline: moment(t.prev.deadline)
                            .endOf('day')
                            .format(DateTimeFormat),
                          totalPrice: t.next.unitPrice
                            ? t.prev.count * t.next.unitPrice
                            : t.next.count
                            ? t.next.count * t.prev.unitPrice
                            : t.next.count || t.next.unitPrice
                            ? t.next.count * t.next.unitPrice
                            : t.prev.count * t.prev.unitPrice,
                        }
                        if (Number(newdata.count) > Number(newdata.invCount)) {
                          messageApi.open({
                            type: 'error',
                            content: '退库数量不可大于实际数量',
                            className: 'custom-class',
                          })
                        } else {
                          handleSave({
                            ...newdata,
                            tenantInventoryReceiptsId: selectIdTag,
                          })
                        }
                      } else if (text === 'save') {
                        handleSave({
                          ...t,
                          tempName: t.name,
                          productDate: moment(t.productDate).format(
                            DateTimeFormat
                          ),
                          deadline: moment(t.deadline)
                            .endOf('day')
                            .format(DateTimeFormat),
                          category: categoryTag,
                          tenantInventoryReceiptsId: selectIdTag,
                        })
                      }
                    }
                  )
                : categoryTag === '3'
                ? TransferOutColumns(
                    detail.storehouseId,
                    detail.storehouseTag,
                    (text, t) => {
                      if (text === 'add') {
                        if (data.every((v: any) => v.id)) {
                          dispatch(addRow([...data, newData]))
                        }
                      } else if (text === 'remove') {
                        if (t.id) {
                          setDeleteSubmitTag(t)
                          dispatch(Removestoredetail(t.id, selectIdTag))
                        } else {
                          dispatch(
                            deleteRow(
                              data.filter((item: { id: any }) => item.id)
                            )
                          )
                        }
                      } else if (text === 'edit') {
                        handleChange(t.prev, t.next)
                      } else if (text === 'submit') {
                        const newdata = {
                          ...t.prev,
                          ...t.next,
                          tempName: t.tempName,
                          productDate: moment(t.prev.productDate).format(
                            DateTimeFormat
                          ),
                          deadline: moment(t.prev.deadline)
                            .endOf('day')
                            .format(DateTimeFormat),
                          totalPrice: t.prev.totalPrice
                            ? t.prev.totalPrice
                            : t.next.count * t.prev.retailPrice,
                        }
                        if (Number(newdata.count) <= Number(newdata.invCount)) {
                          handleSave({
                            ...newdata,
                            tenantInventoryReceiptsId: selectIdTag,
                          })
                        } else {
                          messageApi.open({
                            type: 'error',
                            content: '退库数量不可大于库存数量',
                            className: 'custom-class',
                          })
                        }
                      } else if (text === 'save') {
                        handleSave({
                          ...t,
                          tempName: t.name,
                          productDate: moment(t.productDate).format(
                            DateTimeFormat
                          ),
                          deadline: moment(t.deadline)
                            .endOf('day')
                            .format(DateTimeFormat),
                          category: categoryTag,
                          tenantInventoryReceiptsId: selectIdTag,
                        })
                      }
                    }
                  )
                : categoryTag === '4'
                ? TransferInColumns(detail.storehouseTag)
                : categoryTag === '5'
                ? DebitOutColumns(
                    detail.storehouseId,
                    detail.storehouseTag,
                    (text, t) => {
                      if (text === 'add') {
                        if (data.every((v: any) => v.id)) {
                          dispatch(addRow([...data, newData]))
                        }
                      } else if (text === 'remove') {
                        if (t.id) {
                          setDeleteSubmitTag(t)
                          dispatch(Removestoredetail(t.id, selectIdTag))
                        } else {
                          dispatch(
                            deleteRow(
                              data.filter((item: { id: any }) => item.id)
                            )
                          )
                        }
                      } else if (text === 'edit') {
                        handleChange(t.prev, t.next)
                      } else if (text === 'submit') {
                        const newdata = {
                          ...t.prev,
                          ...t.next,
                          tempName: t.tempName,
                          productDate: moment(t.prev.productDate).format(
                            DateTimeFormat
                          ),
                          deadline: moment(t.prev.deadline)
                            .endOf('day')
                            .format(DateTimeFormat),
                          totalPrice: t.next.unitPrice
                            ? t.prev.count * t.next.unitPrice
                            : t.next.count
                            ? t.next.count * t.prev.unitPrice
                            : t.prev.count * t.prev.unitPrice,
                        }
                        if (Number(newdata.count) > Number(newdata.invCount)) {
                          messageApi.open({
                            type: 'error',
                            content: '借记出库数量不可大于库存数量',
                            className: 'custom-class',
                          })
                        } else {
                          handleSave({
                            ...newdata,
                            tenantInventoryReceiptsId: selectIdTag,
                          })
                        }
                      } else if (text === 'save') {
                        handleSave({
                          ...t,
                          tempName: t.name,
                          productDate: moment(t.productDate).format(
                            DateTimeFormat
                          ),
                          deadline: moment(t.deadline)
                            .endOf('day')
                            .format(DateTimeFormat),
                          category: categoryTag,
                          tenantInventoryReceiptsId: selectIdTag,
                        })
                      }
                    }
                  )
                : categoryTag === '6'
                ? DebitInColumns(detail.storehouseTag, (text, t) => {
                    if (text === 'add') {
                      if (data.every((v: any) => v.id)) {
                        dispatch(addRow([...data, newData]))
                      }
                    } else if (text === 'remove') {
                      if (t.id) {
                        setDeleteSubmitTag(t)
                        dispatch(Removestoredetail(t.id, selectIdTag))
                      } else {
                        dispatch(
                          deleteRow(data.filter((item: { id: any }) => item.id))
                        )
                      }
                    } else if (text === 'edit') {
                      handleChange(t.prev, t.next)
                    } else if (text === 'submit') {
                      const newdata = {
                        ...t.prev,
                        ...t.next,
                        tempName: t.tempName,
                        productDate: t.next.productDate
                          ? moment(t.next.productDate).format(DateTimeFormat)
                          : moment(t.prev.productDate).format(DateTimeFormat),
                        deadline: t.next.deadline
                          ? moment(t.next.deadline)
                              .endOf('day')
                              .format(DateTimeFormat)
                          : moment(t.prev.deadline)
                              .endOf('day')
                              .format(DateTimeFormat),
                        totalPrice: t.next.unitPrice
                          ? t.prev.count * t.next.unitPrice
                          : t.next.count
                          ? t.next.count * t.prev.unitPrice
                          : t.prev.count * t.prev.unitPrice,
                      }
                      if (
                        dateCompare(
                          moment().startOf('day').format(DateTimeFormat),
                          newdata.deadline
                        )
                      ) {
                        handleSave({
                          ...newdata,
                          tenantInventoryReceiptsId: selectIdTag,
                        })
                      } else {
                        notificationMessage(true)
                      }
                    } else if (text === 'save') {
                      handleSave({
                        ...t,
                        tempName: t.name,
                        productDate: moment(t.productDate).format(
                          DateTimeFormat
                        ),
                        deadline: moment(t.deadline)
                          .endOf('day')
                          .format(DateTimeFormat),
                        category: categoryTag,
                        tenantInventoryReceiptsId: selectIdTag,
                      })
                    }
                  })
                : categoryTag === '7'
                ? CollectColumns(
                    detail.storehouseId,
                    detail.storehouseTag,
                    (text, t) => {
                      if (text === 'add') {
                        if (data.every((v: any) => v.id)) {
                          dispatch(addRow([...data, newData]))
                        }
                      } else if (text === 'remove') {
                        if (t.id) {
                          setDeleteSubmitTag(t)
                          dispatch(Removestoredetail(t.id, selectIdTag))
                        } else {
                          dispatch(
                            deleteRow(
                              data.filter((item: { id: any }) => item.id)
                            )
                          )
                        }
                      } else if (text === 'edit') {
                        const newdata = { ...t.prev, ...t.next }
                        if (!newdata?.name) {
                          messageApi.open({
                            type: 'error',
                            content: '请先选择药品',
                            className: 'custom-class',
                          })
                        }
                        handleChange(t.prev, t.next)
                      } else if (text === 'submit') {
                        const newdata = {
                          ...t.prev,
                          ...t.next,
                          tempName: t.tempName,
                          productDate: moment(t.prev.productDate).format(
                            DateTimeFormat
                          ),
                          deadline: moment(t.prev.deadline)
                            .endOf('day')
                            .format(DateTimeFormat),
                          // splitCount:
                          // t?.prev.splitTag == 1 ? t?.next.splitCount : t?.prev.splitCount,
                          totalPrice: t.next.unitPrice
                            ? t.prev.count * t.next.unitPrice +
                              (t.next.unitPrice / t.prev?.splitScale) *
                                (t.prev?.splitTag == 1 ? t.prev?.splitCount : 0)
                            : t.next.count
                            ? t.next.count * t.prev.unitPrice +
                              (t.prev.unitPrice / t.prev?.splitScale) *
                                (t.prev?.splitTag == 1 ? t.prev?.splitCount : 0)
                            : t.next.splitCount
                            ? (t.prev.count || 0) * t.prev.unitPrice +
                              (t.prev.unitPrice / t.prev?.splitScale) *
                                (t.prev?.splitTag == 1
                                  ? Number(t.next?.splitCount)
                                  : 0)
                            : t.prev.count * t.prev.unitPrice +
                              (t.prev.unitPrice / t.prev?.splitScale) *
                                (t.prev?.splitTag == 1
                                  ? t.prev?.splitCount
                                  : 0),
                        }
                        if (!newdata?.name) {
                          messageApi.open({
                            type: 'error',
                            content: '请先选择药品',
                            className: 'custom-class',
                          })
                          return
                        }
                        if (Number(newdata.count) > Number(newdata.invCount)) {
                          messageApi.open({
                            type: 'error',
                            content: '领用出库数量不可大于库存数量',
                            className: 'custom-class',
                          })
                        } else {
                          handleSave({
                            ...newdata,
                            count: newdata?.count ? newdata?.count : 0,
                            tenantInventoryReceiptsId: selectIdTag,
                          })
                        }
                      } else if (text === 'save') {
                        handleSave({
                          ...t,
                          tempName: t.name,
                          productDate: moment(t.productDate).format(
                            DateTimeFormat
                          ),
                          deadline: moment(t.deadline)
                            .endOf('day')
                            .format(DateTimeFormat),
                          category: categoryTag,
                          tenantInventoryReceiptsId: selectIdTag,
                        })
                      } else if (text == 'noSplit') {
                        messageApi.open({
                          type: 'error',
                          content: '该库存不支持拆零',
                          className: 'custom-class',
                        })
                      }
                    }
                  )
                : categoryTag === '8'
                ? InventoryCloumn(detail.storehouseTag, (text, t) => {
                    if (text === 'add') {
                      if (data.every((v: any) => v.id)) {
                        dispatch(addRow([...data, newData]))
                      }
                    } else if (text === 'remove') {
                      if (t.id) {
                        setDeleteSubmitTag(t)
                        dispatch(Removestoredetail(t.id, selectIdTag))
                      } else {
                        dispatch(
                          deleteRow(data.filter((item: { id: any }) => item.id))
                        )
                      }
                    } else if (text === 'edit') {
                      handleChange(t.prev, t.next)
                    } else if (text === 'submit') {
                      const newdata = {
                        ...t.prev,
                        ...t.next,
                        tempName: t.tempName,
                        productDate: moment(t.prev.productDate).format(
                          DateTimeFormat
                        ),
                        deadline: moment(t.prev.deadline)
                          .endOf('day')
                          .format(DateTimeFormat),
                        totalPrice:
                          typeof t.next.count !== 'string' &&
                          typeof isNaN(
                            (t.prev.realSplitCount - t.prev.splitCount) *
                              t.prev.splitPrice +
                              (t.prev.realCount - t.prev.count) *
                                t.prev.unitPrice
                          )
                            ? 0
                            : (t.prev.realSplitCount - t.prev.splitCount) *
                                t.prev.splitPrice +
                              (t.prev.realCount - t.prev.count) *
                                t.prev.unitPrice,
                      }
                      dispatch(
                        changeRow(
                          data.map((v: any) =>
                            v.id === newdata.id ? newdata : { ...v }
                          )
                        )
                      )
                      handleSave({
                        ...newdata,
                        tenantInventoryReceiptsId: selectIdTag,
                      })
                    } else if (text === 'save') {
                      handleSave({
                        ...t,
                        tempName: t.name,
                        productDate: moment(t.productDate).format(
                          DateTimeFormat
                        ),
                        deadline: moment(t.deadline)
                          .endOf('day')
                          .format(DateTimeFormat),
                        category: categoryTag,
                        tenantInventoryReceiptsId: selectIdTag,
                      })
                    }
                  })
                : categoryTag === '9'
                ? BaosunColumns(
                    detail.storehouseId,
                    detail.storehouseTag,
                    (text, t) => {
                      if (text === 'add') {
                        if (data.every((v: any) => v.id)) {
                          dispatch(addRow([...data, newData]))
                        }
                      } else if (text === 'remove') {
                        if (t.id) {
                          setDeleteSubmitTag(t)
                          dispatch(Removestoredetail(t.id, selectIdTag))
                        } else {
                          dispatch(
                            deleteRow(
                              data.filter((item: { id: any }) => item.id)
                            )
                          )
                        }
                      } else if (text === 'edit') {
                        handleChange(t.prev, t.next)
                      } else if (text === 'submit') {
                        const newdata = {
                          ...t.prev,
                          ...t.next,
                          tempName: t.tempName,
                          splitCount: 0,
                          productDate: moment(t.prev.productDate).format(
                            DateTimeFormat
                          ),
                          deadline: moment(t.prev.deadline)
                            .endOf('day')
                            .format(DateTimeFormat),
                          totalPrice: t.next.unitPrice
                            ? t.prev.count * t.next.unitPrice
                            : t.next.count
                            ? t.next.count * t.prev.unitPrice
                            : t.prev.count * t.prev.unitPrice,
                        }
                        if (Number(newdata.count) > Number(newdata.invCount)) {
                          messageApi.open({
                            type: 'error',
                            content: '报损数量不可大于实际数量',
                            className: 'custom-class',
                          })
                        } else {
                          handleSave({
                            ...newdata,
                            tenantInventoryReceiptsId: selectIdTag,
                            totalPrice: 0,
                          })
                        }
                      } else if (text === 'save') {
                        handleSave({
                          ...t,
                          tempName: t.name,
                          productDate: moment(t.productDate).format(
                            DateTimeFormat
                          ),
                          deadline: moment(t.deadline)
                            .endOf('day')
                            .format(DateTimeFormat),
                          category: categoryTag,
                          tenantInventoryReceiptsId: selectIdTag,
                        })
                      }
                    },
                    categoryTag
                  )
                : []
            }
            rowClassName={() => 'editable-row'}
            rowKey={(t: any) => t.id}
            dataSource={
              categoryTag === '1'
                ? data?.map((v: any) => {
                    return {
                      ...v,
                      retailPrice:
                        v?.lastInstockPrice > 0
                          ? v?.lastInstockPrice
                          : v?.retailPrice,
                    }
                  })
                : data
            }
          />
          {categoryTag == 1 ? (
            <PrintFrame
              ref={printRef}
              title='库房业务单'
              // subTitle={BillName(
              //   props.tockSalesUploadCategory || categoryTag
              // )}
            >
              <Row
                style={{
                  marginTop: '20px',
                  lineHeight: '40px',
                  paddingLeft: '20px',
                  marginBottom: '40px',
                  fontSize: '18px',
                }}
              >
                <Col span={6}>库房名称：{detail?.storehouseName}</Col>
                <Col span={6}>业务部门：{detail?.departmentName}</Col>
                <Col span={6}>业务人员：{detail?.userName}</Col>
                <Col span={6}>供应商：{detail?.supplierName}</Col>
                {Number(categoryTag) > 1 ? (
                  // <Col span={6}>业务单号：{props.Detail.recepitsNumber}</Col>
                  ''
                ) : (
                  <Col span={6}>采购单号：{detail?.recepitsNumber}</Col>
                )}
                <Col span={6}>
                  实付金额：
                  {detail?.realPayMoney > 0
                    ? detail?.realPayMoney?.toFixed(2)
                    : '0.00'}
                </Col>
                <Col span={6}>
                  业务日期：
                  {moment(detail?.recepitsDate).format(DateTimeFormatSimple)}
                </Col>
                {detail?.remark ? (
                  <Col span={24}>备注：{detail?.remark}</Col>
                ) : (
                  ''
                )}

                {/* 显示入库单号、来源库房、来源科室、来源操作人员、对应出库单号 */}
                <Col span={24}></Col>
              </Row>
              <EditableList
                bordered
                pagination={false}
                dataSource={data}
                columns={[
                  {
                    title: '序号',
                    align: 'center',
                    width: Dimen.Num,
                    render: (_1, _2, index) => {
                      return `${index + 1}`
                    },
                  },
                  {
                    title: '名称',
                    align: 'center',
                    dataIndex: 'name',
                    width: '206px',
                    key: 'name',
                  },
                  {
                    title: '产品信息',
                    align: 'center',
                    dataIndex: 'productInfo',
                    key: 'productInfo',
                    render: function Element(_, t: any) {
                      if (t.productInfo) {
                        return <>{t.productInfo}</>
                      } else {
                        if (
                          t.spec ||
                          t.packSpec ||
                          t.accountUnit ||
                          t.doseCategory ||
                          t.tenantSupplierName
                        ) {
                          return (
                            <>
                              {t.spec}/{t.packSpec}/{t.accountUnit}/
                              {t.doseCategory}/{t.tenantSupplierName}
                            </>
                          )
                        } else {
                          return <></>
                        }
                      }
                    },
                  },
                  {
                    title: '发票号',
                    align: 'center',
                    width: '140px',
                    dataIndex: 'invoiceNb',
                  },
                  {
                    title: '生产日期',
                    align: 'center',
                    width: '140px',
                    dataIndex: 'productDate',
                    render: (productDate) => {
                      return productDate &&
                        productDate !== undefined &&
                        productDate !== '' &&
                        productDate !== 'Invalid date'
                        ? moment(productDate).format(DateTimeFormatSimple)
                        : undefined
                    },
                  },
                  {
                    title: '有效期',
                    align: 'center',
                    dataIndex: 'deadline',
                    width: '140px',
                    render: (deadline) => {
                      return deadline &&
                        deadline !== undefined &&
                        deadline !== '' &&
                        deadline !== 'Invalid date'
                        ? moment(deadline).format(DateTimeFormatSimple)
                        : ''
                    },
                  },
                  {
                    title: '批号',
                    align: 'center',
                    dataIndex: 'batchNo',
                    width: '130px',
                  },
                  {
                    title: '入库单价',
                    align: 'right',
                    dataIndex: 'unitPrice',
                    width: '130px',
                    render: (_, t: any) => {
                      return t.unitPrice
                        ? parseFloat(t.unitPrice).toFixed(5)
                        : ''
                    },
                  },
                  {
                    title: '零售单价',
                    align: 'right',
                    width: '130px',
                    dataIndex: 'retailPrice',
                    nextDataIndex: 'count',
                    render: (_, t: any) => {
                      return t.retailPrice
                        ? parseFloat(t.retailPrice).toFixed(5)
                        : ''
                    },
                  },
                  {
                    title: '数量',
                    align: 'right',
                    width: '130px',
                    dataIndex: 'count',
                  },
                  {
                    title: '小计',
                    align: 'right',
                    width: '130px',
                    dataIndex: 'totalPrice',
                    render: (_, t: any) => {
                      return t.totalPrice
                        ? parseFloat(t.totalPrice).toFixed(2)
                        : ''
                    },
                  },
                ]}
              />
            </PrintFrame>
          ) : categoryTag == 2 ? (
            <PrintFrame
              ref={printRef}
              title='库房业务单'
              // subTitle={BillName(
              //   props.tockSalesUploadCategory || categoryTag
              // )}
            >
              <Row
                style={{
                  marginTop: '20px',
                  lineHeight: '40px',
                  paddingLeft: '20px',
                  marginBottom: '40px',
                  fontSize: '18px',
                }}
              >
                <Col span={6}>库房名称：{detail?.storehouseName}</Col>
                <Col span={6}>业务部门：{detail?.departmentName}</Col>
                <Col span={6}>业务人员：{detail?.userName}</Col>
                <Col span={6}>供应商：{detail?.supplierName}</Col>
                <Col span={6}>
                  业务日期：
                  {moment(detail?.recepitsDate).format(DateTimeFormatSimple)}
                </Col>
                {detail?.remark ? (
                  <Col span={24}>备注：{detail?.remark}</Col>
                ) : (
                  ''
                )}

                {/* 显示入库单号、来源库房、来源科室、来源操作人员、对应出库单号 */}
                <Col span={24}></Col>
              </Row>
              <EditableList
                bordered
                pagination={false}
                dataSource={data}
                columns={[
                  {
                    title: '序号',
                    align: 'center',
                    render: (text: any, record: any, index: number) =>
                      `${index + 1}`,
                  },
                  {
                    title: '名称',
                    align: 'center',
                    dataIndex: 'name',
                    width: '206px',
                    key: 'name',
                  },
                  {
                    title: '产品信息',
                    dataIndex: 'productInfo',
                    align: 'center',
                  },
                  {
                    title: '医保国码',
                    dataIndex: 'hilistCode',
                    align: 'center',
                  },
                  {
                    title: '批号',
                    align: 'center',
                    dataIndex: 'batchNo',
                  },
                  {
                    title: '有效期',
                    align: 'center',
                    dataIndex: 'deadline',
                    render: (deadline) => {
                      return deadline && deadline !== 'undefined'
                        ? moment(deadline).format(DateTimeFormatSimple)
                        : ''
                    },
                  },
                  {
                    title: '实际数量',
                    dataIndex: 'invCount',
                    align: 'center',
                    render: (_, t: any) => {
                      return detail?.storehouseTag > 0
                        ? t.invCount + t.count
                        : t.invCount
                    },
                  },
                  {
                    title: '退库金额',
                    dataIndex: 'totalPrice',
                    align: 'right',
                    render: (_, t: any) => {
                      return t.unitPrice * t.count
                        ? parseFloat(
                            ((t.unitPrice * t.count) as unknown) as string
                          ).toFixed(2)
                        : ''
                    },
                  },
                  {
                    title: '退库单价',
                    dataIndex: 'unitPrice',
                    align: 'right',
                    width: '130px',
                    render: (_, t: any) => {
                      return t.unitPrice
                        ? parseFloat(t.unitPrice).toFixed(5)
                        : ''
                    },
                  },
                  {
                    title: '退库数量',
                    dataIndex: 'count',
                    width: '130px',
                    align: 'center',
                  },
                ]}
              />
            </PrintFrame>
          ) : (
            ''
          )}
        </div>
        {Number(categoryTag) < 3 ? (
          <Form
            form={form}
            onFinish={(vs) => {
              const shouldPayMoney = parseFloat(
                data.reduce(function (prev: any, next: any) {
                  return prev + next.totalPrice
                }, 0)
              ).toFixed(2)
              dispatch(
                updataPutstorage2({
                  ...detail,
                  ...vs,
                  shouldPayMoney,
                })
              ).then(() => dispatch(detailPutstorage(selectIdTag))) //获取详情
            }}
            onFieldsChange={(_, vs) => {
              const shouldPayMoney = parseFloat(
                data.reduce(function (prev: any, next: any) {
                  return (
                    prev + (Number(next.totalPrice) > 0 ? next.totalPrice : '')
                  )
                }, 0)
              ).toFixed(2)
              const realPayMoney: any = vs.find((f) =>
                isSameName(f, 'realPayMoney')
              )?.value
              setUitlprice(
                parseFloat(
                  ((Number(shouldPayMoney) -
                    Number(realPayMoney)) as unknown) as string
                ).toFixed(2)
              )
            }}
          >
            <Row className={styles.footer}>
              <Col span={4}>
                <Form.Item
                  label={categoryTag === '1' ? '应付金额' : '应收金额'}
                  name='shouldPayMoney'
                >
                  {parseFloat(
                    data.reduce(function (prev: any, next: any) {
                      const prevNum = prev ? parseFloat(prev).toFixed(2) : ''
                      const nextNum = next.totalPrice
                        ? parseFloat(next.totalPrice).toFixed(2)
                        : ''
                      return (
                        Number(prevNum) +
                        (Number(nextNum) > 0 ? Number(nextNum) : 0)
                      )
                    }, 0)
                  ).toFixed(2)}
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  label={categoryTag === '1' ? '实付金额' : '实收金额'}
                  name='realPayMoney'
                  rules={[
                    {
                      required: true,
                    },
                    {
                      pattern: new RegExp(/^(([1-9]\d*)|(0{1}))(\.\d{0,2})?$/),
                      message: '请输入正确格式,最多保留2位小数',
                    },
                  ]}
                >
                  <Input
                    onBlur={(e) => {
                      setInputPrice(Number(e.target.value))
                      form.submit()
                    }}
                    disabled={detail.storehouseTag > 0 ? true : false}
                  />
                </Form.Item>
              </Col>
              <Col flex={1}>
                <Form.Item>
                  <span
                    style={{
                      fontWeight: 'lighter',
                      marginLeft: '10px',
                      lineHeight: '40px',
                    }}
                  >
                    共优惠{Uitlprice}
                  </span>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label='单据备注'
                  name='remark'
                  rules={[{ max: 256, message: '最大256位!' }]}
                >
                  <TextArea
                    disabled={detail.storehouseTag > 0 ? true : false}
                    onBlur={(e) => {
                      form.submit()
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        ) : (
          <></>
        )}

        <Modal
          title='信息'
          visible={isModalVisible}
          okButtonProps={{ loading: buttonLoading }}
          onCancel={() => {
            setButtonLoading(false)
            setIsModalVisible(false)
          }}
          footer={
            <Row>
              {categoryTag == 1 && ( //购入入库
                <Col style={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox
                    checked={priceBackfillFlag}
                    onChange={(v) => {
                      const aFlag = v.target.checked
                      setPriceBackfillFlag(aFlag)
                      localStorage.setItem('priceBackfillFlag', String(aFlag))
                    }}
                  ></Checkbox>
                  &nbsp;&nbsp;登账后售价回填目录
                </Col>
              )}
              <Col flex={1}>
                <Button
                  onClick={() => {
                    setButtonLoading(false)
                    setIsModalVisible(false)
                  }}
                >
                  取消
                </Button>
                <Button
                  type={'primary'}
                  loading={buttonLoading}
                  onClick={() => {
                    setButtonLoading(true)
                    dispatch(
                      multifunctionalUpAccountAsync({
                        tenantInventoryReceiptsIds: String(selectIdTag),
                        priceBackfillFlag: priceBackfillFlag,
                      })
                    )
                      .then(unwrapResult)
                      .then(() => {
                        messageApi.open({
                          type: 'success',
                          content: '登账成功',
                          className: 'custom-class',
                        })
                        setIsModalVisible(false)
                        setButtonLoading(false)
                        setTimeout(() => {
                          dispatch(
                            traceRoute({
                              name: '库房工作站',
                              path:
                                Number(categoryTag) < 9
                                  ? '/putstorage'
                                  : '/InventoryCheck',
                              state: { category: categoryTag },
                            })
                          )
                        }, 1000)
                      })
                      .catch(() => {
                        setIsModalVisible(false)
                        setButtonLoading(false)
                        messageApi.open({
                          type: 'error',
                          content: '登账失败',
                          className: 'custom-class',
                        })
                      })
                  }}
                >
                  确认
                </Button>
              </Col>
            </Row>
          }
        >
          <h3>是否登账？</h3>
          <p>请确认各项信息是否填写正确，登账后将不可修改</p>
        </Modal>
        <TraceCodeInputModal
          showCodeInputModal={showCodeInputModal}
          paymentResult={result}
          recipeItemId={receiptsItemId}
          receiptsId={detail?.id}
          onCancel={() => setShowCodeInputModal(false)}
          onOk={() => {
            setShowCodeInputModal(false)
            dispatch(getTraceableCodeList({ receiptsItemId: receiptsItemId }))
              .then(unwrapResult)
              .then((val: any) => {
                if (val?.length) {
                  const thisData: any = data?.filter((v: any) => {
                    return v?.id == receiptsItemId
                  })?.[0]
                  const list = val?.map((v: any) => {
                    return {
                      ...v,
                      name: v?.itemName,
                      count: thisData?.count,
                      splitTag: thisData?.splitTag,
                      splitUnit: thisData?.splitUnit,
                      accountUnit: thisData?.accountUnit,
                    }
                  })
                  setResult(list)
                }
                dispatch(detailPutstorage(selectIdTag))
              })
          }}
          isShowUpload={false}
          showUploadBtn={false}
          grrkFlag={true}
          storehouseTag ={
            detail.storehouseTag > 0 ? true : false
          }
        />
      </div>
    </>
  )
}
