/*
 * @Descripttion:
 * @version:
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-09-28 17:23:27
 * @LastEditors: linxi
 * @LastEditTime: 2023-12-22 10:46:17
 */
import {PlusOutlined, QuestionCircleTwoTone} from '@ant-design/icons'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Col,
  Form,
  Input,
  message,
  Modal,
  notification, Popover,
  Radio,
  Row,
  Select,
  Space,
  Upload,
} from 'antd'
import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../app/store'
import { getBase64, stringTrim } from '../../utils/StringUtils'
import { serverType } from '../../services/commodity'
import styles from './ElectronicTicketUpload.module.css'
import {
  getMedicalInformationUploadModalDetailAsync,
  selfMedicalInformationUploadAsync,
  selfMedicalInformationUploadElecSetlCertAsync,
} from './ElectronicTicketUploadSlice'
import {medicalSendHttp, strParse, timeOut} from '../../utils/MedicalUtils'
import moment from 'moment'
import {
  selectInsuranceArray,
  selectUserName,
  selectTenantAddress,
  selectTenantAreaCd,
  selectTenantName,
  selectUserId,
  selectTenantCategory,
} from '../../app/applicationSlice'
import { selectInsuranceItem, setInsurance } from '../../layouts/insuranceSlice'
import { DateTimeFormatSimpleOnlyOne } from '../../models/datetime'

interface MedicalInformationUploadProps {
  ids?: any
  paymentInsurance?: any
  visible: boolean
  onCancel: () => void
  onOk: () => void
}

export const MedicalInformationUploadModal = ({
  ids,
  paymentInsurance,
  visible,
  onCancel,
  onOk,
}: MedicalInformationUploadProps): ReactElement => {
  const formGutter = 20

  const firstColumnLayout = {
    labelCol: {
      flex: '0 0 6rem',
    },
  }

  const secondColumnLayout = {
    labelCol: {
      flex: '0 0 6rem',
    },
  }

  const thirdColumnLayout = {
    labelCol: {
      flex: '0 0 6rem',
    },
  }

  const dispatch = useDispatch<RootDispatch>()

  const baseData = useSelector(selectInsuranceItem)

  const [insuranceItem, setInsuranceItem] = useState<any>()

  const insuranceArray = useSelector(selectInsuranceArray)

  const userName = useSelector(selectUserName)

  const tenantAddress = useSelector(selectTenantAddress)

  const tenantAreaCd = useSelector(selectTenantAreaCd)

  const tenantName = useSelector(selectTenantName)

  const userId = useSelector(selectUserId)

  const tenantCategory = useSelector(selectTenantCategory)

  const cycleCode = sessionStorage.getItem('cycleCode')

  const [form] = Form.useForm()

  const [uploadImgSrc, setUploadImgSrc] = useState('')

  const [fileName, setFileName] = useState('')

  const [detail, setDetail] = useState<any>()

  const [isThirdpart, setIsThirdpart] = useState<any>()

  const [submitLoading, setSubmitLoading] = useState<any>()

  const [showStopAction, setShowStopAction] = useState(false)

  const [times, setTimes] = useState<any>(5)

  // 保存医保结构体
  useEffect(() => {
    if (insuranceItem && JSON.stringify(insuranceItem) !== '{}') {
      dispatch(
        setInsurance({
          ...insuranceItem,
          userName,
          userId,
          cycleCode,
          tenantCategory,
          tenantAreaCd,
          name: tenantName,
          address: tenantAddress,
          areaCode: tenantAreaCd,
          endTime: moment(insuranceItem.endTime).format(
            DateTimeFormatSimpleOnlyOne
          ),
        })
      )
    }
    return () => {
      dispatch(setInsurance({}))
    }
  }, [insuranceItem])

  useEffect(() => {
    if (insuranceArray.length !== 0) {
      setInsuranceItem(insuranceArray[0])
    }
  }, [insuranceArray])

  useEffect(() => {
    if (visible) {
      setUploadImgSrc('')
      dispatch(getMedicalInformationUploadModalDetailAsync(ids))
        .then(unwrapResult)
        .then((v: any) => {
          if(v?.disease == "" || v?.treatmentCategory == -1){
            notification.error({
              message: "请到医生门诊处补全病历后再进行上传",
            })
            onCancel()
            form.resetFields()
            return;
          }
          setDetail(v)
          setIsThirdpart(JSON.stringify(v?.invoiceDetails) != '{}' ? 2 : 1)
          form.setFieldsValue({
            elecSetlCertType: '2',
            idCardNumber: v?.patient?.idCardNumber,
            treatmentCategory: v?.treatment.treatmentCategory?.toString(),
            nwbFlag:
              v?.treatment.nwbFlag > -1
                ? v?.treatment.nwbFlag?.toString()
                : '0',
            ownpayType:
              v?.payment.ownpayType > -1
                ? v?.payment.ownpayType?.toString()
                : '3',
            // invoiceCode:
            //   JSON.stringify(v?.invoiceDetails) == '{}'
            //     ? ''
            //     : v?.payment.invoiceCode > -1
            //     ? v?.payment.invoiceCode?.toString()
            //     : '',
            // invoiceNumber:
            //   JSON.stringify(v?.invoiceDetails) == '{}'
            //     ? ''
            //     : v?.payment.invoiceNumber > -1
            //     ? v?.payment.invoiceNumber?.toString()
            //     : '',
            // invoiceCheckCode:
            //   JSON.stringify(v?.invoiceDetails) == '{}'
            //     ? ''
            //     : v?.payment.invoiceCheckCode > -1
            //     ? v?.payment.invoiceCheckCode?.toString()
            //     : '',
            isThirdpart: JSON.stringify(v?.invoiceDetails) != '{}' ? 2 : 1,
          })
        })
    }
  }, [visible])

  // 保存
  const selfMedicalInformationUploadSubmit = (
    serverType: serverType,
    certNo: string,
    invoiceCheckCode: string,
    invoiceCode: string,
    invoiceNumber: string,
    isThirdpart: string,
    nwbFlag: string,
    ownpayType: string,
    paymentId: string,
    treatmentCategory: string,
    treatmentId: string,
    elecSetlCertType: string,
    responseString?: string
  ) => {
    setSubmitLoading(true)
    dispatch(
      selfMedicalInformationUploadAsync({
        serverType,
        body: {
          baseData: {
            ...baseData,
            responseString,
          },
          certNo: certNo,
          invoiceCheckCode: invoiceCheckCode,
          invoiceCode: invoiceCode,
          invoiceNumber: invoiceNumber,
          isThirdpart: isThirdpart,
          nwbFlag: nwbFlag,
          ownpayType: ownpayType,
          paymentId: paymentId,
          treatmentCategory: treatmentCategory,
          treatmentId: treatmentId,
        },
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        if (serverType === 'requestString') {
          const data = strParse(res)
          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                baseData?.countryHospitalNb,
                baseData?.secretToken,
              ],
              message: '自费就医信息上传',
            },
            (call: any, err) => {
              if (!err) {
                selfMedicalInformationUploadSubmit(
                  'response',
                  certNo,
                  invoiceCheckCode,
                  invoiceCode,
                  invoiceNumber,
                  isThirdpart,
                  nwbFlag,
                  ownpayType,
                  paymentId,
                  treatmentCategory,
                  treatmentId,
                  elecSetlCertType,
                  JSON.stringify(call.netmessage)
                )
              } else {
                setShowStopAction(false)
                setSubmitLoading(false)
              }
            }
          )
        } else {
          setShowStopAction(true)
          setTimes(5)
          let countdown = 5 // 5秒
          const timerId = setInterval(() => {
            countdown--
            setTimes(countdown)
            // 更新消息文本，显示倒计时
            // 当倒计时结束时关闭消息
            if (countdown <= 0) {
              clearInterval(timerId)
              setShowStopAction(false)
              selfMedicalInformationUploadElecSetlCert(
                'requestString',
                elecSetlCertType,
                paymentInsurance,
                invoiceCheckCode,
                invoiceCode,
                uploadImgSrc.split(',')[1],
                detail?.invoiceDetails?.id,
                invoiceNumber,
                isThirdpart,
                ids?.paymentId
              )
            }
          }, 1000)
        }
      })
      .catch(() => {
        setShowStopAction(false)
        setSubmitLoading(false)
      })
  }

  // 医疗机构上传电子结算凭证信息结果查询
  const selfMedicalInformationUploadElecSetlCert = (
    serverType: serverType,
    elecSetlCertType: string,
    insuranceCode: string,
    invoiceCheckCode: string,
    invoiceCode: string,
    invoiceFile: string,
    invoiceId: string,
    invoiceNumber: string,
    isThirdpart: string,
    paymentId: string,
    responseString?: string
  ) => {
    setSubmitLoading(true)
    // 设置倒计时关闭消息

    dispatch(
      selfMedicalInformationUploadElecSetlCertAsync({
        serverType,
        body: {
          baseData: {
            ...baseData,
            responseString,
          },
          elecSetlCertType: elecSetlCertType,
          insuranceCode: insuranceCode,
          invoiceCheckCode: invoiceCheckCode,
          invoiceCode: invoiceCode,
          invoiceFile: invoiceFile,
          invoiceId: invoiceId,
          invoiceNumber: invoiceNumber,
          isThirdpart: isThirdpart,
          paymentid: paymentId,
        },
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        if (serverType === 'requestString') {
          const data = strParse(res)
          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                baseData?.countryHospitalNb,
                baseData?.secretToken,
              ],
              message: '医疗机构上传电子结算凭证信息结果查询',
            },
            (call: any, err) => {
              if (!err) {
                selfMedicalInformationUploadElecSetlCert(
                  'response',
                  elecSetlCertType,
                  insuranceCode,
                  invoiceCheckCode,
                  invoiceCode,
                  invoiceFile,
                  invoiceId,
                  invoiceNumber,
                  isThirdpart,
                  paymentId,
                  JSON.stringify(call.netmessage)
                )
              } else {
                setSubmitLoading(false)
              }
            }
          )
        } else {
          setSubmitLoading(false)
          onOk && onOk()
          form.resetFields()
        }
      })
      .catch(() => {
        setSubmitLoading(false)
      })
  }

  return (
    <>
      <Modal
        visible={visible}
        title='就医信息上传'
        width={1000}
        footer={null}
        onCancel={onCancel}
        className={styles.formModal}
      >
        <Form
          form={form}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 22 }}
          autoComplete='off'
          onFinish={(values) => {
            if (paymentInsurance == 'ZIFEI') {
              selfMedicalInformationUploadSubmit(
                'requestString',
                values.idCardNumber,
                values?.invoiceCheckCode,
                values?.invoiceCode,
                values?.invoiceNumber,
                isThirdpart,
                values?.nwbFlag,
                values?.ownpayType,
                ids?.paymentId,
                values?.treatmentCategory,
                ids?.treatmentId,
                values?.elecSetlCertType
              )
            }
          }}
        >
          <Row gutter={formGutter} style={{ marginRight: '10px' }}>
            <Col span={8} order={1}>
              <Form.Item label='患者姓名' name='name' {...firstColumnLayout}>
                {detail?.patient?.name}
              </Form.Item>
            </Col>
            {paymentInsurance == 'ZIFEI' ? (
              <Col span={8} order={2}>
                <Form.Item
                  label='证件号码'
                  name='idCardNumber'
                  {...secondColumnLayout}
                  rules={[
                    {
                      pattern: /(^\d{15}$)|(^\d{16}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
                      message: '证件号码格式错误',
                    },
                    {
                      max: 18,
                      message: '最长18位!',
                    },
                  ]}
                >
                  <Input placeholder='请输入证件号码' />
                </Form.Item>
              </Col>
            ) : (
              <Col span={8} order={2}>
                <Form.Item
                  label='证件号码'
                  name='idCardNumber'
                  {...secondColumnLayout}
                >
                  {detail?.patient?.idCardNumber}
                </Form.Item>
              </Col>
            )}

            <Col span={8} order={2}>
              <Form.Item
                label='就诊医生'
                name='treatmentDoctorName'
                {...thirdColumnLayout}
              >
                {detail?.treatment?.treatmentDoctorName}
              </Form.Item>
            </Col>
            <Col span={24} order={5}>
              <Form.Item
                label='诊断信息'
                name='disease'
                {...secondColumnLayout}
              >
                {detail?.treatment?.disease}
              </Form.Item>
            </Col>
            <Col span={8} order={6}>
              <Form.Item
                label='医疗类别'
                name='treatmentCategory'
                {...firstColumnLayout}
                rules={[
                  {
                    required: true,
                    message: '请选择医疗类别',
                  },
                ]}
              >
                <Select
                  disabled={true}
                  placeholder='医疗类别'
                  allowClear
                  // suffixIcon={<CaretDownFilled style={{ color: theme.tc3 }} />}
                >
                  <Select.Option value='0'>普通门诊</Select.Option>
                  <Select.Option value='1'>门诊特病</Select.Option>
                  <Select.Option value='2'>门诊慢病</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8} order={7}>
              <Form.Item
                label='新生儿标志'
                name='nwbFlag'
                {...secondColumnLayout}
                rules={[
                  {
                    required: true,
                    message: '请选择新生儿标志',
                  },
                ]}
              >
                <Select
                  placeholder='新生儿标志'
                  allowClear
                  // suffixIcon={<CaretDownFilled style={{ color: theme.tc3 }} />}
                >
                  <Select.Option value='0'>非新生儿</Select.Option>
                  <Select.Option value='1'>
                    新生儿(使用母亲身份证)
                  </Select.Option>
                  <Select.Option value='2'>
                    新生儿(使用本人身份证)
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8} order={8}>
              <Form.Item
                label='自费类型'
                name='ownpayType'
                {...thirdColumnLayout}
                rules={[
                  {
                    required: true,
                    message: '请选择自费类型',
                  },
                ]}
              >
                <Select
                  placeholder='自费类型'
                  allowClear
                  // suffixIcon={<CaretDownFilled style={{ color: theme.tc3 }} />}
                >
                  <Select.Option value='3'>医保不可报</Select.Option>
                  <Select.Option value='1'>待医保报销</Select.Option>
                  <Select.Option value='4'>放弃报销</Select.Option>
                  <Select.Option value='2'>意外伤害</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            {isThirdpart != 2 ? <><Col span={24} order={10}>
                <Form.Item
                  label='发票类型'
                  name='elecSetlCertType'
                  {...firstColumnLayout}
                  rules={[
                    {
                      required: isThirdpart == 1 ? true : false,
                      message: '请选择发票类型',
                    },
                  ]}
                >
                  <Select
                    placeholder='发票类型'
                    allowClear
                    style={{ width: '12.4rem' }}
                  >
                    <Select.Option value='2'>增值税发票</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8} order={11}>
                <Form.Item
                  label='发票代码'
                  name='invoiceCode'
                  {...firstColumnLayout}
                  rules={[
                    {
                      required: isThirdpart == 1 ? true : false,
                      message: '请输入发票代码',
                    },
                  ]}
                >
                  <Input placeholder='请输入发票代码' />
                </Form.Item>
              </Col>
              <Col span={8} order={12}>
              <Form.Item
              label='发票号码'
              name='invoiceNumber'
            {...secondColumnLayout}
              rules={[
            {
              required: isThirdpart == 1 ? true : false,
              message: '请输入发票号码',
            },
              ]}
              >
              <Input placeholder='请输入发票号码' />
              </Form.Item>
              </Col>
              <Col span={8} order={13}>
              <Form.Item
              label='发票校验码'
              name='invoiceCheckCode'
            {...thirdColumnLayout}
              rules={[
            {
              required: isThirdpart == 1 ? true : false,
              message: '请输入校验码',
            },
              ]}
              >
              <Input placeholder='请输入校验码' />
              </Form.Item>
              </Col>
              <Col span={8} order={14}>
              <Form.Item
              label='发票文件'
              name='rxFile'
            {...firstColumnLayout}
              rules={[
            {
              required: isThirdpart == 1 ? true : false,
              message: '请上传文件',
            },
              ]}
              >
              <div className={styles.uploadImg}>
              <Upload
              action={async (file: any) => {
              setFileName(file.name)
              setUploadImgSrc((await getBase64(file)) as string)

              return ''
            }}
              listType='picture'
              maxCount={1}
              showUploadList={false}
              accept={'.pdf'}
              beforeUpload={(file) => {
              if (file.type.includes('pdf')) {
              if (file.size / 1024 / 1024 < 1) {
              return true
            } else {
              notification.info({
              message: 'PDF文件大于1M,请重新选择!',
            })
              return false
            }
            }
              notification.info({ message: '请上传PDF文件' })
              return false
            }}
              >
              <Button icon={<PlusOutlined />}>上传附件</Button>
              </Upload>
            {uploadImgSrc && (
              <div style={{ marginTop: 15 }}>{fileName}</div>
              )}
              </div>
              </Form.Item>
              </Col> </> : <><Col order={10} style={{marginLeft:"20px"}}>电子发票患者，无需额外登记发票信息。</Col></>}

          </Row>
          <Row
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '20px',
              marginRight: '24px',
            }}
          >
            <Button
              style={{ marginRight: '20px', color: '#666666' }}
              onClick={() => {
                onCancel()
                form.resetFields()
              }}
            >
              取消
            </Button>
            <Button type='primary' htmlType='submit' loading={submitLoading}>
              确定
            </Button>
          </Row>
        </Form>
        {showStopAction && (
          <div className={styles.stopAction}>
            正在准备电子票据上传，请等待{times}
          </div>
        )}
      </Modal>
    </>
  )
}
