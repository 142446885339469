/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-07-24 15:15:37
 * @LastEditors: linxi
 * @LastEditTime: 2024-10-18 16:53:05
 */

import moment from 'moment'
import {
  DateTimeFormatSimple,
  DateTimeFormatSimpleOnly,
  DateTimeFormatSimpleOnlyOne,
} from './datetime'

// 购入入库
export function printKfywmxBody(data: any) {
  const userName = data?.userName
  const tenantName = data?.tenantName
  const datas = data?.dataList
  const category = data?.category

  let sjDatas = ''
  let receiptDetailTotalStockInAmountAll = 0
  let receiptDetailTotalRetailAmountAll = 0
  let shouldPayMoneyAmountAll = 0
  let realPayMoneyAmountAll = 0
  let stockInAmount = 0
  let retailAmount = 0
  for (let i = 0; i < datas.length; i++) {
    const list = datas?.[i]?.value
    receiptDetailTotalStockInAmountAll = Number(
      datas?.[i]?.receiptDetailTotalStockInAmountAll
    )
    receiptDetailTotalRetailAmountAll = Number(
      datas?.[i]?.receiptDetailTotalRetailAmountAll
    )
    shouldPayMoneyAmountAll = Number(
      datas?.[i]?.shouldPayMoneyAmountAll
    )
    realPayMoneyAmountAll = Number(
      datas?.[i]?.realPayMoneyAmountAll
    )
    stockInAmount += (category == '2' ? Number(datas?.[i]?.shouldPayMoneyAmountAll) : Number(datas?.[i]?.receiptDetailTotalStockInAmountAll))
    retailAmount += (category == '2' ? Number(datas?.[i]?.realPayMoneyAmountAll) :Number(datas?.[i]?.receiptDetailTotalRetailAmountAll))
    sjDatas +=
      '<div style="font-size: 8px;display:table;width:100%;clear: left;border-right: 1px solid #000;border-left: 1px solid #000;border-bottom: 1px solid #000">' +
      '<div style="word-break:break-all;width: 15%;display: table-cell;vertical-align: middle;text-align:left;box-sizing: border-box;border-right: 1px solid #000;padding: 0px 2px;">' +
      datas?.[i]?.name +
      '</div>' +
      '<div style="display:table-cell;word-break:break-all;width: 85%;text-align:center;box-sizing: border-box;">'
    for (let j = 0; j < list.length; j++) {
      // jjfsjeSum += Number(jjfsjes[j])
      // lsjfsjeSum += Number(lsjfsjes[j])
      sjDatas +=
        '<div style="display:table;width:100%;clear: left;border-bottom: 1px solid #000;">' +
        '<div style="word-break:break-all;width: 16%;display: table-cell;vertical-align: middle;text-align:center;box-sizing: border-box;border-right: 1px solid #000;padding: 0px 2px;">' +
        list?.[j]?.putNumber +
        '</div>' +
        '<div style="word-break:break-all;width: 16%;display: table-cell;vertical-align: middle;text-align:center;box-sizing: border-box;border-right: 1px solid #000;padding: 0px 2px;">' +
        list?.[j]?.storehouseName +
        '</div>' +
        '<div style="word-break:break-all;width: 12%;display: table-cell;vertical-align: middle;text-align:center;box-sizing: border-box;border-right: 1px solid #000;padding: 0px 2px;">' +
        (category == '2' ? '购入退库':'购入入库') +
        '</div>' +
        '<div style="word-break:break-all;width: 14%;display: table-cell;vertical-align: middle;text-align:center;box-sizing: border-box;border-right: 1px solid #000;padding: 0px 2px;">' +
        list?.[j]?.departmentName +
        '</div>' +
        '<div style="word-break:break-all;width: 14%;display: table-cell;vertical-align: middle;text-align:center;box-sizing: border-box;border-right: 1px solid #000;padding: 0px 2px;">' +
        moment(list?.[j]?.createTime).format(DateTimeFormatSimpleOnly) +
        '</div>' +
        '<div style="word-break:break-all;width: 14%;display: table-cell;vertical-align: middle;text-align:right;box-sizing: border-box;border-right: 1px solid #000;padding: 0px 2px;">' +
        (category == '2' ? list?.[j]?.shouldPayMoney?.toFixed(2):list?.[j]?.receiptDetailTotalStockInAmount?.toFixed(2)) +
        '</div>' +
        '<div style="word-break:break-all;width: 14%;display: table-cell;vertical-align: middle;text-align:right;box-sizing: border-box;padding: 0px 2px;">' +
        (category == '2' ? list?.[j]?.realPayMoney?.toFixed(2):list?.[j]?.receiptDetailTotalRetailAmount?.toFixed(2)) +
        '</div>' +
        '</div>'
    }
    sjDatas +=
      '<div style="display:table;width:100%;clear: left;">' +
      '<div style="font-weight: bold;word-break:break-all;width: 72%;display: table-cell;vertical-align: middle;text-align:right;box-sizing: border-box;border-right: 1px solid #000">' +
      '小计:' +
      '</div>' +
      '<div style="word-break:break-all;width:14%;display: table-cell;vertical-align: middle;text-align:right;box-sizing: border-box;border-right: 1px solid #000;padding: 0px 2px;">' +
      (category == '2' ? shouldPayMoneyAmountAll?.toFixed(2) :receiptDetailTotalStockInAmountAll?.toFixed(2)) +
      '</div>' +
      '<div style="word-break:break-all;width: 14%;display: table-cell;vertical-align: middle;text-align:right;box-sizing: border-box;padding: 0px 2px;">' +
      (category == '2' ? realPayMoneyAmountAll?.toFixed(2) :receiptDetailTotalRetailAmountAll?.toFixed(2)) +
      '</div>' +
      '</div>' +
      '</div>' +
      '</div>'
  }

  const content =
    '<div style="width: 97%;padding: 0 20px;text-align: center;">' +
    '    <div style="text-align: center;margin-top: 5px;margin-bottom: 5px;width:100%">' +
    '       <h5 style="display: inline-block;text-align: center;margin-top: 5px;margin-bottom: 5px;width: 20%;border-bottom: 1px solid #000;">' +
    '          <div style="text-align: center;width:100%;display: inline-flex;">' +
    '             <div style="display: inline-block;width: 20%;">库</div>\n' +
    '             <div style="display: inline-block;width: 20%;">存</div>\n' +
    '             <div style="display: inline-block;width: 20%;">业</div>\n' +
    '             <div style="display: inline-block;width: 20%;">务</div>\n' +
    '             <div style="display: inline-block;width: 20%;">单</div>\n' +
    '    </div></h5>' +
    ' </div>' +
    '       <div style="text-align: left;padding-bottom: 8px;font-weight: 600;font-size: 10px">\n' +
    '    <div style="display: inline-block;width: 75%;">\n' +
    '       单位: <font style="font-weight: 300">' +
    tenantName +
    '</font>\n' +
    '    </div>\n' +
    '        <div style="display: inline-block;width: 24%;text-align: right;">金额单位:<font style="font-weight: 300;">元</font></div>\n' +
    '    </div>\n' +
    '<div style="font-size: 8px;font-family: SimSun;">' +
    '<div style="font-size:8px;display:table;width:100%;clear: left;border: 1px solid #000;">' +
    '<div style="font-weight: bold;word-break:break-all;width: 15%;display: table-cell;vertical-align: middle;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '对方部门' +
    '</div>' +
    '<div style="display:table-cell;word-break:break-all;width: 85%;text-align:center;box-sizing: border-box;">' +
    '<div style="display:table;width:100%;clear: left;">' +
    '<div style="font-weight: bold;word-break:break-all;width: 16%;display: table-cell;vertical-align: middle;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '业务单号' +
    '</div>' +
    '<div style="font-weight: bold;word-break:break-all;width: 16%;display: table-cell;vertical-align: middle;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '库房名称' +
    '</div>' +
    '<div style="font-weight: bold;word-break:break-all;width: 12%;display: table-cell;vertical-align: middle;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '业务类型' +
    '</div>' +
    '<div style="font-weight: bold;word-break:break-all;width: 14%;display: table-cell;vertical-align: middle;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '业务部门' +
    '</div>' +
    '<div style="font-weight: bold;word-break:break-all;width: 14%;display: table-cell;vertical-align: middle;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '发生日期' +
    '</div>' +
    '<div style="font-weight: bold;word-break:break-all;width: 14%;display: table-cell;vertical-align: middle;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    (category =='2' ?  '应收金额':'进价金额') +
    '</div>' +
    '<div style="font-weight: bold;word-break:break-all;width: 14%;display: table-cell;vertical-align: middle;text-align:center;box-sizing: border-box">' +
    (category =='2' ?  '实收金额':'售价金额') +
    '</div>' +
    '</div>' +
    '</div>' +
    '</div>' +
    sjDatas +
    '<div style="font-size:8px;display:table;width:100%;clear: left;border-bottom: 1px solid #000;border-left: 1px solid #000;border-right: 1px solid #000;">' +
    '<div style="word-break:break-all;width: 15%;display: table-cell;vertical-align: middle;text-align:center;box-sizing: border-box;">' +
    '</div>' +
    '<div style="display:table-cell;word-break:break-all;width: 85%;text-align:center;box-sizing: border-box;">' +
    '<div style="display:table;width:100%;clear: left;">' +
    '<div style="font-weight: bold;word-break:break-all;width: 72.1%;display: table-cell;vertical-align: middle;text-align:right;box-sizing: border-box;border-right: 1px solid #000">' +
    '合计:' +
    '</div>' +
    '<div style="word-break:break-all;width:14%;display: table-cell;vertical-align: middle;text-align:right;box-sizing: border-box;border-right: 1px solid #000;padding: 0px 2px;">' +
    stockInAmount?.toFixed(2) +
    '</div>' +
    '<div style="word-break:break-all;width: 14%;display: table-cell;vertical-align: middle;text-align:right;box-sizing: border-box;padding: 0px 2px;">' +
    retailAmount?.toFixed(2) +
    '</div>' +
    '</div>' +
    '</div>' +
    '</div>' +
    '</div>' +
    '<div style="padding-top: 20px;clear: both;font-size: 10px;font-weight: 600;">' +
    '<div style="display: inline-block;width: 30%;text-align: left;">单位负责人:</div>' +
    '<div style="display: inline-block;width: 30%;text-align: left;">仓管员:</div>' +
    '<div style="display: inline-block;width: 35%;text-align: left;">财&emsp;&emsp;务:</div>' +
    '</div>' +
    '<div style="padding-top: 20px;clear: both;font-size: 10px;font-weight: 600;">' +
    '<div style="display: inline-block;width: 30%;text-align: left;">审&emsp;&emsp;&emsp;核:</div>' +
    '<div style="display: inline-block;width: 30%;text-align: left;">制单人:<font style="font-weight: 300">' +
    userName +
    '</font></div>' +
    '<div style="display: inline-block;width: 35%;text-align: left;">制单日期:<font style="font-weight: 300;">' +
    moment().format(DateTimeFormatSimpleOnlyOne) +
    '</font></div>' +
    '</div>' +
    '</div>'

  return content
}

//购入入库详情入库单
export function printBody(data: any) {
  const userName = data?.userName
  const tenantName = data?.tenantName
  const detail = data?.detail
  const datas = data?.dataList
  let sjDatas = ''
  let sumZsl = 0
  let sumJjJe = 0
  let sumSjJe = 0
  let sumJxce = 0
  const date = new Date()

  for (let i = 0; i < datas.length; i++) {
    sjDatas +=
      '<div style="font-size: 8px;display:table;width:100%;clear: left;border-right: 1px solid #000;border-left: 1px solid #000;border-bottom: 1px solid #000">' +
      '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width: 10%;display: table-cell;vertical-align: middle;text-align:left;box-sizing: border-box;border-right: 1px solid #000">' +
      '                ' +
      datas[i].name +
      '            </div>' +
      '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width: 6%;display: table-cell;vertical-align: middle;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
      '                ' +
      datas[i].packSpec +
      '            </div>' +
      '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width: 10%;display: table-cell;vertical-align: middle;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
      '                ' +
      datas[i].tenantSupplierName +
      '            </div>' +
      '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width: 6%;display: table-cell;vertical-align: middle;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
      '                ' +
      datas[i].accountUnit +
      '            </div>' +
      '             <div style="padding-left:2px;padding-right:2px;word-break:break-all;width:6%;display: table-cell;vertical-align: middle;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
      '                ' +
      datas[i].batchNo +
      '            </div>' +
      '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width:9%;display: table-cell;vertical-align: middle;text-align:right;box-sizing: border-box;border-right: 1px solid #000">' +
      '                ' +
      datas[i].productDate +
      '            </div>' +
      '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width:6%;display: table-cell;vertical-align: middle;text-align:right;box-sizing: border-box;border-right: 1px solid #000">' +
      '                ' +
      datas[i].count +
      '            </div>' +
      '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width:8%;display: table-cell;vertical-align: middle;text-align:right;box-sizing: border-box;border-right: 1px solid #000">' +
      '                ' +
      datas[i].unitPrice +
      '            </div>' +
      '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width:8%;display: table-cell;vertical-align: middle;text-align:right;box-sizing: border-box;border-right: 1px solid #000">' +
      '                ' +
      datas[i].unitPriceAllPrice +
      '            </div>' +
      '           <div style="padding-left:2px;padding-right:2px;word-break:break-all;width:8%;display: table-cell;vertical-align: middle;text-align:right;box-sizing: border-box;border-right: 1px solid #000">' +
      '                ' +
      datas[i].retailPrice +
      '           </div>' +
      '           <div style="padding-left:2px;padding-right:2px;word-break:break-all;width:8%;display: table-cell;vertical-align: middle;text-align:right;box-sizing: border-box;border-right: 1px solid #000">' +
      '                ' +
      datas[i].retailPriceAllPrice +
      '           </div>' +
      '           <div style="padding-left:2px;padding-right:2px;word-break:break-all;width:6%;display: table-cell;vertical-align: middle;text-align:right;box-sizing: border-box;border-right: 1px solid #000">' +
      '              ' +
      datas[i].differencePrice +
      '           </div>' +
      '            <div style="padding-left:2px;padding-right:2px;text-align:center;overflow-wrap:break-word;box-sizing: border-box;word-wrap: break-word;word-break:normal;width:9%;display: table-cell;vertical-align: middle;">' +
      '                ' +
      datas[i].deadline +
      '            </div>' +
      '        </div>'
    sumZsl += Number(datas[i].count)
    sumJjJe += Number(datas[i].unitPriceAllPrice)
    sumSjJe += Number(datas[i].retailPriceAllPrice)
    const jxce: any = Number(datas[i].differencePrice)
    sumJxce += Number(datas[i].differencePrice)
  }
  // const userBean = getUserBean()
  const content =
    '<div style="margin-left: 10px;margin-right: 10px">' +
    '    <h5 style="text-align: center;margin-bottom: 5px;">' +
    tenantName +
    '</h5>' +
    '    <h6 style="text-align: center;margin-top: 5px;margin-bottom: 5px;">入&nbsp;&nbsp;库&nbsp;&nbsp;单</h6>' +
    '    <div style="font-size:10px;display:flex;text-align: left">' +
    '        <div style="float: left;width:20%;padding-left: 10px">库房:<font style="word-break:break-all;padding-left: 10px">' +
    detail?.storehouseName +
    '</font></div>' +
    '       <div style="float: left;width:40%;padding-left: 10px">购入单位:<font style="word-break:break-all;padding-left: 10px">' +
    detail?.supplierName +
    '</font></div>' +
    '       <div style="float: left;width:38%;padding-right: 10px;text-align: right">购入日期:<font style="word-break:break-all;padding-left: 10px">' +
    detail?.recepitsDate +
    '</font></div>' +
    '    </div>' +
    '    <div style="font-size:10px;display:flex;text-align: left">' +
    '       <div style="float: left;width:20%;padding-left: 10px">业务单号:<font style="word-break:break-all;padding-left: 10px">' +
    detail.putNumber +
    '</font></div>' +
    '       <div style="float: left;width:40%;padding-left: 10px">业务人员:<font style="word-break:break-all;padding-left: 10px">' +
    detail?.userName +
    '</font></div>' +
    '    </div>' +
    '    <div style="font-size:8px;display:table;width:100%;align-items:center;clear: left;border: 1px solid #000">' +
    '        <div style="display: table-cell;vertical-align: middle;word-break:break-all;width: 10%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '            名称' +
    '        </div>' +
    '       <div style="display: table-cell;vertical-align: middle;word-break:break-all;width: 6%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '           规格' +
    '       </div>' +
    '        <div style="display: table-cell;vertical-align: middle;word-break:break-all;width: 10%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '            生产厂家' +
    '        </div>' +
    '        <div style="display: table-cell;vertical-align: middle;word-break:break-all;width: 6%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '            单位' +
    '        </div>' +
    '       <div style="display: table-cell;vertical-align: middle;word-break:break-all;width: 6%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '           批次号' +
    '       </div>' +
    '       <div style="display: table-cell;vertical-align: middle;word-break:break-all;width: 9%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '           生产日期' +
    '       </div>' +
    '        <div style="display: table-cell;vertical-align: middle;word-break:break-all;width: 6%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '            数量' +
    '        </div>' +
    '        <div style="display: table-cell;vertical-align: middle;width: 16%;border-right: 1px solid #000;box-sizing: border-box;">' +
    '            <div style="word-break:break-all;width: 100%;float: left;text-align:center;">' +
    '                以入库价计' +
    '            </div>' +
    '            <div style="display: table-cell;vertical-align: middle;width: 100%;float: left;text-align:center;border-top: 1px solid #000;">' +
    '                <div style="word-break:break-all;width:50%;float: left;text-align:center;">单价</div>' +
    '                <div style="word-break:break-all;width:50%;float: left;text-align:center;">金额</div>' +
    '            </div>' +
    '        </div>' +
    '        <div style="display: table-cell;vertical-align: middle;width: 16%;border-right: 1px solid #000;box-sizing: border-box;">' +
    '            <div style="word-break:break-all;width: 100%;float: left;text-align:center;">' +
    '                以出库价计' +
    '            </div>' +
    '            <div style="display: table-cell;vertical-align: middle;width: 100%;float: left;text-align:center;border-top: 1px solid #000;">' +
    '                <div style="word-break:break-all;width:50%;float: left;text-align:center">单价</div>' +
    '                <div style="word-break:break-all;width:50%;float: left;text-align:center;">金额</div>' +
    '            </div>' +
    '        </div>' +
    '        <div style="display: table-cell;vertical-align: middle;word-break:break-all;width: 6%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '            进销差额' +
    '        </div>' +
    '        <div style="display: table-cell;vertical-align: middle;word-break:break-all;width: 9%;text-align:center;">' +
    '            有效期至' +
    '        </div>' +
    '    </div>' +
    sjDatas +
    '      <div style="font-size: 8px;display:table;width:100%;clear: left;border-right: 1px solid #000;border-left: 1px solid #000;border-bottom: 1px solid #000">' +
    '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width: 38%;display: table-cell;vertical-align: middle;text-align:right;box-sizing: border-box;border-right: 1px solid #000">' +
    '                合计：' +
    '            </div>' +
    '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width: 9%;display: table-cell;vertical-align: middle;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '                ' +
    '            </div>' +
    '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width: 6%;display: table-cell;vertical-align: middle;text-align:right;box-sizing: border-box;border-right: 1px solid #000">' +
    '                ' +
    sumZsl?.toFixed(2) +
    '            </div>' +
    '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width: 8%;display: table-cell;vertical-align: middle;text-align:right;box-sizing: border-box;border-right: 1px solid #000">' +
    '                ' +
    '            </div>' +
    '             <div style="padding-left:2px;padding-right:2px;word-break:break-all;width:7.9%;display: table-cell;vertical-align: middle;text-align:right;box-sizing: border-box;border-right: 1px solid #000">' +
    '                ' +
    sumJjJe?.toFixed(2) +
    '            </div>' +
    '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width:7.95%;display: table-cell;vertical-align: middle;text-align:right;box-sizing: border-box;border-right: 1px solid #000">' +
    '                ' +
    '            </div>' +
    '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width:8%;display: table-cell;vertical-align: middle;text-align:right;box-sizing: border-box;border-right: 1px solid #000">' +
    '                ' +
    sumSjJe?.toFixed(2) +
    '            </div>' +
    '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width:6%;display: table-cell;vertical-align: middle;text-align:right;box-sizing: border-box;border-right: 1px solid #000">' +
    '                ' +
    parseFloat(sumJxce as any)?.toFixed(2) +
    '            </div>' +
    '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width:9%;display: table-cell;vertical-align: middle;text-align:center;box-sizing: border-box;">' +
    '                ' +
    '            </div>' +
    '        </div>' +
    '    <div>' +
    '        <div style="font-size:8px;display:table;width: 100%;clear: left;border-right: 1px solid #000;border-left: 1px solid #000;border-bottom: 1px solid #000">' +
    '            <div style="word-break:break-all;width: 90px;display: table-cell;vertical-align: middle;text-align:center;border-right: 1px solid #000">' +
    '                入库单备注' +
    '            </div>' +
    '            <div style="word-break:break-all;width: calx(100% - 90px);display: table-cell;vertical-align: middle;text-align:center;">' +
    '                ' +
    detail?.remark +
    '            </div>' +
    '        </div>' +
    '    </div>' +
    '    <div style="font-size:8px;display:flex;padding-top: 10px">' +
    '        <div style="word-break:break-all;width: 25%;padding-left:25px;float: left;">单位负责人:<span style="padding-left: 20px;"></span></div>' +
    '        <div style="word-break:break-all;width: 25%;padding-left:25px;float: left;">采购人:<span style="padding-left: 20px;"></span></div>' +
    '        <div style="word-break:break-all;width: 25%;padding-left:25px;float: left;">仓管员:<span style="padding-left: 20px;"></span></div>' +
    '        <div style="word-break:break-all;width:25%;float: left;text-align: left;">财务:</div>' +
    '    </div>' +
    '    <div style="font-size:8px;display:flex;padding-top: 10px">' +
    '        <div style="word-break:break-all;width: 25%;padding-left:25px;float: left;">审核:<span style="padding-left: 20px;"></span></div>' +
    '        <div style="word-break:break-all;width: 25%;padding-left:25px;float: left;">验收人:<span style="padding-left: 20px;"></span></div>' +
    '        <div style="word-break:break-all;width: 25%;padding-left:25px;float: left;">制单人:<span style="padding-left: 20px;">' +
    userName +
    '</span></div>' +
    '        <div style="word-break:break-all;width:25%;float: left;text-align: left;">制单日期:' +
    moment().format(DateTimeFormatSimpleOnlyOne) +
    '</div>' +
    '    </div>' +
    '</div>'

  return content
}

//报损详情打印
export function printBodyBS(data: any, storehouseTag: any) {
  const userName = data?.userName
  const tenantName = data?.tenantName
  const detail = data?.detail
  const datas = data?.dataList
  let sjDatas = ''
  let allInvCount = 0
  let allCount = 0
  let allUnitPrice = 0
  let dataUnitPrice = 0
  let AllDataUnitPrice = 0
  

  for (let i = 0; i < datas.length; i++) {
    const dataInvCount = storehouseTag > 0 ? (datas[i]?.invCount > 0 ? datas[i]?.invCount : 0) + (datas[i]?.count > 0 ? datas[i]?.count : 0) : (datas[i]?.invCount > 0 ? datas[i]?.invCount : 0)
    allInvCount += Number(dataInvCount)
    allUnitPrice += Number(datas[i]?.unitPrice > 0 ? datas[i]?.unitPrice : 0)
    allCount += Number(datas[i]?.count > 0 ? datas[i]?.count : 0)
    dataUnitPrice = datas[i].unitPrice * datas[i].count ? parseFloat(((datas[i].unitPrice * datas[i].count) as unknown) as string) : 0
    AllDataUnitPrice += Number(dataUnitPrice)

    sjDatas +=
      '<div style="display: flex;clear: left;font-weight: 300">\n' +
      '        <div style="display:-webkit-flex;align-items:center;justify-content:center;word-break:break-all;width: 5%;box-sizing: border-box;\n' +
      '                border-right: 1px solid #000;border-bottom: 1px solid #000;border-left: 1px solid #000;padding: 0px 2px;">\n' +
      (Number(i) + 1) +
      '        </div>\n' +
      '        <div style="display:-webkit-flex;align-items:center;justify-content:center;word-break:break-all;width: 13%;float: center;box-sizing: border-box;border-right: 1px solid #000;border-bottom: 1px solid #000;">\n' +
      datas[i].name +
      '        </div>\n' +
      '        <div style="display:-webkit-flex;align-items:center;justify-content:center;word-break:break-all;width: 16%;float: center;box-sizing: border-box;border-right: 1px solid #000;border-bottom: 1px solid #000;padding: 0px 2px;">\n' +
      datas[i].productInfo +
      '        </div>\n' +
      '        <div style="display:-webkit-flex;align-items:center;justify-content:center;word-break:break-all;width: 11%;float: center;box-sizing: border-box;border-right: 1px solid #000;border-bottom: 1px solid #000;">\n' +
      datas[i].batchNo +
      '        </div>\n' +
      '        <div style="display:-webkit-flex;align-items:center;justify-content:center;word-break:break-all;width: 11%;float: center;box-sizing: border-box;border-right: 1px solid #000;border-bottom: 1px solid #000;">\n' +
      datas[i].productDate +
      '        </div>\n' +
      '        <div style="display:-webkit-flex;align-items:center;justify-content:center;word-break:break-all;width: 11%;float: center;box-sizing: border-box;border-right: 1px solid #000;border-bottom: 1px solid #000;">\n' +
      datas[i].deadline +
      '        </div>\n' +
      '        <div style="display:-webkit-flex;align-items:center;justify-content:center;word-break:break-all;width: 11%;float: center;box-sizing: border-box;border-right: 1px solid #000;border-bottom: 1px solid #000;padding: 0px 2px;">\n' +
      dataInvCount +
      '        </div>\n' +
      '        <div style="display:-webkit-flex;align-items:center;justify-content:center;word-break:break-all;width: 11%;float: center;box-sizing: border-box;border-right: 1px solid #000;border-bottom: 1px solid #000;padding: 0px 2px;">\n' +
      datas[i].count +
      '        </div>\n' +
      '        <div style="display:-webkit-flex;align-items:center;justify-content:center;word-break:break-all;width: 11%;float: center;box-sizing: border-box;border-right: 1px solid #000;border-bottom: 1px solid #000;padding: 0px 2px;">\n' +
      dataUnitPrice.toFixed(2) +
      '        </div>\n' +
      '    </div>'
  }
  const content =
    '<div style="width: 97%;padding: 0 20px;text-align: center;">\n' +
    '<h6 style="text-align: center;margin-bottom: 5px;">' +
    tenantName +
    '</h6>' +
    '<div style="text-align: center;margin-top: 5px;margin-bottom: 5px;width:100%">' +
    '<h5 style="display: inline-block;text-align: center;margin-top: 5px;margin-bottom: 5px;width: 20%;border-bottom: 1px solid #000;">' +
    '<div style="text-align: center;width:100%;display: inline-flex;">' +
    ' <div style="display: inline-block;width: 33%;">报</div>\n' +
    ' <div style="display: inline-block;width: 33%;">损</div>\n' +
    ' <div style="display: inline-block;width: 33%;">单</div>\n' +
    ' </div></h5>' +
    '</div>' +
    '    <div style="text-align: left;padding-bottom: 8px;font-weight: 600;font-size: 10px;">\n' +
    '        <div style="display: inline-block;width: 45%;">库房名称 : <font style="font-weight: 300">' +
    detail?.storehouseName +
    '</font></div>\n' +
    '        <div style="display: inline-block;width: 35%;">业务部门 : <font style="font-weight: 300">' +
    detail?.departmentName +
    '</font></div>\n' +
    '        <div style="display: inline-block;width: 18%;text-align: left;">业务人员 : <font style="font-weight: 300;">' +
    detail?.userName +
    '</font></div>\n' +
    '    </div>\n' +
    '    <div style="text-align: left;padding-bottom: 8px;font-weight: 600;font-size: 10px;">\n' +
    '        <div style="display: inline-block;width: 45%;">业务日期 :<font style="font-weight: 300">' +
    detail?.recepitsDate +
    '</font></div>\n' +
    '        <div style="display: inline-block;width: 35%;">备注 :<font style="font-weight: 300">' +
    detail?.remark +
    '</font></div>\n' +
    '    </div>\n' +
    '<div style="font-size: 8px;font-family: SimSun;">' +
    '    <div style="display: flex;clear: left;border-top: 1px solid #000;font-weight: 600;">\n' +
    '        <div style="word-break:break-all;width: 5%;float: center;box-sizing: border-box;\n' +
    '                border-right: 1px solid #000;border-bottom: 1px solid #000;border-left: 1px solid #000;">\n' +
    '            序号\n' +
    '        </div>\n' +
    '        <div style="word-break:break-all;width: 13%;float: center;box-sizing: border-box;border-right: 1px solid #000;border-bottom: 1px solid #000;">\n' +
    '            名称\n' +
    '        </div>\n' +
    '        <div style="word-break:break-all;width: 16%;float: center;box-sizing: border-box;border-right: 1px solid #000;border-bottom: 1px solid #000;">\n' +
    '            产品信息\n' +
    '        </div>\n' +
    '        <div style="word-break:break-all;width: 11%;float: center;box-sizing: border-box;border-right: 1px solid #000;border-bottom: 1px solid #000;">\n' +
    '            批号\n' +
    '        </div>\n' +
    '        <div style="word-break:break-all;width: 11%;float: center;box-sizing: border-box;border-right: 1px solid #000;border-bottom: 1px solid #000;">\n' +
    '            生产日期\n' +
    '        </div>\n' +
    '        <div style="word-break:break-all;width: 11%;float: center;box-sizing: border-box;border-right: 1px solid #000;border-bottom: 1px solid #000;">\n' +
    '            有效期\n' +
    '        </div>\n' +
    '        <div style="word-break:break-all;width: 11%;float: center;box-sizing: border-box;border-right: 1px solid #000;border-bottom: 1px solid #000;">\n' +
    '            库存数量\n' +
    '        </div>\n' +
    '        <div style="word-break:break-all;width: 11%;float: center;box-sizing: border-box;border-right: 1px solid #000;border-bottom: 1px solid #000;">\n' +
    '            报损数量\n' +
    '        </div>\n' +
    '        <div style="word-break:break-all;width: 11%;float: center;box-sizing: border-box;border-right: 1px solid #000;border-bottom: 1px solid #000;">\n' +
    '            报损金额(进价)\n' +
    '        </div>\n' +
    '    </div>\n' +
    sjDatas +
    '    <div style="display: flex;clear: left;font-weight: 600;">\n' +
    '        <div style="display:-webkit-flex;align-items:center;justify-content:flex-end;word-break:break-all;width: 67%;float: left;box-sizing: border-box;text-align: right;\n' +
    '                border-right: 1px solid #000;border-bottom: 1px solid #000;border-left: 1px solid #000;">\n' +
    '            合计\n' +
    '        </div>\n' +
    '        <div style="display:-webkit-flex;align-items:center;justify-content:center;word-break:break-all;width: 11%;float: left;box-sizing: border-box;font-weight: bold;\n' +
    '            border-right: 1px solid #000;border-bottom: 1px solid #000;padding: 0px 2px;"><font style="font-weight: 300;">' +
    allInvCount +
    '     </font> </div>\n' +
    '        <div style="display:-webkit-flex;align-items:center;justify-content:center;word-break:break-all;width: 11%;float: left;box-sizing: border-box;font-weight: bold;\n' +
    '            border-right: 1px solid #000;border-bottom: 1px solid #000;padding: 0px 2px;"><font style="font-weight: 300;">' +
    allCount +
    '      </font>  </div>\n' +
    '        <div style="display:-webkit-flex;align-items:center;justify-content:center;word-break:break-all;width: 11%;float: left;box-sizing: border-box;font-weight: bold;\n' +
    '            border-right: 1px solid #000;border-bottom: 1px solid #000;padding: 0px 2px;"><font style="font-weight: 300;">' +
    AllDataUnitPrice?.toFixed(2) +
    '      </font>  </div>\n' +
    '</div>\n' +
    '    <div style="padding-top: 5px;display:flex;clear: left;width: 100%;float: left;font-weight: 600;font-size: 10px;">\n' +
    '        <div style="display: inline-block;width: 33%;text-align: left;">单位负责人:</div>\n' +
    '        <div style="display: inline-block;width: 33%;text-align: left;">仓管员:</div>\n' +
    '        <div style="display: inline-block;width: 33%;text-align: left;">财&emsp;&emsp;务:</div>\n' +
    '    </div>\n' +
    '    <div style="padding-top: 5px;display:flex;clear: left;width: 100%;float: left;font-weight: 600;font-size: 10px;">\n' +
    '        <div style="display: inline-block;width: 33%;text-align: left;">审&emsp;&emsp;&emsp;核:</div>\n' +
    '        <div style="display: inline-block;width: 33%;text-align: left;">制单人:<font style="font-weight: 300">' +
    userName +
    '</font></div>\n' +
    '        <div style="display: inline-block;width: 33%;text-align: left;">制单日期:<font style="font-weight: 300">' +
    moment().format(DateTimeFormatSimpleOnlyOne) +
    '</font></div>\n' +
    '    </div>\n' +
    '</div>'
  return content
}

//四舍五入保留2位小数（不够位数，则用0替补）
export function keepTwoDecimalFull(num: any) {
  num = num - 0
  let result = parseFloat(num)
  if (isNaN(result)) {
    console.info('传递参数错误，请检查！')
    return false
  }
  result = Math.round(num * 10000) / 10000
  let s_x = result.toString()
  let pos_decimal = s_x.indexOf('.')
  if (pos_decimal < 0) {
    pos_decimal = s_x.length
    s_x += '.'
  }
  while (s_x.length <= pos_decimal + 4) {
    s_x += '0'
  }
  return s_x
}

export function tableMoneyFormat(data: any) {
  return data === undefined ? '0.00' : keepTwoDecimalFull(data - 0)
}

//领用出库详情
export function printBodySeven(data: any) {
  const userName = data?.userName
  const tenantName = data?.tenantName
  const detail = data?.detail
  const datas = data?.dataList
  const storehouseTag = detail?.storehouseTag
  let sjDatas = ''
  let allInvCount = 0
  let allCount = 0
  let allUnitPrice = 0
  let allRetailPrice = 0
  let allUnitPriceCount = 0
  let allRetailPriceCount = 0

  for (let i = 0; i < datas.length; i++) {
    const invCounts = storehouseTag
      ? datas[i].invCount + datas[i].count
      : datas[i].invCount
    allInvCount += Number(invCounts > 0 ? invCounts : 0)
    allUnitPrice += Number(datas[i]?.unitPrice > 0 ? datas[i]?.unitPrice : 0)
    allRetailPrice += Number(
      datas[i]?.retailPrice > 0 ? datas[i]?.retailPrice : 0
    )
    allCount += Number(datas[i]?.count > 0 ? datas[i]?.count : 0)
    allUnitPriceCount += Number(
      datas[i]?.lyckIcludePrice > 0 ? datas[i]?.lyckIcludePrice : 0
    )
    allRetailPriceCount += Number(
      datas[i]?.lyckOutIcludePrice > 0 ? datas[i]?.lyckOutIcludePrice : 0
    )
    sjDatas +=
      '<div style="display: flex;clear: left;font-weight: 300">\n' +
      '        <div style="display:-webkit-flex;align-items:center;word-break:break-all;width: 10%;float: left;box-sizing: border-box;\n' +
      '                border-right: 1px solid #000;border-bottom: 1px solid #000;border-left: 1px solid #000;padding: 0px 2px;">\n' +
      datas[i].name +
      '        </div>\n' +
      '        <div style="display:-webkit-flex;align-items:center;justify-content:center;word-break:break-all;width: 6%;float: left;box-sizing: border-box;border-right: 1px solid #000;border-bottom: 1px solid #000;">\n' +
      datas[i].packSpec +
      '        </div>\n' +
      '        <div style="display:-webkit-flex;align-items:center;justify-content:center;word-break:break-all;width: 10%;float: left;box-sizing: border-box;border-right: 1px solid #000;border-bottom: 1px solid #000;padding: 0px 2px;">\n' +
      datas[i].tenantSupplierName +
      '        </div>\n' +
      '        <div style="display:-webkit-flex;align-items:center;justify-content:center;word-break:break-all;width: 4%;float: left;box-sizing: border-box;border-right: 1px solid #000;border-bottom: 1px solid #000;">\n' +
      datas[i].accountUnit +
      '        </div>\n' +
      '        <div style="display:-webkit-flex;align-items:center;justify-content:center;word-break:break-all;width: 6%;float: left;box-sizing: border-box;border-right: 1px solid #000;border-bottom: 1px solid #000;">\n' +
      datas[i].batchNo +
      '        </div>\n' +
      '        <div style="display:-webkit-flex;align-items:center;justify-content:center;word-break:break-all;width: 8%;float: left;box-sizing: border-box;border-right: 1px solid #000;border-bottom: 1px solid #000;">\n' +
      datas[i].deadline +
      '        </div>\n' +
      '        <div style="display:-webkit-flex;align-items:center;justify-content:flex-end;word-break:break-all;width: 7%;float: left;box-sizing: border-box;border-right: 1px solid #000;border-bottom: 1px solid #000;padding: 0px 2px;">\n' +
      (storehouseTag ? datas[i].invCount + datas[i].count : datas[i].invCount) +
      '        </div>\n' +
      '        <div style="display:-webkit-flex;align-items:center;justify-content:flex-end;word-break:break-all;width: 7%;float: left;box-sizing: border-box;border-right: 1px solid #000;border-bottom: 1px solid #000;padding: 0px 2px;">\n' +
      datas[i].unitPrice +
      '        </div>\n' +
      '        <div style="display:-webkit-flex;align-items:center;justify-content:flex-end;word-break:break-all;width: 7%;float: left;box-sizing: border-box;border-right: 1px solid #000;border-bottom: 1px solid #000;padding: 0px 2px;">\n' +
      datas[i].retailPrice +
      '        </div>\n' +
      '        <div style="display:-webkit-flex;align-items:center;justify-content:flex-end;word-break:break-all;width: 9%;float: left;box-sizing: border-box;border-right: 1px solid #000;border-bottom: 1px solid #000;padding: 0px 2px;">\n' +
      datas[i].count +
      '        </div>\n' +
      '        <div style="display:-webkit-flex;align-items:center;justify-content:flex-end;word-break:break-all;width: 13%;float: left;box-sizing: border-box;border-right: 1px solid #000;border-bottom: 1px solid #000;padding: 0px 2px;">\n' +
      datas[i].lyckIcludePrice +
      '        </div>\n' +
      '        <div style="display:-webkit-flex;align-items:center;justify-content:flex-end;word-break:break-all;width: 13%;float: left;box-sizing: border-box;border-right: 1px solid #000;border-bottom: 1px solid #000;padding: 0px 2px;">\n' +
      datas[i].lyckOutIcludePrice +
      '        </div>\n' +
      '    </div>'
  }
  const content =
    '<div style="width: 97%;padding: 0 20px;text-align: center;">\n' +
    '<h6 style="text-align: center;margin-bottom: 5px;">' +
    tenantName +
    '</h6>' +
    '<div style="text-align: center;margin-top: 5px;margin-bottom: 5px;width:100%">' +
    '<h5 style="display: inline-block;text-align: center;margin-top: 5px;margin-bottom: 5px;width: 20%;border-bottom: 1px solid #000;">' +
    '<div style="text-align: center;width:100%;display: inline-flex;">' +
    ' <div style="display: inline-block;width: 20%;">领</div>\n' +
    ' <div style="display: inline-block;width: 20%;">用</div>\n' +
    ' <div style="display: inline-block;width: 20%;">出</div>\n' +
    ' <div style="display: inline-block;width: 20%;">库</div>\n' +
    ' <div style="display: inline-block;width: 20%;">单</div>\n' +
    ' </div></h5>' +
    '</div>' +
    '    <div style="text-align: left;padding-bottom: 8px;font-weight: 600;font-size: 10px;">\n' +
    '        <div style="display: inline-block;width: 45%;">库&emsp;&emsp;房: <font style="font-weight: 300">' +
    detail?.storehouseName +
    '</font></div>\n' +
    '        <div style="display: inline-block;width: 35%;">日&emsp;&emsp;期: <font style="font-weight: 300">' +
    detail?.recepitsDate +
    '</font></div>\n' +
    '        <div style="display: inline-block;width: 18%;text-align: left;">业务单号: <font style="font-weight: 300;">' +
    detail?.putNumber +
    '</font></div>\n' +
    '    </div>\n' +
    '    <div style="text-align: left;padding-bottom: 8px;font-weight: 600;font-size: 10px;">\n' +
    '        <div style="display: inline-block;width: 45%;">领用部门: <font style="font-weight: 300">' +
    detail?.departmentName +
    '</font></div>\n' +
    '        <div style="display: inline-block;width: 35%;">领用人员: <font style="font-weight: 300">' +
    detail?.userName +
    '</font></div>\n' +
    '        <div style="display: inline-block;width: 18%;text-align: left;">金额单位: <font style="font-weight: 300;">元</font></div>\n' +
    '    </div>\n' +
    '<div style="font-size: 8px;font-family: SimSun;">' +
    '    <div style="display: flex;clear: left;border-top: 1px solid #000;font-weight: 600;">\n' +
    '        <div style="word-break:break-all;width: 10%;float: left;box-sizing: border-box;\n' +
    '                border-right: 1px solid #000;border-bottom: 1px solid #000;border-left: 1px solid #000;">\n' +
    '            名称\n' +
    '        </div>\n' +
    '        <div style="word-break:break-all;width: 6%;float: left;box-sizing: border-box;border-right: 1px solid #000;border-bottom: 1px solid #000;">\n' +
    '            规格\n' +
    '        </div>\n' +
    '        <div style="word-break:break-all;width: 10%;float: left;box-sizing: border-box;border-right: 1px solid #000;border-bottom: 1px solid #000;">\n' +
    '            生产厂家\n' +
    '        </div>\n' +
    '        <div style="word-break:break-all;width: 4%;float: left;box-sizing: border-box;border-right: 1px solid #000;border-bottom: 1px solid #000;">\n' +
    '            单位\n' +
    '        </div>\n' +
    '        <div style="word-break:break-all;width: 6%;float: left;box-sizing: border-box;border-right: 1px solid #000;border-bottom: 1px solid #000;">\n' +
    '            批次号\n' +
    '        </div>\n' +
    '        <div style="word-break:break-all;width: 8%;float: left;box-sizing: border-box;border-right: 1px solid #000;border-bottom: 1px solid #000;">\n' +
    '            有效期至\n' +
    '        </div>\n' +
    '        <div style="word-break:break-all;width: 7%;float: left;box-sizing: border-box;border-right: 1px solid #000;border-bottom: 1px solid #000;">\n' +
    '            库存数量\n' +
    '        </div>\n' +
    '        <div style="word-break:break-all;width: 7%;float: left;box-sizing: border-box;border-right: 1px solid #000;border-bottom: 1px solid #000;">\n' +
    '            库存进价\n' +
    '        </div>\n' +
    '        <div style="word-break:break-all;width: 7%;float: left;box-sizing: border-box;border-right: 1px solid #000;border-bottom: 1px solid #000;">\n' +
    '            库存售价\n' +
    '        </div>\n' +
    '        <div style="word-break:break-all;width: 9%;float: left;box-sizing: border-box;border-right: 1px solid #000;border-bottom: 1px solid #000;">\n' +
    '            领用出库数量\n' +
    '        </div>\n' +
    '        <div style="word-break:break-all;width: 13%;float: left;box-sizing: border-box;border-right: 1px solid #000;border-bottom: 1px solid #000;">\n' +
    '            领用出库金额(进价)\n' +
    '        </div>\n' +
    '        <div style="word-break:break-all;width: 13%;float: left;box-sizing: border-box;border-right: 1px solid #000;border-bottom: 1px solid #000;">\n' +
    '            领用出库金额(售价)\n' +
    '        </div>\n' +
    '    </div>\n' +
    sjDatas +
    '    <div style="display: flex;clear: left;font-weight: 600;">\n' +
    '        <div style="display:-webkit-flex;align-items:center;justify-content:flex-end;word-break:break-all;width: 44%;float: left;box-sizing: border-box;text-align: right;\n' +
    '                border-right: 1px solid #000;border-bottom: 1px solid #000;border-left: 1px solid #000;">\n' +
    '            合计\n' +
    '        </div>\n' +
    '        <div style="display:-webkit-flex;align-items:center;justify-content:flex-end;word-break:break-all;width: 7%;float: left;box-sizing: border-box;font-weight: bold;\n' +
    '            border-right: 1px solid #000;border-bottom: 1px solid #000;padding: 0px 2px;"><font style="font-weight: 300;">' +
    allInvCount +
    '      </font>  </div>\n' +
    '        <div style="display:-webkit-flex;align-items:center;justify-content:flex-end;word-break:break-all;width: 7%;float: left;box-sizing: border-box;font-weight: bold;\n' +
    '            border-right: 1px solid #000;border-bottom: 1px solid #000;padding: 0px 2px;"><font style="font-weight: 300;">' +
    allUnitPrice?.toFixed(5) +
    '     </font> </div>\n' +
    '        <div style="display:-webkit-flex;align-items:center;justify-content:flex-end;word-break:break-all;width: 7%;float: left;box-sizing: border-box;font-weight: bold;\n' +
    '            border-right: 1px solid #000;border-bottom: 1px solid #000;padding: 0px 2px;"><font style="font-weight: 300;">' +
    allRetailPrice?.toFixed(2) +
    '      </font>  </div>\n' +
    '        <div style="display:-webkit-flex;align-items:center;justify-content:flex-end;word-break:break-all;width: 9%;float: left;box-sizing: border-box;font-weight: bold;\n' +
    '            border-right: 1px solid #000;border-bottom: 1px solid #000;padding: 0px 2px;"><font style="font-weight: 300;">' +
    allCount +
    '      </font>  </div>\n' +
    '        <div style="display:-webkit-flex;align-items:center;justify-content:flex-end;word-break:break-all;width: 13%;float: left;box-sizing: border-box;font-weight: bold;\n' +
    '            border-right: 1px solid #000;border-bottom: 1px solid #000;padding: 0px 2px;"><font style="font-weight: 300;">' +
    allUnitPriceCount?.toFixed(2) +
    '      </font>  </div>\n' +
    '        <div style="display:-webkit-flex;align-items:center;justify-content:flex-end;word-break:break-all;width: 13%;float: left;box-sizing: border-box;font-weight: bold;\n' +
    '            border-right: 1px solid #000;border-bottom: 1px solid #000;padding: 0px 2px;"><font style="font-weight: 300;">' +
    allRetailPriceCount?.toFixed(2) +
    '      </font>  </div>\n' +
    '</div>\n' +
    '   <div style="padding-top: 5px;display:flex;clear: left;width: 100%;float: left;font-weight: 600;font-size: 10px;">\n' +
    '        <div style="display: inline-block;width: 33%;text-align: left;">单据备注:' +
    '' +
    '</div>\n' +
    '    </div>\n' +
    '    <div style="padding-top: 5px;display:flex;clear: left;width: 100%;float: left;font-weight: 600;font-size: 10px;">\n' +
    '        <div style="display: inline-block;width: 33%;text-align: left;">单位负责人:</div>\n' +
    '        <div style="display: inline-block;width: 33%;text-align: left;">仓管员:</div>\n' +
    '        <div style="display: inline-block;width: 33%;text-align: left;">财&emsp;&emsp;务:</div>\n' +
    '    </div>\n' +
    '    <div style="padding-top: 5px;display:flex;clear: left;width: 100%;float: left;font-weight: 600;font-size: 10px;">\n' +
    '        <div style="display: inline-block;width: 33%;text-align: left;">审&emsp;&emsp;&emsp;核:</div>\n' +
    '        <div style="display: inline-block;width: 33%;text-align: left;">制单人:<font style="font-weight: 300">' +
    userName +
    '</font></div>\n' +
    '        <div style="display: inline-block;width: 33%;text-align: left;">制单日期:<font style="font-weight: 300">' +
    moment().format(DateTimeFormatSimpleOnlyOne) +
    '</font></div>\n' +
    '    </div>\n' +
    '</div>'
  return content
}

// 购入退库详情
export function printBodyGRTK(data: any) {
  const userName = data?.userName
  const tenantName = data?.tenantName
  const detail = data?.detail
  const datas = data?.dataList
  let sjDatas = ''
  let allInvCount = 0
  let allCount = 0
  let allUnitPrice = 0
  let allTotalPrice = 0

  for (let i = 0; i < datas.length; i++) {
    allInvCount += Number(datas[i]?.invCount > 0 ? datas[i]?.invCount : 0)
    allUnitPrice += Number(datas[i]?.unitPrice > 0 ? datas[i]?.unitPrice : 0)
    allTotalPrice += Number(datas[i]?.totalPrice > 0 ? datas[i]?.totalPrice : 0)
    allCount += Number(datas[i]?.count > 0 ? datas[i]?.count : 0)

    sjDatas +=
      '<div style="display: flex;clear: left;font-weight: 300">\n' +
      '        <div style="display:-webkit-flex;align-items:center;justify-content:center;word-break:break-all;width: 4%;box-sizing: border-box;\n' +
      '                border-right: 1px solid #000;border-bottom: 1px solid #000;border-left: 1px solid #000;padding: 0px 2px;">\n' +
      (Number(i) + 1) +
      '        </div>\n' +
      '        <div style="display:-webkit-flex;align-items:center;justify-content:center;word-break:break-all;width: 12%;float: center;box-sizing: border-box;border-right: 1px solid #000;border-bottom: 1px solid #000;">\n' +
      datas[i].name +
      '        </div>\n' +
      '        <div style="display:-webkit-flex;align-items:center;justify-content:center;word-break:break-all;width: 14%;float: center;box-sizing: border-box;border-right: 1px solid #000;border-bottom: 1px solid #000;padding: 0px 2px;">\n' +
      datas[i].productInfo +
      '        </div>\n' +
      '        <div style="display:-webkit-flex;align-items:center;justify-content:center;word-break:break-all;width: 10%;float: center;box-sizing: border-box;border-right: 1px solid #000;border-bottom: 1px solid #000;">\n' +
      datas[i].hilistCode +
      '        </div>\n' +
      '        <div style="display:-webkit-flex;align-items:center;justify-content:center;word-break:break-all;width: 10%;float: center;box-sizing: border-box;border-right: 1px solid #000;border-bottom: 1px solid #000;">\n' +
      datas[i].batchNo +
      '        </div>\n' +
      '        <div style="display:-webkit-flex;align-items:center;justify-content:center;word-break:break-all;width: 10%;float: center;box-sizing: border-box;border-right: 1px solid #000;border-bottom: 1px solid #000;">\n' +
      datas[i].deadline +
      '        </div>\n' +
      '        <div style="display:-webkit-flex;align-items:center;justify-content:center;word-break:break-all;width: 10%;float: center;box-sizing: border-box;border-right: 1px solid #000;border-bottom: 1px solid #000;padding: 0px 2px;">\n' +
      datas[i].invCount +
      '        </div>\n' +
      '        <div style="display:-webkit-flex;align-items:center;justify-content:flex-end;word-break:break-all;width: 10%;float: right;box-sizing: border-box;border-right: 1px solid #000;border-bottom: 1px solid #000;padding: 0px 2px;">\n' +
      datas[i].totalPrice +
      '        </div>\n' +
      '        <div style="display:-webkit-flex;align-items:center;justify-content:flex-end;word-break:break-all;width: 10%;float: center;box-sizing: border-box;border-right: 1px solid #000;border-bottom: 1px solid #000;padding: 0px 2px;">\n' +
      datas[i].unitPrice +
      '        </div>\n' +
      '        <div style="display:-webkit-flex;align-items:center;justify-content:center;word-break:break-all;width: 10%;float: right;box-sizing: border-box;border-right: 1px solid #000;border-bottom: 1px solid #000;padding: 0px 2px;">\n' +
      datas[i].count +
      '        </div>\n' +
      '    </div>'
  }
  const content =
    '<div style="width: 97%;padding: 0 20px;text-align: center;">\n' +
    '<h6 style="text-align: center;margin-bottom: 5px;">' +
    tenantName +
    '</h6>' +
    '<div style="text-align: center;margin-top: 5px;margin-bottom: 5px;width:100%">' +
    '<h5 style="display: inline-block;text-align: center;margin-top: 5px;margin-bottom: 5px;width: 20%;border-bottom: 1px solid #000;">' +
    '<div style="text-align: center;width:100%;display: inline-flex;">' +
    ' <div style="display: inline-block;width: 20%;">购</div>\n' +
    ' <div style="display: inline-block;width: 20%;">入</div>\n' +
    ' <div style="display: inline-block;width: 20%;">退</div>\n' +
    ' <div style="display: inline-block;width: 20%;">库</div>\n' +
    ' <div style="display: inline-block;width: 20%;">单</div>\n' +
    ' </div></h5>' +
    '</div>' +
    '    <div style="text-align: left;padding-bottom: 8px;font-weight: 600;font-size: 10px;">\n' +
    '        <div style="display: inline-block;width: 45%;">库房名称 : <font style="font-weight: 300">' +
    detail?.storehouseName +
    '</font></div>\n' +
    '        <div style="display: inline-block;width: 35%;">业务部门 : <font style="font-weight: 300">' +
    detail?.departmentName +
    '</font></div>\n' +
    '        <div style="display: inline-block;width: 18%;text-align: left;">业务人员 : <font style="font-weight: 300;">' +
    detail?.userName +
    '</font></div>\n' +
    '    </div>\n' +
    '    <div style="text-align: left;padding-bottom: 8px;font-weight: 600;font-size: 10px;">\n' +
    '        <div style="display: inline-block;width: 45%;">供应商 : <font style="font-weight: 300">' +
    detail?.supplierName +
    '</font></div>\n' +
    '        <div style="display: inline-block;width: 35%;">业务日期 : <font style="font-weight: 300">' +
    detail?.recepitsDate +
    '</font></div>\n' +
    '    </div>\n' +
    (detail?.remark
      ? '    <div style="text-align: left;padding-bottom: 8px;font-weight: 600;font-size: 10px;">\n' +
      '        <div style="display: inline-block;width: 100%;">备注 : <font style="font-weight: 300">' +
      detail?.remark +
      '</font></div>\n' +
      '    </div>\n'
      : '') +
    '<div style="font-size: 8px;font-family: SimSun;">' +
    '    <div style="display: flex;clear: left;border-top: 1px solid #000;font-weight: 600;">\n' +
    '        <div style="word-break:break-all;width: 4%;float: center;box-sizing: border-box;\n' +
    '                border-right: 1px solid #000;border-bottom: 1px solid #000;border-left: 1px solid #000;">\n' +
    '            序号\n' +
    '        </div>\n' +
    '        <div style="word-break:break-all;width: 12%;float: center;box-sizing: border-box;border-right: 1px solid #000;border-bottom: 1px solid #000;">\n' +
    '            名称\n' +
    '        </div>\n' +
    '        <div style="word-break:break-all;width: 14%;float: center;box-sizing: border-box;border-right: 1px solid #000;border-bottom: 1px solid #000;">\n' +
    '            产品信息\n' +
    '        </div>\n' +
    '        <div style="word-break:break-all;width: 10%;float: center;box-sizing: border-box;border-right: 1px solid #000;border-bottom: 1px solid #000;">\n' +
    '            医保国码\n' +
    '        </div>\n' +
    '        <div style="word-break:break-all;width: 10%;float: center;box-sizing: border-box;border-right: 1px solid #000;border-bottom: 1px solid #000;">\n' +
    '            批号\n' +
    '        </div>\n' +
    '        <div style="word-break:break-all;width: 10%;float: center;box-sizing: border-box;border-right: 1px solid #000;border-bottom: 1px solid #000;">\n' +
    '            有效期\n' +
    '        </div>\n' +
    '        <div style="word-break:break-all;width: 10%;float: center;box-sizing: border-box;border-right: 1px solid #000;border-bottom: 1px solid #000;">\n' +
    '            实际数量\n' +
    '        </div>\n' +
    '        <div style="word-break:break-all;width: 10%;display:-webkit-flex;align-items:center;justify-content:flex-end;box-sizing: border-box;border-right: 1px solid #000;border-bottom: 1px solid #000;">\n' +
    '            退库金额\n' +
    '        </div>\n' +
    '        <div style="word-break:break-all;width: 10%;display:-webkit-flex;align-items:center;justify-content:flex-end;box-sizing: border-box;border-right: 1px solid #000;border-bottom: 1px solid #000;">\n' +
    '            退库单价\n' +
    '        </div>\n' +
    '        <div style="word-break:break-all;width: 10%;float: center;box-sizing: border-box;border-right: 1px solid #000;border-bottom: 1px solid #000;">\n' +
    '            退库数量\n' +
    '        </div>\n' +
    '    </div>\n' +
    sjDatas +
    '    <div style="display: flex;clear: left;font-weight: 600;">\n' +
    '        <div style="display:-webkit-flex;align-items:center;justify-content:flex-end;word-break:break-all;width: 60%;float: left;box-sizing: border-box;text-align: right;\n' +
    '                border-right: 1px solid #000;border-bottom: 1px solid #000;border-left: 1px solid #000;">\n' +
    '            合计\n' +
    '        </div>\n' +
    '        <div style="display:-webkit-flex;align-items:center;justify-content:center;word-break:break-all;width: 10%;float: left;box-sizing: border-box;font-weight: bold;\n' +
    '            border-right: 1px solid #000;border-bottom: 1px solid #000;padding: 0px 2px;"><font style="font-weight: 300;">' +
    allInvCount +
    '      </font>  </div>\n' +
    '        <div style="display:-webkit-flex;align-items:center;justify-content:flex-end;word-break:break-all;width: 10%;float: left;box-sizing: border-box;font-weight: bold;\n' +
    '            border-right: 1px solid #000;border-bottom: 1px solid #000;padding: 0px 2px;"><font style="font-weight: 300;">' +
    allTotalPrice?.toFixed(2) +
    '     </font> </div>\n' +
    '        <div style="display:-webkit-flex;align-items:center;justify-content:flex-end;word-break:break-all;width: 10%;float: left;box-sizing: border-box;font-weight: bold;\n' +
    '            border-right: 1px solid #000;border-bottom: 1px solid #000;padding: 0px 2px;"><font style="font-weight: 300;">' +
    allUnitPrice?.toFixed(5) +
    '      </font>  </div>\n' +
    '        <div style="display:-webkit-flex;align-items:center;justify-content:center;word-break:break-all;width: 10%;float: left;box-sizing: border-box;font-weight: bold;\n' +
    '            border-right: 1px solid #000;border-bottom: 1px solid #000;padding: 0px 2px;"><font style="font-weight: 300;">' +
    allCount +
    '      </font>  </div>\n' +
    '</div>\n' +
    '    <div style="padding-top: 5px;display:flex;clear: left;width: 100%;float: left;font-weight: 600;font-size: 10px;">\n' +
    '        <div style="display: inline-block;width: 33%;text-align: left;">单位负责人:</div>\n' +
    '        <div style="display: inline-block;width: 33%;text-align: left;">仓管员:</div>\n' +
    '        <div style="display: inline-block;width: 33%;text-align: left;">财&emsp;&emsp;务:</div>\n' +
    '    </div>\n' +
    '    <div style="padding-top: 5px;display:flex;clear: left;width: 100%;float: left;font-weight: 600;font-size: 10px;">\n' +
    '        <div style="display: inline-block;width: 33%;text-align: left;">审&emsp;&emsp;&emsp;核:</div>\n' +
    '        <div style="display: inline-block;width: 33%;text-align: left;">制单人:<font style="font-weight: 300">' +
    userName +
    '</font></div>\n' +
    '        <div style="display: inline-block;width: 33%;text-align: left;">制单日期:<font style="font-weight: 300">' +
    moment().format(DateTimeFormatSimpleOnlyOne) +
    '</font></div>\n' +
    '    </div>\n' +
    '</div>'
  return content
}
