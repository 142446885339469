/* eslint-disable @typescript-eslint/no-empty-function */
import { CaretUpFilled, CaretDownFilled } from '@ant-design/icons'

import { unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Modal,
  ModalProps,
  notification,
  Row,
  Select,
  Space,
  Table,
  Image,
  DatePicker,
} from 'antd'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../app/store'
import { EditableList } from '../../compnents/list/EditableList'
import { LoadingState } from '../../models/common'
import { checkImgExists, PrintTemplate, PrintTemplateCategory } from '../../models/print'
import {
  RecipeCategory,
  isTcmRecipeCategory,
  getRecipeCategoryName,
  Recipe,
} from '../../models/recipe'
import { Settings } from '../../models/setting'
import { ThemeContext } from '../../theme/ThemeContext'
import { stringTrim } from '../../utils/StringUtils'
import {
  getRecipeList,
  getWebPrintDataByTreatmentId,
  getWebPrintDataByPaymentId,
  getWebPrintDataByRecipeId,
} from '../recipe/recipeListSlice'
import { selectBooleanSetting, setSettings } from '../settings/settingsSlice'
import {
  TcmColumns,
  MedicineMaterialColumns,
} from '../treatment/editor/columns'
import {
  checkRepeatInvoiceAsync,
  getDetailSimple,
  getPrinters,
  getPrintRecipeAsyncByPaymentId,
  getPrintTemplateSettingDetail,
  getVisibleIsPeopleFace,
  getVisibleQrCode,
  getWebPrintDataAsyncByRecipeId,
  initializePrintService,
  invoiceListAsync,
  invoiceListAsyncForMbfe,
  printTemplate,
  selectPrinters,
  selectPrintServiceState,
  selectPrintTemplate,
  selectTemplateDefaultPrinters,
  selectTemplateOffsets,
  setTemplateDefaultPrinter,
} from './printSlice'
import JsBarcode from 'jsbarcode'
import styels from './PrintFrame.module.css'
import { invoicenumberconfigAsync } from '../tenant/tenantSlice'
import Qrcode from 'qrcode.react'
import { serverType } from '../../services/commodity'
import {
  selectCredentials,
  selectInsuranceArray,
  selectShortName,
  selectTenantAreaCd,
  selectTenantCityCode,
  selectTenantCountyCode,
  selectTenantId,
  selectTenantName,
  selectUserId,
  selectUserName,
} from "../../app/applicationSlice";
import {
  PrintTYBody,
  PrintZYBody,
  PrintKQBody,
  PrintZXDBody,
  PrintZLZXDBody,
} from '../../models/PrintTemplateModalPrint'
import {
  MbfeService,
  medicalSendHttp,
  strParse,
} from '../../utils/MedicalUtils'
import { otherPatientInsuranceItemParams } from '../../models/payment'
import _, { sum } from 'lodash'
import { fromMaterialJson } from '../../models/material'
import moment from 'moment'
import {
  DateTimeFormatSimpleOne,
  DateTimeFormatSimpleOnly,
  DateTimeFormatSimpleOnlyOne,
} from '../../models/datetime'
import { getSexData } from '../../models/ToothHome'
import { setDetail } from '../durgsystem/durgsystemSlice'
import {
  getSignatureAsync,
  getSignatureEntityAsync,
} from '../personalManagement/personalDetail/personalDetailSlice'
import { selectRecipeMaterials } from '../treatment/editor/recipeEditorSlice'
import {
  getTreatments,
  selectTreatment,
} from '../treatment/editor/recipeListEditorSlice'
import { Patient } from '../../models/patient'
import { getPatientDetailInfoAsync } from '../patient/modals/patientSlice'
import {
  SinglezYPrintPrescriptionContent,
  subPrintPrescriptionContent,
  PrintDiagnosisCertificateContent,
  subZYPrintPrescriptionContent,
} from '../../models/recipeEditorPrint'
import { GlobalKeyboardAction } from '../../compnents/actions/GlobalKeyboardAction'
import { PrintChargeBody } from '../../models/ChargePrint'
import { setPageLoading } from '../nurse/formula/formulas/formulaSlice'
import { subPrintInvoiceFeeBillForWeb } from '../../models/invoiceFeeBillForWeb'
import JXImg from '../../layouts/img/JXImg.png'

let timer: any = null
interface PrintTemplateModalProps {
  reciptIdFlag?: any;
  machiningTypeMsg?: any;
  recipeUseMethod?: any;
  recipeCategory?: any;
  tipCount?: any;
  pagePrintFlag?: any;
  printZXDData?: any;
  doctorId?: any;
  hbdataTime?: any; //打印核拨表所需要的时间
  ids?: any; //打印价格标签所需要的值
  templateCategory?: PrintTemplateCategory;
  registrationId?: string;
  treatmentId?: string;
  patientId?: any;
  recipeId?: string;
  paymentId?: string;
  invoiceId?: string;
  insuranceCode?: string;
  isRecipe?: boolean;
  ishb?: boolean;
  paymentInfo?: any;
  itemList?: any;
  onCancel?: () => void;
  closeModal?: () => void;
  onChange?: () => void;
}

export const PrintTemplateModal = ({
  reciptIdFlag,
  machiningTypeMsg,
  recipeUseMethod,
  recipeCategory,
  tipCount,
  pagePrintFlag,
  printZXDData,
  doctorId,
  hbdataTime,
  ids,
  templateCategory,
  registrationId,
  treatmentId,
  recipeId,
  patientId,
  paymentId,
  invoiceId,
  visible,
  ishb,
  insuranceCode,
  paymentInfo,
  itemList,
  isRecipe = false, //是否在票据打印时展示处方打印按钮
  onCancel = () => {},
  closeModal = () => {},
  onChange = () => {},
  ...others
}: PrintTemplateModalProps & ModalProps): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const credentials = useSelector(selectCredentials)

  const [form] = Form.useForm()

  const { Option } = Select

  const theme = useContext(ThemeContext)

  const insuranceArray = useSelector(selectInsuranceArray)

  const tenantName = useSelector(selectTenantName)

  const shortName = useSelector(selectShortName)

  const tenantId = useSelector(selectTenantId)

  const userId = useSelector(selectUserId)

  const userName = useSelector(selectUserName)
  
  const cityCode = useSelector(selectTenantCityCode)

  console.log(cityCode,"cityCode");
  

  const countyCode = useSelector(selectTenantCountyCode);

  const materials = useSelector(selectRecipeMaterials)

  const treatment = useSelector(selectTreatment)

  const initialized = useSelector(selectPrintServiceState)

  const isPreview = useSelector(selectBooleanSetting(Settings.Printing.Preview))

  const printers = useSelector(selectPrinters)

  const offsets = useSelector(selectTemplateOffsets)

  const defaultPrinters = useSelector(selectTemplateDefaultPrinters)

  const [templates, setTemplates] = useState<PrintTemplate[]>([])

  const [selectedTemplateIds, setSelectedTemplateIds] = useState<string[]>([])

  const [isModalVisible, setIsModalVisible] = useState(false)

  const [isQrCodeVisible, setIsQrCodeVisible] = useState(false)

  const [recipeList, setRecipeList] = useState<any[]>([])

  const [invoiceVisible, setInvoiceVisible] = useState(false)

  const [invoicePrinted, setInvoicePrinted] = useState<any>([])

  const [showPerson, setShowPerson] = useState(true)

  const [loading, setLoading] = useState(false)

  const [repeatVisible, setRepeatVisible] = useState(false)

  const [editNoViaible, setEditNoViaible] = useState(false)

  const [invoiceNo, setInvoiceNo] = useState('')

  const [chargeNumber, setChargeNumber] = useState<any>({})

  const [tipsMessage, setTipsMessage] = useState('')

  const [imgSrc, setImgSrc] = useState('') //预览图片路径

  const [imgVisible, setImgVisible] = useState(false)

  const [isQrCodeData, setIsQrCodeData] = useState<any>()

  const [patient, setPatient] = useState<Patient | undefined>()

  const [detail, setDetail] = useState<any>()

  const [hotkeyTimeWaitFlag, setHotkeyTimeWaitFlag] = useState(false)

  const [heboTime, setHeboTime] = useState<any>([]);
  
  const [wechatQrCode, setWechatQrCode] = useState<any>()

  useEffect(() => {
    if (visible) {
      //100毫秒以后，启用快捷键
      setTimeout(() => {
        setHotkeyTimeWaitFlag(true)
      }, 100)
    } else {
      setHotkeyTimeWaitFlag(false)
    }
  }, [visible])

  useEffect(() => {
    if (visible) {
      dispatch(
        selectPrintTemplate({
          templateCategory,
          insuranceCode: templateCategory == 132 ? insuranceCode : undefined,
        })
      )
        .then(unwrapResult)
        .then((templates) => {
          setTemplates(templates)
          if (templates.length && templates.some((v) => v.invoiceType == '2')) {
            dispatch(invoicenumberconfigAsync())
              .then(unwrapResult)
              .then((res: any) => {
                const data = res.records.find((v: any) => v.categoryType == 0)
                setChargeNumber(data)
                setInvoiceNo(data?.currentNo)
              })
          }
        })
        .catch(() => {
          // do nothing.
        })
    }
  }, [templateCategory, visible])

  useEffect(() => {
    editNoViaible && setInvoiceNo(chargeNumber?.currentNo)
  }, [chargeNumber, editNoViaible])

  useEffect(() => {
    if (initialized === LoadingState.Idle && visible) {
      dispatch(initializePrintService())
        .then(unwrapResult)
        .catch(() => {
          // do nothing.
        })
    }
    if (initialized === LoadingState.Finished && visible) {
      dispatch(getPrinters())
    }
    if (initialized === LoadingState.Error && visible) {
      setIsModalVisible(true)
    }
  }, [dispatch, initialized, visible])

  useEffect(() => {
    if (templates.length === 1) {
      setSelectedTemplateIds([templates[0].id])
    }
  }, [templates])

  useEffect(() => {
    const defaultKeys = window.localStorage.getItem('PRINTKEYS')
      ? JSON.parse(window.localStorage.getItem('PRINTKEYS')!)
      : []
    setSelectedTemplateIds(defaultKeys)
  }, [])

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  }

  const print = async (t: PrintTemplate, status?: any) => {
    const offset = offsets[t.id]
    const left = offset?.left || 0
    const top = offset?.top || 0
    return dispatch(
      printTemplate({
        templateCode: t.templateCode,
        topOffset: top,
        leftOffset: left,
        registrationId,
        treatmentId,
        recipeId,
        paymentId,
        preview: isPreview,
        printer: defaultPrinters[t.id],
        status,
        invoiceId,
        priceTagPrintsIdList: ids,
        startTime: heboTime?.[0] ? heboTime?.[0] : undefined,
        endTime: heboTime?.[1] ? heboTime?.[1] : undefined,
      })
    );
  }
  const getZeroSupply = (
    invoiceNumber: string,
    invoiceNumberLength: number
  ) => {
    if (
      !invoiceNumberLength ||
      invoiceNumberLength <= 0 ||
      invoiceNumberLength <= invoiceNumber.length ||
      invoiceNumberLength > 15
    ) {
      return invoiceNumber
    } else {
      const diffLength = invoiceNumberLength - invoiceNumber.length
      let Azero = ''
      for (let i = 0; i < diffLength; i++) {
        Azero += '0'
      }
      return Azero + '' + invoiceNumber
    }
  }
  const getInvoiceType = (type = '') => {
    switch (type) {
      case '1':
        return '票据'
      case '2':
        return '发票'
      case '3':
        return '电子发票'
      default:
        return '-'
    }
  }

  const setNo = () => {
    setChargeNumber({
      ...chargeNumber,
      currentNo: invoiceNo,
    })
    setInvoiceNo('')
  }

  useEffect(() => {
    const startOfMonth = moment().startOf("month").format("YYYY-MM-DD 00:00:00");
    const today = moment().format("YYYY-MM-DD 23:59:59");
    setHeboTime([startOfMonth, today])
  }, [visible])

  useEffect(() => {
    if (isQrCodeVisible) {
      setIsQrCodeData('')
      dispatch(getVisibleQrCode())
        .then(unwrapResult)
        .then((v) => {
          setIsQrCodeData(v)
        })
      isPeopleFace()
    }
  }, [isQrCodeVisible])

  useEffect(() => {
    if (isQrCodeVisible) {
      timer = setInterval(() => {
        isPeopleFace()
      }, 20000)
    } else {
      timer && clearInterval(timer)
    }
  }, [dispatch, isQrCodeVisible])

  const isPeopleFace = () => {
    dispatch(getVisibleIsPeopleFace())
      .then(unwrapResult)
      .then((v) => {
        if (v?.sfsl == 1) {
          timer && clearInterval(timer)
          setIsQrCodeVisible(false)
        }
      })
  }

  const getWebPrintDataMain = async (templateCategory: string) => {
    // data 打印需要的值
    // medicalRecord 1 通用 2 中医 3 口腔 (区分什么病历)
    if (templateCategory == 'RecipeExecuteForWeb') {
      //输注执行单
      getRecipeExecuteForWeb()
    } else if (templateCategory == 'RecipeExecuteZLForWeb') {
      //诊疗执行单
      getRecipeExecuteZLForWeb()
    } else if (templateCategory == 'TreatmentRecordNoRecForWeb') {
      //诊断书 无处方
      getTreatmentRecordForWeb(false)
    } else if (templateCategory == 'TreatmentRecordForWeb') {
      //诊断书 有处方
      getTreatmentRecordForWeb(true)
    } else if (templateCategory == 'ChnTradRecipeForWeb') {
      // 中药处方单打印
      getPrescriptionForWeb('', 3)
    } else if (templateCategory == 'WesternMedicinePreferenceSettingForWeb') {
      // 西药处方单打印
      getPrescriptionForWeb('', 1)
    } else if (templateCategory == 'FirstClassPsychotropicPrescriptionForWeb') {
      // 精一药品处方笺打印
      getPrescriptionForWeb('精一', 1)
    } else if (
      templateCategory == 'SecondClassPsychotropicPrescriptionForWeb'
    ) {
      // 精二药品处方笺打印
      getPrescriptionForWeb('精二', 1)
    } else if (templateCategory == 'ToxicMedicationsPrescriptionForWeb') {
      // 毒性药品
      getPrescriptionForWeb('毒', 1)
    } else if (templateCategory == 'AnesthesiaPrescriptionForWeb') {
      // 醉药品处
      getPrescriptionForWeb('麻醉', 1)
    } else if (templateCategory == 'DiagnosisCertificateForWeb') {
      // 诊断证明书
      getDiagnosisCertificateForWeb()
    } else if (templateCategory == 'SZDentistryReceiptForWeb') {
      // 苏州口腔收费收据
      getSZDentistryReceiptForWeb()
    } else if (templateCategory == 'InvoiceFeeBillForWeb') {
      //80mm 小票结算
      let codeImg = ''
        try {
          await checkImgExists(
            `${window.location.origin}/proxyToOssHydhisPublic/WechatQRCode/${tenantId}.png`
          );
          codeImg = `<div style="display: flex;align-items: flex-end;width:100%;margin: 6px 0 6px 100px">
                <img src="${window.location.origin}/proxyToOssHydhisPublic/WechatQRCode/${tenantId}.png" style="width:100px;height:100px" />
              </div>
            <div>杭州医保政策，医保票据智能解读看这里</div>`;
          getPrescriptionForWeb("小票", 1 , codeImg)
        } catch (err) {
          console.log(err)
          getPrescriptionForWeb("小票", 1)
        }
    } else {
      notification.error({
        message: '不支持的打印模板，请联系管理员',
      })
    }
  }

  const getTreatmentRecordForWeb = (haveRecipeFlag: boolean) => {
    const getWebPrintContent =
      pagePrintFlag == 'sfPrintTag'
        ? getWebPrintDataByPaymentId
        : getWebPrintDataByTreatmentId

    if (paymentId || treatmentId) {
      dispatch(
        getWebPrintContent({
          paymentId: pagePrintFlag == 'sfPrintTag' ? paymentId : null,
          treatmentId: pagePrintFlag == 'sfPrintTag' ? null : treatmentId,
        })
      )
        .then(unwrapResult)
        .then((data: any) => {
          JsBarcode('#barcode', data?.treatment?.patientId)
          const list = data?.recipeWebPrintVOList?.map((v: any) => {
            return {
              ...v,
              itemList: v?.recipeItemWebPrintVOList,
            }
          })
          const dataMainContent = {
            tenantName: tenantName?.split('-')?.[0],
            userName: userName,
            dataMain: {
              treatment: data?.treatment,
              feeCategory: data?.feeCategory,
              insuranceArrayLength:insuranceArray.length,
              // 签名
              SignatureSrc: data?.printSettingData?.userSign,
              // 拿到当前病历下所有处方 打印诊断书时有处方就打印
              printRecipes: list,
              printAddress: data?.patientAddress,
              codeImg: document.getElementById('barcode')?.getAttribute('src'),
              JXQrCodeImg:cityCode == '330400' ? JXImg :'',
              cityCode:cityCode
            },
          }
          if (data?.medicalRecord == 1) {
            const page: any = window.open('', '_blank') // 打开一个新窗口，用于打印
            page?.document?.write(PrintTYBody(dataMainContent, haveRecipeFlag)) // 写入打印页面的内容
            setTimeout(function () {
              page.print() // 打印
              page?.close()
              onCancel()
              setLoading(false)
              document.getElementById('barcode')?.setAttribute('src', '')
            }, 200)
          } else if (data?.medicalRecord == 2) {
            const page: any = window.open('', '_blank') // 打开一个新窗口，用于打印
            page?.document?.write(PrintZYBody(dataMainContent, haveRecipeFlag)) // 写入打印页面的内容
            setTimeout(function () {
              page.print() // 打印
              page?.close()
              onCancel()
              setLoading(false)
              document.getElementById('barcode')?.setAttribute('src', '')
            }, 200)
          } else if (data?.medicalRecord == 3) {
            const page: any = window.open('', '_blank') // 打开一个新窗口，用于打印
            page?.document?.write(PrintKQBody(dataMainContent, haveRecipeFlag)) // 写入打印页面的内容
            setTimeout(function () {
              page.print() // 打印
              page?.close()
              onCancel()
              setLoading(false)
              document.getElementById('barcode')?.setAttribute('src', '')
            }, 200)
          }
        })
    } else {
      notification.error({
        message: '缺少必要的打印参数',
      })
    }
  }

  //  苏州口腔收费收据
  const getSZDentistryReceiptForWeb = () => {
    const getWebPrintContent =
      pagePrintFlag == 'sfPrintTag'
        ? getWebPrintDataByPaymentId
        : getWebPrintDataByTreatmentId

    if (paymentId || treatmentId) {
      dispatch(
        getWebPrintContent({
          paymentId: pagePrintFlag == 'sfPrintTag' ? paymentId : null,
          treatmentId: pagePrintFlag == 'sfPrintTag' ? null : treatmentId,
        })
      )
        .then(unwrapResult)
        .then((data: any) => {
          const list = data?.recipeWebPrintVOList?.map((v: any) => {
            return {
              ...v,
              itemList: v?.recipeItemWebPrintVOList,
            }
          })
          const dataMainContent = {
            tenantName: tenantName?.split('-')?.[0],
            userName: userName,
            dataMain: {
              treatment: data?.treatment,
              patient: data?.patient,
              payment: data?.payment,
              // 拿到当前病历下所有处方 打印诊断书时有处方就打印
              printRecipes: list,
              tenantAddress: data?.tenantAddress,
              tenantPhone: data?.tenantPhone,
              payMethod: data?.payMethod?.map((v: any, index: any) => {
                return v?.payMethodName + v?.receivePayAmountFormat
              }),
            },
          }
          const page: any = window.open('', '_blank') // 打开一个新窗口，用于打印
          page?.document?.write(PrintChargeBody(dataMainContent)) // 写入打印页面的内容
          setTimeout(function () {
            page?.print() // 打印
            onCancel()
            setLoading(false)
            page?.close()
          }, 200)
        })
    } else {
      notification.error({
        message: '缺少必要的打印参数',
      })
    }
  }

  useEffect(() => {
    if (visible) {
      // 获取详情
      dispatch(getPrintTemplateSettingDetail())
        .then(unwrapResult)
        .then((v: any) => {
          setDetail(v)
        })

      if (patientId) {
        dispatch(getPatientDetailInfoAsync({ id: patientId }))
          .then(unwrapResult)
          .then((res: any) => {
            setPatient(res)
          })

        JsBarcode('#barcode', patientId)
      }
    }
  }, [recipeId, visible, patientId])

  // 处方单打印
  const getPrescriptionForWeb = (typeName?: any, category?: any, codeImg = '') => {
    let medicalWestPrintQuantity = 8 //固定一页8条
    // 是否打印厂家
    const cjFlag = detail?.functionList?.filter((v: any) => v?.type == 33)?.[0]
      ?.value
    if (cjFlag && (cjFlag == true || cjFlag == 'true')) {
      medicalWestPrintQuantity = typeName == '麻醉' ? 7 : 8
    } else {
      medicalWestPrintQuantity = typeName == '麻醉' ? 11 : 12
    }
    const getWebPrintContent =
      pagePrintFlag == 'sfPrintTag'
        ? getWebPrintDataByPaymentId
        : getWebPrintDataByRecipeId
    if (paymentId || recipeId) {
      dispatch(
        getWebPrintContent({
          paymentId: pagePrintFlag == 'sfPrintTag' ? paymentId : undefined,
          recipeId: pagePrintFlag == 'sfPrintTag' ? undefined : recipeId,
        })
      )
        .then(unwrapResult)
        .then((data: any) => {
          // let signBaseInfo = ''
          // if (
          //   data?.treatment?.doctorSignId &&
          //   data?.treatment?.doctorSignId != -1
          // ) {
          //   dispatch(
          //     getSignatureEntityAsync({
          //       signId: data?.treatment?.doctorSignId,
          //       tenantId: tenantId,
          //     })
          //   )
          //     .then(unwrapResult)
          //     .then((urlMain: any) => {
          //       signBaseInfo = urlMain?.signBaseInfo
          //     })
          // } else {
          //   signBaseInfo = ''
          // }
          // 西药
          if (category == 1) {
            const list = data?.recipeWebPrintVOList
              ?.map((v: any) => {
                return {
                  ...v,
                  itemList: v?.recipeItemWebPrintVOList,
                }
              })
              ?.map((v: any) => v?.itemList)
              ?.flat(1)

            const getWebPrintRecipeContent =
              pagePrintFlag == 'sfPrintTag'
                ? getPrintRecipeAsyncByPaymentId
                : getWebPrintDataAsyncByRecipeId
            if (recipeId || paymentId) {
              dispatch(
                getWebPrintRecipeContent(
                  pagePrintFlag == 'sfPrintTag' ? paymentId : recipeId
                )
              )
                .then(unwrapResult)
                .then((res: any) => {
                  const allPage = Math.ceil(
                    Number(res?.recipeItemGroupNumber?.length) /
                      medicalWestPrintQuantity
                  )
                  const chunkArray = (array: any, chunkSize: any) => {
                    const result = []
                    for (let i = 0; i < array.length; i += chunkSize) {
                      result.push(array.slice(i, i + chunkSize))
                    }
                    return result
                  }
                  const chunkedArray = chunkArray(
                    res?.recipeItemGroupNumber,
                    medicalWestPrintQuantity
                  )
                  const dataMainList = {
                    chunkedArray: chunkedArray,
                    allPage: allPage,
                    tenantName: tenantName?.split('-')?.[0],
                    userName: userName,
                    recipeCategory: recipeCategory,
                    doctorMessage: data?.printSettingData,
                    zlCount: res?.serviceAmount,
                    ypCount: res?.drugAmount,
                    showFlag: {
                      zsFlag: detail?.functionList?.filter(
                        (v: any) => v?.type == 37
                      )?.[0]?.value,
                      cjFlag: detail?.functionList?.filter(
                        (v: any) => v?.type == 33
                      )?.[0]?.value,
                      blhFlag: detail?.functionList?.filter(
                        (v: any) => v?.type == 32
                      )?.[0]?.value,
                      bzFlag: detail?.functionList?.filter(
                        (v: any) => v?.type == 35
                      )?.[0]?.value,
                      jeFlag: detail?.functionList?.filter(
                        (v: any) => v?.type == 28
                      )?.[0]?.value,
                      // 是否打印诊疗
                      zlFlag: detail?.functionList?.filter(
                        (v: any) => v?.type == 39
                      )?.[0]?.value,
                      // 是否打印柜号
                      ghFlag: detail?.functionList?.filter(
                        (v: any) => v?.type == 52
                      )?.[0]?.value,
                      // 是否打印天数
                      daysFlag: detail?.functionList?.filter(
                        (v: any) => v?.type == 53
                      )?.[0]?.value,
                    },
                    // SignatureSrc: signBaseInfo,
                    typeName: typeName,
                    recipeId: data?.recipeAllid,
                    detailMessage: {
                      ...data?.treatment,
                      feeCategory: data?.feeCategory,
                      insuranceArrayLength:insuranceArray.length,
                      medicalRecord:data?.medicalRecord,
                      sex: getSexData(Number(data?.treatment?.patientSex)),
                      type:
                        data?.treatment?.insuranceCode == 'ZIFEI'
                          ? '自费'
                          : data?.treatment?.insuranceCode != 'ZIFEI' &&
                            data?.treatment?.insuranceCode != ''
                          ? '医保'
                          : '',
                      createTime: data?.treatment?.createTime
                        ? moment(data?.treatment?.createTime)?.format(
                            DateTimeFormatSimpleOnly
                          )
                        : '-',
                      codeImg: document
                        .getElementById('barcode')
                        ?.getAttribute('src'),
                    },
                    patientMessage: {
                      ...data?.patient,
                      patientAddress: data?.patientAddress,
                    },
                  }
                  const page: any = window.open('', '_blank') // 打开一个新窗口，用于打印
                  page?.document?.write(
                    typeName == "小票"
                      ? subPrintInvoiceFeeBillForWeb({
                          ...dataMainList,
                          payment: data?.payment,
                          paymentInfo: paymentInfo,
                          countyCode: countyCode,
                          wechatQrCode: tenantId,
                          itemList: itemList,
                          codeImg,
                        })
                      : subPrintPrescriptionContent(dataMainList)
                  ); //JsBarcode 写入打印页面的内容
                  setTimeout(function () {
                    page.print() // 打印
                    page?.close()
                    onCancel()
                    setLoading(false)
                    document.getElementById('barcode')?.setAttribute('src', '')
                  }, 200)
                })
            }
          } else {
            const list = data?.recipeWebPrintVOList
              ?.filter((v: any) => v?.category == 3)
              ?.map((v: any) => {
                return {
                  ...v,
                  itemList: v?.recipeItemWebPrintVOList,
                }
              })
              ?.map((v: any) => v?.itemList)
              ?.flat(1)

            const zyList = data?.recipeWebPrintVOList?.filter(
              (v: any) => v?.category == 3
            )

            const drugAmount = sum(
              zyList?.[0]?.recipeItemWebPrintVOList?.map((i: any) =>
                Number(i?.drugAmount)
              )
            )
            const tip = zyList?.[0]?.recipeItemWebPrintVOList?.[0]?.tip
            // 打印

            const medicalWestZYPrintQuantity = 24 //固定一页24条

            const allPage = Math.ceil(
              Number(list?.length) / medicalWestZYPrintQuantity
            )
            const chunkArray = (array: any, chunkSize: any) => {
              const result = []
              for (let i = 0; i < array.length; i += chunkSize) {
                result.push(array.slice(i, i + chunkSize))
              }
              return result
            }
            const chunkedArray = chunkArray(list, medicalWestZYPrintQuantity)
            const dataMainList = {
              chunkedArray: chunkedArray,
              allPage: allPage,
              tenantName: tenantName?.split('-')?.[0],
              userName: userName,
              shortName:shortName,
              recipeCategory: recipeCategory,
              doctorMessage: data?.printSettingData,
              showFlag: {
                zsFlag: detail?.functionList?.filter(
                  (v: any) => v?.type == 37
                )?.[0]?.value,
                cjFlag: detail?.functionList?.filter(
                  (v: any) => v?.type == 33
                )?.[0]?.value,
                blhFlag: detail?.functionList?.filter(
                  (v: any) => v?.type == 32
                )?.[0]?.value,
                bzFlag: detail?.functionList?.filter(
                  (v: any) => v?.type == 35
                )?.[0]?.value,
                jeFlag: detail?.functionList?.filter(
                  (v: any) => v?.type == 28
                )?.[0]?.value,
                // 是否打印诊疗
                zlFlag: detail?.functionList?.filter(
                  (v: any) => v?.type == 39
                )?.[0]?.value,
                // 是否打印柜号
                ghFlag: detail?.functionList?.filter(
                  (v: any) => v?.type == 52
                )?.[0]?.value,
                // 是否打印天数
                daysFlag: detail?.functionList?.filter(
                  (v: any) => v?.type == 53
                )?.[0]?.value,
              },
              // SignatureSrc: signBaseInfo,
              typeName: typeName,
              tcmMessage: {
                tipCount: tip,
                recipeUseMethod: zyList?.[0]?.useMethod,
                machiningTypeMsg: zyList?.[0]?.machiningName,
                mtCount: sum(
                  zyList?.[0]?.recipeItemWebPrintVOList?.map((i: any) =>
                    Number(i?.billingCategory == '03' ? i?.countPerTip : 0)
                  )
                ),
                allCount: sum(
                  zyList?.[0]?.recipeItemWebPrintVOList?.map((i: any) =>
                    Number(i?.billingCategory == '03' ? i?.drugCount : 0)
                  )
                ),
                costPerPatch: Number(drugAmount / Number(tip))?.toFixed(2),
                fryingFee: Number(
                  zyList?.[0]?.recipeItemWebPrintVOList?.find(
                    (v: any) => v.billingCategory === '97'
                  )?.drugAmount
                )?.toFixed(2),
                amount: Number(
                  sum(
                    zyList?.[0]?.recipeItemWebPrintVOList?.map((i: any) =>
                      Number(i?.drugAmount)
                    )
                  )
                )?.toFixed(2),
              },
              recipeId: data?.recipeAllid,
              detailMessage: {
                ...data?.treatment,
                feeCategory: data?.feeCategory,
                insuranceArrayLength:insuranceArray.length,
                sex: getSexData(Number(data?.treatment?.patientSex)),
                type:
                  data?.treatment?.insuranceCode == 'ZIFEI'
                    ? '自费'
                    : data?.treatment?.insuranceCode != 'ZIFEI' &&
                      data?.treatment?.insuranceCode != ''
                    ? '医保'
                    : '',
                createTime: data?.treatment?.createTime
                  ? moment(data?.treatment?.createTime)?.format(
                      DateTimeFormatSimpleOnly
                    )
                  : '-',
                codeImg: document
                  .getElementById('barcode')
                  ?.getAttribute('src'),
              },
              payment: {
                ...data?.payment,
                createTime: data?.payment?.createTime
                  ? moment(data?.treatment?.createTime)?.format(
                    DateTimeFormatSimpleOnlyOne
                    )
                  : '',
              },
              patientMessage: {
                ...data?.patient,
                patientAddress: data?.patientAddress,
              },
              dataList: list,
            }
            const page: any = window.open('', '_blank') // 打开一个新窗口，用于打印

            page?.document?.write(subZYPrintPrescriptionContent(dataMainList)) // 写入打印页面的内容
            setTimeout(function () {
              page.print() // 打印
              page?.close()
              onCancel()
              setLoading(false)
              document.getElementById('barcode')?.setAttribute('src', '')
            }, 200)
          }
        })
    }
  }

  // 诊断证明书打印
  const getDiagnosisCertificateForWeb = () => {
    const getWebPrintContent =
      pagePrintFlag == 'sfPrintTag'
        ? getWebPrintDataByPaymentId
        : getWebPrintDataByTreatmentId
    if (paymentId || treatmentId) {
      dispatch(
        getWebPrintContent({
          paymentId: pagePrintFlag == 'sfPrintTag' ? paymentId : undefined,
          treatmentId: pagePrintFlag == 'sfPrintTag' ? undefined : treatmentId,
        })
      )
        .then(unwrapResult)
        .then((data: any) => {
          JsBarcode('#barcode', data?.patient?.id)
          const dataMainList = {
            tenantName: tenantName?.split('-')?.[0],
            userName: userName,
            recipeCategory: recipeCategory,
            doctorMessage: detail,
            recipeId: data?.recipeAllid,
            detailMessage: {
              ...data?.treatment,
              createTime: data?.treatment?.createTime
                ? moment(data?.treatment?.createTime)?.format(
                    DateTimeFormatSimpleOne
                  )
                : '-',
              codeImg: document.getElementById('barcode')?.getAttribute('src'),
            },
            patientMessage: {
              ...data?.patient,
              sex: getSexData(Number(data?.patient?.sex)),
              patientAddress: data?.patientAddress,
            },
          }
          const page: any = window.open('', '_blank') // 打开一个新窗口，用于打印

          page?.document?.write(PrintDiagnosisCertificateContent(dataMainList)) // 写入打印页面的内容

          setTimeout(function () {
            page.print() // 打印
            page?.close()
            onCancel()
            setLoading(false)
            document.getElementById('barcode')?.setAttribute('src', '')
          }, 200)
        })
    }
  }

  const getRecipeExecuteForWeb = () => {
    const getWebPrintContent =
      pagePrintFlag == 'sfPrintTag'
        ? getWebPrintDataByPaymentId
        : getWebPrintDataByTreatmentId
    if (paymentId || treatmentId) {
      dispatch(
        getWebPrintContent({
          paymentId: pagePrintFlag == 'sfPrintTag' ? paymentId : undefined,
          treatmentId: pagePrintFlag == 'sfPrintTag' ? undefined : treatmentId,
        })
      )
        .then(unwrapResult)
        .then((data: any) => {
          // pagePrintFlag == 'sfPrintTag'   收费标志
          // 执行单打印西药方处方明细接口获取
          const list = data?.recipeWebPrintVOList?.map((v: any) => {
            return {
              ...v,
              itemList: v?.recipeItemWebPrintVOList,
            }
          })
          let printZXDList
          //收费页面进来的，需要打印所有处方
          if (pagePrintFlag == 'sfPrintTag') {
            // 结算时执行单打印西药，治疗，检查处方
            const printList = list
              ?.filter(
                (v: any) =>
                  v?.category == 1 || v?.category == 4 || v?.category == 6
              )
              ?.map((val: any) => {
                return val?.itemList
              })
              ?.flat(1)
            const sortDataList =
              _.chain(printList)
                .map((m) => fromMaterialJson(m))
                .sort((a, b) => {
                  if (a.groupNumber < b.groupNumber) {
                    return -1
                  }
                  if (a.groupNumber > b.groupNumber) {
                    return 1
                  }
                  return a.sort - b.sort
                })
                .value() || []
            printZXDList = sortDataList?.filter((v: any) => {
              return (
                v?.normalUsemethod == '静滴' ||
                v?.normalUsemethod == '肌注' ||
                v?.normalUsemethod == '静滴(JDZ)' ||
                v?.normalUsemethod == '静注' ||
                v?.normalUsemethod == '穴注' ||
                v?.normalUsemethod == '雾化吸入' ||
                v?.normalUsemethod == '静推' ||
                v?.normalUsemethod == '皮下'
              )
            })
          } else {
            const sortDataList =
              _.chain(printZXDData)
                .map((m) => fromMaterialJson(m))
                .sort((a, b) => {
                  if (a.groupNumber < b.groupNumber) {
                    return -1
                  }
                  if (a.groupNumber > b.groupNumber) {
                    return 1
                  }
                  return a.sort - b.sort
                })
                .value() || []
            //否则，只打印当前处方
            printZXDList = sortDataList?.filter((v: any) => {
              return (
                v?.normalUsemethod == '静滴' ||
                v?.normalUsemethod == '肌注' ||
                v?.normalUsemethod == '静滴(JDZ)' ||
                v?.normalUsemethod == '静注' ||
                v?.normalUsemethod == '穴注' ||
                v?.normalUsemethod == '雾化吸入' ||
                v?.normalUsemethod == '静推' ||
                v?.normalUsemethod == '皮下'
              )
            })
          }

          const dataMainContent = {
            tenantName: tenantName?.split('-')?.[0],
            userName: userName,
            dataMain: {
              treatment: data?.treatment,
              // 执行单打印数据
              materials: printZXDList,
              // 签名
              SignatureSrc: data?.printSettingData?.userSign,
              printAddress: data?.patientAddress,
            },
          }
          if (printZXDList?.length) {
            const page: any = window.open('', '_blank') // 打开一个新窗口，用于打印
            page?.document?.write(PrintZXDBody(dataMainContent)) // 写入打印页面的内容
            setTimeout(function () {
              page?.print() // 打印
              onCancel()
              setLoading(false)
              page?.close()
            }, 200)
          } else {
            notification.info({
              message: '暂无可打印执行单',
            })
            onCancel()
            setLoading(false)
          }
        })
    } else {
      notification.error({
        message: '缺少必要的打印参数',
      })
    }
  }

  const getRecipeExecuteZLForWeb = () => {
    const getWebPrintContent =
      pagePrintFlag == 'sfPrintTag'
        ? getWebPrintDataByPaymentId
        : getWebPrintDataByTreatmentId
    if (paymentId || treatmentId) {
      dispatch(
        getWebPrintContent({
          paymentId: pagePrintFlag == 'sfPrintTag' ? paymentId : undefined,
          treatmentId: pagePrintFlag == 'sfPrintTag' ? undefined : treatmentId,
          printType: 1,
        })
      )
        .then(unwrapResult)
        .then((data: any) => {
          // 执行单打印西药方处方明细接口获取
          const list = data?.recipeWebPrintVOList?.map((v: any) => {
            return {
              ...v,
              itemList: v?.recipeItemWebPrintVOList,
            }
          })
          const printList = list
            ?.map((val: any) => {
              return val?.itemList
            })
            ?.flat(1)

          let printZXDList
          //收费页面进来的，需要打印所有非药品处方
          if (pagePrintFlag == 'sfPrintTag') {
            // 结算时执行单打印西药处方
            const sortDataList =
              _.chain(printList)
                .map((m) => fromMaterialJson(m))
                .sort((a, b) => {
                  if (a.groupNumber < b.groupNumber) {
                    return -1
                  }
                  if (a.groupNumber > b.groupNumber) {
                    return 1
                  }
                  return a.sort - b.sort
                })
                .value() || []
            printZXDList = sortDataList?.filter((v: any) => {
              return v?.drugType == 1
            })
          } else {
            const sortDataList =
              _.chain(printList)
                .map((m) => fromMaterialJson(m))
                .sort((a, b) => {
                  if (a.groupNumber < b.groupNumber) {
                    return -1
                  }
                  if (a.groupNumber > b.groupNumber) {
                    return 1
                  }
                  return a.sort - b.sort
                })
                .value() || []
            //否则，只打印当前处方
            printZXDList = sortDataList?.filter((v: any) => {
              return v?.drugType == 1
            })
          }

          const dataMainContent = {
            tenantName: tenantName?.split('-')?.[0],
            userName: userName,
            dataMain: {
              treatment: data?.treatment,
              // 执行单打印数据
              materials: printZXDList,
              // 签名
              SignatureSrc: data?.printSettingData?.userSign,
              printAddress: data?.patientAddress,
            },
          }

          if (printZXDList?.length) {
            const page: any = window.open('', '_blank') // 打开一个新窗口，用于打印
            page?.document?.write(PrintZLZXDBody(dataMainContent)) // 写入打印页面的内容
            setTimeout(function () {
              page?.print() // 打印
              onCancel()
              setLoading(false)
              page?.close()
            }, 200)
          } else {
            notification.info({
              message: '暂无可打印执行单',
            })
            onCancel()
            setLoading(false)
          }
        })
    } else {
      notification.error({
        message: '缺少必要的打印参数',
      })
    }
  }

  // 前置机模式的开票接口
  const openDzfpForMbfe = (
    bodyJson: any,
    serverType: serverType,
    responseString?: string
  ) => {
    dispatch(
      invoiceListAsyncForMbfe({
        serverType,
        ...bodyJson,
        responseString: encodeURIComponent(
          responseString
            ?.replace('>"', '>')
            ?.replace('"<', '<')
            .replace(/\\/g, '') || ''
        ),
      })
    )
      .then(unwrapResult)
      .then((res) => {
        if (serverType === 'requestString') {
          MbfeService(
            'HX_ZJ_CZPJ',
            {
              trade_args: [
                'dzfpOpen',
                res?.url,
                res?.requestString,
                'nodata',
                'nodata',
              ],
              message: '开具电子发票',
            },
            (call: any, err) => {
              if (!err) {
                openDzfpForMbfe(
                  bodyJson,
                  'response',
                  JSON.stringify(call.netmessage.result)
                )
              }
            }
          )
        } else {
          notification.success({
            message: '操作成功',
          })
          setLoading(false)
          setInvoiceVisible(false)
          setInvoicePrinted([])
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    if (!(visible && !isModalVisible)) {
      document.getElementById('barcode')?.setAttribute('src', '')
    }
  }, [visible, isModalVisible])

  return (
    <>
      <Modal
        title="选择打印模版"
        width={1100}
        visible={visible && !isModalVisible}
        footer={null}
        onCancel={onCancel}
        {...others}
        destroyOnClose={ ishb ? true : false}
      >
        <GlobalKeyboardAction
          onSpacePress={async () => {
            if (
              !(visible && !isModalVisible) ||
              !hotkeyTimeWaitFlag ||
              loading
            ) {
              return;
            }
            const ids = [...selectedTemplateIds];
            if(ids?.length){
              setLoading(true);
            }
            for (let i = 0; i < ids.length; ++i) {
              const template: any = templates.find(
                (t) => t.id === ids[i] && t.invoiceType !== "3"
              );
              //网页打印
              if (template?.templateCode?.indexOf("ForWeb") > 0) {
                getWebPrintDataMain(template?.templateCode);
                //lodop打印
              } else {
                if (template) {
                  let status = null;
                  if (template.invoiceType == "2") {
                    status = {
                      insuranceCode,
                      invoiceCode: chargeNumber.invoiceCode,
                      invoiceCoifigId: chargeNumber.id,
                      invoiceNum: getZeroSupply(
                        chargeNumber.currentNo,
                        chargeNumber.invoiceNumberLength
                      ),
                      paymentId: paymentId,
                      templateId: template.id,
                      treatmentId,
                    };
                    // dispatch(invoicedetailsOpenAsync(data))
                  }
                  await print(template, status);
                  onCancel();
                  setLoading(false);
                }
              }
            }
            if (treatmentId) {
              ids.forEach((v, i) => {
                const template = templates.find(
                  (t) => t.id === ids[i] && t.invoiceType === "3"
                );
                template &&
                  dispatch(getRecipeList(treatmentId!))
                    .then(unwrapResult)
                    .then((res: any) => {
                      setRecipeList(
                        res?.map((item: any) => {
                          return {
                            ...item,
                            open: false,
                          };
                        })
                      );
                      form.setFieldsValue({
                        psnName: res[0].name,
                        phone: res[0].phone,
                        idCardNumber: patient?.idCardNumber,
                      });
                      setInvoicePrinted(
                        res
                          ?.filter((item: any) => {
                            return item.invoiceFlag !== "1";
                          })
                          ?.map((v: any) => v.id)
                      );
                      setInvoiceVisible(true);
                      onCancel();
                      setLoading(false);
                    });
              });
            }
          }}
        />
        <img id="barcode" style={{ display: "none" }}></img>
        <Col style={{ paddingBottom: 40 }}>
          <Row
            style={{ marginBottom: 20 }}
            justify={isRecipe || ishb ? "space-between" : "end"}
          >
            {isRecipe ? (
              <Space>
                <span
                  style={{
                    borderBottom: "1px solid rgb(2, 122, 255)",
                    color: "rgb(2, 122, 255)",
                    fontSize: "16px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    onChange();
                  }}
                >
                  处方打印
                </span>
              </Space>
            ) : (
              ""
            )}
            <Space>
              {ishb ? (
                <Row>
                  <DatePicker.RangePicker
                    defaultValue={[
                      moment().startOf("month"),
                      moment()
                    ]}
                    onChange={(v) => {
                      if (v) {
                        setHeboTime([
                          moment(v[0]).format("YYYY-MM-DD 00:00:00"),
                          moment(v[1]).format("YYYY-MM-DD 23:59:59"),
                        ]);
                      } else {
                        setHeboTime([]);
                      }
                    }}
                  />
                </Row>
              ) : (
                <></>
              )}
            </Space>
            <Space>
              <Checkbox
                style={{ fontSize: "1rem", color: theme.tc1 }}
                checked={isPreview}
                onChange={(e) =>
                  dispatch(
                    setSettings({
                      [Settings.Printing.Preview]: e.target.checked,
                    })
                  )
                }
              >
                是否预览
              </Checkbox>
              <Button
                type="primary"
                disabled={selectedTemplateIds.length === 0 || !templates.length}
                loading={loading}
                onClick={async () => {
                  setLoading(true);
                  const ids = [...selectedTemplateIds];
                  for (let i = 0; i < ids.length; ++i) {
                    const template: any = templates.find(
                      (t) => t.id === ids[i] && t.invoiceType !== "3"
                    );
                    //网页打印
                    if (template?.templateCode?.indexOf("ForWeb") > 0) {
                      getWebPrintDataMain(template?.templateCode);
                      //lodop打印
                    } else {
                      if (template) {
                        let status = null;
                        if (template.invoiceType == "2") {
                          status = {
                            insuranceCode,
                            invoiceCode: chargeNumber.invoiceCode,
                            invoiceCoifigId: chargeNumber.id,
                            invoiceNum: getZeroSupply(
                              chargeNumber.currentNo,
                              chargeNumber.invoiceNumberLength
                            ),
                            paymentId: paymentId,
                            templateId: template.id,
                            treatmentId,
                          };
                          // dispatch(invoicedetailsOpenAsync(data))
                        }
                        await print(template, status);
                        onCancel();
                        setLoading(false);
                      }
                    }
                  }
                  if (treatmentId) {
                    ids.forEach((v, i) => {
                      const template = templates.find(
                        (t) => t.id === ids[i] && t.invoiceType === "3"
                      );
                      template &&
                        dispatch(getRecipeList(treatmentId!))
                          .then(unwrapResult)
                          .then((res: any) => {
                            setRecipeList(
                              res?.map((item: any) => {
                                return {
                                  ...item,
                                  open: false,
                                };
                              })
                            );
                            form.setFieldsValue({
                              psnName: res[0].name,
                              phone: res[0].phone,
                              idCardNumber: patient?.idCardNumber,
                            });
                            setInvoicePrinted(
                              res
                                ?.filter((item: any) => {
                                  return item.invoiceFlag !== "1";
                                })
                                ?.map((v: any) => v.id)
                            );
                            setInvoiceVisible(true);
                            onCancel();
                            setLoading(false);
                          });
                    });
                  }
                }}
              >
                打印（空格）
              </Button>
            </Space>
          </Row>
          <EditableList<PrintTemplate>
            pagination={false}
            dataSource={templates}
            rowSelection={{
              type: "checkbox",
              selectedRowKeys: selectedTemplateIds,
              onChange: (ids) => {
                setSelectedTemplateIds(ids as string[]);
                window.localStorage.setItem("PRINTKEYS", JSON.stringify(ids));
              },
            }}
            onRow={(d) => ({
              onClick: (e) => {
                e.stopPropagation();
                const isCheck = selectedTemplateIds.includes(d?.id);
                let ids = [];
                if (isCheck) {
                  ids = selectedTemplateIds.filter((id) => id !== d?.id);
                } else {
                  ids = [...selectedTemplateIds, d?.id];
                }
                setSelectedTemplateIds(ids as string[]);
                window.localStorage.setItem("PRINTKEYS", JSON.stringify(ids));
              },
            })}
            columns={[
              {
                title: "序号",
                key: "no",
                align: "center",
                width: "5rem",
                render: (_1, _2, i) => `${i + 1}`,
              },
              {
                title: "模板类型",
                align: "center",
                dataIndex: "invoiceType",
                width: "6rem",
                render: (_, t) => getInvoiceType(t.invoiceType),
              },
              {
                title: "模版描述",
                align: "center",
                dataIndex: "templateDesc",
                // ellipsis: true,
                render: function desc(_, t) {
                  return (
                    <div className={styels.desc}>
                      {t.templateDesc}
                      {t.invoiceType == "2" && (
                        <>
                          <span
                            style={{
                              marginLeft: 15,
                            }}
                          >
                            发票代码：{chargeNumber?.invoiceCode} &emsp;
                            发票号码：
                            {getZeroSupply(
                              chargeNumber?.currentNo,
                              chargeNumber?.invoiceNumberLength
                            )}
                          </span>
                          <Button
                            type="link"
                            onClick={() => {
                              setEditNoViaible(true);
                            }}
                          >
                            修改
                          </Button>
                        </>
                      )}
                    </div>
                  );
                },
              },
              // {
              //   title: '预览',
              //   align: 'center',
              //   dataIndex: 'invoiceType',
              //   width: '6rem',

              //   render: function Element(_, t) {
              //     return (
              //       <>
              //         <p
              //           style={{
              //             color: '#057cff',
              //             cursor: 'pointer',
              //             height: 15,
              //           }}
              //           onClick={() => {
              //             dispatch(getTemplateImg({ id: t.id }))
              //               .then(unwrapResult)
              //               .then((res) => {
              //                 if (res.templatePreview != '') {
              //                   setImgSrc(res.templatePreview)
              //                   setImgVisible(true)
              //                 } else {
              //                   notification.error({
              //                     message: '请联系管理员上传预览图',
              //                   })
              //                 }
              //               })
              //           }}
              //         >
              //           预览
              //         </p>
              //       </>
              //     )
              //   },
              // },
              {
                title: "选择打印机",
                key: "printers",
                align: "center",
                width: 230,
                render: function ShowPrinters(_, t) {
                  return t.invoiceType !== "3" ? (
                    t?.templateCode?.indexOf("ForWeb") > 0 ? (
                      "-"
                    ) : (
                      <Select
                        style={{ width: 220 }}
                        value={defaultPrinters[t.id]}
                        onChange={async (v) => {
                          await dispatch(
                            setTemplateDefaultPrinter({
                              code: t.id,
                              printer: v,
                            })
                          );
                          // onCancel()
                        }}
                      >
                        {printers.map((p) => (
                          <Select.Option key={p.id} value={p.name}>
                            {p.name}
                          </Select.Option>
                        ))}
                      </Select>
                    )
                  ) : (
                    ""
                  );
                },
              },
            ]}
          />
        </Col>
      </Modal>

      {/* 修改发票号码 */}
      <Modal
        title="修改号码"
        visible={editNoViaible}
        onCancel={() => {
          setEditNoViaible(false);
          setInvoiceNo("");
        }}
        onOk={() => {
          // setEditNoViaible(false)
          if (!invoiceNo) return;
          const data = {
            paymentId,
            invoiceCode: chargeNumber?.invoiceCode,
            invoiceNum: invoiceNo,
          };
          dispatch(checkRepeatInvoiceAsync(data))
            .then(unwrapResult)
            .then((res: any) => {
              if (res) {
                setTipsMessage(res);
                setRepeatVisible(true);
              } else {
                setEditNoViaible(false);
                setNo();
              }
            });
        }}
        maskClosable={false}
      >
        <Input
          value={invoiceNo}
          onChange={(e) => {
            const reg = /^\d+$/;
            const value = e.target.value ? e.target.value.trim() : "";
            if (reg.test(value) || !value) {
              setInvoiceNo(value);
            }
          }}
          maxLength={12}
        />
      </Modal>

      {/* 发票重复弹框 */}
      <Modal
        title="号码重复"
        visible={repeatVisible}
        onCancel={() => setRepeatVisible(false)}
        onOk={() => {
          setNo();
          setEditNoViaible(false);
          setRepeatVisible(false);
        }}
        maskClosable={false}
        mask={false}
      >
        {tipsMessage}
      </Modal>

      <Modal
        title="您电脑上未检测到打印软件"
        footer={null}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
      >
        <div>
          请先安装该软件：
          {/* <a href="http://demo.c-lodop.com:8000/Lodop6.226_Clodop4.127.zip"> */}
          <a href="https://hydhis-public.oss-cn-hangzhou.aliyuncs.com/setup/install_lodop32.exe?versionId=CAEQRRiBgIC9wL33mxgiIDc0MDRlNmEzMTI3YTQwNWQ5YTA1MzMyZGQ2NWI2ZWU2">
            点击下载
          </a>
        </div>
      </Modal>
      {/* 电子发票打印弹框 */}
      <Modal
        title="选择需要开票的处方（可多选）"
        footer={null}
        width={1200}
        visible={invoiceVisible}
        onCancel={() => {
          setInvoiceVisible(false);
          setInvoicePrinted([]);
        }}
      >
        <Checkbox.Group
          style={{ width: "100%" }}
          value={invoicePrinted}
          onChange={(e) => {
            setInvoicePrinted(e);
          }}
        >
          {recipeList.length &&
            recipeList.map((r, i) => {
              return (
                <>
                  <Row
                    key={r.id}
                    style={{
                      marginTop: 20,
                      marginLeft: 10,
                      marginRight: 10,
                      padding: "0 10px",
                      height: "2.5rem",
                      backgroundColor: theme.th,
                    }}
                    onClick={() => {
                      const data = recipeList.map((item) => {
                        return r.id === item.id
                          ? { ...item, open: !item.open }
                          : item;
                      });
                      setRecipeList(data);
                    }}
                    align="middle"
                  >
                    <Col
                      flex={1}
                      style={{ color: theme.tc1, fontSize: "1rem" }}
                    >
                      {i + 1}&ensp;
                      <Checkbox
                        disabled={r.invoiceFlag === "1"}
                        value={r.id}
                        onClick={(e) => e.stopPropagation()}
                      />
                      &ensp;
                      {getRecipeCategoryName(r.category)}
                      处方单，处方编号：
                      {r.id}
                    </Col>
                    <Col>
                      <a style={{ fontSize: "1rem", marginRight: 10 }}>
                        单击查看处方信息
                      </a>
                    </Col>
                    <Col>
                      {r?.open ? <CaretUpFilled /> : <CaretDownFilled />}
                    </Col>
                  </Row>
                  {r?.open && (
                    <Table
                      style={{ margin: "0 10px" }}
                      pagination={false}
                      size="small"
                      bordered
                      columns={
                        r.category === RecipeCategory.Tcm ||
                        r.category === RecipeCategory.TcmGranules
                          ? TcmColumns(theme)
                          : MedicineMaterialColumns(theme, r.itemList)
                      }
                      dataSource={r.itemList}
                    />
                  )}
                </>
              );
            })}
        </Checkbox.Group>
        <Row style={{ marginTop: "20px", padding: "0 10px" }}>
          <Form
            {...layout}
            form={form}
            autoComplete="off"
            name="invoice-hooks"
            style={{ width: "100%" }}
            initialValues={{
              openType: 0,
            }}
            onFinish={(values) => {
              setLoading(true);
              if (!invoicePrinted.length) {
                notification.error({
                  message: "请选择需要开票的处方",
                });
                setLoading(false);
                return;
              }
              // if (!values.phone && !values.email) {
              //   notification.error({
              //     message: '至少填写一个发票接收人信息',
              //   })
              //   setLoading(false)
              //   return
              // }
              // setLoading(false)
              const data = {
                ...values,
                patientId,
                treatmentId,
                recipeIds: invoicePrinted,
                supplierId: credentials?.dzfpSupplierId, // 只有一个供应商目前写死为1
              };
              //厂商ID为4:浙江财政票据时，使用前置机方式发起交易，其他均采用服务器模式发送交易
              if (credentials?.dzfpSupplierId == "4") {
                openDzfpForMbfe(data, "requestString");
              } else {
                dispatch(invoiceListAsync(data))
                  .then(unwrapResult)
                  .then(() => {
                    notification.success({
                      message: "操作成功",
                    });
                    setLoading(false);
                    setInvoiceVisible(false);
                    setInvoicePrinted([]);
                  })
                  .catch((v) => {
                    setLoading(false);
                    if (String(v?.message).indexOf("人脸认证失效") != -1) {
                      setIsQrCodeVisible(true);
                    }
                  })
                  .finally(() => {});
              }
            }}
          >
            <div style={{ marginBottom: "10px" }}>开票信息</div>
            <Row gutter={10}>
              <Col span={8}>
                <Form.Item name="openType" label="开票方类型">
                  <Select
                    placeholder="开票方类型"
                    // suffixIcon={<CaretDownFilled />}
                    onChange={(e) => {
                      setShowPerson(e === 0);
                    }}
                  >
                    <Option value={0}>个人</Option>
                    <Option value={1}>企业</Option>
                  </Select>
                </Form.Item>
              </Col>
              {showPerson ? (
                <>
                  <Col span={7}>
                    <Form.Item
                      name="psnName"
                      label="姓名"
                      getValueFromEvent={stringTrim}
                      rules={[{ required: true }]}
                    >
                      <Input placeholder="请输入姓名" allowClear />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="idCardNumber"
                      label="身份证号"
                      getValueFromEvent={stringTrim}
                    >
                      <Input placeholder="请输入身份证号" allowClear />
                    </Form.Item>
                  </Col>
                </>
              ) : (
                <>
                  <Col span={9}>
                    <Form.Item
                      name="companyName"
                      label="企业名称（抬头）"
                      getValueFromEvent={stringTrim}
                      rules={[{ required: true }]}
                    >
                      <Input placeholder="请输入企业名称（抬头）" allowClear />
                    </Form.Item>
                  </Col>
                  <Col span={7}>
                    <Form.Item
                      name="companyTaxNo"
                      label="企业税号"
                      getValueFromEvent={stringTrim}
                      rules={[{ required: true }]}
                    >
                      <Input placeholder="请输入企业税号" allowClear />
                    </Form.Item>
                  </Col>
                </>
              )}
            </Row>
            <div style={{ marginBottom: "10px" }}>发票接收人信息</div>
            <Row gutter={10}>
              <Col span={8}>
                <Form.Item
                  name="phone"
                  label="接收人手机号"
                  getValueFromEvent={stringTrim}
                  validateTrigger="onBlur"
                  rules={[
                    {
                      pattern: /^1\d{10}$/,
                      message: "请输入正确手机号",
                    },
                  ]}
                >
                  <Input placeholder="请输入手机号" maxLength={11} allowClear />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="email"
                  validateTrigger="onBlur"
                  rules={[{ type: "email" }]}
                  label="接收人电子邮箱地址"
                  getValueFromEvent={stringTrim}
                >
                  <Input placeholder="请输入电子邮箱地址" allowClear />
                </Form.Item>
              </Col>
            </Row>
            <Row justify="end">
              <Space>
                <Button
                  onClick={() => {
                    setInvoiceVisible(false);
                    setInvoicePrinted([]);
                  }}
                >
                  取消
                </Button>
                <Button type="primary" htmlType="submit" loading={loading}>
                  确认
                </Button>
              </Space>
            </Row>
          </Form>
        </Row>
      </Modal>
      <Modal
        title="二维码"
        footer={null}
        visible={isQrCodeVisible}
        onCancel={() => setIsQrCodeVisible(false)}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {isQrCodeData && (
            <Qrcode
              id="qrcode2"
              value={isQrCodeData?.ewm}
              style={{
                width: 240,
                height: 240,
              }}
            />
          )}
        </div>
      </Modal>
      <Image
        style={{ display: "none" }}
        src={imgSrc}
        preview={{
          visible: imgVisible,
          onVisibleChange: (value) => {
            setImgVisible(value);
          },
        }}
      />
    </>
  );
}
