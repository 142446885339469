/*
 * @Descripttion:
 * @version:
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-07-13 13:56:42
 * @LastEditors: linxi
 * @LastEditTime: 2023-07-25 17:19:15
 */
import { SearchOutlined } from '@ant-design/icons'
import { Button, Form, Input, Row, Select, Space } from 'antd'
import React, { useContext } from 'react'
import { useDispatch } from 'react-redux'
import { RootDispatch } from '../../app/store'
import { DateRangeType, DateSelect } from '../../compnents/form/DateSelect'
import { ThemeContext } from '../../theme/ThemeContext'
import styles from './InsuranceSettlementRecord.module.css'

export const Query = (props: {
  onValueChange: (queries: any) => void
  export: (v?: any) => void
  showCS:()=> void
}) => {
  const dispatch = useDispatch<RootDispatch>()

  const [form] = Form.useForm()

  const theme = useContext(ThemeContext)

  return (
    <div>
      <Form
        form={form}
        autoComplete='off'
        onFinish={(values) => {
          props.onValueChange({
            ...values,
          })
        }}
      >
        <Row
          align='middle'
          justify='space-between'
          style={{ marginTop: 10, marginBottom: 20 }}
        >
          <Space>
            <Form.Item name='psnName' noStyle>
              <Input
                placeholder='请输入姓名'
                style={{ width: '8rem' }}
                prefix={<SearchOutlined color={theme.tc3} />}
                allowClear
                onChange={form.submit}
              />
            </Form.Item>
            <Form.Item name='yidibz' noStyle>
              <Select
                placeholder='状态'
                onChange={form.submit}
                allowClear
                style={{ width: 120 }}
              >
                <Select.Option value={0}>本地</Select.Option>
                <Select.Option value={1}>省内异地</Select.Option>
                <Select.Option value={2}>省外异地</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item name='xianzhonglx' noStyle>
              <Select
                placeholder='险种类型'
                style={{ width: '8rem' }}
                allowClear
                // suffixIcon={<CaretDownFilled />}
                onChange={form.submit}
              >
                <Select.Option value={310}>职工</Select.Option>
                <Select.Option value={340}>离休</Select.Option>
                <Select.Option value={390}>城乡居民</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item name='areaName' noStyle>
              <Input
                placeholder='请输入地区'
                style={{ width: '8rem' }}
                allowClear
                onChange={form.submit}
              />
            </Form.Item>
            <DateSelect
              style={{ width: '7rem' }}
              labelPrefix=''
              namePrefix='time'
              placeholder='请选择时间'
              onChange={() => form.submit()}
              allowClear={false}
              initialValue={DateRangeType.Today}
            />
            <Button type='primary' onClick={form.submit}>
              查询
            </Button>
          </Space>
          <Space>
            <div style={{ color: '#999999' }}>
              （最多支持导出近3个月10000条内的数据，更多请联系客服）
            </div>
            <Button
              type='primary'
              onClick={() => {
                props?.export && props?.export()
              }}
            >
              导出
            </Button>
            <div
              className={styles.noShowButton}
              onDoubleClick={() => {
                props?.showCS && props?.showCS()
              }}
            ></div>
          </Space>
        </Row>
      </Form>
    </div>
  )
}
