/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-06-29 16:16:11
 * @LastEditors: linxi
 * @LastEditTime: 2024-10-29 15:56:49
 */
import { CaretDownFilled, PlusOutlined, SearchOutlined } from '@ant-design/icons'
import { unwrapResult } from '@reduxjs/toolkit'
import { Button, Form, Input, Modal, Row, Select, Space } from 'antd'
import moment from 'moment'
import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {selectInsuranceArray, selectUserId} from '../../../app/applicationSlice'
import { RootDispatch } from '../../../app/store'
import {
  DateRangeType,
  DateSelect,
  getDateRange,
  ToothDay,
} from '../../../compnents/form/DateSelect'
import {
  appointmentStatus,
  getAppointTypeName,
  appointmentStatusOptions,
} from '../../../models/appointment'
import { DateTimeFormat } from '../../../models/datetime'
import { StationCategory } from '../../../models/user'
import { InvoicePageParams, PaymentListParams } from '../../../services/payment'
import { getUsersInDepartmentAsync } from '../../completion/completionSlice'
import { uploadOptions } from '../../InventoryChecking/query'
import {
  getDepartments,
  selectDepartments,
} from '../../payment/list/paymentSlice'
import { AppointmentRegister } from '../../registration/register/appointmentRegister'
import { selectBtnLoading, setBtnLoading } from '../appointmentSelice'
// import { selectDepartments } from '../list/paymentSlice'

export const Query = (props: {
  onValueChange: (params: any) => void
  YBZFUploadflag?: boolean
  patientId?: string
  type: number
  toothPatient?: any
}): ReactElement => {
  const loading = useSelector(selectBtnLoading)
  const [form] = Form.useForm()

  const dispatch = useDispatch<RootDispatch>()

  const { Option } = Select

  const departments = useSelector(selectDepartments)

  const userId = useSelector(selectUserId) //登录是用户数据

  const [doctorList, setDoctorList] = useState<any>([])

  const [toothEditAppointmentMoal, setToothEditAppointmentMoal] = useState(
    false
  ) //  修改预约弹窗

  const storageParams = JSON.parse(
    sessionStorage.getItem(location.pathname+"_yuyue") || '{}'
  )


  const [head, tail] = getDateRange(DateRangeType.Tomorrow)

  useEffect(() => {
    dispatch(getDepartments())
    dispatch(
      getUsersInDepartmentAsync({
        departmentId: '',
        stationCategory: StationCategory.DoctorOrPharmacist,
      })
    )
    .then(unwrapResult)
    .then((res) => {
      setDoctorList(res)
      if (Object.keys(storageParams).length == 0) {
        form.setFieldsValue({
          mainDoctorId: res?.find(
              (v: any) => v.id == userId && v.kssxbz != 1
          )?.id,
        })
        form.submit()
      }
      subsearch(res);
    })


  }, [])


  const subsearch = (dList:any) => {
    if (
        Object.keys(storageParams).length &&
        !props.patientId &&
        (location.pathname == '/toothHome' || location.pathname == '/toothPatient' )
    ) {
      form.setFieldsValue({
        ...storageParams,
        mainDoctorId:
            storageParams?.mainDoctorId != null
                ? storageParams?.mainDoctorId
                : dList?.find((v: any) => v.id == userId && v.kssxbz != 1)?.id
      })
    } else {
      form.setFieldsValue({
        _dateRange: 13,
        actionTimeHead: head,
        actionTimeTail: tail,
      })
    }
    form.submit()
  }

  const refresh = () => {
    props.onValueChange({
      ...storageParams,
      ...form.getFieldsValue(),
      current: 1,
    })
  }

  const appointmentStatusOption = props.patientId
    ? appointmentStatus.filter((v: any) => v.value == 1 || v.value == 2)
    : appointmentStatus

  return (
    <>
    <Form
      form={form}
      style={{ marginTop: props.type == 1 ? '-20px' : 0 }}
      onFinish={(values) => {
        props.onValueChange({
          ...storageParams,
          ...values,
          current: storageParams.current || 1,
        })
      }}
    >
      <Row style={{ marginTop: 10, marginBottom: 10 }} justify='space-between'>
        <Space>
          {!props.patientId && !props?.toothPatient && (
            <Form.Item noStyle name='name'>
              <Input
                autoComplete='off'
                prefix={<SearchOutlined />}
                placeholder='姓名/手机号/助记码/病历号'
                style={{ width: '16rem' }}
                allowClear
                onChange={refresh}
              />
            </Form.Item>
          )}
          <Form.Item noStyle name='departmentId'>
            <Select
              style={{ width: '10rem' }}
              placeholder='查看范围-科室列表'
              allowClear
              onChange={refresh}
            >
              {departments.map((d) => (
                <Select.Option key={d.id} value={d.id}>
                  {d.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name='mainDoctorId' noStyle>
            <Select
              placeholder='医生列表'
              allowClear
              onChange={refresh}
              showSearch
              style={{ width: '10rem' }}
              filterOption={(input, option) => {
                return option?.className
                  ? ((option?.className as unknown) as string)
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  : false
              }}
            >
              {doctorList.map((v: any) => (
                <Option className={v.mnemonicCode} key={v.id} value={v.id}>
                  {v.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item noStyle name='appointmentType'>
            <Select
              style={{ width: '10rem' }}
              placeholder='预约方式'
              allowClear
              onChange={refresh}
            >
              {appointmentStatusOptions.map((d) => (
                <Select.Option key={d} value={d}>
                  {getAppointTypeName(d)}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item noStyle name='appointmentState'>
            <Select
              style={{ width: '10rem' }}
              placeholder='预约状态'
              allowClear
              onChange={refresh}
            >
              {appointmentStatusOption.map((d) => (
                <Select.Option key={d.value} value={d.value}>
                  {d.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <DateSelect
            style={{ width: '8.5rem' }}
            allowClear={false}
            labelPrefix=''
            namePrefix='actionTime'
            initialValue={DateRangeType.Today}
            placeholder='时间'
            options={ToothDay}
            onChange={(e) => {
              if (e !== 6) {
                refresh()
              }
            }}
          />
          <Button
            type='primary'
            onClick={() => {
              refresh()
              dispatch(setBtnLoading(true))
            }}
            loading={loading}
          >
            查询
          </Button>
        </Space>
        {props?.toothPatient ? (
          <Space>
            <Button
             icon={<PlusOutlined />}
              type='primary'
              onClick={() => {
                setToothEditAppointmentMoal(true)
              }}
              loading={loading}
            >
              新增预约
            </Button>
          </Space>
        ) : (
          <></>
        )}
      </Row>
    </Form>
    <Modal
        title='新增预约'
        visible={toothEditAppointmentMoal}
        onCancel={() => {
          setToothEditAppointmentMoal(false)
        }}
        width={1200}
        bodyStyle={{
          height: 650,
          overflow: 'auto',
        }}
        footer={null}
        destroyOnClose
      >
        <div style={{ height: 600 }}>
          <AppointmentRegister
            status={1}
            onsubmit={() => {
              setToothEditAppointmentMoal(false)
            }}
            onCancel={() => {
              setToothEditAppointmentMoal(false)
            }}
          />
        </div>
      </Modal>
    </>
  )
}
