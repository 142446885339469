/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2022-12-26 15:31:12
 * @LastEditors: linxi
 * @LastEditTime: 2024-11-06 14:59:11
 */
import {
  CaretDownFilled,
  LoadingOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import { nanoid, unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Select,
  Space,
  Upload,
} from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { RcFile } from 'antd/lib/upload'
import { stubFalse } from 'lodash'
import { duration } from 'moment'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../app/store'
import { TenantFilled } from '../../compnents/icons/Icons'
import { ThemeContext } from '../../theme/ThemeContext'
import { isValidMobileNumber } from '../../utils/StringUtils'
import {
  getResetSecretSubmitAsync,
  getTenantsList,
  getVerificationCodeAsync,
} from './loglinSlice'

interface resetSecretModalProps {
  visible: boolean
  onCancel: () => void
  onOk: () => void
}
export const ResetSecretModal = ({
  visible,
  onCancel,
  onOk,
}: resetSecretModalProps): ReactElement => {
  const { Option } = Select

  const [form] = Form.useForm()

  const theme = useContext(ThemeContext)

  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch<RootDispatch>()

  const [countdown, setCountdown] = useState<any>()

  const [tenantList, setTenantList] = useState<any>()

  useEffect(() => {
    const tenantId = tenantList?.length ? tenantList[0].id : undefined
    form.setFieldsValue({
      tenantId,
    })
  }, [form, tenantList])

  useEffect(() => {
    if (countdown > 0) {
      setTimeout(() => {
        setCountdown(countdown - 1)
      }, 1000)
    }
  }, [countdown])

  useEffect(() => {
    if (!visible) {
      form.resetFields()
      setTenantList([])
      setLoading(false)
    }
  }, [visible])

  return (
    <Modal
      title='忘记密码'
      width={600}
      visible={visible}
      onCancel={() => {
        onCancel()
        form.resetFields()
      }}
      centered
      footer={null}
      maskStyle={{
        overflow: 'hidden',
      }}
    >
      <Form
        form={form}
        onFinish={(values) => {
          if (values?.newPwdAgain != values?.newPwd) {
            notification.error({
              message: '两次输入密码不一致，请核对',
            })
          } else {
            if (
              values?.newPwd == 'abc123456' ||
              values?.newPwdAgain == 'abc123456'
            ) {
              notification.error({
                message: '简单密码，请重新修改',
              })
            } else {
              setLoading(true)
              dispatch(getResetSecretSubmitAsync({ ...values }))
                .then(unwrapResult)
                .then((v: any) => {
                  notification.success({
                    message: '密码修改成功，请重新登陆！',
                    duration: 2,
                    onClose: () => {
                      form.resetFields()
                      setLoading(false)
                      onOk()
                    },
                  })
                })
                .catch(() => {
                  setLoading(false)
                })
            }
          }
        }}
        labelCol={{
          span: 6,
        }}
        wrapperCol={{
          span: 18,
        }}
      >
        <Form.Item
          name='phone'
          label='登录手机号码'
          style={{ fontSize: '1rem' }}
          rules={[{ required: true, message: '请填写登录手机号码' }]}
        >
          <Input
            placeholder='手机号码'
            allowClear
            onChange={(e) => {
              const text = e.target.value
              if (isValidMobileNumber(text)) {
                dispatch(getTenantsList(text))
                  .then(unwrapResult)
                  .then((v) => {
                    setTenantList(v)
                  })
              } else {
                setTenantList([])
              }
            }}
          />
        </Form.Item>
        <Form.Item
          label='机构列表'
          name='tenantId'
          rules={[{ required: true, message: '请选择诊所' }]}
        >
          <Select
            placeholder=' 机构列表'
            disabled={tenantList?.length <= 1}
            suffixIcon={
              tenantList?.length > 1 && (
                <CaretDownFilled style={{ color: theme.tc3 }} />
              )
            }
            style={{ color: theme.tc1 }}
          >
            {tenantList?.map((t: any) => (
              <Option key={t.id} value={t.id}>
                {t.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name='smsCode'
          label='验证码'
          rules={[{ required: true, message: '请填写验证码' }]}
        >
          <Input
            placeholder='手机验证码'
            autoComplete='off'
            suffix={
              <div
                style={{
                  width: '5.6rem',
                  height: '1.8rem',
                  lineHeight: '1.8rem',
                  textAlign: 'center',
                  fontSize: countdown > 0 ? '0.625rem' : '0.875rem',
                  color: countdown > 0 ? theme.tc3 : theme.tc2,
                  backgroundColor: theme.c3,
                  cursor: countdown > 0 ? 'not-allowed' : 'pointer',
                }}
                onClick={async (e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  if (countdown > 0) {
                    return
                  }
                  const phone = form.getFieldValue('phone')
                  const tenantId = form.getFieldValue('tenantId')
                  if (!isValidMobileNumber(phone)) {
                    notification.error({
                      message: '手机号码格式不正确',
                      duration: 3,
                    })
                    return
                  }
                  try {
                    await dispatch(
                      getVerificationCodeAsync({
                        phone: phone,
                        tenantId: tenantId,
                      })
                    ).then(unwrapResult)
                    setCountdown(60)
                  } catch {
                    // do nothing.
                  }
                }}
              >
                {countdown > 0 ? `获取验证码(${countdown}s)` : '获取验证码'}
              </div>
            }
          />
        </Form.Item>
        <Form.Item
          label='新密码'
          name='newPwd'
          style={{ fontSize: '1rem' }}
          rules={[
            { required: true, message: '请填写新密码' },
            {
              pattern: /^(?=.*[a-zA-Z])(?=.*\d)(?=\S+$)[a-zA-Z\d\W]{6,30}$/,
              message: '确认密码格式为6～30位，数字 + 字母',
            },
          ]}
        >
          <Input placeholder='新密码' allowClear />
        </Form.Item>
        <Form.Item
          label='再次输入新密码'
          name='newPwdAgain'
          style={{ fontSize: '1rem' }}
          rules={[
            { required: true, message: '请再次填写新密码' },
            {
              pattern: /^(?=.*[a-zA-Z])(?=.*\d)(?=\S+$)[a-zA-Z\d\W]{6,30}$/,
              message: '新密码格式为6～30位，数字 + 字母',
            },
          ]}
        >
          <Input placeholder='再次输入新密码' allowClear />
        </Form.Item>
        <div style={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
          <Space>
            <Button onClick={() => onCancel()}>取消</Button>
            <Button type='primary' htmlType='submit' loading={loading}>
              确定
            </Button>
          </Space>
        </div>
      </Form>
    </Modal>
  )
}
