/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-05-08 13:36:29
 * @LastEditors: linxi
 * @LastEditTime: 2024-10-08 14:28:35
 */
import {Button, Checkbox, Col, Modal, notification, Pagination, Row} from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import { NavBar } from '../../compnents/nav/NavBar'
import { CategoryName } from '../../models/category'
import {
  detailPutstorage,
  savelistPutstorageAsync, saveTakeStockAsync,
  selectDetail,
} from '../putstorage/putstorageSlice'
import { InventStockQuery } from '../putstorelist/Query/InventStockQuery'
import { EditableList } from '../../compnents/list/EditableList'
import moment from 'moment'
import { DateTimeFormat } from '../../models/datetime'
import { InventoryCloumn } from './columns'
import { changeRow, getInventroyAsync, selectPage } from './InventStockSlice'
import {
  saveStoreDetailAsync,
  selectorPutstoreData,
} from '../putstorelist/putstorelistSlice'
import { InventStockModal } from './InventoryMerge/InventStockModal'
import { RootDispatch } from '../../app/store'
import { unwrapResult } from '@reduxjs/toolkit'
import { AccountOutlined } from '../../compnents/icons/Icons'

export const InventoryStock = (props: {
  category?: string
  selectId?: number,
}) => {
  const history = useHistory()

  const dispatch = useDispatch<RootDispatch>()

  const detail: any = useSelector(selectDetail)

  const data = useSelector(selectorPutstoreData)

  const [flag, setFlag] = useState(false)

  const [fullAmountflag, setFullAmountflag] = useState(false)

  const [loading, setLoading] = useState(false)

  const [btnLoading, setBtnLoading] = useState(false)

  const location: any = useLocation()

  const [isModalVisible, setIsModalVisible] = useState(false)

  const [isVisible, setIsVisible] = useState(false)

  const categoryTag = props?.category || location.state?.category;

  const selectIdTag = props?.selectId || location.state?.selectId;

  const [queries, setQueries] = useState<any>({
    current: 1,
    size: 10,
  })

  const pages = useSelector(selectPage)

  const page = {
    ...pages,
    items: pages?.items?.map((v) => {
      return {
        ...v,
        realSplitCount: v?.realSplitCount > -1 ? v?.realSplitCount : undefined,
        realCount: v?.realCount > -1 ? v?.realCount : undefined,
      }
    }),
  }
  useEffect(() => {
    if (selectIdTag) {
      dispatch(detailPutstorage(selectIdTag))
    }
  }, [location])

  useEffect(() => {

    const fullAmountInventoryFlag = localStorage.getItem('fullAmountInventoryFlag')
    if (fullAmountInventoryFlag && fullAmountInventoryFlag === 'true') {
      setFullAmountflag(true)
    }

  }, [])

  useEffect(() => {
    if (JSON.stringify(detail) !== '{}') {
      setQueries({
        current: 1,
        storehouseId: detail.storehouseId,
      })
    }
  }, [detail?.storehouseId])

  useEffect(() => {
    if (JSON.stringify(queries) !== '{}') {
      if (detail?.storehouseId) {
        getDetailList()
      }
    }
  }, [queries, detail])

  const getDetailList = () => {
    setLoading(true)
    dispatch(
      getInventroyAsync({
        ...queries,
        storehouseId: detail?.storehouseId,
        tenantInventoryReceiptsId: selectIdTag,
      })
    )
      .then(unwrapResult)
      .then((v) => {
        setLoading(false)
      })
  }
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <Col
        style={{
          margin: '10px 20px',
        }}
      >
        {!props?.category ? <NavBar
          where={['库存查询', CategoryName(location.state.category) as string]}
          backtrace={{
            name: location.state?.tockSalesUploadCategory ? '进销存上传':CategoryName(location.state.category) as string,
            path: location.state?.tockSalesUploadCategory ? '/stockSalesUpload' :'/InventoryCheck',
            state: location.state?.tockSalesUploadCategory ? { station:location.state?.station } : { category: location.state.category },
            routeName: location.state?.tockSalesUploadCategory ? '进销存上传':'库房工作站',
          }}
          buttons={
            !detail.storehouseTag ? (
              <Button
                type='link'
                onClick={() => {
                  if (selectIdTag) {
                    setIsVisible(true)
                  }
                }}
                style={{
                  color: '#333333',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <AccountOutlined
                  style={{
                    fill: '#666',
                    width: '16px',
                    height: '16px',
                    marginRight: '4px',
                  }}
                />
                登账
              </Button>
            ) : (
              <></>
            )
          }
        /> : <></>}
      </Col>
      <div
        style={{
          margin: '10px 20px',
          padding: '10px 20px',
          height: 'calc(100% - 70px)',
          position: 'relative',
          background: '#fff',
          borderRadius: '10px',
        }}
      >
        <InventStockQuery
          refresh={() => {
            dispatch(detailPutstorage(selectIdTag))
          }}
          tenantInventoryReceiptsId={selectIdTag}
          params={queries}
          category={categoryTag}
          Detail={detail}
          onValueChange={(v) => {
            setQueries({
              ...queries,
              ...v,
            })
            dispatch(detailPutstorage(selectIdTag))
          }}
        />
        <EditableList
          loading={loading}
          style={{
            height: 'calc(100% - 222px)',
            overflowY: 'scroll',
          }}
          bordered
          page={page}
          pagination={false}
          columns={InventoryCloumn(
            detail.storehouseTag,
            (text, t) => {
              if (text === 'edit') {
                const newdata = {
                  ...t.prev,
                  ...t.next,
                  stockCount: t.next.count ? t.next.count : t.prev.count,
                  // productInfo:
                  //   t.prev.name ||
                  //   t.prev.tenantSupplierName ||
                  //   t.prev.spec ||
                  //   t.prev.doseCategory
                  //     ? `${t.prev.name} /${t.prev.tenantSupplierName} /${t.prev.spec} /${t.prev.tenantSupplierName}`
                  //     : `${t.prev.doseCategory}`,
                  // productDate: t.next.productDate
                  //   ? t.next.productDate
                  //   : t.prev.productDate,
                  // // tenantMaterialsCatalogId:
                  id: t.prev.id,
                }
                dispatch(
                  changeRow(
                    page.items.map((v: any) =>
                      v.tenantInventoryLedgerId ===
                      newdata.tenantInventoryLedgerId
                        ? newdata
                        : { ...v }
                    )
                  )
                )
              }
              if (text === 'submit') {
                // handleChange(t.prev, t.next);
                const newdata = {
                  ...t.prev,
                  ...t.next,
                  productDate: moment(t.prev.productDate).format(
                    DateTimeFormat
                  ),
                  deadline: moment(t.prev.deadline)
                    .endOf('day')
                    .format(DateTimeFormat),
                  totalPrice:
                    typeof t.next.count !== 'string' &&
                    typeof isNaN(
                      (t.prev.realSplitCount - t.prev.splitCount) *
                        t.prev.splitPrice +
                        (t.prev.realCount - t.prev.count) * t.prev.unitPrice
                    )
                      ? 0
                      : (t.prev.realSplitCount - t.prev.splitCount) *
                          t.prev.splitPrice +
                        (t.prev.realCount - t.prev.count) * t.prev.unitPrice,
                }
                dispatch(
                  changeRow(
                    page.items.map((v: any) =>
                      v.tenantInventoryLedgerId ===
                      newdata.tenantInventoryLedgerId
                        ? newdata
                        : { ...v }
                    )
                  )
                )
                if (t.prev.tenantInventoryLedgerId) {
                  dispatch(
                    saveStoreDetailAsync({
                      ...newdata,
                      tenantInventoryReceiptsId: selectIdTag,
                    })
                  )
                    .then(unwrapResult)
                    .then(() => {
                      dispatch(detailPutstorage(selectIdTag))
                    })
                } else {
                  dispatch(
                    saveStoreDetailAsync({
                      ...newdata,
                      tenantInventoryReceiptsId: selectIdTag,
                    })
                  )
                    .then(unwrapResult)
                    .then(() => {
                      dispatch(detailPutstorage(selectIdTag))
                    })
                }
              }
            },
            page.size * (page.current - 1)
          )}
        />
        <Pagination
          total={page.total}
          current={page.current}
          pageSize={page?.size}
          showSizeChanger
          showQuickJumper
          pageSizeOptions={['10', '20', '50', '100', '500']}
          style={{
            position: 'absolute',
            bottom: props?.selectId ? '-40px' : '20px',
            right: '20px',
          }}
          onChange={(current, size) => {
            setQueries({ ...queries, current, size })
          }}
          showTotal={(total) => `共 ${total} 条`}
        />
        <Button
          size='large'
          type='link'
          style={{
            position: 'absolute',
            bottom: props?.selectId ? '-40px' : '20px',
            left: '20px',
            fontSize: '18px',
            fontWeight: 'bold',
          }}
          onClick={() => {
            setIsModalVisible(true)
          }}
        >
          已添加{detail.totalItem}条盘点记录
        </Button>
      </div>
      <InventStockModal
        visible={isModalVisible}
        onOk={() => {
          dispatch(detailPutstorage(selectIdTag))
          setIsModalVisible(false)
        
        }}
        onCancel={() => {
          setIsModalVisible(false)
          dispatch(detailPutstorage(selectIdTag))
        
        }}
        RegisterOk={() => {
          setIsModalVisible(false)
          setIsVisible(true)
          setFlag(true)
        }}
        RegisterCancel={() => {
          setIsModalVisible(true)
          setIsVisible(false)
        }}
      />
      <Modal
        title='信息'
        visible={isVisible}
        onCancel={() => {
          if (flag) {
            setFlag(false)
            setIsModalVisible(true)
            setIsVisible(false)
          } else {
            setIsVisible(false)
          }
        }}
        footer={
          <Row>
            <Col style={{display:'flex',alignItems:'center'}}>
              <Checkbox checked={fullAmountflag} onChange={(v) => {
                const aFlag = v.target.checked
                setFullAmountflag(aFlag)
                localStorage.setItem('fullAmountInventoryFlag',String(aFlag))
              }}></Checkbox>
              &nbsp;&nbsp;同时保存没有变动的数量
            </Col>
            <Col flex={1}>
              <Button  onClick={() => {
                if (flag) {
                  setFlag(false)
                  setIsModalVisible(true)
                  setIsVisible(false)
                } else {
                  setIsVisible(false)
                }
              }}>取消</Button>
              <Button loading={btnLoading} type={'primary'} onClick={() => {
                setBtnLoading(true)
                dispatch(saveTakeStockAsync({tenantInventoryReceiptsIds:selectIdTag,fullTakeStockFlag:fullAmountflag}))
                  .then(unwrapResult)
                  .then(() => {
                    notification.success({
                      message: '登账成功',
                      duration: 3,
                    })
                    history.push({
                      pathname: '/InventoryCheck',
                      state: { category: '8', station: '2' },
                    })
                  })
                  .catch(() => {
                    if (flag) {
                      setFlag(false)
                      notification.error({
                        message:
                          '当前单据单据无明细或有药品执行加减库存时失败,单据登账失败',
                        duration: 3,
                      })
                      setIsModalVisible(true)
                      setIsVisible(false)
                    } else {
                      notification.error({
                        message:
                          '当前单据单据无明细或有药品执行加减库存时失败,单据登账失败',
                        duration: 3,
                      })
                      setIsVisible(false)
                    }
                  }).finally(()=>{
                  setIsVisible(false)
                  setBtnLoading(false)
                })
              }}>确认</Button>
            </Col>
          </Row>
        }
      >
        <h3>是否登账？</h3>
        <p>请确认各项信息是否填写正确，登账后将不可修改</p>
      </Modal>
    </div>
  )
}
