/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-09-13 14:53:45
 * @LastEditors: sj
 * @LastEditTime: 2022-09-19 14:58:09
 */
import { unwrapResult } from '@reduxjs/toolkit'
import { Button, Col, Modal, Row, Tooltip } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../../app/store'
import { TreatmentBoxList } from '../list/TreatmentBoxList'
import { getTreatmentOldList } from '../list/treatmentSlice'
import {
  selectMedicalRecordVisible,
  setDetailVisible,
  setMedicalRecordVisible,
  setTreatmentLeftPage,
} from '../treatmentSlice'
import styles from './TcmCases.module.css'
import { getPatientCourseAsync } from './tcmCasesSlice'

interface MedicalRecordModalProps {
  registration: any
}

export const MedicalRecordModal = ({
  registration,
}: MedicalRecordModalProps) => {
  const visible = useSelector(selectMedicalRecordVisible)

  const dispatch = useDispatch<RootDispatch>()

  const [list, setList] = useState<any>([[]])

  useEffect(() => {
    visible &&
      registration?.patientId &&
      dispatch(getPatientCourseAsync(registration.patientId))
        .then(unwrapResult)
        .then((res: any) => {
          setList(res)
        })
  }, [registration?.patientId, visible])

  return (
    <Modal
      title={registration?.patientName + '的历史病程'}
      visible={visible}
      footer={null}
      onCancel={() => {
        dispatch(setMedicalRecordVisible(false))
      }}
      width={1000}
      bodyStyle={{
        height: 600,
        padding: '10px 15px 20px 25px',
      }}
    >
      <span
        style={{
          fontStyle: 'oblique',
          color: '#999',
        }}
      >
        单击可查看病历详情
      </span>
      <div className={styles.MedicalRecordModal}>
        {list.map((v: any, i: number) => (
          <div
            className={styles.cell}
            key={i}
            style={{
              marginRight: i == list.length - 1 ? 0 : 40,
            }}
          >
            {v.map((el: any, ei: number) => (
              <div className={styles.cellItem} key={el.id}>
                <div className={styles.content}>
                  <div
                    className={styles.contentItem}
                    style={{
                      backgroundColor:
                        el.doctorType == 0 ? '#F79D21' : '#4095E5',
                    }}
                    onClick={() => {
                      dispatch(
                        getTreatmentOldList({
                          current: 1,
                          id: el.id,
                        })
                      )
                        .then(unwrapResult)
                        .then((res: any) => {
                          dispatch(setTreatmentLeftPage(res))
                          dispatch(setDetailVisible(true))
                          dispatch(setMedicalRecordVisible(false))
                        })
                    }}
                  >
                    <span>{moment(el?.createTime).format('YYYY/MM/DD')}</span>
                    <Tooltip title={el.disease}>
                      <div className={styles.contentDetail}>{el.disease}</div>
                    </Tooltip>
                    <span>{el.receiveTag == 0 ? '初诊' : '复诊'}</span>
                  </div>
                  {ei != v.length - 1 && (
                    <div className={styles.arrow}>
                      <div className={styles.arrowTop}></div>
                      <div className={styles.arrowMiddle}></div>
                      <div className={styles.arrowBottom}></div>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
      <Row
        justify='end'
        style={{
          marginTop: 8,
        }}
        onClick={() => {
          dispatch(setMedicalRecordVisible(false))
        }}
      >
        <Button>返回（ESC）</Button>
      </Row>
    </Modal>
  )
}
