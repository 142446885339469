/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/prop-types */
import {
  CaretDownFilled,
  CaretRightFilled,
  CloseOutlined,
  PlusOutlined,
  SearchOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
  HeartFilled,
  HeartOutlined,
} from '@ant-design/icons'

import { unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Col,
  Collapse,
  Divider,
  Dropdown,
  Form,
  Input,
  message,
  Modal,
  notification,
  Radio,
  Row,
  Space,
  Spin,
} from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import _, { fromPairs } from 'lodash'
import React, {
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectEdition } from '../../app/applicationSlice'
import { RootDispatch } from '../../app/store'
import {
  DelectOutlined,
  EditsOutlined,
  LikeedIcon,
  LikeIcon,
} from '../../compnents/icons/Icons'
import { DiseaseInput } from '../../compnents/input/DiseaseInput'
import { Cross } from '../../compnents/tooth/Cross'
import { CToothSel } from '../../compnents/tooth/CtoothSel'
import { TeethSel } from '../../compnents/tooth/TeethSel'
import { TeethLab, ToothBal, ToothMenu } from '../../compnents/tooth/ToothMenu'
import { ToothSel } from '../../compnents/tooth/ToothSel'
import { ToothTable } from '../../compnents/tooth/ToothTable'
import { Label } from '../../compnents/widgets/Label'
import { CommonCodeCategory } from '../../models/commoncode'
import { Disease } from '../../models/disease'
import {
  getTemPlateName,
  TemPlateOption,
  ToothOption,
} from '../../models/personalSetting'
import { TemplateCategory, TemplateDetail } from '../../models/template'
import { ThemeContext } from '../../theme/ThemeContext'
import { sleep } from '../../utils/SystemUtils'
import { AuxiliaryInput } from '../treatment/oralCases/componment/AuxiliaryInput'
import {
  pushList,
  selTeethKeyType,
  toothProps,
} from '../treatment/oralCases/OralCases'
import {
  selectRegistration,
  selectTreatmentO,
} from '../treatment/oralCases/oralCasesSlice'
import { SaveTemplateModal } from './SaveTemplateModal'
import styles from './TemplateEditor.module.css'
import {
  commonListAsync,
  commonRemoveAsync,
  commonSaveAsync,
  createOrUpdateTemplateCategory,
  getTempalteDetailList,
  getTemplateCategoryList,
  removeTemplateCategory,
  removeTemplateDetail,
} from './templateSlice'
const { Item } = Form

export const TemplateEditor = (): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const theme = useContext(ThemeContext)

  const [categoryForm] = Form.useForm()

  const [templateForm] = Form.useForm()

  const [diagnosisForm] = Form.useForm()

  const [templateKeyword, setTemplateKeyword] = useState('')

  const [selectedCategoryId, setSelectedCategoryId] = useState('')

  const [selectedTemplateId, setSelectedTemplateId] = useState<any>('')

  const [categories, setCategories] = useState<TemplateCategory[]>([])

  const [templates, setTemplates] = useState<Record<string, TemplateDetail[]>>(
    {}
  )

  const [isDiseaseEmpty, setIsDiseaseEmpty] = useState(false)

  const [selectedDiseases, setSelectedDiseases] = useState<Disease[]>([])

  const [medicalRecord, setMedicalRecord] = useState(1)

  const [isCategoryModalVisible, setIsCategoryModalVisibile] = useState(false)

  const [targetDetail, setTargetDetail] = useState<TemplateDetail | undefined>(
    undefined
  )

  const categoryRef = useRef<Input>(null)

  const tentmentInfo = JSON.parse(localStorage.getItem('credentials') || '{}')

  const edition = useSelector(selectEdition)

  //口腔

  const [tooth, setTooth] = useState([
    [{ teethStr: ['', '', '', ''], modalShow: false, Check: '' }],
    [{ teethStr: ['', '', '', ''], modalShow: false, Check: '' }],
    [{ teethStr: ['', '', '', ''], modalShow: false, Check: '' }],
    [{ teethStr: ['', '', '', ''], modalShow: false, Check: '' }],
    [{ teethStr: ['', '', '', ''], modalShow: false, Check: '' }],
  ])

  const [teethKey, setTeethKey] = useState('')

  const [checkStr, setCheckStr] = useState('')

  const [menuKey, setMenuKey] = useState(0)

  const [selTeethKey, setSelTeethKey] = useState<selTeethKeyType>()

  const [isDiseaseEditable, setIsDiseaseEditable] = useState(true)

  const [selectedDiseasesList, setSelectedDiseasesList] = useState<Disease[][]>(
    [[]]
  )

  const [loading, setLoading] = useState(false)

  const [commonName, setcommonName] = useState<any>({})

  const changeTooth = (record: any) => {
    setTooth(
      tooth.map((v) => {
        return v.map((item) => {
          return {
            ...item,
            teethStr: record,
          }
        })
      })
    )
  }

  const getToothKey = (toothIndex: number, teethIndex: number, key: number) => {
    if (key === 0) {
      setTooth(
        tooth.map((v, i) => {
          if (i === toothIndex) {
            return v.map((item, index) => {
              if (index === teethIndex) {
                return {
                  ...item,
                  teethStr: !menuKey
                    ? [
                        '1,2,3,4,5,6,7,8',
                        '1,2,3,4,5,6,7,8',
                        '1,2,3,4,5,6,7,8',
                        '1,2,3,4,5,6,7,8',
                      ]
                    : ['A,B,C,D,E', 'A,B,C,D,E', 'A,B,C,D,E', 'A,B,C,D,E'],
                }
              } else {
                return item
              }
            })
          } else {
            return v
          }
        })
      )
    } else if (key === 1) {
      setTooth(
        tooth.map((v, i) => {
          if (i === toothIndex) {
            return v.map((item, index) => {
              if (index === teethIndex) {
                return {
                  ...item,
                  teethStr: !menuKey
                    ? ['1,2,3,4,5,6,7,8', '1,2,3,4,5,6,7,8', '', '']
                    : ['A,B,C,D,E', 'A,B,C,D,E', '', ''],
                }
              } else {
                return item
              }
            })
          } else {
            return v
          }
        })
      )
    } else if (key === 2) {
      setTooth(
        tooth.map((v, i) => {
          if (i === toothIndex) {
            return v.map((item, index) => {
              if (index === teethIndex) {
                return {
                  ...item,
                  teethStr: !menuKey
                    ? ['', '', '1,2,3,4,5,6,7,8', '1,2,3,4,5,6,7,8']
                    : ['', '', 'A,B,C,D,E', 'A,B,C,D,E'],
                }
              } else {
                return item
              }
            })
          } else {
            return v
          }
        })
      )
    } else if (key === 3) {
      setTooth(
        tooth.map((v, i) => {
          if (i === toothIndex) {
            return v.map((item, index) => {
              if (index === teethIndex) {
                return {
                  ...item,
                  teethStr: ['', '', '', ''],
                }
              } else {
                return item
              }
            })
          } else {
            return v
          }
        })
      )
    }
  }

  const getTeethStr = (v: string) => {
    const teethStr = v.substring(1)
    return teethStr.replace('-', ',')
  }

  // const authChangeTooth = () => {
  //   setTooth(tooth.map.de);
  // };

  const toothChange = (
    currentIndex: number,
    changeMove: string,
    changeIndex: number,
    selToothStr?: string[]
  ) => {
    if (changeMove === 'show') {
      setTooth(
        tooth.map((v, i) => {
          if (i === currentIndex) {
            return v.map((item, index) => {
              if (index === changeIndex) {
                return {
                  ...item,
                  modalShow: true,
                }
              } else {
                return {
                  ...item,
                  modalShow: false,
                }
              }
            })
          } else {
            return v.map((item) => {
              return {
                ...item,
                modalShow: false,
              }
            })
          }
        })
      )
    } else if (changeMove === 'notShow') {
      setTooth(
        tooth.map((v, i) => {
          if (i === currentIndex) {
            return v.map((item, index) => {
              if (index === changeIndex) {
                return {
                  ...item,
                  modalShow: false,
                }
              } else {
                return item
              }
            })
          } else {
            return v
          }
        })
      )
    } else if (changeMove === 'notAllShow') {
      setTooth(
        tooth.map((v, i) => {
          return v.map((item, index) => {
            return {
              ...item,
              modalShow: false,
            }
          })
        })
      )
    } else if (changeMove === 'add') {
      setTooth(
        tooth.map((v, i) => {
          if (i === currentIndex) {
            return [...v, pushList]
          } else {
            return v
          }
        })
      )
    } else if (changeMove === 'remove') {
      setTooth(
        tooth.map((v, i) => {
          if (i === currentIndex) {
            return v.filter((item, index) => {
              if (index !== changeIndex) {
                return item
              } else {
                return
              }
            })
          } else {
            return v
          }
        })
      )
    } else if (changeMove === 'toothChange') {
      setTooth(
        tooth.map((v, i) => {
          if (i === currentIndex) {
            return v.map((item, index) => {
              if (index === changeIndex) {
                return {
                  ...item,
                  teethStr: selToothStr || ['', '', '', ''],
                }
              } else {
                return item
              }
            })
          } else {
            return v
          }
        })
      )
    }
  }

  const checkChange = (
    toothIndex: number,
    teethIndex: number,
    check: string,
    change?: boolean
  ) => {
    change
      ? setTooth(
          tooth.map((v, i) => {
            if (i === toothIndex) {
              return v.map((item, index) => {
                if (index === teethIndex) {
                  return {
                    ...item,
                    Check: check,
                  }
                } else {
                  return item
                }
              })
            } else {
              return v
            }
          })
        )
      : setTooth(
          tooth.map((v, i) => {
            if (i === toothIndex) {
              return v.map((item, index) => {
                if (index === teethIndex) {
                  return {
                    ...item,
                    Check: item.Check.concat(check),
                  }
                } else {
                  return item
                }
              })
            } else {
              return v
            }
          })
        )
  }

  const Menu: React.FC<toothProps> = ({
    syn,
    toothIndex,
    teethIndex,
    authChangeTooth = () => {},
  }) => {
    return (
      <>
        <div
          style={{
            height: 'auto',
            background: '#fff',
            width: 'auto',
            border: '1px solid rgba(224, 224, 224, 0.644)',
            position: 'relative',
          }}
        >
          <span
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              zIndex: 999,
              cursor: 'pointer',
            }}
            onClick={() => {
              toothChange(toothIndex, 'notShow', teethIndex)
            }}
          >
            X
          </span>
          <ToothMenu
            defaultKey={menuKey}
            click={(v) => {
              setMenuKey(v)
            }}
          >
            <ToothBal name='恒牙'>
              <div style={{ display: 'flex' }}>
                <div>
                  <Row
                    gutter={10}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      marginBottom: '10px',
                    }}
                  >
                    <Col>
                      <TeethLab
                        labClick={() => {
                          getToothKey(toothIndex, teethIndex, 0)
                        }}
                      >
                        全
                      </TeethLab>
                    </Col>
                    <Col>
                      <TeethLab
                        labClick={() => {
                          getToothKey(toothIndex, teethIndex, 1)
                        }}
                      >
                        上
                      </TeethLab>
                    </Col>
                    <Col>
                      <TeethLab
                        labClick={() => {
                          getToothKey(toothIndex, teethIndex, 2)
                        }}
                      >
                        下
                      </TeethLab>
                    </Col>
                    <Col>
                      <TeethLab
                        labClick={() => {
                          getToothKey(toothIndex, teethIndex, 3)
                        }}
                      >
                        清空
                      </TeethLab>
                    </Col>
                  </Row>
                  <Cross style={{ width: '400px' }} pos={true}>
                    <ToothSel
                      defaultTeethKey={tooth[toothIndex][teethIndex].teethStr}
                      selTooth={(sel, t, tp) => {
                        setSelTeethKey({
                          sel: sel,
                          tooth: t,
                          teeth: tp,
                        })
                        setTeethKey('')
                      }}
                      teethSelect={(v) => {
                        toothChange(toothIndex, 'toothChange', teethIndex, v)
                      }}
                    ></ToothSel>
                  </Cross>
                  {syn && (
                    <Row
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '10px',
                      }}
                    >
                      <Button
                        type='primary'
                        ghost
                        onClick={() => authChangeTooth()}
                      >
                        同步牙位
                      </Button>
                    </Row>
                  )}
                </div>
                <TeethSel
                  MC={selTeethKey?.sel}
                  teethKey={selTeethKey?.teeth}
                  defaultTeethKey={
                    tooth[toothIndex][teethIndex].teethStr[
                      selTeethKey?.tooth || 0
                    ]
                  }
                  selTeethKey={(v) => {
                    const selTooth = tooth[toothIndex][teethIndex].teethStr.map(
                      (item, index) => {
                        if (index === selTeethKey?.tooth) {
                          return v
                        } else {
                          return item
                        }
                      }
                    )
                    toothChange(toothIndex, 'toothChange', teethIndex, selTooth)
                  }}
                  style={{ marginLeft: '30px', marginTop: '30px' }}
                ></TeethSel>
              </div>
            </ToothBal>
            <ToothBal name='乳牙'>
              <div style={{ display: 'flex' }}>
                <div>
                  <Row
                    gutter={10}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      marginBottom: '10px',
                    }}
                  >
                    <Col>
                      <TeethLab
                        labClick={() => {
                          getToothKey(toothIndex, teethIndex, 0)
                        }}
                      >
                        全
                      </TeethLab>
                    </Col>
                    <Col>
                      <TeethLab
                        labClick={() => {
                          getToothKey(toothIndex, teethIndex, 1)
                        }}
                      >
                        上
                      </TeethLab>
                    </Col>
                    <Col>
                      <TeethLab
                        labClick={() => {
                          getToothKey(toothIndex, teethIndex, 2)
                        }}
                      >
                        下
                      </TeethLab>
                    </Col>
                    <Col>
                      <TeethLab
                        labClick={() => {
                          getToothKey(toothIndex, teethIndex, 3)
                        }}
                      >
                        清空
                      </TeethLab>
                    </Col>
                  </Row>
                  <Cross style={{ width: '260px' }} pos={true}>
                    <CToothSel
                      defaultTeethKey={tooth[toothIndex][teethIndex].teethStr}
                      selTooth={(sel, t, tp) => {
                        setSelTeethKey({
                          sel: sel,
                          tooth: t,
                          teeth: tp,
                        })
                        setTeethKey('')
                      }}
                      teethSelect={(v) => {
                        toothChange(toothIndex, 'toothChange', teethIndex, v)
                      }}
                    ></CToothSel>
                  </Cross>
                  {syn && (
                    <Row
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '10px',
                      }}
                    >
                      <Button
                        type='primary'
                        ghost
                        onClick={() => authChangeTooth()}
                      >
                        同步牙位
                      </Button>
                    </Row>
                  )}
                </div>
                <TeethSel
                  MC={selTeethKey?.sel}
                  teethKey={selTeethKey?.teeth}
                  defaultTeethKey={
                    tooth[toothIndex][teethIndex].teethStr[
                      selTeethKey?.tooth || 0
                    ]
                  }
                  selTeethKey={(v) => {
                    const selTooth = tooth[toothIndex][teethIndex].teethStr.map(
                      (item, index) => {
                        if (index === selTeethKey?.tooth) {
                          return v
                        } else {
                          return item
                        }
                      }
                    )
                    toothChange(toothIndex, 'toothChange', teethIndex, selTooth)
                  }}
                  style={{ marginLeft: '30px', marginTop: '30px' }}
                ></TeethSel>
              </div>
            </ToothBal>
          </ToothMenu>
        </div>
      </>
    )
  }

  const renderTeeth = (toothIndex: number, teethIndex: number, i: number) => {
    if (i % 2) {
      return tooth[toothIndex][teethIndex]?.teethStr[i]?.split(',').map((v) => {
        if (v.length !== 1) {
          return v ? (
            <>
              {v[0]}
              <sup>{getTeethStr(v)}</sup>
            </>
          ) : (
            ''
          )
        } else {
          return v
        }
      })
    } else {
      const data = tooth[toothIndex][teethIndex]?.teethStr[i]
        ?.split(',')
        .sort(function (a: any, b: any) {
          return b.substr(0, 1) - a.substr(0, 1)
        })
      const letters: any = data?.filter((element: any) => isNaN(element)) // 过滤出字母

      letters?.reverse() // 反转字母数组顺序
      const newData = data?.map((element: any) =>
        isNaN(element) ? letters?.shift() : element
      ) // 将反转后的字母按顺序放回原数组
      return newData?.map((v) => {
        if (v.length > 1) {
          return v ? (
            <>
              {v[0]}
              <sup>{getTeethStr(v)}</sup>
            </>
          ) : (
            ''
          )
        } else {
          return v
        }
      })
    }
  }

  useEffect(() => {
    diagnosisForm.setFieldsValue({
      stomatologyTooth: tooth[0].map((v) => {
        return {
          stomatologyCheck: v.Check,
        }
      }),
      inspectionTooth: tooth[1].map((v) => {
        return {
          inspectionCheck: v.Check,
        }
      }),
      diseaseTooth: tooth[2].map((v) => v.Check),
      treatmentTooth: tooth[3].map((v) => {
        return {
          treatmentPlan: v.Check,
        }
      }),
      disposeTooth: tooth[4].map((v) => {
        return { disposeContent: v.Check }
      }),
    })
  }, [tooth])

  useEffect(() => {
    diagnosisForm.setFieldsValue({
      stomatologyTooth: [''],
      inspectionTooth: [''],
      diseaseTooth: [''],
      treatmentTooth: [''],
      disposeTooth: [''],
    })
  }, [])

  useEffect(() => {
    diagnosisForm.setFieldsValue({
      treatmentConclusion: checkStr,
    })
  }, [checkStr])

  const layOut = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
  }

  useEffect(() => {
    reload(templateKeyword)
  }, [templateKeyword])

  useEffect(() => {
    if (!selectedTemplateId) {
      templateForm.resetFields()
    }
  }, [selectedTemplateId])

  useEffect(() => {
    if (isCategoryModalVisible) {
      sleep(200).then(() => {
        categoryRef.current?.focus()
      })
    }
  }, [isCategoryModalVisible])

  const reload = (keyword: string) => {
    dispatch(
      getTemplateCategoryList({
        current: 1,
        size: 100,
        categoryName: keyword,
      })
    )
      .then(unwrapResult)
      .then((cs) => {
        setCategories([
          {
            categoryName: '收藏',
            id: '-1',
            medicalRecord: -1,
          },
          ...cs,
        ])
        setSelectedCategoryId('-1')
        // if (cs.length > 0) {
        //   setSelectedCategoryId(cs[0].id)
        // } else {
        //   setSelectedCategoryId("")
        // }
        setSelectedTemplateId('')
      })
      .catch(() => {
        // do nothing.
      })
  }

  const reflesh = (parentid: string) => {
    dispatch(getTempalteDetailList(parentid))
      .then(unwrapResult)
      .then(async (details) => {
        const commonList = await getcommonListAsync()
        setTemplates({
          ...templates,
          [parentid]: details,
          ['-1']: commonList,
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const load = async (id: string, force = false): Promise<number> => {
    const category = categories.find((t) => t.id === id)
    if (Number(id) > 0) {
      if (category || force) {
        return dispatch(getTempalteDetailList(id))
          .then(unwrapResult)
          .then((details) => {
            setTemplates({
              ...templates,
              [id]: details,
            })
            return details.length
          })
      }
    } else {
      const details = await getcommonListAsync()
      setTemplates({
        ...templates,
        ['-1']: details,
      })
    }
    return Promise.resolve(0)
  }

  const getcommonListAsync = async () => {
    return await dispatch(commonListAsync()).then(unwrapResult)
  }

  const save = async () => {
    if (
      categories.find((t) => t.id === selectedCategoryId)?.categoryName ==
        '收藏' &&
      !Object.keys(commonName).length
    ) {
      notification.info({
        message: '请在其他分类下添加模板',
      })
      return
    }
    toothChange(0, 'notAllShow', 0)
    if (medicalRecord === 3) {
      if (!selectedDiseasesList.some((v) => v.length)) {
        setIsDiseaseEmpty(true)
      }
      try {
        const values = await diagnosisForm.validateFields()
        const name = templates[selectedCategoryId]?.find(
          (t) => t.id === selectedTemplateId
        )?.itemName

        setTargetDetail({
          ...values,
          pulseCondition: values.pulseCondition || '',
          tonguePicture: values.tonguePicture || '',
          medicalRecord: medicalRecord,
          id: selectedTemplateId || '',
          itemName: name || '',
          disease:
            medicalRecord === 3
              ? selectedDiseasesList
                  .map((v) =>
                    v.map((item) => `${item.name}/${item.icds}`).join(',')
                  )
                  .join('#')
              : selectedDiseases.map((d) => `${d.name}/${d.icds}`).join(','),
        })
      } catch {
        // do nothing.
      }
    } else {
      if (selectedDiseases.length == 0) {
        setIsDiseaseEmpty(true)
      }
      try {
        const values = await diagnosisForm.validateFields()
        const name = templates[selectedCategoryId]?.find(
          (t) => t.id === selectedTemplateId
        )?.itemName

        setTargetDetail({
          ...values,
          pulseCondition: values.pulseCondition || '',
          tonguePicture: values.tonguePicture || '',
          medicalRecord: medicalRecord,
          id: selectedTemplateId || '',
          itemName: name || '',
          disease:
            medicalRecord === 3
              ? selectedDiseasesList
                  .map((v) =>
                    v.map((item) => `${item.name}/${item.icds}`).join(',')
                  )
                  .join('#')
              : selectedDiseases.map((d) => `${d.name}/${d.icds}`).join(','),
        })
      } catch {
        // do nothing.
      }
    }
  }

  const like = useCallback(
    _.debounce((d, c) => {
      setLoading(true)
      dispatch(
        commonSaveAsync({
          itemId: d.id,
          medicalRecord: c.medicalRecord,
        })
      )
        .then(unwrapResult)
        .then(() => {
          reflesh(d.templateId)
        })
        .catch(() => {
          setLoading(false)
        })
    }, 500),
    []
  )

  const liked = useCallback(
    _.debounce((d) => {
      setLoading(true)
      dispatch(commonRemoveAsync(d.commonId))
        .then(unwrapResult)
        .then(() => {
          reflesh(d.templateId)
        })
        .catch(() => {
          setLoading(false)
        })
    }, 500),
    []
  )

  return (
    <Row style={{ paddingTop: 10, height: 'calc(100%)' }}>
      <Col
        style={{
          width: 400,
          height: '100%',
          marginRight: 10,
          backgroundColor: theme.pn,
          borderRadius: theme.br,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        }}
        className={styles.leftContent}
      >
        <Label label='诊断模板' style={{ marginLeft: 20 }} />
        <Divider style={{ margin: 0, borderTopColor: theme.c3 }} />
        <Input
          value={templateKeyword}
          prefix={<SearchOutlined style={{ color: theme.tc3 }} />}
          placeholder='请输入内容检索'
          onChange={(e) => setTemplateKeyword((e.target.value || '').trim())}
          style={{
            width: 'calc(100% - 40px)',
            marginTop: 20,
            marginLeft: 20,
            marginRight: 20,
          }}
          allowClear
        />
        <Spin spinning={loading}>
          <Collapse
            className={styles.templates}
            bordered={false}
            // collapsible='header'
            expandIcon={(v) =>
              v.isActive ? (
                <CaretDownFilled style={{ lineHeight: '26px' }} />
              ) : (
                <CaretRightFilled style={{ lineHeight: '26px' }} />
              )
            }
            style={{
              marginTop: 20,
              marginLeft: 20,
              marginRight: 20,
              flex: 1,
              overflow: 'auto',
              backgroundColor: 'transparent',
            }}
            onChange={(ids) => {
              if (ids.length) {
                // setSelectedCategoryId(ids[ids.length]);
              }
              _.forEach(ids, (id) => {
                if (templates[id]) {
                  return
                }
                load(id)
              })
            }}
          >
            {categories.map((c) => (
              <Collapse.Panel
                key={c.id}
                header={
                  <Row
                    align='middle'
                    justify='space-between'
                    wrap={false}
                    style={{
                      color: selectedCategoryId === c.id ? theme.c1 : undefined,
                      fontWeight:
                        selectedCategoryId === c.id ? 'bold' : 'normal',
                      width: '100%',
                    }}
                    onClick={() => {
                      setcommonName({})
                      if (c.medicalRecord === 3) {
                        // handleChange
                        setTooth([
                          [
                            {
                              teethStr: ['', '', '', ''],
                              modalShow: false,
                              Check: '',
                            },
                          ],
                          [
                            {
                              teethStr: ['', '', '', ''],
                              modalShow: false,
                              Check: '',
                            },
                          ],
                          [
                            {
                              teethStr: ['', '', '', ''],
                              modalShow: false,
                              Check: '',
                            },
                          ],
                          [
                            {
                              teethStr: ['', '', '', ''],
                              modalShow: false,
                              Check: '',
                            },
                          ],
                          [
                            {
                              teethStr: ['', '', '', ''],
                              modalShow: false,
                              Check: '',
                            },
                          ],
                        ])
                        diagnosisForm.setFieldsValue({
                          stomatologyTooth: [''],
                          inspectionTooth: [''],
                          diseaseTooth: [''],
                          treatmentTooth: [''],
                          disposeTooth: [''],
                        })
                      }
                      if (c.id !== selectedCategoryId) {
                        diagnosisForm.resetFields()
                        setSelectedDiseases([])
                        setSelectedDiseasesList([[]])
                        setMedicalRecord(c.medicalRecord)
                        setSelectedCategoryId(c.id)
                        setSelectedTemplateId('')
                      }
                    }}
                  >
                    <span
                      style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        marginRight: 10,
                      }}
                    >
                      {c.categoryName}
                    </span>
                    {c.id != '-1' && (
                      <Space className={styles.opBar}>
                        <PlusOutlined
                          style={{
                            fontSize: 16,
                            color: '#027AFF',
                            width: '16px',
                            height: '16px',
                            paddingBottom: 4,
                          }}
                          onClick={(e) => {
                            e.stopPropagation()
                            setSelectedCategoryId(c.id)
                            setSelectedTemplateId('')
                            save()
                          }}
                        />
                        <EditsOutlined
                          style={{
                            fill: '#027AFF',
                            width: '16px',
                            height: '16px',
                          }}
                          onClick={(e) => {
                            e.stopPropagation()
                            setSelectedCategoryId(c.id)
                            setSelectedTemplateId('')
                            categoryForm.setFieldsValue({...c, medicalRecord : c?.medicalRecord == 7 ? 1 :medicalRecord})
                            setIsCategoryModalVisibile(true)
                          }}
                        />
                        <DelectOutlined
                          style={{
                            fill: '#FF5D5D',
                            width: '16px',
                            height: '16px',
                          }}
                          onClick={async (e) => {
                            e.stopPropagation()
                            let count = templates[c.id]?.length || 0
                            if (templates[c.id] === undefined) {
                              count = await load(c.id)
                            }
                            if (count > 0) {
                              Modal.warn({ title: '请先删除分类内模版' })
                              return
                            }
                            Modal.confirm({
                              title: '提示',
                              content: `确定要删除「${c.categoryName}」分类？`,
                              onOk: () => {
                                dispatch(removeTemplateCategory(c.id)).then(
                                  () => {
                                    reload('')
                                    setTemplateKeyword('')
                                  }
                                )
                              },
                            })
                          }}
                        />
                      </Space>
                    )}
                  </Row>
                }
              >
                {templates[c.id] &&
                  templates[c.id]
                    ?.filter(
                      (d) =>
                        !templateKeyword ||
                        d.itemName.indexOf(templateKeyword) >= 0 ||
                        d.mnemonicCode.indexOf(templateKeyword.toUpperCase()) >=
                          0
                    )
                    ?.map((d, i) => (
                      <Row
                        key={d.id}
                        onClick={() => {
                          setcommonName({
                            id: c.id,
                            medicalRecord: d.medicalRecord,
                            categoryName: d.categoryName,
                          })

                          setMedicalRecord(
                            Number(c.id) > 0 ? c.medicalRecord : d.medicalRecord
                          )
                          const clickMedicalRecord =
                            Number(c.id) > 0 ? c.medicalRecord : d.medicalRecord
                          if (clickMedicalRecord === 3) {
                            setTooth(
                              tooth?.map((v, i) => {
                                if (i === 0) {
                                  return d?.stomatologyTooth
                                    ?.split('#')
                                    ?.map((item, index) => {
                                      return {
                                        teethStr: item
                                          ?.split('@')
                                          ?.map((current) => {
                                            return current === '--'
                                              ? ''
                                              : current
                                          }),
                                        modalShow: false,
                                        Check: d?.stomatologyCheck
                                          ?.split('#%#')
                                          ?.map((v) => {
                                            return v
                                          })[index],
                                      }
                                    }) as any
                                  // return v;
                                } else if (i === 1) {
                                  return d?.inspectionTooth
                                    ?.split('#')
                                    ?.map((item, index) => {
                                      return {
                                        teethStr: item
                                          ?.split('@')
                                          ?.map((current) => {
                                            return current === '--'
                                              ? ''
                                              : current
                                          }),
                                        modalShow: false,
                                        Check: d.inspectionCheck
                                          ?.split('#%#')
                                          ?.map((v) => {
                                            return v
                                          })[index],
                                      }
                                    }) as any
                                } else if (i === 2) {
                                  return d?.diseaseTooth
                                    ?.split('#')
                                    ?.map((item, index) => {
                                      return {
                                        teethStr: item
                                          ?.split('@')
                                          ?.map((current) => {
                                            return current === '--'
                                              ? ''
                                              : current
                                          }),
                                        modalShow: false,
                                        Check: '',
                                      }
                                    }) as any
                                } else if (i === 3) {
                                  return d.treatmentTooth
                                    ?.split('#')
                                    ?.map((item, index) => {
                                      return {
                                        teethStr: item
                                          ?.split('@')
                                          ?.map((current) => {
                                            return current === '--'
                                              ? ''
                                              : current
                                          }),
                                        modalShow: false,
                                        Check: d.treatmentPlan
                                          ?.split('#%#')
                                          ?.map((v) => {
                                            return v
                                          })[index],
                                      }
                                    }) as any
                                } else if (i === 4) {
                                  return d.disposeTooth
                                    ?.split('#')
                                    ?.map((item, index) => {
                                      return {
                                        teethStr: item
                                          ?.split('@')
                                          ?.map((current) => {
                                            return current === '--'
                                              ? ''
                                              : current
                                          }),
                                        modalShow: false,
                                        Check: d.disposeContent
                                          ?.split('#%#')
                                          ?.map((v) => {
                                            return v
                                          })[index],
                                      }
                                    }) as any
                                }
                              })
                            )
                            diagnosisForm.setFieldsValue({
                              stomatologyTooth: [
                                {
                                  stomatologyCheck: '1',
                                },
                              ],
                              inspectionTooth: [''],
                              diseaseTooth: [''],
                              treatmentTooth: [''],
                              disposeTooth: [''],
                            })
                          }
                          if (selectedTemplateId === d.id) {
                            // setSelectedTemplateId("")
                          } else {
                            setSelectedCategoryId(c.id)
                            setMedicalRecord(clickMedicalRecord)
                            setSelectedTemplateId(d.id)
                            templateForm.setFieldsValue(d)
                          }
                          diagnosisForm.setFields([
                            {
                              name: 'disease',
                              errors: [],
                            },
                          ])
                          diagnosisForm.setFieldsValue({
                            ...d,
                          })
                          const diseases = _.chain(d.disease)
                            ?.split(',')
                            .map((s) => s?.split('/'))
                            .map(([n, c]) => ({ name: n, icds: c } as Disease))
                            .value()
                          const diseasesList = d.disease
                            ?.split('#')
                            .map((v) => {
                              return _.chain(v)
                                ?.split(',')
                                ?.map((s) => s?.split('/'))
                                ?.map(
                                  ([n, c]) => ({ name: n, icds: c } as Disease)
                                )
                                .value()
                            })
                          setSelectedDiseases(diseases)
                          const result = !diseasesList[0].every(
                            (v: any) => v.icds
                          )
                          setSelectedDiseasesList(
                            result ? [[]] : (diseasesList as any)
                          )
                          setIsDiseaseEmpty(
                            diseases.length === 0 || diseasesList.length === 0
                          )
                        }}
                        style={{
                          color:
                            d.id === selectedTemplateId ? theme.c1 : theme.tc2,
                          fontSize: '1.125rem',
                          marginTop: 10,
                          cursor: 'pointer',
                        }}
                        justify='space-between'
                        align='middle'
                      >
                        <Col>
                          {i + 1}. {d.itemName}
                        </Col>
                        <Col>
                          <Space>
                            {d?.commonId && d?.commonId > 0 ? (
                              <LikeedIcon
                                style={{
                                  width: '16px',
                                  height: '16px',
                                }}
                                onClick={(e) => {
                                  e.stopPropagation()
                                  liked(d)
                                  // dispatch(commonRemoveAsync(d.commonId)).then(unwrapResult).then(() => {
                                  //   reflesh(d.templateId)
                                  // })
                                }}
                              />
                            ) : (
                              <LikeIcon
                                style={{
                                  width: '16px',
                                  height: '16px',
                                }}
                                onClick={(e) => {
                                  e.stopPropagation()
                                  like(d, c)
                                }}
                              />
                            )}
                            <EditsOutlined
                              style={{
                                fill: theme.c1,
                                width: '16px',
                                height: '16px',
                              }}
                              onClick={(e) => {
                                setcommonName({
                                  id: c.id,
                                  medicalRecord: d.medicalRecord,
                                  categoryName: d.categoryName,
                                })
                                // if (Number(c.id) > 0) {
                                e.stopPropagation()
                                setSelectedCategoryId(c.id)
                                setSelectedTemplateId(d.id)
                                setTargetDetail(d)
                                // }else{

                                // }
                              }}
                            />
                            <DelectOutlined
                              style={{
                                fill: theme.e,
                                width: '16px',
                                height: '16px',
                              }}
                              onClick={(e) => {
                                e.stopPropagation()
                                Modal.confirm({
                                  title: `确定删除「${d.itemName}」`,
                                  onOk: async () => {
                                    await dispatch(
                                      removeTemplateDetail(d.id)
                                    ).then(unwrapResult)
                                    if (selectedTemplateId === d.id) {
                                      setSelectedTemplateId('')
                                    }
                                    reflesh(d.templateId)
                                  },
                                })
                              }}
                            />
                          </Space>
                        </Col>
                      </Row>
                    ))}
              </Collapse.Panel>
            ))}
          </Collapse>
        </Spin>
        <Button
          type='primary'
          onClick={() => {
            setSelectedCategoryId('')
            categoryForm.setFieldsValue({ categoryName: '' })
            setIsCategoryModalVisibile(true)
          }}
          style={{
            width: '6.5rem',
            marginRight: 20,
            marginTop: 20,
            marginBottom: 20,
            alignSelf: 'flex-end',
          }}
        >
          添加分类
        </Button>
      </Col>
      <Col
        flex='1'
        style={{
          height: '100%',
          borderRadius: theme.br,
          backgroundColor: theme.pn,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        }}
      >
        <Label label='疾病诊断' style={{ marginLeft: 20 }} />
        <Divider style={{ margin: 0, borderTopColor: theme.th }} />
        <Form
          form={diagnosisForm}
          colon={false}
          className={styles.info}
          autoComplete='off'
          labelCol={{
            flex: '7.75rem',
          }}
          style={{ flex: 1, marginRight: 40, overflow: 'auto' }}
        >
          <Form.Item
            label='主诉'
            name='chiefComplaint'
            rules={[
              {
                max: 500,
                message: '最长500位!',
              },
            ]}
          >
            <TextArea placeholder='请输入内容' autoSize />
          </Form.Item>
          <Form.Item
            label='现病史'
            name='historyOfPresentIllness'
            rules={[
              {
                max: 200,
                message: '最长200位!',
              },
            ]}
          >
            <TextArea placeholder='请输入内容' autoSize />
          </Form.Item>
          {medicalRecord === 3 ? (
            <>
              <div className={styles.container}>
                <Form.List name='stomatologyTooth'>
                  {(fields, { add, remove }) => (
                    <Item label='口腔检查'>
                      {fields.map((field, index) => (
                        <Space
                          key={field.key}
                          align='baseline'
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          <Item
                            noStyle
                            shouldUpdate={(prevValues, curValues) =>
                              prevValues.area !== curValues.area ||
                              prevValues.sights !== curValues.sights
                            }
                          >
                            {() => (
                              <Item required>
                                <Dropdown
                                  overlay={
                                    <Menu
                                      syn={true}
                                      toothIndex={0}
                                      teethIndex={index}
                                      authChangeTooth={() => {
                                        changeTooth(tooth[0][index].teethStr)
                                      }}
                                    />
                                  }
                                  placement='bottomLeft'
                                  getPopupContainer={(triggerNode: any) =>
                                    triggerNode.parentNode
                                  }
                                  visible={tooth[0][index]?.modalShow}
                                  onVisibleChange={(v) => {
                                    if (!v) {
                                      toothChange(0, 'notShow', index)
                                    }
                                  }}
                                >
                                  <ToothTable
                                    tableShow={() => {
                                      toothChange(0, 'show', index)
                                    }}
                                    LT={renderTeeth(0, index, 0)}
                                    RT={renderTeeth(0, index, 1)}
                                    LB={renderTeeth(0, index, 2)}
                                    RB={renderTeeth(0, index, 3)}
                                  />
                                </Dropdown>
                              </Item>
                            )}
                          </Item>
                          <Item name={[field.name, 'stomatologyCheck']}>
                            <AuxiliaryInput
                              value={tooth[1][index]?.Check}
                              record={CommonCodeCategory.KQJC}
                              colBack={(v) => {
                                checkChange(0, index, v)
                              }}
                              onChange={(v) => {
                                checkChange(0, index, v, true)
                              }}
                              visibleChange={(isVisible) => {
                                if (isVisible) {
                                  toothChange(0, 'notAllShow', index)
                                }
                              }}
                            />
                          </Item>
                          <Item>
                            {fields.length > 1 &&
                            (fields.length !== index + 1 ||
                              fields.length == 2) &&
                            index !== 0 ? (
                              <MinusCircleOutlined
                                onClick={() => {
                                  remove(field.name)
                                  toothChange(0, 'remove', index)
                                }}
                              />
                            ) : null}
                            {(index + 1 === fields.length &&
                              fields.length !== 2) ||
                            index === 0 ? (
                              <PlusCircleOutlined
                                onClick={() => {
                                  if (fields.length < 5) {
                                    add()
                                    toothChange(0, 'add', index)
                                  }
                                }}
                              />
                            ) : null}
                          </Item>
                        </Space>
                      ))}
                    </Item>
                  )}
                </Form.List>
                <Form.List name='inspectionTooth'>
                  {(fields, { add, remove }) => (
                    <Item label='检验检查'>
                      {fields.map((field, index) => (
                        <Space
                          key={field.key}
                          align='baseline'
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          <Item
                            noStyle
                            shouldUpdate={(prevValues, curValues) =>
                              prevValues.area !== curValues.area ||
                              prevValues.sights !== curValues.sights
                            }
                          >
                            {() => (
                              <Item required>
                                <Dropdown
                                  overlay={
                                    <Menu toothIndex={1} teethIndex={index} />
                                  }
                                  placement='bottomLeft'
                                  getPopupContainer={(triggerNode: any) =>
                                    triggerNode.parentNode
                                  }
                                  visible={tooth[1][index].modalShow}
                                  onVisibleChange={(v) => {
                                    if (!v) {
                                      toothChange(0, 'notShow', index)
                                    }
                                  }}
                                >
                                  <ToothTable
                                    tableShow={() => {
                                      toothChange(1, 'show', index)
                                    }}
                                    LT={renderTeeth(1, index, 0)}
                                    RT={renderTeeth(1, index, 1)}
                                    LB={renderTeeth(1, index, 2)}
                                    RB={renderTeeth(1, index, 3)}
                                  />
                                </Dropdown>
                              </Item>
                            )}
                          </Item>
                          <Item name={[field.name, 'inspectionCheck']}>
                            <AuxiliaryInput
                              value={tooth[1][index].Check}
                              record={CommonCodeCategory.JYJC}
                              colBack={(v) => {
                                checkChange(1, index, v)
                              }}
                              onChange={(v) => {
                                checkChange(1, index, v, true)
                              }}
                              visibleChange={(isVisible) => {
                                if (isVisible) {
                                  toothChange(0, 'notAllShow', index)
                                }
                              }}
                            />
                          </Item>
                          <Item>
                            {fields.length > 1 &&
                            (fields.length !== index + 1 ||
                              fields.length == 2) &&
                            index !== 0 ? (
                              <MinusCircleOutlined
                                onClick={() => {
                                  remove(field.name)
                                  toothChange(1, 'remove', index)
                                }}
                              />
                            ) : null}
                            {(index + 1 === fields.length &&
                              fields.length !== 2) ||
                            index === 0 ? (
                              <PlusCircleOutlined
                                onClick={() => {
                                  if (fields.length < 5) {
                                    add()
                                    toothChange(1, 'add', index)
                                  }
                                }}
                              />
                            ) : null}
                          </Item>
                        </Space>
                      ))}
                    </Item>
                  )}
                </Form.List>
                <Form.List name='diseaseTooth'>
                  {(fields, { add, remove }) => (
                    <Item label='口腔诊断' required>
                      {fields.map((field: any, index) => (
                        <>
                          <Space
                            key={field.key}
                            align='baseline'
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <Item
                              noStyle
                              shouldUpdate={(prevValues, curValues) =>
                                prevValues.area !== curValues.area ||
                                prevValues.sights !== curValues.sights
                              }
                            >
                              {() => (
                                <Item required>
                                  <Dropdown
                                    overlay={
                                      <Menu toothIndex={2} teethIndex={index} />
                                    }
                                    placement='bottomLeft'
                                    getPopupContainer={(triggerNode: any) =>
                                      triggerNode.parentNode
                                    }
                                    visible={tooth[2][index].modalShow}
                                  >
                                    <ToothTable
                                      tableShow={() => {
                                        toothChange(2, 'show', index)
                                      }}
                                      LT={renderTeeth(2, index, 0)}
                                      RT={renderTeeth(2, index, 1)}
                                      LB={renderTeeth(2, index, 2)}
                                      RB={renderTeeth(2, index, 3)}
                                    />
                                  </Dropdown>
                                </Item>
                              )}
                            </Item>
                            <Item
                              name={[field.name, 'disease']}
                              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                              // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
                              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                              fieldKey={[field?.fieldKey, 'last']}
                              required
                              rules={[
                                {
                                  max: 500,
                                  message: '最长500位!',
                                },
                                () => ({
                                  validator: () => {
                                    if (isDiseaseEmpty) {
                                      return Promise.reject('请输入主要疾病')
                                    }
                                    return Promise.resolve()
                                  },
                                }),
                              ]}
                            >
                              <DiseaseInput
                                isMedical={1}
                                disabled={!isDiseaseEditable}
                                placeholder='请输入主要疾病'
                                onChange={() => setIsDiseaseEmpty(false)}
                                click={() => {
                                  toothChange(1, 'notAllShow', index)
                                }}
                                onSelect={(disease) => {
                                  if (
                                    !selectedDiseasesList[index]?.find(
                                      (d) => d.icds === disease.icds
                                    )
                                  ) {
                                    setSelectedDiseasesList(
                                      selectedDiseasesList?.map((v, i) => {
                                        if (i === index) {
                                          return [...v, disease]
                                        } else {
                                          return v
                                        }
                                      })
                                      // selectedDiseases.concat(disease)
                                    )
                                  } else {
                                    return
                                  }
                                }}
                                insuranceCode={
                                  Object.keys(tentmentInfo).length
                                    ? tentmentInfo?.insuranceArray[0]?.insuranceCode
                                    : 'ZIFEI'
                                }
                              />
                            </Item>
                            <Item>
                              {fields.length > 1 &&
                              (fields.length !== index + 1 ||
                                fields.length == 2) &&
                              index !== 0 ? (
                                <MinusCircleOutlined
                                  onClick={() => {
                                    remove(field.name)
                                    setSelectedDiseasesList(
                                      selectedDiseasesList.filter((v, i) => {
                                        if (i === index) {
                                          return
                                        } else {
                                          return v
                                        }
                                      })
                                    )
                                    toothChange(2, 'remove', index)
                                  }}
                                />
                              ) : null}
                              {(index + 1 === fields.length &&
                                fields.length !== 2) ||
                              index === 0 ? (
                                <PlusCircleOutlined
                                  onClick={() => {
                                    if (fields.length < 5) {
                                      add()
                                      setSelectedDiseasesList([
                                        ...selectedDiseasesList,
                                        [],
                                      ])
                                      toothChange(2, 'add', index)
                                    }
                                  }}
                                />
                              ) : null}
                            </Item>
                          </Space>
                          <Col flex='7.125rem' />
                          {selectedDiseasesList[index]?.length ? (
                            <Col
                              flex='1'
                              style={{
                                borderRadius: 2,
                                border: `1px solid ${theme.wbd}`,
                                padding: '10px 0 0 10px',
                                marginBottom: 10,
                              }}
                            >
                              {selectedDiseasesList[index]?.map((d: any) => (
                                <div key={d.icds} className={styles.disease}>
                                  {`${d.name}/${d.icds}`}{' '}
                                  <CloseOutlined
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                      setSelectedDiseasesList(
                                        selectedDiseasesList.map((v, i) => {
                                          if (i === index) {
                                            return v.filter(
                                              (item) => item?.icds !== d?.icds
                                            )
                                          } else {
                                            return v
                                          }
                                        })
                                      )
                                    }}
                                  />
                                </div>
                              ))}
                            </Col>
                          ) : (
                            <></>
                          )}
                        </>
                      ))}
                    </Item>
                  )}
                </Form.List>
                <Form.List name='treatmentTooth'>
                  {(fields, { add, remove }) => (
                    <Item label='治疗计划'>
                      {fields.map((field, index) => (
                        <Space
                          key={field.key}
                          align='baseline'
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          <Item
                            noStyle
                            shouldUpdate={(prevValues, curValues) =>
                              prevValues.area !== curValues.area ||
                              prevValues.sights !== curValues.sights
                            }
                          >
                            {() => (
                              <Item required>
                                <Dropdown
                                  overlay={
                                    <Menu toothIndex={3} teethIndex={index} />
                                  }
                                  placement='bottomLeft'
                                  getPopupContainer={(triggerNode: any) =>
                                    triggerNode.parentNode
                                  }
                                  visible={tooth[3][index].modalShow}
                                  onVisibleChange={(v) => {
                                    if (!v) {
                                      toothChange(0, 'notShow', index)
                                    }
                                  }}
                                >
                                  <ToothTable
                                    tableShow={() => {
                                      toothChange(3, 'show', index)
                                    }}
                                    LT={renderTeeth(3, index, 0)}
                                    RT={renderTeeth(3, index, 1)}
                                    LB={renderTeeth(3, index, 2)}
                                    RB={renderTeeth(3, index, 3)}
                                  />
                                </Dropdown>
                              </Item>
                            )}
                          </Item>
                          <Item name={[field.name, 'treatmentPlan']}>
                            <AuxiliaryInput
                              value={tooth[3][index].Check}
                              record={CommonCodeCategory.ZLJH}
                              colBack={(v) => {
                                checkChange(3, index, v)
                              }}
                              onChange={(v) => {
                                checkChange(3, index, v, true)
                              }}
                              visibleChange={(isVisible) => {
                                if (isVisible) {
                                  toothChange(0, 'notAllShow', index)
                                }
                              }}
                            />
                          </Item>
                          <Item>
                            {fields.length > 1 &&
                            (fields.length !== index + 1 ||
                              fields.length == 2) &&
                            index !== 0 ? (
                              <MinusCircleOutlined
                                onClick={() => {
                                  remove(field.name)
                                  toothChange(3, 'remove', index)
                                }}
                              />
                            ) : null}
                            {(index + 1 === fields.length &&
                              fields.length !== 2) ||
                            index === 0 ? (
                              <PlusCircleOutlined
                                onClick={() => {
                                  if (fields.length < 5) {
                                    add()
                                    toothChange(3, 'add', index)
                                  }
                                }}
                              />
                            ) : null}
                          </Item>
                        </Space>
                      ))}
                    </Item>
                  )}
                </Form.List>
                <Form.List name='disposeTooth'>
                  {(fields, { add, remove }) => (
                    <Item label='处置'>
                      {fields.map((field, index) => (
                        <Space
                          key={field.key}
                          align='baseline'
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          <Item
                            noStyle
                            shouldUpdate={(prevValues, curValues) =>
                              prevValues.area !== curValues.area ||
                              prevValues.sights !== curValues.sights
                            }
                          >
                            {() => (
                              <Item required>
                                <Dropdown
                                  overlay={
                                    <Menu toothIndex={4} teethIndex={index} />
                                  }
                                  placement='bottomLeft'
                                  getPopupContainer={(triggerNode: any) =>
                                    triggerNode.parentNode
                                  }
                                  visible={tooth[4][index].modalShow}
                                  onVisibleChange={(v) => {
                                    if (!v) {
                                      toothChange(0, 'notShow', index)
                                    }
                                  }}
                                >
                                  <ToothTable
                                    tableShow={() => {
                                      toothChange(4, 'show', index)
                                    }}
                                    LT={renderTeeth(4, index, 0)}
                                    RT={renderTeeth(4, index, 1)}
                                    LB={renderTeeth(4, index, 2)}
                                    RB={renderTeeth(4, index, 3)}
                                  />
                                </Dropdown>
                              </Item>
                            )}
                          </Item>
                          <Item name={[field.name, 'disposeContent']}>
                            <AuxiliaryInput
                              value={tooth[4][index].Check}
                              record={CommonCodeCategory.KQCZ}
                              colBack={(v) => {
                                checkChange(4, index, v)
                              }}
                              onChange={(v) => {
                                checkChange(4, index, v, true)
                              }}
                              visibleChange={(isVisible) => {
                                if (isVisible) {
                                  toothChange(0, 'notAllShow', index)
                                }
                              }}
                            />
                          </Item>
                          <Item>
                            {fields.length > 1 &&
                            (fields.length !== index + 1 ||
                              fields.length == 2) &&
                            index !== 0 ? (
                              <MinusCircleOutlined
                                onClick={() => {
                                  remove(field.name)
                                  toothChange(4, 'remove', index)
                                }}
                              />
                            ) : null}
                            {(index + 1 === fields.length &&
                              fields.length !== 2) ||
                            index === 0 ? (
                              <PlusCircleOutlined
                                onClick={() => {
                                  if (fields.length < 5) {
                                    add()
                                    toothChange(4, 'add', index)
                                  }
                                }}
                              />
                            ) : null}
                          </Item>
                        </Space>
                      ))}
                    </Item>
                  )}
                </Form.List>
                <Item
                  label='治疗意见'
                  name='treatmentConclusion'
                  style={{ marginBottom: '180px' }}
                >
                  <AuxiliaryInput
                    value={checkStr}
                    record={CommonCodeCategory.KQZL}
                    colBack={(v) => {
                      setCheckStr(checkStr.concat(v))
                    }}
                    onChange={(v) => {
                      setCheckStr(v)
                    }}
                  />
                </Item>
              </div>
            </>
          ) : (
            <>
              <Form.Item
                label={medicalRecord === 2  ? '中医诊断' : '疾病名称'}
                name='disease'
                required
                rules={[
                  {
                    max: 500,
                    message: '最长500位!',
                  },
                  () => ({
                    validator: () => {
                      if (isDiseaseEmpty) {
                        return Promise.reject('请输入主要疾病')
                      }
                      return Promise.resolve()
                    },
                  }),
                ]}
              >
                <DiseaseInput
                  isMedical={1}
                  placeholder='请输入主要疾病'
                  onChange={() => setIsDiseaseEmpty(false)}
                  onSelect={(disease) => {
                    if (
                      !selectedDiseases.find((d) => d?.icds === disease?.icds)
                    ) {
                      setSelectedDiseases(selectedDiseases.concat(disease))
                    }
                  }}
                  insuranceCode={
                    Object.keys(tentmentInfo).length
                      ? tentmentInfo?.insuranceArray[0]?.insuranceCode
                      :'ZIFEI'
                  }
                />
              </Form.Item>
              <Row
                style={{
                  display: selectedDiseases.length === 0 ? 'none' : undefined,
                }}
              >
                <Col flex='8.375rem' />
                <Col
                  flex='1'
                  style={{
                    borderRadius: 2,
                    border: `1px solid ${theme.wbd}`,
                    padding: '10px 10px 0 10px',
                    marginTop: -10,
                    marginBottom: 20,
                  }}
                >
                  {selectedDiseases.map((d) => (
                    <div key={d.icds} className={styles.disease}>
                      {`${d.name}/${d.icds}`}
                      <CloseOutlined
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setSelectedDiseases(
                            selectedDiseases.filter((i) => i?.icds !== d?.icds)
                          )
                        }}
                      />
                    </div>
                  ))}
                </Col>
              </Row>
              {medicalRecord === 2 ? (
                <>
                  <Form.Item name='pulseCondition' label='脉象'>
                    <TextArea placeholder='请输入内容' autoSize />
                  </Form.Item>
                  <Form.Item name='tonguePicture' label='舌象'>
                    <TextArea placeholder='请输入内容' autoSize />
                  </Form.Item>
                </>
              ) : (
                <Form.Item
                  label='体格检查'
                  name='physicalExamination'
                  rules={[
                    {
                      max: 500,
                      message: '最长500位!',
                    },
                  ]}
                >
                  <TextArea placeholder='请输入内容' autoSize />
                </Form.Item>
              )}

              <Form.Item
                label='检验检查'
                name='inspectionCheck'
                rules={[
                  {
                    max: 500,
                    message: '最长500位!',
                  },
                ]}
              >
                <TextArea placeholder='请输入内容' autoSize />
              </Form.Item>
              <Form.Item label='治疗意见' name='treatmentConclusion'>
                <TextArea placeholder='请输入内容' autoSize />
              </Form.Item>
            </>
          )}
        </Form>
        <Space
          style={{
            alignSelf: 'flex-end',
            marginRight: 40,
            marginTop: 15,
            marginBottom: 15,
          }}
        >
          <Button
            style={{
              width: '7.375rem',
            }}
            disabled={selectedCategoryId == '-1'}
            onClick={() => {
              diagnosisForm.resetFields()
              setSelectedDiseases([])
              setSelectedDiseasesList([[]])
              // setMedicalRecord(c.medicalRecord)
              // setSelectedCategoryId(c.id)
              setSelectedTemplateId('')
            }}
          >
            取消
          </Button>
          <Button
            type='primary'
            style={{
              width: '7.375rem',
            }}
            onClick={save}
          >
            保存模版
          </Button>
        </Space>
      </Col>
      <Modal
        title={`${selectedCategoryId ? '修改' : '添加'}模版分类`}
        visible={isCategoryModalVisible}
        onCancel={() => setIsCategoryModalVisibile(false)}
        onOk={async () => {
          try {
            const values = await categoryForm.validateFields()
            await dispatch(
              createOrUpdateTemplateCategory({
                ...values,
                id: selectedCategoryId,
                medicalRecord: edition == 1 ? 3 : values?.medicalRecord,
              })
            )
            categoryForm.resetFields()
            reload('')
            setTemplateKeyword('')
            setIsCategoryModalVisibile(false)
          } catch {
            // do nothing.
          }
        }}
      >
        <Form form={categoryForm} autoComplete='off' {...layOut}>
          <Form.Item
            name='categoryName'
            label='分类名称'
            rules={[
              {
                required: true,
                message: '请输入分类名称',
              },
              {
                pattern: /^[^\s]*$/,
                message: '模版分类不能存在空格',
              },
            ]}
          >
            <Input ref={categoryRef} autoFocus />
          </Form.Item>
          {edition == 1 ? (
            <></>
          ) : (
            <Form.Item
              name='medicalRecord'
              label='模板病历类型'
              rules={[
                {
                  required: true,
                  message: '请选择模板病历类型',
                },
              ]}
            >
              <Radio.Group>
                {selectedCategoryId
                  ? TemPlateOption.map((v, i) => {
                      return (
                        <Radio
                          key={i}
                          value={v}
                          style={{
                            display:
                              selectedCategoryId &&
                              (categoryForm.getFieldValue('medicalRecord') == 7 ? 1 : categoryForm.getFieldValue('medicalRecord')) === v
                                ? 'inline-block'
                                : 'none',
                          }}
                          disabled
                        >
                          {getTemPlateName(v)}
                        </Radio>
                      )
                    })
                  : TemPlateOption.map((v, i) => {
                      return (
                        <Radio key={i} value={v}>
                          {getTemPlateName(v)}
                        </Radio>
                      )
                    })}
              </Radio.Group>
            </Form.Item>
          )}
        </Form>
      </Modal>
      <SaveTemplateModal
        commonName={commonName}
        medicalRecord={medicalRecord}
        tooth={tooth}
        visible={!!targetDetail}
        categoryName={
          categories.find((t) => t.id === selectedCategoryId)?.categoryName
        }
        detail={targetDetail}
        onCancel={() => {
          setTargetDetail(undefined)
        }}
        onSave={async (categoryId) => {
          if (!categories.find((c) => c.id === categoryId)) {
            setTemplateKeyword('')
            reload('')
          }
          if (categoryId) {
            // load(categoryId, true)
            reflesh(categoryId)
          }
          diagnosisForm.resetFields()
          setSelectedDiseases([])
          setSelectedDiseasesList([[]])
          diagnosisForm.setFieldsValue({
            stomatologyTooth: [''],
            inspectionTooth: [''],
            diseaseTooth: [''],
            treatmentTooth: [''],
            disposeTooth: [''],
          })
          setTooth([
            [{ teethStr: ['', '', '', ''], modalShow: false, Check: '' }],
            [{ teethStr: ['', '', '', ''], modalShow: false, Check: '' }],
            [{ teethStr: ['', '', '', ''], modalShow: false, Check: '' }],
            [{ teethStr: ['', '', '', ''], modalShow: false, Check: '' }],
            [{ teethStr: ['', '', '', ''], modalShow: false, Check: '' }],
          ])
          setTargetDetail(undefined)
        }}
      />
    </Row>
  )
}
