/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-12-31 13:52:38
 * @LastEditors: linxi
 * @LastEditTime: 2023-10-09 11:42:38
 */
/* eslint-disable react/prop-types */
import {
  CaretDownFilled,
  CloseOutlined,
  EllipsisOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
  PlusOutlined,
  SearchOutlined,
} from '@ant-design/icons'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
} from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { RcFile } from 'antd/lib/upload'
import { UploadFile } from 'antd/lib/upload/interface'
import moment from 'moment'
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectEdition } from '../../../app/applicationSlice'
import { RootDispatch } from '../../../app/store'
import FileUpload from '../../../compnents/file/FileUpload'
import { CommonCodeCategory, SurgicalList } from '../../../models/commoncode'
import { DateTimeFormat, DateTimeFormatSimple } from '../../../models/datetime'
import {
  GenderOptions,
  getGenderName,
  StationCategory,
} from '../../../models/user'
import { OpRecord } from '../../../services/operatingRoom'
import { surgicalBody } from '../../../services/surgical'
import { useQuery } from '../../../utils/HttpUtils'
import {
  getRegistByPatientIdGneralAsync,
  selectPatientInfo,
} from '../../A-toothModule/patient/patientSlice'
import {
  getCommonCodeCompletionsAsync,
  getUsersInDepartmentAsync,
} from '../../completion/completionSlice'
import { getDepartmentList } from '../../department/departmentSlice'
import { getOperaingRoomList } from '../../operatingRoom/operatingRoomSlice'
import { getTreatments, selectRegistration } from '../diagnosis/diagnosisSlice'
import { PreferencesSetModal } from '../diagnosis/preferencesSetModal'
import { ToothComponents } from '../editor/modals/toothComponents'
import { getUrl } from '../oralCases/oralCasesSlice'
import { HistoryTreatment, HistoryTreatmentsRef } from './historyTreatment'
import styles from './Surgical.module.css'
const { Item } = Form
const { Option } = Select

export interface surgicalProps {
  [CommonCodeCategory.MZFS]: [] // 麻醉方式
  [CommonCodeCategory.SSBW]: [] // 手术部位
  [CommonCodeCategory.SSDJ]: [] // 手术等级
  [CommonCodeCategory.SSQK]: [] // 手术切口
  [CommonCodeCategory.TW]: [] // 体位
  [CommonCodeCategory.ZZ]: []
}
export interface SurgicalFromProps {
  toothPatient?: boolean //口腔专业版
  id?: number
  tag?: any
  subForm: (vs: surgicalBody) => void
  PreferencesSetChange?: () => void
  patientId?: string
}

const SurgicalFrom = (props: SurgicalFromProps, ref: any) => {
  const tips = {
    input: '请输入',
    select: '请选择',
    position: '请选择手术部位',
    level: '请选择手术等级',
    incision: '请选择手术切开',
    assistant: '请选择助手',
    duty: '请选择职责',
  }

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  }

  const simpleLayout = {
    labelCol: { span: 12 },
    wrapperCol: { span: 12 },
  }

  const BatLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
  }

  const SelectLayout = {
    labelCol: { flex: ' 0 0 0rem' },
    wrapperCol: { flex: 1 },
  }

  const dispatch = useDispatch<RootDispatch>()

  const [form] = Form.useForm()

  const hisVersion = useSelector(selectEdition) //his版本 0通用 1口腔

  const query = useQuery()

  const [userMessage, setUserMessage] = useState<any>()

  const [fileList, setFileList] = useState<UploadFile[]>([])

  const [isModalVisible, setIsModalVisible] = useState(false)

  const registration = useSelector(selectRegistration)

  const historyTreatmentsRef = useRef<HistoryTreatmentsRef>(null)

  const [surgical, setSurgical] = useState<surgicalProps>()

  const [opRoom, setOpRoom] = useState<OpRecord[]>([])

  const [doctorList, setDoctorList] = useState<any>([])

  const [assistantList, setAssistantList] = useState<any>([])

  const [departmentList, setDepartmentList] = useState<any>([])

  const [registrations, setRegistration] = useState<any>()

  const [preferencesSetShow, setiPreferencesSetShow] = useState(false) //偏好设置

  const [disposeSelectOptions, setDisposeSelectOptions] = useState<any[]>([])

  const [operatingToothBefore, setoperatingToothBefore] = useState(
    '--@--@--@--'
  )

  const detail = useSelector(selectPatientInfo)

  useImperativeHandle(ref, () => ({
    subSurgical: () => {
      return form.submit() //提交手术申请
    },
  }))

  useEffect(() => {
    if (!props.toothPatient || !detail) return

    const data = {
      patientAge: detail?.ageYear,
      patientId: detail?.patientId,
      patientName: detail?.patientName,
      patientSex: detail?.patientSex,
    }
    setUserMessage(data)
  }, [props.toothPatient, detail])

  useEffect(() => {
    if (query.get('registrationId'))
      dispatch(
        getTreatments({
          registrationId: query.get('registrationId') || '',
        })
      )
        .then(unwrapResult)
        .then((v: any) => {
          setRegistration(v?.registration)
        })
  }, [dispatch])

  useEffect(() => {
    form.setFieldsValue({
      operatingContent: [{}],
      helper: [{}],
      name:
        (userMessage?.createTime
          ? moment(userMessage?.createTime).format(DateTimeFormatSimple)
          : '') +
        ' ' +
        (userMessage?.disease ? userMessage?.disease : ''),
      patientName: userMessage?.patientName,
      patientSex: userMessage?.patientSex,
      patientAge: userMessage?.patientAge,
    })
  }, [userMessage])

  useEffect(() => {
    if (doctorList) {
      dispatch(
        getUsersInDepartmentAsync({
          departmentId: '',
          stationCategory: StationCategory.Nurse,
        })
      )
        .then(unwrapResult)
        .then((res) => {
          setAssistantList(doctorList.concat(res))
        })
    }
  }, [doctorList])

  useEffect(() => {
    props.patientId &&
      dispatch(
        getRegistByPatientIdGneralAsync({
          patientId: props.patientId,
          size: 30,
        })
      )
        .then(unwrapResult)
        .then((res: any) => {
          setDisposeSelectOptions(res)
        })
  }, [props.patientId])

  useEffect(() => {
    SurgicalList.forEach((v) => {
      dispatch(getCommonCodeCompletionsAsync({ category: v, keyword: '' }))
        .then(unwrapResult)
        .then((res) => {
          setSurgical((i: any) => {
            return { ...i, [v]: res.records }
          })
        })
    })
    dispatch(getOperaingRoomList({ current: 1, size: 1000 }))
      .then(unwrapResult)
      .then((res) => {
        setOpRoom(res.records.filter((v) => v.state === 1))
      })
    dispatch(
      getUsersInDepartmentAsync({
        departmentId: '',
        stationCategory: StationCategory.DoctorOrPharmacist,
      })
    )
      .then(unwrapResult)
      .then((res) => {
        setDoctorList(res)
      })
    dispatch(getDepartmentList({ current: 1, size: 1000, status: 1 }))
      .then(unwrapResult)
      .then((res) => {
        setDepartmentList(res.items)
      })
  }, [])

  return (
    <div className={styles.surgicalFrom}>
      {/* <Label label='手术申请单' style={{ marginLeft: 20 }} /> */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingRight: 20,
        }}
      >
        <p className={styles.surgTitle}>手术申请单</p>
        {props?.tag != 'operation' && !props.toothPatient && (
          <div
            style={{ fontSize: '24px', cursor: 'pointer' }}
            onClick={() => setiPreferencesSetShow(true)}
          >
            <EllipsisOutlined />
          </div>
        )}
      </div>
      <Divider />
      <div style={{ overflow: 'auto' }}>
        <Form
          autoComplete='off'
          form={form}
          name='advanced_search'
          onFinish={(fs) => {
            const mainDoctorName =
              (doctorList.find((u: any) => u.id === fs.mainDoctorId) as any)
                ?.name || ''
            const anesthesiaDoctorName =
              (doctorList.find(
                (u: any) => u.id === fs.anesthesiaDoctorId
              ) as any)?.name || ''
            const operatingRoomName =
              (opRoom.find((u: any) => u.id === fs.operatingRoomId) as any)
                ?.name || ''
            const treatmentDepartmentName =
              (departmentList.find(
                (u: any) => u.id === fs.treatmentDepartmentId
              ) as any)?.name || ''
            const toothTreatment = disposeSelectOptions.find(
              (v: any) => v.registrationId == fs.registrationId
            )
            const subToothTreatmentId =
              toothTreatment && toothTreatment?.treatmentId > 0
                ? toothTreatment?.treatmentId
                : undefined
            const subToothTreatmentNo =
              toothTreatment && toothTreatment?.treatmentNo > 0
                ? toothTreatment?.treatmentNo
                : undefined
            props.subForm({
              ...fs,
              appointmentTime: moment(fs.appointmentTime).format(
                DateTimeFormat
              ),
              operatingContent: JSON.stringify(fs.operatingContent),
              helper: JSON.stringify(fs.helper),
              registrationId:
                query.get('registrationId') ||
                userMessage?.registrationId ||
                fs.registrationId,
              mainDoctorName,
              anesthesiaDoctorName,
              operatingRoomName,
              treatmentDepartmentName,
              patientAge:
                props?.tag == 'operation'
                  ? userMessage?.patientAge
                  : registrations?.patientAge,
              patientId:
                (props?.tag == 'operation'
                  ? userMessage?.patientId
                  : registrations?.patientId) || props.patientId,
              patientName:
                props?.tag == 'operation'
                  ? userMessage?.patientName
                  : registrations?.patientName,
              patientSex:
                props?.tag == 'operation'
                  ? userMessage?.patientSex
                  : registrations?.patientSex,
              coherentVideo: fileList
                .map((v) => `${v.name}@${v.uid}@`.concat(v.url || ''))
                .join(','),
              treatmentId:
                props?.tag == 'operation'
                  ? props.toothPatient
                    ? subToothTreatmentId
                    : userMessage?.id
                  : registrations?.treatmentId,
              // : registrations?.treatmentId) || toothTreatmentId > 0 ? toothTreatmentId : undefined,
              treatmentNo:
                props?.tag == 'operation'
                  ? props.toothPatient
                    ? subToothTreatmentNo
                    : userMessage?.treatmentNo
                  : registrations?.treatmentNo,
              operatingToothBefore,
            })
          }}
          {...layout}
        >
          <Row style={{ padding: '0 20px', paddingRight: '40px' }}>
            {props?.tag == 'operation' && !props.toothPatient && (
              <>
                <Col span={24}>
                  <Col span={12}>
                    <Item
                      label='关联病历'
                      required
                      rules={[{ required: true, message: '请选择病历' }]}
                      name='name'
                    >
                      <Input
                        placeholder={tips.input}
                        prefix={<SearchOutlined />}
                        onClick={() => setIsModalVisible(true)}
                      />
                    </Item>
                  </Col>
                </Col>
                <Col span={12}>
                  <Item
                    label='患者姓名'
                    name='patientName'
                    required
                    rules={[{ required: true, message: '请输入姓名' }]}
                  >
                    <Input
                      placeholder={tips.input}
                      disabled
                      style={{ width: '100%' }}
                    />
                  </Item>
                </Col>
                <Col span={12}>
                  <Row gutter={10}>
                    <Col span={16}>
                      <Item
                        label='性别'
                        name='patientSex'
                        required
                        {...simpleLayout}
                        rules={[{ required: true, message: '请输入姓名' }]}
                      >
                        <Select
                          placeholder={tips.input}
                          disabled
                          style={{ width: '70%' }}
                        >
                          {GenderOptions.map((v) => (
                            <Option key={v} value={v}>
                              {getGenderName(v)}
                            </Option>
                          ))}
                        </Select>
                      </Item>
                    </Col>
                    <Col span={8}>
                      <Item
                        name='patientAge'
                        label='年龄'
                        required
                        rules={[{ required: true, message: '请输入姓名' }]}
                      >
                        <Input
                          placeholder={tips.input}
                          disabled
                          style={{ width: '100%' }}
                        />
                      </Item>
                    </Col>
                  </Row>
                </Col>
              </>
            )}
            {props.toothPatient && (
              <Col span={24}>
                <Item
                  label='就诊信息'
                  name='registrationId'
                  {...BatLayout}
                  rules={[{ required: true }]}
                >
                  <Select
                    placeholder={tips.select}
                    suffixIcon={<CaretDownFilled />}
                    allowClear
                    showSearch
                    filterOption={(input, option: any) => {
                      return (option?.children ?? '')
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }}
                  >
                    {disposeSelectOptions.length != 0 &&
                      disposeSelectOptions.map((v: any) => (
                        <Option key={v.registrationId} value={v.registrationId}>
                          {`${v.registrationTime} ${v.treatmentDoctorName} ${
                            v.insuranceCode == 'ZIFEI'
                              ? '自费'
                              : v.insuranceCode == '' || v.insuranceCode == null
                              ? '未知险种'
                              : '医保刷卡'
                          } (${v.treatmentDepartmentName}) ${v.disease}`}
                        </Option>
                      ))}
                  </Select>
                </Item>
              </Col>
            )}
            <Col span={12}>
              <Item label='预约手术时间' name='appointmentTime'>
                <DatePicker
                  style={{ width: '100%' }}
                  format={DateTimeFormat}
                  showTime
                />
              </Item>
            </Col>
            <Col span={12}>
              <Item
                label='手术时长'
                name='appointmentDuration'
                rules={[
                  {
                    pattern: /^\d+$|^\d+[.]?\d+$/,
                    message: '只能输入纯数字，请重新输入',
                  },
                ]}
              >
                <Input placeholder={tips.input} suffix='分钟' />
              </Item>
            </Col>
            <Col span={12}>
              <Item label='手术室' name='operatingRoomId'>
                <Select
                  placeholder={tips.select}
                  suffixIcon={<CaretDownFilled />}
                >
                  {opRoom.map((v) => (
                    <Option key={v.id} value={v.id}>
                      {v.name}
                    </Option>
                  ))}
                </Select>
              </Item>
            </Col>
            <Col span={12}>
              <Item label='手术科室' name='treatmentDepartmentId'>
                <Select
                  placeholder={tips.select}
                  suffixIcon={<CaretDownFilled />}
                >
                  {departmentList.map((v: any) => (
                    <Option key={v.id} value={v.id}>
                      {v.name}
                    </Option>
                  ))}
                </Select>
              </Item>
            </Col>
            {props.toothPatient && (
              <Col span={24}>
                <Item label='术前诊断' {...BatLayout}>
                  <Row>
                    <Col span={4}>
                      <ToothComponents
                        isShowSave={false}
                        onChange={(v, t) => {
                          setoperatingToothBefore(
                            v.map((v: any) => (v ? v : '--')).join('@')
                          )
                        }}
                      />
                    </Col>
                    <Col span={20}>
                      <Item
                        name='operatingDiagnoseBefore'
                        rules={[
                          {
                            max: 512,
                          },
                        ]}
                      >
                        <Input placeholder={tips.input} />
                      </Item>
                    </Col>
                  </Row>
                </Item>
              </Col>
            )}
            <Row style={{ marginTop: hisVersion == 1 ? '-30px' : '0' }}>
              <Col span={24}>
                <Form.List name='operatingContent'>
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map((field, index) => (
                        <Row key={field.key}>
                          <Col span={12}>
                            <Item
                              label='手术名称'
                              {...layout}
                              required
                              name={[field.name, 'surgIcalName']}
                              rules={[{ required: true }]}
                            >
                              <Input
                                placeholder={tips.input + '手术名称'}
                              ></Input>
                            </Item>
                          </Col>
                          <Col span={12}>
                            <Row
                              gutter={10}
                              style={{
                                paddingLeft: '10px',
                                position: 'relative',
                              }}
                            >
                              {!props.toothPatient && (
                                <Col span={8}>
                                  <Item
                                    label='手术部位'
                                    name={[field.name, 'bw']}
                                    rules={[{ required: true }]}
                                    {...SelectLayout}
                                  >
                                    <Select
                                      placeholder={tips.position}
                                      suffixIcon={<CaretDownFilled />}
                                      style={{ width: '100%' }}
                                    >
                                      {surgical?.SSBW &&
                                        surgical?.SSBW.map((v: any) => (
                                          <Option key={v.id} value={v.codeName}>
                                            {v.codeName}
                                          </Option>
                                        ))}
                                    </Select>
                                  </Item>
                                </Col>
                              )}
                              <Col span={props.toothPatient ? 12 : 8}>
                                <Item
                                  label='手术等级'
                                  name={[field.name, 'dj']}
                                  rules={[{ required: true }]}
                                  {...SelectLayout}
                                >
                                  <Select
                                    placeholder={tips.level}
                                    suffixIcon={<CaretDownFilled />}
                                    style={{ width: '100%' }}
                                  >
                                    {surgical?.SSDJ &&
                                      surgical?.SSDJ.map((v: any) => (
                                        <Option key={v.id} value={v.codeName}>
                                          {v.codeName}
                                        </Option>
                                      ))}
                                  </Select>
                                </Item>
                              </Col>
                              <Col span={props.toothPatient ? 12 : 8}>
                                <Item
                                  label='手术切开'
                                  name={[field.name, 'qk']}
                                  rules={[{ required: true }]}
                                  {...SelectLayout}
                                >
                                  <Select
                                    placeholder={tips.incision}
                                    suffixIcon={<CaretDownFilled />}
                                    style={{ width: '100%' }}
                                  >
                                    {surgical?.SSQK &&
                                      surgical?.SSQK.map((v: any) => (
                                        <Option key={v.id} value={v.codeName}>
                                          {v.codeName}
                                        </Option>
                                      ))}
                                  </Select>
                                </Item>
                              </Col>
                              {index !== 0 ? (
                                <MinusCircleOutlined
                                  className={styles.moveBtn}
                                  onClick={() => {
                                    remove(field.name)
                                  }}
                                />
                              ) : null}
                              {index === 0 ? (
                                <PlusCircleOutlined
                                  className={styles.moveBtn}
                                  onClick={() => {
                                    if (fields.length < 5) {
                                      add()
                                    }
                                  }}
                                />
                              ) : null}
                            </Row>
                          </Col>
                        </Row>
                      ))}
                    </>
                  )}
                </Form.List>
              </Col>
              <Col span={12}>
                <Item label='麻醉方式' name='anesthesiaMethod'>
                  <Select
                    placeholder={tips.select}
                    suffixIcon={<CaretDownFilled />}
                  >
                    {surgical?.MZFS &&
                      surgical?.MZFS.map((v: any) => (
                        <Option key={v.id} value={v.codeName}>
                          {v.codeName}
                        </Option>
                      ))}
                  </Select>
                </Item>
              </Col>
              <Col span={12}>
                <Item label='体位' name='position'>
                  <Select
                    placeholder={tips.select}
                    suffixIcon={<CaretDownFilled />}
                  >
                    {surgical?.TW &&
                      surgical?.TW.map((v: any) => (
                        <Option key={v.id} value={v.codeName}>
                          {v.codeName}
                        </Option>
                      ))}
                  </Select>
                </Item>
              </Col>
              <Col span={24}>
                <Item label='相关影像' {...BatLayout} name='coherentVideo'>
                  <FileUpload
                    count={fileList.length || 0}
                    maxCount={10}
                    files={fileList}
                    onUpload={(v, i) => {
                      setFileList([
                        ...fileList,
                        {
                          uid: v.uid,
                          size: v.size,
                          name: v.name,
                          type: v.type,
                          originFileObj: {} as RcFile,
                          url: i,
                        },
                      ])
                    }}
                  >
                    <Button icon={<PlusOutlined />}>上传附件</Button>
                  </FileUpload>
                  {fileList.map((item) => (
                    <Row
                      gutter={24}
                      key={item.uid}
                      style={{
                        padding: '10px',
                        width: '300px',
                      }}
                      wrap={false}
                    >
                      <Col
                        flex={1}
                        style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}
                      >
                        <span
                          style={{
                            display: 'inline-block',
                            maxWidth: '60px',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            verticalAlign: 'middle',
                          }}
                        >
                          {item.name.split('.')[0]}
                        </span>
                        <span
                          style={{
                            verticalAlign: 'middle',
                          }}
                        >
                          .{item.name.split('.')[1]}
                        </span>
                      </Col>
                      <Col span={6}>
                        <a
                          onClick={() => {
                            dispatch(getUrl({ fileName: item.url }))
                              .then(unwrapResult)
                              .then((v: any) => {
                                const proxy =
                                  window.location.origin.indexOf(
                                    'his.develop.hydhis.com'
                                  ) >= 0
                                    ? '/proxyToOssDevPrivate'
                                    : window.location.origin.indexOf(
                                        'his.test.hydhis.com'
                                      ) >= 0
                                    ? '/proxyToOssTestPrivate'
                                    : window.location.origin.indexOf(
                                        'hydhis.cn'
                                      ) >= 0
                                    ? '/proxyToOssProdPrivate'
                                    : '/proxyToOssProdPrivate'
                                const url = (window.location.origin?.includes(
                                  'localhost'
                                )
                                  ? 'http://his.develop.hydhis.com'
                                  : window.location.origin) + proxy + v
                                window.open(url, '_blank')
                              })
                          }}
                        >
                          查看
                        </a>
                      </Col>
                      <Col span={6}>
                        <CloseOutlined
                          onClick={() => {
                            //  e.stopPropagation();
                            setFileList(() =>
                              fileList.filter((ite) => ite.uid !== item.uid)
                            )
                          }}
                        />
                      </Col>
                    </Row>
                  ))}
                </Item>
              </Col>
              <Col span={12}>
                <Item
                  label='主刀医生'
                  required
                  rules={[{ required: true }]}
                  name='mainDoctorId'
                >
                  <Select
                    placeholder={tips.select}
                    suffixIcon={<CaretDownFilled />}
                    showSearch
                    filterOption={(input, option) => {
                      return option?.className
                        ? ((option?.className as unknown) as string)
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        : false
                    }}
                  >
                    {doctorList.map((v: any) => (
                      <Option
                        className={v.mnemonicCode}
                        key={v.id}
                        value={v.id}
                      >
                        {v.name}
                      </Option>
                    ))}
                  </Select>
                </Item>
              </Col>
              <Col span={12}>
                <Item label='麻醉医生' name='anesthesiaDoctorId'>
                  <Select
                    placeholder={tips.select}
                    suffixIcon={<CaretDownFilled />}
                    showSearch
                    filterOption={(input, option) => {
                      return option?.className
                        ? ((option?.className as unknown) as string)
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        : false
                    }}
                  >
                    {doctorList.map((v: any) => (
                      <Option
                        className={v.mnemonicCode}
                        key={v.id}
                        value={v.id}
                      >
                        {v.name}
                      </Option>
                    ))}
                  </Select>
                </Item>
              </Col>
              <Col span={24}>
                <Form.List name='helper'>
                  {(fields, { add, remove }) => (
                    <Row wrap>
                      {fields.map((field, index) => (
                        <Col span={12} key={field.key}>
                          <Row gutter={10}>
                            <Col span={16}>
                              <Item
                                label='助手'
                                {...simpleLayout}
                                name={[field.name, 'assistant']}
                              >
                                <Select
                                  placeholder={tips.select}
                                  suffixIcon={<CaretDownFilled />}
                                  style={{ width: '100%' }}
                                  showSearch
                                  filterOption={(input, option) => {
                                    return option?.className
                                      ? ((option?.className as unknown) as string)
                                          .toLowerCase()
                                          .includes(input.toLowerCase())
                                      : false
                                  }}
                                >
                                  {assistantList.map((v: any) => (
                                    <Option
                                      className={v.mnemonicCode}
                                      key={v.id}
                                      value={v.name}
                                    >
                                      {v.name}
                                    </Option>
                                  ))}
                                </Select>
                              </Item>
                            </Col>
                            <Col span={8}>
                              <Item
                                label={''}
                                noStyle
                                name={[field.name, 'zz']}
                              >
                                <Select
                                  placeholder={tips.duty}
                                  suffixIcon={<CaretDownFilled />}
                                  style={{ width: '100%' }}
                                >
                                  {surgical?.ZZ &&
                                    surgical?.ZZ.map((v: any) => (
                                      <Option key={v.id} value={v.codeName}>
                                        {v.codeName}
                                      </Option>
                                    ))}
                                </Select>
                              </Item>
                              {index !== 0 ? (
                                <MinusCircleOutlined
                                  className={styles.moveBtn}
                                  onClick={() => {
                                    remove(field.name)
                                  }}
                                />
                              ) : null}
                              {index === 0 ? (
                                <PlusCircleOutlined
                                  className={styles.moveBtn}
                                  onClick={() => {
                                    if (fields.length < 5) {
                                      add()
                                    }
                                  }}
                                />
                              ) : null}
                            </Col>
                          </Row>
                        </Col>
                      ))}
                    </Row>
                  )}
                </Form.List>
              </Col>
              <Col span={24}>
                <Item label='备注' {...BatLayout} name='remark'>
                  <TextArea placeholder={tips.input} />
                </Item>
              </Col>
            </Row>
          </Row>
        </Form>
      </div>
      <Modal
        className={styles.history}
        width={1200}
        style={{ height: 720, maxHeight: 720, overflow: 'hidden' }}
        title='历史就诊信息'
        visible={isModalVisible}
        footer={null}
        onCancel={() => {
          historyTreatmentsRef.current?.reset()
          setIsModalVisible(false)
        }}
      >
        <HistoryTreatment
          ref={historyTreatmentsRef}
          style={{ height: '100%' }}
          patientId={registration?.patientId}
          onConnect={(vs) => {
            setUserMessage(vs?.t)
            setIsModalVisible(false)
          }}
        />
      </Modal>

      <PreferencesSetModal
        tabNum={'5'}
        isModalVisible={preferencesSetShow}
        handleCancel={() => setiPreferencesSetShow(false)}
        onsubmit={() => {
          props.PreferencesSetChange && props?.PreferencesSetChange()
          setiPreferencesSetShow(false)
        }}
      />
    </div>
  )
}

export default forwardRef(SurgicalFrom)
