/* eslint-disable @typescript-eslint/ban-types */
import { Fee } from '../models/fee'
import * as P from '../models/patient'
import { Patient } from '../models/patient'
import { PaymentRegistrationParams } from '../models/payment'
import { Registration, RegistrationState } from '../models/registration'
import { Settinglable } from '../models/setting'
import { baseDataType, serverType } from './commodity'
import { API_PREFIX } from './constant'
import { Request } from './request'

export function getRegistrationFeeList(insuranceCode?: string): Request {
  return {
    url: `${API_PREFIX}/blade-store/treatmentServiceCatalog/registrationFee/list`,
    method: 'GET',
    params: {
      current: 0,
      size: 1000,
      insuranceCode,
    },
  }
}

export interface RegistrationQueryParams {
  index?: number
  current?: number
  size?: number
  param?: string
  treatmentDepartmentId?: string
  treatmentDoctorId?: string
  registrationState?: RegistrationState
  registrationTimeHead?: string
  registrationTimeTail?: string
  _dateRange?: string
}

export function getRegistrationList(params: RegistrationQueryParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/registration/list`,
    method: 'GET',
    params: {
      ...params,
      index: undefined,
      _dateRange: undefined,
    },
  }
}

export interface patientInsurance {
  birthday: string
  brdw: string
  cbxz: string
  certType: string
  createUser: number
  dnzhye: string
  expContent: string
  fullCardInfo: string
  gwybz: string
  insuranceCode: string
  insuranceRegion: string
  insurance_start_date: string
  insurance_stop_date: string
  lnzhye: string
  medicalTreatmentType: string
  memberType: string
  mztclj: string
  name: string
  naty: string
  sex: string
  tbno: string
  treatmentStatus: string
  updateUser: number
  ybPersonalNo: string
  zhye: string
  cardType: string
}

export interface RegisterParams {
  patient: Patient
  registration: Registration
  fee?: Fee
  payment?: PaymentRegistrationParams
  patientInsurance?: patientInsurance
  disease?: string
  treatmentCategory?: any
}

export function register(params: RegisterParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/registration/save`,
    method: 'POST',
    body: {
      patient: P.toJson(params.patient),
      registration: params.registration,
      treatmentServiceCatalog: params.fee,
      payment: params.payment,
      patientInsurance: params.patientInsurance,
      disease: params.disease,
      treatmentCategory: params.treatmentCategory,
    },
  }
}

// 快速开单保存挂号
export interface RegisterAndTreatmentParams {
  patient: Patient
  registration: Registration
  fee?: Fee
  payment?: PaymentRegistrationParams
  patientInsurance?: patientInsurance
  disease?: string
}

export function registerAndTreatment(
  params: RegisterAndTreatmentParams
): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/treatment/savePatientAndRegistrationAndTreatment`,
    method: 'POST',
    body: {
      registrationVO: {
        patient: P.toJson(params.patient),
        registration: params.registration,
        treatmentServiceCatalog: params.fee,
        payment: params.payment,
        patientInsurance: params.patientInsurance,
      },
      disease: params.disease,
    },
  }
}

export function updateInsuranceCardInfo(body: patientInsurance): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/updateInsuranceCardInfo`,
    method: 'POST',
    body,
  }
}

export function cancelRegistration(registrationId: string): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/registration/cancel`,
    method: 'POST',
    params: {
      id: registrationId,
    },
  }
}

export function cancelCheckRegistration(registrationId: string): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/registration/cancelCheck`,
    method: 'GET',
    params: {
      id: registrationId,
    },
  }
}

export function getRegistrationDetail(registrationId: string): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/registration/detail`,
    method: 'GET',
    params: {
      registrationId,
    },
  }
}

export function getRegistrationCounts(
  params: Record<string, unknown>
): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/registration/count`,
    method: 'GET',
    params,
  }
}

// 今日患者或者数量
export function getPatientRegistrationCounts(): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/registration/treatmentCount`,
    method: 'GET',
  }
}

export function startTreatment(registrationId: string): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/registration/change`,
    method: 'POST',
    params: {
      id: registrationId,
    },
  }
}

export function getMedical(): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/seq/allocate/registrationNo`,
    method: 'POST',
  }
}

export interface PatientInfoBody {
  serverType: serverType
  body: {
    baseData: baseDataType
    registrationId?: any
    cardType: string
    fullCardInfo: string
    otherMap: any
  }
}

export function getPatientInfo(PatientInfo: PatientInfoBody): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/getPerBaseInfo_${PatientInfo.serverType}`,
    method: 'POST',
    body: PatientInfo.body,
  }
}

export interface RegisterBody {
  serverType: serverType
  body: {
    baseData: baseDataType
    begntime?: string
    cardType?: string
    cbxz?: string
    doctorCode?: string
    doctorName?: string
    fullCardInfo?: string
    gwybz?: string
    insuranceRegion?: string
    psnType?: string
    treatmentDepartmentCode?: string
    treatmentDepartmentName?: string
    treatmentNo?: string
    patientId?: string
    ybKebie?: string
    ybPersonalNo?: string
    name?: string
    registrationId?: string
    pi?: any
    patientName?: string
    treatmentId?: string
    oldTreatmentId?:string //用于医保异地患者二次结算的挂号请求
  }
}
export interface RegisterBodyForMultiYidi {
  serverType: serverType
  body: {
    baseData: baseDataType
    originRegistrationId?:string //用于医保异地患者二次结算的挂号请求
  }
}

// 医保挂号（用于异地患者多次结算时的补充挂号）
export function medicalRegisterForMultiYidi(register: RegisterBodyForMultiYidi): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/outpatientRregistrationForMultiYidi_${register.serverType}`,
    method: 'POST',
    body: register.body,
  }
}
// 医保挂号
export function medicalRegister(register: RegisterBody): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/outpatientRregistration_${register.serverType}`,
    method: 'POST',
    body: register.body,
  }
}

export interface registerCancelBody {
  serverType: serverType
  body: {
    baseData?: baseDataType
    exp_content?: string
    registrationId?: number
  }
}

// 医保取消挂号
export function medicalRegisterCancel(
  registerCancelBody: registerCancelBody
): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/outpatientRegistrationCancel_${registerCancelBody.serverType}`,
    method: 'POST',
    body: registerCancelBody.body,
  }
}

export interface searchBody {
  serverType: serverType
  body: {
    baseData: baseDataType
    insuplcAdmdvs?: string
    psnNo?: string
  }
}
export interface searchBodyLocal {
  psnNo?: string
}
// // 特慢病查询
export function searchQueryPsn(searchBody: searchBody): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/queryPsnOpspReg_${searchBody.serverType}`,
    method: 'POST',
    body: searchBody.body,
  }
}

// // 特慢病查询
export function insurancePatientSpecialDiseaseByYbPersonalNoAsync(
  ybPersonalNo: searchBody
): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/getInsurancePatientSpecialDiseaseByYbPersonalNo`,
    method: 'GET',
    params: ybPersonalNo,
  }
}

export interface searchParams {
  // patientId?: string
  registrationId?: any
}

// 根据医保个人编号查询慢特病
export function searchMedical(params: searchParams): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/getInsurancePatientSpecialDiseaseByRegistrationId`,
    // url: `${API_PREFIX}/blade-insurance/insurance/getInsurancePatientSpecialDisease`,
    method: 'GET',
    params,
  }
}

export interface ParcPsnSumBody {
  serverType: serverType
  body: {
    baseData: baseDataType
    psnNo: string
    insuranceRegion: string
  }
}
export interface ParcPsnSumBodyLocal {
  psnNo: string
}
// 人员累计信息查询
export function searchPsnSum(searchBody: searchBody): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/queryFixmedinsParcPsnSum_${searchBody.serverType}`,
    method: 'POST',
    body: searchBody.body,
  }
}

// 查询近三个月的医保用药记录
export interface SearchMARecordListBody {
  serverType: serverType
  body: {
    baseData: baseDataType
    currentPage: any
    pageSize: any
    patientId: any
    doctorId: any
  }
}
// 人员累计信息查询
export function searchMARecordList(
  searchBody: SearchMARecordListBody
): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/medicationRecordQuery_${searchBody.serverType}`,
    method: 'POST',
    body: searchBody.body,
  }
}

// 今日预约列表
export interface getToDayListPagePageFnParams {
  keyword: string | undefined
}

export function getToDayListPagePageFn(
  params: getToDayListPagePageFnParams
): Request {
  return {
    url: `${API_PREFIX}/blade-appointment/appointmentRecord/todayAppointment`,
    method: 'GET',
    params,
  }
}
//获取接诊类型是否显示
export interface getAcceptsTypeFnParams {
  type: string
}

export function getAcceptsTypeFn(params: getAcceptsTypeFnParams): Request {
  return {
    url: `${API_PREFIX}/blade-user/function/getValue`,
    method: 'GET',
    params,
  }
}

export interface saveSomeFunctionsParams {
  functionList: { type?: string; value?: string }
}
export function saveSomeFunctions(body: saveSomeFunctionsParams): Request {
  return {
    url: `${API_PREFIX}/blade-user/function/saveSomeFunctions`,
    method: 'POST',
    body,
  }
}

export function getReceiveTagOfOral(
  params: getRegistrationHistoryParams
): Request {
  return {
    url: `${API_PREFIX}/blade-user/function/getValueByRegist`,
    method: 'GET',
    params,
  }
}
//查询是否就诊
export interface getIsSeeDoctorFnParams {
  registration?: any
  idCard?: string | undefined
  name?: string | undefined
  patientId?: string
  phone?: number | undefined
}
//查询患者是否有挂号记录就诊
export interface getRegistrationHistoryParams {
  patientId?: string
}

export function getIsSeeDoctorFn(params: getIsSeeDoctorFnParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/registration/getReceiveTag`,
    method: 'GET',
    params,
  }
}

// 获取h5扫码挂号二维码
export function mobileTodayRegisterCode(): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/registration/getH5Params`,
    method: 'POST',
  }
}

// 人员累计信息查询(本地查询)
export function searchPsnSumForLocal(params: searchBodyLocal): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/cuminfo/selectPersonCumInfo`,
    method: 'GET',
    params,
  }
}

export interface cancelPayMoneyBody {
  treatmentId?: any
  recipeIdList?: any
}

export function cancelPayMoney(body: cancelPayMoneyBody): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/payment/cancelAggregateAndConvertToCash`,
    method: 'POST',
    body,
  }
}
