import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { EditableList } from '../../../compnents/list/EditableList'
import { drugStaticParams } from '../../../services/drugStatic'
import { DrugStaticColumns } from './column'
import {
  getDrugStaticList,
  selectCurrent,
  selectDrugStaticData,
  selectTotal,
  setCurrent,
  getDrugStaticExport,
  selectLoading,
  setLoading,
} from './drugStaticSlice'
import { DrugStaticModal } from './Modal/Modal'
import { DrugStaticQuery } from './Query'
import styles from './drugStatic.module.css'
import moment from 'moment'
import {
  DateTimeFormat,
  DateTimeFormatSimple,
  DateTimeFormatSimpleOnlyOne,
} from '../../../models/datetime'
import { RootDispatch } from '../../../app/store'
import { message, notification, Table, Col, Row } from 'antd'
import { DataType } from '../../putstorage/putstorage'
import { TableRowSelection } from 'antd/lib/table/interface'
import { lowerFirst, merge } from 'lodash'
import { PrintFrame, PrintFrameRef } from '../../print/PrintFrame'
import { TabBar, TabPane } from '../../../compnents/widgets/TabBar'
import { unwrapResult } from '@reduxjs/toolkit'

let drugStaticExportXLSXList: any[] = []

export const DrugStatic = (): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const [queries, setQueries] = useState<drugStaticParams>({
    timeHead: moment().startOf('day').format(DateTimeFormat),
    timeTail: moment().endOf('day').format(DateTimeFormat),
  })

  const [isModalVisible, setIsModalVisible] = useState(false)

  const [ids, setIds] = useState<any>([])

  const [selectionType] = useState<'checkbox' | 'radio'>('checkbox')

  const Current = useSelector(selectCurrent)

  const total = useSelector(selectTotal)

  const data: any = useSelector(selectDrugStaticData)

  const [size, setSize] = useState(10)

  const [name, setName] = useState('')

  const [localItemId, setLocalItemId] = useState(0)

  const [totalData, setTotalData] = useState<any>()

  const loading = useSelector(selectLoading)

  const printRef = useRef<PrintFrameRef>(null)

  const [ctrlAltPressed, setCtrlAltPressed] = useState(false)

  const rowSelection = {
    selectedRowKeys: ids,
    onSelect: (record: any, selected: boolean, row: DataType[]) => {
      if (selected) {
        drugStaticExportXLSXList.push(record)
      } else {
        drugStaticExportXLSXList = drugStaticExportXLSXList.filter(
          (item) => item.id !== record.id
        )
      }
      drugStaticExportXLSXList = [...(new Set(drugStaticExportXLSXList) as any)]
      setIds(drugStaticExportXLSXList.map((item) => item.id))
    },
    onSelectAll: (selected: boolean, rows: any, changeRows: any) => {
      if (selected) {
        drugStaticExportXLSXList.push(...changeRows)
      } else {
        changeRows.forEach((row: any) => {
          drugStaticExportXLSXList = drugStaticExportXLSXList.filter(
            (item) => item.id !== row.id
          )
        })
      }
      drugStaticExportXLSXList = [...(new Set(drugStaticExportXLSXList) as any)]
      setIds(drugStaticExportXLSXList?.map((item) => item.id))
    },
  }

  // 表格数据导出
  const exportXSLX = () => {
    dispatch(
      getDrugStaticExport({
        ...queries,
        timeHead: queries?.timeHead
          ? moment(queries?.timeHead)?.format(DateTimeFormatSimpleOnlyOne)
          : '',
        timeTail: queries?.timeTail
          ? moment(queries?.timeTail)?.format(DateTimeFormatSimpleOnlyOne)
          : '',
        current: Current,
        size,
        releasingTime: ctrlAltPressed ? '99' : undefined
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        const content = res
        const blob = new Blob([content])
        const fileName = '药品销售统计.xlsx'
        const selfURL = window[window.webkitURL ? 'webkitURL' : 'URL']
        const elink = document.createElement('a')
        if ('download' in elink) {
          elink.download = fileName
          elink.style.display = 'none'
          elink.href = selfURL['createObjectURL'](blob)
          document.body.appendChild(elink)
          // 触发链接
          elink.click()
          selfURL.revokeObjectURL(elink.href)
          document.body.removeChild(elink)
        } else {
          (navigator as any).msSaveBlob(blob, fileName)
        }
      })
      .then(() => {
        notification.success({
          message: '导出成功',
          duration: 3,
        })
      })
      .catch((res: any) => {
        notification.success({
          message: '导出失败',
          duration: 3,
        })
      })
    // const theader = [
    //   '药品名称',
    //   '生产厂家',
    //   '包装规格',
    //   '账簿单位',
    //   '剂型',
    //   '批次号',
    //   '开票项目',
    //   '整存数量',
    //   '拆零数量',
    //   '整存数量',
    //   '拆零数量',
    //   '整存成本',
    //   '拆零成本',
    //   '整存销售额',
    //   '拆零销售额',
    //   '利润',
    //   '供应商',
    // ]
    // const multiHeader = [
    //   [
    //     '',
    //     '',
    //     '',
    //     '',
    //     '',
    //     '',
    //     '',
    //     '库存数量',
    //     '',
    //     '销售数量',
    //     '',
    //     '药品成本',
    //     '',
    //     '销售额',
    //     '',
    //     '',
    //     '',
    //   ],
    // ]
    // const merges = ['H1:I1', 'J1:K1', 'L1:M1', 'N1:O1']
    // const filterVal = [
    //   'name',
    //   'tenantSupplierName',
    //   'packSpec',
    //   'accountUnit',
    //   'doseCategory',
    //   'batchNo',
    //   'billingCategory',
    //   'invWholeCount',
    //   'invSplitCount',
    //   'wholeCountInfo',
    //   'splitCountInfo',
    //   'wholeCost',
    //   'splitCost',
    //   'wholeRetail',
    //   'splitRetail',
    //   'profit',
    //   'vendorName',
    // ]
    // drugStaticExportXLSXList = drugStaticExportXLSXList?.map((item) => {
    //   return {
    //     ...item,
    //     splitCountInfo: item?.splitCountInfo.replace(/null/g, ' '),
    //     invWholeCount: item?.invWholeCount + item?.accountUnit,
    //     invSplitCount: item?.invSplitCount + item?.splitUnit,
    //     // wholeCost: item.wholeCost
    //     //   ? parseFloat((item.wholeCost as unknown) as string).toFixed(2)
    //     //   : "0.00",
    //     // splitCost: item?.splitCost
    //     //   ? parseFloat((item.splitCost as unknown) as string).toFixed(2)
    //     //   : "0.00",
    //     // wholeRetail: item?.wholeRetail
    //     //   ? parseFloat((item.wholeRetail as unknown) as string).toFixed(2)
    //     //   : "0.00",
    //     // splitRetail: item?.splitRetail
    //     //   ? parseFloat((item.splitRetail as unknown) as string).toFixed(2)
    //     //   : "0.00",
    //     // profit: item?.profit
    //     //   ? parseFloat((item.profit as unknown) as string).toFixed(2)
    //     //   : "0.00",
    //   }
    // })
    // const data = drugStaticExportXLSXList?.slice(0, 500)
    // const value =
    //   drugStaticExportXLSXList?.length >= 500
    //     ? data
    //     : drugStaticExportXLSXList?.map((v: any) =>
    //         filterVal?.map((j: any) => v[j])
    //       )
    // import('../../../ExportXLSX/Export2Excel').then((excel) => {
    //   excel.export_json_to_excel({
    //     header: theader,
    //     data: value,
    //     filename: '药品销售统计数据',
    //     autoWidth: true,
    //     bookType: 'xlsx',
    //     multiHeader,
    //     merges,
    //   })
    //   setIds([])
    //   setCurrent(1)
    //   drugStaticExportXLSXList = []
    // })
  }

  // useEffect(() => {
  //   if (drugStaticExportXLSXList?.length >= 500) {
  //     message.info('最多可导出500条')
  //   }
  // }, [drugStaticExportXLSXList])

  useEffect(() => {
    if (queries?.timeHead) {
      const endTime = moment(queries?.timeHead).add(6, 'month').endOf('month')
      if ((moment(queries?.timeTail) > endTime) && !ctrlAltPressed) {
        notification.info({
          message: '一次最多只能查询6个月的数据',
        })
      } else {
        dispatch(setLoading(true))
        dispatch(getDrugStaticList({ ...queries, current: Current, size }))
      }
    }
  }, [queries, size, Current])

  useEffect(() => {
    setTotalData(data?.[0])
  }, [data])

  document.addEventListener("keydown", function (event) {
    if (event.ctrlKey && event.altKey) {
      // 当按下Ctrl和Alt时，执行你想要的操作
      console.log("Ctrl + Alt 被按下");
      setCtrlAltPressed(true);
    }
  })

  document.addEventListener("keyup", function (event) {
    if (event.ctrlKey || event.altKey) {
      // 当按下Ctrl和Alt时，执行你想要的操作
      console.log("Ctrl + Alt 取消");
      setCtrlAltPressed(false);
    }
  })

  return (
    <>
      <TabBar style={{ margin: '10px 20px', position: 'relative' }}>
        <TabPane key='0' tab='药品销售统计'>
          <div className={styles.content}>
            <DrugStaticQuery
              ids={ids}
              outClued={() => {
                if (queries?.timeHead) {
                  const endTime = moment(queries?.timeHead)
                    .add(6, 'month')
                    .endOf('month')
                  if ((moment(queries?.timeTail) > endTime) && !ctrlAltPressed) {
                    notification.info({
                      message: '一次最多只能查询6个月的数据',
                    })
                  } else {
                    exportXSLX()
                  }
                }
              }}
              continueOrder={(v:any, type: string) => {
                if(type === 'djdd') {
                  setQueries({ ...queries, ...v, showPotion: Number(v) })
                } else {
                  setQueries({ ...queries, ...v, isAccurate: Number(v) })
                }
                dispatch(setCurrent(1))
                setIds([])
                drugStaticExportXLSXList = []
              }}
              onValueChange={(v) => {
                setQueries({ ...queries, ...v })
                dispatch(setCurrent(1))
                setIds([])
                drugStaticExportXLSXList = []
              }}
              print={() => {
                if (queries?.timeHead) {
                  const endTime = moment(queries?.timeHead)
                    .add(6, 'month')
                    .endOf('month')
                  if (moment(queries?.timeTail) > endTime) {
                    notification.info({
                      message: '一次最多只能查询6个月的数据',
                    })
                  } else {
                    printRef.current?.print()
                  }
                }
              }}
            />
            <EditableList
              loading={loading}
              className={styles.drugStatic}
              rowKey={(record) => record.id}
              bordered
              // rowSelection={
              //   {
              //     type: selectionType,
              //     ...rowSelection,
              //   } as TableRowSelection<any>
              // }
              page={{
                items: data,
                current: Current,
                size: size,
                total: total,
              }}
              summary={() => (
                <Table.Summary fixed>
                  <Table.Summary.Row className={styles.total}>
                    <Table.Summary.Cell index={0}></Table.Summary.Cell>
                    <Table.Summary.Cell index={1} align='center'>
                      合计
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={3}></Table.Summary.Cell>
                    <Table.Summary.Cell index={13} align='right'>
                      {totalData?.invWholeCountSum}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={14} align='right'>
                      {totalData?.invSplitCountSum}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={5} align='right'>
                      {totalData?.salesTotalQuantityCount}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={6} align='right'>
                      {totalData?.salesSplitQuantityCount}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={7} align='right'>
                      {totalData?.costTotalQuantity}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={8} align='right'>
                      {totalData?.costSplitQuantity}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={9} align='right'>
                      {totalData?.salesTotalQuantity}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={10} align='right'>
                      {totalData?.salesSplitQuantity}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={11} align='right'>
                      {totalData?.totalprofit}
                    </Table.Summary.Cell>
                    {/* <Table.Summary.Cell index={12}></Table.Summary.Cell> */}
                  </Table.Summary.Row>
                </Table.Summary>
              )}
              onChangePage={(current, pageSize) => {
                setSize(pageSize as number)
                dispatch(setCurrent(current))
              }}
              columns={DrugStaticColumns(size * (Current - 1))}
              onRow={(d: any) => ({
                onDoubleClick: (v) => {
                  setIsModalVisible(true)
                  setName(d.name)
                  setLocalItemId(d.localItemId)
                },
              })}
            />
          </div>
        </TabPane>
        <p style={{ position: 'absolute', right: '20px', top: '10px' }}>
          备注：如您想要查询更久远的数据，请联系我们的客服人员
        </p>
      </TabBar>
      <DrugStaticModal
        isModalVisible={isModalVisible}
        onOk={() => {
          setIsModalVisible(false)
        }}
        onCancel={() => {
          setIsModalVisible(false)
        }}
        timeHead={
          queries.timeHead
            ? queries.timeHead
            : moment().startOf('day').format(DateTimeFormat)
        }
        timeTail={
          queries.timeTail
            ? queries.timeTail
            : moment().endOf('day').format(DateTimeFormat)
        }
        drugName={name}
        localItemId={localItemId}
      />
      <PrintFrame
        ref={printRef}
        title={
          JSON.parse(localStorage.getItem('credentials') as string)?.tenantName
        }
        subTitle=''
      >
        <Row style={{ textAlign: 'right' }}>
          <Col span={13}>药品销售统计</Col>
          <Col span={11}>日期：{queries.timeHead?.split(' ')[0]} - {queries.timeTail?.split(' ')[0]}</Col>
        </Row>
        <EditableList
          className={styles.drugStatic}
          rowKey={(record) => record.id}
          bordered
          pagination={false}
          page={{
            items: data,
            current: Current,
            size: size,
            total: total,
          }}
          summary={() => (
            <Table.Summary fixed>
              <Table.Summary.Row className={styles.total}>
                <Table.Summary.Cell index={0}></Table.Summary.Cell>
                <Table.Summary.Cell index={1} align='center'>
                  合计
                </Table.Summary.Cell>
                <Table.Summary.Cell index={3}></Table.Summary.Cell>
                <Table.Summary.Cell index={13} align='right'>
                  {totalData?.invWholeCountSum}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={14} align='right'>
                  {totalData?.invSplitCountSum}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={5} align='right'>
                  {totalData?.salesTotalQuantityCount}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={6} align='right'>
                  {totalData?.salesSplitQuantityCount}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={7} align='right'>
                  {totalData?.costTotalQuantity}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={8} align='right'>
                  {totalData?.costSplitQuantity}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={9} align='right'>
                  {totalData?.salesTotalQuantity}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={10} align='right'>
                  {totalData?.salesSplitQuantity}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={11} align='right'>
                  {totalData?.totalprofit}
                </Table.Summary.Cell>
                {/* <Table.Summary.Cell index={12}></Table.Summary.Cell> */}
              </Table.Summary.Row>
            </Table.Summary>
          )}
          onChangePage={(current, pageSize) => {
            setSize(pageSize as number)
            dispatch(setCurrent(current))
          }}
          columns={DrugStaticColumns(size * (Current - 1))}
        />
      </PrintFrame>
    </>
  )
}
