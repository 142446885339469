import { unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Select,
  Space,
  Table,
} from 'antd'
import React, {
  forwardRef,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectInsuranceArray } from '../../../../app/applicationSlice'
import { RootDispatch } from '../../../../app/store'
import {
  DateRangeType,
  DateSelect,
  getDateRange,
  TJFXTime,
} from '../../../../compnents/form/DateSelect'
import { EditableList } from '../../../../compnents/list/EditableList'
import { columns } from './columns'
import {
  getOutPatientRegistrationReportExport,
  getOutPatientRegistrationReportList,
} from './outPatientRegistrationReportSlice'
import styles from './outPatientRegistrationReport.module.css'
import { ThemeContext } from '../../../../theme/ThemeContext'

export interface OutPatientRegistrationReportRef {
  tabKey?: any
  oneTabKey?: any
}

interface OutPatientRegistrationReportProps {
  tabKey?: any
  oneTabKey?: any
}
const OutPatientRegistrationReportList = (
  { tabKey, oneTabKey }: OutPatientRegistrationReportProps,
  ref: React.Ref<OutPatientRegistrationReportRef>
): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const insuranceArray = useSelector(selectInsuranceArray)

  const theme = useContext(ThemeContext)

  const [form] = Form.useForm()

  const [pageLoading, setPageLoading] = useState(false) //表格 loading

  const [timeHead, timeTail] = getDateRange(DateRangeType.Today)

  const [params, setParams] = useState<any>({
    current: 1,
    size: 10,
    startTime: timeHead,
    endTime: timeTail,
  })

  const [page, setPage] = useState({ items: [], total: 0 })

  const [allData, setAllData] = useState<any>()

  const getPage = () => {
    setPageLoading(true)
    dispatch(
      getOutPatientRegistrationReportList({
        ...params,
        current: params.current,
        size: params.size,
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        setAllData(res)
        setPage({
          items: res?.registionStatisticsVO?.records,
          total: res?.registionStatisticsVO?.total,
        })
      })
      .finally(() => setPageLoading(false))
  }

  const ListExport = () => {
    dispatch(getOutPatientRegistrationReportExport(params))
      .then(unwrapResult)
      .then((res: any) => {
        const content = res
        const blob = new Blob([content])
        const fileName = '门诊挂号统计导出数据.xlsx'
        const selfURL = window[window.webkitURL ? 'webkitURL' : 'URL']
        const elink = document.createElement('a')
        if ('download' in elink) {
          elink.download = fileName
          elink.style.display = 'none'
          elink.href = selfURL['createObjectURL'](blob)
          document.body.appendChild(elink)
          // 触发链接
          elink.click()
          selfURL.revokeObjectURL(elink.href)
          document.body.removeChild(elink)
        } else {
          (navigator as any).msSaveBlob(blob, fileName)
        }
      })
      .then(() => {
        notification.success({
          message: '导出成功',
          duration: 3,
        })
      })
      .catch((res: any) => {
        notification.error({
          message: '导出失败：' + res,
          duration: 3,
        })
      })
  }

  useEffect(() => {
    if (tabKey == 0 && oneTabKey == 0) {
      getPage()
    }
  }, [params, tabKey, oneTabKey])

  return (
    <Col
      style={{
        height: 'calc(100% - 30px)',
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Col
        style={{
          flex: 1,
          backgroundColor: theme.pn,
          borderRadius: 10,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Form
          form={form}
          autoComplete='off'
          onFinish={(values) => {
            setParams({
              ...params,
              ...values,
              current: 1,
              startTime: values?.timeHead ? values?.timeHead : timeHead,
              endTime: values?.timeTail ? values?.timeTail : timeTail,
              timeHead: undefined,
              timeTail: undefined,
            })
          }}
        >
          <Row
            align='middle'
            justify='space-between'
            style={{ marginTop: 10, marginBottom: 20 }}
          >
            <Space>
              <DateSelect
                style={{ width: '8.5rem' }}
                labelPrefix=''
                allowClear={false}
                options={TJFXTime}
                namePrefix='time'
                placeholder='收费列表-时间'
                initialValue={DateRangeType.Today}
                onChange={form.submit}
              />
              {insuranceArray && insuranceArray.length !== 0 && (
                <Form.Item name='insuranceCode' noStyle>
                  <Select
                    placeholder='险种类型'
                    style={{ width: '8.25rem' }}
                    allowClear
                    // suffixIcon={<CaretDownFilled />}
                    onChange={form.submit}
                  >
                    <Select.Option value={''}>全部</Select.Option>
                    <Select.Option value={'ZIFEI'}>自费</Select.Option>
                    {insuranceArray.map((v) => (
                      <Select.Option value={v.insuranceCode} key={v.id}>
                        {v.insuranceName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
              <Button type='primary' onClick={form.submit}>
                查询
              </Button>
            </Space>
            <Space>
              <Button
                type='primary'
                onClick={() => {
                  ListExport()
                }}
              >
                导出
              </Button>
            </Space>
          </Row>
        </Form>
        <EditableList
          bordered
          page={{
            items: page.items,
            current: params.current,
            size: params.size,
            total: page.total,
          }}
          loading={pageLoading}
          columns={columns((action: any, v: any, e: any) => {
            return
          }, params.size * (params.current - 1))}
          onChangePage={(current, size) => {
            setParams({ ...params, current, size: size || 10 })
          }}
          summary={() => (
            <Table.Summary fixed>
              <Table.Summary.Row className={styles.total}>
                <Table.Summary.Cell index={0} align='center'>
                  合计
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} align='center'>
                  {allData?.fristVisitNumberSum}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2} align='center'>
                  {allData?.returnVisitNumberSum}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={3} align='center'>
                  {allData?.visitNumberSum}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={4} align='right'>
                  {allData?.realPayAmountSum > 0
                    ? allData?.realPayAmountSum?.toFixed(2)
                    : '0.00'}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={5} align='center'>
                  {allData?.totalPayAmountSum > 0
                    ? allData?.totalPayAmountSum?.toFixed(2)
                    : '0.00'}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={6} align='center'>
                  {allData?.cashAmountSum > 0
                    ? allData?.cashAmountSum?.toFixed(2)
                    : '0.00'}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={7} align='center'>
                  {allData?.insurancePayAmountSum > 0
                    ? allData?.insurancePayAmountSum?.toFixed(2)
                    : '0.00'}
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          )}
        />
      </Col>
    </Col>
  )
}

export const OutPatientRegistrationReportLists = forwardRef(
  OutPatientRegistrationReportList
)
