/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-04-19 12:05:04
 * @LastEditors: linxi
 * @LastEditTime: 2024-10-17 14:14:01
 */
import { Col } from "antd"
import React, { useRef } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { TabBar, TabPane } from "../../compnents/widgets/TabBar"
import { Delivery } from "../delivery/Delivery"
import { InventoryChecking } from "../InventoryChecking/InventoryChecking"
import { InventoryInit } from "../inventoryInit/inventoryInit"
import { Putstorage } from "../putstorage/putstorage"
import { SetMealRef } from "../setmeal/setmeal"
import styles from "./stockSalesUpload.module.css"

export const StockSalesUploadTabBar = () => {
  const ref = useRef<SetMealRef>(null)

  const location: any = useLocation()

  const history = useHistory()



  return (
    <Col className={styles.container}>
      <TabBar
        defaultActiveKey={location.state ? location.state.station : '1'}
        destroyInactiveTabPane
      >
        <TabPane key='1' tab='采购单上传'>
          <Putstorage tockSalesUploadCategory={'1'} station={'1'}/>
        </TabPane> 
        <TabPane key='2' tab='出库单上传'>
          <Putstorage tockSalesUploadCategory={'2'} station={'2'}/>
        </TabPane>
        <TabPane key='3' tab='盘存上传'>
          <InventoryChecking tockSalesUploadCategory={'8'} station={'3'}/>
        </TabPane>
        <TabPane key='5' tab='库存变更上传'>
          <Putstorage tockSalesUploadCategory={'4'} station={'5'}/>
        </TabPane>
        <TabPane key='4' tab='发药上传'>
          <Delivery tockSalesUploadCategory={'1'} station={'4'}/>
        </TabPane>
        <TabPane key='6' tab='医保初始化'>
          <InventoryInit />
        </TabPane>
      </TabBar>
    </Col>
  )
}
