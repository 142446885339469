/*
 * @Descripttion:
 * @version:
 * @Author: suiyue
 * @email: 1373842098@qq.com
 * @Date: 2022-05-12 17:50:42
 * @LastEditors: linxi
 * @LastEditTime: 2023-12-26 18:23:37
 */

import { API_PREFIX } from './constant'
import { Request } from './request'

export interface drugStaticParams {
  id?: number
  current?: number
  hitCount?: boolean
  pages?: number
  accountUnit?: string
  billingCategory?: string
  costUnit?: number
  doseCategory?: string
  name?: string
  localItemId?: number | null | string
  packSpec?: string
  profit?: number
  retailUnit?: number
  splitCost?: number
  splitCount?: number
  splitRetail?: number
  status?: number
  tenantSupplierName?: string
  wholeCost?: number
  wholeCount?: number
  wholeRetail?: number
  searchCount?: boolean
  size?: number
  total?: number
  drugName?: any
  timeHead?: string
  timeTail?: string
  personTime?: string
  count?: string
  averagePrice?: string
  createTime?: string
  releasingTime?: string
  _dateRange?:any
}
export function getDrugStaticList(params: drugStaticParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/statistics/drugSaleList`,
    method: 'GET',
    params,
  }
}

export function drugStaticExport(params: drugStaticParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/statistics/drugSaleListExportexcel`,
    method: 'GET',
    responseType: 'blob',
    params,
  }
}

