import { sum } from 'lodash'
import moment from 'moment'
import { BillingCategoryName } from './billingCategory'
import { DateTimeFormatSimple, DateTimeFormatSimpleOnlyOne } from './datetime'
import { getAge } from '../utils/StringUtils'
import { getGenderName } from './user'
import { getSourceName } from './ToothHome'
import { decrypt } from './m'

export function printBody(data: any) {
    const tenantName = data?.tenantName
    const timeHead = data?.timeHead
    const timeTail = data?.timeTail
    const local = data?.local
    console.log(data);
    
    const ysDatas = data.data?.map((v:any) => {
        return {
            ...v,
            createTime: moment(v.createTime).format(DateTimeFormatSimple),
            patientAge: getAge(v.patientAge, v.patientMonth),
            patientSex: getGenderName(v.patientSex),
            patientSource: getSourceName(Number(v.source)),
            infectiousDisease: v.infectiousDisease == 1 ? '是' : '否',
            receiveTag: v.receiveTag ? '复诊' : '初诊'
        }
    })

    let patientTotalMoneyTotal = 0
    let patientSkMoneyTotal = 0
    let patientInsuranceMoneyTotal = 0
    let patientOweMoneyTotal = 0
    let patientBjMoneyTotal = 0
    const remark = ''

    let sjDatas = ''

    for (const keys in ysDatas) {
        const rowData = ysDatas[keys]

        sjDatas +=
            ' <div style="font: 8px \'宋体\';display:flex;clear: left;border-right: 1px solid #000;border-left: 1px solid #000;border-bottom: 1px solid #000">' +
            '            <div style="box-sizing: border-box;padding:0px 2px;word-break:break-all;width: 10%;float: left;text-align:center;border-right: 1px solid #000">' +
            '                ' +
            rowData?.createTime +
            '            </div>' +
            '            <div style="box-sizing: border-box;padding:0px 2px;word-break:break-all;width: 8%;float: left;text-align:center;border-right: 1px solid #000">' +
            '                ' +
            rowData?.patientName +
            '            </div>' +
            '            <div style="box-sizing: border-box;padding:0px 2px;word-break:break-all;width: 8%;float: left;text-align:center;border-right: 1px solid #000">' +
            '                ' +
            rowData?.patientAge +
            '            </div>' +
            '            <div style="box-sizing: border-box;padding:0px 2px;word-break:break-all;width: 8%;float: left;text-align:center;border-right: 1px solid #000">' +
            '                ' +
            rowData?.patientSex +
            '            </div>' +
            '            <div style="box-sizing: border-box;padding:0px 2px;word-break:break-all;width: 10%;float: left;text-align:center;border-right: 1px solid #000">' +
            '                ' +
            (rowData?.patientPhone ? decrypt(rowData?.patientPhone): '-')  +
            '            </div>' +
            '            <div style="box-sizing: border-box;padding:0px 2px;word-break:break-all;width: 10%;float: left;text-align:center;border-right: 1px solid #000">' +
            '                ' +
            rowData?.patientTotalMoney +
            '            </div>' +
            '            <div style="box-sizing: border-box;padding:0px 2px;word-break:break-all;width: 8%;float: left;text-align:center;border-right: 1px solid #000">' +
            '                ' +
            rowData?.patientSkMoney +
            '            </div>' +
            '            <div style="box-sizing: border-box;padding:0px 2px;word-break:break-all;width: 12%;float: left;text-align:center;border-right: 1px solid #000">' +
            '                ' +
            rowData?.patientInsuranceMoney +
            '            </div>' +
            '         <div style="box-sizing: border-box;padding:0px 2px;word-break:break-all;width: 10%;float: left;text-align:center;border-right: 1px solid #000">' +
            '               ' +
            rowData?.patientOweMoney +
            '           </div>' +
            '            <div style="box-sizing: border-box;padding:0px 2px;word-break:break-all;width: 12%;float: left;text-align:center;border-right: 1px solid #000">' +
            '                ' +
            rowData?.patientBjMoney +
            '            </div>' +
            '            <div style="box-sizing: border-box;padding:0px 2px;word-break:break-all;width: 8%;float: left;text-align:center;border-right: 1px solid #000">' +
            '                ' +
            rowData?.receiveTag +
            '            </div>' +
            '            <div style="box-sizing: border-box;padding:0px 2px;word-break:break-all;width: 8%;float: left;text-align:center;border-right: 1px solid #000">' +
            '                ' +
            rowData?.infectiousDisease +
            '            </div>' +
            '            <div style="box-sizing: border-box;padding:0px 2px;word-break:break-all;width: 10%;float: left;text-align:center;border-right: 1px solid #000">' +
            '                ' +
            rowData?.disease +
            '            </div>' +
            '            <div style="box-sizing: border-box;padding:0px 2px;word-break:break-all;width: 10%;float: left;text-align:center;border-right: 1px solid #000">' +
            '                ' +
            rowData?.treatmentDepartmentName +
            '            </div>' +
            '            <div style="box-sizing: border-box;padding:0px 2px;word-break:break-all;width: 10%;float: left;text-align:center;border-right: 1px solid #000">' +
            '                ' +
            rowData?.treatmentDoctorName +
            '            </div>'

        sjDatas += '    </div>'
        
        patientTotalMoneyTotal += Number(rowData?.patientTotalMoney)

        patientSkMoneyTotal += Number(rowData?.patientSkMoney)

        patientInsuranceMoneyTotal += Number(rowData?.patientInsuranceMoney)

        patientOweMoneyTotal += Number(rowData?.patientOweMoney)

        patientBjMoneyTotal += Number(rowData?.patientBjMoney)
            
    }

    let content =
        '<div style="margin-left: 10px;margin-right: 10px">' +
        '    <h4 style="text-align: center;margin-bottom: 0px">' +
        tenantName + '-' + '门诊日志' +
        '</h4>' +
        '    <div style="font-size:10px;text-align: right">' +
        '        <div style="float: left;margin-left: 10px">日期:   ' +
        timeHead +
        '  至  ' +
        timeTail +
        '</div>' +
        '       <div style="float: right;">单位:元</div>' +
        '    </div>' +
        '    <div style="font-size:8px;display:flex;clear: left;border: 1px solid #000">' +
        '        <div style="box-sizing: border-box;padding:0px 2px;word-break:break-all;;width: 10%;float: left;text-align:center;border-right: 1px solid #000">' +
        '            就诊时间' +
        '        </div>' +
        '        <div style="box-sizing: border-box;padding:0px 2px;word-break:break-all;;width: 8%;float: left;text-align:center;border-right: 1px solid #000">' +
        '            患者姓名' +
        '        </div>' +
        '        <div style="box-sizing: border-box;padding:0px 2px;word-break:break-all;width: 8%;float: left;text-align:center;border-right: 1px solid #000">' +
        '            年龄' +
        '        </div>' +
        '        <div style="box-sizing: border-box;padding:0px 2px;word-break:break-all;width: 8%;float: left;text-align:center;border-right: 1px solid #000">' +
        '            性别' +
        '        </div>' +
        '        <div style="box-sizing: border-box;padding:0px 2px;word-break:break-all;width: 10%;float: left;text-align:center;border-right: 1px solid #000">' +
        '            联系电话' +
        '        </div>' +
        '        <div style="box-sizing: border-box;padding:0px 2px;word-break:break-all;width: 10%;float: left;text-align:center;border-right: 1px solid #000">' +
        '            应收款额' +
        '        </div>' +
        '        <div style="box-sizing: border-box;padding:0px 2px;word-break:break-all;width: 8%;float: left;text-align:center;border-right: 1px solid #000">' +
        '            收款额' +
        '        </div>' +
        '        <div style="box-sizing: border-box;padding:0px 2px;word-break:break-all;width: 12%;float: left;text-align:center;border-right: 1px solid #000">' +
        '            医保报销金额' +
        '        </div>' +
        '        <div style="box-sizing: border-box;padding:0px 2px;word-break:break-all;width: 10%;float: left;text-align:center;border-right: 1px solid #000">' +
        '            欠费金额' +
        '        </div>' +
        '        <div style="box-sizing: border-box;padding:0px 2px;word-break:break-all;width: 12%;float: left;text-align:center;border-right: 1px solid #000">' +
        '            欠费补缴金额' +
        '        </div>' +
        '        <div style="box-sizing: border-box;padding:0px 2px;word-break:break-all;width: 8%;float: left;text-align:center;border-right: 1px solid #000">' +
        '            接诊类型' +
        '        </div>' +
        '        <div style="box-sizing: border-box;padding:0px 2px;word-break:break-all;width: 8%;float: left;text-align:center;border-right: 1px solid #000">' +
        '            传染病' +
        '        </div>' +
        '        <div style="box-sizing: border-box;padding:0px 2px;word-break:break-all;width: 10%;float: left;text-align:center;border-right: 1px solid #000">' +
        '            疾病名称' +
        '        </div>' +
        '        <div style="box-sizing: border-box;padding:0px 2px;word-break:break-all;width: 10%;float: left;text-align:center;border-right: 1px solid #000">' +
        '            接诊科室' +
        '        </div>' +
        '        <div style="box-sizing: border-box;padding:0px 2px;word-break:break-all;width: 10%;float: left;text-align:center;border-right: 1px solid #000">' +
        '            接诊医生' +
        '        </div>' +
        '    </div>'
    content += sjDatas +
        '    <div style="font-size:8px;display:flex;clear: left;border-right: 1px solid #000;border-left: 1px solid #000;border-bottom: 1px solid #000">' +
        '        <div style="box-sizing: border-box;padding:0px 10px;word-break:break-all;width: 44%;float: left;text-align:center;border-right: 1px solid #000">' +
        '            合计 ' +
        ':' + '</div>' +
        '        <div style="box-sizing: border-box;padding:0px 2px;word-break:break-all;width: 10%;float: left;text-align:center;border-right: 1px solid #000">' +
        patientTotalMoneyTotal.toFixed(2) +
        '        </div>' +
        '        <div style="box-sizing: border-box;padding:0px 2px;word-break:break-all;width: 8%;float: left;text-align:center;border-right: 1px solid #000">' +
        patientSkMoneyTotal.toFixed(2) +
        '        </div>' +
        '        <div style="box-sizing: border-box;padding:0px 2px;word-break:break-all;width: 12%;float: left;text-align:center;border-right: 1px solid #000">' +
        patientInsuranceMoneyTotal.toFixed(2) +
        '        </div>' +
        '        <div style="box-sizing: border-box;padding:0px 2px;word-break:break-all;width: 10%;float: left;text-align:center;border-right: 1px solid #000">' +
        patientOweMoneyTotal.toFixed(2) +
        '        </div>' +
        '        <div style="box-sizing: border-box;padding:0px 2px;word-break:break-all;width: 12%;float: left;text-align:center;border-right: 1px solid #000">' +
        patientBjMoneyTotal.toFixed(2) +
        '        </div>' +
        '        <div style="box-sizing: border-box;padding:0px 10px;word-break:break-all;width: 46%;float: left;text-align:center;border-right: 1px solid #000">' +
        remark +
        '        </div>' +
        '    </div>'


    return content
}