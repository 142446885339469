/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2024-10-18 13:59:20
 * @LastEditors: linxi
 * @LastEditTime: 2024-10-18 17:14:36
 */
import { unwrapResult } from '@reduxjs/toolkit'
import { Col } from 'antd'
import moment from 'moment'
import React, {
  forwardRef,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../../../app/store'
import { EditableList } from '../../../../compnents/list/EditableList'
// import styles from './AnnualCapitalFund.module.css'
import { Column } from './column'
import { Query } from './Query'
import {
  getDateRange,
  DateRangeType,
} from '../../../../compnents/form/DateSelect'
import {
  DateTimeFormatSimple,
  DateTimeFormatSimpleOnlyOne,
} from '../../../../models/datetime'
import { ThemeContext } from '../../../../theme/ThemeContext'
import { getSelfRateInquiryList } from './selfRateInquirySlice'

interface FeeLimitProps {
  selectedTab?: any
}

const SelfRateInquiry = ({ selectedTab }: FeeLimitProps): ReactElement => {
  const theme = useContext(ThemeContext)

  const dispatch = useDispatch<RootDispatch>()

  const [params, setParams] = useState<any>({
  })

  const [page, setPage] = useState<any>()

  const [pageLoading, setPageLoading] = useState(false)

  useEffect(() => {
    if (selectedTab == '4') {
      if (params?.timeHead) {
        setPageLoading(true)
        getPage()
      }
    }
  }, [selectedTab, params])

  const getPage = () => {
    dispatch(
      getSelfRateInquiryList({
        ...params,
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        if (res?.length) {
          setPage(res)
        } else {
          setPage([])
        }
        setPageLoading(false)
      })
      .catch(() => {
        setPageLoading(false)
      })
  }

  return (
    <Col
      style={{
        backgroundColor: theme.pn,
        borderRadius: 10,
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100% - 10px)',
        overflow: 'hidden',
        marginTop: '10px',
        padding: '10px 20px',
      }}
    >
      <Query
        onValueChange={(v: any) => {
          const paramData = {
            ...params,
            ...v,
          }
          setParams({
            ...paramData,
          })
        }}
      />
      <div style={{ flex: 1 }}>
        <EditableList
          rowKey={(v, i) => `${i}`}
          loading={pageLoading}
          pagination={false}
          page={{
            current: 1,
            size: 10,
            total: 0,
            items: page,
          }}
          columns={Column()}
          onChangePage={(current, size) => {
            setParams({
              ...params,
              current,
              size,
            })
          }}
        />
      </div>
    </Col>
  )
}

export const SelfRateInquirys = forwardRef(SelfRateInquiry)
