/*
 * @Descripttion: 
 * @version: 
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-08-17 13:54:58
 * @LastEditors: linxi
 * @LastEditTime: 2024-11-12 14:06:29
 */
import { SearchOutlined, CaretDownFilled } from '@ant-design/icons';
import { unwrapResult } from '@reduxjs/toolkit';
import { Button, Col, Form, Input, Pagination, Row, Select, Space, Spin } from 'antd';
import form from 'antd/lib/form';
import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootDispatch } from '../../../app/store';
import { DateRangeType, DateSelect, getDateRange } from '../../../compnents/form/DateSelect';
import { historicalRecordsList } from './beautyClinicSlice';
import styles from './beautyClinic.module.css'
import moment from 'moment';
import { selectUserDepartmentId, selectUserId } from '../../../app/applicationSlice';

interface HistoricalRecordsProps {
  patientId?: number
  historicalRecordsBtn: boolean
  onCopy: (oldId: number,beautyId:number,name:string) => void
  version : string
}
export const HistoricalRecords = ({ patientId, historicalRecordsBtn, onCopy,version }: HistoricalRecordsProps): ReactElement => {

  const dispatch = useDispatch<RootDispatch>()

  const userId = useSelector(selectUserId);

  const departmentId = useSelector(selectUserDepartmentId);

  const [form] = Form.useForm()

  const [spinLoading, setSpinLoading] = useState(false)

  const [btnLoading, setBtnLoading] = useState<any>(null)

  const [head, tail] = getDateRange(DateRangeType.ThisYear)

  const [params, setParams] = useState({
    current: 1,
    size: 10,
    patientId,
    startTime: moment(head).format("YYYY-MM-DD"),
    endTime: moment(tail).format("YYYY-MM-DD"),
    version : version,
    doctorId: userId ,
  })

  const [page, setPage] = useState({
    items: [],
    total: 0,
  })
  const getList = () => {
    setSpinLoading(true)
    params.version = version
    dispatch(historicalRecordsList(params))
      .then(unwrapResult)
      .then(res => {
        setPage({
          items: res.records,
          total: res.total
        })
      })
      .finally(() => setSpinLoading(false))
  }

  useEffect(() => {
    getList()
  }, [params])

  useEffect(() => {
    setBtnLoading(historicalRecordsBtn)
  }, [historicalRecordsBtn])
  return (
    <>
      <Col
        style={{
          // ...style,
          display: "flex",
          flexDirection: "column",
          overflowY: "hidden",
        }}
      >
        <Form
          form={form}
          initialValues={{
            id: `u${userId}`,
            scope: 0,
          }}
          onFinish={(values) => {
            const vs = {
              ...params,
              ...values,
              id: undefined,
              scope: undefined,
              departmentId: values.id?.startsWith("d")
                ? departmentId
                : undefined,
              doctorId: values.id?.startsWith("u") ? userId : undefined,
              patientId: values.scope === 0 ? patientId : undefined,
              startTime: values.timeHead ? moment(values.timeHead).format("YYYY-MM-DD") : params.startTime,
              endTime: values.timeTail ? moment(values.timeTail).format("YYYY-MM-DD") : params.endTime,
              timeHead: undefined,
              timeTail: undefined,
            };
            setParams(vs);
          }}
        >
          <Row style={{ marginBottom: 20 }} justify="space-between">
            <Space>
              <Form.Item name="keyword" noStyle>
                <Input
                  style={{ width: "14rem" }}
                  placeholder="请输入患者信息"
                  prefix={<SearchOutlined />}
                  onChange={form.submit}
                />
              </Form.Item>
              <Form.Item noStyle name="id">
                <Select
                  style={{ width: "7.5rem" }}
                  placeholder="查看范围"
                  allowClear
                  // suffixIcon={<CaretDownFilled />}
                  onChange={form.submit}
                >
                  <Select.Option value={`u${userId}`}>只看本人</Select.Option>
                  <Select.Option value={`d${departmentId}`}>
                    只看本科室
                  </Select.Option>
                </Select>
              </Form.Item>
              <Form.Item noStyle name="scope">
                <Select
                  style={{ width: "12rem" }}
                  placeholder="查看范围"
                  // suffixIcon={<CaretDownFilled />}
                  onChange={form.submit}
                >
                  <Select.Option value={0}>查看病人范围-当前病人</Select.Option>
                  <Select.Option value={1}>查看病人范围-所有病人</Select.Option>
                </Select>
              </Form.Item>
              <DateSelect
                namePrefix="time"
                labelPrefix=""
                placeholder="时间范围"
                style={{ width: "8.5rem" }}
                initialValue={DateRangeType.ThisYear}
                allowClear={false}
                onChange={form.submit}
                // onChange={(data) => {
                //   const [head, tail] = getDateRange(data)
                //   setParams({
                //     ...params,
                //     startTime: moment(head).format("YYYY-MM-DD"),
                //     endTime: moment(tail).format("YYYY-MM-DD")
                //   })
                // }}
              />
            </Space>
            <Button type="primary" htmlType="submit">
              查询
            </Button>

          </Row>
        </Form>
        <Col style={{ height: 500, overflow: "auto" }}>
          <Spin spinning={spinLoading}>
            {
              page.items && page.items.map((item: any) => {
                return (
                  <Col key={item.id} className={styles.itemWrap}>
                    <Space size={30}>
                      <span>{item.createTime}</span>
                      <span>{item.patientName}</span>
                      <span>{item.doctorName}</span>
                      <span>({item.departmentName})</span>
                      <span className={styles.imgName}>({item.name})</span>
                    </Space>
                    <Button type='primary' loading={btnLoading == item.id} onClick={() => {
                      setBtnLoading(item.id)
                      onCopy(item.id,item.medicalBeautyId,item.name)
                    }}>复制</Button>
                  </Col>
                )
              })
            }
          </Spin>
        </Col>
        <Row justify="end">
          <Pagination
            style={{ marginTop: 20, marginRight: 30 }}
            total={page.total}
            current={params.current}
            pageSize={params.size}
            onChange={(current, size) => setParams({ ...params, current, size })}
          />
        </Row>
      </Col>
    </>
  );
}

