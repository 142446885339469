/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-11-29 18:03:51
 * @LastEditors: linxi
 * @LastEditTime: 2023-01-10 16:36:02
 */
import { EnvironmentOutlined } from '@ant-design/icons'
import { Button, Space, TableColumnType } from 'antd'
import React from 'react'
import { decrypt } from '../../../../models/m'
import { getSexData } from '../../../../models/ToothHome'
import { getAge } from '../../../../utils/StringUtils'

export const BirthdayColumns = (
  startIndex: number,
  onAction: (action: string, t: any) => void
): TableColumnType<any>[] => {
  return [
    //患者生日表格
    {
      title: '序号',
      key: 'no',
      align: 'center',
      width: '5rem',
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
    {
      title: '患者姓名',
      dataIndex: 'name',
      align: 'center',
    },
    {
      title: '性别',
      dataIndex: 'sex',
      align: 'center',
      render: (_, t) => getSexData(Number(t.sex)),
    },
    {
      title: '年龄',
      dataIndex: 'ageYear',
      align: 'center',
      render: (_, r) => getAge(r?.ageYear, r?.ageMonth),
    },
    {
      title: '联系电话',
      dataIndex: 'phone',
      align: 'center',
      render: (_, r) => {
        return r?.phone ? decrypt(r?.phone) : '-'
      },
    },
    {
      title: '出生年月',
      dataIndex: 'birthday',
      align: 'center',
    },
    {
      title: '医生',
      dataIndex: 'doctorName',
      align: 'center',
    },
    {
      title: '短信发送时间',
      dataIndex: 'sendSmsTime',
      align: 'center',
    },
    {
      title: '操作',
      key: 'action',
      align: 'center',
      render: function Element(_, t) {
        return (
          <Space>
            <Button
              type='link'
              onClick={(e) => {
                e.stopPropagation()
                onAction('send', t)
              }}
            >
              发送短信
            </Button>
          </Space>
        )
      },
    },
  ]
}
