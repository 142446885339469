/* eslint-disable react/jsx-no-undef */
import {
  CaretDownFilled,
  ExclamationCircleFilled,
  SearchOutlined,
} from '@ant-design/icons'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Select,
  Space,
} from 'antd'
import { resolve } from 'dns'
import { reject } from 'lodash'
import moment from 'moment'
import React, {
  forwardRef,
  ReactElement,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import {
  selectInsuranceArray,
  selectUserName,
  selectTenantAddress,
  selectTenantAreaCd,
  selectTenantName,
  selectTenantCategory,
  selectUserId,
  selectQueryLoadingTag,
  setQueryLoadingTag,
} from '../../../app/applicationSlice'
import { RootDispatch } from '../../../app/store'
import {
  DateRangeFieldName,
  DateSelect,
} from '../../../compnents/form/DateSelect'
import { EditableList } from '../../../compnents/list/EditableList'
import { traceRoute } from '../../../layouts/layoutSlice'
import { DateTimeFormatSimpleOnlyOne } from '../../../models/datetime'
import { PrintTemplateCategory } from '../../../models/print'
import {
  getRegistrationStateName,
  Registration,
  RegistrationState,
  RegistrationStates,
} from '../../../models/registration'
import { RegistrationQueryParams } from '../../../services/registration'
import { ThemeContext } from '../../../theme/ThemeContext'
import { strParse, medicalSendHttp } from '../../../utils/MedicalUtils'
import { RegistrationColumns } from '../../patient/columns'
import { PatientModal } from '../../patient/modals/PatientModal'
import { getPatientDetail } from '../../patient/patientSlice'
import { PrintTemplateModal } from '../../print/PrintTemplateModal'
import {
  selectPatientModalVisible,
  setPatientModalVisible,
} from '../../treatment/treatmentSlice'
import { medicalRegisterCancelAsync } from '../register/registerSlice'
import { RegisterNoAppointModal } from './RegisterNoAppointModal'
import styles from './Registrations.module.css'
import {
  cancelRegistration,
  getFullDoctorList,
  getOutpatientDepartment,
  getRegistrationList,
  selectDepartments,
  selectDoctors,
  selectRegistrationPage,
  cancelCheckRegistration,
} from './registrationSlice'

export interface RegistrationsRef {
  refresh: () => void
}

const RealRegistrations = (
  _: unknown,
  ref: React.Ref<RegistrationsRef>
): ReactElement => {
  const inputRef = useRef<any>(null)

  const dispatch = useDispatch<RootDispatch>()

  const history = useHistory()

  const theme = useContext(ThemeContext)

  const page = useSelector(selectRegistrationPage)

  const [form] = Form.useForm()

  const departments = useSelector(selectDepartments)

  const doctors = useSelector(selectDoctors)

  const [params, setParams] = useState<RegistrationQueryParams>({
    current: 1,
  })

  const [treatmentId, setTreatmentId] = useState<any>()

  const [printRegistrationId, setPrintRegistrationId] = useState('')

  const insuranceArray = useSelector(selectInsuranceArray)

  const isPatientModalVisible = useSelector(selectPatientModalVisible)

  const userName = useSelector(selectUserName)

  const userId = useSelector(selectUserId)

  const tenantAddress = useSelector(selectTenantAddress)

  const tenantAreaCd = useSelector(selectTenantAreaCd)

  const tenantName = useSelector(selectTenantName)

  const tenantCategory = useSelector(selectTenantCategory)

  const queryLoading = useSelector(selectQueryLoadingTag)

  const cycleCode = sessionStorage.getItem('cycleCode')

  const [modalBtnLoading, setModalBtnLoading] = useState(false) //  弹窗确定按钮 loading

  const [arriveModal, setArriveModal] = useState(false) //工作站-到达弹窗

  const [canclePatient, setCanclePatient] = useState<any>()

  const [cancleType, setCancleType] = useState<any>()

  const [registrations, setRegistrations] = useState<any>()

  const [patient, setPatient] = useState<any>()

  const [
    registerNoAppointModalVisible,
    setRegisterNoAppointModalVisible,
  ] = useState(false)

  const [remark, setRemark] = useState('')

  const [registerNoAppointId, setRegisterNoAppointId] = useState('')

  useEffect(() => {
    //搜索框获取焦点
    inputRef.current!.focus({
      cursor: 'start',
    })
  }, [])
  useEffect(() => {
    //搜索框获取焦点跳转
    if (params.param?.length == 9 && page.items.length == 1) {
      if (page.items[0].registrationState !== RegistrationState.Canceled) {
        dispatch(
          traceRoute({
            name: '医生门诊',
            path: '/treatment',
            query: `registrationId=${page.items[0].id}`,
          })
        )
      }
    }
  }, [page])

  // 挂号撤销
  const registerCancel = (r: any) => {
    dispatch(cancelRegistration(r.id))
      .then(unwrapResult)
      .then(() => dispatch(getRegistrationList(params)))
      .catch(() => {
        // do nothing.
      })
  }

  // 医保撤销挂号
  const medicalRegisterCancel = (
    vs: any,
    serverType: any,
    responseString?: string
  ) => {
    const insuranceItem = insuranceArray.find(
      (v) =>
        v.insuranceCode ===
        (vs.insuranceCode || insuranceArray[0].insuranceCode)
    )
    dispatch(
      medicalRegisterCancelAsync({
        serverType,
        body: {
          baseData: {
            ...insuranceItem,
            responseString: responseString ? responseString : null,
            endTime: moment(insuranceItem.endTime).format(
              DateTimeFormatSimpleOnlyOne
            ),
            userName,
            userId,
            tenantCategory,
            tenantAreaCd,
            cycleCode,
            name: tenantName,
            address: tenantAddress,
            areaCode: tenantAreaCd,
          },
          exp_content: '',
          registrationId: vs.id,
        },
      }) as any
    )
      .then(unwrapResult)
      .then((res: any) => {
        if (serverType === 'requestString') {
          const data = strParse(res)
          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                insuranceItem.countryHospitalNb,
                insuranceItem.secretToken,
              ],
              notification: '取消医保挂号',
            },
            (call, err) => {
              if (!err) {
                registerCancel(vs)
              }
            }
          )
        } else {
          // notification.success({
          //   message: '医保挂号撤销成功',
          //   duration: 2.5,
          // })
        }
      })
  }

  useImperativeHandle(ref, () => ({
    refresh: () => {
      dispatch(getRegistrationList(params))
    },
  }))

  useEffect(() => {
    dispatch(getOutpatientDepartment())
    dispatch(getFullDoctorList())
  }, [])

  useEffect(() => {
    dispatch(setQueryLoadingTag(true))
    dispatch(getRegistrationList(params)).finally(() => {
      dispatch(setQueryLoadingTag(false))
    })
  }, [dispatch, params])

  // useEffect(() => {
  //   setTimeout(function () {
  //     dispatch(setQueryLoadingTag(false))
  //   }, 3000)
  // }, [])

  return (
    <Col
      style={{
        margin: '10px 0px',
        height: 'calc(100% - 30px)',
        padding: '20px 15px',
        borderRadius: theme.br,
        backgroundColor: theme.pn,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
      }}
    >
      <Form
        form={form}
        autoComplete='off'
        onFinish={(values) => {
          setParams({
            ...params,
            ...values,
            current: 1,
            [DateRangeFieldName]: undefined,
          })
        }}
      >
        <Row justify='space-between' style={{ marginBottom: 20 }}>
          <Space>
            <Form.Item name='param' noStyle>
              <Input
                ref={inputRef}
                prefix={<SearchOutlined style={{ color: theme.tc3 }} />}
                placeholder='患者姓名/手机号/助记码/病历号'
                style={{ width: '17rem' }}
                allowClear
                onChange={form.submit}
              />
            </Form.Item>
            <Form.Item name='treatmentDepartmentId' noStyle>
              <Select
                style={{ width: '12.5rem' }}
                placeholder='查看范围-科室列表'
                allowClear
                // suffixIcon={<CaretDownFilled />}
                onChange={form.submit}
              >
                {departments.map((d) => (
                  <Select.Option key={d.id} value={d.id}>
                    {d.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name='registrationState' noStyle>
              <Select
                style={{ width: '11.25rem' }}
                placeholder='是否就诊-全部'
                // suffixIcon={<CaretDownFilled />}
                allowClear
                onChange={form.submit}
              >
                {RegistrationStates.map((s) => (
                  <Select.Option key={s} value={s}>
                    {getRegistrationStateName(s)}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name='treatmentDoctorId' noStyle>
              <Select
                style={{ width: '8.25rem' }}
                placeholder='医生列表'
                // suffixIcon={<CaretDownFilled />}
                allowClear
                onChange={form.submit}
              >
                {doctors.map((d) => (
                  <Select.Option key={d.id} value={d.id}>
                    {d.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            {insuranceArray && insuranceArray.length !== 0 && (
              <Form.Item name='insuranceCode' noStyle>
                <Select
                  placeholder='险种类型'
                  style={{ width: '8.25rem' }}
                  // suffixIcon={<CaretDownFilled />}
                  allowClear
                  onChange={form.submit}
                >
                  <Select.Option value={''}>全部</Select.Option>
                  <Select.Option value={'ZIFEI'}>自费</Select.Option>
                  {insuranceArray.map((v) => (
                    <Select.Option value={v.insuranceCode} key={v.id}>
                      {v.insuranceName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}

            <DateSelect
              style={{ width: '8.5rem' }}
              labelPrefix=''
              namePrefix='registrationTime'
              placeholder='挂号时间'
              onChange={form.submit}
            />
            <Button
              type='primary'
              onClick={() => {
                form.submit(), dispatch(setQueryLoadingTag(true))
              }}
            >
              查询
            </Button>
          </Space>
        </Row>
      </Form>
      <EditableList<Registration>
        loading={queryLoading}
        rowClassName={(r) =>
          r.registrationState === RegistrationState.Canceled
            ? styles.canceled
            : ''
        }
        page={page}
        onRow={(r: any) => ({
          onDoubleClick: () => {
            if (r.registrationState !== RegistrationState.Canceled) {
              if (
                (r?.treatmentDepartmentName == '未指定科室' &&
                  r?.treatmentDoctorName == '未指定医生') ||
                (r?.registrationDepartmentName == '未指定科室' &&
                  r?.registrationDoctorName == '未指定医生')
              ) {
                setRemark(r?.remark?.replace('#', ','))
                setRegisterNoAppointId(r?.id)
                setRegisterNoAppointModalVisible(true)
              } else {
                dispatch(
                  traceRoute({
                    name: '医生门诊',
                    path: '/treatment',
                    query: `registrationId=${(r as Registration).id}`,
                  })
                )
              }
            }
          },
        })}
        columns={RegistrationColumns((a, r) => {
          switch (a) {
            case 'editPatientInfo':
              console.log(r, 'registrationsregistrations')
              setRegistrations({ ...r })
              dispatch(getPatientDetail(r?.patientId))
                .then(unwrapResult)
                .then((pateint: any) => {
                  setPatient(pateint)
                  dispatch(setPatientModalVisible(true))
                })
                .catch(() => {
                  // do nothing.
                })
              break
            case 'registerNoAppoint':
              setRemark(r?.remark?.replace('#', ','))
              setRegisterNoAppointId(r?.id)
              setRegisterNoAppointModalVisible(true)
              break
            case 'treatment':
              dispatch(
                traceRoute({
                  name: '医生门诊',
                  path: '/treatment',
                  query: `registrationId=${r.id}`,
                })
              )
              break
            case 'cancel':
              dispatch(cancelCheckRegistration(r.id))
                .then(unwrapResult)
                .then((res: any) => {
                  setCanclePatient(r)
                  setCancleType(res)
                  setArriveModal(true)
                  // Modal.confirm({
                  //   title: '提示',
                  //   content: res === 'true' ?
                  //     '该患者已经产生过结算信息，继续取消将会把结算信息、病历信息一起取消，如果产生库存变动，将会把库存回退，是否确认取消？'
                  //     : `确认取消 ${r.patientName} 的挂号？`,
                  //   okText: '确认',
                  //   cancelText: '取消',
                  //   onOk: () => {
                  //     if (r.insuranceCode !== 'ZIFEI') {
                  //       medicalRegisterCancel(r, 'requestString')
                  //     } else {
                  //       registerCancel(r)
                  //     }
                  //   },
                  // })
                })
              break
            case 'print':
              setPrintRegistrationId(r.id)
              setTreatmentId(r?.treatmentId)
              break
            case 'register':
              history.push({
                pathname: '/RegistrationsDetail',
                state: {
                  registrationId: r.id,
                  patientId: r.patientId,
                  tag: 1,
                  name: 'registration',
                  patientInfo: {
                    patientName: r.patientName,
                    sex: r.patientSex || undefined,
                    age: r.patientAge,
                    ageMonth: r.patientMonth,
                    phone: r.patientPhone,
                    address: r.address,
                    identityCard: r.idCardNumber,
                    ageYear: r.birthday,
                  },
                },
              })
              sessionStorage.setItem('tag', 1 as any)
              sessionStorage.removeItem('id')
              break
            case 'detail':
              history.push({
                pathname: '/RegistrationsDetail',
                state: {
                  registrationId: r.id,
                  id: r?.precheckId,
                  tag: 1,
                  name: 'registration',
                },
              })
              sessionStorage.setItem('id', r?.precheckId as any)
              sessionStorage.setItem('tag', 1 as any)

              break
          }
        }, (page.current - 1) * page.size)}
        onChangePage={(current, size) =>
          setParams({ ...params, current, size })
        }
      />
      <PrintTemplateModal
        treatmentId={treatmentId}
        templateCategory={PrintTemplateCategory.Reg_Zds}
        visible={!!printRegistrationId}
        registrationId={printRegistrationId}
        onCancel={() => setPrintRegistrationId('')}
      />
      <RegisterNoAppointModal
        id={registerNoAppointId}
        remark={remark}
        visible={registerNoAppointModalVisible}
        onCancel={() => {
          setRegisterNoAppointModalVisible(false)
        }}
        onOk={() => {
          dispatch(setQueryLoadingTag(true))
          dispatch(getRegistrationList(params)).finally(() => {
            dispatch(setQueryLoadingTag(false))
          })
          setRegisterNoAppointModalVisible(false)
        }}
      />
      <Modal
        title={'确认操作'}
        width={400}
        footer={null}
        visible={arriveModal}
        confirmLoading={modalBtnLoading}
        onOk={() => {
          setArriveModal(false)
        }}
        onCancel={() => setArriveModal(false)}
      >
        <div style={{ display: 'flex', lineHeight: '22px' }}>
          <div style={{ color: '#ffae55', fontSize: 24, marginRight: 10 }}>
            <ExclamationCircleFilled />
          </div>
          {cancleType === 'true' && canclePatient?.patientName ? (
            <p>
              该患者已经产生过结算信息，继续取消将会把结算信息、病历信息一起取消，如果产生库存变动，将会把库存回退，是否确认取消？
            </p>
          ) : (
            <p>确认取消{canclePatient?.patientName}的挂号？</p>
          )}
        </div>
        <Row
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'right',
            alignItems: 'center',
            marginTop: '60px',
          }}
        >
          <Button
            type='primary'
            ghost
            style={{ marginRight: '20px' }}
            onClick={() => {
              setArriveModal(false)
            }}
          >
            取消
          </Button>
          <Button
            loading={modalBtnLoading}
            style={{
              background: 'red',
              color: '#FFFFFF',
              border: '1px solid red',
            }}
            onClick={() => {
              setModalBtnLoading(true)
              dispatch(cancelRegistration(canclePatient?.id))
                .then(unwrapResult)
                .then(() => dispatch(getRegistrationList(params)))
                .catch(() => {
                  // do nothing.
                })
                .finally(() => {
                  setModalBtnLoading(false)
                  setArriveModal(false)
                  setCanclePatient({})
                })
            }}
          >
            确定删除
          </Button>
        </Row>
      </Modal>
      <PatientModal
        registrations={registrations}
        registrationId={registrations?.id}
        patient={patient}
        visible={isPatientModalVisible}
        onCancel={() => {
          dispatch(setPatientModalVisible(false))
          setPatient({})}}
        onOk={() => {
          setPatient({})
          dispatch(getRegistrationList(params))
          dispatch(setPatientModalVisible(false))
        }}
      />
    </Col>
  )
}

export const Registrations = forwardRef(RealRegistrations)
