/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-06-29 16:15:53
 * @LastEditors: linxi
 * @LastEditTime: 2024-10-30 10:13:06
 */
import { Button, Checkbox, Popconfirm, Space, TableColumnType } from 'antd'
import React, { ReactElement, useEffect, useState } from 'react'
import {
  getAppointTypeName,
  getAppointTypeType,
  getBgc,
  getColor,
} from '../../../models/appointment'
import { decrypt } from '../../../models/m'
import { getInvoiceStatus } from '../../../models/payment'
import { getGenderName } from '../../../models/user'
import { getAge } from '../../../utils/StringUtils'

type Action = 'apply' | 'detail' | 'edit' | 'cancel' | 'sure'

export const Columns = (
  startIndex: number,
  onAction: (action: Action, t: any) => void,
  hisVersion?: number
): TableColumnType<any>[] => {
  // const [a, sets] = useState(false)

  const [isSendMessage, setIsSendMessage] = useState(false);

  const flag: any =
    localStorage.getItem("isSendMessage") == "true" ? true : false;

  useEffect(() => {
    setIsSendMessage(flag);
  }, [flag]);

  return [
    {
      title: '序号',
      key: 'no',
      align: 'center',
      width: '3rem',
      render: (_1, _2, i) => `${startIndex + i + 1}`,
    },
    {
      title: '患者姓名',
      dataIndex: 'name',
      align: 'center',
      width: '6rem',
      ellipsis: true,
    },
    {
      title: '性别',
      dataIndex: 'sex',
      align: 'center',
      width: '4rem',
      ellipsis: true,
      render: (_, t) => {
        return getGenderName(t?.sex)
      },
    },
    {
      title: '年龄',
      dataIndex: 'ageYear',
      align: 'center',
      width: '4rem',
      ellipsis: true,
      render: (_, r) => getAge(r?.ageYear, r?.ageMonth),
    },
    {
      title: '手机号',
      dataIndex: 'phone',
      align: 'center',
      width: '8rem',
      ellipsis: true,
      render: (_, r) => (r?.phone ? decrypt(r?.phone) : '-'),
    },
    {
      title: '预约时间',
      dataIndex: 'appointmentDate',
      align: 'center',
      width: '12rem',
      ellipsis: true,
      render: function action(_, t) {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <p style={{ margin: 0, height: 18, lineHeight: '18px' }}>
              {t.appointmentDate} {t.appointmentStartTime}~
              {t.appointmentEndTime}
            </p>
            {/* <p style={{ margin: 0, height: 18, lineHeight: '18px' }}></p> */}
          </div>
        )
      },
    },
    {
      title: '预约科室',
      dataIndex: 'appointmentDepartmentName',
      align: 'center',
      width: '6rem',
      ellipsis: true,
    },
    {
      title: '预约方式',
      dataIndex: 'appointmentType',
      align: 'center',
      width: '6rem',
      render: (_, t) => {
        return getAppointTypeName(t.appointmentType)
      },
    },
    {
      title: '预约医生',
      dataIndex: 'appointmentDoctorName',
      align: 'center',
      width: '6rem',
      ellipsis: true,
    },
    {
      title: '接诊类型',
      dataIndex: 'furtherConsideration',
      align: 'center',
      width: '6rem',
      ellipsis: true,
      render: (_, t) => {
        return t.furtherConsideration ? '复诊' : '初诊'
      },
    },
    {
      title: '预约项目',
      dataIndex: 'appointmentItem',
      align: 'center',
      width: '6rem',
      ellipsis: true,
    },
    {
      title: '预约备注',
      dataIndex: 'appointmentComment',
      align: 'center',
      width: '6rem',
      ellipsis: true,
    },
    // {
    //   title: '流失备注',
    //   dataIndex: 'einvoiceStatus',
    //   align: 'center',
    // },
    {
      title: '创建时间',
      dataIndex: 'createTime',
      align: 'center',
      width: '10rem',
      ellipsis: true,
    },
    {
      title: '状态',
      dataIndex: 'appointmentState',
      align: 'center',
      width: '6rem',
      ellipsis: true,
      fixed: 'right',
      render: function action(_, t) {
        return (
          <div
            style={{
              color: getColor(Number(t.appointmentState)),
              padding: '0 4px',
            }}
          >
            <span>{getAppointTypeType(t?.appointmentState)}</span>
          </div>
        )
      },
    },
    {
      title: '操作',
      dataIndex: 'action',
      align: 'center',
      width: '14rem',
      fixed: 'right',
      render: function action(_, t) {
        return (
          <Space>
            {t.appointmentState == 0 && (
              // <Popconfirm
              //   title='确认进行此操作？'
              //   onConfirm={() => onAction('sure', t)}
              //   okText='确认'
              //   cancelText='取消'
              // >
              <a
                href="javascript:;"
                onClick={() => {
                  onAction("sure", t);
                }}
              >
                确认
              </a>
              /* </Popconfirm> */
            )}
            {(t.appointmentState == 0 ||
              t.appointmentState == 4 ||
              t.appointmentState == 9) && (
              <>
                {hisVersion != 1 && (
                  <a
                    href="javascript:;"
                    onClick={() => {
                      onAction("apply", t);
                    }}
                  >
                    挂号
                  </a>
                )}
                <a
                  href="javascript:;"
                  onClick={() => {
                    onAction("edit", t);
                  }}
                >
                  修改
                </a>
              </>
            )}

            {hisVersion != 1 && (
              <a
                href="javascript:;"
                onClick={() => {
                  onAction("detail", t);
                }}
              >
                查看详情
              </a>
            )}
            {(t.appointmentState == 0 ||
              t.appointmentState == 4 ||
              t.appointmentState == 9 ||
              t.appointmentState == -1) && (
              <Popconfirm
                title={
                  <div>
                    <p>确认进行此操作？</p>
                    <p style={{ position: "relative", left: "-20px" }}>
                      <Checkbox
                        checked={isSendMessage}
                        onChange={(e) => {
                          setIsSendMessage(e.target.checked);
                          localStorage.setItem(
                            "isSendMessage",
                            JSON.stringify(e.target.checked)
                          );
                        }}
                      >
                        是否向患者发送取消预约的通知短信
                      </Checkbox>
                    </p>
                  </div>
                }
                onConfirm={() =>
                  onAction("cancel", {
                    ...t,
                    sendSMSFlag: Number(isSendMessage),
                  })
                }
                okText="确认"
                cancelText="取消"
              >
                <a href="javascript:;">取消</a>
              </Popconfirm>
            )}
          </Space>
        );
      },
    },
  ]
}


export const ToothColumns = (
  startIndex: number,
  onAction: (action: Action, t: any) => void,
  hisVersion?: number,
): TableColumnType<any>[] => {
  // const [a, sets] = useState(false)

  const [isSendMessage, setIsSendMessage] = useState(false);

  const flag: any =
    localStorage.getItem("isSendMessage") == "true" ? true : false;

  useEffect(() => {
    setIsSendMessage(flag);
  }, [flag]);

  return [
    {
      title: '序号',
      key: 'no',
      align: 'center',
      width: '3rem',
      render: (_1, _2, i) => `${startIndex + i + 1}`,
    },
    {
      title: '预约时间',
      dataIndex: 'appointmentDate',
      align: 'center',
      width: '12rem',
      ellipsis: true,
      render: function action(_, t) {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <p style={{ margin: 0, height: 18, lineHeight: '18px' }}>
              {t.appointmentDate} {t.appointmentStartTime}~
              {t.appointmentEndTime}
            </p>
            {/* <p style={{ margin: 0, height: 18, lineHeight: '18px' }}></p> */}
          </div>
        )
      },
    },
    {
      title: '预约科室',
      dataIndex: 'appointmentDepartmentName',
      align: 'center',
      width: '6rem',
      ellipsis: true,
    },
    {
      title: '预约方式',
      dataIndex: 'appointmentType',
      align: 'center',
      width: '6rem',
      render: (_, t) => {
        return getAppointTypeName(t.appointmentType)
      },
    },
    {
      title: '预约医生',
      dataIndex: 'appointmentDoctorName',
      align: 'center',
      width: '6rem',
      ellipsis: true,
    },
    {
      title: '接诊类型',
      dataIndex: 'furtherConsideration',
      align: 'center',
      width: '6rem',
      ellipsis: true,
      render: (_, t) => {
        return t.furtherConsideration ? '复诊' : '初诊'
      },
    },
    {
      title: '预约项目',
      dataIndex: 'appointmentItem',
      align: 'center',
      width: '6rem',
      ellipsis: true,
    },
    {
      title: '预约备注',
      dataIndex: 'appointmentComment',
      align: 'center',
      width: '6rem',
      ellipsis: true,
    },
    // {
    //   title: '流失备注',
    //   dataIndex: 'einvoiceStatus',
    //   align: 'center',
    // },
    {
      title: '创建时间',
      dataIndex: 'createTime',
      align: 'center',
      width: '10rem',
      ellipsis: true,
    },
    {
      title: '状态',
      dataIndex: 'appointmentState',
      align: 'center',
      width: '6rem',
      ellipsis: true,
      fixed: 'right',
      render: function action(_, t) {
        return (
          <div
            style={{
              color: getColor(Number(t.appointmentState)),
              padding: '0 4px',
            }}
          >
            <span>{getAppointTypeType(t?.appointmentState)}</span>
          </div>
        )
      },
    },
    {
      title: '操作',
      dataIndex: 'action',
      align: 'center',
      width: '14rem',
      fixed: 'right',
      render: function action(_, t) {
        return (
          <Space>
            {t.appointmentState == 0 && (
              // <Popconfirm
              //   title='确认进行此操作？'
              //   onConfirm={() => onAction('sure', t)}
              //   okText='确认'
              //   cancelText='取消'
              // >
              <a
                href="javascript:;"
                onClick={() => {
                  onAction("sure", t);
                }}
              >
                确认
              </a>
              /* </Popconfirm> */
            )}
            {(t.appointmentState == 0 ||
              t.appointmentState == 4 ||
              t.appointmentState == 9) && (
              <>
                {hisVersion != 1 && (
                  <a
                    href="javascript:;"
                    onClick={() => {
                      onAction("apply", t);
                    }}
                  >
                    挂号
                  </a>
                )}
                <a
                  href="javascript:;"
                  onClick={() => {
                    onAction("edit", t);
                  }}
                >
                  修改
                </a>
              </>
            )}

            {hisVersion != 1 && (
              <a
                href="javascript:;"
                onClick={() => {
                  onAction("detail", t);
                }}
              >
                查看详情
              </a>
            )}
            {(t.appointmentState == 0 ||
              t.appointmentState == 4 ||
              t.appointmentState == 9 ||
              t.appointmentState == -1) && (
              <Popconfirm
                title={
                  <div>
                    <p>确认进行此操作？</p>
                    <p style={{ position: "relative", left: "-20px" }}>
                      <Checkbox
                        checked={isSendMessage}
                        onChange={(e) => {
                          setIsSendMessage(e.target.checked);
                          localStorage.setItem(
                            "isSendMessage",
                            JSON.stringify(e.target.checked)
                          );
                        }}
                      >
                        是否向患者发送取消预约的通知短信
                      </Checkbox>
                    </p>
                  </div>
                }
                onConfirm={() =>
                  onAction("cancel", {
                    ...t,
                    sendSMSFlag: Number(isSendMessage),
                  })
                }
                okText="确认"
                cancelText="取消"
              >
                <a href="javascript:;">取消</a>
              </Popconfirm>
            )}
          </Space>
        );
      },
    },
  ]
}