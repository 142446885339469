/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-08-12 10:42:52
 * @LastEditors: linxi
 * @LastEditTime: 2024-10-23 11:24:25
 */
import { createAsyncThunk, } from '@reduxjs/toolkit'
import _ from 'lodash'
import { api, sendAsync } from '../../app/applicationSlice'
import { RootState, RootThunkApi } from '../../app/store'
import * as T from '../../services/drugWarning'

export const getDrugWarningList = createAsyncThunk<
  void,
  T.DrugWarningListParams,
  RootThunkApi
>('payment/getDrugWarningList', async (params, api) => {
  return sendAsync(T.drugWarningList(params), api)
})

export const getDeleteDrugWarning = createAsyncThunk<
  void,
  T.DeleteDrugWarningParams,
  RootThunkApi
>('payment/getDeleteDrugWarning', async (params, api) => {
  return sendAsync(T.deleteDrugWarning(params), api)
})


export const getDrugWarningSubmit = createAsyncThunk<
  void,
  T.DrugWarningSubmitParams,
  RootThunkApi
>('payment/getDrugWarningSubmit', async (params, api) => {
  return sendAsync(T.drugWarningSubmit(params), api)
})


export const getDrugWarningDetail  = createAsyncThunk<
void,
T.DrugWarningDetailParams,
RootThunkApi
>('payment/getDrugWarningDetail', async (params, api) => {
return sendAsync(T.drugWarningDetail(params), api)
})