/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2022-12-27 16:20:35
 * @LastEditors: linxi
 * @LastEditTime: 2024-09-29 15:36:27
 */
import { Space, Switch, TableColumnType } from 'antd'
import moment from 'moment'
import React, { ReactElement, useState } from 'react'
import { DateTimeFormat, DateTimeFormatSimple } from '../../models/datetime'
import { Dimen } from '../../models/dimen'
import { decrypt } from '../../models/m'
import { getCardType } from '../payment/JuhePayPage/columns'

type ActionType = 'edit' | 'stateEdit'

export const Columns = (startIndex: number,showFlag?:any): TableColumnType<any>[] => {

  const column: TableColumnType<any>[] = [
    {
      title: '序号',
      key: 'order',
      align: 'center',
      width: Dimen.Num,
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
    {
      title: '姓名',
      dataIndex: 'psnName',
      align: 'center',
      width: '6rem',
      ellipsis: true,
      render: (_, r) => {
        return r.psnName ? r.psnName : '-'
      },
    },
    {
      title: '卡类型',
      dataIndex: 'cardType',
      align: 'center',
      width: '6rem',
      ellipsis: true,
      render: (_, r) => {
        return r.cardType ? getCardType(r.cardType) : '-'
      },
    },

    {
      title: '地区',
      dataIndex: 'insuranceRegion',
      align: 'center',
      width: '10rem',
      ellipsis: true,
      render: (_, r) => {
        return r.insuranceRegion ? r.insuranceRegion : '-'
      },
    },
    {
      title: '人员类型',
      dataIndex: 'renyuanlx',
      width: '10rem',
      align: 'center',
      ellipsis: true,
      render: (_, r) => {
        return r.renyuanlx ? r.renyuanlx : '-'
      },
    },

    {
      title: '日期',
      width: '8rem',
      dataIndex: 'jiesuansj',
      align: 'center',
      ellipsis: true,
      render: (_, r) => {
        return r.jiesuansj
          ? moment(r.jiesuansj).format(DateTimeFormatSimple)
          : '-'
      },
    },
    {
      title: '单据号',
      dataIndex: 'yibaojsbh',
      align: 'center',
      ellipsis: true,
      width: '18rem ',
      render: (_, r) => {
        return r.yibaojsbh ? r.yibaojsbh : '-'
      },
    },

    {
      title: '总费用(元)',
      dataIndex: 'totalPayAmount',
      align: 'center',
      width: '10rem',
      ellipsis: true,
      render: (_, r) => {
        return r.totalPayAmount ? r.totalPayAmount?.toFixed(2) : '-'
      },
    },
    {
      title: '医保费用(元)',
      dataIndex: 'insurancePayAmount',
      align: 'center',
      width: '10rem',
      ellipsis: true,
      render: (_, r) => {
        return r.insurancePayAmount ? r.insurancePayAmount?.toFixed(2) : '-'
      },
    },
    {
      title: '现金金额 (元)',
      dataIndex: 'cashAmount',
      align: 'center',
      width: '10rem',
      ellipsis: true,
      render: (_, r) => {
        return r.cashAmount ? r.cashAmount?.toFixed(2) : '-'
      },
    },
    {
      title: '基金支付总额',
      dataIndex: 'jijinzfze',
      align: 'center',
      width: '10rem',
      ellipsis: true,
      render: (_, r) => {
        return r.jijinzfze ? r.jijinzfze?.toFixed(2) : '-'
      },
    },
    {
      title: '个人帐户支出',
      dataIndex: 'gerenzhzc',
      align: 'center',
      width: '10rem',
      ellipsis: true,
      render: (_, r) => {
        return r.gerenzhzc ? r.gerenzhzc?.toFixed(2) : '-'
      },
    },
    {
      title: '参数1',
      dataIndex: 'yj',
      key: 'yj',
      align: 'center',
      width: '18rem',
      ellipsis: true,
      render: (_, r) => (r?.yj ? decrypt(r?.yj) : '-'),
    },
    {
      title: '参数2',
      dataIndex: 'yr',
      key: 'yr',
      align: 'center',
      width: '18rem',
      ellipsis: true,
      render: (_, r) => (r?.yr ? decrypt(r?.yr) : '-'),
    },
  ]
  return !showFlag ? column.filter((c) => c.key != 'yj' && c.key != 'yr') :column
}
