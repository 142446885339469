/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2022-12-26 15:31:12
 * @LastEditors: linxi
 * @LastEditTime: 2024-07-22 11:59:51
 */
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Space,
  Upload,
} from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { RcFile } from 'antd/lib/upload'
import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { RootDispatch } from '../../../../app/store'
import { getBase64 } from '../../../../utils/StringUtils'
import { setDetail } from '../../../sales/DrugStatic/Modal/modalSlice'
import styles from './DoctorManagement.module.css'
import {
  getDoctorManagementDetail,
  getDoctorManagementEditSubmit,
} from './doctorManagementSlice'

interface editDetailModalProps {
  id: any
  visible: boolean
  onCancel: () => void
  onOk: () => void
}
export const EditDetailModal = ({
  id,
  visible,
  onCancel,
  onOk,
}: editDetailModalProps): ReactElement => {
  const [form] = Form.useForm()

  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch<RootDispatch>()

  const [imageUrl, setImageUrl] = useState<any>()

  const [imageFileUrl, setImageFileUrl] = useState<any>()

  const [detail, setDetail] = useState<any>()

  useEffect(() => {
    if (visible) {
      dispatch(getDoctorManagementDetail(id))
        .then(unwrapResult)
        .then((v) => {
          setDetail(v)
        })
    } else {
      setImageUrl('')
    }
  }, [visible])

  useEffect(() => {
    if (detail) {
      form.setFieldsValue({
        ...detail,
      })
      const href = window.location.origin?.includes('localhost')
      ? 'http://his.develop.hydhis.com'
      : window.location?.origin
      const headPhotoList = detail?.headPhoto?.split('/')
      setImageUrl(
        headPhotoList?.[0] == 'proxyToOss'
          ? href + '/' + detail?.headPhoto
          : detail?.headPhoto
      )
    }
  }, [detail])

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>上传照片</div>
    </div>
  )

  return (
    <Modal
      title='编辑医生'
      width={600}
      visible={visible}
      // destroyOnClose
      onCancel={() => {
        onCancel()
        form.resetFields()
      }}
      centered
      footer={null}
      className={styles.editRemark}
      maskStyle={{
        overflow: 'hidden',
      }}
    >
      <Form
        className={styles.form}
        form={form}
        onFinish={(values) => {
          dispatch(
            getDoctorManagementEditSubmit({
              ...detail,
              flag: undefined,
              innerDepartmentId: undefined,
              ...values,
              file: imageFileUrl,
            })
          )
            .then(unwrapResult)
            .then((v) => {
              notification.success({
                message: '操作成功',
                duration: 3,
              })
              onOk()
              form.resetFields()
            })
        }}
        labelCol={{
          span: 3,
        }}
        wrapperCol={{
          span: 21,
        }}
      >
        <Form.Item label='医生头像' name='headPhoto'>
          <Row>
            <Col>
              <Upload
                beforeUpload={(file) => {
                  if (file.size / 1024 < 100) {
                    return true
                  } else {
                    notification.info({ message: '图片大于100kb,请重新选择!' })
                    return false
                  }
                }}
                accept={'.png,.jpg,.jpeg,bmp'}
                name='avatar'
                listType='picture-card'
                className='avatar-uploader'
                showUploadList={false}
                customRequest={async ({ file }) => {
                  if (file instanceof File) {
                    setImageUrl((await getBase64(file)) as string)
                    setImageFileUrl(file)
                  }
                }}
              >
                {imageUrl ? (
                  <img src={imageUrl} alt='avatar' style={{ width: '100%' }} />
                ) : (
                  uploadButton
                )}
              </Upload>
            </Col>
            <Col
              style={{
                display: 'flex',
                alignItems: 'flex-end',
                fontSize: '14px',
                color: '#cbcacb ',
                margin: '0  0 4px 10px',
              }}
            >
              只支持.png,.jpg,.jpeg,bmp格式
              <br /> 头像文件大小请控制在100kb以内
              <br /> 未上传头像时，系统会默认按照性别展示系统头像
            </Col>
          </Row>
        </Form.Item>
        <Form.Item label='医生姓名' name='realname'>
          <Input style={{ width: '300px' }} disabled={true} />
        </Form.Item>
        <Form.Item name='innerDepartmentName' label='科室'>
          <Input style={{ width: '300px' }} disabled={true} />
        </Form.Item>
        <Form.Item name='jobTitle' label='职称'>
          <Input style={{ width: '300px' }} />
        </Form.Item>
        <Form.Item name='adeptAt' label='擅长'>
          <TextArea showCount maxLength={500} rows={3} placeholder='多行输入' />
        </Form.Item>
        <Form.Item name='intro' label='简介'>
          <TextArea showCount maxLength={500} rows={3} placeholder='多行输入' />
        </Form.Item>
        <div style={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
          <Space>
            <Button onClick={() => onCancel()}>取消</Button>
            <Button type='primary' htmlType='submit'>
              确定
            </Button>
          </Space>
        </div>
      </Form>
    </Modal>
  )
}
