/*
 * @Descripttion: 
 * @version: 
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-08-18 17:44:48
 * @LastEditors: sj
 * @LastEditTime: 2022-08-23 09:12:13
 */
import { PlusOutlined } from '@ant-design/icons';
import { unwrapResult } from '@reduxjs/toolkit';
import {Button, Form, Input, Modal, notification, Image, Radio, Row, Space, Upload, Spin, Select} from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import { isNumber } from 'lodash';
import moment from 'moment';
import React, {forwardRef, ReactElement, useEffect, useImperativeHandle, useState} from 'react';
import { useDispatch } from 'react-redux';
import { RootDispatch } from '../../../../app/store';
import FileUpload from '../../../../compnents/file/FileUpload';
import { getBase64 } from '../../../../utils/StringUtils';
import { getUrl } from '../../diagnosis/diagnosisSlice';
import styles from './RecipeItemModal.module.css'
import {User} from "../../../../models/user";
import {getDoctorList} from "../../../sales/DoctorAccont/doctorAuditSlice";

export interface outRecipeRef {
  reset: () => void
}
interface OutRecipeProps {
  recipeId?: string
  onOk?: (v: any) => void
  onCancel: () => void
  visible: boolean
  loading?: boolean
}

const OutRecipeModal = ({
  recipeId,
  onOk,
  visible,
  onCancel,
  loading = false
}: OutRecipeProps, ref: React.Ref<outRecipeRef>): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const [form] = Form.useForm()

  const [isShowNo, setIsShowNo] = useState(false)

  const [uploadImgSrc, setUploadImgSrc] = useState('')

  const [fileName, setFileName] = useState('')

  const [doctors, setDoctors] = useState<User[]>([]);

  useEffect(() => {
    dispatch(getDoctorList())
        .then(unwrapResult)
        .then((doctors) => setDoctors(doctors));
  }, [])

  const close = () => {
    onCancel()
  }

  const required = {
    rules: [{
      required: true
    }]
  }

  useImperativeHandle(ref, () => ({
    reset: () => {
      form.resetFields()
      setUploadImgSrc('')
    }
  }))

  return (
    <Modal
      title={'医保外配处方设置'}
      width={800}
      visible={visible}
      onCancel={close}
      footer={null}
      bodyStyle={{ height: 450 }}
      maskClosable={false}
      keyboard={false}
      closable={false}
      className={styles.outRecipeModal}
    >
      <Spin spinning={loading} delay={500}>
      <Form form={form}
        labelAlign='right'
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 10 }}
        initialValues={{
          rxCotnFlag: 0
        }}
        onFinish={(vs) => {
          const data = {
            ...vs,
            rxFile: uploadImgSrc.split(',')[1]
          }
          onOk && onOk(data)
        }}
      >
        <Form.Item label="是否续方" name='rxCotnFlag' {...required} >
          <Radio.Group onChange={(v) => {
            const value = v.target.value
            setIsShowNo(value == 1)
          }}>
            <Radio value={1}> 是 </Radio>
            <Radio value={0}> 否 </Radio>
          </Radio.Group>
        </Form.Item>
        {
          isShowNo && (
            <Form.Item label="续方的原处方编号" name='initRxno' {...required}>
              <Input />
            </Form.Item>
          )
        }
        <Form.Item label="处方有效天数" name='validDays' rules={[
          { required: true },
          {
            pattern: new RegExp(/^[1-9]\d*$/, 'g'),
            message: '请输入数字',
          },
        ]}>
          <Input addonAfter='天' onChange={(e) => {
            const reg = new RegExp(/^[1-9]\d*$/, 'g')
            if (reg.test(e.target.value)) {
              form.setFieldsValue({
                validEndTime: moment().add(e.target.value, 'd').format('YYYY-MM-DD')
              })

            }

          }} />
        </Form.Item>
        <Form.Item label="有效截止时间" name='validEndTime' >
          <Input disabled />
        </Form.Item>
          <Form.Item label="处方原件上传" name='rxFile' rules={[{
            required: true,
            message: '请上传文件'
          }]}>
          <div className={styles.uploadImg}>
            <Upload
              action={async (file: any) => {
                  setFileName(file.name)
                setUploadImgSrc(await getBase64(file) as string)

                return ''
              }}
              listType="picture"
              maxCount={1}
              showUploadList={false}
              accept={'.pdf'}
              beforeUpload={(file) => {
                if (file.type.includes('pdf')) {
                  if ((file.size / 1024 / 1024) < 1) {
                    return true
                  } else {
                    notification.info({ message: 'PDF文件不能大于1M,请重新选择!' })
                    return false
                  }
                }
                notification.info({ message: '请上传PDF文件' })
                return false
              }}
            >
              <Button icon={<PlusOutlined />}>上传附件</Button>
            </Upload>
              {
              uploadImgSrc && (
                  <div style={{ marginTop: 15 }}>{fileName}</div>
              )
              }
          </div>
        </Form.Item>
        <Form.Item label="审方医生" name='docterId' >
          <Select
              allowClear
              style={{ width: 180 }}
              placeholder='审方医生'
              // suffixIcon={
              //   <CaretDownFilled style={{ color: theme.tc3 }} />
              // }
          >
            {doctors.map((d) => (
                <Select.Option key={d.id} value={d.id}>
                  {d.name}
                </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
      <Row justify='end' className={styles.outRecipeFooter}>
        <Space>
            <Button onClick={close} >取消</Button>
            <Button type='primary' onClick={form.submit} loading={loading} >确认</Button>
        </Space>
      </Row>
      </Spin>
    </Modal>
  );
}

export default forwardRef(OutRecipeModal)
