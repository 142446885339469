import { unwrapResult } from "@reduxjs/toolkit";
import { Col, Modal, Row } from "antd";
import { UploadFile } from "antd/lib/upload/interface";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { RootDispatch } from "../../app/store";
import { getUrl } from "../treatment/diagnosis/diagnosisSlice";

export const LookModal = (props: {
  isModalVisible: boolean;
  id: any;
  fileList?: any;
  onOk: () => void;
  onCancel: () => void;
}) => {
  const dispatch = useDispatch<RootDispatch>();

  const [fileList, setFileList] = useState<any>();


  useEffect(() => {
    if (props?.fileList && props.isModalVisible) {
      setFileList(
        props?.fileList &&
          props?.fileList.split(",").map((v: any) => {
            return ({
              name: v.split("@")[0],
              uid: v.split("@")[1],
              url: v.split("@")[2],
            } as unknown) as UploadFile;
          })
      );
    }
  }, [props.isModalVisible]);

  return (
    <Modal
      width={500}
      title="查看附件"
      visible={props.isModalVisible}
      onCancel={() => {
        props.onCancel();
      }}
      onOk={() => {
        props.onCancel();
      }}
    >
      <Row
        style={{
          lineHeight: "30px",
          marginBottom: "20px",
          paddingLeft: "20px",
          fontSize: "14px",
        }}
      >
        <Row>
          <Col>
            {fileList &&
              fileList?.map((item: any) => (
                <Row
                  gutter={24}
                  key={item.uid}
                  style={{
                    marginTop: "-1px",
                    width: "300px",
                  }}
                >
                  <Col flex={1}>
                    <span
                      style={{
                        display: "inline-block",
                        maxWidth: "60px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        verticalAlign: "middle",
                      }}
                    >
                      {item.name.split(".")[0]}
                    </span>
                    <span
                      style={{
                        verticalAlign: "middle",
                      }}
                    >
                      .{item.name.split(".")[1]}
                    </span>
                  </Col>
                  <Col span={6}>
                    <a
                      onClick={() => {
                        dispatch(getUrl({ fileName: item.url }))
                          .then(unwrapResult)
                          .then((v: any) => {
                            const proxy =
                              window.location.origin.indexOf(
                                'his.develop.hydhis.com'
                              ) >= 0
                                ? '/proxyToOssDevPrivate'
                                : window.location.origin.indexOf(
                                    'his.test.hydhis.com'
                                  ) >= 0
                                ? '/proxyToOssTestPrivate'
                                : window.location.origin.indexOf('hydhis.cn') >=
                                  0
                                ? '/proxyToOssProdPrivate'
                                : '/proxyToOssProdPrivate'
                            const url = (window.location.origin?.includes('localhost')
                            ? 'http://his.develop.hydhis.com'
                            : window.location.origin) + proxy + v
                            window.open(url, '_blank')
                          })
                      }}
                    >
                      查看
                    </a>
                  </Col>
                  {/* <Col span={6}>
                    <CloseOutlined
                      onClick={() => {
                        //  e.stopPropagation();
                        setFileList(() =>
                          fileList.filter((ite) => ite.uid !== item.uid)
                        );
                      }}
                    />
                  </Col> */}
                </Row>
              ))}
          </Col>
        </Row>
      </Row>
    </Modal>
  );
};
