/*
 * @Descripttion: 
 * @version: 
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-09-06 13:53:34
 * @LastEditors: linxi
 * @LastEditTime: 2023-09-06 17:42:19
 */
/* eslint-disable react/display-name */
import { TableColumnType,  } from 'antd'
import moment from 'moment'
import React, { ReactElement } from 'react'
import { getXianzhonglxName } from '../../models/AnnualCapitalFund'
import { DateTimeFormat, DateTimeFormatSimpleOnly } from '../../models/datetime'
import { Dimen } from '../../models/dimen'

export const Columns = (
  startIndex?: any,
): TableColumnType<any>[] => {
  return [
    {
      title: '时间',
      key: 'no',
      align: 'center',
      width: '10em',
      ellipsis: true,
      fixed: 'left',
      render: function element(_, t) {
        return (
          <div>
            {moment(t?.startTime)?.format(DateTimeFormatSimpleOnly)} ~
            {moment(t?.endTime)?.format(DateTimeFormatSimpleOnly)}
          </div>
        )
      },
    },
    {
      title: '险种',
      dataIndex: 'xianzhonglx',
      width: '6em',
      align: 'center',
      render: (_, r) => {
        return r?.xianzhonglx ? getXianzhonglxName(r?.xianzhonglx) : '全部'
      },
    },
    {
      title: '统筹基金（元）',
      key: 'jijinzfze',
      width: '6em',
      align: 'right',
      render: function element(_, t) {
        return (
          <div>
            {Number(t?.jijinzfze) > 0
              ? Number(t?.jijinzfze)?.toFixed(2)
              : '0.00'}
          </div>
        )
      },
    },
    {
      title: '公务员补助（元）',
      dataIndex: 'gongwuyylbzzjzc',
      align: 'right',
      width: '6rem',
      ellipsis: true,
      render: function element(_, t) {
        return (
          <div>
            {Number(t?.gongwuyylbzzjzc) > 0
              ? Number(t?.gongwuyylbzzjzc)?.toFixed(2)
              : '0.00'}
          </div>
        )
      },
    },
    {
      title: '当年账户支付（元）',
      dataIndex: 'dangnianzhzc',
      align: 'right',
      width: '6rem',
      ellipsis: true,
      render: function element(_, t) {
        return (
          <div>
            {Number(t?.dangnianzhzc) > 0
              ? Number(t?.dangnianzhzc)?.toFixed(2)
              : '0.00'}
          </div>
        )
      },
    },
    {
      title: '人头数',
      dataIndex: 'rt',
      align: 'right',
      width: '6rem',
      ellipsis: true,
      render: function element(_, t) {
        return (
          <div>
            {t?.rt
              ? t?.rt
              : '-'}
          </div>
        )
      },
    },
    {
      title: '人次',
      dataIndex: 'rc',
      align: 'right',
      width: '6rem',
      ellipsis: true,
      render: function element(_, t) {
        return (
          <div>
            {t?.rc
              ? t?.rc
              : '-'}
          </div>
        )
      },
    },
    {
      title: '人均基金（元）',
      dataIndex: 'jijinzfzePre',
      align: 'right',
      width: '6rem',
      ellipsis: true,
      render: (_, t) => {
        return Number(t?.jijinzfzePre) > 0
          ? Number(t?.jijinzfzePre)?.toFixed(2)
          : '-'
      },
    },
  ]
}

