/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2024-10-15 11:08:45
 * @LastEditors: linxi
 * @LastEditTime: 2024-10-22 14:42:47
 */
import { unwrapResult } from '@reduxjs/toolkit'
import { Button, Col, Input, Modal, notification, Popconfirm, Row } from 'antd'
import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { RootDispatch } from '../../../app/store'
// import { deleteCategory } from '../commodity/commoditySlice'
import styles from './TcmCases.module.css'
import {
  PlusOutlined,
  MinusOutlined,
  ArrowDownOutlined,
  ArrowUpOutlined,
} from '@ant-design/icons'
import { deleteEntry } from './tcmCasesSlice'
import TextArea from 'antd/lib/input/TextArea'

export const SetXianBingEntryModal = (props: {
  visible: boolean
  xianbingDataList: any
  onOk: (data: any) => void
  onCancel: () => void
  onRefresh: () => void
}): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()
  const [entryList, setEntryList] = useState<any>()

  const text = '确认删除！'

  useEffect(() => {
    if (!props.visible) {
      setEntryList([])
    } else {
      props.xianbingDataList &&
        props.xianbingDataList.push({
          name: '',
          id: '',
        })
      setEntryList(
        props.xianbingDataList.filter((item: any) => item.id != '1234567894562')
      )
    }
  }, [props.visible,props.xianbingDataList])

  const moveItemUp = (index: number) => {
    const newClassificationList = [...entryList]
    const temp = newClassificationList[index]
    newClassificationList[index] = newClassificationList[index - 1]
    newClassificationList[index - 1] = temp
    setEntryList(newClassificationList)
  }

  const moveItemDown = (index: number) => {
    const newClassificationList = [...entryList]
    const temp = newClassificationList[index]
    newClassificationList[index] = newClassificationList[index + 1]
    newClassificationList[index + 1] = temp
    setEntryList(newClassificationList)
  }

  return (
    <div>
      <Modal
        width={640}
        wrapClassName={styles.modalWrap}
        title='现病史设置'
        visible={props.visible}
        okText='保存'
        onOk={() => {
          if (entryList.some((v: any) => v.name == '全部')) {
            notification.info({ message: '现病史名称不能设置成全部' })
            return
          }
          const tempArr = entryList.filter((item: any) => {
            return item.name != ''
          })
          props.onOk(tempArr)
        }}
        onCancel={() => props.onCancel()}
      >
        {entryList &&
          entryList.map((item: any, index: number) => {
            return (
              <Row key={index}>
                <Col
                  span={18}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '10px',
                  }}
                >
                  <Input
                    showCount
                    value={item.name}
                    maxLength={15}
                    placeholder='请输入现病史内容，最大支持15个字符'
                    onChange={(e:any) => {
                      const values = e.target.value
                      const data = entryList.map((v: any, num: number) => {
                        if (v.id == item.id && num == index) {
                          return {
                            ...v,
                            name: values,
                          }
                        } else {
                          return v
                        }
                      })

                      if (data[data.length - 1].name != '') {
                        //判断是否新增一行
                        data.push({
                          name: '',
                          id: '',
                        })
                      }
                      setEntryList(data)
                    }}
                  />
                  {/*  visible={popVisible} */}
                  <Popconfirm
                    placement='top'
                    title={text}
                    onConfirm={() => {
                      let data
                      if (item.id == '') {
                        data = entryList.filter((v: any, num: number) => {
                          return num !== index
                        })
                        notification.success({ message: '删除成功' })
                        setEntryList(data)
                      } else {
                        dispatch(deleteEntry({ id: item.id }))
                          .then(unwrapResult)
                          .then((res) => {
                            data = entryList.filter((v: any, num: number) => {
                              return item.id !== v.id
                            })
                            notification.success({ message: '删除成功' })
                            setEntryList(data)
                            props?.onRefresh && props?.onRefresh()
                          })
                      }
                    }}
                    okText='确认'
                    cancelText='取消'
                  >
                    <div className={styles.iptClose}>
                      <MinusOutlined
                        style={{ marginLeft: '10px', color: '#101010' }}
                      />
                    </div>
                  </Popconfirm>
                </Col>
                {item.name && (
                  <Col
                    span={6}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginTop: '-10px',
                    }}
                  >
                    {index != 0 ? (
                      <Button
                        type='primary'
                        size='small'
                        style={{ width: '66px', marginLeft: '10px' }}
                        onClick={() => moveItemUp(index)}
                      >
                        <ArrowUpOutlined style={{ color: '#ffffff' }} />
                        上移
                      </Button>
                    ) : (
                      <div style={{ width: '66px', marginLeft: '10px' }}></div>
                    )}
                    {entryList?.filter((v: any) => v?.name)?.length - 1 ==
                    index ? (
                      <div style={{ width: '66px', marginLeft: '10px' }}></div>
                    ) : (
                      <Button
                        type='primary'
                        size='small'
                        style={{ width: '66px', marginLeft: '10px' }}
                        onClick={() => moveItemDown(index)}
                      >
                        <ArrowDownOutlined style={{ color: '#ffffff' }} />
                        下移
                      </Button>
                    )}
                  </Col>
                )}
              </Row>
            )
          })}
      </Modal>
    </div>
  )
}
