import { Modal, Pagination, Row, Table, TableColumnType } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { supplierParams } from "../../services/supplier";
import { SupplierQuery } from "./query";
import {
  selectSupplierdata,
  selectTotal,
  selectCurrent,
  pageSupplier,
  setCurrent,
} from "../wms/supplierSlice";
import styles from "./Savebusiness.module.css";
import { getMaterialsCategoryName } from "../../models/commodity";
import {Dimen} from "../../models/dimen";
import {getSupplierCategory} from "../../models/supplierCategory";
import { decrypt } from "../../models/m";
interface datatype {
  address?: string;
  companyCode?: string;
  phone?: string;
  supplierCategory?: number;
  supplierMaterialsCategory?: string;
}

export const SupplierModal = (props: {
  supplierCategory: number;
  visible: boolean;
  onOk: () => void;
  onCancel: () => void;
  onRecord: (record: any) => void;
  switchModal: () => void;
  isAdd?: boolean

}) => {
  const [size, setSize] = useState(10);

  const dispatch = useDispatch();

  const total = useSelector(selectTotal);

  const current = useSelector(selectCurrent);

  const data = useSelector(selectSupplierdata);

  const [queries, setQueries] = useState<supplierParams>();

  useEffect(() => {

    props.visible && getPage()
  }, [current, size, queries, props.visible]);

  const getPage = () => {
    dispatch(
      pageSupplier({
        ...queries,
        current,
        size,
        supplierCategory: props.supplierCategory,
      })
    );
  }

  const columns = (startIndex: number): TableColumnType<any>[] => {
    return [
      {
        title: "序号",
        align: "center",
        render: (_1, _2, index) => `${startIndex + index + 1}`,
      },
      {
        title: "供应商名称",
        align: "center",
        dataIndex: "companyName",
        sorter: (a, b) => {
          return a.companyName.length - b.companyName.length;
        },
      },
      {
        title: "企业编号",
        align: "center",
        dataIndex: "companyCode",
      },
      {
        title: "许可证类型",
        align: "center",
        dataIndex: "licenceCategory",
        width: Dimen.Category,
        render: (licenceCategory) => {
          return getSupplierCategory(licenceCategory);
        },
      },
      {
        title: "许可证号",
        align: "center",
        dataIndex: "licenceNumber",
      },
      {
        title: "地址",
        align: "center",
        dataIndex: "address",
      },
      {
        title: "联系电话",
        align: "center",
        dataIndex: "phone",
        render: (_, r) => {
          return r?.phone ? decrypt(r?.phone) : '-'
        },
      },
      {
        title: "供货物资类别",
        align: "center",
        dataIndex: "supplierMaterialsCategory",
        render: (_, t) => {
          try {
          const arr = JSON.parse(t.supplierMaterialsCategory as string);
          return Array.isArray(arr)
            ? arr.map((v) => getMaterialsCategoryName(Number(v))).join("，")
            : getMaterialsCategoryName(Number(arr));
          } catch {
            return "";
          }
        },
      },
      {
        title: "操作",
        align: "center",
        dataIndex: "",
        key: "x",
        render: function edit(_: any, t: any) {
          return <a>选择</a>;
        },
      },
    ];
  };

  return (
    <>
      <Modal
        title={props.supplierCategory ? "查询生产厂商" : "查询供应商"}
        visible={props.visible}
        okText="确认"
        cancelText="取消"
        className={styles.form}
        onOk={() => {
          props.onOk();
        }}
        onCancel={() => {
          props.onCancel();
        }}
        footer={null}
      >
        <SupplierQuery
          supplierSave={() => {
            props.switchModal();
          }}
          onValueChange={(v) => {
            setQueries(v);
          }}
          supplierCategory={props.supplierCategory }
        />
        <Table
          columns={columns(size * (current - 1))}
          dataSource={data}
          bordered
          pagination={false}
          onRow={function (record) {
            return {
              onClick: () => {
                props.onRecord(record);
                props.onOk();
              },
            };
          }}
        ></Table>
        <Row style={{ justifyContent: "flex-end" }}>
          <Pagination
            total={total}
            current={current}
            pageSize={size}
            showSizeChanger
            showQuickJumper
            pageSizeOptions={['10', '20', '50', '100', '500']}
            style={{
              marginTop: "10px",
            }}
            onChange={(current, pageSize) => {
              setSize(pageSize as number);
              dispatch(setCurrent(current));
            }}
          />
        </Row>
      </Modal>
    </>
  );
};
