/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2024-10-18 16:55:09
 * @LastEditors: linxi
 * @LastEditTime: 2024-10-18 17:20:11
 */
import {
  Button,
  Form,
  Row,
  Select,
  Space,
  Cascader as Cascaded,
  DatePicker,
  Checkbox,
} from 'antd'
import moment from 'moment'
import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectTenantCountyCode } from '../../../../app/applicationSlice'
import { RootDispatch } from '../../../../app/store'
import {
  DateTimeFormatSimple,
  DateTimeFormatSimpleOnlyOne,
} from '../../../../models/datetime'

export const Query = (props: {
  onValueChange: (params: any) => void
}): ReactElement => {
  const [form] = Form.useForm()

  const { RangePicker } = DatePicker

  const dispatch = useDispatch<RootDispatch>()

  const [flag, setFlag] = useState<any>()

  const [timeList, setTimeList] = useState<any>()

  const tenantCountyCode = useSelector(selectTenantCountyCode)

  useEffect(() => {
    setTimeList([moment().startOf('month'), moment().endOf('day')])
    form.setFieldsValue({
      time: [moment().startOf('month'), moment().endOf('day')],
    })
    form.submit()
  }, [tenantCountyCode])

  // const disabledDate: any = (current: any) => {
  //   return timeList?.[0]
  //     ? moment(current)?.format('yyyy') != moment(timeList?.[0])?.format('yyyy')
  //     : false
  // }

  return (
    <Form
      form={form}
      onFinish={(values) => {
        const startTime = moment(values?.time?.[0])?.format(
          DateTimeFormatSimpleOnlyOne
        )
        const endTime = moment(values?.time?.[1]).endOf('day').format(
          DateTimeFormatSimpleOnlyOne
        )
        props.onValueChange({
          ...values,
          timeHead: startTime,
          timeTail: endTime,
          time: undefined,
        })
      }}
    >
      <Row style={{ marginLeft: 4, marginBottom: 10 }} justify='space-between'>
        <Space>
          <Form.Item name='time' noStyle>
            <RangePicker
              allowClear={false}
              style={{ width: '16em' }}
              onCalendarChange={(v: any) => {
                setTimeList(v)
              }}
            />
          </Form.Item>
          <Button
            type='primary'
            onClick={() => {
              form.submit()
            }}
          >
            查询
          </Button>
        </Space>
      </Row>
    </Form>
  )
}
