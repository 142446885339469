/* eslint-disable react/display-name */
import {
  Avatar,
  Badge,
  Button,
  Popover,
  Space,
  TableColumnType,
  Tooltip,
} from 'antd'
import moment from 'moment'
import React, { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { selectInsuranceArray } from '../../../app/applicationSlice'
import { DateTimeFormat, DateTimeFormatSimple } from '../../../models/datetime'
import { Dimen } from '../../../models/dimen'
import { decrypt } from '../../../models/m'
import { getPayFlagName, getPayMethodName } from '../../../models/payment'
import { getTransactionQueryName } from '../../../models/TransactionQuery'
import { getCardType } from '../../payment/JuhePayPage/columns'

type Action =
  | 'pay'
  | 'return'
  | 'detail'
  | 'upload'
  | 'repeal'
  | 'supplementary'

export const Columns = (
  startIndex?: any,
  onAction?: (
    action: Action,
    id: any,
    t: any,
    settlementCategory?: number
  ) => void,
  openDetail?: (text?: any, v?: any) => void
): TableColumnType<any>[] => {
  const insuranceArray = useSelector(selectInsuranceArray)

  return [
    {
      title: '序号',
      key: 'no',
      align: 'center',
      width: Dimen.Num,
      fixed: 'left',
      render: (_1, _2, i) => `${startIndex + i + 1}`,
    },
    {
      title: '患者姓名',
      key: 'patientName',
      align: 'center',
      width: '6rem',
      fixed: 'left',
      render: function element(_, t) {
        return (
          <Popover
            content={
              <>
                <div style={{ cursor: 'pointer' }}>
                  {insuranceArray.length !== 0 &&
                    '医保卡号' +
                      '：' +
                      (t?.medicalCardNo ? t?.medicalCardNo : '-') +
                      '，'}
                  联系方式：
                  {t?.patientPhone ? decrypt(t?.patientPhone) : '-'}
                  ，身份证号：{t?.idCard ? t?.idCard : '-'}
                </div>
              </>
            }
            placement='bottom'
          >
            <div
              style={{
                color: '#027AFF',
                cursor: 'pointer',
              }}
              onClick={() => {
                openDetail && openDetail('openDetail', t)
              }}
            >
              {t?.patientName ? t?.patientName : '-'}
            </div>
          </Popover>
        )
      },
    },

    {
      title: '结算险种',
      dataIndex: 'insuranceCode',
      width: '6em',
      align: 'center',
      render: (_, r) => {
        return r?.insuranceCode === 'ZIFEI' || !r?.insuranceCode
          ? '自费'
          : '智慧医保'
      },
    },
    // {
    //   title: '参保地区',
    //   dataIndex: 'insuranceRegionName',
    //   width: '6em',
    //   align: 'center',
    //   render: (_, r) => {
    //     return r?.insuranceRegionName ? r?.insuranceRegionName : '-'
    //   },
    // },
    {
      title: '总金额',
      key: 'totalPrice',
      width: '6em',
      align: 'right',
      render: function element(_, t) {
        return (
          <div
            style={{
              color: t?.state == 5 ? 'red' : '',
            }}
          >
            {Number(t?.totalPrice) > 0
              ? Number(t?.totalPrice)?.toFixed(2)
              : '0.00'}
          </div>
        )
      },
    },
    {
      title: '报销金额',
      dataIndex: 'insurancePayAmount',
      align: 'right',
      width: '6rem',
      ellipsis: true,
      render: function element(_, t) {
        return (
          <div
            style={{
              color: t?.state == 5 ? 'red' : '',
            }}
          >
            {Number(t?.insurancePayAmount) > 0
              ? Number(t?.insurancePayAmount)?.toFixed(2)
              : '0.00'}
          </div>
        )
      },
    },
    // {
    //   title: '基金支付',
    //   dataIndex: 'jijinzfze',
    //   align: 'right',
    //   width: '6rem',
    //   ellipsis: true,
    //   render: function element(_, t) {
    //     return (
    //       <div
    //         style={{
    //           color: t?.state == 5 ? 'red' : '',
    //         }}
    //       >
    //         {Number(t?.jijinzfze) > 0
    //           ? Number(t?.jijinzfze)?.toFixed(2)
    //           : '0.00'}
    //       </div>
    //     )
    //   },
    // },
    // {
    //   title: '个人帐户支出',
    //   dataIndex: 'gerenzhzc',
    //   align: 'right',
    //   width: '8rem',
    //   ellipsis: true,
    //   render: function element(_, t) {
    //     return (
    //       <div
    //         style={{
    //           color: t?.state == 5 ? 'red' : '',
    //         }}
    //       >
    //         {Number(t?.gerenzhzc) > 0
    //           ? Number(t?.gerenzhzc)?.toFixed(2)
    //           : '0.00'}
    //       </div>
    //     )
    //   },
    // },
    // {
    //   title: '个人历年帐户支出',
    //   dataIndex: 'linianzhzc',
    //   align: 'right',
    //   width: '9rem',
    //   ellipsis: true,
    //   render: function element(_, t) {
    //     return (
    //       <div
    //         style={{
    //           color: t?.state == 5 ? 'red' : '',
    //         }}
    //       >
    //         {Number(t?.linianzhzc) > 0
    //           ? Number(t?.linianzhzc)?.toFixed(2)
    //           : '0.00'}
    //       </div>
    //     )
    //   },
    // },
    {
      title: '现金金额',
      dataIndex: 'cashAmount',
      align: 'right',
      width: '6rem',
      ellipsis: true,
      render: function element(_, t) {
        return (
          <div
            style={{
              color: t?.state == 5 ? 'red' : '',
            }}
          >
            {Number(t?.cashAmount) > 0
              ? Number(t?.cashAmount)?.toFixed(2)
              : '0.00'}
          </div>
        )
      },
    },
    {
      title: '欠费金额',
      dataIndex: 'arrearageAmount',
      align: 'right',
      width: '6rem',
      ellipsis: true,
      render: function element(_, t) {
        return (
          <div
            style={{
              color: t?.state == 5 ? 'red' : '',
            }}
          >
            {Number(t?.arrearageAmount) > 0
              ? Number(t?.arrearageAmount)?.toFixed(2)
              : '0.00'}
          </div>
        )
      },
    },
    {
      title: '超限价金额',
      dataIndex: 'overlmtSelfpay',
      align: 'right',
      width: '6rem',
      ellipsis: true,
      render: (_, t) => {
        return Number(t?.overlmtSelfpay) > 0
          ? Number(t?.overlmtSelfpay)?.toFixed(2)
          : '-'
      },
    },
    {
      title: '收款人',
      dataIndex: 'userName',
      align: 'center',
      width: '6rem',
      key: 'userName',
      render: function element(_, t) {
        return (
          <div
            style={{
              color: t?.state == 5 ? 'red' : '',
            }}
          >
            {t?.userName ? t?.userName : '-'}
          </div>
        )
      },
    },
    {
      title: '接诊医生',
      key: 'treatmentDoctorName',
      align: 'center',
      width: '6em',
      render: (_, r) => {
        return r?.treatmentDoctorName ? r?.treatmentDoctorName : '-'
      },
    },
    {
      title: '读卡方式',
      key: 'cardType',
      align: 'center',
      width: '6em',
      render: (_, r) => {
        return r?.cardType ? getCardType(r?.cardType) : '-'
      },
    },
    {
      title: '支付方式',
      dataIndex: 'payMethodName',
      key: 'payMethodName',
      align: 'center',
      width: '8rem',
      ellipsis: true,
      render: (_, r) => {
        return r?.payMethodName ? r?.payMethodName : '-'
      },
    },
    {
      title: '收费标志',
      key: 'settlementCategory',
      align: 'center',
      width: '6em',
      render: (_, r) => {
        return getPayFlagName(r?.settlementCategory)
      },
    },
    {
      title: '病历号',
      key: 'patientId',
      align: 'center',
      width: '6em',
      dataIndex: 'patientId',
      render: (_, r) => (r?.patientId > -1 ? r?.patientId : '-'),
    },
    {
      title: '手机号',
      key: 'patientPhone',
      align: 'center',
      width: '8em',
      dataIndex: 'patientPhone',
      render: (_, r) => {
        return r?.patientPhone ? decrypt(r?.patientPhone) : '-'
      },
    },
    {
      title: '收费时间',
      key: 'chargeOrRefundTime',
      align: 'center',
      width: '10em',
      fixed: 'right',
      render: (_, r) => {
        return r?.chargeOrRefundTime
          ? moment(r?.chargeOrRefundTime)?.format(DateTimeFormat)
          : '-'
      },
    },
    {
      title: '结算状态',
      align: 'center',
      key: 'state',
      width: '6rem',
      fixed: 'right',
      render: function element(_, t) {
        return (
          <div
            style={{
              color: t?.state == 5 ? 'red' : '',
            }}
          >
            {t?.state ? getTransactionQueryName(Number(t?.state)) : '-'}
          </div>
        )
      },
    },
    {
      title: '操作',
      key: 'action',
      align: 'center',
      width: '6rem',
      fixed: 'right',
      render: function ShowAction(_, t): ReactElement {
        return (
          <>
            {t?.state == 3 || t?.state == 7 ? (
              <Button
                size='small'
                type='primary'
                onClick={() => onAction && onAction('return', t.paymentId, t)}
              >
                退费
              </Button>
            ) : (
              '-'
            )}
          </>
        )
      },
    },
  ]
}
