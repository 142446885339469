import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import _ from 'lodash'
import { api, sendAsync } from '../../../app/applicationSlice'
import { RootState, RootThunk, RootThunkApi } from '../../../app/store'
import * as D from '../../../models/department'
import { Department } from '../../../models/department'
import * as F from '../../../models/fee'
import { Fee } from '../../../models/fee'
import { DefaultPageSize } from '../../../models/pagination'
import * as P from '../../../models/patient'
import { AppointmentPatient, Patient } from '../../../models/patient'
import * as U from '../../../models/user'
import { AppointmentDoctorsRes, User } from '../../../models/user'
import {
  getOutpatientDepartment as apiGetOutpatientDepartment,
  getAppointmentOutpatientDepartment,
} from '../../../services/department'
import {
  address,
  changeRegisterAppointData,
  getAppointmentPhone,
  getPatientDetail as apiGetPatientDetail,
  getPatientList as apiGetPatientList,
  GetPatientListParams,
  RegisterAppointDataParam,
} from '../../../services/patient'
import {
  getMedical,
  getPatientInfo,
  getRegistrationFeeList as apiGetRegistrationFeeList,
  medicalRegister,
  medicalRegisterCancel,
  ParcPsnSumBody,
  PatientInfoBody,
  register as apiRegister,
  registerAndTreatment as apiRegisterAndTreatment,
  RegisterBody,
  registerCancelBody,
  RegisterParams,
  searchBody,
  searchMedical,
  searchParams,
  searchPsnSum,
  searchQueryPsn,
  getToDayListPagePageFn,
  getToDayListPagePageFnParams,
  getAcceptsTypeFn,
  getAcceptsTypeFnParams,
  getIsSeeDoctorFn,
  getIsSeeDoctorFnParams,
  updateInsuranceCardInfo,
  patientInsurance,
  RegisterAndTreatmentParams,
  getRegistrationHistoryParams,
  getReceiveTagOfOral,
  mobileTodayRegisterCode,
  searchPsnSumForLocal,
  ParcPsnSumBodyLocal,
  insurancePatientSpecialDiseaseByYbPersonalNoAsync,
  saveSomeFunctions,
  saveSomeFunctionsParams,
  cancelPayMoney,
  cancelPayMoneyBody,
  searchMARecordList,
  SearchMARecordListBody, RegisterBodyForMultiYidi, medicalRegisterForMultiYidi,
} from '../../../services/registration'
import {
  getDoctorsInDepartment as apiGetDoctorsInDepartment,
  getAppointmentDoctors,
  addAppointment,
  getDoctorsTimes,
  seeAppointment,
  getDoctorsTimeStep,
  DoctorsTimeStepParams,
} from '../../../services/user'

interface RegisterState {
  departments: Department[]
  doctors: User[]
  AppointmentDoctors: AppointmentDoctorsRes[]
  patients: Patient[]
  patient?: Patient
  feeList: Fee[]
  searchMedical?: any[]
  appointmentId?: ''
  patientPageId?: ''
}

const initialState: RegisterState = {
  departments: [],
  doctors: [],
  patients: [],
  patient: undefined,
  feeList: [],
  AppointmentDoctors: [],
  appointmentId: '', // 预约过劳
  patientPageId: '', // 患者管理过来
}

export interface registerValue {
  treatmentId: number
  patientId: number
  registrationId: number
  recipeId: number
  paymentId?: number
  payMethod?: any
  payment?:any
}

export const register = createAsyncThunk<
  registerValue,
  RegisterParams,
  RootThunkApi<string>
>('register/registerStatus', async (params, api) => {
  return sendAsync(
    apiRegister({
      ...params,
      registration: {
        ...params.registration,
        patientName: params.patient.name,
        patientPhone: params.patient.phone,
      },
    }),
    api
  )
})

export interface registerAndTreatmentValue {
  treatmentId: number
  patientId: number
  registrationId: number
  recipeId: number
}

export const registerAndTreatmentSave = createAsyncThunk<
  registerAndTreatmentValue,
  RegisterAndTreatmentParams,
  RootThunkApi<string>
>('register/registerAndTreatmentSave', async (params, api) => {
  return sendAsync(
    apiRegisterAndTreatment({
      ...params,
      registration: {
        ...params.registration,
        patientName: params.patient.name,
        patientPhone: params.patient.phone,
      },
    }),
    api
  )
})
export const updateInsuranceCardInfoAsync = createAsyncThunk<
  registerValue,
  patientInsurance,
  RootThunkApi<string>
>('register/updateInsuranceCardInfoAsync', async (params, api) => {
  return sendAsync(updateInsuranceCardInfo(params), api)
})

// 获取病历号
export const getMedicalNum = createAsyncThunk<void, void, RootThunkApi>(
  'register/getMedicalNum',
  async (_, api) => {
    return sendAsync(getMedical(), api)
  }
)

// 获取医生信息
export const getPatientInfoAsync = createAsyncThunk<
  any,
  PatientInfoBody,
  RootThunkApi
>('register/getPatientInfo', async (body, api) => {
  return sendAsync(getPatientInfo(body), api)
})

// 医保挂号
export const medicalRegisterAsync = createAsyncThunk<
  any,
  RegisterBody,
  RootThunkApi
>('register/medicalRegister', async (body, api) => {
  return sendAsync(medicalRegister(body), api)
})

// 医保挂号（用于异地患者多次结算时的补充挂号）
export const medicalRegisterForMultiYidiAsync = createAsyncThunk<
  any,
  RegisterBodyForMultiYidi,
  RootThunkApi
  >('register/medicalRegisterForMultiYidi', async (body, api) => {
  return sendAsync(medicalRegisterForMultiYidi(body), api)
})

// 医保取消挂号
export const medicalRegisterCancelAsync = createAsyncThunk<
  any,
  registerCancelBody,
  RootThunkApi
>('register/medicalRegisterCancel', async (body, api) => {
  return sendAsync(medicalRegisterCancel(body), api)
})

// // 特慢病查询
export const searchQueryPsnAsync = createAsyncThunk<
  any,
  searchBody,
  RootThunkApi
>('register/searchQueryPsn', async (body, api) => {
  return sendAsync(searchQueryPsn(body), api, true)
})
// 查询特慢病
export const getInsurancePatientSpecialDiseaseByYbPersonalNoAsync = createAsyncThunk<
  void,
  any,
  RootThunkApi
>(
  'register/getInsurancePatientSpecialDiseaseByYbPersonalNoAsync',
  async (body, api) => {
    return sendAsync(
      insurancePatientSpecialDiseaseByYbPersonalNoAsync(body),
      api,
      true
    )
  }
)

// 根据医保个人编号查询慢特病
export const searchMedicalAsync = createAsyncThunk<
  any,
  searchParams,
  RootThunkApi
>('register/searchMedical', async (params, api) => {
  return sendAsync(searchMedical(params), api, true)
})

// 人员累计信息查询
export const searchPsnSumAsync = createAsyncThunk<
  any,
  ParcPsnSumBody,
  RootThunkApi
>('register/searchPsnSumAsync', async (body, api) => {
  return sendAsync(searchPsnSum(body), api, true)
})

// 查询近三个月的医保用药记录
export const searchMARecordListAsync = createAsyncThunk<
  any,
  SearchMARecordListBody,
  RootThunkApi
>('register/searchMARecordListAsync', async (body, api) => {
  return sendAsync(searchMARecordList(body), api, true)
})

// 新增编辑预约
export const addAppointmentAsync = createAsyncThunk<any, any, RootThunkApi>(
  'register/addAppointmentAsync',
  async (body, api) => {
    return sendAsync(addAppointment(body), api)
  }
)

// 预约回显
export const seeAppointmentAsync = createAsyncThunk<any, string, RootThunkApi>(
  'register/addAppointmentAsync',
  async (id, api) => {
    return sendAsync(seeAppointment(id), api)
  }
)

// 获取医生可用时间
export const getDoctorsTimesAsync = createAsyncThunk<any, string, RootThunkApi>(
  'register/getDoctorsTimesAsync',
  async (id, api) => {
    return sendAsync(getDoctorsTimes(id), api)
  }
)

// 获取医生可用时间刻度
export const getDoctorsTimeStepAsync = createAsyncThunk<
  any,
  DoctorsTimeStepParams,
  RootThunkApi
>('register/getDoctorsTimeStepAsync', async (body, api) => {
  return sendAsync(getDoctorsTimeStep(body), api)
})

// 患者管理---获取患者信息
export const getPatientPageInfo = createAsyncThunk<any, string, RootThunkApi>(
  'register/getPatientPageInfo',
  async (id, api) => {
    return sendAsync(apiGetPatientDetail(id), api)
  }
)

// 医生科室指定
export const getChangeRegisterAppointData = createAsyncThunk<
  any,
  RegisterAppointDataParam,
  RootThunkApi
>('register/getChangeRegisterAppointData', async (id, api) => {
  return sendAsync(changeRegisterAppointData(id), api)
})
// /
//   blade -
// outpatient / registration / changeRegistrationDoctor
// 获取预约电话
export const getAppointmentPhoneAsync = createAsyncThunk<
  any,
  void,
  RootThunkApi
>('register/getAppointmentPhoneAsync', async (_, api) => {
  return sendAsync(getAppointmentPhone(), api)
})

const registerSlice = createSlice({
  name: 'register',
  initialState,
  reducers: {
    setDepartments: (state, action: PayloadAction<Department[]>) => {
      state.departments = action.payload
    },
    setDoctors: (state, action: PayloadAction<User[]>) => {
      state.doctors = action.payload
    },
    setAppointmentDoctors: (
      state,
      action: PayloadAction<AppointmentDoctorsRes[]>
    ) => {
      state.AppointmentDoctors = action.payload
    },
    setPatients: (state, action: PayloadAction<Patient[]>) => {
      state.patients = action.payload
    },
    setPatient: (state, action: PayloadAction<Patient | undefined>) => {
      state.patient = action.payload
    },
    setFeeList: (state, action: PayloadAction<Fee[]>) => {
      state.feeList = action.payload
    },
    setSearchMedical: (state, action: any) => {
      state.searchMedical = action.payload
    },
    setAppointmentIdSlice: (state, action: any) => {
      state.appointmentId = action.payload
    },
    setPatientPageId: (state, action: any) => {
      state.patientPageId = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(searchMedicalAsync.fulfilled, (state, action) => {
      state.searchMedical = action.payload
    })
  },
})

// const {

// } = registerSlice.actions

export const {
  setDepartments,
  setPatients,
  setFeeList: setRegistrationFeeList,
  setDoctors,
  setPatient,
  setAppointmentDoctors,
  setSearchMedical,
  setAppointmentIdSlice,
  setPatientPageId,
} = registerSlice.actions

export const selectDepartments = (state: RootState): Department[] =>
  state.register.departments

export const selectDoctors = (state: RootState): User[] =>
  state.register.doctors

export const selectAppointmentDoctors = (
  state: RootState
): AppointmentDoctorsRes[] => state.register.AppointmentDoctors

export const selectAppointmentId = (state: RootState) =>
  state.register.appointmentId

export const selectPatientPageId = (state: RootState) =>
  state.register.patientPageId

export const selectPatients = (state: RootState): Patient[] =>
  state.register.patients

export const selectPatientDetail = (
  state: RootState
): (Patient & AppointmentPatient) | undefined => state.register.patient

export const selectFeeList = (state: RootState): Fee[] => state.register.feeList

export function getOutpatientDepartment(): RootThunk {
  return api(apiGetOutpatientDepartment(), (data: any, dispatch) => {
    const departments = _.chain(data.records)
      .map((d) => D.fromJson(d))
      .value()
    dispatch(setDepartments(departments))
  })
}

export function getAppointmentOutpatientDepartmentAsync(): RootThunk {
  return api(getAppointmentOutpatientDepartment(), (data: any, dispatch) => {
    const departments = data.map((v: any) => {
      return {
        id: v.departmentId.toString(),
        name: v.departmentName,
      }
    })
    dispatch(setDepartments(departments))
  })
}

export function getPatientList(keyword: any, size?: any): RootThunk {
  return api(
    apiGetPatientList({
      param: !keyword ? undefined : keyword,
      size: size || DefaultPageSize,
      current: 1,
    }),
    (data: any, dispatch) => {
      const patients = _.chain(data.records)
        .map((p) => P.fromJson(p))
        .value()
      dispatch(setPatients(patients))
    }
  )
}

// 查询所有的省市区
export const getAddress = createAsyncThunk<any, void, RootThunkApi<void>>(
  'recipeEditor/getAddress',
  async (params, api) => {
    return sendAsync(address(), api)
  }
)

export const getTriagePatientList = createAsyncThunk<
  void,
  GetPatientListParams,
  RootThunkApi
>('recipeEditor/getAddress', async (params, api) => {
  return sendAsync(apiGetPatientList(params), api)
})

export function getPatientDetail(patientId: string): RootThunk {
  return api(apiGetPatientDetail(patientId), (data: any, dispatch) => {
    dispatch(setPatient(P.fromJson(data)))
    dispatch(setPatientPageId(''))
  })
}

export function getAppointmentPatientDetail(patientId: string): RootThunk {
  return api(seeAppointment(patientId), (data: any, dispatch) => {
    dispatch(setPatient(P.fromAppointmentJson(data)))
  })
}

export function getDoctorsInDepartment(departmentId: string): RootThunk {
  return api(apiGetDoctorsInDepartment(departmentId), (data: any, dispatch) => {
    const doctors = _.chain(data.records)
      .map((u) => U.fromJson(u))
      .value()
    dispatch(setDoctors(doctors))
  })
}

export function getAppointmentDoctorsInDepartment(
  departmentId: string
): RootThunk {
  return api(getAppointmentDoctors(departmentId), (data: any, dispatch) => {
    dispatch(setAppointmentDoctors(data))
  })
}

export function getFeeList(insuranceCode?: string): RootThunk {
  return api(
    apiGetRegistrationFeeList(insuranceCode),
    (data: any, dispatch) => {
      const feeList = _.chain(data.records)
        .map((f) => F.fromJson(f))
        .value()
      dispatch(setRegistrationFeeList(feeList))
    }
  )
}
// 今日预约列表
export const getToDayListPage = createAsyncThunk<
  any,
  getToDayListPagePageFnParams,
  RootThunkApi<void>
>('confirm/getToDayListPage', async (params, api) => {
  return sendAsync(getToDayListPagePageFn(params), api)
})

//获取接诊类型是否显示
export const getAcceptsType = createAsyncThunk<
  any,
  getAcceptsTypeFnParams,
  RootThunkApi<void>
>('confirm/getAcceptsType', async (params, api) => {
  return sendAsync(getAcceptsTypeFn(params), api)
})

export const getSaveSomeFunctions = createAsyncThunk<
  any,
  saveSomeFunctionsParams,
  RootThunkApi<void>
>('confirm/getAcceptsType', async (body, api) => {
  return sendAsync(saveSomeFunctions(body), api)
})

// 取消
export const cancelPayMoneyAsync = createAsyncThunk<
  any,
  cancelPayMoneyBody,
  RootThunkApi<void>
>('register/cancelPayMoneyAsync', async (params, api) => {
  return sendAsync(cancelPayMoney(params), api)
})

//查询患者是否有挂号记录就诊 （2：标记为复诊，1：标记为初诊
export const getReceiveTagOfOralAsync = createAsyncThunk<
  any,
  getRegistrationHistoryParams,
  RootThunkApi<void>
>('confirm/getRegisterHistoryValueByRegist', async (params, api) => {
  return sendAsync(getReceiveTagOfOral(params), api)
})
//查询是否产生过patient数据
export const getIsSeeDoctor = createAsyncThunk<
  any,
  getIsSeeDoctorFnParams,
  RootThunkApi<void>
>('confirm/getIsSeeDoctor', async (params, api) => {
  return sendAsync(getIsSeeDoctorFn(params), api)
})

// 获取小程序二维码
export const getMobileTodayRegisterCode = createAsyncThunk<
  any,
  void,
  RootThunkApi
>('user/getMobileTodayRegisterCode', async (_, api) => {
  return sendAsync(mobileTodayRegisterCode(), api)
})
// 人员累计信息查询(本地查询)
export const searchPsnSumForLocalAsync = createAsyncThunk<
  any,
  ParcPsnSumBodyLocal,
  RootThunkApi
>('register/searchPsnSumForLocalAsync', async (body, api) => {
  return sendAsync(searchPsnSumForLocal(body), api, true)
})
//获取医保人员类别名称
export const getRylb = (code: string) => {
  if (code === '11') {
    return '在职'
  }
  if (code === '136003') {
    return '缴专项基金的建国前工人'
  }
  if (code === '12') {
    return '退休'
  }
  if (code === '136004') {
    return '地震截瘫(市属离休)'
  }
  if (code === '13') {
    return '离休'
  }
  if (code === '136005') {
    return '离休省属有级别'
  }
  if (code === '1300') {
    return '离休人员'
  }
  if (code === '136006') {
    return '离休省属无级别'
  }
  if (code === '136001') {
    return '行政离休'
  }
  if (code === '136007') {
    return '离休市属有级别'
  }
  if (code === '136002') {
    return '财政供养离休人员'
  }
  if (code === '136008') {
    return '离休市属无级别'
  }
  if (code === '1101') {
    return '职工在职'
  }
  if (code === '136009') {
    return '市直离休'
  }
  if (code === '1102') {
    return '公务员在职'
  }
  if (code === '136010') {
    return '建国前老工人(市属离休)'
  }
  if (code === '11021') {
    return '转制并轨在职'
  }
  if (code === '136011') {
    return '二等乙(市属离休)'
  }
  if (code === '1103') {
    return '灵活就业人员在职'
  }
  if (code === '136012') {
    return '企业离休'
  }
  if (code === '11031') {
    return '保健对象在职'
  }
  if (code === '136013') {
    return '已剥离专项基金的建国前工人'
  }
  if (code === '1104') {
    return '减员职工'
  }
  if (code === '136014') {
    return '普通离休(非地市)'
  }
  if (code === '1105') {
    return '农民工'
  }
  if (code === '136015') {
    return '省属离休人员'
  }
  if (code === '1111') {
    return '伤残军人在职'
  }
  if (code === '136016') {
    return '市直机关事业单位离休'
  }
  if (code === '1112') {
    return '伤残军人退休'
  }
  if (code === '136017') {
    return '企业自筹离休人员'
  }
  if (code === '1113') {
    return '职工在职(农垦)'
  }
  if (code === '136018') {
    return '普通离休(地市级)'
  }
  if (code === '1114') {
    return '公务员退休(特)'
  }
  if (code === '136019') {
    return '市属离休人员'
  }
  if (code === '116003') {
    return '失业人员'
  }
  if (code === '136020') {
    return '市直企业离休'
  }
  if (code === '116004') {
    return '子女'
  }
  if (code === '136021') {
    return '特殊离休'
  }
  if (code === '116006') {
    return '企业高管(在职)'
  }
  if (code === '136022') {
    return '垦区离休人员'
  }
  if (code === '116011') {
    return '非财政划拨在职保健对象'
  }
  if (code === '136023') {
    return '省直代管离休'
  }
  if (code === '116012') {
    return '国务院特殊津贴在职人员'
  }
  if (code === '136024') {
    return '享受副省部级待遇离休'
  }
  if (code === '116013') {
    return '在职副厅以上干部'
  }
  if (code === '14') {
    return '居民（未成年）'
  }
  if (code === '116014') {
    return '在职残疾军人'
  }
  if (code === '1401') {
    return '新生儿'
  }
  if (code === '116015') {
    return '二等乙级在职职工'
  }
  if (code === '1402') {
    return '学龄前儿童'
  }
  if (code === '116016') {
    return '下岗职工'
  }
  if (code === '1403') {
    return '中小学生'
  }
  if (code === '116017') {
    return '在职二级保健对象'
  }
  if (code === '1404') {
    return '大学生'
  }
  if (code === '1201') {
    return '职工退休'
  }
  if (code === '1405') {
    return '未成年（未入学）'
  }
  if (code === '1202') {
    return '公务员退休'
  }
  if (code === '1406') {
    return '低保学生儿童'
  }
  if (code === '1203') {
    return '灵活就业人员退休'
  }
  if (code === '146001') {
    return '市直财政供养人员'
  }
  if (code === '12031') {
    return '保健对象退休'
  }
  if (code === '146002') {
    return '残疾人员'
  }
  if (code === '1204') {
    return '退职职工'
  }
  if (code === '146004') {
    return '低保人员'
  }
  if (code === '1205') {
    return '转制并轨退休'
  }
  if (code === '146005') {
    return '重度残疾学生'
  }
  if (code === '126000') {
    return '老工人'
  }
  if (code === '1461') {
    return '积分入学（百佳学子）'
  }
  if (code === '126001') {
    return '退休新人'
  }
  if (code === '149901') {
    return '学生儿童'
  }
  if (code === '126002') {
    return '退休非新人'
  }
  if (code === '15') {
    return '居民（成年）'
  }
  if (code === '126003') {
    return '提前退休'
  }
  if (code === '1501') {
    return '普通居民（成年）'
  }
  if (code === '126004') {
    return '退休二级保健对象'
  }
  if (code === '151') {
    return '居民'
  }
  if (code === '126009') {
    return '非财政划拨退休保健对象'
  }
  if (code === '156001') {
    return '普通居民'
  }
  if (code === '126010') {
    return '国务院特殊津贴退休人员'
  }
  if (code === '16') {
    return '居民（老年）'
  }
  if (code === '126013') {
    return '退休副厅以上干部'
  }
  if (code === '17') {
    return '农牧民少'
  }
  if (code === '126015') {
    return '退休残疾军人'
  }
  if (code === '18') {
    return '农牧民中'
  }
  if (code === '126016') {
    return '二等乙级退休职工'
  }
  if (code === '20') {
    return '农牧民老'
  }
  if (code === '126017') {
    return '退休(满足年限)'
  }
  if (code === '33') {
    return '一至六级残疾军人'
  }
  if (code === '126018') {
    return '退休保健对象'
  }
  if (code === '34') {
    return '建国前老工人'
  }
  if (code === '126019') {
    return '退休(不满足年限)'
  }
  if (code === '99') {
    return '其他'
  }
  if (code === '126022') {
    return '企业高管(退休)'
  }
  if (code === '136009') {
    return '市直离休'
  }
  return '未知：' + code
}

export const selectSearchMedical = (state: RootState): any | [] =>
  state.register.searchMedical

export default registerSlice.reducer
